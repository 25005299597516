import { useMutation } from '@apollo/client'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  Button,
  ButtonColor,
  NotificationType,
  Select,
  TextField,
} from '../../../components'
import { UpdateCreatorResponse, UPDATE_CREATOR } from '../../../gql'
import {
  CountryCode,
  Creator,
  CreatorOnboardingStep,
  MutationUpdateCreatorArgs,
} from '../../../gql/types'
import { AnalyticsEvent, trackEvent } from '../../../utils/analytics'
import {
  countryOptions,
  mapCountryCodeToFlagAndText,
  mapFlagAndTextToCountryCode,
} from '../../../utils/mappers'
import { Bullets } from '../components/Bullets'
import { Steps } from '../components/Steps'
import { useNotification } from '../../../utils/hooks'

export type EditCreatorLocation = {
  addressLine1: string
  addressCity: string
  addressCounty: string
  addressPostcode: string
  countryCode: CountryCode
}

interface Props {
  creator: Creator
}

const SetShippingAddress = ({ creator }: Props) => {
  const { addNotification } = useNotification()

  const [updateCreator, { loading }] = useMutation<
    UpdateCreatorResponse,
    MutationUpdateCreatorArgs
  >(UPDATE_CREATOR)

  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      addressLine1: creator.addressLine1,
      addressCity: creator.addressCity,
      addressCounty: creator.addressCounty,
      addressPostcode: creator.addressPostcode,
      countryCode: mapCountryCodeToFlagAndText(creator.countryCode),
    },
  })

  useEffect(() => {
    trackEvent(AnalyticsEvent.viewCreatorsOnboardingSetShippingAddress)
  }, [])

  const goBack = async () => {
    try {
      await updateCreator({
        variables: {
          id: creator.id,
          input: {
            onboarding: CreatorOnboardingStep.setPortfolio,
          },
        },
      })
    } catch (error) {
      addNotification(error.message, NotificationType.error)
    }
  }

  const onSubmit = async (data: EditCreatorLocation) => {
    try {
      await updateCreator({
        variables: {
          id: creator.id,
          input: {
            addressLine1: data.addressLine1,
            addressCity: data.addressCity,
            addressCounty: data.addressCounty,
            addressPostcode: data.addressPostcode,
            countryCode: mapFlagAndTextToCountryCode(data.countryCode),
            onboarding: CreatorOnboardingStep.setPaypalEmailAddress,
          },
        },
      })
    } catch (error) {
      console.log('@error SetShippingAddress', error.message)
    }
  }

  return (
    <div className='min-h-screen flex flex-col'>
      <div className='flex-grow'>
        <Steps
          creator={creator}
          stepCurrent={CreatorOnboardingStep.setShippingAddress}
        />
        <div className='min-h-full flex flex-col justify-center pt-4 px-4 md:mt-8 lg:px-8'>
          <div className='sm:mx-auto sm:w-full sm:max-w-2xl'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='md:shadow rounded-t-md sm:overflow-hidden'>
                <div className='py-5 md:bg-white space-y-6 sm:p-6'>
                  <div className='sm:mx-auto'>
                    <h2 className='mt-0 md:text-center text-3xl font-extrabold text-gray-900'>
                      Shipping address
                    </h2>
                    <p className='mt-2 md:text-center text-sm text-gray-600'>
                      Will be shared with brands when they need to ship product
                    </p>
                  </div>

                  <div className='grid grid-cols-6 gap-6'>
                    <div className='col-span-6 sm:col-span-6'>
                      <Controller
                        name='countryCode'
                        control={control}
                        rules={{ required: `Please set a country.` }}
                        render={({ field }) => (
                          <Select
                            options={countryOptions}
                            field={field}
                            label='Country/Region'
                            error={errors?.countryCode?.message}
                          />
                        )}
                      />
                    </div>

                    <div className='col-span-6 sm:col-span-6'>
                      <TextField
                        error={errors.addressLine1?.message}
                        label='Address'
                        placeholder='Street, apartment, suite, etc.'
                        // rightElement={
                        //   <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                        //     <QuestionMarkCircleIcon
                        //       className='h-5 w-5 text-gray-400'
                        //       aria-hidden='true'
                        //     />
                        //   </span>
                        // }
                        {...register('addressLine1', {
                          required: `Please set the address.`,
                          maxLength: {
                            value: 100,
                            message: `Your address cannot have more than 100 characters.`,
                          },
                        })}
                      />
                    </div>

                    <div className='col-span-6 sm:col-span-6 lg:col-span-2'>
                      <TextField
                        error={errors.addressCity?.message}
                        label='City'
                        placeholder='London'
                        {...register('addressCity', {
                          required: `Please set the city.`,
                          maxLength: {
                            value: 100,
                            message: `Your city name cannot have more than 100 characters.`,
                          },
                        })}
                      />
                    </div>

                    <div className='col-span-6 sm:col-span-3 lg:col-span-2'>
                      <TextField
                        error={errors.addressCounty?.message}
                        label='County'
                        placeholder='Greater London'
                        {...register('addressCounty', {
                          required: `Please set the county.`,
                          maxLength: {
                            value: 100,
                            message: `Your county name cannot have more than 100 characters.`,
                          },
                        })}
                      />
                    </div>

                    <div className='col-span-6 sm:col-span-3 lg:col-span-2'>
                      <TextField
                        error={errors.addressPostcode?.message}
                        label='Postcode'
                        placeholder='Greater London'
                        {...register('addressPostcode', {
                          required: `Please set the postcode.`,
                          maxLength: {
                            value: 100,
                            message: `Your postcode cannot have more than 100 characters.`,
                          },
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex justify-between md:shadow rounded-b-md py-3 md:bg-gray-50 text-right sm:px-6'>
                <Button
                  title='Go back'
                  type='button'
                  colour={ButtonColor.white}
                  loading={loading}
                  disabled={loading}
                  onClick={goBack}
                />
                <Button
                  title='Continue'
                  type='submit'
                  loading={loading}
                  disabled={loading}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <Bullets
        creator={creator}
        stepCurrent={CreatorOnboardingStep.setShippingAddress}
      />
    </div>
  )
}

export { SetShippingAddress }
