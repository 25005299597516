import moment from 'moment'
import { FunctionComponent, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Masonry from 'react-masonry-css'
import { Link } from 'react-router-dom'
import { BrandRoutes } from '../../brands/BrandRoutes'
import { Avatar } from '../../elements'
import { Clip } from '../../gql/types'
import { ClipTypeBadge } from '../ClipType'
import { FooterClip, HeaderClip } from '../ClipsGrid/ClipItem'
import { DownloadFileButton } from '../DownloadFileButton'
import { VideoZoom } from '../VideoZoom'

interface IDefaultProps {
  useWindow: boolean
  allowDownload: boolean
}

interface IProps extends Partial<IDefaultProps> {
  fetchData: () => void
  hasMore: boolean
  clips: Clip[]
  loading: boolean
  type: string
}

const defaultProps: IDefaultProps = {
  useWindow: true,
  allowDownload: false,
}

const ContentGrid: FunctionComponent<IProps> = ({
  useWindow,
  allowDownload,

  fetchData,
  hasMore,
  clips,
  loading,
  type,
}) => {
  const [numCols, setNumCols] = useState(6)
  const [gridElement, setGridElement] = useState<HTMLDivElement | null>(null)

  const getNumColumns = (width: number) => {
    if (width >= 1536) return 6
    else if (width >= 1280) return 5
    else if (width >= 1024) return 4
    else if (width >= 768) return 3
    else return 1
  }

  useEffect(() => {
    if (!gridElement) return

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const newNumCols = getNumColumns(entry.contentRect.width)
        setNumCols(newNumCols)
      }
    })

    resizeObserver.observe(gridElement)

    return () => {
      resizeObserver.disconnect()
    }
  }, [gridElement])

  if (!loading && clips?.length === 0) {
    return (
      <div className='lg:max-w-7xl'>
        <p className='text-sm text-gray-700 px-4'>No clips found.</p>
      </div>
    )
  }

  return (
    <>
      <InfiniteScroll
        loadMore={fetchData}
        hasMore={hasMore}
        initialLoad={false}
        useWindow={useWindow}
      >
        <div ref={setGridElement}>
          <Masonry
            breakpointCols={numCols}
            className='flex p-3 -ml-2 md:-ml-2 lg:-ml-4 xl:-ml-4 2xl:-ml-6'
            columnClassName='pl-2 space-y-2 md:pl-2 md:space-y-4 lg:pl-4 lg:space-y-4 xl:pl-4 xl:space-y-4 2xl:pl-6 2xl:space-y-6 '
          >
            {clips?.map((clip: Clip, index) => (
              <div key={index}>
                <HeaderClip clip={clip} type={type} className='mt-2' />
                <div className='shadow-2xl overflow-hidden rounded-xl ring-1 ring-black ring-opacity-20 w-full relative'>
                  <VideoZoom
                    src={clip?.video?.url}
                    thumbnailSrc={clip?.thumbnail?.url}
                    className='cursor-pointer rounded-xl w-full object-cover hover:opacity-75'
                  />
                  <div className='absolute left-2 top-2 gap-y-1 overflow-hidden text-sm leading-6 text-white font-bold bg-black/30 px-1 rounded-lg'>
                    <Link
                      to={BrandRoutes.collaborationViewProfile
                        .replace(
                          ':id',
                          `${clip.collaboration.campaign.brand.id}`,
                        )
                        .replace(':uuid', clip.collaboration.uuid)}
                    >
                      <div className='flex gap-x-1 items-center'>
                        <Avatar
                          type='user'
                          size='xxs'
                          src={clip.creator.avatar?.url}
                          alt={clip.creator?.firstName}
                        />
                        <div className=''>
                          {clip.creator?.firstName}
                          <div className='text-xs pb-1'>
                            {moment(clip.createdAt).format('ll')}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  {clip?.type && (
                    <div className='absolute left-2 top-12 pt-1 overflow-hidden'>
                      <ClipTypeBadge type={clip.type} />
                    </div>
                  )}

                  {allowDownload && (
                    <div className='absolute right-2 top-4 gap-y-1 overflow-hidden text-sm leading-6 text-white border border-gray-900 shadow-xl rounded-full'>
                      <DownloadFileButton
                        fileUrl={clip?.video?.url}
                        fileName={clip?.video?.name}
                      />
                    </div>
                  )}

                  {type !== 'creator' && (
                    <div className='absolute right-2 bottom-1'>
                      <FooterClip clip={clip} type={type} />
                    </div>
                  )}
                </div>
              </div>
            ))}
            {loading &&
              [...Array(10)].map((x, i) => (
                <div key={i}>
                  <Skeleton className='shadow-2xl overflow-hidden !rounded-xl ring-1 ring-black ring-opacity-20 w-full relative aspect-[9/16]' />
                </div>
              ))}
          </Masonry>
        </div>
      </InfiniteScroll>
      {(hasMore === false || (!loading && clips.length > 0)) && (
        <div className='flex justify-center py-2'>
          <span className='text-gray-500 text-sm'>No more content to load</span>
        </div>
      )}
    </>
  )
}

ContentGrid.defaultProps = defaultProps

export { ContentGrid }
