import { useMutation } from '@apollo/client'
import { FunctionComponent, useState } from 'react'
import { Button, NotificationType } from '../../../../components'
import { useNotification } from '../../../../utils/hooks'
import { User } from '../../../../gql/types'
import { Toggle } from '../../../../components/Toggle'
import { UPDATE_USER_NOTIFICATIONS } from '../../../../gql'

interface IProps {
  user: User
}

export type EditNotificationsFormData = {
  isMarketingEnabled: boolean
  isReviewsEnabled: boolean
  isApplicantsReminderEnabled: boolean
  isDailyDigestEnabled: boolean
}

const EditNotifications: FunctionComponent<IProps> = ({ user }) => {
  const { addNotification } = useNotification()

  const [updateUserNotifications, { loading: updateNotificationsLoading }] =
    useMutation(UPDATE_USER_NOTIFICATIONS)

  const [isMarketingEnabled, setIsMarketingEnabled] = useState(
    user?.notifications?.isMarketingEnabled ?? true,
  )

  const [isDailyDigestEnabled, setIsDailyDigestEnabled] = useState(
    user?.notifications?.isDailyDigestEnabled ?? true,
  )

  const onSubmit = async (e) => {
    e.preventDefault()

    try {
      await updateUserNotifications({
        variables: {
          userId: user.id,
          input: {
            isMarketingEnabled: isMarketingEnabled,
            isDailyDigestEnabled: isDailyDigestEnabled,
          },
        },
      })

      addNotification(
        'Notification preferences have been updated.',
        NotificationType.success,
      )
    } catch (error) {
      addNotification(
        `Something went wrong. Please try again.`,
        NotificationType.error,
      )
    }
  }

  return (
    <div className='grid grid-cols-1 gap-x-8 gap-y-8 pt-10 lg:grid-cols-3'>
      <div className='px-4 sm:px-0'>
        <h2 className='text-lg leading-6 font-medium text-gray-900'>
          Notifications
        </h2>
        <p className='mt-1 text-sm text-gray-500'>
          We'll always let you know about important changes, but you pick what
          else you want to hear about.
        </p>
      </div>

      <form
        onSubmit={onSubmit}
        className='bg-white shadow ring-1 ring-gray-900/5 sm:rounded-xl lg:col-span-2'
      >
        <div className='px-4 py-6 sm:p-8'>
          <div className='space-y-10'>
            <fieldset>
              {/* <legend className='text-sm font-semibold leading-6 text-gray-900'>
                By Email
              </legend> */}
              <div className='space-y-6'>
                <div className='relative flex gap-x-3'>
                  <Toggle
                    label='Marketing'
                    description='General marketing emails like newsletter and other info.'
                    enabled={isMarketingEnabled}
                    onChange={() => setIsMarketingEnabled(!isMarketingEnabled)}
                  />
                </div>
                <div className='relative flex gap-x-3'>
                  <Toggle
                    label='Daily Digest'
                    description='A daily email digest of the new campaigns.'
                    enabled={isDailyDigestEnabled}
                    onChange={() =>
                      setIsDailyDigestEnabled(!isDailyDigestEnabled)
                    }
                  />
                </div>
              </div>
            </fieldset>
          </div>
        </div>
        <div className='flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8'>
          <Button
            title='Update'
            type='submit'
            loading={updateNotificationsLoading}
            disabled={updateNotificationsLoading}
          />
        </div>
      </form>
    </div>
  )
}

export { EditNotifications }
