import { Avatar } from '../../../elements'
import { Product } from '../../../gql/types'
import { formattedPrice } from '../../../utils/PricingUtil'
import { capitalize, formatLongMessage } from '../../../utils/helpers'
import { BriefExternalLinkRow, BriefTextRow } from '../BriefRow'

interface IProductProps {
  product: Product
}

export const ProductBrief = ({ product }: IProductProps) => {
  return (
    <div className='@container'>
      <div className='grid @xl:grid-cols-4 @xl:gap-6 grid-cols-1 gap-2'>
        <div>
          <h3 className='text-base font-semibold leading-6 text-gray-900'>
            Product
          </h3>
        </div>
        <div className='overflow-hidden bg-white shadow rounded-lg col-span-3'>
          <div className='border-t border-gray-200 px-4'>
            <div className='@md:divide-y @md:divide-gray-200'>
              <div className='py-5 @md:grid @md:grid-cols-3 @md:gap-4'>
                <div className='text-sm font-medium text-gray-500'>Photo</div>
                <Avatar
                  type='product'
                  size='md'
                  src={product.photo?.url}
                  alt={product.name}
                />
              </div>
              <BriefTextRow title='Name' value={product.name} />
              <BriefTextRow title='Price' value={formattedPrice(product)} />
              <BriefTextRow title='Type' value={capitalize(product.type)} />
              <BriefExternalLinkRow title='Link' value={product.link} />
              <BriefTextRow
                title='Details'
                value={formatLongMessage(capitalize(product.details))}
                formatHTML={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
