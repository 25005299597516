import { useQuery } from '@apollo/client'
import { FunctionComponent } from 'react'
import {
  GET_CLIPS_BY_CAMPAIGN_ID,
  GetClipsByCampaignResponse,
} from '../../../gql'
import {
  Collaboration,
  GetClipsSort,
  QueryGetClipsByCampaignIdArgs,
} from '../../../gql/types'
import classNames from 'classnames'
import { ContentGrid } from '../../../components/ContentGrid'

interface IProps {
  collaboration: Collaboration
  className?: string
}

export const ClipsList: FunctionComponent<IProps> = ({
  collaboration,
  className,
}) => {
  const limit = 30

  const { loading, error, data, fetchMore } = useQuery<
    GetClipsByCampaignResponse,
    QueryGetClipsByCampaignIdArgs
  >(GET_CLIPS_BY_CAMPAIGN_ID, {
    variables: {
      campaignId: collaboration?.campaign.id,
      options: {
        query: '',
        filters: {
          creatorId: collaboration?.creator.id,
        },
        sort: GetClipsSort.newest,
        limit,
        skip: 0,
      },
    },
  })

  const fetchData = () => {
    if (loading) {
      return
    }

    // if we have all the data, don't fetch more
    if (
      data?.getClipsByCampaignId?.items?.length >=
      data?.getClipsByCampaignId?.pagination?.numberOfHits
    ) {
      return
    }

    fetchMore({
      variables: {
        campaignId: collaboration?.campaign.id,
        options: {
          query: '',
          filters: {
            creatorId: collaboration?.creator.id,
          },
          sort: GetClipsSort.newest,
          limit,
          skip: data?.getClipsByCampaignId?.items?.length ?? 0,
        },
      },
    })
  }

  if (error) {
    console.log(error)
    return <p>There has been an error. Try refreshing the page</p>
  }

  if (data?.getClipsByCampaignId?.items?.length === 0) {
    return (
      <div className={classNames('lg:max-w-7xl', className)}>
        <p className='text-sm text-gray-700'>No clips available yet.</p>
      </div>
    )
  }

  return (
    <div className={classNames(className, 'overflow-y-scroll')}>
      <ContentGrid
        clips={data?.getClipsByCampaignId?.items || []}
        hasMore={
          data?.getClipsByCampaignId?.items?.length <
          data?.getClipsByCampaignId?.pagination?.numberOfHits
        }
        fetchData={fetchData}
        loading={loading}
        allowDownload={true}
        type='brand'
      />
    </div>
  )
}
