import React, { createRef } from 'react'
import classnames from 'classnames'
import { Icon, IconType } from '..'
import { mergeRefs } from '../../utils/helpers'

interface IDefaultProps {
  className?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
  isActiveStar?: boolean
  changeStarsRating?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

interface IProps extends Partial<IDefaultProps> {
  value: number
  name: string
}

const defaultProps: IDefaultProps = {}

const Star = React.forwardRef(
  (
    {
      className,
      value,
      name,
      onChange,
      disabled,
      isActiveStar,
      changeStarsRating,
    }: IProps,
    ref,
  ) => {
    const localInputRef = createRef<HTMLInputElement>()

    const changeRating = (e) => {
      changeStarsRating(e.target.value)
    }

    const handleOnClick = () => {
      localInputRef?.current?.click()
    }

    return (
      <label
        className={classnames('text-slate-700', className)}
        onClick={handleOnClick}
      >
        <input
          className='absolute top-0 left-0 w-[1px] h-[1px]'
          onChange={onChange}
          ref={mergeRefs(ref, localInputRef)}
          value={value}
          type={'radio'}
          name={name}
          disabled={disabled}
          onClick={changeRating}
        />
        {isActiveStar ? (
          <Icon type={IconType.starFilled} className='text-gray-700 text-xl' />
        ) : (
          <Icon
            type={IconType.starOutlined}
            className='text-gray-700 text-xl'
          />
        )}
      </label>
    )
  },
)

Star.defaultProps = defaultProps

export { Star }
