import { Pagination as PaginationComponent } from '../../../../components'
import { Collaboration, Pagination } from '../../../../gql/types'
import { GridItem } from '../GridItem'

interface IProps {
  collaborations: Collaboration[]
  pagination?: Pagination
  setPage(page: number): void
  onRefetch: () => void
}

const Grid = ({ collaborations, pagination, setPage, onRefetch }: IProps) => {
  return (
    <>
      <ul
        role='list'
        className='py-2 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8'
      >
        {collaborations.map((collaboration, index) => (
          <GridItem
            collaboration={collaboration}
            key={`gridItem-${collaboration?.uuid}`}
          />
        ))}
        {/* {pagination.numberOfHits === 0 && ( */}
        {collaborations?.length === 0 && (
          <div className='py-4 pl-4 pr-3 text-sm sm:pl-6 w-full'>
            No collaborators found.
          </div>
        )}
      </ul>
      {pagination && (
        <PaginationComponent pagination={pagination} setPage={setPage} />
      )}
    </>
  )
}

export { Grid }
