import { Brand, Campaign } from '../../../gql/types'
import {
  modelTypeOptions,
  platformTypeOptions,
  videoTypeOptions,
} from '../../../utils/mappers'
import { Timeline } from './Timeline'

interface Props {
  brand: Brand
  campaign: Campaign
}

const VideoBrief = (campaign: Campaign) => {
  return (
    <section aria-labelledby='comments'>
      <div className='overflow-hidden bg-white shadow sm:rounded-lg'>
        <div className='px-4 py-5 sm:px-6'>
          <h3 className='text-lg font-semibold leading-6 text-gray-900 font-sans'>
            Video brief
          </h3>
          <p className='mt-1 max-w-2xl text-sm text-gray-500'>
            Personal details and application.
          </p>
        </div>
        <div className='border-t border-gray-200 px-4 py-5 sm:px-6'>
          <dl className='grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2'>
            <div className='sm:col-span-1'>
              <dt className='text-sm font-medium text-gray-500'>Video type</dt>
              {videoTypeOptions.map((type, idx) =>
                campaign.videoType === type.type ? (
                  <dd
                    key={idx}
                    className='mt-1 text-sm leading-5 font-normal text-gray-900 font-sans'
                  >
                    {type.name}
                  </dd>
                ) : null,
              )}
            </div>
            <div className='sm:col-span-1'>
              <dt className='text-sm font-medium text-gray-500'>Model type</dt>
              {modelTypeOptions.map((type, idx) =>
                campaign.modelType === type.type ? (
                  <dd
                    key={idx}
                    className='mt-1 text-sm leading-5 font-normal text-gray-900 font-sans'
                  >
                    {type.name}
                  </dd>
                ) : null,
              )}
            </div>
            {/* <div className='sm:col-span-1'>
              <dt className='text-sm font-medium text-gray-500'>Platform</dt>
              {platformTypeOptions.map((type, idx) =>
                campaign.platform === type.type ? (
                  <dd
                    key={idx}
                    className='mt-1 text-sm leading-5 font-normal text-gray-900 font-sans'
                  >
                    {type.name}
                  </dd>
                ) : null,
              )}
            </div> */}
          </dl>
        </div>
      </div>
    </section>
  )
}

const Details = ({ brand, campaign }: Props) => {
  return (
    <div className='lg:col-span-1 lg:block xl:col-span-1 pb-14'>
      <div className='py-5 space-y-6 sm:p-6 lg:col-span-2 lg:col-start-1'>
        {/* Description list*/}
        <Timeline />

        {/* Comments*/}
      </div>
    </div>
  )
}

export { Details }
