import { FunctionComponent } from 'react'
import { TeamInvitation, User } from '../../../../gql/types'
import { useMutation } from '@apollo/client'
import {
  ACCEPT_TEAM_MEMBER_INVITATION,
  GET_SELF,
  GET_USER_BRANDS,
} from '../../../../gql'
import { Button, ButtonColor, NotificationType } from '../../../../components'
import { useNavigate } from 'react-router-dom'
import { BrandRoutes } from '../../../../brands/BrandRoutes'
import { Avatar } from '../../../../elements'
import { useNotification } from '../../../../utils/hooks'
import { useLogout } from '../../../../hooks'
import LOGO from '../../../../assets/logo/lancape_no_bg.png'

interface IDefaultProps {}

interface IProps extends Partial<IDefaultProps> {
  user: User
  invitation: TeamInvitation
}

const defaultProps: IDefaultProps = {}

const AcceptInvitationBrand: FunctionComponent<IProps> = ({
  user,
  invitation,
}) => {
  const navigate = useNavigate()
  const { addNotification } = useNotification()
  const logout = useLogout()
  const [
    acceptTeamMemberInvitation,
    { loading: acceptTeamMemberInvitationLoading },
  ] = useMutation(ACCEPT_TEAM_MEMBER_INVITATION, {
    refetchQueries: [
      {
        query: GET_SELF,
      },
      {
        query: GET_USER_BRANDS,
        variables: {
          options: {
            filters: {
              userIds: [user.id],
            },
            limit: 20,
          },
        },
      },
    ],
  })

  const acceptInvitation = async () => {
    try {
      await acceptTeamMemberInvitation({
        variables: {
          userId: user?.id,
          token: invitation?.token,
        },
      })

      addNotification(`Invitation accepted.`, NotificationType.success)
      navigate(BrandRoutes.team.replace(':id', `${invitation?.brand?.id}`))
    } catch (error) {
      addNotification(
        error.message
          ? error.message
          : `Something went wrong. Please try again.`,
        NotificationType.error,
      )
    }
  }

  return (
    <div className='flex flex-col max-w-lg m-auto p-4 mt-20'>
      <header className='text-center mx-auto pb-4'>
        <img className='h-12 w-auto mx-auto mb-2' src={LOGO} alt='Logo' />
        <small>
          Signed in as {user?.firstName} {user?.lastName}
        </small>
        <div className='flex py-4 items-center justify-center'>
          <Avatar
            src={invitation?.brand?.logo?.url}
            alt={invitation?.brand?.name}
            size='xs'
            type='brand'
          />
          <h2 className='text-gray-900 ml-2'>
            Team invitation for brand {invitation?.brand?.name}
          </h2>
        </div>
        <p className='text-sm py-4'>
          You can logout and create a new user or accept with current user.
        </p>
        <div className='flex flex-col sm:flex-row space-y-2 space-x-0 sm:space-y-0 sm:space-x-2 justify-center'>
          <Button
            colour={ButtonColor.secondary}
            title='Logout and Sign Up'
            onClick={logout}
          />
          <Button
            colour={ButtonColor.primary}
            title='Accept as current user'
            onClick={acceptInvitation}
            loading={acceptTeamMemberInvitationLoading}
          />
        </div>
      </header>
    </div>
  )
}

AcceptInvitationBrand.defaultProps = defaultProps

export { AcceptInvitationBrand }
