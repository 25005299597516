// eslint-disable-next-line
export default {
  API_URL: 'https://clip-api.socialkaat.com',
  CLIENT_ID: '',
  CLIENT_VERSION: '',
  MIXPANEL_KEY: '',
  STRIPE_KEY:
    'pk_test_51Mb8TlFtRUv2zngb1wdxWc1ShtKBX8tllvNNfMR7E5NRTOVhzxko7cWcJfotKkGTCeMBOgaNASPpK21SqtFzeFcB00LbvqetXW',
  ENABLE_BLACK_FRIDAY_DEAL: false,
  ENABLE_CHRISTMAS_DEAL: true,
}
