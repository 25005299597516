import { FunctionComponent } from 'react'
import { Icon, IconType } from '../../../components'
import { Brand } from '../../../gql/types'
import { formatPriceInCents } from '../../../utils/PricingUtil'
import { BrandRoutes } from '../../BrandRoutes'
import { Link } from 'react-router-dom'

interface IProps {
  brand: Brand
}

export const ClipCredits: FunctionComponent<IProps> = ({ brand }) => {
  return (
    <div>
      <div className='mb-2 relative flex rounded-lg border bg-white p-4 shadow-sm focus:outline-none border-indigo-600 ring-2 ring-indigo-600'>
        <span className='flex flex-1'>
          <span className='flex flex-col'>
            <span className='block text-sm font-medium text-gray-900'>
              Clip Credits
            </span>
            <span className='mt-1 flex items-center text-sm text-gray-500'>
              You can top up your account with credits and get discounts up to
              25% with Clip Packs.
            </span>
            <span className='mt-6 flex items-center text-sm text-gray-500'>
              Remaining credit:
            </span>
            <span className='mt-1 text-sm font-medium text-gray-900'>
              {formatPriceInCents(
                {
                  priceInCents: brand.creditCents,
                  currency: brand.currency,
                },
                { minimumFractionDigits: 0 },
              )}
            </span>
          </span>
        </span>
        <Icon
          type={IconType.card}
          className='h-5 w-5 text-indigo-600'
          aria-hidden='true'
        />
        <span
          className='pointer-events-none absolute -inset-px rounded-lg border border-indigo-600'
          aria-hidden='true'
        />
      </div>
    </div>
  )
}
