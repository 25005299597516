import { FunctionComponent } from 'react'
import classnames from 'classnames'
import { TeamInvitationStatus as Type } from '../../../../../gql/types'
import moment from 'moment'

interface IDefaultProps {
  className: string
}

interface IProps extends Partial<IDefaultProps> {
  type: Type
  expirationDate?: number
}

const defaultProps: IDefaultProps = {
  className: null,
}

export const mapInvitationStatusToText = (
  type: Type,
  expirationDate: number,
): string => {
  switch (type) {
    case Type.invited:
      if (moment().isAfter(moment.unix(expirationDate))) {
        return `Expired`
      } else {
        return `Invited`
      }
    case Type.accepted:
      return `Accepted`
    case Type.canceled:
      return `Canceled`
  }
}

const mapInvitationStatusToColor = (type: Type, expirationDate: number) => {
  switch (type) {
    case Type.invited:
      if (moment().isAfter(moment.unix(expirationDate))) {
        return 'bg-orange-400 text-white'
      } else {
        return 'bg-blue-400 text-white'
      }
    case Type.accepted:
      return 'bg-green-400 text-white'
    case Type.canceled:
      return 'bg-red-400 text-white'
  }
}

const InvitationStatus: FunctionComponent<IProps> = ({
  type,
  expirationDate,
  className,
}) => {
  return (
    <span
      className={classnames(
        `inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize ${mapInvitationStatusToColor(
          type,
          expirationDate,
        )}`,
        className,
      )}
    >
      {mapInvitationStatusToText(type, expirationDate)}
    </span>
  )
}

InvitationStatus.defaultProps = defaultProps

export { InvitationStatus }
