import axios from 'axios'
import moment from 'moment-timezone'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Badge,
  BadgeSize,
  BadgeType,
  Button,
  ButtonColor,
  ButtonSize,
  ButtonStyle,
  CollaborationStatus as CollaborationStatusComponent,
} from '..'
import { BrandRoutes } from '../../brands/BrandRoutes'
import { AcceptContentModal } from '../../brands/shared/AcceptContentModal'
import { RequestEditsModal } from '../../brands/shared/RequestEditsModal'
import { CreatorRoutes } from '../../creators/CreatorRoutes'
import { Avatar } from '../../elements'
import { Clip, ClipType, CollaborationStatus } from '../../gql/types'
import { AnalyticsEvent, trackEvent } from '../../utils/analytics'
import { Icon, IconType } from '../Icon'
import { VideoPlayer } from '../VideoPlayer'

export interface ClipsGridProps {
  clip: Clip
  type?: string
}

export const HeaderClip = ({ clip, type, className = '' }) => {
  const [isAcceptContentModalOpen, setIsAcceptContentModalOpen] =
    useState(false)

  return (
    <>
      <dl>
        <div className='grid grid-cols-2 gap-x-4 -mb-2 pt-2'>
          {type === 'brand' && (
            <div>
              <dt className='text-sm font-medium text-gray-500'>Campaign</dt>
              <div className='mt-1 text-sm text-gray-900'>
                <Link
                  to={BrandRoutes.campaignView
                    .replace(':id', `${clip.campaign.brand.id}`)
                    .replace(':campaignId', `${clip?.campaign.id}`)}
                  className='mr-1 text-sm font-semibold text-blue-600 hover:text-blue-500'
                >
                  <div className='flex items-start truncate w-5/6'>
                    {clip?.campaign?.title}
                  </div>
                </Link>
              </div>
            </div>
          )}

          {type === 'creator' && (
            <div>
              <dt className='text-sm font-medium text-gray-500'>Brand</dt>
              <div className='mt-1 text-sm text-gray-900'>
                <Link
                  to={CreatorRoutes.collaborationViewBriefDetails
                    .replace(':id', `${clip.creator.id}`)
                    .replace(':uuid', `${clip.collaboration.uuid}`)}
                  className='mr-1 text-sm font-semibold text-blue-600 hover:text-blue-500'
                >
                  <div className='flex items-start truncate w-5/6'>
                    {clip.campaign.product.name}
                  </div>
                </Link>
              </div>
            </div>
          )}

          <div>
            <dt className='text-sm font-medium text-gray-500 flex justify-end'>
              Collaboration
            </dt>
            <div className='mt-1 text-sm text-gray-900 flex justify-end  whitespace-nowrap'>
              {clip?.collaboration?.status !==
                CollaborationStatus.contentUploaded && (
                <CollaborationStatusComponent
                  status={clip.collaboration.status}
                  onProductShippingClick={() => {
                    if (clip.collaboration.productTrackingLink) {
                      window.open(
                        clip.collaboration.productTrackingLink,
                        '_blank',
                      )
                    }
                  }}
                />
              )}
              {clip?.collaboration?.status ===
                CollaborationStatus.contentUploaded &&
                (type === 'brand' && clip.type === ClipType.firstVersion ? (
                  <Button
                    title='Approve'
                    type='button'
                    styleType={ButtonStyle.fill}
                    size={ButtonSize.small}
                    colour={ButtonColor.indigo}
                    onClick={() => {
                      setIsAcceptContentModalOpen(true)
                    }}
                  />
                ) : (
                  <CollaborationStatusComponent
                    status={clip.collaboration.status}
                    onProductShippingClick={() => {
                      if (clip.collaboration.productTrackingLink) {
                        window.open(
                          clip.collaboration.productTrackingLink,
                          '_blank',
                        )
                      }
                    }}
                  />
                ))}
            </div>
          </div>
        </div>
      </dl>

      {isAcceptContentModalOpen && (
        <AcceptContentModal
          collaboration={clip.collaboration}
          isOpen={isAcceptContentModalOpen}
          setIsOpen={setIsAcceptContentModalOpen}
        />
      )}
    </>
  )
}

export const FooterClip = ({ clip, type, className = '' }) => {
  const [isRequestEditsModalOpen, setIsRequestEditsModalOpen] = useState(false)

  return (
    <>
      <dl>
        <div className='grid grid-cols-1 gap-y-2 gap-x-4'>
          <div>
            <dt className='sr-only text-sm font-medium text-gray-500'>
              Clip actions
            </dt>
            {type === 'brand' && (
              <div className='my-1 flex items-center justify-end text-base font-medium text-gray-900 gap-x-2'>
                {!clip?.collaboration?.editsRequestMessage &&
                  clip?.collaboration?.status ===
                    CollaborationStatus.contentUploaded &&
                  clip?.type === ClipType.firstVersion && (
                    <Button
                      title='Request Edits'
                      type='button'
                      colour={ButtonColor.white}
                      size={ButtonSize.small}
                      onClick={() => {
                        setIsRequestEditsModalOpen(true)
                      }}
                      className='!border !border-gray-900 shadow-xl rounded-full'
                    />
                  )}
                {clip?.collaboration?.editsRequestMessage && (
                  <Badge
                    type={BadgeType.sky}
                    text='Edit Requested'
                    size={BadgeSize.small}
                    className='!mr-0'
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </dl>
      {isRequestEditsModalOpen && (
        <RequestEditsModal
          collaboration={clip.collaboration}
          isOpen={isRequestEditsModalOpen}
          setIsOpen={setIsRequestEditsModalOpen}
        />
      )}
    </>
  )
}

export const ClipItem: React.FC<ClipsGridProps> = ({ clip, type }) => {
  const EditsRequestMessageAcordion = ({ editsRequestMessage }) => {
    return (
      <div className='max-w-sm divide-y text-xs text-slate-700'>
        <details className='group'>
          <summary className='flex items-center gap-2 marker:content-none hover:cursor-pointer rounded-full'>
            <Icon
              type={IconType.chevronRight}
              className='w-4 h-4 transition group-open:rotate-90'
              aria-hidden='true'
            />
            {/* <span className=''>View Edits Request Message:</span> */}
            <span className='truncate w-1/2'>{editsRequestMessage}</span>
          </summary>

          <article className='px-2 py-2'>
            <p>{editsRequestMessage}</p>
          </article>
        </details>
      </div>
    )
  }

  const downloadImageVideo = async () => {
    try {
      const response = await axios({
        // Access to XMLHttpRequest at ‘...' from origin 'http://localhost:3000' has been blocked by CORS policy:
        // No 'Access-Control-Allow-Origin' header is present on the requested resource.
        url: clip?.video?.url,
        method: 'GET',
        headers: {
          'Content-Type': 'video/*,image/*',
        },
        responseType: 'blob', // important
      })

      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${clip?.video?.url}`)
      document.body.appendChild(link)
      link.click()

      // Clean up and remove the link
      link.parentNode.removeChild(link)

      trackEvent(AnalyticsEvent.brandDownloadClip, {
        brandId: clip.campaign.brand.id,
        clipId: clip.id,
      })
    } catch (error) {
      console.log(error.message)
    }
  }

  return (
    <div key={clip.id} className='group relative'>
      <HeaderClip type={type} clip={clip} />
      <div className='relative isolate flex flex-col justify-end shadow-sm shadow-black/20 dark:shadow-white/20 overflow-hidden rounded-2xl bg-gray-900 aspect-[0.7]'>
        <VideoPlayer
          src={clip.video.url}
          poster={clip?.thumbnail?.url}
          className='absolute inset-0 -z-10 h-full w-full object-cover'
          controls
        />
        <div className='absolute left-2 top-2 gap-y-1 overflow-hidden text-sm leading-6 text-white font-bold bg-black/30 px-2 rounded-lg'>
          <Link
            to={BrandRoutes.collaborationViewProfile
              .replace(':id', `${clip.collaboration.campaign.brand.id}`)
              .replace(':uuid', clip.collaboration.uuid)}
          >
            <div className='flex gap-x-1 items-center'>
              <Avatar
                type='user'
                size='xxs'
                src={clip.creator.avatar?.url}
                alt={clip.creator?.firstName}
              />
              <div className=''>
                {clip.creator?.firstName}
                <div className='text-xs pb-1'>
                  {moment(clip.createdAt).format('ll')}
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className='absolute right-2 top-2 gap-y-1 overflow-hidden text-sm leading-6 text-white'>
          <button
            onClick={downloadImageVideo}
            className='rounded-full bg-gray-100 p-1 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-200'
          >
            <Icon type={IconType.download} className='h-5 w-5 text-primary' />
          </button>
        </div>
      </div>
      {/* <button
        onClick={downloadImageVideo}
        className='absolute right-2 text-gray-700 hover:text-gray-600 focus:outline-none'
      >
        <Icon type={IconType.download} className='h-5 w-5 text-primary' />
      </button> */}
      <FooterClip type={type} clip={clip} />
    </div>
  )
}
