import { useMutation } from '@apollo/client'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import validator from 'validator'
import { Button, TextField } from '../../../components'
import { UPDATE_BRAND } from '../../../gql'
import { Brand, BrandOnboardingStep, BrandType } from '../../../gql/types'
import { AnalyticsEvent, trackEvent } from '../../../utils/analytics'
import { cleanUrl } from '../../../utils/helpers'
import { Bullets } from '../components/Bullets'
import { Steps } from '../components/Steps'

export type EditBrandWebsiteFormData = {
  website: string
}

interface Props {
  brand: Brand
}

const SetBrandWebsite = ({ brand }: Props) => {
  const [updateBrand, { loading }] = useMutation(UPDATE_BRAND)

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      website: brand?.website ? brand?.website?.substring(8) : '',
    },
  })

  useEffect(() => {
    trackEvent(AnalyticsEvent.viewBrandsOnboardingSetWebsite)
  }, [])

  const onSubmit = async (data: EditBrandWebsiteFormData) => {
    const website = cleanUrl(data.website)

    try {
      await updateBrand({
        variables: {
          id: brand.id,
          input: {
            website,
            onboarding: BrandOnboardingStep.setDetails,
          },
        },
      })
    } catch (error) {
      console.log('@error SetBrandWebsite', error.message)
    }
  }

  return (
    <div className='min-h-screen flex flex-col'>
      <div className='flex-grow'>
        <Steps brand={brand} stepCurrent={BrandOnboardingStep.setWebsite} />

        <div className='min-h-full flex flex-col justify-center pt-4 px-4 md:mt-8 lg:px-8 md:mt-20'>
          <div className='sm:mx-auto sm:w-full sm:max-w-2xl'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='md:shadow rounded-t-md sm:overflow-hidden'>
                <div className='py-5 md:bg-white space-y-6 sm:p-6'>
                  <div className='mx-auto'>
                    <h2 className='mt-0 md:text-center text-3xl font-extrabold text-gray-900'>
                      {brand.type === BrandType.brand
                        ? `Brand details`
                        : `Onboard one of your brands`}
                    </h2>
                    <p className='mt-2 md:text-center text-sm text-gray-600'>
                      {brand.type === BrandType.brand
                        ? `We'll try to automatically extract essential info about
                      your brand.`
                        : `Add the website for your first brand to start creating a brief`}
                    </p>
                  </div>

                  <div className='grid gap-6'>
                    <div className='col-span-3 sm:col-span-2'>
                      <TextField
                        error={errors.website?.message}
                        label='Website'
                        placeholder='www.example.com'
                        className={'rounded-none rounded-r-md'}
                        leftElement={
                          <span className='inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm'>
                            https://
                          </span>
                        }
                        {...register('website', {
                          required: `Please set a website for your brand.`,
                          maxLength: {
                            value: 100,
                            message: `Your brand website cannot have more than 100 characters.`,
                          },
                          validate: {
                            value: (website) => {
                              if (validator.isURL(website)) {
                                return true
                              }

                              return 'Invalid website.'
                            },
                          },
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='md:shadow rounded-b-md py-3 md:bg-gray-50 text-right sm:px-6'>
                <Button
                  title='Continue'
                  type='submit'
                  loading={loading}
                  disabled={loading}
                />
              </div>
            </form>
          </div>
        </div>
      </div>

      <Bullets brand={brand} stepCurrent={BrandOnboardingStep.setWebsite} />
    </div>
  )
}

export { SetBrandWebsite }
