import { Campaign, Creator, ProductType } from '../../../../gql/types'
import { Avatar } from '../../../../elements'
import {
  Badge,
  BadgeType,
  BadgeSize,
  TooltipInfo,
} from '../../../../components'
import { GridButtons } from '../GridButtons'
import { isCampaignPremium } from '../../../../utils/hooks'
import { mapCurrencyToSign } from '../../../../utils/mappers'
import { useState } from 'react'
import { PricingData, formatPriceInCents } from '../../../../utils/PricingUtil'
import { CampaignSlider } from '../../../components/CampaignSlider'
import { ApplyToCampaignModal } from '../ApplyToCampaignModal'
import {
  modelTypeLocalizedString,
  videoDurationLocalizedString,
  videoTypeLocalizedString,
  voiceTypeLocalizedString,
} from '../../../../utils/GqlStrings'

interface IProps {
  creator: Creator
  campaign: Campaign
  invitationId?: number | null
  pricing: PricingData
  onAppliedToCampaign: () => void
}

const GridItem = ({
  creator,
  campaign,
  invitationId,
  pricing,
  onAppliedToCampaign,
}: IProps) => {
  const { totalCreatorEarningsCents, currency } = campaign
  const [isCampaignSliderOpen, setIsCampaignSliderOpen] = useState(false)
  const [isApplyToCampaignModalOpen, setIsApplyToCampaignModalOpen] =
    useState(false)

  // const getCreatorEarningText = () => {
  //   return formattedVideoCreatorEarnings({
  //     quality: campaign.quality,
  //     currency: campaign.currency,
  //     videoDuration: campaign.videoDuration,
  //     pricing: pricing,
  //   })
  // }

  // const getHookCreatorEarningText = () => {
  //   return formattedHookCreatorEarnings({
  //     quality: campaign.quality,
  //     currency: campaign.currency,
  //     videoDuration: campaign.videoDuration,
  //     pricing: pricing,
  //   })
  // }

  // const getTotalCreatorEarningsText = () => {
  //   const videoPricing = getVideoPricing({
  //     currency: campaign.currency,
  //     videoDuration: campaign.videoDuration,
  //     pricing,
  //   })

  //   let totalEarnings = getPriceByQuality(campaign.quality, videoPricing?.price)
  //     .creatorEarningsCents
  //   totalEarnings +=
  //     campaign.hooks?.length * videoPricing?.hook?.creatorEarningsCents

  //   return formatPriceInCents({
  //     priceInCents: totalEarnings,
  //     currency: campaign.currency,
  //   })
  // }
  const getTotalCreatorEarningsText = () => {
    return formatPriceInCents({
      priceInCents: totalCreatorEarningsCents,
      currency: currency,
    })
  }

  return (
    <div
      key={campaign.id}
      className='col-span-1 divide-y divide-gray-200 rounded-lg bg-white border border-gray-200 shadow mx-2'
    >
      <div
        className='cursor-pointer flex w-full items-center justify-between align-top space-x-4 p-4'
        onClick={() => setIsCampaignSliderOpen(true)}
      >
        <div className='flex flex-col'>
          <div className='flex text-gray-900 max-w-[150px] xs:max-w-[250px] sm:max-w-[400px] overflow-hidden truncate'>
            {campaign.product.name}
          </div>
          <div className='mt-1 mb-0 flex flex-shrink-0 text-sm text-gray-500'>
            {campaign.product.price === 0 &&
              campaign.product.type === ProductType.digital && (
                <span>digital</span>
              )}
            {campaign.product.price > 0 && (
              <span>
                worth {mapCurrencyToSign(campaign.product.currency)}{' '}
                {campaign.product.price}
              </span>
            )}
            {isCampaignPremium(campaign) && (
              <Badge
                type={BadgeType.amber}
                text={`premium`}
                size={BadgeSize.small}
              />
            )}
          </div>
        </div>

        <Avatar
          type='product'
          src={campaign.product.photo?.url}
          size='sm'
          alt={campaign.product.name}
        />
      </div>
      <dl className='space-y-3 p-4'>
        <div className='flex flex-col text-left'>
          <dt className='text-sm font-medium text-gray-500'>Earnings</dt>
          <dd className='mt-1 text-sm text-gray-900'>
            <div>{getTotalCreatorEarningsText()} + free product</div>
            {campaign.hooks?.length > 0 && (
              <div className='mt-1 mb-0'>
                <Badge
                  type={BadgeType.indigo}
                  iconPosition='left'
                  text={
                    <TooltipInfo
                      valueKey='hooks-badge'
                      content={`${campaign.hooks?.length} variation${campaign.hooks?.length > 1 ? 's' : ''} required`}
                      tooltipText='Each variation is an altered version of the original video, featuring a unique hook or call-to-action. The duration of each variation is up to 5 seconds.'
                    />
                  }
                  size={BadgeSize.small}
                  className='!mr-0'
                />
              </div>
            )}
            {campaign.addons?.length > 0 && (
              <div className='mt-1 mb-0'>
                <Badge
                  type={BadgeType.purple}
                  iconPosition='left'
                  text={
                    <TooltipInfo
                      valueKey='addons-badge'
                      content={`${campaign.addons?.length} addon${campaign.addons?.length > 1 ? 's' : ''} required`}
                      tooltipText='Each addon is an altered version of the original video with captions, music or additional elements.'
                    />
                  }
                  size={BadgeSize.small}
                  className='!mr-0'
                />
              </div>
            )}
          </dd>
        </div>
        <div className='flex flex-col text-left'>
          <dt className='text-sm font-medium text-gray-500'>
            Campaign Details
          </dt>
          <dd className='mt-1 flex flex-col text-sm text-gray-900'>
            <Badge
              type={BadgeType.gray}
              iconPosition='left'
              text={videoDurationLocalizedString(campaign.videoDuration)}
              size={BadgeSize.medium}
              className='my-1'
            />
            {campaign.voiceType && (
              <Badge
                type={BadgeType.gray}
                iconPosition='left'
                text={voiceTypeLocalizedString(campaign.voiceType)}
                size={BadgeSize.medium}
                className='my-1'
              />
            )}
            <Badge
              type={BadgeType.gray}
              iconPosition='left'
              text={videoTypeLocalizedString(campaign.videoType)}
              size={BadgeSize.medium}
              className='my-1'
            />
            <Badge
              type={BadgeType.gray}
              iconPosition='left'
              text={modelTypeLocalizedString(campaign.modelType)}
              size={BadgeSize.medium}
              className='my-1'
            />
          </dd>
        </div>
      </dl>
      <GridButtons
        setIsCampaignSliderOpen={setIsCampaignSliderOpen}
        setIsApplyToCampaignModalOpen={setIsApplyToCampaignModalOpen}
      />

      <CampaignSlider
        open={isCampaignSliderOpen}
        setOpen={setIsCampaignSliderOpen}
        campaign={campaign}
        openApplyToCampaignModal={() => setIsApplyToCampaignModalOpen(true)}
      />
      <ApplyToCampaignModal
        creator={creator}
        campaign={campaign}
        invitationId={invitationId}
        isOpen={isApplyToCampaignModalOpen}
        setIsOpen={setIsApplyToCampaignModalOpen}
        onAppliedToCampaign={onAppliedToCampaign}
      />
    </div>
  )
}

export { GridItem }
