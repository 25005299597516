import { gql } from '@apollo/client'
import { PaginationFragment } from './common'

export const InspirationFragment = gql`
  fragment InspirationFragment on Inspiration {
    id
    type
    externalUrl
    brandName
    videoType
    source
    productType
    model
    gender
    ageGroups
    ethnicity
    industry
    duration
    isTextOver
    isVoiceOver
    tags
    isAllVisible
    createdAt
    updatedAt

    file {
      url
      name
    }

    thumbnail {
      url
    }
  }
`

export const GET_INSPIRATIONS = gql`
  query GetInspirations($options: GetInspirationsOptions!) {
    getInspirations(options: $options) {
      items {
        ...InspirationFragment
        brand {
          id
        }
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${InspirationFragment}
  ${PaginationFragment}
`

export const GET_INSPIRATIONS_BY_BRAND_ID = gql`
  query GetInspirationsByBrandId(
    $brandId: Int!
    $options: GetInspirationsOptions!
  ) {
    getInspirationsByBrandId(brandId: $brandId, options: $options) {
      items {
        ...InspirationFragment
        brand {
          id
        }
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${InspirationFragment}
  ${PaginationFragment}
`

export const GET_INSPIRATIONS_BY_CAMPAIGN_ID = gql`
  query GetInspirationsByCampaignId(
    $campaignId: Int!
    $options: GetInspirationsOptions!
  ) {
    getInspirationsByCampaignId(campaignId: $campaignId, options: $options) {
      items {
        ...InspirationFragment
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${InspirationFragment}
  ${PaginationFragment}
`

export const ADD_CAMPAIGN = gql`
  mutation AddCampaign($inspirationId: Int!, $campaignId: Int!) {
    addCampaign(inspirationId: $inspirationId, campaignId: $campaignId) {
      ...InspirationFragment
    }
  }
  ${InspirationFragment}
`

export const REMOVE_CAMPAIGN = gql`
  mutation RemoveCampaign($inspirationId: Int!, $campaignId: Int!) {
    removeCampaign(inspirationId: $inspirationId, campaignId: $campaignId) {
      ...InspirationFragment
    }
  }
  ${InspirationFragment}
`

export const CREATE_INSPIRATION = gql`
  mutation CreateInspiration($input: CreateInspirationInput!) {
    createInspiration(input: $input) {
      ...InspirationFragment
    }
  }
  ${InspirationFragment}
`

export const GET_INSPIRATION_UPLOAD_URL = gql`
  mutation GetInspirationUploadUrl($mime: String!) {
    getInspirationUploadUrl(mime: $mime) {
      inspirationUploadUrl
      fileName
    }
  }
`
