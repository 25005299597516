import { Navigate } from 'react-router-dom'
import { CreatorOnboardingStep, CreatorStatus, User } from '../../gql/types'
import { CreatorRoutes } from '../CreatorRoutes'
import { SetInterests } from './SetInterests'
import { SetIntroVideo } from './SetIntroVideo'
import { SetProfile } from './SetProfile'
import { SetShippingAddress } from './SetShippingAddress'
import { ApplicationCompleted } from './ApplicationCompleted'
import { SetAbout } from './SetAbout'
import { SetAgreeTerms } from './SetAgreeTerms'
import { SetPaypalEmailAddress } from './SetPaypalEmailAddress'
import { ApplicationRejected } from './ApplicationRejected'
import { SetPortfolio } from './SetPortofolio'

interface Props {
  user: User
}

const Onboarding = ({ user }: Props) => {
  const creator = user.creator
  const { onboarding, status } = creator

  if (
    onboarding === CreatorOnboardingStep.completed &&
    creator.status !== CreatorStatus.pending &&
    creator.status !== CreatorStatus.rejected
  ) {
    return (
      <Navigate
        to={CreatorRoutes.opportunities.replace(':id', `${creator.id}`)}
      />
    )
  }

  return (
    <div>
      {onboarding === CreatorOnboardingStep.setProfile && (
        <SetProfile creator={creator} />
      )}
      {onboarding === CreatorOnboardingStep.setAbout && (
        <SetAbout creator={creator} user={user} />
      )}
      {onboarding === CreatorOnboardingStep.setInterests && (
        <SetInterests creator={creator} />
      )}
      {onboarding === CreatorOnboardingStep.setIntroVideo && (
        <SetIntroVideo creator={creator} />
      )}
      {onboarding === CreatorOnboardingStep.setShippingAddress && (
        <SetShippingAddress creator={creator} />
      )}
      {onboarding === CreatorOnboardingStep.agreeTerms && (
        <SetAgreeTerms creator={creator} />
      )}
      {onboarding === CreatorOnboardingStep.setPortfolio && (
        <SetPortfolio creator={creator} />
      )}
      {onboarding === CreatorOnboardingStep.setPaypalEmailAddress && (
        <SetPaypalEmailAddress creator={creator} />
      )}
      {status === CreatorStatus.pending && (
        <ApplicationCompleted creator={creator} />
      )}
      {status === CreatorStatus.rejected && (
        <ApplicationRejected creator={creator} />
      )}
    </div>
  )
}

export { Onboarding }
