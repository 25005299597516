import { useMutation } from '@apollo/client'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
  Button,
  ButtonColor,
  NotificationType,
  TextArea,
} from '../../../components'
import { UPDATE_BRAND, UPDATE_CAMPAIGN } from '../../../gql'
import { Brand, BrandOnboardingStep, Campaign } from '../../../gql/types'
import { AnalyticsEvent, trackEvent } from '../../../utils/analytics'
import { Bullets } from '../components/Bullets'
import { Steps } from '../components/Steps'
import { useNotification } from '../../../utils/hooks'

type EditFormData = {
  requirements: string
}

interface Props {
  brand: Brand
  campaign: Campaign
}

const SetRequirements = ({ brand, campaign }: Props) => {
  const { addNotification } = useNotification()

  const [updateBrand, { loading: loadingUpdateBrand }] =
    useMutation(UPDATE_BRAND)
  const [updateCampaign, { loading: loadingUpdateCampaign }] =
    useMutation(UPDATE_CAMPAIGN)

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      requirements: campaign?.requirements,
    },
  })

  useEffect(() => {
    trackEvent(AnalyticsEvent.viewBrandsOnboardingSetRequirements)
  }, [])

  const goBack = async () => {
    try {
      await updateBrand({
        variables: {
          id: brand.id,
          input: {
            onboarding: BrandOnboardingStep.setVideoDuration,
          },
        },
      })
    } catch (error) {
      addNotification(error.message, NotificationType.error)
    }
  }

  const onSubmit = async ({ requirements }: EditFormData) => {
    try {
      await Promise.all([
        updateCampaign({
          variables: {
            id: campaign.id,
            input: {
              requirements,
            },
          },
        }),
        updateBrand({
          variables: {
            id: brand.id,
            input: {
              onboarding: BrandOnboardingStep.setAddons,
            },
          },
        }),
      ])
    } catch (error) {
      console.log('@error SetRequirements', error.message)
      if (error.message) {
        setError('requirements', {
          type: 'custom',
          message: error.message,
        })
        return
      }

      addNotification(error.message, NotificationType.error)
    }
  }

  return (
    <div className='min-h-screen flex flex-col pb-14'>
      <div className='flex-grow'>
        <Steps
          brand={brand}
          stepCurrent={BrandOnboardingStep.setRequirements}
        />

        <div className='min-h-full flex flex-col justify-center pt-4 px-4 md:mt-8 lg:px-8'>
          <div className='sm:mx-auto sm:w-full sm:max-w-2xl'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='md:shadow rounded-t-md sm:overflow-hidden'>
                <div className='py-5 md:bg-white space-y-6 sm:p-6'>
                  <div className='sm:mx-auto'>
                    <h2 className='mt-0 text-3xl font-extrabold text-gray-900'>
                      Mentions & Scenes
                    </h2>
                    <p className='mt-2 text-sm text-gray-600'>
                      Consider mentioning campaign goals, specific scenes, or
                      other essential aspects for your marketing video.
                    </p>
                  </div>

                  <div className='grid gap-6'>
                    <div className='col-span-3 sm:col-span-2'>
                      <TextArea
                        error={errors.requirements?.message}
                        label='Talking points'
                        placeholder='Mentions, scenes or other requirements'
                        {...register('requirements', {
                          maxLength: {
                            value: 10000,
                            message: `Your requirements text is too long`,
                          },
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='flex justify-between md:shadow rounded-b-md py-3 md:bg-gray-50 sm:px-6'>
                <Button
                  title='Go back'
                  type='button'
                  colour={ButtonColor.white}
                  loading={loadingUpdateBrand}
                  disabled={loadingUpdateBrand}
                  onClick={goBack}
                />
                <Button
                  title='Continue'
                  type='submit'
                  loading={loadingUpdateBrand || loadingUpdateCampaign}
                  disabled={loadingUpdateBrand || loadingUpdateCampaign}
                />
              </div>
            </form>
          </div>
        </div>
      </div>

      <Bullets
        brand={brand}
        stepCurrent={BrandOnboardingStep.setRequirements}
      />
    </div>
  )
}

export { SetRequirements }
