import { gql } from '@apollo/client'
import { PaginationFragment } from './common'
import { Brand } from './types'

export const BrandFragment = gql`
  fragment BrandFragment on Brand {
    id
    name
    email
    phoneNumber
    onboarding
    website
    description
    businessType
    logo {
      url
      name
    }
    currency
    creditCents
    countryCode
    metadata {
      ratingsScore
      ratingsCount
    }
    unreadMessagesCount
  }
`

export const CREATE_BRAND = gql`
  mutation CreateBrand($userId: Int!, $input: CreateBrandInput!) {
    createBrand(userId: $userId, input: $input) {
      ...BrandFragment
    }
  }
  ${BrandFragment}
`

export const UPDATE_BRAND = gql`
  mutation UpdateBrand($id: Int!, $input: UpdateBrandInput!) {
    updateBrand(id: $id, input: $input) {
      ...BrandFragment
    }
  }
  ${BrandFragment}
`

export const GET_BRAND_BY_ID = gql`
  query GetBrandById($id: Int!) {
    getBrandById(id: $id) {
      ...BrandFragment
    }
  }
  ${BrandFragment}
`

export const GET_DEFAULT_BRAND = gql`
  query GetDefaultBrand($userId: Int!, $brandId: Int) {
    getDefaultBrand(userId: $userId, brandId: $brandId) {
      ...BrandFragment
    }
  }
  ${BrandFragment}
`

export const GET_USER_BRANDS = gql`
  query GetUserBrands($options: GetBrandsOptions!) {
    getUserBrands(options: $options) {
      items {
        ...BrandFragment
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${BrandFragment}
  ${PaginationFragment}
`

export const GET_WEBSITE_META = gql`
  mutation GetWebsiteMeta($website: String!) {
    getWebsiteMeta(website: $website) {
      website
      title
      description
      imageUrl
    }
  }
`

export const GET_BRAND_LOGO_UPLOAD_URL = gql`
  mutation GetBrandLogoUploadUrl($mime: String!, $brandId: Int) {
    getBrandLogoUploadUrl(mime: $mime, brandId: $brandId) {
      uploadUrl
      fileName
    }
  }
`

export const UPLOAD_BRAND_LOGO_FROM_URL = gql`
  mutation UploadBrandLogoFromUrl($logoUrl: String!, $brandId: Int) {
    uploadBrandLogoFromUrl(logoUrl: $logoUrl, brandId: $brandId) {
      fileName
    }
  }
`

export interface QueryGetBrandByIdResponse {
  getBrandById: Brand
}

export interface QueryGetDefaultBrandResponse {
  getDefaultBrand: Brand
}
