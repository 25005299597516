import { FunctionComponent } from 'react'
import { Avatar } from '../../../elements'
import moment from 'moment'

interface IDefaultProps {
  isActive: boolean
  type: 'user' | 'brand'
}

interface IProps extends Partial<IDefaultProps> {
  name: string
  avatar: any
  unreadMessages: number
  lastMessageText: string
  lastMessageSentAt: string
}

const defaultProps: IDefaultProps = {
  isActive: false,
  type: 'user',
}

const ChatMember: FunctionComponent<IProps> = ({
  name,
  avatar,
  unreadMessages,
  lastMessageText,
  lastMessageSentAt,
  isActive,
  type,
}) => {
  return (
    <div
      className={`font-inter flex flex-row px-4 py-4  items-start gap-3 ${
        isActive ? 'bg-gray-50' : ''
      }  cursor-pointer`}
    >
      <div className='h-12 w-12 flex-shrink-0 relative'>
        <Avatar
          type={type}
          size='sm'
          src={avatar?.url}
          alt={name}
          isSelected={isActive}
        />
        {unreadMessages > 0 && (
          <span className='absolute -top-1 -right-1 h-5 w-5 rounded-full bg-red-400 ring-2 ring-white text-xs text-white flex justify-center items-center items'>
            {unreadMessages}
          </span>
        )}
      </div>
      <div className='max-w-[260px] flex-none flex flex-col items-start gap-1 order-1 flex-grow'>
        <div className='flex w-full flex-row items-center p-0 gap-1.5 justify-between	'>
          <div className='font-bold text-sm text-gray-900 max-w-[140px] overflow-hidden whitespace-nowrap'>
            {name}
          </div>
          {lastMessageSentAt && (
            <div className='font-normal text-xs text-gray-400 order-1 flex-grow-0 flex-none'>
              {moment(lastMessageSentAt).format('ll')}
            </div>
          )}
        </div>
        <div className='flex flex-row justify-between items-center w-full'></div>

        <div className='font-normal text-xs  text-gray-500 flex-none order-2 grow-0 self-stretch truncate'>
          {lastMessageText}
        </div>
      </div>
    </div>
  )
}

ChatMember.defaultProps = defaultProps

export { ChatMember }
