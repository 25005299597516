import { useParams } from 'react-router-dom'
import { Button } from '../../../../components'
import { BrandRoutes } from '../../../BrandRoutes'

const Header = () => {
  const { id } = useParams()

  return (
    <div className='sm:flex sm:items-center -mx-4 sm:-mx-6 md:mx-0 py-4'>
      <div className='sm:flex-auto'>
        <div className='flex flex-row'>
          <h1 className='font-bold text-3xl font-outfit'>Campaigns</h1>
        </div>
      </div>
      <div className='mt-2 sm:ml-4 sm:mt-0 sm:flex-none'>
        <Button
          title='Create Campaign'
          to={BrandRoutes.newCampaign.replace(':id', `${id}`)}
          className='w-full'
        />
      </div>
    </div>
  )
}

export { Header }
