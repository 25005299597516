import { useMutation } from '@apollo/client'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import validator from 'validator'
import {
  Button,
  ButtonColor,
  ButtonSize,
  Icon,
  IconType,
  TextArea,
  TextField,
} from '../../../components'
import {
  GET_BRAND_LOGO_UPLOAD_URL,
  GET_WEBSITE_META,
  UPDATE_BRAND,
  UPLOAD_BRAND_LOGO_FROM_URL,
} from '../../../gql'
import { Brand, BrandOnboardingStep } from '../../../gql/types'
import { AnalyticsEvent, trackEvent } from '../../../utils/analytics'
import { cleanUrl } from '../../../utils/helpers'
import { Bullets } from '../components/Bullets'
import { Steps } from '../components/Steps'
import { LogoUpload } from '../../../components/LogoUpload'

export type EditBrandDetailsFormData = {
  website: string
  name: string
  description: string
  logoFileName: string
}

interface Props {
  brand: Brand
}

const SetBrandDetails = ({ brand }: Props) => {
  const [selectedFile, setSelectedFile] = useState(null)
  const [imageUrl, setImageUrl] = useState(brand.logo?.url)
  const [updateBrand, { loading }] = useMutation(UPDATE_BRAND)

  const [getWebsiteMeta, { loading: getWebsiteMetaLoading }] =
    useMutation(GET_WEBSITE_META)

  const [getBrandLogoUploadUrl, { loading: loadingBrandLogoUploadUrl }] =
    useMutation(GET_BRAND_LOGO_UPLOAD_URL)

  const [uploadBrandLogoFromUrl, { loading: uploadBrandLogoFromUrlLoading }] =
    useMutation(UPLOAD_BRAND_LOGO_FROM_URL)

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
    clearErrors,
    setValue,
  } = useForm({
    defaultValues: {
      website: brand?.website?.substring(8) ?? null,
      name: brand?.name ?? '',
      description: brand?.description ?? null,
      logoFileName: brand?.logo?.name ?? null,
    },
  })

  useEffect(() => {
    trackEvent(AnalyticsEvent.viewBrandsOnboardingSetDetails)
  }, [])

  const updateMeta = async () => {
    if (!watch('website')) {
      setError('website', {
        type: 'custom',
        message: 'Please set a website for your brand.',
      })
      return
    }

    clearErrors('website')

    const { data: dataMeta } = await getWebsiteMeta({
      variables: {
        website: cleanUrl(watch('website')),
      },
    })
    const meta = dataMeta?.getWebsiteMeta

    setValue('website', meta.website)
    setValue('name', meta.title && meta.title !== '' ? meta.title : brand.name)
    if (watch('name') !== '') {
      clearErrors('name')
    }
    setValue('description', meta.description)
    if (watch('description') !== '') {
      clearErrors('description')
    }

    if (!meta.imageUrl) {
      return
    }

    setImageUrl(meta.imageUrl)

    try {
      const { data } = await uploadBrandLogoFromUrl({
        variables: {
          logoUrl: meta.imageUrl,
          brandId: brand.id,
        },
      })

      setValue('logoFileName', data.uploadBrandLogoFromUrl.fileName)
      setSelectedFile(null)
      clearErrors('logoFileName')
    } catch (error) {
      console.log('error uploadBrandLogoFromUrl', error.message)
    }
  }

  // const onUpdateDetails = async (website) => {
  //   try {
  //     await updateBrand({
  //       variables: {
  //         id: brand.id,
  //         input: {
  //           website,
  //         },
  //       },
  //     })
  //     window.location.reload()
  //   } catch (error) {
  //     console.log(
  //       '@error onUpdateDetails for brand from website',
  //       error.message,
  //     )
  //     addNotification(
  //       `Something went wrong. Please try again.`,
  //       NotificationType.error,
  //     )
  //   }
  // }

  const onBrandLogoUpload = async (file: File) => {
    if (!file) {
      return
    }
    setSelectedFile(file)
    clearErrors('logoFileName')

    try {
      const { data } = await getBrandLogoUploadUrl({
        variables: {
          brandId: brand.id,
          mime: file.type,
        },
      })

      await axios.put(data.getBrandLogoUploadUrl.uploadUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
      })

      setValue('logoFileName', data.getBrandLogoUploadUrl.fileName)
    } catch (error) {
      console.log('error onBrandLogoUpload', error.message)
    }
  }

  // const onClickCreate = async () => {
  //   if (watch('logoFileName') === null) {
  //     setError('logoFileName', {
  //       type: 'custom',
  //       message: 'Please set a logo for your brand.',
  //     })
  //     return
  //   }
  //   clearErrors('logoFileName')
  // }

  const onSubmit = async (data: EditBrandDetailsFormData) => {
    const website = cleanUrl(data.website)

    // if (website !== brand.website) {
    //   onUpdateDetails(website)
    //   return
    // }

    try {
      await updateBrand({
        variables: {
          id: brand.id,
          input: {
            website,
            name: data.name ?? brand.name,
            description: data.description,
            logoFileName: data.logoFileName,
            onboarding: BrandOnboardingStep.setProductDetails,
          },
        },
      })
    } catch (error) {
      console.log('@error SetBrandDetails', error.message)
    }
  }

  return (
    <div className='min-h-screen flex flex-col'>
      <div className='flex-grow'>
        <Steps brand={brand} stepCurrent={BrandOnboardingStep.setDetails} />

        <div className='min-h-full flex flex-col justify-center pt-4 px-4 md:mt-8 lg:px-8'>
          <div className='sm:mx-auto sm:w-full sm:max-w-2xl'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='md:shadow rounded-t-md sm:overflow-hidden'>
                <div className='py-5 md:bg-white space-y-6 sm:p-6'>
                  <div className='sm:mx-auto'>
                    <h2 className='mt-0 md:text-center text-3xl font-extrabold text-gray-900'>
                      Brand details
                    </h2>
                    <p className='mt-2 md:text-center text-sm text-gray-600'>
                      This information will be available to creators
                    </p>
                  </div>

                  <div className='grid gap-6'>
                    <div className='col-span-3 sm:col-span-2'>
                      <TextField
                        error={errors.website?.message}
                        label='Website'
                        placeholder='www.example.com'
                        className={'rounded-none'}
                        leftElement={
                          <span className='inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm'>
                            https://
                          </span>
                        }
                        rightElement={
                          <Button
                            title={
                              <Icon
                                type={IconType.check}
                                className='text-white'
                              />
                            }
                            type='button'
                            className='md:text-center text-sm text-gray-600 rounded-none rounded-r-md max-w-20'
                            loading={getWebsiteMetaLoading}
                            size={ButtonSize.medium}
                            colour={ButtonColor.primary}
                            onClick={() => updateMeta()}
                          />
                        }
                        {...register('website', {
                          required: `Please set a website for your brand.`,
                          maxLength: {
                            value: 100,
                            message: `Your brand website cannot have more than 100 characters.`,
                          },
                          validate: {
                            value: (website) => {
                              if (validator.isURL(website)) {
                                return true
                              }

                              return 'Invalid website.'
                            },
                          },
                        })}
                      />
                    </div>
                  </div>

                  <div className='grid gap-6'>
                    <div className='col-span-3 sm:col-span-2'>
                      <LogoUpload
                        title='Logo (optional)'
                        logoUpload={
                          selectedFile
                            ? URL.createObjectURL(selectedFile)
                            : imageUrl
                        }
                        onChange={onBrandLogoUpload}
                      />
                      {/* <div
                        className='relative mt-2 text-sm text-red-600'
                        id='errorLogoUrl'
                      >
                        {errors.logoFileName?.message && (
                          <div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
                            <Icon
                              type={IconType.exclamationCircleFill}
                              className='h-5 w-5 text-red-500'
                              aria-hidden='true'
                            />
                          </div>
                        )}
                      </div> */}
                      {/* {errors.logoFileName?.message && (
                        <p
                          className='mt-2 text-sm text-red-600'
                          id='errorLogoUrl'
                        >
                          Please set a logo.
                        </p>
                      )} */}
                    </div>
                  </div>

                  <div className='grid gap-6'>
                    <div className='col-span-3 sm:col-span-2'>
                      <TextField
                        error={errors.name?.message}
                        label='Brand Name'
                        placeholder='Example: Gym Shark'
                        {...register('name', {
                          required: `Please set a brand name.`,
                          maxLength: {
                            value: 100,
                            message: `Your brand name cannot have more than 100 characters.`,
                          },
                        })}
                      />
                    </div>
                  </div>

                  <div className='grid gap-6'>
                    <div className='col-span-3 sm:col-span-2'>
                      <TextArea
                        error={errors.description?.message}
                        label='Description (optional)'
                        placeholder='Example: Fitness apparel & accessories'
                        {...register('description', {
                          // required: `Please set a brand description.`,
                          maxLength: {
                            value: 10000,
                            message: `Description too long.`,
                          },
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='md:shadow rounded-b-md py-3 md:bg-gray-50 text-right sm:px-6'>
                <Button
                  // title={
                  //   cleanUrl(watch('website')) !== brand.website
                  //     ? 'Update'
                  //     : 'Continue'
                  // }
                  title='Continue'
                  type='submit'
                  loading={
                    loading ||
                    loadingBrandLogoUploadUrl ||
                    uploadBrandLogoFromUrlLoading
                  }
                  disabled={
                    loading ||
                    loadingBrandLogoUploadUrl ||
                    uploadBrandLogoFromUrlLoading
                  }
                  // onClick={onClickCreate}
                />
              </div>
            </form>
          </div>
        </div>
      </div>

      <Bullets brand={brand} stepCurrent={BrandOnboardingStep.setDetails} />
    </div>
  )
}

export { SetBrandDetails }
