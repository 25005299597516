import classnames from 'classnames'
import { FunctionComponent } from 'react'
import {
  AiFillCheckCircle,
  AiFillStar,
  AiOutlineCloudDownload,
  AiOutlineCloudUpload,
  AiOutlineEdit,
  AiOutlineGift,
  AiOutlineInbox,
  AiOutlineInstagram,
  AiOutlineLink,
  AiOutlineLogout,
  AiOutlineMinus,
  AiOutlinePauseCircle,
  AiOutlinePlayCircle,
  AiOutlinePlus,
  AiOutlinePlusCircle,
  AiOutlineShopping,
  AiOutlineStar,
  AiOutlineVideoCameraAdd,
  AiOutlineWarning,
} from 'react-icons/ai'
import {
  MdOutlinePendingActions,
  MdDoneAll,
  MdRemoveDone,
  MdOutlineWorkspacePremium,
  MdOutlinePersonAddAlt,
  MdOutlineSubtitles,
  MdMusicVideo,
  MdFavorite,
  MdOutlineRecordVoiceOver,
  MdOutlineVoiceOverOff,
} from 'react-icons/md'
import {
  BsPerson,
  BsQuestionOctagonFill,
  BsThreeDots,
  BsThreeDotsVertical,
  BsFillSendFill,
} from 'react-icons/bs'
import { CiPower, CiSettings, CiVideoOn } from 'react-icons/ci'
import {
  FaPenFancy,
  FaRegHandshake,
  FaShippingFast,
  FaTiktok,
} from 'react-icons/fa'
import { VscVerifiedFilled } from 'react-icons/vsc'
import { FiExternalLink, FiLayers, FiPackage, FiThumbsUp } from 'react-icons/fi'
import { BiBullseye, BiDuplicate, BiLoader, BiStoreAlt } from 'react-icons/bi'
import { SlRefresh } from 'react-icons/sl'
import {
  HiArrowDown,
  HiArrowUp,
  HiBars3,
  HiChevronDown,
  HiChevronLeft,
  HiChevronRight,
  HiChevronUp,
  HiChevronUpDown,
  HiExclamationCircle,
  HiOutlineAcademicCap,
  HiOutlineBell,
  HiOutlineBolt,
  HiOutlineBriefcase,
  HiOutlineCamera,
  HiOutlineChartBar,
  HiOutlineChatBubbleBottomCenter,
  HiOutlineChatBubbleLeftRight,
  HiOutlineCheck,
  HiOutlineCheckCircle,
  HiOutlineClock,
  HiOutlineComputerDesktop,
  HiOutlineCreditCard,
  HiOutlineCube,
  HiOutlineEnvelope,
  HiOutlineEye,
  HiOutlineFilm,
  HiOutlineFunnel,
  HiOutlineHandRaised,
  HiOutlineHeart,
  HiOutlineHome,
  HiOutlineInformationCircle,
  HiOutlineLightBulb,
  HiOutlineMagnifyingGlass,
  HiOutlineMegaphone,
  HiOutlinePaperAirplane,
  HiOutlinePencil,
  HiOutlinePhone,
  HiOutlineQuestionMarkCircle,
  HiOutlineRectangleStack,
  HiOutlineSparkles,
  HiOutlineTrash,
  HiOutlineUserCircle,
  HiOutlineXCircle,
  HiOutlineXMark,
} from 'react-icons/hi2'
import { RiCameraLensLine, RiPenNibLine, RiUserLine } from 'react-icons/ri'
import { TbListDetails, TbMoneybag, TbTemplate } from 'react-icons/tb'

// import styles from './styles.module.scss'

export enum IconType {
  // Mapped
  home = 'home',
  info = 'info',
  thumbsUp = 'thumbsUp',
  camera = 'camera',
  cameraLens = 'cameraLens',
  card = 'card',
  userCircle = 'userCircle',
  user = 'user',
  premium = 'premium',
  pencil = 'pencil',
  paperPlaneRight = 'paperPlaneRight',
  dotsThreeHorizontal = 'dotsThreeHorizontal',
  dotsThreeVertical = 'dotsThreeVertical',
  download = 'download',
  upload = 'upload',
  loading = 'loading',
  instagram = 'instagram',
  tiktok = 'tiktok',
  warning = 'warning',
  trash = 'trash',
  heart = 'heart',
  heartFilled = 'heartFilled',
  gift = 'gift',
  handshake = 'handshake',
  bell = 'bell',
  chevronDown = 'chevronDown',
  chevronLeft = 'chevronLeft',
  chevronRight = 'chevronRight',
  chevronUp = 'chevronUp',
  chevronUpDown = 'chevronUpDown',
  settings = 'settings',
  power = 'power',
  starFilled = 'starFilled',
  starOutlined = 'starOutlined',
  chat = 'chat',
  clock = 'clock',
  checkCircle = 'checkCircle',
  fillCheckCircle = 'fillCheckCircle',
  check = 'check',
  xCircle = 'xCircle',
  x = 'x',
  chartBars = 'chartBars',
  rectangleStack = 'rectangleStack',
  inbox = 'inbox',
  inboxStack = 'inboxStack',
  menu = 'menu',
  funnel = 'funnel',
  search = 'search',
  question = 'question',
  eye = 'eye',
  link = 'link',
  externalLink = 'externalLink',
  bullsEye = 'bullsEye',
  shipping = 'shipping',
  pending = 'pending',
  plus = 'plus',
  plusCircle = 'plusCircle',
  minus = 'minus',
  layers = 'layers',
  lightbulb = 'lightbulb',
  computer = 'computer',
  sparkles = 'sparkles',
  cube = 'cube',
  package = 'package',
  product = 'product',
  refresh = 'refresh',
  handRaised = 'handRaised',
  framePerson = 'framePerson',
  filmThree = 'filmThree',
  megaphone = 'megaphone',
  academic = 'academic',
  exclamationCircleFill = 'exclamationCircleFill',
  arrowDown = 'arrowDown',
  arrowUp = 'arrowUp',
  phone = 'phone',
  envelope = 'envelope',
  bolt = 'bolt',
  pen = 'pen',
  penNib = 'penNib',
  edit = 'edit',
  verified = 'verified',
  store = 'store',
  briefcase = 'briefcase',
  addVideo = 'addVideo',
  doneAll = 'doneAll',
  send = 'send',
  removeDoneAll = 'removeDoneAll',
  logout = 'logout',
  pause = 'pause',
  play = 'play',
  delivery = 'delivery',
  moneyBag = 'moneyBag',
  duplicate = 'duplicate',
  personAdd = 'personAdd',
  details = 'details',
  videoCamera = 'videoCamera',
  subtitles = 'subtitles',
  music = 'music',
  template = 'template',
  voiceOver = 'voiceOver',
  noVoice = 'noVoice',
}

interface IDefaultProps {
  type: IconType
  style: 'default' | 'circle' | 'square'
  highlighted: boolean
  className: string
  onClick(): void
}

interface IProps extends Partial<IDefaultProps> {}

const defaultProps: IDefaultProps = {
  type: undefined,
  style: 'default',
  highlighted: false,
  className: undefined,
  onClick: null,
}

const Icon: FunctionComponent<IProps> = ({
  type,
  style,
  highlighted,
  className,
  onClick,
}) => {
  let icon = {
    name: null,
  }

  switch (type) {
    case IconType.home:
      icon.name = HiOutlineHome
      break
    case IconType.info:
      icon.name = HiOutlineInformationCircle
      break
    case IconType.thumbsUp:
      icon.name = FiThumbsUp
      break
    case IconType.camera:
      icon.name = HiOutlineCamera
      break
    case IconType.cameraLens:
      icon.name = RiCameraLensLine
      break
    case IconType.card:
      icon.name = HiOutlineCreditCard
      break
    case IconType.userCircle:
      icon.name = HiOutlineUserCircle
      break
    case IconType.user:
      icon.name = RiUserLine
      break
    case IconType.premium:
      icon.name = MdOutlineWorkspacePremium
      break
    case IconType.pencil:
      icon.name = HiOutlinePencil
      break
    case IconType.paperPlaneRight:
      icon.name = HiOutlinePaperAirplane
      break
    case IconType.dotsThreeHorizontal:
      icon.name = BsThreeDots
      break
    case IconType.dotsThreeVertical:
      icon.name = BsThreeDotsVertical
      break
    case IconType.download:
      icon.name = AiOutlineCloudDownload
      break
    case IconType.upload:
      icon.name = AiOutlineCloudUpload
      break
    case IconType.loading:
      icon.name = BiLoader
      break
    case IconType.instagram:
      icon.name = AiOutlineInstagram
      break
    case IconType.tiktok:
      icon.name = FaTiktok
      break
    case IconType.warning:
      icon.name = AiOutlineWarning
      break
    case IconType.trash:
      icon.name = HiOutlineTrash
      break
    case IconType.heart:
      icon.name = HiOutlineHeart
      break
    case IconType.gift:
      icon.name = AiOutlineGift
      break
    case IconType.handshake:
      icon.name = FaRegHandshake
      break
    case IconType.bell:
      icon.name = HiOutlineBell
      break
    case IconType.chevronDown:
      icon.name = HiChevronDown
      break
    case IconType.chevronLeft:
      icon.name = HiChevronLeft
      break
    case IconType.chevronRight:
      icon.name = HiChevronRight
      break
    case IconType.chevronUp:
      icon.name = HiChevronUp
      break
    case IconType.chevronUpDown:
      icon.name = HiChevronUpDown
      break
    case IconType.settings:
      icon.name = CiSettings
      break
    case IconType.power:
      icon.name = CiPower
      break
    case IconType.starFilled:
      icon.name = AiFillStar
      break
    case IconType.starOutlined:
      icon.name = AiOutlineStar
      break
    case IconType.chat:
      icon.name = HiOutlineChatBubbleBottomCenter
      break
    case IconType.clock:
      icon.name = HiOutlineClock
      break
    case IconType.checkCircle:
      icon.name = HiOutlineCheckCircle
      break
    case IconType.check:
      icon.name = HiOutlineCheck
      break
    case IconType.xCircle:
      icon.name = HiOutlineXCircle
      break
    case IconType.x:
      icon.name = HiOutlineXMark
      break
    case IconType.chartBars:
      icon.name = HiOutlineChartBar
      break
    case IconType.rectangleStack:
      icon.name = HiOutlineRectangleStack
      break
    case IconType.inbox:
      icon.name = AiOutlineInbox
      break
    case IconType.inboxStack:
      icon.name = HiOutlineChatBubbleLeftRight
      break
    case IconType.menu:
      icon.name = HiBars3
      break
    case IconType.funnel:
      icon.name = HiOutlineFunnel
      break
    case IconType.search:
      icon.name = HiOutlineMagnifyingGlass
      break
    case IconType.question:
      icon.name = HiOutlineQuestionMarkCircle
      break
    case IconType.eye:
      icon.name = HiOutlineEye
      break
    case IconType.link:
      icon.name = AiOutlineLink
      break
    case IconType.externalLink:
      icon.name = FiExternalLink
      break
    case IconType.bullsEye:
      icon.name = BiBullseye
      break
    case IconType.shipping:
      icon.name = FaShippingFast
      break
    case IconType.pending:
      icon.name = MdOutlinePendingActions
      break
    case IconType.plus:
      icon.name = AiOutlinePlus
      break
    case IconType.plusCircle:
      icon.name = AiOutlinePlusCircle
      break
    case IconType.minus:
      icon.name = AiOutlineMinus
      break
    case IconType.layers:
      icon.name = FiLayers
      break
    case IconType.lightbulb:
      icon.name = HiOutlineLightBulb
      break
    case IconType.computer:
      icon.name = HiOutlineComputerDesktop
      break
    case IconType.sparkles:
      icon.name = HiOutlineSparkles
      break
    case IconType.cube:
      icon.name = HiOutlineCube
      break
    case IconType.delivery:
    case IconType.package:
      icon.name = FiPackage
      break
    case IconType.product:
      icon.name = AiOutlineShopping
      break
    case IconType.megaphone:
      icon.name = HiOutlineMegaphone
      break
    case IconType.academic:
      icon.name = HiOutlineAcademicCap
      break
    case IconType.handRaised:
      icon.name = HiOutlineHandRaised
      break
    case IconType.framePerson:
      icon.name = BsPerson
      break
    case IconType.filmThree:
      icon.name = HiOutlineFilm
      break
    case IconType.exclamationCircleFill:
      icon.name = HiExclamationCircle
      break
    case IconType.refresh:
      icon.name = SlRefresh
      break
    case IconType.arrowDown:
      icon.name = HiArrowDown
      break
    case IconType.arrowUp:
      icon.name = HiArrowUp
      break
    case IconType.phone:
      icon.name = HiOutlinePhone
      break
    case IconType.envelope:
      icon.name = HiOutlineEnvelope
      break
    case IconType.bolt:
      icon.name = HiOutlineBolt //BsLightningChargeFill
      break
    case IconType.pen:
      icon.name = FaPenFancy
      break
    case IconType.penNib:
      icon.name = RiPenNibLine
      break
    case IconType.edit:
      icon.name = AiOutlineEdit
      break
    case IconType.verified:
      icon.name = VscVerifiedFilled
      break
    case IconType.store:
      icon.name = BiStoreAlt
      break
    case IconType.briefcase:
      icon.name = HiOutlineBriefcase
      break
    case IconType.addVideo:
      icon.name = AiOutlineVideoCameraAdd
      break
    case IconType.doneAll:
      icon.name = MdDoneAll
      break
    case IconType.send:
      icon.name = BsFillSendFill
      break
    case IconType.removeDoneAll:
      icon.name = MdRemoveDone
      break
    case IconType.logout:
      icon.name = AiOutlineLogout
      break
    case IconType.pause:
      icon.name = AiOutlinePauseCircle
      break
    case IconType.play:
      icon.name = AiOutlinePlayCircle
      break
    case IconType.moneyBag:
      icon.name = TbMoneybag
      break
    case IconType.fillCheckCircle:
      icon.name = AiFillCheckCircle
      break
    case IconType.duplicate:
      icon.name = BiDuplicate
      break
    case IconType.personAdd:
      icon.name = MdOutlinePersonAddAlt
      break
    case IconType.details:
      icon.name = TbListDetails
      break
    case IconType.videoCamera:
      icon.name = CiVideoOn
      break
    case IconType.subtitles:
      icon.name = MdOutlineSubtitles
      break
    case IconType.music:
      icon.name = MdMusicVideo
      break
    case IconType.template:
      icon.name = TbTemplate
      break
    case IconType.heartFilled:
      icon.name = MdFavorite
      break
    case IconType.voiceOver:
      icon.name = MdOutlineRecordVoiceOver
      break
    case IconType.noVoice:
      icon.name = MdOutlineVoiceOverOff
      break
    default:
      icon.name = BsQuestionOctagonFill
  }

  return (
    <div
      onClick={onClick}
      // className={classnames(styles['icon'], {
      //   [styles[`icon-${style}`]]: style,
      //   [styles['highlighted']]: highlighted,
      //   'cursor-pointer': onClick ? true : false,
      // })}
      className={classnames(
        'icon',
        'font-normal text-base leading-none text-gray-500',
        {
          'bg-gray-200 rounded-full p-2': style === 'circle',
          'bg-white rounded-md p-3 border border-gray-200 hover:bg-gray-100':
            style === 'square',
          'bg-gray-100': highlighted,
          'cursor-pointer': onClick,
        },
      )}
    >
      <icon.name className={className} aria-hidden='true' />
    </div>
  )
}

Icon.defaultProps = defaultProps

export { Icon }
