export enum CreatorRoutes {
  prefix = '/c/',
  onboarding = '/c/onboarding',
  pending = '/c/pending',
  opportunities = '/c/:id/opportunities',
  invitations = '/c/:id/opportunities/invitations',

  /** Collaborations */
  collaborations = '/c/:id/collaborations',
  applications = '/c/:id/collaborations/applications',
  collaborationViewBriefDetails = '/c/:id/collaborations/:uuid/brief-details',
  collaborationViewBrandDetails = '/c/:id/collaborations/:uuid/brand-details',
  collaborationViewClips = '/c/:id/collaborations/:uuid/clips',

  /** Inspirations */
  inspiration = '/c/:id/inspiration',

  /** Account Settings */
  accountProfile = '/c/:id/account/profile/edit',
  accountShippingAddress = '/c/:id/account/profile/address',
  accountPassword = '/c/:id/account/profile/password',
  accountEarnings = '/c/:id/account/profile/earnings',
  accountPortfolio = '/c/:id/account/profile/potfolio',
  accountReviews = '/c/:id/account/profile/reviews',

  /** Inbox */
  inbox = '/c/:id/inbox/',
  inboxChat = '/c/:id/inbox/:uuid',
}
