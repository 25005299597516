import { useMutation } from '@apollo/client'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, ButtonColor, NotificationType } from '../../../components'
import { ACCEPT_CONTENT, MutationAcceptContentResponse } from '../../../gql'
import { Collaboration, MutationAcceptContentArgs } from '../../../gql/types'
import { useNotification } from '../../../utils/hooks'
import { BrandRoutes } from '../../BrandRoutes'

interface IProps {
  collaboration: Collaboration
  isOpen: boolean
  setIsOpen: (event: any) => void
}

export const AcceptContentModal = ({
  collaboration,
  isOpen,
  setIsOpen,
}: IProps) => {
  const { addNotification } = useNotification()
  const navigate = useNavigate()

  const [acceptContent, { loading }] = useMutation<
    MutationAcceptContentResponse,
    MutationAcceptContentArgs
  >(ACCEPT_CONTENT, {
    refetchQueries: [
      'GetCollaborationsByCampaignId',
      'GetCollaborationsByBrandId',
    ],
  })

  const onAcceptContent = async (data) => {
    try {
      await acceptContent({
        variables: {
          collaborationId: collaboration.id,
        },
      })

      closeModal()
      navigate(
        BrandRoutes.collaborationViewReviews
          .replace(`:id`, `${collaboration.campaign.brand.id}`)
          .replace(`:uuid`, `${collaboration.uuid}`),
      )
      addNotification(`Clips approved.`, NotificationType.success)
    } catch (error) {
      addNotification(
        `Something went wrong. Please try again.`,
        NotificationType.error,
      )
    }
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const messageLink =
    BrandRoutes.inboxChat
      .replace(':id', `${collaboration.campaign.brand.id}`)
      .replace(
        ':uuid',
        `${collaboration.campaign.brand.id}-${collaboration.creator.id}`,
      ) +
    '?collaborationId=' +
    collaboration.id

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-25' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                <Dialog.Title
                  as='h3'
                  className='text-lg font-medium leading-6 text-gray-900'
                >
                  Approve the video from {collaboration.creator?.firstName}
                </Dialog.Title>

                <div className='text-gray-700 my-5'>
                  Use the button below to approve the clip. For adjustments, you
                  can ask for one revision only — please use the{' '}
                  <i>Request Edits</i> button.
                </div>

                <div className='text-gray-700'>
                  If you're dissapointed with the quality please email us at{' '}
                  <a
                    href={`mailto:hello@useclip.com`}
                    className='text-blue-700'
                  >
                    hello@useclip.com
                  </a>
                  .
                </div>

                <div className='mt-4 flex justify-between'>
                  <div className='py-2'>
                    <Button
                      type='button'
                      title='Cancel'
                      colour={ButtonColor.white}
                      onClick={closeModal}
                    />
                  </div>

                  {/* <div className='py-2'>
                    <Button
                      type='submit'
                      title='Request revision'
                      loading={loading}
                    />
                  </div> */}

                  <div className='py-2'>
                    <Button
                      type='submit'
                      colour={ButtonColor.indigo}
                      title='Approve'
                      onClick={onAcceptContent}
                      loading={loading}
                    />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
