import { FunctionComponent, useState } from 'react'

import { Brand, GetUserBrandsSort, User, UserRole } from '../../../gql/types'
import { useQuery } from '@apollo/client'
import {
  Button,
  ButtonStyle,
  Icon,
  IconType,
  ListOptions,
} from '../../../components'
import { GET_TEAM_MEMBERS } from '../../../gql'
import { SKIP_DEFAULT, LIMIT_DEFAULT } from '../../../utils/constants'
import { useUserBrand } from '../../../hooks'
import { InviteMemberModal } from '../BrandInvitations/components/InviteMemberModal'
import { List } from './components/List'
import { Header } from '../components/Header'
import { useParams } from 'react-router-dom'

interface IDefaultProps {}

interface IProps extends Partial<IDefaultProps> {
  user: User
  currentBrand: Brand
}

const defaultProps: IDefaultProps = {}

const BrandTeam: FunctionComponent<IProps> = ({ user, currentBrand }) => {
  const userBrand = useUserBrand(currentBrand?.id)

  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false)

  const [options, setOptions] = useState({
    query: null,
    filters: {},
    sort: GetUserBrandsSort.newest,
    skip: SKIP_DEFAULT,
    limit: LIMIT_DEFAULT,
  })

  const { loading, data } = useQuery(GET_TEAM_MEMBERS, {
    variables: {
      options: {
        ...options,
        filters: {
          ...options.filters,
          brandId: currentBrand?.id,
        },
      },
    },
    skip: !currentBrand?.id,
  })

  const [query, setQuery] = useState('')
  const onSearch = () => {
    setOptions({
      ...options,
      query,
      filters: {
        ...options.filters,
      },
    })
  }

  const setPage = (page) => {
    setOptions({
      ...options,
      skip: page * options.limit,
    })
  }

  return (
    <div>
      <Header />

      <div className='sm:flex sm:items-center -mx-4 sm:-mx-6 md:mx-0 py-4'>
        <div className='sm:flex-auto'>
          <h2 className='text-lg font-medium leading-6 text-gray-900'>Team</h2>
          <p className='mt-1 text-sm text-gray-500'>
            Update your brand's team and roles.
          </p>
        </div>
        {userBrand?.roles?.includes(UserRole.owner) && (
          <div className='mt-4 sm:ml-16 sm:mt-0 sm:flex-none'>
            <Button
              styleType={ButtonStyle.fill}
              title='Invite'
              type='button'
              icon={
                <Icon
                  type={IconType.personAdd}
                  className='h-5 w-5 text-white'
                  aria-hidden='true'
                />
              }
              onClick={() => setIsInviteModalOpen(!isInviteModalOpen)}
              iconPosition='left'
            />
          </div>
        )}
      </div>

      <div className='pb-4 w-auto h-fit'>
        <div className='-my-2 -mx-4 overflow-x-auto'>
          <div className='inline-block min-w-full py-2 px-1 align-middle'>
            <div className='overflow-hidden shadow  md:w-auto ring-1 ring-black ring-opacity-5 rounded-t'>
              <ListOptions
                onSearch={onSearch}
                query={query}
                setQuery={setQuery}
              />
              <List
                userBrands={data?.getTeamMembers?.items}
                pagination={data?.getTeamMembers?.pagination}
                setPage={setPage}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>

      <InviteMemberModal
        brand={currentBrand}
        isOpen={isInviteModalOpen}
        setIsOpen={setIsInviteModalOpen}
      />
    </div>
  )
}

BrandTeam.defaultProps = defaultProps

export { BrandTeam }
