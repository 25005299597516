import { Switch } from '@headlessui/react'
import classNames from 'classnames'
import { FunctionComponent } from 'react'

interface IDefaultProps {
  enabled: boolean
  className?: string
  disabled?: boolean
}

interface IProps extends Partial<IDefaultProps> {
  label?: string
  description?: string
  onChange(): void
}

const defaultProps: IDefaultProps = {
  enabled: false,
  className: null,
}

const Toggle: FunctionComponent<IProps> = (props: IProps) => {
  const { label, description, enabled, onChange, className, disabled } = props

  return (
    <div>
      <Switch.Group as='div' className='flex items-center'>
        <Switch
          checked={enabled}
          onChange={onChange}
          className={classNames(
            enabled ? 'bg-gray-800' : 'bg-gray-200',
            'mt-2 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-gray-700 focus:ring-offset-2',
            className,
          )}
          disabled={disabled}
        >
          <span
            aria-hidden='true'
            className={classNames(
              enabled ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
            )}
          />
        </Switch>
        <span className='flex flex-grow flex-col ml-2 mt-2'>
          {label && (
            <Switch.Label
              as='span'
              className='text-sm font-medium text-gray-700'
              passive
            >
              {label}
            </Switch.Label>
          )}
          {description && (
            <Switch.Description as='span' className='text-sm text-gray-500'>
              {description}
            </Switch.Description>
          )}
        </span>
      </Switch.Group>
    </div>
  )
}

export { Toggle }
