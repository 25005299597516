import React, { useState } from 'react'
import { Icon, IconType } from '../Icon'
import { Loader, LoaderType } from '../Loader'
import { NotificationType } from '../Notification'
import { useMutation } from '@apollo/client'
import { DELETE_PORTFOLIO_ITEM } from '../../gql'
import { useNotification } from '../../utils/hooks'

interface Props {
  portfolioId: number
}

export const DeletePortfolioItemButton: React.FC<Props> = ({ portfolioId }) => {
  const { addNotification } = useNotification()
  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const [deletePortfolioItem] = useMutation(DELETE_PORTFOLIO_ITEM)

  const deleteContent = async () => {
    try {
      setIsDeleting(true)

      await deletePortfolioItem({
        variables: {
          portfolioItemId: portfolioId,
        },
        update(cache) {
          const normalizedId = cache.identify({
            __typename: 'PortfolioItem',
            id: portfolioId,
          })

          cache.evict({ id: normalizedId })
          cache.gc()
        },
      })

      addNotification(`Portfolio item deleted.`, NotificationType.success)
      setIsDeleting(false)
    } catch (error) {
      addNotification(
        `Something went wrong. Please try again.`,
        NotificationType.error,
      )
    }
  }

  if (isDeleting) {
    return (
      <div className='flex items-center text-gray-400 hover:text-gray-600 focus:outline-none rounded-full bg-gray-100 p-1 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-200'>
        <Loader type={LoaderType.button} className='mr-0 !h-5 !w-5' />
      </div>
    )
  }

  return (
    <button
      onClick={deleteContent}
      className='flex items-center text-gray-400 hover:text-gray-600 focus:outline-none rounded-full bg-gray-100 p-1 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-200'
    >
      <Icon type={IconType.trash} className='h-5 w-5 text-primary' />
    </button>
  )
}
