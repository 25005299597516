import { Dialog, Transition } from '@headlessui/react'
import classnames from 'classnames'
import { Fragment, useState } from 'react'
import { Link, Outlet, useLocation, useParams } from 'react-router-dom'
import LOGO from '../../assets/logo/lancape_no_bg.png'
import { CreatorRoutes } from '../../creators/CreatorRoutes'
import { User } from '../../gql/types'
import { Icon, IconType } from '../Icon'
import { useLogout } from '../../hooks'
import { Dropdown } from '../Dropdown'
import { userFullname } from '../../utils/UserUtil'
import { Avatar } from '../../elements'

interface IProps {
  user: User
}

const CreatorsLayout = ({ user }: IProps) => {
  const { id } = useParams()

  const logout = useLogout()
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const location = useLocation()

  let creator = user.creator

  const creatorNavigation = {
    name: creator.id,
    href: CreatorRoutes.opportunities.replace(':id', `${creator.id}`),
    logo: creator.introVideo?.url,
    current: location.pathname.includes(`/${creator.id}/`),
  }

  const navigation = [
    {
      name: 'Opportunities',
      href: CreatorRoutes.opportunities.replace(':id', `${creator.id}`),
      icon: IconType.layers,
      current: location.pathname.startsWith(
        CreatorRoutes.opportunities.replace(':id', `${creator.id}`),
      ),
    },
    {
      name: 'Active campaigns',
      href: CreatorRoutes.collaborations.replace(':id', `${creator.id}`),
      icon: IconType.bullsEye,
      current: location.pathname.startsWith(
        CreatorRoutes.collaborations.replace(':id', `${creator.id}`),
      ),
    },
    {
      name: 'Inbox',
      href: CreatorRoutes.inbox.replace(':id', `${creator.id}`),
      icon: IconType.inbox,
      current: location.pathname.startsWith(
        CreatorRoutes.inbox.replace(':id', `${creator.id}`),
      ),
      count: creator?.unreadMessagesCount,
    },
    {
      name: 'Inspiration',
      href: CreatorRoutes.inspiration.replace(':id', `${creator.id}`),
      icon: IconType.lightbulb,
      current: location.pathname.startsWith(
        CreatorRoutes.inspiration.replace(':id', `${creator.id}`),
      ),
    },
  ]

  const profileNavigation = [
    {
      name: 'Edit Profile',
      href: CreatorRoutes.accountProfile.replace(':id', `${creator.id}`),
      icon: IconType.user,
    },
    {
      name: 'Earnings',
      href: CreatorRoutes.accountEarnings.replace(':id', `${creator.id}`),
      icon: IconType.moneyBag,
    },
    {
      name: 'Edit Portfolio',
      href: CreatorRoutes.accountPortfolio.replace(':id', `${creator.id}`),
      icon: IconType.addVideo,
    },
    {
      name: 'Logout',
      onClick: () => logout(),
      icon: IconType.logout,
    },
  ]

  const currentItem = navigation.find((item) => item.current)

  const DesktopMenuSidebar = () => {
    return (
      <>
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className='flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white'>
          <div className='flex flex-1 flex-col overflow-y-auto pt-6 pb-4'>
            <div className='flex flex-shrink-0 items-center px-6'>
              <img className='h-8 w-auto' src={LOGO} alt='Workflow' />
            </div>

            <nav className='mt-6 flex-1 space-y-1 bg-white px-6'>
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classnames(
                    item.current
                      ? 'bg-gray-50 text-gray-900'
                      : 'text-gray-700 hover:bg-gray-50 hover:text-gray-900',
                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                  )}
                >
                  <Icon
                    type={item.icon}
                    className={classnames(
                      item.current
                        ? 'text-gray-500'
                        : 'text-gray-500 group-hover:text-gray-500',
                      'mr-3 flex-shrink-0 h-6 w-6',
                    )}
                    aria-hidden='true'
                  />

                  {item.name}

                  {item.count ? (
                    <span
                      className={classnames(
                        item.current
                          ? 'bg-gray-500'
                          : 'bg-gray-500 group-hover:bg-gray-500',
                        'ml-3 inline-block py-0.5 px-3 text-xs font-medium rounded-full text-white',
                      )}
                    >
                      {item.count}
                    </span>
                  ) : null}
                </Link>
              ))}
            </nav>
          </div>
          <div className='w-full flex flex-shrink-0'>
            <Dropdown
              fullWidth
              title={
                <div className='w-full flex items-start text-left'>
                  <div className='w-10 h-10'>
                    <Avatar
                      type='user'
                      size='xs'
                      src={user.avatar?.url}
                      alt={userFullname(user)}
                    />
                  </div>
                  <div className='ml-2 w-36 truncate'>
                    <p className='text-sm font-medium text-gray-700 group-hover:text-gray-900 truncate'>
                      {userFullname(user)}
                    </p>
                    <p className='text-xs font-medium text-gray-500 group-hover:text-gray-700 truncate'>
                      {user.email}
                    </p>
                  </div>
                </div>
              }
              className='p-4 border-t border-gray-200'
              menuPosition='top'
              options={profileNavigation.map((item) => ({
                text: (
                  <div className='inline-flex max-w-full'>
                    <div className='ml-1 truncate'>{item.name}</div>
                  </div>
                ),
                icon: item?.icon,
                to: item.href,
                onClick: () => {
                  setSidebarOpen(false)
                  item.onClick?.()
                },
              }))}
            />
          </div>
        </div>
      </>
    )
  }

  const MobileSidebar = () => {
    return (
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-40 md:hidden'
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter='transition-opacity ease-linear duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-gray-600 bg-opacity-75' />
          </Transition.Child>

          <div className='fixed inset-0 z-40 flex'>
            <Transition.Child
              as={Fragment}
              enter='transition ease-in-out duration-300 transform'
              enterFrom='-translate-x-full'
              enterTo='translate-x-0'
              leave='transition ease-in-out duration-300 transform'
              leaveFrom='translate-x-0'
              leaveTo='-translate-x-full'
            >
              <Dialog.Panel className='relative flex w-full max-w-xs flex-1 flex-col bg-white'>
                <Transition.Child
                  as={Fragment}
                  enter='ease-in-out duration-300'
                  enterFrom='opacity-0'
                  enterTo='opacity-100'
                  leave='ease-in-out duration-300'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                >
                  <div className='absolute top-0 right-0 -mr-12 pt-2'>
                    <button
                      type='button'
                      className='ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className='sr-only'>Close sidebar</span>
                      <Icon
                        type={IconType.x}
                        className='h-6 w-6 text-white'
                        aria-hidden='true'
                      />
                      {/* <XIcon className='h-6 w-6 text-white' aria-hidden='true' /> */}
                    </button>
                  </div>
                </Transition.Child>
                <div className='h-0 flex-1 overflow-y-auto pt-5 pb-4'>
                  <div className='flex flex-shrink-0 items-center px-4'>
                    {/* <img
                  className="h-8 w-auto"
                  src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                  alt="Your Company"
                /> */}
                    <img className='h-8 w-auto' src={LOGO} alt='Workflow' />
                  </div>
                  <nav className='mt-5 space-y-1 px-2'>
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classnames(
                          item.current
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                          'group flex items-center px-2 py-2 text-base font-medium rounded-md',
                        )}
                        onClick={() => setSidebarOpen(false)}
                      >
                        <Icon
                          type={item.icon}
                          className={classnames(
                            item.current
                              ? 'text-gray-500'
                              : 'text-gray-400 group-hover:text-gray-500',
                            'mr-4 flex-shrink-0 h-6 w-6',
                          )}
                          aria-hidden='true'
                        />
                        {item.name}
                      </Link>
                    ))}
                  </nav>
                </div>
                <div className='w-full flex flex-shrink-0 border-t border-gray-200'>
                  <Dropdown
                    fullWidth
                    title={
                      <div className='w-full flex items-start text-left'>
                        <div className='w-10 h-10'>
                          <Avatar
                            type='user'
                            size='xs'
                            src={user.avatar?.url}
                            alt={userFullname(user)}
                          />
                        </div>
                        <div className='ml-2 truncate'>
                          <p className='text-sm font-medium text-gray-700 group-hover:text-gray-900 truncate'>
                            {userFullname(user)}
                          </p>
                          <p className='text-xs font-medium text-gray-500 group-hover:text-gray-700 truncate'>
                            {user.email}
                          </p>
                        </div>
                      </div>
                    }
                    className='p-4'
                    menuPosition='top'
                    options={profileNavigation.map((item) => ({
                      text: (
                        <div className='inline-flex max-w-full'>
                          <div className='ml-1 truncate'>{item.name}</div>
                        </div>
                      ),
                      to: item.href,
                      onClick: () => {
                        setSidebarOpen(false)
                        item.onClick?.()
                      },
                    }))}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className='w-14 flex-shrink-0'>
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
  }

  const DesktopSwitchCreatorSidebar = () => {
    return (
      <>
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className='flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-gray-50'>
          <div className='flex flex-1 flex-col overflow-y-auto pt-5'>
            <nav className='flex-1 space-y-1 bg-gray-50 px-4'>
              <Link
                key={creatorNavigation.name}
                to={creatorNavigation.href}
                className='mb-4'
              >
                <div
                  className={classnames(
                    'w-11 h-11 rounded-lg mb-4 p-[1px]',
                    creatorNavigation.current
                      ? 'border-2 border-indigo-500'
                      : null,
                  )}
                >
                  {/* <img
                      src={creatorNavigation.logo}
                      className={classnames(
                        'rounded-md',
                        // item.current
                        //   ? 'border-2 border-indigo-500'
                        //   : null,
                      )}
                    /> */}
                </div>
              </Link>
            </nav>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div>
        <MobileSidebar />

        {/* Static sidebar for desktop */}
        {/* <div className='hidden md:fixed md:inset-y-0 md:flex md:w-[72px] md:flex-col'>
          <DesktopSwitchCreatorSidebar />
        </div> */}
        <div className='hidden md:fixed md:inset-y-0 md:left-[0px] md:flex md:w-64 md:flex-col'>
          <DesktopMenuSidebar />
        </div>

        <div className='flex flex-1 flex-col md:pl-[256px]'>
          <div className='sticky top-0 z-10 bg-gray-50 pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden'>
            <button
              type='button'
              className='-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none'
              onClick={() => setSidebarOpen(true)}
            >
              <span className='sr-only'>Open sidebar</span>
              <Icon
                type={IconType.menu}
                className='h-6 w-6'
                aria-hidden='true'
              />
            </button>
          </div>
          <main className='flex-1'>
            <div
              className={
                location?.pathname?.includes('inbox')
                  ? 'lg:h-screen'
                  : 'px-8 xs:px-8'
              }
            >
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

export { CreatorsLayout }
