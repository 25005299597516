import {
  ClipType,
  Ethnicity,
  Gender,
  ModelType,
  Platform,
  VideoDuration,
  VideoType,
  VoiceType,
} from '../gql/types'

export const videoTypeLocalizedString = (videoType: VideoType) => {
  switch (videoType) {
    case VideoType.howTo:
      return 'How to'
    case VideoType.unboxing:
      return 'Unboxing'
    case VideoType.testimonial:
      return 'Testimonial'
    case VideoType.upToTheCreator:
      return 'Up to the creator'
  }
}

export const videoDurationLocalizedString = (videoDuration: VideoDuration) => {
  switch (videoDuration) {
    case VideoDuration.quarter:
      return '15 seconds'
    case VideoDuration.half:
      return '30 seconds'
    case VideoDuration.minute:
      return '60 seconds'
  }
}

export const modelTypeLocalizedString = (modelType: ModelType) => {
  switch (modelType) {
    case ModelType.bodyFace:
      return 'Body & Face'
    case ModelType.handsOnly:
      return 'Hands only'
  }
}

export const platformLocalizedString = (platform: Platform) => {
  switch (platform) {
    case Platform.instagram:
      return 'Instagram'
    case Platform.tiktok:
      return 'TikTok'
  }
}

export const ethnicityLocalizedString = (ethnicity: Ethnicity) => {
  switch (ethnicity) {
    case Ethnicity.asian:
      return 'Asian / Pacific Islander'
    case Ethnicity.black:
      return 'Black or African American'
    case Ethnicity.hispanic:
      return 'Hispanic'
    case Ethnicity.indian:
      return 'American Indian or Alaskan Native'
    case Ethnicity.white:
      return 'White / Caucasian'
    case Ethnicity.other:
      return 'Other'
  }
}

export const ethnicityLocalizedStringInverse = (ethnicity): Ethnicity => {
  switch (ethnicity) {
    case 'Asian / Pacific Islander':
      return Ethnicity.asian
    case 'Black or African American':
      return Ethnicity.black
    case 'Hispanic':
      return Ethnicity.hispanic
    case 'American Indian or Alaskan Native':
      return Ethnicity.indian
    case 'White / Caucasian':
      return Ethnicity.white
    case 'Other':
      return Ethnicity.other
    default:
      return null
  }
}

export const genderLocalizedString = (gender: Gender) => {
  switch (gender) {
    case Gender.female:
      return 'Female'
    case Gender.male:
      return 'Male'
    case Gender.other:
      return 'Other'
  }
}

export const genderLocalizedStringInverse = (gender): Gender => {
  switch (gender) {
    case 'Female':
      return Gender.female
    case 'Male':
      return Gender.male
    case 'Other':
      return Gender.other
    default:
      return null
  }
}

export const clipTypeLocalizedString = (type: ClipType) => {
  switch (type) {
    case ClipType.firstVersion:
      return 'Main Video'
    case ClipType.editedVersion:
      return 'Edited Video'
    case ClipType.hook:
      return 'Variation'
    case ClipType.addon:
      return 'Addon'
    case ClipType.otherFile:
      return 'Other'
    case ClipType.rawFile:
      return 'Raw File'
  }
}

export const clipTypeLocalizedStringInverse = (type): ClipType => {
  switch (type) {
    case 'Main Video':
      return ClipType.firstVersion
    case 'Edited Video':
      return ClipType.editedVersion
    case 'Variation':
      return ClipType.hook
    case 'Addon':
      return ClipType.addon
    case 'Raw File':
      return ClipType.rawFile
    case 'Other':
      return ClipType.otherFile
    default:
      return null
  }
}

export const voiceTypeLocalizedString = (voiceType: VoiceType) => {
  switch (voiceType) {
    case VoiceType.overVoice:
      return 'Voiceover'
    case VoiceType.noVoice:
      return 'No Voice'
  }
}

export const voiceTypeLocalizedStringInverse = (voiceType): VoiceType => {
  switch (voiceType) {
    case 'Voiceover':
      return VoiceType.overVoice
    case 'No Voice':
      return VoiceType.noVoice
    default:
      return null
  }
}
