import { useQuery } from '@apollo/client'
import React from 'react'
import { Icon, IconType, Slider, SliderProps } from '../../../components'
import { Divider } from '../../../components/Divider'
import {
  GET_CLIPS_BY_CAMPAIGN_ID,
  GetClipsByCampaignResponse,
} from '../../../gql'
import {
  Collaboration,
  GetClipsSort,
  QueryGetClipsByCampaignIdArgs,
} from '../../../gql/types'
import { ContentGrid } from '../../../components/ContentGrid'
import { LIMIT_DEFAULT } from '../../../utils/constants'

interface IProps extends Omit<SliderProps, 'children'> {
  collaboration: Collaboration
}

export const CollaborationClipsSlider: React.FC<IProps> = ({
  setOpen,
  open,
  collaboration,
}) => {
  const limit = LIMIT_DEFAULT

  const { loading, error, data, fetchMore } = useQuery<
    GetClipsByCampaignResponse,
    QueryGetClipsByCampaignIdArgs
  >(GET_CLIPS_BY_CAMPAIGN_ID, {
    variables: {
      campaignId: collaboration.campaign.id,
      options: {
        query: '',
        filters: {
          creatorId: collaboration.creator.id,
        },
        sort: GetClipsSort.newest,
        limit,
        skip: 0,
      },
    },
    skip: !open,
  })

  const fetchData = () => {
    if (loading) {
      return
    }

    // if we have all the data, don't fetch more
    if (
      data?.getClipsByCampaignId?.items?.length >=
      data?.getClipsByCampaignId?.pagination?.numberOfHits
    ) {
      return
    }

    fetchMore({
      variables: {
        campaignId: collaboration.campaign.id,
        options: {
          query: '',
          filters: {
            creatorId: collaboration.creator.id,
          },
          sort: GetClipsSort.newest,
          limit,
          skip: data?.getClipsByCampaignId?.items?.length ?? 0,
        },
      },
    })
  }

  if (error) {
    console.log(error)
    return <p>There has been an error. Try refreshing the page</p>
  }

  return (
    <Slider setOpen={setOpen} open={open} size='md'>
      <div className='flex h-full flex-col overflow-y-scroll bg-white shadow-xl'>
        {/* Header */}
        <div className='sm:sticky top-0 z-10 bg-white'>
          <div className='px-4 pt-6 sm:px-6'>
            <div className='flex items-start justify-between'>
              <h3 className='text-xl font-bold text-gray-900'>Clips</h3>
              <button
                type='button'
                className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none'
                onClick={() => setOpen(false)}
              >
                <span className='sr-only'>Close panel</span>
                <Icon
                  type={IconType.x}
                  className='h-6 w-6'
                  aria-hidden='true'
                />
              </button>
            </div>
          </div>
          <Divider className='py-6' />
        </div>

        {/* Main */}
        <div className='pb-1 sm:pb-6 px-4 sm:px-6 overflow-y-scroll'>
          <ContentGrid
            clips={data?.getClipsByCampaignId?.items || []}
            hasMore={
              data?.getClipsByCampaignId?.items?.length <
              data?.getClipsByCampaignId?.pagination?.numberOfHits
            }
            fetchData={fetchData}
            loading={loading}
            allowDownload={true}
            type='brand'
            useWindow={false}
          />
        </div>
      </div>
    </Slider>
  )
}
