import { Industry } from '../../gql/types'
import { Badge, BadgeSize, BadgeType } from '../Badge'

const InspirationIndustry = ({ industry }: { industry: Industry }) => {
  let badgeType: BadgeType
  switch (industry) {
    case Industry.baby:
      badgeType = BadgeType.gray
      break
    case Industry.beauty:
      badgeType = BadgeType.warning
      break
    case Industry.essentials:
      badgeType = BadgeType.success
      break
    case Industry.fashion:
      badgeType = BadgeType.error
      break
    case Industry.fitness:
      badgeType = BadgeType.violet
      break
    case Industry.food:
      badgeType = BadgeType.sky
      break
    case Industry.hair:
      badgeType = BadgeType.orange
      break
    case Industry.health:
      badgeType = BadgeType.rose
      break
    case Industry.homeware:
      badgeType = BadgeType.blue
      break
    case Industry.jewellery:
      badgeType = BadgeType.indigo
      break
    case Industry.makeup:
      badgeType = BadgeType.lime
      break
    case Industry.skincare:
      badgeType = BadgeType.orange
      break
    case Industry.wellness:
      badgeType = BadgeType.teal
      break
    case Industry.outdoors:
      badgeType = BadgeType.cyan
      break
    case Industry.toys:
      badgeType = BadgeType.purple
      break
    case Industry.pet:
      badgeType = BadgeType.amber
      break
    case Industry.software:
      badgeType = BadgeType.pink
      break
    case Industry.other:
      badgeType = BadgeType.fuchsia
      break
    default:
      throw new Error(`Invalid industry ${industry}`)
  }

  return <Badge type={badgeType} text={industry} size={BadgeSize.small} />
}

export { InspirationIndustry }
