import { gql } from '@apollo/client'
import { CreatorFragment, PaginationFragment } from '.'
import { GetPortfolioResponse } from './types'

export const PortfolioItemFragment = gql`
  fragment PortfolioItemFragment on PortfolioItem {
    id
    createdAt
    updatedAt
    type

    video {
      url
      name
    }
    thumbnail {
      url
      name
    }
    clip {
      id
    }
    creator {
      ...CreatorFragment
    }
  }
  ${CreatorFragment}
`

export const GET_PORTFOLIO_BY_CREATOR = gql`
  query GetPortfolioByCreator(
    $creatorId: Int!
    $options: GetPortfolioOptions!
  ) {
    getPortfolioByCreator(creatorId: $creatorId, options: $options) {
      items {
        ...PortfolioItemFragment
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${PortfolioItemFragment}
  ${PaginationFragment}
`

export const GET_PORTFOLIO_ITEM_UPLOAD_URL = gql`
  mutation GetPortfolioItemUploadUrl($creatorId: Int!, $mime: String!) {
    getPortfolioItemUploadUrl(creatorId: $creatorId, mime: $mime) {
      uploadUrl
      fileName
    }
  }
`

export const CREATE_PORTFOLIO_ITEM = gql`
  mutation CreatePortfolioItem(
    $creatorId: Int!
    $input: CreatePortfolioInput!
  ) {
    createPortfolioItem(creatorId: $creatorId, input: $input) {
      ...PortfolioItemFragment
    }
  }
  ${PortfolioItemFragment}
`

export const DELETE_PORTFOLIO_ITEM = gql`
  mutation DeletePortfolioItem($portfolioItemId: Int!) {
    deletePortfolioItem(portfolioItemId: $portfolioItemId)
  }
`

export interface GetPortfolioByCreatorResponse {
  getPortfolioByCreator: GetPortfolioResponse
}
