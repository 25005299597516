import { CreatorInterests as CreatorInterestsType } from '../../gql/types'
import { Badge, BadgeSize, BadgeType } from '../Badge'

const CreatorInterests = ({ interest }: { interest: CreatorInterestsType }) => {
  // TODO: Decide to either add the rest of the interests or use a default color for all.

  let badgeType
  switch (interest) {
    case CreatorInterestsType.beauty:
      badgeType = BadgeType.slate
      break
    case CreatorInterestsType.business:
      badgeType = BadgeType.success
      break
    case CreatorInterestsType.fashion:
      badgeType = BadgeType.warning
      break
    case CreatorInterestsType.fitness:
      badgeType = BadgeType.violet
      break
    case CreatorInterestsType.food:
      badgeType = BadgeType.error
      break
    case CreatorInterestsType.hair:
      badgeType = BadgeType.orange
      break
    case CreatorInterestsType.health:
      badgeType = BadgeType.rose
      break
    case CreatorInterestsType.home:
      badgeType = BadgeType.sky
      break
    case CreatorInterestsType.jewellery:
      badgeType = BadgeType.amber
      break
    case CreatorInterestsType.lifestyle:
      badgeType = BadgeType.pink
      break
    case CreatorInterestsType.makeup:
      badgeType = BadgeType.fuchsia
      break
    case CreatorInterestsType.music:
      badgeType = BadgeType.purple
      break
    case CreatorInterestsType.parenting:
      badgeType = BadgeType.indigo
      break
    case CreatorInterestsType.pet:
      badgeType = BadgeType.blue
      break
    case CreatorInterestsType.photography:
      badgeType = BadgeType.cyan
      break
    case CreatorInterestsType.skincare:
      badgeType = BadgeType.teal
      break
    case CreatorInterestsType.travel:
      badgeType = BadgeType.lime
      break
    case CreatorInterestsType.yoga:
      badgeType = BadgeType.stone
      break
    default:
      badgeType = BadgeType.neutral
      break
  }

  return (
    <Badge
      type={badgeType}
      text={interest.charAt(0).toUpperCase() + interest.slice(1)}
      size={BadgeSize.small}
      className='text-[10px] mr-0.5'
    />
  )
}

export { CreatorInterests }
