import { gql } from '@apollo/client'
import { BrandFragment } from './brands'
import { PaginationFragment } from './common'
import { SessionFragment, UserBrandFragment, UserFragment } from './users'

export const TeamInvitationFragment = gql`
  fragment TeamInvitationFragment on TeamInvitation {
    id
    token
    email
    firstName
    lastName
    roles
    status
    expirationDate
    updatedAt
    createdAt
  }
`

export const GET_TEAM_MEMBERS = gql`
  query GetTeamMembers($options: GetUserBrandsOptions!) {
    getTeamMembers(options: $options) {
      items {
        ...UserBrandFragment
        brand {
          ...BrandFragment
        }
        user {
          ...UserFragment
        }
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${UserBrandFragment}
  ${BrandFragment}
  ${UserFragment}
  ${PaginationFragment}
`

export const INVITE_TEAM_MEMBER = gql`
  mutation InviteTeamMember($input: InviteTeamMemberInput!, $brandId: Int!) {
    inviteTeamMember(input: $input, brandId: $brandId) {
      ...TeamInvitationFragment
    }
  }
  ${TeamInvitationFragment}
`

export const GET_TEAM_INVITATIONS = gql`
  query GetTeamMembersInvitations($options: GetTeamInvitationsOptions!) {
    getTeamMembersInvitations(options: $options) {
      items {
        ...TeamInvitationFragment
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${TeamInvitationFragment}
  ${PaginationFragment}
`

export const RESEND_TEAM_MEMBER_INVITATION = gql`
  mutation ResendTeamMemberInvitation($email: String!, $brandId: Int!) {
    resendTeamMemberInvitation(email: $email, brandId: $brandId) {
      ...TeamInvitationFragment
    }
  }
  ${TeamInvitationFragment}
`

export const CANCEL_TEAM_MEMBER_INVITATION = gql`
  mutation CancelTeamMemberInvitation($email: String!, $brandId: Int!) {
    cancelTeamMemberInvitation(email: $email, brandId: $brandId) {
      ...BrandFragment
    }
  }
  ${BrandFragment}
`

export const GET_TEAM_MEMBER_INVITATION_BY_TOKEN = gql`
  query GetTeamInvitationByToken($token: String!) {
    getTeamInvitationByToken(token: $token) {
      ...TeamInvitationFragment
      brand {
        ...BrandFragment
      }
    }
  }
  ${TeamInvitationFragment}
  ${BrandFragment}
`

export const ACCEPT_TEAM_MEMBER_INVITATION = gql`
  mutation AcceptTeamMemberInvitation($userId: Int!, $token: String!) {
    acceptTeamMemberInvitation(userId: $userId, token: $token) {
      ...TeamInvitationFragment
    }
  }
  ${TeamInvitationFragment}
`

export const UPDATE_TEAM_MEMBER_ROLES = gql`
  mutation UpdateTeamMemberRoles(
    $userId: Int!
    $brandId: Int!
    $input: UpdateTeamMemberRolesInput!
  ) {
    updateTeamMemberRoles(userId: $userId, brandId: $brandId, input: $input) {
      ...UserBrandFragment
    }
  }
  ${UserBrandFragment}
`

export const REMOVE_TEAM_MEMBER = gql`
  mutation RemoveTeamMember($userId: Int!, $brandId: Int!) {
    removeTeamMember(userId: $userId, brandId: $brandId) {
      ...BrandFragment
    }
  }
  ${BrandFragment}
`

export const SIGNUP_TEAM_MEMBER = gql`
  mutation SignupTeamMember($input: TeamMemberSignupInput!) {
    signupTeamMember(input: $input) {
      user {
        ...UserFragment
      }
      session {
        ...SessionFragment
      }
    }
  }
  ${UserFragment}
  ${SessionFragment}
`
