import { useMutation } from '@apollo/client'
import LOGO from '../../../assets/logo/lancape_no_bg.png'
import { Button, ButtonColor, NotificationType } from '../../../components'
import {
  Creator,
  CreatorOnboardingStep,
  MutationUpdateCreatorArgs,
} from '../../../gql/types'
import { useNotification } from '../../../utils/hooks'
import { UPDATE_CREATOR, UpdateCreatorResponse } from '../../../gql'

interface Props {
  creator: Creator
}

const Confirmation = ({ creator }: Props) => {
  const { addNotification } = useNotification()

  const [updateCreator, { loading }] = useMutation<
    UpdateCreatorResponse,
    MutationUpdateCreatorArgs
  >(UPDATE_CREATOR)

  const goBack = async () => {
    try {
      await updateCreator({
        variables: {
          id: creator.id,
          input: {
            onboarding: CreatorOnboardingStep.agreeTerms,
          },
        },
      })
    } catch (error) {
      addNotification(error.message, NotificationType.error)
    }
  }

  return (
    <div className='lg:col-span-6 lg:block xl:col-span-2 pb-14'>
      <div className='space-y-6 sm:p-6'>
        <img className='h-12 w-auto' src={LOGO} alt='Workflow' />
        <div className='sm:mx-auto'>
          <h2 className='mt-0 text-left text-3xl font-extrabold text-gray-900'>
            Lovely jubbly! ✨
          </h2>
          <p className='mt-4 text-left text-sm text-gray-600'>
            That's it for now. Once we approve your application you'll be able
            to apply to campaigns.
          </p>
          <p className='mt-4 text-left text-sm text-gray-600'>
            We review each application manually and in case your application
            won’t be approved you can apply multiple times.
          </p>

          <p className='mt-8 text-left text-xl font-bold text-gray-900'>
            How does Clip work again? 🤔
          </p>
          <p className='mt-4 text-left text-sm text-gray-600'>
            1. Creators submit their applications, and our team reviews each one
            manually, either approving it or requesting additional information.
          </p>
          <p className='mt-4 text-left text-sm text-gray-600'>
            2. Once approved, creators receive a Daily Digest email containing a
            list of brands they can apply to (including the product offered to
            review and earnings details), and they can also check our platform
            throughout the day for new brand opportunities.
          </p>
          <p className='mt-4 text-left text-sm text-gray-600'>
            3. Creators apply to campaigns. Adding a personal note for the brand
            increases your approval chance.
          </p>
          <p className='mt-4 text-left text-sm text-gray-600'>
            4. Brands evaluate the applications and select creators to
            participate in the campaign.
          </p>
          <p className='mt-4 text-left text-sm text-gray-600'>
            5. Upon acceptance, creators are notified via email and their
            shipping address is automatically shared with the brand for product
            shipment.
          </p>
          <p className='mt-4 text-left text-sm text-gray-600'>
            6. After receiving the product, creators have a 3 day window to
            create a video for the campaign and upload it to Clip.
          </p>
          <p className='mt-4 text-left text-sm text-gray-600'>
            7. Brands have the option to request revisions or accept the video
            as is.
          </p>
          <p className='mt-4 text-left text-sm text-gray-600'>
            8. Keep the product and we'll be processing payouts to your Paypal
            email address every two weeks.
          </p>
          <Button
            title='Go back'
            type='button'
            colour={ButtonColor.white}
            onClick={goBack}
            className='mt-4'
            loading={loading}
          />
        </div>
      </div>
    </div>
  )
}

export { Confirmation }
