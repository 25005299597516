import { useMutation } from '@apollo/client'
import React, { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Dropdown, Icon, IconType } from '../..'
import {
  CREATE_MESSAGES,
  GET_MESSAGES_BY_THREAD,
  GET_MESSAGES_THREADS,
} from '../../../gql/messages'
import {
  GetMessagesSort,
  GetMessagesThreadsSort,
  MessageType,
  Template,
} from '../../../gql/types'
import { NotificationType } from '../../Notification'
import { TextArea } from '../../TextArea'
import { Avatar } from '../../../elements'
import { useNotification } from '../../../utils/hooks'
import { BrandRoutes } from '../../../brands/BrandRoutes'

interface IProps {
  profilePicUrl: string
  creatorId: number
  brandId: number
  type: MessageType
  threadId: number
  templates?: Template[]
}

const ChatInput: React.FC<IProps> = ({
  profilePicUrl,
  creatorId,
  brandId,
  type,
  threadId,
  templates,
}) => {
  const { addNotification } = useNotification()
  // margin left  breakpoint from md to lg based on the type of the message (brand or creator)
  // 328px=256px(w-64=DesktopMenuSidebar)+72px(DesktopSwitchBrandSidebar only for brands)
  const marginLeft =
    type === MessageType.brandToCreator ? 'md:left-[328px]' : 'md:left-64'

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      message: '',
    },
  })

  const [createMessage, { loading, error }] = useMutation(CREATE_MESSAGES, {
    refetchQueries: [
      {
        query: GET_MESSAGES_BY_THREAD,
        variables: {
          threadId,
          options: {
            sort: GetMessagesSort.oldest,
            skip: 0,
            limit: 100,
          },
        },
      },
      {
        query: GET_MESSAGES_THREADS,
        variables: {
          options: {
            filters: {
              creatorId: type === MessageType.brandToCreator ? null : creatorId,
              brandId: type === MessageType.creatorToBrand ? null : brandId,
            },
            sort: GetMessagesThreadsSort.lastUpdated,
            skip: 0,
            limit: 100,
          },
        },
      },
    ],
  })

  const onSubmit = async ({ message }) => {
    try {
      await createMessage({
        variables: {
          input: {
            creatorId,
            brandId,
            message,
            type,
          },
        },
      })

      reset()
    } catch (error) {
      addNotification(
        `Something went wrong. Please try again.`,
        NotificationType.error,
      )
    }
  }

  const options = useMemo(() => {
    const options = []

    if (templates && templates.length > 0) {
      options.push({
        items: templates?.map((template) => ({
          text: template.name,
          to: null,
          onClick: () => setValue('message', template.message),
        })),
      })
    }

    if (type === MessageType.brandToCreator) {
      options.push({
        items: [
          {
            text: 'Edit your templates',
            to: BrandRoutes.templates.replace(':id', brandId.toString()),
            icon: IconType.pencil,
          },
        ],
      })
    }

    return options
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templates])

  return (
    <div
      className={`fixed lg:absolute bottom-0 flex flex-1 shrink grow-0 flex-row max-w-full w-auto right-0 left-0 ${marginLeft} lg:left-0`}
    >
      <div className='bg-gray-50 px-4 py-4 sm:px-6 w-full'>
        <div className='flex space-x-3'>
          <div className='flex-shrink-0'>
            <Avatar
              className='h-10 w-10'
              size='xs'
              src={profilePicUrl}
              type='user'
              alt='Logo'
            />
          </div>
          <div className='min-w-0 flex-1'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <label htmlFor='comment' className='sr-only'>
                  Send a private message
                </label>
                <TextArea
                  placeholder='Type to add your message'
                  name='email'
                  error={errors.message?.message}
                  {...register('message', {
                    required: `Please add a message.`,
                    maxLength: {
                      value: 10000,
                      message: `Message too long.`,
                    },
                  })}
                />
              </div>
              {type === MessageType.brandToCreator && (
                <div className='absolute bottom-16 right-8 mb-3'>
                  <Dropdown
                    menuPosition='top-left'
                    options={options}
                    isArrowInvisible={true}
                    title={
                      <div className='cursor-pointer inline-flex items-center px-4 py-[9px] rounded-md text-xs font-medium bg-indigo-100 text-indigo-800'>
                        <Icon
                          type={IconType.template}
                          className='h-4 w-4 text-indigo-800'
                          aria-hidden='true'
                        />
                        <span className='ml-2 text-indigo-800'>Templates</span>
                      </div>
                    }
                  />
                </div>
              )}
              <div className='mt-3 flex items-center justify-end'>
                <Button title={'Send message'} loading={loading} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export { ChatInput }
