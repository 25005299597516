import { useQuery } from '@apollo/client'
import { FunctionComponent } from 'react'
import {
  GET_CLIPS_BY_CREATOR_ID,
  GetClipsByCreatorResponse,
} from '../../../gql'
import {
  Collaboration,
  GetClipsSort,
  QueryGetClipsByCreatorIdArgs,
} from '../../../gql/types'
import { ContentGrid } from '../../../components/ContentGrid'
import { LIMIT_DEFAULT } from '../../../utils/constants'

interface IProps {
  collaboration: Collaboration
  useWindow?: boolean
}

export const ClipsList: FunctionComponent<IProps> = ({
  collaboration,
  useWindow = false,
}) => {
  const limit = LIMIT_DEFAULT

  const { loading, error, data, fetchMore } = useQuery<
    GetClipsByCreatorResponse,
    QueryGetClipsByCreatorIdArgs
  >(GET_CLIPS_BY_CREATOR_ID, {
    variables: {
      creatorId: collaboration?.creator?.id,
      options: {
        query: '',
        filters: {
          campaignId: collaboration?.campaign?.id,
        },
        sort: GetClipsSort.newest,
        limit,
        skip: 0,
      },
    },
    skip: !collaboration,
  })

  const fetchData = () => {
    if (loading) {
      return
    }

    // if we have all the data, don't fetch more
    if (
      data?.getClipsByCreatorId?.items?.length >=
      data?.getClipsByCreatorId?.pagination?.numberOfHits
    ) {
      return
    }

    fetchMore({
      variables: {
        creatorId: collaboration?.creator?.id,
        options: {
          query: '',
          filters: {
            campaignId: collaboration?.campaign?.id,
          },
          sort: GetClipsSort.newest,
          limit,
          skip: data?.getClipsByCreatorId?.items?.length ?? 0,
        },
      },
    })
  }

  if (error) {
    console.log(error)
    return <p>There has been an error. Try refreshing the page</p>
  }

  if (data?.getClipsByCreatorId?.items?.length === 0) {
    return (
      <div className='lg:max-w-7xl'>
        <p className='text-sm text-gray-700'>No clips uploaded.</p>
      </div>
    )
  }

  return (
    <div className='w-auto h-fit'>
      <div className='-my-2 overflow-x-auto'>
        <div className='inline-block min-w-full py-2 align-middle overflow-y-scroll'>
          <ContentGrid
            clips={data?.getClipsByCreatorId?.items || []}
            hasMore={
              data?.getClipsByCreatorId?.items?.length <
              data?.getClipsByCreatorId?.pagination?.numberOfHits
            }
            fetchData={fetchData}
            loading={loading}
            allowDownload={true}
            type='creator'
            useWindow={useWindow}
          />
        </div>
      </div>
    </div>
  )
}
