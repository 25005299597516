import { useEffect } from 'react'
import {
  Creator,
  CreatorOnboardingStep,
  MutationUpdateCreatorArgs,
} from '../../../gql/types'
import { AnalyticsEvent, trackEvent } from '../../../utils/analytics'
import LOGO from '../../../assets/logo/lancape_no_bg.png'
import { Button, ButtonColor, NotificationType } from '../../../components'
import { useMutation } from '@apollo/client'
import { UpdateCreatorResponse, UPDATE_CREATOR } from '../../../gql'
import { useNotification } from '../../../utils/hooks'

interface Props {
  creator: Creator
}

const SetAgreeTerms = ({ creator }: Props) => {
  const { addNotification } = useNotification()

  const [updateCreator, { loading }] = useMutation<
    UpdateCreatorResponse,
    MutationUpdateCreatorArgs
  >(UPDATE_CREATOR)

  useEffect(() => {
    trackEvent(AnalyticsEvent.viewCreatorsOnboardingSetAgreeTerms)
  }, [])

  const goBack = async () => {
    try {
      await updateCreator({
        variables: {
          id: creator.id,
          input: {
            onboarding: CreatorOnboardingStep.setPaypalEmailAddress,
          },
        },
      })
    } catch (error) {
      addNotification(error.message, NotificationType.error)
    }
  }

  const onSubmit = async () => {
    try {
      await updateCreator({
        variables: {
          id: creator.id,
          input: {
            onboarding: CreatorOnboardingStep.completed,
          },
        },
      })

      trackEvent(AnalyticsEvent.creatorAppliedToClip)
    } catch (error) {
      console.log('@error SetShippingAddress', error.message)
    }
  }

  return (
    <div className='min-h-screen flex flex-col'>
      <div className='flex-grow'>
        <div className='min-h-full flex flex-col justify-center pt-4 px-4 md:mt-8 lg:px-8'>
          <div className='sm:mx-auto sm:w-full sm:max-w-2xl'>
            <div className='lg:col-span-6 lg:block xl:col-span-2 pb-14'>
              <div className='space-y-6 sm:p-6'>
                <img className='h-12 w-auto' src={LOGO} alt='Workflow' />
                <div className='sm:mx-auto'>
                  <h2 className='mt-0 text-left text-3xl font-extrabold text-gray-900'>
                    Agree Terms
                  </h2>
                  <p className='mt-2 text-gray-700 text-sm font-bold'>
                    To collaborate with brands on Clip please agree the
                    following
                  </p>
                  <p className='mt-8 text-left text-sm text-gray-600'>
                    1. Upon receipt of the product, the creator shall have a
                    maximum of three (3) days to create, deliver, and upload the
                    agreed-upon content to Clip.
                  </p>
                  <p className='mt-8 text-left text-sm text-gray-600'>
                    2. Content Rights. The brand retains the right to use the
                    content created by you for marketing and promotional
                    purposes, including but not limited to ads, social media,
                    websites, and other online platforms. You grant the brand a
                    perpetual, non-exclusive, royalty-free license to use the
                    content for these purposes.
                  </p>

                  <div className='flex justify-between py-3 text-right sm:px-6'>
                    <Button
                      title='Go back'
                      type='button'
                      colour={ButtonColor.white}
                      loading={loading}
                      disabled={loading}
                      onClick={goBack}
                      className='mt-4'
                    />

                    <Button
                      title='Agree Terms'
                      type='submit'
                      className='mt-4'
                      onClick={onSubmit}
                      loading={loading}
                      disabled={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { SetAgreeTerms }
