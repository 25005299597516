import { useQuery } from '@apollo/client'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { ListOptions, Loader } from '../../components'
import { GET_CAMPAIGNS_BY_BRAND_ID } from '../../gql'
import { Brand, CampaignStatus, GetCampaignsSort, User } from '../../gql/types'
import { formatPriceInCents } from '../../utils/PricingUtil'
import { BrandRoutes } from '../BrandRoutes'
import { Grid } from './components/Grid'
import { Header } from './components/Header'
import { List } from './components/List'

interface IProps {
  user: User
  brand: Brand
}

const sortOptions = [
  { name: 'Newest', value: GetCampaignsSort.newest },
  { name: 'Oldest', value: GetCampaignsSort.oldest },
  { name: 'Last Updated', value: GetCampaignsSort.lastUpdated },
]

const filterOptions = [
  {
    id: 'status',
    name: 'Status',
    options: [
      { value: CampaignStatus.draft, label: 'Draft' },
      { value: CampaignStatus.active, label: 'Active' },
      { value: CampaignStatus.paused, label: 'Paused' },
      { value: CampaignStatus.completed, label: 'Completed' },
    ],
  },
]

const Campaigns = ({ brand }: IProps) => {
  const limit = 20
  const [skip, setSkip] = useState(0)

  const { loading, error, data, refetch } = useQuery(
    GET_CAMPAIGNS_BY_BRAND_ID,
    {
      variables: {
        brandId: brand.id,
        options: {
          query: '',
          filters: {},
          sort: GetCampaignsSort.lastUpdated,
          skip,
          limit,
        },
      },
    },
  )

  const [query, setQuery] = useState('')
  const [filtersValue, setFiltersValue] = useState({
    status: {
      [CampaignStatus.draft]: false,
      [CampaignStatus.active]: false,
      [CampaignStatus.paused]: false,
      [CampaignStatus.completed]: false,
    },
  })

  const refetchData = (query, filters, sort = null) => {
    refetch({
      options: {
        query: query,
        filters: {
          status: Object.keys(filters.status).filter(
            (key) => filters.status[key] === true,
          ),
        },
        sort: sort,
        skip,
        limit,
      },
    })
  }

  const onSearch = () => {
    refetchData(query, filtersValue)
  }

  const onFiltersUpdate = (section, option) => {
    const newFiltersValue = {
      ...filtersValue,
      [section.id]: {
        ...filtersValue[section.id],
        [option.value]: !filtersValue[section.id][option.value],
      },
    }

    setFiltersValue(newFiltersValue)
    refetchData(query, newFiltersValue)
  }

  const onSort = (sort) => {
    refetchData(query, filtersValue, sort)
  }

  if (error) {
    console.log(error)
    return <p>There has been an error. Try refreshing the page</p>
  }

  const setPage = (page: number) => {
    setSkip(page * limit)
  }

  const formatCredits = (creditCents, currency) => {
    return formatPriceInCents(
      {
        priceInCents: brand.creditCents,
        currency: brand.currency,
      },
      { minimumFractionDigits: 0 },
    )
  }

  return (
    <div>
      <Header />

      {loading && <Loader />}
      {!loading && (
        <div className='pb-4 w-auto h-fit'>
          <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='inline-block min-w-full py-2 px-1 align-middle md:px-6 lg:px-8'>
              <div className='shadow ring-1 ring-black ring-opacity-5 rounded-t'>
                <ListOptions
                  query={query}
                  setQuery={setQuery}
                  onSearch={onSearch}
                  leftComponent={
                    <div className='text-xs text-gray-600'>
                      {brand?.creditCents > 0 &&
                        `You have ${formatCredits(
                          brand?.creditCents,
                          brand?.currency,
                        )} credits available.`}
                      {brand?.creditCents === 0 && (
                        <div>
                          <Link
                            to={BrandRoutes.creditPacks.replace(
                              ':id',
                              `${brand.id}`,
                            )}
                            className='text-blue-500'
                          >
                            Buy credit packs
                          </Link>{' '}
                          and save up.
                        </div>
                      )}
                    </div>
                  }
                  // filterOptions={filterOptions}
                  // filtersValue={filtersValue}
                  // onFiltersUpdate={onFiltersUpdate}
                  // sortOptions={sortOptions}
                  // onSort={onSort}
                />
                <div className='hidden lg:block'>
                  <List
                    campaigns={data?.getCampaignsByBrandId?.items}
                    pagination={data?.getCampaignsByBrandId?.pagination}
                    setPage={setPage}
                  />
                </div>
                <div className='block lg:hidden'>
                  <Grid
                    campaigns={data?.getCampaignsByBrandId?.items}
                    pagination={data?.getCampaignsByBrandId?.pagination}
                    setPage={setPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export { Campaigns }
