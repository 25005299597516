import { useQuery } from '@apollo/client'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Loader } from '../../components'
import { GET_CAMPAIGNS, QueryGetCampaignsResponse } from '../../gql'
import {
  CampaignStatus,
  CreatorQuality,
  CreatorStatus,
  GetCampaignsSort,
  QueryGetCampaignsArgs,
  User,
} from '../../gql/types'
import { PricingData } from '../../utils/PricingUtil'
import { AnalyticsEvent, trackEvent } from '../../utils/analytics'
import { getAgeGroupByBirthday } from '../../utils/mappers'
import { AccountStatus } from '../components/AccountStatus'
import { isCreatorAllowedToAccessOpportunities } from '../utils'
import { Header } from './components/Header'
import { List } from './components/List'
import { Grid } from './components/Grid'

interface IProps {
  user: User
  pricing: PricingData
}

const Opportunities = ({ user, pricing }: IProps) => {
  const { creator } = user

  const ageGroup = [getAgeGroupByBirthday(creator.birthday)]
  const { loading, error, data, refetch } = useQuery<
    QueryGetCampaignsResponse,
    QueryGetCampaignsArgs
  >(GET_CAMPAIGNS, {
    skip: creator.status === CreatorStatus.shadowBanned,
    variables: {
      options: {
        query: '',
        filters: {
          isIgnoreFilledCampaigns: true,
          ignoreCollaborationsWithCreatorIds: [creator.id],
          status: [CampaignStatus.active],
          countryCode: [creator.countryCode],
          modelGender: creator.gender ? [creator.gender] : [],
          ageGroups: ageGroup,
          // quality: [CreatorQuality.regular, CreatorQuality.premium],
          quality: user.creator.isPremium
            ? [CreatorQuality.regular, CreatorQuality.premium]
            : [CreatorQuality.regular],
        },
        sort: GetCampaignsSort.lastBoosted,
        skip: 0,
      },
    },
  })

  const setPage = (page) => {
    refetch({
      options: {
        query: '',
        filters: {
          isIgnoreFilledCampaigns: true,
          ignoreCollaborationsWithCreatorIds: [creator.id],
          status: [CampaignStatus.active],
          countryCode: [creator.countryCode],
          modelGender: creator.gender ? [creator.gender] : [],
          ageGroups: ageGroup,
          quality: user.creator?.isPremium
            ? [CreatorQuality.regular, CreatorQuality.premium]
            : [CreatorQuality.regular],
        },
        sort: GetCampaignsSort.lastBoosted,
        skip: page * 20,
      },
    })
  }

  useEffect(() => {
    if (isCreatorAllowedToAccessOpportunities(creator)) {
      trackEvent(AnalyticsEvent.viewCreatorsOpportunities, {
        creatorId: creator.id,
      })
    }
  }, [])

  // if creator not allowed to access collaborations, display account status
  if (!isCreatorAllowedToAccessOpportunities(creator)) {
    return <AccountStatus creator={creator} />
  }

  if (error) {
    console.log(error)
    return <p>There has been an error. Try refreshing the page</p>
  }

  let message
  if (creator.status === CreatorStatus.rejected) {
    message = (
      <>
        We couldn't approve your account. Please send us a new intro video at{' '}
        <a href='mailto:hello@useclip.com'>hello@useclip.com</a>
      </>
    )
  }

  if (creator.status === CreatorStatus.paused) {
    message = (
      <>
        Your account is paused. Email us at{' '}
        <a href='mailto:hello@useclip.com'>hello@useclip.com</a>
      </>
    )
  }

  return (
    <div>
      <Header />

      {message && (
        <div className='mb-4 relative bg-primary-600 rounded-lg'>
          <div className='mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8'>
            <div className='pr-16 sm:px-16 sm:text-center'>
              <p className='font-medium text-white'>
                {/* <span className="md:hidden">We announced a new product!</span> */}
                <span className='md:inline text-sm'>{message}</span>
                {/* <span className='block sm:ml-2 sm:inline-block text-sm'>
                  <a
                    href='https://help.thesocialcat.com/article/72-i-want-to-take-a-break-or-pause-collaborations-can-i-do-that'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='font-bold text-white underline'
                  >
                    Learn more
                    <span aria-hidden='true'> &rarr;</span>
                  </a>
                </span> */}
              </p>
            </div>
          </div>
        </div>
      )}

      {loading && <Loader />}
      {!loading && creator.status === CreatorStatus.shadowBanned && (
        <div className='pb-4 w-auto h-fit'>
          <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='inline-block min-w-full py-2 px-1 align-middle md:px-6 lg:px-8'>
              <div className='shadow md:w-auto ring-1 ring-black ring-opacity-5 rounded-t'>
                <div className='hidden lg:block'>
                  <List
                    creator={user.creator}
                    campaigns={[]}
                    pagination={{
                      numberOfHits: 0,
                      pageNumber: 0,
                      hitsPerPage: 20,
                      numberOfPages: 0,
                    }}
                    pricing={pricing}
                    setPage={setPage}
                    onRefetch={() => refetch()}
                  />
                </div>
                <div className='block lg:hidden'>
                  <Grid
                    creator={user.creator}
                    campaigns={[]}
                    pagination={{
                      numberOfHits: 0,
                      pageNumber: 0,
                      hitsPerPage: 20,
                      numberOfPages: 0,
                    }}
                    pricing={pricing}
                    setPage={setPage}
                    onRefetch={() => refetch()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!loading && creator.status === CreatorStatus.active && (
        <div className='pb-4 w-auto h-fit'>
          <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='inline-block min-w-full py-2 px-1 align-middle md:px-6 lg:px-8'>
              <div className='shadow md:w-auto ring-1 ring-black ring-opacity-5 rounded-t'>
                <div className='hidden lg:block'>
                  <List
                    creator={user.creator}
                    campaigns={data?.getCampaigns?.items.map((item) => {
                      const campaignItem = {
                        campaign: item,
                        invitationId: null,
                      }
                      return campaignItem
                    })}
                    pagination={data?.getCampaigns?.pagination}
                    pricing={pricing}
                    setPage={setPage}
                    onRefetch={() => refetch()}
                  />
                </div>
                <div className='block lg:hidden'>
                  <Grid
                    creator={user.creator}
                    campaigns={data?.getCampaigns?.items.map((item) => {
                      const campaignItem = {
                        campaign: item,
                        invitationId: null,
                      }
                      return campaignItem
                    })}
                    pagination={data?.getCampaigns?.pagination}
                    pricing={pricing}
                    setPage={setPage}
                    onRefetch={() => refetch()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export { Opportunities }
