import { FunctionComponent, useEffect } from 'react'
import { User } from '../../gql/types'
import { EditPassword } from './components/EditPassword'
import { EditPersonalInformation } from './components/EditPersonalInformation'
import { EditShippingAddress } from './components/EditShippingAddress'
import { Header } from './components/Header'
import { EditNotifications } from './components/EditNotifications'
import { AnalyticsEvent, trackEvent } from '../../utils/analytics'
import { EditPaused } from './components/EditPaused'

interface IProps {
  user: User
}

const Profile: FunctionComponent<IProps> = ({ user }) => {
  useEffect(() => {
    trackEvent(AnalyticsEvent.viewCreatorsProfile)
  }, [])

  return (
    <div className='pb-12'>
      <Header />
      <div className='space-y-10 divide-y divide-gray-900/10'>
        <EditPersonalInformation user={user} />
        <EditShippingAddress creator={user.creator} />
        <EditNotifications user={user} />
        <EditPassword />
        <EditPaused creator={user.creator} />
      </div>
    </div>
  )
}

export { Profile }
