import { useMutation } from '@apollo/client'
import moment from 'moment-timezone'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  Button,
  NotificationType,
  Select,
  TextField,
} from '../../../components'
import { UpdateCreatorResponse, UPDATE_CREATOR } from '../../../gql'
import {
  Creator,
  CreatorOnboardingStep,
  Ethnicity,
  Gender,
  MutationUpdateCreatorArgs,
} from '../../../gql/types'
import { AnalyticsEvent, trackEvent } from '../../../utils/analytics'
import {
  ethnicityLocalizedString,
  ethnicityLocalizedStringInverse,
  genderLocalizedString,
  genderLocalizedStringInverse,
} from '../../../utils/GqlStrings'
import { useNotification } from '../../../utils/hooks'
import { Bullets } from '../components/Bullets'
import { Steps } from '../components/Steps'

export type EditCreatorProfileFormData = {
  gender: string
  ethnicity: string
  occupation: string
  birthday: string
}

interface Props {
  creator: Creator
}

const SetProfile = ({ creator }: Props) => {
  const defaultBirthday = moment().subtract(20, 'years').format('YYYY-MM-DD')
  const minBirthday = moment().subtract(100, 'years').format('YYYY-MM-DD')
  const maxBirthday = moment().subtract(16, 'years').format('YYYY-MM-DD')
  const { addNotification } = useNotification()
  const [updateCreator, { loading }] = useMutation<
    UpdateCreatorResponse,
    MutationUpdateCreatorArgs
  >(UPDATE_CREATOR)

  const {
    register,
    handleSubmit,
    control,
    setValue,
    // setError,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      gender: creator?.gender ?? null,
      ethnicity: creator?.ethnicity ?? null,
      occupation: creator.occupation ?? null,
      birthday: creator?.birthday
        ? moment(creator.birthday).format('YYYY-MM-DD')
        : defaultBirthday, // null,
    },
  })

  useEffect(() => {
    trackEvent(AnalyticsEvent.viewCreatorsOnboardingSetProfile)
  }, [])

  const onSubmit = async (data: EditCreatorProfileFormData) => {
    try {
      await updateCreator({
        variables: {
          id: creator.id,
          input: {
            gender: genderLocalizedStringInverse(data.gender),
            ethnicity: ethnicityLocalizedStringInverse(data.ethnicity),
            occupation: data.occupation,
            birthday: data.birthday,
            onboarding: CreatorOnboardingStep.setAbout,
          },
        },
      })
    } catch (error) {
      console.log('@error SetProfile', error?.message)
      addNotification(error?.message, NotificationType.error)
    }
  }

  return (
    <div className='min-h-screen flex flex-col'>
      <div className='flex-grow'>
        <Steps
          creator={creator}
          stepCurrent={CreatorOnboardingStep.setProfile}
        />

        <div className='min-h-full flex flex-col justify-center pt-4 px-4 md:mt-8 lg:px-8'>
          <div className='sm:mx-auto sm:w-full sm:max-w-2xl'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='md:shadow rounded-t-md sm:overflow-hidden mb-8'>
                <div className='py-5 md:bg-white space-y-6 sm:p-6'>
                  <div className='sm:mx-auto'>
                    <h2 className='mt-0 md:text-center text-3xl font-extrabold text-gray-900'>
                      My profile
                    </h2>
                    <p className='mt-2 md:text-center text-sm text-gray-600'>
                      We will share this information with brands to facilitate
                      the campaign process.
                    </p>
                  </div>

                  <div className='grid grid-cols-6 gap-6'>
                    <div className='col-span-6 sm:col-span-3'>
                      <Controller
                        name='gender'
                        control={control}
                        rules={{ required: `Please set a gender.` }}
                        render={({ field }) => (
                          <Select
                            options={Object.keys(Gender).map((gender) =>
                              genderLocalizedString(gender as Gender),
                            )}
                            field={field}
                            label='Gender'
                            error={errors?.gender?.message}
                          />
                        )}
                      />
                    </div>

                    <div className='col-span-6 sm:col-span-3'>
                      <Controller
                        name='ethnicity'
                        control={control}
                        rules={{ required: `Please set an ethnicity.` }}
                        render={({ field }) => (
                          <Select
                            options={Object.keys(Ethnicity).map((ethnicity) =>
                              ethnicityLocalizedString(ethnicity as Ethnicity),
                            )}
                            field={field}
                            label='Ethnicity'
                            error={errors?.ethnicity?.message}
                          />
                        )}
                      />
                    </div>

                    <div className='col-span-6 sm:col-span-3'>
                      <TextField
                        error={errors.occupation?.message}
                        label='Occupation'
                        placeholder='Magician'
                        {...register('occupation', {
                          required: `Please set your occupation.`,
                          minLength: {
                            value: 3,
                            message: `Your occupation must have more than 3 characters.`,
                          },
                          maxLength: {
                            value: 100,
                            message: `Your occupation cannot have more than 100 characters.`,
                          },
                        })}
                      />
                    </div>

                    <div className='col-span-6 sm:col-span-6'>
                      <TextField
                        type='date'
                        min={minBirthday}
                        max={maxBirthday}
                        error={errors.birthday?.message}
                        label='When is your birthday?'
                        // placeholder='dd/mm/yyyy'
                        {...register('birthday', {
                          required: `Please set your birthday.`,
                          validate: {
                            value: (birthday) => {
                              const age = moment().diff(
                                moment(birthday),
                                'years',
                              )
                              if (age >= 16) {
                                return true
                              }

                              return 'Make sure you are 16 or older.'
                            },
                          },
                        })}
                      />
                      {/* <p className='mt-1 block text-sm font-medium text-gray-500'>
                        We only show your age to potential matches, not your
                        birthday.
                      </p> */}
                    </div>
                  </div>
                </div>

                <div className='md:shadow rounded-b-md py-3 md:bg-gray-50 text-right sm:px-6'>
                  <Button
                    title='Continue'
                    type='submit'
                    loading={loading}
                    disabled={loading}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Bullets
        creator={creator}
        stepCurrent={CreatorOnboardingStep.setProfile}
      />
    </div>
  )
}

export { SetProfile }
