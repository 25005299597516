import { useMutation } from '@apollo/client'
import { FunctionComponent, useEffect, useState } from 'react'
import { NotificationType } from '../../../../components'
import { Toggle } from '../../../../components/Toggle'
import { PAUSE_CREATOR, UNPAUSE_CREATOR } from '../../../../gql'
import {
  Creator,
  CreatorPausedReason,
  CreatorStatus,
} from '../../../../gql/types'
import { AnalyticsEvent, trackEvent } from '../../../../utils/analytics'
import { useNotification } from '../../../../utils/hooks'

export type EditCreatorPaused = {
  pausedReason: CreatorPausedReason
  pausedDescription: string
}

interface IProps {
  creator: Creator
}

const EditPaused: FunctionComponent<IProps> = ({ creator }) => {
  const { addNotification } = useNotification()
  const isCreatorAccountActive = creator.status === CreatorStatus.active

  const [pauseCreator, { loading }] = useMutation(PAUSE_CREATOR)
  const [unpauseCreator, { loading: unpauseCreatorLoading }] =
    useMutation(UNPAUSE_CREATOR)

  useEffect(() => {
    trackEvent(AnalyticsEvent.viewCreatorsEditPaused)
  }, [])

  const handlePauseCreator = async () => {
    try {
      await pauseCreator({
        variables: {
          id: creator.id,
          input: {
            pausedReason: CreatorPausedReason.creatorRequested,
            pausedDescription: 'creator requested',
          },
        },
      })

      addNotification('Account paused.', NotificationType.success)
    } catch (error) {
      console.log('@error creator pauseCreator', error)
      addNotification(error.message, NotificationType.error)
    }
  }

  const handleUnpauseCreator = async () => {
    try {
      await unpauseCreator({
        variables: {
          id: creator.id,
        },
      })

      addNotification('Account activated.', NotificationType.success)
    } catch (error) {
      console.log('@error creator unpauseCreator', error)
      addNotification(error.message, NotificationType.error)
    }
  }

  const onChangeToggle = () => {
    console.log('onToggle', `isCreatorAccountActive=${isCreatorAccountActive}`)

    if (isCreatorAccountActive) {
      handlePauseCreator()
      return
    }

    if (!isCreatorAccountActive) {
      handleUnpauseCreator()
    }
  }

  return (
    <div>
      <div className='grid grid-cols-1 gap-x-8 gap-y-8 pt-10 lg:grid-cols-3'>
        <div className='px-0'>
          <h2 className='text-lg leading-6 font-medium text-gray-900'>
            Pause your account
          </h2>
          <p className='mt-1 text-sm text-gray-500'>
            You can pause your account at anytime. You won't be able to apply
            for new opportunities and we'll hide your current applications from
            brands. Once you unpause your account, everything will go back to
            normal, and your pending applications will be visible again.
          </p>
        </div>

        <div className='bg-white shadow ring-1 ring-gray-900/5 sm:rounded-xl lg:col-span-2'>
          <div className='px-4 py-6 sm:p-8'>
            <div className='grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6'>
              <div className='sm:col-span-full'>
                <Toggle
                  label={`${isCreatorAccountActive ? 'Active' : 'Active'}`}
                  description={`${
                    isCreatorAccountActive
                      ? 'Your account is currently active.'
                      : 'Your account is currently paused.'
                  }`}
                  enabled={isCreatorAccountActive}
                  onChange={onChangeToggle}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { EditPaused }
