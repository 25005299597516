import { ReactNode } from 'react'
import { formatLongMessage, replaceURLs } from '../../../utils/helpers'

interface IBriefRowProps {
  title: string
  value?: string | ReactNode
  formatHTML?: boolean
}

interface IRowProps extends Partial<IBriefRowProps> {
  children: React.ReactNode
}

const Row = ({ title, children, formatHTML }: IRowProps) => {
  return (
    <div className='py-5 @md:grid @md:grid-cols-3 @md:gap-4'>
      <div className='text-sm font-medium text-gray-500'>{title}</div>
      {!formatHTML && (
        <div className='mt-1 text-sm text-gray-900 @md:col-span-2 @md:mt-0'>
          {children}
        </div>
      )}

      {formatHTML && (
        <div
          className='mt-1 text-sm text-gray-900 @md:col-span-2 @md:mt-0'
          dangerouslySetInnerHTML={{
            __html: formatLongMessage(children),
          }}
        ></div>
      )}
    </div>
  )
}

export const BriefTextRow = ({ title, value, formatHTML }: IBriefRowProps) => {
  return (
    value && (
      <Row title={title} formatHTML={formatHTML}>
        {value}
      </Row>
    )
  )
}

export const BriefExternalLinkRow = ({ title, value }: IBriefRowProps) => {
  if (typeof value !== 'string') {
    return null
  }

  return (
    <Row title={title}>
      <a
        href={value as string}
        target='_blank'
        className='font-medium text-blue-600 hover:text-blue-500'
      >
        {value}
      </a>
    </Row>
  )
}
