import { FunctionComponent } from 'react'
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { BrandsLayout } from '../components/BrandsLayout'
import { Loader } from '../components/Loader'
import { Brand, BrandOnboardingStep, User, UserType } from '../gql/types'
import { useBrandInit } from '../hooks'
import { PricingData } from '../utils/PricingUtil'
import { PublicRoutes } from '../utils/PublicRoutes'
import { Brand as BrandComponent } from './Brand'
import { Billing } from './Brand/Billing'
import { BrandCreditPacks } from './Brand/BrandCreditPacks'
import { BrandInvitations } from './Brand/BrandInvitations'
import { BrandProducts } from './Brand/BrandProducts'
import { BrandTeam } from './Brand/BrandTeam'
import { BrandRoutes } from './BrandRoutes'
import { CampaignEdit } from './CampaignEdit'
import { CampaignView } from './CampaignView'
import { Applicants } from './CampaignView/Applicants'
import { Clips as CampaignClips } from './CampaignView/Clips'
import { Collaborators } from './CampaignView/Collaborators'
import { Campaigns } from './Campaigns'
import { Clips } from './Clips'
import { CollaborationClips as BrandCollaborationClips } from './CollaborationView/CollaborationClips'
import { CreatorProfile as BrandCollaborationViewCreatorProfile } from './CollaborationView/CreatorProfile'
import { Reviews as BrandCollaborationsViewReviews } from './CollaborationView/Reviews'
import { Create as BrandCreate } from './Create'
import { Delivery } from './Delivery'
import { Inbox as BrandsInbox } from './Inbox'
import { Inspirations as InspirationBrand } from './Inspirations'
import { NewCampaign } from './NewCampaign'
import { NewInspiration } from './NewInspiration'
import { Onboarding as BrandOnboarding } from './Onboarding'
import { ConfirmAddonsPayment } from './Payments/ConfirmAddonsPayment'
import { ConfirmCreditPackPayment } from './Payments/ConfirmCreditPackPayment'
import { ConfirmPayment as BrandConfirmPayment } from './Payments/ConfirmPayment'
import { ConfirmVariationsPayment } from './Payments/ConfirmVariationsPayment'
import { EditProduct } from './Products/EditProduct'
import { NewProduct } from './Products/NewProduct'
import { Profile as BrandProfile } from './Profile'
import { Reviews as BrandReviewsView } from './Profile/ReviewsView'
import { Templates } from './Templates'
import { DEAL_CODE } from '../utils/constants'

interface IPrivateBrandOutlet {
  user: User
  currentBrand: Brand
}

function PrivateBrandOutlet({ user, currentBrand }: IPrivateBrandOutlet) {
  const { pathname } = useLocation()
  const deal = localStorage.getItem(DEAL_CODE)

  // const [searchParams] = useSearchParams()

  // // if deal code present in search params, set it in local storage
  // const dealCode = searchParams.get('deal')
  // if (dealCode) {
  //   localStorage.setItem(DEAL_CODE, dealCode)
  // }

  if (
    !user ||
    (user.type !== UserType.brand && user.type !== UserType.agency)
  ) {
    return <Navigate to={PublicRoutes.login} />
  }

  if (pathname === BrandRoutes.confirmPayment) {
    return <Outlet />
  }

  if (pathname === BrandRoutes.confirmCreditPackPayment) {
    return <Outlet />
  }

  if (pathname === BrandRoutes.confirmVariationsPayment) {
    return <Outlet />
  }

  if (pathname === BrandRoutes.confirmAddonsPayment) {
    return <Outlet />
  }

  if (deal) {
    if (deal === 'credit-packs') {
      return (
        <BrandCreditPacks
          brand={currentBrand}
          user={user}
          isOnboarding={true}
        />
      )
    }

    // if (deal === 'black-friday' && env.ENABLE_BLACK_FRIDAY_DEAL) {
    //   return <BrandBlackFridayPacks user={user} brand={currentBrand} />
    // }

    // if (deal === 'christmas' && env.ENABLE_CHRISTMAS_DEAL) {
    //   return <BrandChristmasPacks user={user} brand={currentBrand} />
    // }
  }

  if (
    currentBrand.onboarding === BrandOnboardingStep.setOrderSummary &&
    pathname === BrandRoutes.pending
  ) {
    return <Outlet />
  }

  if (
    pathname === BrandRoutes.onboarding &&
    currentBrand.onboarding !== BrandOnboardingStep.completed
  ) {
    return <Outlet />
  }

  if (
    pathname !== BrandRoutes.onboarding &&
    currentBrand.onboarding !== BrandOnboardingStep.completed
  ) {
    return <Navigate to={BrandRoutes.onboarding} />
  }

  return <BrandsLayout user={user} currentBrand={currentBrand} />
}

const PageNotFound = () => {
  return <Navigate to={BrandRoutes.default} />
}

interface IDefaultProps {}

interface IProps extends Partial<IDefaultProps> {
  user: User
  pricing: PricingData
}

const defaultProps: IDefaultProps = {}

const BrandRouter: FunctionComponent<IProps> = ({ user, pricing }) => {
  const route = useParams()['*']
  const brandId = parseInt(route.split('/')?.[0])

  const { brand, loading } = useBrandInit(user?.id, brandId)

  if (loading) {
    return <Loader />
  }

  return (
    <Routes>
      <Route
        path={BrandRoutes.confirmPayment.replace('/b/', '')}
        element={<BrandConfirmPayment user={user} />}
      />

      <Route
        path={BrandRoutes.confirmCreditPackPayment.replace('/b/', '')}
        element={<ConfirmCreditPackPayment user={user} />}
      />

      <Route
        path={BrandRoutes.confirmVariationsPayment.replace('/b/', '')}
        element={<ConfirmVariationsPayment user={user} />}
      />

      <Route
        path={BrandRoutes.confirmAddonsPayment.replace('/b/', '')}
        element={<ConfirmAddonsPayment user={user} />}
      />

      <Route
        path={BrandRoutes.prefix.replace('/b/', '')}
        element={<PrivateBrandOutlet user={user} currentBrand={brand} />}
      >
        <Route
          path={BrandRoutes.onboarding.replace('/b/', '')}
          element={
            <BrandOnboarding
              user={user}
              pricing={pricing}
              currentBrand={brand}
            />
          }
        />
        <Route
          path={BrandRoutes.brand.replace('/b/', '')}
          element={<BrandComponent user={user} brand={brand} />}
        />
        <Route
          path={BrandRoutes.create.replace('/b/', '')}
          element={<BrandCreate user={user} />}
        />
        <Route
          path={BrandRoutes.campaigns.replace('/b/', '')}
          element={<Campaigns user={user} brand={brand} />}
        />
        <Route
          path={BrandRoutes.campaignView.replace('/b/', '')}
          element={<CampaignView user={user} />}
        />
        <Route
          path={BrandRoutes.campaignApplicants.replace('/b/', '')}
          element={<Applicants user={user} />}
        />
        <Route
          path={BrandRoutes.campaignCollaborators.replace('/b/', '')}
          element={<Collaborators user={user} />}
        />
        <Route
          path={BrandRoutes.collaborationViewProfile.replace('/b/', '')}
          element={<BrandCollaborationViewCreatorProfile />}
        />
        <Route
          path={BrandRoutes.collaborationViewReviews.replace('/b/', '')}
          element={<BrandCollaborationsViewReviews />}
        />
        <Route
          path={BrandRoutes.collaborationViewClips.replace('/b/', '')}
          element={<BrandCollaborationClips />}
        />
        <Route
          path={BrandRoutes.campaignClips.replace('/b/', '')}
          element={<CampaignClips user={user} />}
        />
        <Route
          path={BrandRoutes.campaignEdit.replace('/b/', '')}
          element={<CampaignEdit user={user} brand={brand} pricing={pricing} />}
        />
        <Route
          path={BrandRoutes.newCampaign.replace('/b/', '')}
          element={<NewCampaign user={user} brand={brand} pricing={pricing} />}
        />
        <Route
          path={BrandRoutes.clips.replace('/b/', '')}
          element={<Clips user={user} />}
        />
        <Route
          path={BrandRoutes.inspiration.replace('/b/', '')}
          element={<InspirationBrand user={user} />}
        />
        <Route
          path={BrandRoutes.newInspiration.replace('/b/', '')}
          element={<NewInspiration user={user} />}
        />
        <Route
          path={BrandRoutes.delivery.replace('/b/', '')}
          element={<Delivery user={user} />}
        />
        <Route
          path={BrandRoutes.products.replace('/b/', '')}
          element={<BrandProducts user={user} />}
        />
        <Route
          path={BrandRoutes.newProduct.replace('/b/', '')}
          element={<NewProduct user={user} brand={brand} />}
        />
        <Route
          path={BrandRoutes.productEdit.replace('/b/', '')}
          element={<EditProduct user={user} brand={brand} />}
        />
        <Route
          path={BrandRoutes.billing.replace('/b/', '')}
          element={<Billing user={user} brand={brand} />}
        />
        <Route
          path={BrandRoutes.creditPacks.replace('/b/', '')}
          element={<BrandCreditPacks user={user} brand={brand} />}
        />
        <Route
          path={BrandRoutes.accountProfile.replace('/b/', '')}
          element={<BrandProfile user={user} />}
        />
        <Route
          path={BrandRoutes.accountReviews.replace('/b/', '')}
          element={<BrandReviewsView user={user} />}
        />
        <Route
          path={BrandRoutes.inbox.replace('/b/', '')}
          element={<BrandsInbox pricing={pricing} />}
        />
        <Route
          path={BrandRoutes.inboxChat.replace('/b/', '')}
          element={<BrandsInbox pricing={pricing} />}
        />
        <Route
          path={BrandRoutes.templates.replace('/b/', '')}
          element={<Templates user={user} brand={brand} />}
        />
        <Route
          path={BrandRoutes.team.replace('/b/', '')}
          element={<BrandTeam user={user} currentBrand={brand} />}
        />
        <Route
          path={BrandRoutes.invitations.replace('/b/', '')}
          element={<BrandInvitations user={user} currentBrand={brand} />}
        />
      </Route>
    </Routes>
  )
}

BrandRouter.defaultProps = defaultProps

export { BrandRouter }
