import { ApolloCache, useMutation } from '@apollo/client'
import {
  GetTemplatesSort,
  MutationCreateTemplateArgs,
  MutationUpdateTemplateArgs,
  Template,
} from '../gql/types'
import {
  CREATE_BRAND_TEMPLATE,
  GET_BRAND_TEMPLATES,
  MutationCreateBrandTemplateResponse,
  MutationUpdateTemplateResponse,
  QueryGetBrandTemplatesResponse,
  UPDATE_TEMPLATE,
} from '../gql/templates'

export const useCreateBrandTemplateMutation = ({
  brandId,
}: {
  brandId?: number
}) => {
  const [createTemplate, { loading }] = useMutation<
    MutationCreateBrandTemplateResponse,
    MutationCreateTemplateArgs
  >(CREATE_BRAND_TEMPLATE, {
    update: (
      cache: ApolloCache<any>,
      { data: { createTemplate: newTemplate } },
    ) => {
      if (!brandId) {
        return
      }
      addTemplateToGetBrandTemplates(cache, newTemplate, {
        brandId: brandId,
        options: {
          query: '',
          filters: {},
          sort: GetTemplatesSort.newest,
          skip: 0,
          limit: 100,
        },
      })
    },
  })

  return { createTemplate, loading }
}

export const addTemplateToGetBrandTemplates = (
  cache: ApolloCache<any>,
  template: Template,
  variables: object,
) => {
  const existingTemplatesData = cache.readQuery<QueryGetBrandTemplatesResponse>(
    {
      query: GET_BRAND_TEMPLATES,
      variables: variables,
    },
  )

  console.log('existingTemplatesData', existingTemplatesData)

  // if no existing Templates, create and save
  if (!existingTemplatesData || !existingTemplatesData.getBrandTemplates) {
    return
  }

  // if Templates exist, update the list => // Update Templates list
  const updatedTemplates = {
    ...existingTemplatesData.getBrandTemplates,
    items: [template, ...existingTemplatesData.getBrandTemplates.items],
  }

  console.log('updatedTemplates', updatedTemplates)

  // Update pagination, incrementing the numberOfHits
  const updatedPagination = {
    ...existingTemplatesData.getBrandTemplates.pagination,
    numberOfHits:
      existingTemplatesData.getBrandTemplates.pagination.numberOfHits + 1,
    // Update other pagination fields as necessary
  }

  console.log('updatedPagination', updatedPagination)

  cache.writeQuery({
    query: GET_BRAND_TEMPLATES,
    variables,
    data: {
      getBrandTemplates: {
        ...updatedTemplates,
        pagination: updatedPagination,
      },
    },
  })
}

export const useUpdateBrandTemplateMutation = () => {
  const [updateTemplate, { loading }] = useMutation<
    MutationUpdateTemplateResponse,
    MutationUpdateTemplateArgs
  >(UPDATE_TEMPLATE)

  return { updateTemplate, loading }
}
