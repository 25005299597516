import { ClipType } from '../../gql/types'
import { clipTypeLocalizedString } from '../../utils/GqlStrings'
import { Badge, BadgeSize, BadgeType } from '../Badge'

const ClipTypeBadge = ({ type }: { type: ClipType }) => {
  let badgeType
  switch (type) {
    case ClipType.firstVersion:
      badgeType = BadgeType.success
      break
    case ClipType.editedVersion:
      badgeType = BadgeType.blue
      break
    case ClipType.hook:
      badgeType = BadgeType.indigo
      break
    case ClipType.addon:
      badgeType = BadgeType.purple
      break
    case ClipType.rawFile:
      badgeType = BadgeType.gray
      break
    case ClipType.otherFile:
      badgeType = BadgeType.gray
      break
    default:
      throw new Error(`Invalid clip type ${type}`)
  }

  return (
    <Badge
      type={badgeType}
      text={clipTypeLocalizedString(type)}
      size={BadgeSize.small}
    />
  )
}

export { ClipTypeBadge }
