import { useQuery } from '@apollo/client'
import { Controller } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { Loader } from '../../../../components'
import { GET_PRODUCTS_BY_BRAND_ID } from '../../../../gql'
import { Brand, GetProductsSort } from '../../../../gql/types'
import { BrandRoutes } from '../../../BrandRoutes'
import { DropdownSearch } from './DropdownSearch'

interface Props {
  brand: Brand
  control: any
  errors: any
}

const EditProductDetails = ({ brand, control, errors }: Props) => {
  const { loading, error, data } = useQuery(GET_PRODUCTS_BY_BRAND_ID, {
    fetchPolicy: 'network-only',
    variables: {
      brandId: brand.id,
      options: {
        query: '',
        filters: {},
        sort: GetProductsSort.newest,
        limit: 100,
      },
    },
  })

  if (loading) {
    return <Loader />
  }

  if (error) {
    console.log('error GET_PRODUCTS_BY_BRAND_ID', error.message)
    return <p>There has been an error. Try refreshing the page</p>
  }

  const products = data?.getProductsByBrandId?.items

  return (
    <div>
      <div className='mt-5 sm:mt-0 md:grid md:grid-cols-3 md:gap-6'>
        <div className='md:col-span-1'>
          <div className=''>
            <h3 className='text-lg font-semibold leading-6 text-gray-900 font-sans'>
              Product
            </h3>
            <p className='mt-1 text-sm font-normal leading-5 text-gray-500 font-sans'>
              What are your products’ features? Write out any details you
              consider set your product apart.
            </p>
          </div>
        </div>
        <div className='mt-5 md:col-span-2 md:mt-0'>
          <div className='md:shadow rounded-t-md'>
            <div className='py-5 md:bg-white space-y-6 sm:p-6'>
              <div className='grid gap-6'>
                <div className='col-span-3 sm:col-span-2'>
                  <Controller
                    name='requestedProduct'
                    control={control}
                    rules={{ required: `Please set a product.` }}
                    render={({ field }) => (
                      <DropdownSearch
                        options={products}
                        field={field}
                        error={errors?.requestedProduct?.message}
                      />
                    )}
                  />
                  <div className='block mt-4'>
                    <Link
                      key={`createNewProduct`}
                      to={BrandRoutes.newProduct.replace(':id', `${brand.id}`)}
                      className='text-base text-sm font-medium rounded-md text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                    >
                      Create new product?
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='hidden sm:block' aria-hidden='true'>
        <div className='py-5'>
          <div className='border-t border-gray-200' />
        </div>
      </div>
    </div>
  )
}

export { EditProductDetails }
