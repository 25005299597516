import React from 'react'

import { useQuery } from '@apollo/client'
import {
  GET_CLIPS_BY_BRAND_CREATOR,
  GetClipsByBrandAndCreatorResponse,
} from '../../gql'
import {
  Brand,
  Creator,
  GetClipsSort,
  QueryGetClipsByBrandAndCreatorArgs,
} from '../../gql/types'
import { Divider } from '../Divider'
import { Icon, IconType } from '../Icon'
import { Slider, SliderProps } from '../Slider'
import { ContentGrid } from '../ContentGrid'
import { LIMIT_DEFAULT } from '../../utils/constants'

interface IProps extends Omit<SliderProps, 'children'> {
  creator: Creator
  brand: Brand
  type?: string
}

export const ViewAllClipsSlider: React.FC<IProps> = ({
  setOpen,
  open,
  creator,
  brand,
  type,
}) => {
  const limit = LIMIT_DEFAULT

  const { loading, data, fetchMore } = useQuery<
    GetClipsByBrandAndCreatorResponse,
    QueryGetClipsByBrandAndCreatorArgs
  >(GET_CLIPS_BY_BRAND_CREATOR, {
    variables: {
      creatorId: creator.id,
      brandId: brand.id,
      options: {
        query: '',
        filters: {},
        sort: GetClipsSort.newest,
        limit,
        skip: 0,
      },
    },
    skip: !open,
  })

  const fetchData = () => {
    if (loading) {
      return
    }

    // if we have all the data, don't fetch more
    if (
      data?.getClipsByBrandAndCreator?.items?.length >=
      data?.getClipsByBrandAndCreator?.pagination?.numberOfHits
    ) {
      return
    }

    fetchMore({
      variables: {
        creatorId: creator.id,
        brandId: brand.id,
        options: {
          query: '',
          filters: {},
          sort: GetClipsSort.newest,
          limit,
          skip: data?.getClipsByBrandAndCreator?.items?.length ?? 0,
        },
      },
    })
  }

  return (
    <Slider setOpen={setOpen} open={open} size='md'>
      <div className='flex h-full flex-col overflow-y-scroll bg-white shadow-xl'>
        {/* Header */}

        <div className='sm:sticky top-0 z-10 bg-white'>
          <div className='px-4 pt-6 sm:px-6'>
            <div className='flex items-start justify-between'>
              <h3 className='text-xl font-bold text-gray-900'>Clips</h3>
              <button
                type='button'
                className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none'
                onClick={() => setOpen(false)}
              >
                <span className='sr-only'>Close panel</span>
                <Icon
                  type={IconType.x}
                  className='h-6 w-6'
                  aria-hidden='true'
                />
              </button>
            </div>
          </div>
          <Divider className='py-6' />
        </div>

        {/* Main */}
        <div className='pb-1 sm:pb-6 px-4 sm:px-6 overflow-y-scroll'>
          <ContentGrid
            clips={data?.getClipsByBrandAndCreator?.items || []}
            hasMore={
              data?.getClipsByBrandAndCreator?.items?.length <
              data?.getClipsByBrandAndCreator?.pagination?.numberOfHits
            }
            fetchData={fetchData}
            loading={loading}
            allowDownload={true}
            type={type}
            useWindow={false}
          />
        </div>
      </div>
    </Slider>
  )
}
