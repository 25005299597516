import { useMutation } from '@apollo/client'
import { useEffect, useState } from 'react'
import { Button, ButtonColor, NotificationType, Tag } from '../../../components'
import { UPDATE_CREATOR } from '../../../gql'
import {
  Creator,
  CreatorInterests,
  CreatorOnboardingStep,
} from '../../../gql/types'
import { AnalyticsEvent, trackEvent } from '../../../utils/analytics'
import { capitalize } from '../../../utils/helpers'
import { Bullets } from '../components/Bullets'
import { Steps } from '../components/Steps'
import { useNotification } from '../../../utils/hooks'

interface Props {
  creator: Creator
}

const SetInterests = ({ creator }: Props) => {
  const [updateCreator, { loading }] = useMutation(UPDATE_CREATOR)
  const [error, setError] = useState(null)
  const { addNotification } = useNotification()

  const [selectedInterests, setSelectedInterests] = useState(
    creator?.interests ?? [],
  )

  const isInterestSelected = (category) => {
    return selectedInterests?.find((value) => value === category) ? true : false
  }

  const selectInterest = (newTopic) => {
    let newSelectedTopics = [...selectedInterests]
    if (isInterestSelected(newTopic)) {
      onSetError(selectedInterests.length - 1)
      newSelectedTopics.splice(newSelectedTopics.indexOf(newTopic), 1)
      return setSelectedInterests(newSelectedTopics)
    }

    onSetError(selectedInterests.length + 1)
    return setSelectedInterests([...selectedInterests, newTopic])
  }

  useEffect(() => {
    trackEvent(AnalyticsEvent.viewCreatorsOnboardingSetInterests)
  }, [])

  const goBack = async () => {
    try {
      await updateCreator({
        variables: {
          id: creator.id,
          input: {
            onboarding: CreatorOnboardingStep.setAbout,
          },
        },
      })
    } catch (error) {
      addNotification(error.message, NotificationType.error)
    }
  }

  const onSetError = async (newSelectedTopicsLength) => {
    if (newSelectedTopicsLength === 0) {
      setError('Please select the interests.')
      return
    }

    setError(null)
  }

  const onSubmit = async () => {
    if (!selectedInterests || selectedInterests.length === 0) {
      setError('Please select the interests.')
      return
    }

    try {
      await updateCreator({
        variables: {
          id: creator.id,
          input: {
            interests: selectedInterests,
            onboarding: CreatorOnboardingStep.setIntroVideo,
          },
        },
      })
    } catch (error) {
      console.log('@error SetInterests', error.message)
    }
  }

  return (
    <div className='min-h-screen flex flex-col'>
      <div className='flex-grow'>
        <Steps
          creator={creator}
          stepCurrent={CreatorOnboardingStep.setInterests}
        />

        <div className='min-h-full flex flex-col justify-center pt-4 px-4 md:mt-8 lg:px-8'>
          <div className='sm:mx-auto sm:w-full sm:max-w-2xl'>
            <div className='md:shadow rounded-t-md mb-8'>
              <div className='py-5 md:bg-white space-y-6 sm:p-6'>
                <div className='sm:mx-auto'>
                  <h2 className='mt-0 md:text-center text-3xl font-extrabold text-gray-900'>
                    Interests
                  </h2>
                  <p className='mt-2 md:text-center text-sm text-gray-600'>
                    Tell us more about what you like to get personalised
                    recommendations.
                  </p>
                </div>

                <div className='space-y-4'>
                  {Object.keys(CreatorInterests).map((key) => {
                    return (
                      <Tag
                        key={key}
                        value={key}
                        title={capitalize(key)}
                        isSelected={isInterestSelected(key)}
                        selectTag={(value) => {
                          selectInterest(value)
                        }}
                        className='mr-2 cursor-pointer'
                      />
                    )
                  })}
                </div>
                {error && <p className='mt-2 text-sm text-red-600'>{error}</p>}
              </div>

              <div className='flex justify-between md:shadow rounded-b-md py-3 md:bg-gray-50 text-right sm:px-6'>
                <Button
                  title='Go back'
                  type='button'
                  colour={ButtonColor.white}
                  loading={loading}
                  disabled={loading}
                  onClick={goBack}
                />
                <Button
                  title='Continue'
                  type='submit'
                  loading={loading}
                  disabled={loading}
                  onClick={onSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Bullets
        creator={creator}
        stepCurrent={CreatorOnboardingStep.setInterests}
      />
    </div>
  )
}

export { SetInterests }
