import { gql } from '@apollo/client'
import { BrandFragment } from './brands'
import { PaginationFragment } from './common'
import { GetUploadUrlResponse, Product } from './types'

export const ProductFragment = gql`
  fragment ProductFragment on Product {
    id
    name
    type
    link
    details
    price
    currency
    createdAt
    updatedAt

    photo {
      name
      url
    }
  }
`

export const GET_PRODUCTS_BY_BRAND_ID = gql`
  query GetProductsByBrandId($brandId: Int!, $options: GetProductsOptions!) {
    getProductsByBrandId(brandId: $brandId, options: $options) {
      items {
        ...ProductFragment
        brand {
          ...BrandFragment
        }
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${ProductFragment}
  ${BrandFragment}
  ${PaginationFragment}
`

export const GET_PRODUCT_BY_ID = gql`
  query GetProductById($id: Int!) {
    getProductById(id: $id) {
      ...ProductFragment
      brand {
        ...BrandFragment
      }
    }
  }
  ${ProductFragment}
  ${BrandFragment}
`

export const CREATE_PRODUCT = gql`
  mutation CreateProduct($brandId: Int!, $input: CreateProductInput!) {
    createProduct(brandId: $brandId, input: $input) {
      ...ProductFragment
    }
  }
  ${ProductFragment}
`

export const UPDATE_PRODUCT = gql`
  mutation UpdateProduct($id: Int!, $input: UpdateProductInput!) {
    updateProduct(id: $id, input: $input) {
      ...ProductFragment
    }
  }
  ${ProductFragment}
`

export const DELETE_PRODUCT = gql`
  mutation DeleteProduct($productId: Int!) {
    deleteProduct(productId: $productId)
  }
`

export const GET_PRODUCT_PHOTO_UPLOAD_URL = gql`
  mutation GetProductPhotoUploadUrl($brandId: Int!, $mime: String!) {
    getProductPhotoUploadUrl(brandId: $brandId, mime: $mime) {
      uploadUrl
      fileName
    }
  }
`

export const GET_LATEST_CREATED_PRODUCT_BY_BRAND_ID = gql`
  query GetLatestCreatedProductByBrandId($brandId: Int!) {
    getLatestCreatedProductByBrandId(brandId: $brandId) {
      ...ProductFragment
      brand {
        ...BrandFragment
      }
    }
  }
  ${BrandFragment}
  ${ProductFragment}
`

export interface GetProductPhotoUploadUrlResponse {
  getProductPhotoUploadUrl: GetUploadUrlResponse
}

export interface GetLatestCreatedProductByBrandId {
  getLatestCreatedProductByBrandId: Product
}
