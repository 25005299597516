import classNames from 'classnames'
import { Pagination as PaginationComponent } from '../../../../components'
import { Campaign, Creator, Pagination } from '../../../../gql/types'
import { CampaignCard } from '../CampaignCard'
import { PricingData } from '../../../../utils/PricingUtil'

interface IProps {
  creator: Creator
  campaigns: { campaign: Campaign; invitationId?: number | null }[]
  pagination: Pagination
  pricing: PricingData
  setPage(page: number): void
  onRefetch: () => void
}

const TableHead = () => {
  return (
    <thead className='bg-white'>
      <tr>
        <th
          scope='col'
          className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-600 sm:pl-6'
        >
          Product
        </th>

        <th
          scope='col'
          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-600'
        >
          Earnings
        </th>

        <th
          scope='col'
          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-600'
        >
          Campaign Details
        </th>
        <th></th>
        <th></th>
      </tr>
    </thead>
  )
}

const List = ({
  creator,
  campaigns,
  pagination,
  pricing,
  setPage,
  onRefetch,
}: IProps) => {
  const onAppliedToCampaign = () => {
    onRefetch()
  }

  return (
    <div className='flex flex-col'>
      <table className='min-w-full divide-y divide-gray-200'>
        <TableHead />
        <tbody className='divide-y divide-gray-200 bg-white'>
          {pagination.numberOfHits === 0 && (
            <tr>
              <td colSpan={6}>
                <p className='py-4 pl-4 pr-3 text-sm sm:pl-6 w-full'>
                  No opportunities found.
                </p>
              </td>
            </tr>
          )}

          {campaigns.map((item, index) => (
            <tr
              key={item.campaign.id}
              className={classNames(
                index % 2 === 0 ? 'bg-gray-50' : 'bg-white',
              )}
            >
              <CampaignCard
                creator={creator}
                campaign={item.campaign}
                invitationId={item.invitationId}
                pricing={pricing}
                onAppliedToCampaign={onAppliedToCampaign}
              />
            </tr>
          ))}
        </tbody>
      </table>

      {pagination && (
        <PaginationComponent
          pagination={pagination}
          setPage={setPage}
          className='bg-white'
        />
      )}
    </div>
  )
}

export { List }
