import moment from 'moment'
import React from 'react'
import { CollaborationStatus as CollaborationStatusType } from '../../../../gql/types'

interface IProps {
  status: CollaborationStatusType
  updatedAt: Date
}

export const CollaborationStatusTime: React.FC<IProps> = ({
  status,
  updatedAt,
}) => {
  const getText = (): string | undefined => {
    switch (status) {
      case CollaborationStatusType.creatorApplied:
        return 'Creator has applied'
      case CollaborationStatusType.creatorAccepted:
        return 'Creator was approved'
      case CollaborationStatusType.productShipped:
        return 'Product was shipped'
      case CollaborationStatusType.productDelivered:
        return 'Product was delivered'
      case CollaborationStatusType.contentUploaded:
        return 'Content uploaded'
      case CollaborationStatusType.contentAccepted:
        return 'Content accepted'
      case CollaborationStatusType.contentRejected:
        return 'Content rejected'
    }
  }

  const text = getText()

  if (!text) {
    return <></>
  }

  return (
    <div className='flex flex-col'>
      <div className='text-gray-900 text-sm font-medium'>{text}</div>
      <div className='text-gray-600 text-sm'>{moment(updatedAt).fromNow()}</div>
    </div>
  )
}
