import React from 'react'

const Header = () => {
  return (
    <div className='sm:flex sm:items-center -mx-4 sm:-mx-6 md:mx-0 py-4'>
      <div className='sm:flex-auto'>
        <div className='flex flex-col'>
          <h1 className='font-bold text-3xl font-outfit'>Clips</h1>
          <p className='mt-2 text-sm text-gray-500 max-w-2xl'>
            Videos made for your brand by creators on Clip
          </p>
        </div>
      </div>
    </div>
  )
}

export { Header }
