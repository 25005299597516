import { useMutation } from '@apollo/client'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import LOGO from '../../../assets/logo/lancape_no_bg.png'
import {
  Button,
  ButtonColor,
  NotificationType,
  TextField,
} from '../../../components'
import { UPDATE_CREATOR, UpdateCreatorResponse } from '../../../gql'
import {
  Creator,
  CreatorOnboardingStep,
  MutationUpdateCreatorArgs,
} from '../../../gql/types'
import { AnalyticsEvent, trackEvent } from '../../../utils/analytics'
import { useNotification } from '../../../utils/hooks'

export type EditPaypalEmailFormData = {
  paypalEmail: string
}

interface Props {
  creator: Creator
}

const SetPaypalEmailAddress = ({ creator }: Props) => {
  const { addNotification } = useNotification()

  const [updateCreator, { loading }] = useMutation<
    UpdateCreatorResponse,
    MutationUpdateCreatorArgs
  >(UPDATE_CREATOR)

  const {
    register,
    handleSubmit,
    control,
    setValue,
    // setError,
    formState: { errors },
    watch,
  } = useForm<EditPaypalEmailFormData>({
    defaultValues: {
      paypalEmail: creator?.paypalEmail ?? null,
    },
  })

  useEffect(() => {
    trackEvent(AnalyticsEvent.viewCreatorsOnboardingSetPaypal)
  }, [])

  const goBack = async () => {
    try {
      await updateCreator({
        variables: {
          id: creator.id,
          input: {
            onboarding: CreatorOnboardingStep.setShippingAddress,
          },
        },
      })
    } catch (error) {
      addNotification(error.message, NotificationType.error)
    }
  }

  const onSubmit = async (data?: EditPaypalEmailFormData) => {
    try {
      await updateCreator({
        variables: {
          id: creator.id,
          input: {
            paypalEmail: data?.paypalEmail,
            onboarding: CreatorOnboardingStep.agreeTerms,
          },
        },
      })
    } catch (error) {
      console.log('@error SetProfile', error?.message)
      addNotification(error?.message, NotificationType.error)
    }
  }

  const PaypalEmailField = () => {
    return (
      <TextField
        // label='Paypal Email Address'
        name='paypalEmail'
        placeholder={creator.paypalEmail}
        error={errors.paypalEmail?.message}
        {...register('paypalEmail', {
          // required: `Please set an paypal email address.`,
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: `Please use a real paypal email address.`,
          },
          maxLength: {
            value: 100,
            message: `Please use a real paypal email address.`,
          },
        })}
      />
    )
  }

  return (
    <div className='min-h-screen flex flex-col'>
      <div className='flex-grow'>
        <div className='min-h-full flex flex-col justify-center pt-4 px-4 md:mt-8 lg:px-8'>
          <div className='sm:mx-auto sm:w-full sm:max-w-2xl'>
            <div className='lg:col-span-6 lg:block xl:col-span-2 pb-14'>
              <div className='space-y-6 sm:p-6'>
                <img className='h-12 w-auto' src={LOGO} alt='Workflow' />
                <div className='sm:mx-auto'>
                  <h2 className='mt-0 text-left text-3xl font-extrabold text-gray-900'>
                    Paypal Email Address
                  </h2>
                  <p className='mt-2 text-gray-500 text-sm font-base'>
                    Please enter the email address associated with your Paypal
                    account so that we can process your earnings.
                  </p>

                  <form onSubmit={handleSubmit(onSubmit)} className='mt-6'>
                    <PaypalEmailField />

                    <div className='flex justify-between py-3 text-right sm:px-6'>
                      <Button
                        title='Go back'
                        type='button'
                        colour={ButtonColor.white}
                        loading={loading}
                        disabled={loading}
                        onClick={goBack}
                        className='mt-4'
                      />

                      <Button
                        title='Continue'
                        type='submit'
                        className='mt-4'
                        loading={loading}
                        disabled={loading}
                      />
                    </div>

                    <p
                      className='text-sm mt-4 text-center cursor-pointer'
                      onClick={() => onSubmit()}
                    >
                      I'll add this later
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { SetPaypalEmailAddress }
