import classNames from 'classnames'
import { FunctionComponent, ReactElement, ReactNode } from 'react'
import { Icon, IconType } from '../Icon'

export enum BadgeType {
  gray = 'gray',
  error = 'error',
  warning = 'warning',
  success = 'success',
  violet = 'violet',
  sky = 'sky',
  orange = 'orange',
  rose = 'rose',
  slate = 'slate',
  zinc = 'zinc',
  neutral = 'neutral',
  stone = 'stone',
  lime = 'lime',
  emerald = 'emerald',
  teal = 'teal',
  cyan = 'cyan',
  blue = 'blue',
  indigo = 'indigo',
  purple = 'purple',
  fuchsia = 'fuchsia',
  pink = 'pink',
  amber = 'amber',
}

export enum BadgeStyle {
  fill = 'fill',
  ghost = 'ghost',
  outline = 'outline',
}

export enum BadgeSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

interface IDefaultProps {
  style: BadgeStyle
  size: BadgeSize
  iconType?: IconType
  iconPosition?: 'left' | 'right'
  icon?: ReactElement
  className?: string
  onClick(e): void
}

interface IProps extends Partial<IDefaultProps> {
  type: BadgeType
  text: ReactNode
}

const defaultProps: IDefaultProps = {
  style: BadgeStyle.fill,
  size: BadgeSize.medium,
  iconType: null,
  iconPosition: null,
  icon: undefined,
  className: null,
  onClick: null,
}

const Badge: FunctionComponent<IProps> = ({
  type,
  text,
  style,
  size,
  iconType,
  iconPosition,
  icon,
  className,
  onClick,
}) => {
  let bgColor = 'bg-gray-100',
    color = 'text-zinc-700',
    borderColor = 'border-gray-600'
  switch (type) {
    case BadgeType.error:
      bgColor = 'bg-red-50'
      color = 'text-red-700'
      borderColor = 'border-red-600'
      break
    case BadgeType.warning:
      bgColor = 'bg-yellow-50'
      color = 'text-yellow-700'
      borderColor = 'border-yellow-600'
      break
    case BadgeType.success:
      bgColor = 'bg-green-50'
      color = 'text-green-700'
      borderColor = 'border-green-600'
      break
    case BadgeType.violet:
      bgColor = 'bg-violet-50'
      color = 'text-violet-700'
      borderColor = 'border-violet-600'
      break
    case BadgeType.sky:
      bgColor = 'bg-sky-50'
      color = 'text-sky-700'
      borderColor = 'border-sky-600'
      break
    case BadgeType.rose:
      bgColor = 'bg-rose-50'
      color = 'text-rose-700'
      borderColor = 'border-rose-600'
      break
    case BadgeType.orange:
      bgColor = 'bg-orange-50'
      color = 'text-orange-700'
      borderColor = 'border-orange-600'
      break
    case BadgeType.slate:
      bgColor = 'bg-slate-50'
      color = 'text-slate-700'
      borderColor = 'border-slate-600'
      break
    case BadgeType.zinc:
      bgColor = 'bg-zinc-50'
      color = 'text-zinc-700'
      borderColor = 'border-zinc-600'
      break
    case BadgeType.neutral:
      bgColor = 'bg-neutral-50'
      color = 'text-neutral-700'
      borderColor = 'border-neutral-600'
      break
    case BadgeType.stone:
      bgColor = 'bg-stone-50'
      color = 'text-stone-700'
      borderColor = 'border-stone-600'
      break
    case BadgeType.lime:
      bgColor = 'bg-lime-50'
      color = 'text-lime-700'
      borderColor = 'border-lime-600'
      break
    case BadgeType.emerald:
      bgColor = 'bg-emerald-50'
      color = 'text-emerald-700'
      borderColor = 'border-emerald-600'
      break
    case BadgeType.teal:
      bgColor = 'bg-teal-50'
      color = 'text-teal-700'
      borderColor = 'border-teal-600'
      break
    case BadgeType.cyan:
      bgColor = 'bg-cyan-50'
      color = 'text-cyan-700'
      borderColor = 'border-cyan-600'
      break
    case BadgeType.blue:
      bgColor = 'bg-blue-50'
      color = 'text-blue-700'
      borderColor = 'border-blue-600'
      break
    case BadgeType.indigo:
      bgColor = 'bg-indigo-50'
      color = 'text-indigo-700'
      borderColor = 'border-indigo-600'
      break
    case BadgeType.purple:
      bgColor = 'bg-purple-50'
      color = 'text-purple-700'
      borderColor = 'border-purple-600'
      break
    case BadgeType.fuchsia:
      bgColor = 'bg-fuchsia-50'
      color = 'text-fuchsia-700'
      borderColor = 'border-fuchsia-600'
      break
    case BadgeType.pink:
      bgColor = 'bg-pink-50'
      color = 'text-pink-700'
      borderColor = 'border-pink-600'
      break
    case BadgeType.amber:
      bgColor = 'bg-amber-50'
      color = 'text-amber-700'
      borderColor = 'border-amber-600'
      break
    default:
      break
  }

  let fontSize = 'text-sm',
    fontWeight = 'font-medium',
    py = 'py-[2px]',
    px = 'px-[10px]',
    wIcon = 'w-3.5',
    hIcon = 'h-3.5'
  switch (size) {
    case BadgeSize.small:
      fontSize = 'text-xs'
      py = 'py-[2px]'
      px = 'px-[8px]'
      wIcon = 'w-3'
      hIcon = 'h-3'
      break
    case BadgeSize.medium:
      fontSize = 'text-sm'
      py = 'py-[2px]'
      px = 'px-[10px]'
      wIcon = 'w-3.5'
      hIcon = 'h-3.5'
      break
    case BadgeSize.large:
      fontSize = 'text-sm'
      py = 'py-[4px]'
      px = 'px-[12px]'
      wIcon = 'w-4'
      hIcon = 'h-4'
      break
    default:
      break
  }

  if (style === BadgeStyle.ghost) {
    bgColor = null
    borderColor = null
  }

  if (style === BadgeStyle.outline) {
    bgColor = null
  }

  return (
    <span
      className={classNames(
        `${bgColor} ${color} ${fontSize} ${fontWeight} ${py} ${px}`,
        `${
          bgColor === null && borderColor !== null
            ? `border ${borderColor}`
            : null
        }`,
        'inline-flex items-center mr-2 rounded-full',
        className,
      )}
      onClick={(e) => onClick && onClick(e)}
    >
      {icon && iconPosition === 'left' && (
        <div
          className={classNames(
            `${bgColor} ${color} ${fontSize} ${fontWeight} ${wIcon} ${hIcon}`,
            'inline-flex items-center mr-[7px]',
          )}
        >
          {icon}
        </div>
      )}
      {iconType && iconPosition === 'left' && (
        <Icon
          type={iconType}
          className={classNames(
            `${bgColor} ${color} ${fontSize} ${fontWeight} ${wIcon} ${hIcon}`,
            'inline-flex items-center mr-[7px]',
          )}
        />
      )}
      {text}
      {iconType && iconPosition === 'right' && (
        <Icon
          type={iconType}
          className={classNames(
            `${bgColor} ${color} ${fontSize} ${fontWeight} ${wIcon} ${hIcon}`,
            'inline-flex items-center ml-[7px]',
          )}
        />
      )}
      {icon && iconPosition === 'right' && (
        <div
          className={classNames(
            `${bgColor} ${color} ${fontSize} ${fontWeight} ${wIcon} ${hIcon}`,
            'inline-flex items-center ml-[7px]',
          )}
        >
          {icon}
        </div>
      )}
    </span>
  )
}

Badge.defaultProps = defaultProps

export { Badge }
