import { useMutation, useReactiveVar } from '@apollo/client'
import Cookies from 'js-cookie'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link, useSearchParams } from 'react-router-dom'
import validator from 'validator'
import LOGO from '../../assets/logo/lancape_no_bg.png'
import {
  Button,
  CheckboxItem,
  CheckboxType,
  Icon,
  IconType,
  RadioGroup,
  TextField,
} from '../../components'
import { CREATE_USER } from '../../gql'
import {
  BrandType,
  CountryCode,
  Currency,
  Session,
  User,
  UserType,
} from '../../gql/types'
import { ErrorCode } from '../../utils/ErrorCode'
import { PublicRoutes } from '../../utils/PublicRoutes'
import {
  AnalyticsEvent,
  trackCompleteRegistration,
  trackEvent,
} from '../../utils/analytics'
import { AUTH_TOKEN, Locale } from '../../hooks'
import { brandTypeOptions } from '../../utils/mappers'
import { Params, captureQueryParams } from '../../utils/params'
import { formatPhoneNumber, validateE164PhoneNumber } from '../../utils/helpers'

export type CreateUserFormData = {
  firstName: string
  lastName: string
  email: string
  phoneNumberCountry: CountryCode
  phoneNumber: string
  password: string
  countryCode: CountryCode
  currency: Currency
  timezone: string
  utcOffset: number
  isMarketingEmailsSubscribed: boolean
  brandType: string | BrandType
}

const RegisterBrand = () => {
  const [searchParams] = useSearchParams()
  captureQueryParams(searchParams)

  const params = Object.fromEntries(searchParams)
  const locale = useReactiveVar(Locale)

  const [createUser, { loading }] = useMutation<{
    createUser: {
      user: User
      session: Session
    }
  }>(CREATE_USER)

  useEffect(() => {
    trackEvent(AnalyticsEvent.viewBrandsRegister)
  }, [])

  const {
    register,
    handleSubmit,
    control,
    setError,
    setValue,
    formState: { errors },
    watch,
  } = useForm<CreateUserFormData>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: params.email ?? '',
      phoneNumberCountry: locale.countryCode,
      phoneNumber:
        params.phoneNumber ??
        `+ ${locale.countryCode === 'US' ? '1' : locale.countryCode === 'GB' ? '44' : locale.countryCode === 'AU' ? '61' : ''}`,
      password: '',
      countryCode: locale.countryCode,
      currency: locale.currency,
      timezone: locale.timezone,
      utcOffset: locale.utcOffset,
      isMarketingEmailsSubscribed: true,
      brandType: params.type ?? BrandType.brand,
    },
  })

  const onSubmit = async (form: CreateUserFormData) => {
    try {
      trackCompleteRegistration()
    } catch (error) {
      console.log('Error tracking complete registration', error)
    }

    try {
      const { data } = await createUser({
        variables: {
          input: {
            type: form.brandType ?? UserType.brand,
            firstName: form.firstName,
            lastName: form.lastName,
            email: form.email,
            password: form.password,
            countryCode: form.countryCode,
            brandType: form.brandType,
            phoneNumberCountry: form.phoneNumberCountry,
            phoneNumber: formatPhoneNumber(form.phoneNumber),
            currency: form.currency,
            timezone: form.timezone,
            utcOffset: form.utcOffset,
            isMarketingEmailsSubscribed:
              form.isMarketingEmailsSubscribed ||
              // @ts-ignore
              form.isMarketingEmailsSubscribed === 'true'
                ? true
                : false,
            fbp: Cookies.get('_fbp'),
            fbc: Cookies.get('_fbc'),
            brandName: params.name ?? null,
            utmSource: localStorage.getItem(Params.utm_source),
            utmMedium: localStorage.getItem(Params.utm_medium),
            utmCampaign: localStorage.getItem(Params.utm_campaign),
            utmTerm: localStorage.getItem(Params.utm_term),
            utmContent: localStorage.getItem(Params.utm_content),
          },
        },
      })

      localStorage.setItem(AUTH_TOKEN, data.createUser.session.token)
      window.location.reload()
    } catch (error) {
      if (error.message === ErrorCode.emailAlreadyTaken) {
        setError('email', { type: 'custom', message: 'Email already taken.' })
        return
      }
    }
  }

  return (
    <div>
      <div className='min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
        <div className='sm:mx-auto sm:w-full sm:max-w-lg'>
          <img className='mx-auto h-12 w-auto' src={LOGO} alt='Workflow' />
          <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>
            Create an account
          </h2>
          <p className='mt-2 text-center text-sm text-gray-600'>
            Are you a content creator?{' '}
            <Link
              to={PublicRoutes.registerCreator}
              className='font-medium text-indigo-500 hover:text-indigo-600'
            >
              Join as a creator
            </Link>
          </p>
        </div>

        <div className='mt-4 sm:mx-auto sm:w-full sm:max-w-lg'>
          <div className='py-8 px-4 sm:px-10'>
            <form className='space-y-6' onSubmit={handleSubmit(onSubmit)}>
              <div>
                <Controller
                  name='brandType'
                  control={control}
                  rules={{ required: `Please select a type.` }}
                  render={({ field }) => (
                    <RadioGroup
                      options={brandTypeOptions}
                      field={field}
                      error={errors?.brandType?.message}
                      className='grid-cols-2'
                    />
                  )}
                />
              </div>

              <div className='space-y-6 md:grid md:grid-cols-2 md:gap-2 md:space-y-0'>
                <div>
                  <TextField
                    error={errors.firstName?.message}
                    label='First name'
                    placeholder='Lisa'
                    {...register('firstName', {
                      required: `Please set a first name.`,
                      maxLength: {
                        value: 100,
                        message: `Your first name cannot have more than 100 letters.`,
                      },
                    })}
                  />
                </div>

                <div>
                  <TextField
                    error={errors.lastName?.message}
                    label='Last name'
                    placeholder='Mayer'
                    {...register('lastName', {
                      required: `Please set a last name.`,
                      maxLength: {
                        value: 100,
                        message: `Your last name cannot have more than 100 letters.`,
                      },
                    })}
                  />
                </div>
              </div>

              <div>
                <TextField
                  error={errors.email?.message}
                  label='Email'
                  placeholder='you@example.com'
                  {...register('email', {
                    required: `Please set an email.`,
                    maxLength: {
                      value: 100,
                      message: `Please keep your email under 100 letters.`,
                    },
                    validate: {
                      value: (email) => {
                        if (validator.isEmail(email)) {
                          return true
                        }

                        return 'Invalid email address.'
                      },
                    },
                  })}
                />
              </div>

              <div>
                <TextField
                  error={errors.phoneNumber?.message}
                  label='Phone Number (please include the + sign and country code)'
                  placeholder='+1 (855) 526-7321'
                  {...register('phoneNumber', {
                    // required: `Please set a phone number.`,
                    minLength: {
                      value: 6,
                      message: `Please set a real phone number.`,
                    },
                    maxLength: {
                      value: 20,
                      message: `Please set a real phone number.`,
                    },
                    validate: (value) => {
                      if (validateE164PhoneNumber(formatPhoneNumber(value))) {
                        return true
                      }

                      return 'Invalid phone number. Make sure your +(country code) is included.'
                    },
                  })}
                />
              </div>

              <div>
                <label
                  htmlFor='password'
                  className='block text-sm font-medium text-gray-700'
                >
                  Password
                </label>
                <div className='relative mt-1'>
                  <input
                    type='password'
                    placeholder='******'
                    className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-500 focus:outline-none focus:ring-gray-800 focus:border-gray-800 sm:text-sm'
                    {...register('password', {
                      required: `Please set a password.`,
                      minLength: {
                        value: 6,
                        message: `Please set a password with minimum 6 letters.`,
                      },
                      maxLength: {
                        value: 100,
                        message: `Please keep your password under 100 letters.`,
                      },
                    })}
                  />
                  {errors.password?.message && (
                    <div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
                      <Icon
                        type={IconType.exclamationCircleFill}
                        className='h-5 w-5 text-red-500'
                        aria-hidden='true'
                      />
                    </div>
                  )}
                </div>
                {errors.password?.message && (
                  <p className='mt-2 text-sm text-red-600' id='email-error'>
                    {errors.password?.message}
                  </p>
                )}
              </div>

              <div className='flex items-center justify-between'>
                <div className='flex items-center'>
                  <CheckboxItem
                    type={CheckboxType.checkbox}
                    value={!watch('isMarketingEmailsSubscribed')}
                    title='Email me about product updates and helpful resources'
                    {...register('isMarketingEmailsSubscribed')}
                    selected={watch('isMarketingEmailsSubscribed')}
                    className='-m-4'
                  />
                </div>
              </div>

              <div>
                <Button
                  title='Create account'
                  type='submit'
                  loading={loading}
                  disabled={loading}
                  width='w-full'
                />
              </div>
            </form>

            <div className='mt-6'>
              <div className='relative'>
                <div className='relative flex justify-center text-sm'>
                  <p className='px-2 text-center text-sm text-gray-600'>
                    Have an account already?{' '}
                    <Link
                      to={PublicRoutes.login}
                      className='font-medium text-indigo-500 hover:text-indigo-600'
                    >
                      Sign in
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className='flex flex-col my-auto items-center text-sm text-gray-600 font-normal p-5'>
          <p>
            By clicking “Create account”, I agree to Clip’s{' '}
            <a
              href={WebLink.tos}
              target='_blank'
              rel='noopener noreferrer'
              className='font-medium text-indigo-500 hover:text-indigo-600'
            >
              TOS
            </a>{' '}
            and{' '}
            <a
              href={WebLink.privacyPolicy}
              target='_blank'
              rel='noopener noreferrer'
              className='font-medium text-indigo-500 hover:text-indigo-600'
            >
              Privacy Policy
            </a>
          </p>
        </div> */}
      </div>
    </div>
  )
}

export { RegisterBrand }
