import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Icon, IconType, ListOptions, Loader } from '../../../components'
import {
  GetCollaborationsByCampaignIdResponse,
  GET_COLLABORATIONS_BY_CAMPAIGN_ID,
} from '../../../gql'
import {
  CollaborationStatus,
  GetCollaborationsOptions,
  GetCollaborationsSort,
  QueryGetCollaborationsByCampaignIdArgs,
  User,
} from '../../../gql/types'
import { Header } from '../components/Header'
import { List } from './components/List'
import { Grid } from './components/Grid'

interface IProps {
  user: User
}

const filterOptions = [
  {
    id: 'isFavorite',
    name: 'Favorites',
    options: [
      {
        value: true,
        label: 'View Favorite Collaborators',
        // icon: <Icon type={IconType.heartFilled} className='h-4 w-4 text-red-500' aria-hidden='true'/>
      },
      // { value: false, label: 'Not Favorites'},
    ],
  },
]

const Collaborators = (_props: IProps) => {
  const { campaignId } = useParams()

  const limit = 40
  const [skip, setSkip] = useState(0)

  const [query, setQuery] = useState('')
  const [filtersValue, setFiltersValue] = useState({
    status: {
      [CollaborationStatus.creatorApplied]: false,
      [CollaborationStatus.creatorAccepted]: true,
      [CollaborationStatus.productShipped]: true,
      [CollaborationStatus.productDelivered]: true,
      [CollaborationStatus.contentUploaded]: true,
      [CollaborationStatus.contentAccepted]: true,
      [CollaborationStatus.contentRejected]: true,
    },
    isFavorite: {
      ['true']: false,
      ['false']: false,
    },
  })

  const { loading, error, data, refetch } = useQuery<
    GetCollaborationsByCampaignIdResponse,
    QueryGetCollaborationsByCampaignIdArgs
  >(GET_COLLABORATIONS_BY_CAMPAIGN_ID, {
    variables: {
      campaignId: Number(campaignId),
      options: {
        query: '',
        filters: {
          status: [
            CollaborationStatus.creatorAccepted,
            CollaborationStatus.productShipped,
            CollaborationStatus.productDelivered,
            CollaborationStatus.contentUploaded,
            CollaborationStatus.contentAccepted,
            CollaborationStatus.contentRejected,
          ],
          isFavorite: null,
        },
        sort: GetCollaborationsSort.lastUpdated,
        skip,
        limit,
      },
    },
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    refetch()
  }, [skip, refetch])

  const refetchData = (query, filters, sort = null) => {
    const isFavoriteFilters = Object.keys(filters.isFavorite).filter(
      (key) => filters.isFavorite[key] === true,
    )

    refetch({
      options: {
        query: query,
        filters: {
          status: Object.keys(filters.status).filter(
            (key) => filters.status[key] === true,
          ) as CollaborationStatus[],
          isFavorite:
            isFavoriteFilters.length === 1
              ? isFavoriteFilters[0] === 'true'
              : null,
        },
        sort: sort,
        skip: 0,
        limit,
      },
    })
  }

  const onSearch = () => {
    refetchData(query, filtersValue)
  }

  const setPage = (page: number) => {
    setSkip(page * limit)
  }

  const onFiltersUpdate = (section, option) => {
    const newFiltersValue = {
      ...filtersValue,
      [section.id]: {
        ...filtersValue[section.id],
        [option.value]: !filtersValue[section.id][option.value],
      },
    }

    setFiltersValue(newFiltersValue)
    refetchData(query, newFiltersValue)
  }

  if (error) {
    console.log(error)
    return <p>There has been an error. Try refreshing the page</p>
  }

  if (!loading && !data?.getCollaborationsByCampaignId) {
    return <p>Campaign does not exist.</p>
  }

  return (
    <div>
      <Header />

      {loading && <Loader />}
      {!loading && (
        <div className='pb-4 w-auto h-fit'>
          <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='inline-block min-w-full py-2 px-1 align-middle md:px-6 lg:px-8'>
              <div className='shadow ring-1 ring-black ring-opacity-5 rounded-t'>
                <ListOptions
                  query={query}
                  setQuery={setQuery}
                  onSearch={onSearch}
                  filterOptions={filterOptions}
                  filtersValue={filtersValue}
                  onFiltersUpdate={onFiltersUpdate}
                />
                <div className='hidden lg:block'>
                  <List
                    collaborations={data?.getCollaborationsByCampaignId.items}
                    pagination={data?.getCollaborationsByCampaignId?.pagination}
                    setPage={setPage}
                    onRefetch={() => refetch()}
                  />
                </div>
                <div className='block lg:hidden'>
                  <Grid
                    collaborations={data?.getCollaborationsByCampaignId.items}
                    pagination={data?.getCollaborationsByCampaignId?.pagination}
                    setPage={setPage}
                    onRefetch={() => refetch()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export { Collaborators }
