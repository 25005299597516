import { useQuery } from '@apollo/client'
import { FunctionComponent } from 'react'
import { useParams } from 'react-router'
import { Button, Loader } from '../../../components'
import { GET_CREDIT_PACKS_WITH_BRAND } from '../../../gql/creditPacks'
import {
  Brand,
  Currency,
  GetCreditPacksResponse,
  Scalars,
  User,
} from '../../../gql/types'
import { CreditPacks } from '../../CreditPacks'
import { Header } from '../components/Header'
import { DEAL_CODE } from '../../../utils/constants'

interface IProps {
  user: User
  isOnboarding?: boolean
  brand: Brand
}

export const BrandCreditPacks: FunctionComponent<IProps> = ({
  user,
  brand,
  isOnboarding = false,
}) => {
  let { id: brandId } = useParams()

  const { data, loading, error } = useQuery<
    {
      getBrandById: Brand
      getCreditPacks: GetCreditPacksResponse
    },
    { brandId: Number; currency: String }
  >(GET_CREDIT_PACKS_WITH_BRAND, {
    variables: {
      brandId: brand.id,
      currency: brand.currency,
    },
  })

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <p>There has been an error. Try refreshing the page</p>
  }

  const onSkip = () => {
    localStorage.removeItem(DEAL_CODE)
    window.location.href = '/'
  }

  return (
    <div className='text-center'>
      {!isOnboarding && <Header />}
      <CreditPacks
        user={user}
        brand={data.getBrandById}
        creditPacks={data.getCreditPacks}
      />
      <div className='px-8'>
        {isOnboarding && (
          <Button
            title='Skip deal'
            onClick={onSkip}
            className='mb-8 w-full sm:w-[300px]'
          />
        )}
      </div>
    </div>
  )
}
