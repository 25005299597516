import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useRef } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import validator from 'validator'
import {
  Button,
  ButtonColor,
  Icon,
  IconType,
  NotificationType,
  TextField,
} from '../../../components'
import { Campaign } from '../../../gql/types'
import { cleanUrl } from '../../../utils/helpers'
import { isEmptyString, useNotification } from '../../../utils/hooks'
import { useUpdateCampaignMutation } from '../../../hooks'

type EditCampaignFormData = {
  inspirationVideosLinks: string[]
}

interface IProps {
  campaign: Campaign
  isOpen: boolean
  setIsOpen: (event: any) => void
}

export const UpdateInspirationLinksModal = ({
  campaign,
  isOpen,
  setIsOpen,
}: IProps) => {
  const { addNotification } = useNotification()
  const cancelButtonRef = useRef(null)

  const { updateCampaign, loading } = useUpdateCampaignMutation()

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
  } = useForm<EditCampaignFormData>({
    defaultValues: {
      inspirationVideosLinks: campaign?.inspirationVideosLinks ?? [],
    },
  })

  const {
    fields: inspirationVideosLinksFields,
    append: inspirationVideosLinksAppend,
    remove: inspirationVideosLinksRemove,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'inspirationVideosLinks' as never, // unique name for your Field Array
  })

  const onSubmit = async (data: EditCampaignFormData) => {
    try {
      await updateCampaign({
        variables: {
          id: campaign.id,
          input: {
            inspirationVideosLinks: data.inspirationVideosLinks
              .filter(
                (inspirationVideosLink) =>
                  !isEmptyString(inspirationVideosLink),
              )
              .map((inspirationVideosLink) => cleanUrl(inspirationVideosLink)),
          },
        },
      })

      reset()
      closeModal()
      addNotification(
        `Inspiration videos links updated`,
        NotificationType.success,
      )
    } catch (error) {
      addNotification(
        `Something went wrong. Please try again.`,
        NotificationType.error,
      )
    }
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-10'
        initialFocus={cancelButtonRef}
        onClose={setIsOpen}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed z-10 inset-0 overflow-y-auto'>
          <div className='flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:max-w-xl sm:w-full'>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
                    <div className='sm:flex sm:items-start'>
                      <div className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10'>
                        <div aria-hidden='true'>
                          <Icon
                            type={IconType.link}
                            className='h-5 w-5 text-blue-600'
                            aria-hidden='true'
                          />
                        </div>
                      </div>
                      <div className='mt-3 sm:mt-0 sm:ml-4 sm:text-left'>
                        <Dialog.Title
                          as='h3'
                          className='text-lg leading-6 font-medium text-gray-900'
                        >
                          Inspiration
                        </Dialog.Title>
                        <div className='my-2'>
                          <p className='text-sm text-gray-500'>
                            Share links to UGC examples that you would like
                            creators to use as inspiration for videos (public
                            links to Instagram, TikTok, Facebook, Google Drive
                            or others).
                          </p>
                        </div>
                        <div className='grid gap-6 max-h-[400px] overflow-y-auto '>
                          <div className='col-span-3 sm:col-span-2'>
                            <ul>
                              {inspirationVideosLinksFields.map(
                                (field, index) => (
                                  <li key={field.id} className='relative'>
                                    <div>
                                      <Controller
                                        name={`inspirationVideosLinks.${index}`}
                                        control={control}
                                        rules={{
                                          maxLength: {
                                            value: 1000,
                                            message: `The link cannot have more than 1000 characters.`,
                                          },
                                          validate: {
                                            value: (url) => {
                                              if (validator.isURL(url)) {
                                                return true
                                              }

                                              return 'Please enter a valid URL'
                                            },
                                          },
                                        }}
                                        render={({ field }) => (
                                          <TextField
                                            error={
                                              errors.inspirationVideosLinks?.[
                                                index
                                              ]?.message
                                            }
                                            placeholder='www.example.com'
                                            // className={'rounded-none rounded-r-md'}
                                            field={field}
                                            // leftElement={
                                            //   <span className='inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm'>
                                            //     https://
                                            //   </span>
                                            // }
                                            {...register(
                                              'inspirationVideosLinks',
                                            )}
                                            autoFocus={
                                              index ===
                                              inspirationVideosLinksFields.length
                                            }
                                          />
                                        )}
                                      />
                                    </div>
                                    <div
                                      onClick={() =>
                                        inspirationVideosLinksRemove(index)
                                      }
                                      className='absolute right-0 top-2 cursor-pointer z-10'
                                    >
                                      <Icon
                                        type={IconType.x}
                                        className='h-5 w-5 text-primary'
                                        aria-hidden='true'
                                      />
                                    </div>
                                  </li>
                                ),
                              )}
                            </ul>
                          </div>
                        </div>
                        <div className='pt-4 flex flex-row justify-between'>
                          <Button
                            title='Add link'
                            type='button'
                            colour={ButtonColor.primary}
                            onClick={() => inspirationVideosLinksAppend('')}
                            icon={
                              <Icon
                                type={IconType.plus}
                                className='h-5 w-5 text-white'
                                aria-hidden='true'
                              />
                            }
                            iconPosition='left'
                          />
                          <div className='w-full items-center'></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse'>
                    <Button
                      title='Update'
                      type='submit'
                      loading={loading}
                      className='w-full sm:w-auto'
                    />
                    <button
                      type='button'
                      className='mt-3 mr-3 w-full inline-flex justify-center items-center rounded-md border border-gray-300 shadow-sm px-4 py-1 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                      onClick={() => setIsOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
