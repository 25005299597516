import {
  GetInspirationsResponse,
  GetInspirationsSort,
  InspirationType,
  QueryGetInspirationsArgs,
  User,
  Inspiration as InspirationGqlType,
} from '../../gql/types'
import { useQuery } from '@apollo/client'
import { InspirationIndustry } from '../../components'
import { GET_INSPIRATIONS } from '../../gql'
import { Header } from './components/Header'
import { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import Masonry from 'react-masonry-css'
import { DownloadFileButton } from '../../components/DownloadFileButton'
import { VideoZoom } from '../../components/VideoZoom'
import InfiniteScroll from 'react-infinite-scroller'
import { LIMIT_DEFAULT } from '../../utils/constants'

interface IProps {
  user: User
}

const Inspiration = ({ user }: IProps) => {
  const [numCols, setNumCols] = useState(6)
  const [gridElement, setGridElement] = useState<HTMLDivElement | null>(null)

  const getNumColumns = (width: number) => {
    if (width >= 1536) return 6
    else if (width >= 1280) return 5
    else if (width >= 1024) return 4
    else if (width >= 768) return 3
    else return 2
  }

  const limit = LIMIT_DEFAULT
  const { loading, error, data, fetchMore } = useQuery<
    { getInspirations: GetInspirationsResponse },
    QueryGetInspirationsArgs
  >(GET_INSPIRATIONS, {
    variables: {
      options: {
        query: '',
        filters: {
          isAllVisible: true,
        },
        sort: GetInspirationsSort.lastUpdated,
        skip: 0,
        limit,
      },
    },
  })

  const fetchData = () => {
    if (loading) {
      return
    }

    // if we have all the data, don't fetch more
    if (
      data?.getInspirations?.items?.length >=
      data?.getInspirations?.pagination?.numberOfHits
    ) {
      return
    }

    fetchMore({
      variables: {
        options: {
          query: '',
          filters: {
            isAllVisible: true,
          },
          sort: GetInspirationsSort.lastUpdated,
          limit,
          skip: data?.getInspirations?.items?.length ?? 0,
        },
      },
    })
  }

  useEffect(() => {
    if (!gridElement) return

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const newNumCols = getNumColumns(entry.contentRect.width)
        setNumCols(newNumCols)
      }
    })

    resizeObserver.observe(gridElement)

    return () => {
      resizeObserver.disconnect()
    }
  }, [gridElement])

  if (error) {
    console.log(error)
    return <p>There has been an error. Try refreshing the page</p>
  }

  return (
    <div className='h-full'>
      <Header />

      <InfiniteScroll
        loadMore={fetchData}
        hasMore={
          data?.getInspirations?.items?.length <
          data?.getInspirations?.pagination?.numberOfHits
        }
        initialLoad={false}
        useWindow={true}
      >
        <div ref={setGridElement}>
          <Masonry
            breakpointCols={numCols}
            className='flex p-1 -ml-6 md:-ml-6 lg:-ml-8 xl:-ml-8 2xl:-ml-10'
            columnClassName='pl-6 space-y-6 md:pl-6 md:space-y-6 lg:pl-8 lg:space-y-8 xl:pl-8 xl:space-y-8 2xl:pl-10 2xl:space-y-10 '
          >
            {data?.getInspirations?.items?.map(
              (inspiration: InspirationGqlType, index) => (
                <div
                  key={index}
                  className='shadow-2xl overflow-hidden rounded-xl ring-1 ring-black ring-opacity-20 w-full relative'
                >
                  {inspiration.type === InspirationType.video && (
                    <VideoZoom
                      src={inspiration?.file?.url}
                      thumbnailSrc={inspiration?.thumbnail?.url}
                      className='cursor-pointer rounded-xl w-full object-cover hover:opacity-75'
                    />
                  )}
                  {inspiration.industry && (
                    <div className='absolute right-0 top-2 text-xs font-medium'>
                      <InspirationIndustry industry={inspiration.industry} />
                    </div>
                  )}
                  <div className='absolute right-2 bottom-3 gap-y-1 overflow-hidden text-sm leading-6 text-white'>
                    <DownloadFileButton
                      fileUrl={inspiration?.file?.url}
                      fileName={inspiration?.file?.name}
                    />
                  </div>
                </div>
              ),
            )}
            {loading &&
              [...Array(limit)].map((x, i) => (
                <div key={i}>
                  <Skeleton className='shadow-2xl overflow-hidden !rounded-xl ring-1 ring-black ring-opacity-20 w-full relative aspect-[9/16]' />
                </div>
              ))}
          </Masonry>
        </div>
      </InfiniteScroll>
      {data?.getInspirations?.items?.length >=
        data?.getInspirations?.pagination?.numberOfHits && (
        <div className='flex justify-center py-2'>
          <span className='text-gray-500 text-sm'>No more content to load</span>
        </div>
      )}
    </div>
  )
}

export { Inspiration }
