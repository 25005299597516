import { Pagination } from '../../../../components'
import { Pagination as IPagination, Campaign } from '../../../../gql/types'
import { GridItem } from '../GridItem'

interface IProps {
  campaigns: Campaign[]
  pagination: IPagination
  setPage(page: number): void
}

const Grid = ({ campaigns, pagination, setPage }: IProps) => {
  return (
    <>
      <ul
        role='list'
        className='py-2 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8'
      >
        {campaigns?.map((campaign) => (
          <GridItem campaign={campaign} key={`gridItem-${campaign?.uuid}`} />
        ))}
        {campaigns?.length === 0 && (
          <div className='py-4 pl-4 pr-3 text-sm sm:pl-6 w-full'>
            No results for this search.
          </div>
        )}
      </ul>
      {pagination && <Pagination pagination={pagination} setPage={setPage} />}
    </>
  )
}

export { Grid }
