import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import qs from 'qs'
import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import { useForm } from 'react-hook-form'
import Cookies from 'js-cookie'
import {
  CountryCode,
  Currency,
  Session,
  TeamInvitation,
  TeamInvitationStatus,
  User,
  UserType,
} from '../../gql/types'
import validator from 'validator'
import { Error } from './components/Error'
import { AcceptInvitationCreator } from './components/AcceptInvitationCreator'
import { AcceptInvitationBrand } from './components/AcceptInvitationBrand'
import { InvitationAccepted } from './components/InvitationAccepted'
import LOGO from '../../assets/logo/lancape_no_bg.png'
import { ErrorCode } from '../../utils/ErrorCode'
import { AnalyticsEvent, trackEvent } from '../../utils/analytics'
import { findErrorMethodName } from '../../utils/helpers'
import { useNotification } from '../../utils/hooks'
import { Locale } from '../../hooks'
import {
  Loader,
  NotificationType,
  TextField,
  Button,
  CheckboxItem,
  CheckboxType,
} from '../../components'
import { Avatar } from '../../elements'
import {
  GET_TEAM_MEMBER_INVITATION_BY_TOKEN,
  SIGNUP_TEAM_MEMBER,
} from '../../gql'
import { AUTH_SESSION } from '../../utils/constants'

interface GetRegisterData {
  signupTeamMember: {
    user: User
    session: Session
  }
}

const extractData = (
  data?: GetRegisterData,
): {
  user: User
  session: Session
} => {
  return {
    user: data?.signupTeamMember?.user,
    session: data?.signupTeamMember?.session,
  }
}

export type RegisterMemberFormData = {
  token: string
  firstName: string
  lastName: string
  email: string
  password: string
  countryCode: CountryCode
  currency: Currency
  timezone: string
  utcOffset: number
  marketingEmailsOptin: boolean
  fbp: string
  fbc: string
  phoneNumber: string
}

interface IDefaultProps {}

interface IProps extends Partial<IDefaultProps> {
  user?: User
}

const defaultProps: IDefaultProps = {}

const RegisterTeamMember: FunctionComponent<IProps> = ({ user }) => {
  const locale = useReactiveVar(Locale)
  const { addNotification } = useNotification()
  const location = useLocation()

  const [invitation, setInvitation] = useState<TeamInvitation>()
  const { token } = qs.parse(location.search, { ignoreQueryPrefix: true })

  const {
    data,
    loading: getTeamMemberInvitationLoading,
    error: getTeamMemberInvitationError,
  } = useQuery(GET_TEAM_MEMBER_INVITATION_BY_TOKEN, {
    variables: {
      token,
    },
  })

  useEffect(() => {
    // prefill form with data from invitation
    if (data?.getTeamInvitationByToken) {
      setValue('token', data?.getTeamInvitationByToken?.token)
      setValue('firstName', data?.getTeamInvitationByToken?.firstName)
      setValue('lastName', data?.getTeamInvitationByToken?.lastName)
      setValue('email', data?.getTeamInvitationByToken?.email)
    }
    setInvitation(data?.getTeamInvitationByToken)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.getTeamInvitationByToken])

  const [signupMember, { loading: signupMemberLoading }] =
    useMutation<GetRegisterData>(SIGNUP_TEAM_MEMBER)

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    formState: { errors },
  } = useForm<RegisterMemberFormData>({
    defaultValues: {
      token: invitation?.token ?? '',
      firstName: invitation?.firstName ?? '',
      lastName: invitation?.lastName ?? '',
      email: invitation?.email ?? '',
      password: '',
      countryCode: locale.countryCode,
      currency: locale.currency,
      timezone: locale.timezone,
      utcOffset: locale.utcOffset,
      marketingEmailsOptin: true,
      fbp: Cookies.get('_fbp'),
      fbc: Cookies.get('_fbc'),
      phoneNumber: '',
    },
  })
  const isInvitationExpired = useMemo(() => {
    if (
      invitation &&
      invitation?.expirationDate &&
      invitation?.expirationDate < moment().unix()
    ) {
      return true
    } else {
      return false
    }
  }, [invitation])

  const isInvitationAccepted = useMemo(() => {
    if (invitation && invitation?.status === TeamInvitationStatus.accepted) {
      return true
    } else {
      return false
    }
  }, [invitation])

  const isInvitationCancelled = useMemo(() => {
    if (invitation && invitation?.status === TeamInvitationStatus.canceled) {
      return true
    } else {
      return false
    }
  }, [invitation])

  if (
    user &&
    user.type === UserType.creator &&
    invitation &&
    invitation?.status === TeamInvitationStatus.invited
  ) {
    return <AcceptInvitationCreator invitation={invitation} user={user} />
  }

  if (
    user &&
    (user.type === UserType.brand || user.type === UserType.agency) &&
    invitation &&
    invitation?.status === TeamInvitationStatus.invited
  ) {
    return <AcceptInvitationBrand invitation={invitation} user={user} />
  }

  if (
    getTeamMemberInvitationError ||
    isInvitationExpired ||
    isInvitationCancelled
  ) {
    const messages =
      getTeamMemberInvitationError?.graphQLErrors?.map(
        (message) => message.message,
      ) ?? []

    if (isInvitationExpired) {
      messages.push('Invitation expired!')
    }

    if (isInvitationCancelled) {
      messages.push('Invitation cancelled!')
    }

    return <Error messages={messages} />
  }

  if (isInvitationAccepted) {
    return <InvitationAccepted />
  }

  if (getTeamMemberInvitationLoading) {
    return <Loader />
  }

  const onSubmit = async (form: RegisterMemberFormData) => {
    try {
      const { data } = await signupMember({
        variables: {
          input: {
            token: form.token,
            firstName: form.firstName,
            lastName: form.lastName,
            email: form.email.trim(),
            password: form.password,
            phoneNumberCountry: form.countryCode,
            phoneNumber: form.phoneNumber,
            isMarketingEmailsSubscribed:
              form.marketingEmailsOptin ||
              (form.marketingEmailsOptin as unknown) === 'true'
                ? true
                : false,
            fbp: Cookies.get('_fbp'),
            fbc: Cookies.get('_fbc'),
          },
        },
      })
      trackEvent(AnalyticsEvent.teamMemberSignedUp)

      const { session } = extractData(data)
      localStorage.setItem(AUTH_SESSION, JSON.stringify(session))

      window.location.reload()
    } catch (error) {
      if (findErrorMethodName(error, ErrorCode.validateNameIsNotLink)) {
        setError('firstName', {
          type: 'custom',
          message: 'Your first name should not be url.',
        })
        setError('lastName', {
          type: 'custom',
          message: 'Your last name should not be url.',
        })
      }
      if (findErrorMethodName(error, ErrorCode.emailAlreadyTaken)) {
        setError('email', {
          type: 'custom',
          message: 'User with this email address already registered.',
        })
      }
      addNotification(error.message, NotificationType.error)
    }
  }

  return (
    <div className='flex flex-col max-w-lg m-auto p-4'>
      <header className='text-center mx-auto pb-4'>
        <img className='h-12 w-auto mx-auto mb-2' src={LOGO} alt='Logo' />
        <small>Sign up</small>

        <div className='flex py-4 items-center justify-center'>
          <Avatar
            src={invitation?.brand?.logo?.url}
            alt={invitation?.brand?.name}
            size='xs'
            type='brand'
          />
          <h2 className='text-gray-900 ml-2'>
            Team invitation for {invitation?.brand?.name}
          </h2>
        </div>

        <h3>Register as a team member</h3>
      </header>

      <form onSubmit={handleSubmit(onSubmit)} className='py-8 space-y-4'>
        <TextField
          placeholder={`Example: Jane`}
          label='First name'
          name='firstName'
          error={errors.firstName?.message}
          {...register('firstName', {
            required: `Please set an first name.`,
            maxLength: {
              value: 100,
              message: `Your first name cannot have more than 100 letters.`,
            },
          })}
        />

        <TextField
          placeholder={`Example: Cat`}
          label='Last name'
          name='lastName'
          error={errors.lastName?.message}
          {...register('lastName', {
            required: `Please set a last name.`,
            maxLength: {
              value: 100,
              message: `Your last name cannot have more than 100 letters.`,
            },
          })}
        />

        <TextField
          placeholder={`Example: joe@thesocialcat.com`}
          label='Email'
          name='Email'
          error={errors.email?.message}
          {...register('email', {
            required: `Please set an email.`,
            maxLength: {
              value: 100,
              message: `Please keep your email under 100 letters.`,
            },
            validate: {
              value: (email) => {
                if (validator.isEmail(email.trim())) {
                  return true
                }

                return 'Invalid email.'
              },
            },
          })}
        />

        <TextField
          error={errors.phoneNumber?.message}
          label='Phone Number (optional)'
          placeholder='07450 797 128'
          {...register('phoneNumber', {
            // required: `Please set a phone number.`,
            minLength: {
              value: 6,
              message: `Please set a real phone number.`,
            },
            maxLength: {
              value: 20,
              message: `Please set a real phone number.`,
            },
          })}
        />

        <TextField
          type='password'
          placeholder={`******`}
          label='Password'
          name='password'
          error={errors.password?.message}
          {...register('password', {
            required: `Please set a password.`,
            minLength: {
              value: 6,
              message: `Please set a password with minimum 6 letters.`,
            },
            maxLength: {
              value: 100,
              message: `Please keep your password under 100 letters.`,
            },
          })}
        />

        <CheckboxItem
          type={CheckboxType.checkbox}
          name='marketing-opt-in'
          title={`Can we reach out with offers or promotions?`}
          selected={watch('marketingEmailsOptin')}
          value={!watch('marketingEmailsOptin')}
          {...register('marketingEmailsOptin')}
          className='!px-0'
        />

        <Button
          title='Register as a Team Member'
          loading={signupMemberLoading}
          className='w-full'
        />
      </form>
    </div>
  )
}

RegisterTeamMember.defaultProps = defaultProps

export { RegisterTeamMember }
