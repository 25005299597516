import { useMutation } from '@apollo/client'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Button,
  ButtonColor,
  Icon,
  IconType,
  NotificationType,
  TextArea,
} from '../../../components'
import { AvatarUpdate } from '../../../components/AvatarUpdate'
import {
  GetUserAvatarUploadUrlResponse,
  GET_USER_AVATAR_UPLOAD_URL,
  UPDATE_CREATOR,
  UPDATE_USER,
  GET_SELF,
} from '../../../gql'
import {
  Creator,
  CreatorOnboardingStep,
  MutationGetUserAvatarUploadUrlArgs,
  MutationUpdateUserArgs,
  UpdateUserInput,
  User,
} from '../../../gql/types'
import { AnalyticsEvent, trackEvent } from '../../../utils/analytics'
import { useNotification } from '../../../utils/hooks'
import { Bullets } from '../components/Bullets'
import { Steps } from '../components/Steps'

interface Props {
  creator: Creator
  user: User
}

const SetAbout = ({ creator, user }: Props) => {
  const [isAvatarUploadLoading, setIsAvatarUploadLoading] = useState(false)

  const { addNotification } = useNotification()

  const [updateCreator, { loading: updateCreatorLoading }] = useMutation(
    UPDATE_CREATOR,
    {
      refetchQueries: ['GetSelf'],
    },
  )

  const [updateUser, { loading }] = useMutation<User, MutationUpdateUserArgs>(
    UPDATE_USER,
    {
      refetchQueries: [
        'GetSelf',
        {
          query: GET_SELF,
        },
      ],
    },
  )

  const [getAvatarUploadUrl] = useMutation<
    GetUserAvatarUploadUrlResponse,
    MutationGetUserAvatarUploadUrlArgs
  >(GET_USER_AVATAR_UPLOAD_URL)

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    watch,
    clearErrors,
  } = useForm<UpdateUserInput>({
    defaultValues: {
      avatarFileName: user.avatar?.name,
      bio: user.bio,
    },
  })

  useEffect(() => {
    trackEvent(AnalyticsEvent.viewCreatorsOnboardingSetAbout)
  }, [])

  const goBack = async () => {
    try {
      await updateCreator({
        variables: {
          id: creator.id,
          input: {
            onboarding: CreatorOnboardingStep.setProfile,
          },
        },
      })
    } catch (error) {
      addNotification(error.message, NotificationType.error)
    }
  }

  const onSubmit = async (data: UpdateUserInput) => {
    if (!data.avatarFileName) {
      setError('avatarFileName', {
        type: 'manual',
        message: 'Please set your avatar.',
      })

      return
    }

    try {
      await Promise.all([
        await updateUser({
          variables: {
            id: user.id,
            input: {
              bio: data.bio,
              avatarFileName: data.avatarFileName,
            },
          },
        }),
        await updateCreator({
          variables: {
            id: creator.id,
            input: {
              onboarding: CreatorOnboardingStep.setInterests,
            },
          },
        }),
      ])
    } catch (error) {
      console.log('error SetAbout', error.message)
      addNotification(error.message, NotificationType.error)
    }
  }

  const onAvatarUpdated = async (file: File) => {
    if (!file) return

    try {
      setIsAvatarUploadLoading(true)
      const { data } = await getAvatarUploadUrl({
        variables: {
          userId: user.id,
          mime: file.type,
        },
      })

      await axios.put(data.getUserAvatarUploadUrl.uploadUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
      })

      if (data.getUserAvatarUploadUrl.fileName) {
        setValue('avatarFileName', data.getUserAvatarUploadUrl.fileName)
        clearErrors('avatarFileName')
      }
    } catch (error) {
      console.log('error onAvatarUpdated', error.message)
    } finally {
      setIsAvatarUploadLoading(false)
    }
  }

  const AboutField = () => {
    return (
      <div>
        <TextArea
          label='Tell us about yourself'
          name='about'
          placeholder={user.bio}
          error={errors.bio?.message}
          {...register('bio', {
            required: 'Please add a description',
            maxLength: {
              value: 1000,
              message: `Please use a shorter bio.`,
            },
          })}
        />
        <p className='mt-1 block text-sm font-medium text-gray-500'>
          The more interesting and relevant information you provide, the higher
          the chances of being approved by the brands.
        </p>
      </div>
    )
  }

  return (
    <div className='min-h-screen flex flex-col'>
      <div className='flex-grow'>
        <Steps creator={creator} stepCurrent={CreatorOnboardingStep.setAbout} />

        <div className='min-h-full flex flex-col justify-center pt-4 px-4 md:mt-8 lg:px-8'>
          <div className='sm:mx-auto sm:w-full sm:max-w-2xl'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='md:shadow rounded-t-md sm:overflow-hidden mb-8'>
                <div className='py-5 md:bg-white space-y-6 sm:p-6'>
                  <div className='sm:mx-auto'>
                    <h2 className='mt-0 md:text-center text-3xl font-extrabold text-gray-900'>
                      About you
                    </h2>
                    <p className='mt-2 md:text-center text-sm text-gray-600'>
                      We will share this information with brands to facilitate
                      the campaign process.
                    </p>
                  </div>

                  <div className='grid grid-cols-6 gap-6'>
                    <div className='col-span-6 sm:col-span-3'>
                      <AvatarUpdate
                        title='Avatar'
                        avatarUrl={user.avatar?.url}
                        onChange={onAvatarUpdated}
                      />
                      <div
                        className='relative mt-2 text-sm text-red-600'
                        id='errorAvatarUrl'
                      >
                        {errors.avatarFileName?.message && (
                          <p
                            className='mt-2 text-sm text-red-600'
                            id='errorAvatarUrl'
                          >
                            {errors?.avatarFileName?.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className='col-span-6 sm:col-span-6'>
                      <AboutField />
                    </div>
                  </div>
                </div>

                <div className='flex justify-between md:shadow rounded-b-md py-3 md:bg-gray-50 text-right sm:px-6'>
                  <Button
                    title='Go back'
                    type='button'
                    colour={ButtonColor.white}
                    loading={
                      loading || updateCreatorLoading || isAvatarUploadLoading
                    }
                    disabled={
                      loading || updateCreatorLoading || isAvatarUploadLoading
                    }
                    onClick={goBack}
                  />
                  <Button
                    title='Continue'
                    type='submit'
                    loading={
                      loading || updateCreatorLoading || isAvatarUploadLoading
                    }
                    disabled={
                      loading || updateCreatorLoading || isAvatarUploadLoading
                    }
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Bullets creator={creator} stepCurrent={CreatorOnboardingStep.setAbout} />
    </div>
  )
}

export { SetAbout }
