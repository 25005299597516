import { useMutation } from '@apollo/client'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import {
  Button,
  ButtonColor,
  NotificationType,
  TextArea,
} from '../../../components'
import { Collaboration } from '../../../gql/types'
import { useNotification } from '../../../utils/hooks'
import { BrandRoutes } from '../../BrandRoutes'
import { useForm } from 'react-hook-form'
import { CREATE_EDITS_REQUEST_MESSAGES } from '../../../gql'

interface IProps {
  collaboration?: Collaboration
  isOpen: boolean
  setIsOpen: (event: any) => void
}

export const RequestEditsModal = ({
  collaboration,
  isOpen,
  setIsOpen,
}: IProps) => {
  const { addNotification } = useNotification()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      message: '',
    },
  })

  const [createEditsRequestMessage, { loading, error }] = useMutation(
    CREATE_EDITS_REQUEST_MESSAGES,
  )

  const onSubmit = async ({ message }) => {
    try {
      await createEditsRequestMessage({
        variables: {
          collaborationId: collaboration.id,
          input: {
            editsRequestMessage: message,
          },
        },
      })

      reset()
      closeModal()
      addNotification(`Edit request sent.`, NotificationType.success)
    } catch (error) {
      addNotification(
        `Something went wrong. Please try again.`,
        NotificationType.error,
      )
    }
  }

  const messageLink =
    BrandRoutes.inboxChat
      .replace(':id', `${collaboration.campaign.brand.id}`)
      .replace(
        ':uuid',
        `${collaboration.campaign.brand.id}-${collaboration.creator.id}`,
      ) +
    '?collaborationId=' +
    collaboration.id

  const closeModal = () => {
    setIsOpen(false)
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-25' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                <Dialog.Title
                  as='h3'
                  className='text-lg font-medium leading-6 text-gray-900'
                >
                  Request edits
                </Dialog.Title>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='text-gray-700 my-5'>
                    Add your required changes for{' '}
                    {collaboration.creator.firstName}'s video. Please keep in
                    mind that you only have one round of edits. Make sure that
                    you add all the adjustments necessary.
                  </div>

                  <div className='min-w-0 flex-1'>
                    <div>
                      <label htmlFor='comment' className='sr-only'>
                        Send a private message
                      </label>
                      <TextArea
                        placeholder='Type to add your message'
                        name='email'
                        error={errors.message?.message}
                        {...register('message', {
                          required: `Please add a message.`,
                          maxLength: {
                            value: 10000,
                            message: `Message too long.`,
                          },
                        })}
                      />
                    </div>
                  </div>

                  <div className='text-sm text-gray-600 mt-2'>
                    If you're dissapointed with the quality please email us at{' '}
                    <a
                      href={`mailto:hello@useclip.com`}
                      className='text-blue-700'
                    >
                      hello@useclip.com
                    </a>
                    .
                  </div>

                  <div className='mt-4 flex justify-between'>
                    <div className='py-2'>
                      <Button
                        type='button'
                        title='Cancel'
                        colour={ButtonColor.white}
                        onClick={closeModal}
                      />
                    </div>

                    <div className='py-2'>
                      <Button
                        type='submit'
                        colour={ButtonColor.black}
                        title='Send requested edits'
                        loading={loading}
                      />
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
