import { FunctionComponent } from 'react'
import { Campaign } from '../../gql/types'
import { Divider } from '../Divider'
import { CreatorRequirementsBrief } from './CreatorRequirementsBrief'
import { InspirationBrief } from './InspirationBrief'
import { ProductBrief } from './ProductBrief'
import { VideoBrief } from './VideoBrief'

interface IDefaultProps {
  // if this viewed by the creator or the brand
  viewMode?: 'brand' | 'creator'
  className?: string
}

interface IProps extends Partial<IDefaultProps> {
  campaign: Campaign
  hooks?: string[]
}
const defaultProps: IDefaultProps = {
  viewMode: 'brand',
}

const CampaignDetailsBrief: FunctionComponent<IProps> = ({
  campaign,
  viewMode,
  hooks,
  className,
}) => {
  return (
    <div className='w-full'>
      <VideoBrief campaign={campaign} hooks={hooks} viewMode={viewMode} />
      <Divider className='py-8' />
      <ProductBrief product={campaign.product} />
      <Divider className='py-8' />
      <CreatorRequirementsBrief campaign={campaign} />
      <Divider className='py-8' />
      <InspirationBrief campaign={campaign} viewMode={viewMode} />
    </div>
  )
}

CampaignDetailsBrief.defaultProps = defaultProps

export { CampaignDetailsBrief }
