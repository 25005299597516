import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from 'react-router-dom'
import './App.css'
import { BrandRouter } from './brands/BrandRouter'
import { BrandRoutes } from './brands/BrandRoutes'
import { CreatorsLayout } from './components/CreatorsLayout'
import { Loader } from './components/Loader'
import { ActiveCampaigns } from './creators/ActiveCampaigns'
import { Applications } from './creators/ActiveCampaigns/Applications'
import { BrandDetails as CreatorCollaborationViewBrandDetails } from './creators/CollaborationView/BrandDetails'
import { BriefDetails as CreatorCollaborationViewBriefDetails } from './creators/CollaborationView/BriefDetails'
import { CollaborationClips as CreatorCollaborationClips } from './creators/CollaborationView/CollaborationClips'
import { CreatorRoutes } from './creators/CreatorRoutes'
import { Inbox as CreatorsInbox } from './creators/Inbox'
import { Inspiration as InspirationCreator } from './creators/Inspiration'
import { Onboarding as CreatorOnboarding } from './creators/Onboarding'
import { Opportunities } from './creators/Opportunities'
import { Invitations } from './creators/Opportunities/Invitations'
import { Profile as CreatorProfile } from './creators/Profile'
import { CreatorEarnings } from './creators/Profile/Earnings'
import { CreatorPortfolio } from './creators/Profile/EditPortfolio'
import { Reviews as CreatorReviewsView } from './creators/Profile/ReviewsView'
import {
  CreatorOnboardingStep,
  CreatorStatus,
  User,
  UserType,
} from './gql/types'
import { useInit } from './hooks'
import { ForgotPassword } from './public/ForgotPassword'
import { Login } from './public/Login'
import { NotFound } from './public/NotFound'
import { RegisterBrand } from './public/RegisterBrand'
import { RegisterCreator } from './public/RegisterCreator'
import { RegisterTeamMember } from './public/RegisterTeamMember'
import { ResetPassword } from './public/ResetPassword'
import { VerifyEmail } from './public/VerifyEmail'
import { PublicRoutes } from './utils/PublicRoutes'
import { UiContextProvider } from './utils/UiContext'
import { DEAL_CODE } from './utils/constants'
import { useEffect } from 'react'
import { identity } from './utils/analytics'

interface IProps {
  user: User
}

function PrivateCreatorOutlet({ user }: IProps) {
  const { pathname } = useLocation()

  if (!user || user.type !== UserType.creator) {
    return <Navigate to={PublicRoutes.login} />
  }

  if (
    pathname !== CreatorRoutes.onboarding &&
    (user.creator.status === CreatorStatus.draft ||
      user.creator.status === CreatorStatus.pending ||
      user.creator.status === CreatorStatus.rejected)
  ) {
    return <Navigate to={CreatorRoutes.onboarding} />
  }

  if (
    pathname === CreatorRoutes.onboarding &&
    (user.creator.status === CreatorStatus.pending ||
      user.creator.status === CreatorStatus.rejected)
  ) {
    return <Outlet />
  }

  if (
    pathname === CreatorRoutes.onboarding &&
    user.creator.onboarding !== CreatorOnboardingStep.completed
  ) {
    return <Outlet />
  }

  if (
    pathname === CreatorRoutes.onboarding &&
    user.creator.status === CreatorStatus.active
  ) {
    return (
      <Navigate
        to={CreatorRoutes.opportunities.replace(':id', `${user.creator.id}`)}
      />
    )
  }

  return <CreatorsLayout user={user} />
}

interface IRedirectIfLoggedIn {
  user: User
}

const RedirectIfLoggedIn = ({ user }: IRedirectIfLoggedIn) => {
  const [searchParams] = useSearchParams()

  const dealCode = searchParams.get('deal')
  if (dealCode) {
    localStorage.setItem(DEAL_CODE, dealCode)
  }

  if (user && (user.type === UserType.brand || user.type === UserType.agency)) {
    return <Navigate to={BrandRoutes.onboarding} />
  }

  if (user && user.type === UserType.creator) {
    return <Navigate to={CreatorRoutes.onboarding} />
  }

  return <Outlet />
}

function App() {
  const { user, pricing, loading } = useInit()

  useEffect(() => {
    if (user) {
      identity(user)
    }
  }, [user])

  if (loading) {
    return <Loader />
  }

  /**
   * Routing, handling private routes and redirects
   * https://dev.to/iamandrewluca/private-route-in-react-router-v6-lg5
   */
  return (
    <UiContextProvider>
      <BrowserRouter>
        <div>
          <Routes>
            <Route path={PublicRoutes.verifyEmail} element={<VerifyEmail />} />

            {/* Login & Register */}
            <Route element={<RedirectIfLoggedIn user={user} />}>
              <Route path={PublicRoutes.login} element={<Login />} />
              <Route
                path={PublicRoutes.registerBrand}
                element={<RegisterBrand />}
              />
              <Route
                path={PublicRoutes.registerCreator}
                element={<RegisterCreator />}
              />

              {/* password reset and update notifications */}
              <Route
                path={PublicRoutes.forgotPassword}
                element={<ForgotPassword />}
              />
              <Route
                path={PublicRoutes.resetPassword}
                element={<ResetPassword />}
              />
            </Route>

            {/* Team invitation */}
            <Route
              path={PublicRoutes.registerTeamMember}
              element={<RegisterTeamMember user={user} />}
            />

            {/* Brand Logged In */}
            <Route
              path='/b/*'
              element={<BrandRouter user={user} pricing={pricing} />}
            />

            {/* Creator Logged In */}
            <Route
              path={CreatorRoutes.prefix}
              element={<PrivateCreatorOutlet user={user} />}
            >
              <Route
                path={CreatorRoutes.onboarding}
                element={<CreatorOnboarding user={user} />}
              />
              <Route
                path={CreatorRoutes.opportunities}
                element={<Opportunities user={user} pricing={pricing} />}
              />
              <Route
                path={CreatorRoutes.invitations}
                element={<Invitations user={user} pricing={pricing} />}
              />
              <Route
                path={CreatorRoutes.collaborations}
                element={<ActiveCampaigns user={user} pricing={pricing} />}
              />
              <Route
                path={CreatorRoutes.applications}
                element={<Applications user={user} pricing={pricing} />}
              />
              <Route
                path={CreatorRoutes.collaborationViewBriefDetails}
                element={<CreatorCollaborationViewBriefDetails />}
              />
              <Route
                path={CreatorRoutes.collaborationViewBrandDetails}
                element={<CreatorCollaborationViewBrandDetails />}
              />
              <Route
                path={CreatorRoutes.collaborationViewClips}
                element={<CreatorCollaborationClips />}
              />
              <Route
                path={CreatorRoutes.inspiration}
                element={<InspirationCreator user={user} />}
              />
              <Route
                path={CreatorRoutes.accountProfile}
                element={<CreatorProfile user={user} />}
              />
              <Route
                path={CreatorRoutes.accountReviews}
                element={<CreatorReviewsView user={user} />}
              />
              <Route
                path={CreatorRoutes.accountEarnings}
                element={<CreatorEarnings user={user} />}
              />
              <Route
                path={CreatorRoutes.accountPortfolio}
                element={<CreatorPortfolio user={user} />}
              />
              <Route
                path={CreatorRoutes.inbox}
                element={<CreatorsInbox pricing={pricing} />}
              />
              <Route
                path={CreatorRoutes.inboxChat}
                element={<CreatorsInbox pricing={pricing} />}
              />
            </Route>

            {/* Not Found */}
            <Route path='*' element={<NotFound statusCode={404} />} />
          </Routes>
        </div>
      </BrowserRouter>
    </UiContextProvider>
  )
}

export default App
