import classNames from 'classnames'
import { FunctionComponent, useEffect, useState } from 'react'
import { Brand, Creator } from '../../../../gql/types'
import { Avatar } from '../../../../elements'
import {
  Button,
  ButtonColor,
  ButtonSize,
  ButtonStyle,
  Icon,
  IconType,
  NotificationType,
} from '../../../../components'
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { ViewCollaborationsSlider } from '../../../../components/ViewCollaborationsSlider'
import { ViewAllClipsSlider } from '../../../../components/ViewAllClipsSlider'
import { ViewProfileSlider } from '../ViewProfileSlider'
import { useNotification } from '../../../../utils/hooks'
import { useMutation } from '@apollo/client'
import { MARK_THREAD_MESSAGES_AS_UNREAD_BY_CREATOR } from '../../../../gql'
import { CreatorRoutes } from '../../../CreatorRoutes'
import { PricingData } from '../../../../utils/PricingUtil'

interface IProps {
  pricing: PricingData
  className?: string
  creator?: Creator
  brand?: Brand
}

const Header: FunctionComponent<IProps> = ({
  pricing,
  className,
  creator,
  brand,
}) => {
  const { addNotification } = useNotification()
  const navigate = useNavigate()
  const { id, uuid } = useParams()

  // in order to get campaign
  const [searchParams] = useSearchParams()
  const [activeCollaborationId, setActiveCollaborationId] = useState(null)

  useEffect(() => {
    if (searchParams.get('collaborationId')) {
      setActiveCollaborationId(parseInt(searchParams.get('collaborationId')))
    }
  }, [searchParams])

  const [isViewCollaborationsSliderOpen, setIsViewCollaborationsSliderOpen] =
    useState(false)

  const [isViewClipsSliderOpen, setIsViewClipsSliderOpen] = useState(false)
  const [isViewProfileSliderOpen, setIsViewProfileSliderOpen] = useState(false)

  const [markThreadMessagesAsUnreadByCreator] = useMutation(
    MARK_THREAD_MESSAGES_AS_UNREAD_BY_CREATOR,
  )
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const mobileMediaQuery = window.matchMedia('(max-width: 767px)') // Adjust the breakpoint as needed

    const handleMobileChange = (event) => {
      setIsMobile(event.matches)
    }

    mobileMediaQuery.addEventListener('change', handleMobileChange)
    setIsMobile(mobileMediaQuery.matches)

    return () => {
      mobileMediaQuery.removeEventListener('change', handleMobileChange)
    }
  }, [])

  const onMarkConversationUnread = async () => {
    try {
      await markThreadMessagesAsUnreadByCreator({
        variables: {
          uuid,
        },
      })

      addNotification(`Conversation marked as unread`, NotificationType.success)
      navigate({
        pathname: CreatorRoutes.inbox.replace(':id', `${id}`),
        search: createSearchParams(searchParams).toString(),
      })
    } catch (error) {
      addNotification(
        `We couldn't mark this conversation as unread.`,
        NotificationType.error,
      )
    }
  }

  if (!creator || !brand) {
    return null
  }

  return (
    <>
      <div
        className={classNames(
          'md:flex md:items-center md:justify-between md:space-x-5',
          className,
        )}
      >
        <div
          className='flex items-center space-x-5 align-middle justify-center cursor-pointer'
          onClick={() => setIsViewProfileSliderOpen(true)}
        >
          <div className='flex-shrink-0'>
            <div className='relative'>
              <Avatar
                size='md'
                src={brand?.logo?.url}
                type='brand'
                alt='Logo'
              />
            </div>
          </div>

          <div className='max-w-sm overflow-hidden'>
            <h1 className='text-xl font-bold text-gray-900'>{brand.name}</h1>
          </div>
        </div>

        <div className='mt-6 flex flex-row space-x-1 justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-1 md:mt-0 md:flex-row md:space-x-1'>
          <Button
            // title='Mark unread'
            icon={
              <Icon
                type={IconType.removeDoneAll}
                className='h-4 w-4'
                aria-hidden='true'
              />
            }
            iconPosition='left'
            colour={ButtonColor.white}
            onClick={onMarkConversationUnread}
            className='w-full h-full'
            tooltip='Mark message as unread'
          />
          <Button
            // title={isMobile ? 'Clips' : null}
            type='button'
            colour={ButtonColor.secondary}
            onClick={() => setIsViewClipsSliderOpen(true)}
            icon={
              <Icon
                type={IconType.filmThree}
                className='h-4 w-4 text-white'
                aria-hidden='true'
              />
            }
            iconPosition='left'
            className='w-full h-full'
            tooltip='View clips'
          />
          <Button
            // title={isMobile ? 'Campaigns' : null}
            type='button'
            colour={ButtonColor.primary}
            onClick={() => setIsViewCollaborationsSliderOpen(true)}
            // applyStyleToLink={true}
            icon={
              <Icon
                type={IconType.layers}
                className='h-4 w-4 text-white'
                aria-hidden='true'
              />
            }
            iconPosition='left'
            className='w-full h-full'
            tooltip='View campaigns'
          />
          {/* <Button
            // title='Profile'
            type='button'
            colour={ButtonColor.lightGray}
            onClick={() => setIsViewProfileSliderOpen(true)}
            icon={
                <Icon
                  type={IconType.store}
                  className='h-4 w-4 text-white'
                  aria-hidden='true'
                />
            }
            iconPosition='left'
          /> */}
        </div>
      </div>
      {creator && brand && (
        <>
          <ViewCollaborationsSlider
            open={isViewCollaborationsSliderOpen}
            setOpen={setIsViewCollaborationsSliderOpen}
            creator={creator}
            brand={brand}
            pricing={pricing}
            type='creator'
          />
          <ViewAllClipsSlider
            open={isViewClipsSliderOpen}
            setOpen={setIsViewClipsSliderOpen}
            creator={creator}
            brand={brand}
            type='creator'
          />
          <ViewProfileSlider
            open={isViewProfileSliderOpen}
            setOpen={setIsViewProfileSliderOpen}
            brand={brand}
          />
        </>
      )}
    </>
  )
}

export { Header }
