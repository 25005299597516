import classnames from 'classnames'
import { useState } from 'react'
import { useMutation } from '@apollo/client'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import {
  Button,
  ButtonColor,
  Loader,
  NotificationType,
  Pagination,
} from '../../../../../components'
import { useNotification } from '../../../../../utils/hooks'
import { useUserBrand } from '../../../../../hooks'
import { UpdateRolesModal } from '../UpdateRolesModal'
import { REMOVE_TEAM_MEMBER, GET_TEAM_MEMBERS } from '../../../../../gql'
import { SKIP_DEFAULT, LIMIT_DEFAULT } from '../../../../../utils/constants'
import { mapRoleToText } from '../../../BrandInvitations/components/InviteMemberModal'
import {
  UserBrand,
  GetUserBrandsSort,
  UserRole,
  Pagination as IPagination,
} from '../../../../../gql/types'

const TableHead = () => {
  return (
    <thead className='bg-gray-50'>
      <tr>
        <th
          scope='col'
          className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-600 sm:pl-6'
        >
          Name
        </th>

        <th
          scope='col'
          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-600'
        >
          Email
        </th>

        <th
          scope='col'
          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-600'
        >
          Roles
        </th>

        <th
          scope='col'
          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-600'
        >
          Primary Contact
        </th>

        <th
          scope='col'
          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-600'
        >
          Status
        </th>

        <th
          scope='col'
          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-600'
        >
          Joined
        </th>
      </tr>
    </thead>
  )
}

interface IProps {
  userBrands: UserBrand[]
  pagination: IPagination
  setPage(page: number): void
  loading: boolean
}

const List = ({ userBrands, pagination, setPage, loading }: IProps) => {
  const { addNotification } = useNotification()
  const [isUpdateRolesOpen, setIsUpdateRolesOpen] = useState(false)
  const [roleToUpdate, setRoleToUpdate] = useState<UserBrand | null>(null)
  const { id: brandId } = useParams()
  const userBrand = useUserBrand(parseInt(brandId))

  const [removeTeamMember, { loading: removeTeamMemberLoading }] = useMutation(
    REMOVE_TEAM_MEMBER,
    {
      refetchQueries: [
        {
          query: GET_TEAM_MEMBERS,
          variables: {
            options: {
              query: null,
              filters: {
                brandId: parseInt(brandId),
              },
              sort: GetUserBrandsSort.newest,
              skip: SKIP_DEFAULT,
              limit: LIMIT_DEFAULT,
            },
          },
        },
      ],
    },
  )

  const removeMember = (userBrand: UserBrand) => async () => {
    try {
      await removeTeamMember({
        variables: {
          userId: userBrand.user.id,
          brandId: userBrand.brand.id,
        },
      })

      addNotification('Member has been removed.', NotificationType.success)
    } catch (error) {
      addNotification(
        `Something went wrong. Please try again.`,
        NotificationType.error,
      )
    }
  }

  if (loading) {
    return <Loader />
  }

  return (
    <div className='flex flex-col'>
      <table className='min-w-full divide-y divide-gray-200'>
        <TableHead />
        <tbody className='divide-y divide-gray-200 bg-white'>
          {pagination?.numberOfHits === 0 && (
            <tr>
              <td colSpan={6}>
                <p className='py-4 pl-4 pr-3 text-sm sm:pl-6 w-full'>
                  No results for this search.
                </p>
              </td>
            </tr>
          )}

          {userBrands?.map((userBrandItem, index) => (
            <tr
              key={userBrandItem.user.id}
              className={classnames(
                index % 2 === 0 ? 'bg-gray-50' : 'bg-white',
              )}
            >
              <td className='whitespace-nowrap py-4 pl-4 text-sm sm:pl-6'>
                {userBrandItem.user.firstName} {userBrandItem.user.lastName}{' '}
                {brandId && userBrandItem.id === userBrand?.id && (
                  <span className='text-gray-500'>(You)</span>
                )}
              </td>

              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                {userBrandItem.user.email}
              </td>

              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                {userBrandItem.roles
                  .map((role) => mapRoleToText(role))
                  .join(', ')}
              </td>

              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                {userBrandItem.isDefaultContactUser ? 'Yes' : 'No'}
              </td>

              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                {userBrandItem.isActive ? 'Active' : 'Inactive'}
              </td>

              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                {moment(userBrandItem.createdAt).fromNow()}
              </td>

              <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 float-right space-x-2'>
                {userBrand?.roles?.includes(UserRole.owner) && (
                  <>
                    <Button
                      colour={ButtonColor.secondary}
                      title='Update roles'
                      onClick={() => {
                        setIsUpdateRolesOpen(!isUpdateRolesOpen)
                        setRoleToUpdate(userBrandItem)
                      }}
                    />
                    {brandId && userBrandItem.id !== userBrand?.id && (
                      <Button
                        colour={ButtonColor.error}
                        title='Remove'
                        loading={removeTeamMemberLoading}
                        onClick={removeMember(userBrandItem)}
                        disabled={false}
                      />
                    )}
                    {brandId && userBrandItem.id === userBrand?.id && (
                      <Button
                        colour={ButtonColor.error}
                        title='Remove'
                        loading={removeTeamMemberLoading}
                        onClick={removeMember(userBrandItem)}
                        disabled={true}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {pagination && <Pagination pagination={pagination} setPage={setPage} />}

      {roleToUpdate && (
        <UpdateRolesModal
          userBrand={roleToUpdate}
          isOpen={isUpdateRolesOpen}
          setIsOpen={setIsUpdateRolesOpen}
        />
      )}
    </div>
  )
}

export { List }
