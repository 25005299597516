import { useMutation, useQuery } from '@apollo/client'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  Button,
  ButtonColor,
  Loader,
  NotificationType,
} from '../../../components'
import Multiselect from '../../../elements/Multiselect'
import {
  GET_CREATORS_BY_BRAND,
  INVITE_CREATORS_TO_CAMPAIGN,
  MutationInviteCreatorsToCampaignResponse,
  QueryGetCreatorsByBrandResponse,
} from '../../../gql'
import {
  Campaign,
  Creator,
  MutationInviteCreatorsToCampaignArgs,
  QueryGetCreatorsByBrandArgs,
} from '../../../gql/types'
import { useNotification } from '../../../utils/hooks'
import { BrandRoutes } from '../../BrandRoutes'
import { Avatar } from '../../../elements'

interface IProps {
  campaign: Campaign
  isOpen: boolean
  setIsOpen: (event: any) => void
}

const InviteCreatorModal = ({ campaign, isOpen, setIsOpen }: IProps) => {
  const { addNotification } = useNotification()
  const navigate = useNavigate()
  const location = useLocation()
  const { id: brandId } = useParams()
  const brand = campaign?.brand

  const [selectedCreators, setSelectedCreators] = useState([])

  const { loading, error, data, refetch } = useQuery<
    QueryGetCreatorsByBrandResponse,
    QueryGetCreatorsByBrandArgs
  >(GET_CREATORS_BY_BRAND, {
    variables: {
      brandId: parseInt(brandId),
    },
  })

  const [inviteCampaign, { loading: inviteCampaignLoading }] = useMutation<
    MutationInviteCreatorsToCampaignResponse,
    MutationInviteCreatorsToCampaignArgs
  >(INVITE_CREATORS_TO_CAMPAIGN)

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      selectedCreators: [],
    },
  })

  const closeModal = () => {
    setIsOpen(false)
  }

  const onSubmit = async (data) => {
    try {
      const invitations = await inviteCampaign({
        variables: {
          campaignId: campaign.id,
          input: {
            creatorIds: selectedCreators.map((creator) => creator.id),
            // creatorIds: selectedCreators[0].id,
          },
        },
      })

      addNotification(`Invite sent.`, NotificationType.success)
      closeModal()
      reset()
      setSelectedCreators([])
      if (location.pathname !== BrandRoutes.campaigns.replace(':id', brandId)) {
        navigate(BrandRoutes.campaigns.replace(':id', brandId))
      }
    } catch (error) {
      addNotification(
        error.message
          ? error.message
          : `Something went wrong. Please try again.`,
        NotificationType.error,
      )
    }
  }

  const optionToMultiselectOption = (creator) => {
    return {
      label: creator.firstName,
      value: creator,
      avatar: (
        <Avatar
          type='user'
          src={creator.avatar?.url}
          size='xxs'
          alt={creator.firstName}
        />
      ),
    }
  }

  if (loading) return <Loader />

  if (error) {
    console.log(error)
    return <p>There has been an error. Try refreshing the page</p>
  }

  const creators = data?.getCreatorsByBrand || []

  const multiselectOptionToOption = (multiselectOption) => {
    return Object.values(creators).find(
      (option) => option === multiselectOption.value,
    )
  }

  const setSelectedCreatorsMultiselect = (multiselectOptions) => {
    if (multiselectOptions.length < 1) {
      addNotification('Please set at least 1 creator.', NotificationType.error)
      return
    }

    const newSelectedCreators = []
    multiselectOptions.forEach((multiselectOption) => {
      newSelectedCreators.push(multiselectOptionToOption(multiselectOption))
    })
    return setSelectedCreators(newSelectedCreators)
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                  <Dialog.Title
                    as='h3'
                    className='text-lg font-medium leading-6 text-gray-900'
                  >
                    Invite creators
                  </Dialog.Title>
                  <p className='mt-2 text-base text-gray-500'>
                    Invite creators you have previously worked with to apply to
                    your campaign.
                  </p>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='mt-4'>
                      <Multiselect
                        options={creators.map((creator) =>
                          optionToMultiselectOption(creator),
                        )}
                        onChange={setSelectedCreatorsMultiselect}
                        selected={selectedCreators.map((creator) =>
                          optionToMultiselectOption(creator),
                        )}
                        isSearchable={true}
                      />
                    </div>

                    {/* <p className='text-sm text-gray-400 mt-4'>
                      The invitation is valid for 2 days, after which it will
                      expire.
                    </p> */}

                    <div className='mt-4 flex items-center justify-between'>
                      <div className='py-2'>
                        <Button
                          type='button'
                          title='Cancel'
                          colour={ButtonColor.white}
                          onClick={closeModal}
                        />
                      </div>

                      <Button title='Send' loading={inviteCampaignLoading} />
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export { InviteCreatorModal }
