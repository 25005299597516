import classNames from 'classnames'
import { Icon, IconType } from '../../../../components'
import { formatPriceInCents } from '../../../../utils/PricingUtil'
import { Brand, CountryCode, Currency } from '../../../../gql/types'
import { mapCurrencyToSign } from '../../../../utils/mappers'

interface Props {
  brand: Brand
  quantity: number
  setQuantity: (quantity: number) => void
  totalPriceCents: number
  pricePerVideoCents: number
  pricePerVariationCents: number
  pricePerAddons: number
  hooksCount: number
  currency: Currency
}

const EditQuantity = ({
  brand,
  quantity,
  setQuantity,
  totalPriceCents,
  pricePerVideoCents,
  pricePerVariationCents,
  pricePerAddons,
  currency,
  hooksCount,
}: Props) => {
  const renderPayWithCreditInput = () => {
    if (brand.creditCents <= 0 || brand.currency !== currency) {
      return null
    }

    const formattedCredit = formatPriceInCents(
      {
        priceInCents: brand.creditCents,
        currency: brand.currency,
      },
      { minimumFractionDigits: 0 },
    )

    return (
      <div className='relative flex items-start mt-3'>
        <div className='flex h-6 items-center'>
          <input
            id='payWithCredit'
            aria-describedby='payWithCredit-description'
            name='payWithCredit'
            type='checkbox'
            // checked={creditPayment}
            checked={true}
            disabled
            className={classNames(
              'h-4 w-4 rounded border-gray-300 text-gray-600 disabled:accent-gray-300 focus:ring-gray-500 cursor-pointer accent-gray-300',
            )}
          />
        </div>
        <div className='ml-3 text-sm leading-6'>
          <label
            htmlFor='payWithCredit'
            className='font-medium text-gray-900 cursor-pointer'
          >
            Use credits
          </label>
          <label
            htmlFor='payWithCredit'
            id='payWithCredit-description'
            className='block leading-4 text-gray-500 cursor-pointer'
          >
            You have {formattedCredit} remaining credits. Credits will
            automatically be applied.
          </label>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className='mt-10 sm:mt-0'>
        <div className='md:grid md:grid-cols-3 md:gap-6'>
          <div className='md:col-span-1'>
            <div className=''>
              <h3 className='text-lg font-semibold font-normal font-sans leading-6 text-gray-900'>
                Videos
              </h3>
              <p className='mt-1 text-sm font-normal font-sans leading-5 text-gray-500'>
                How many videos would you like? This will be from multiple
                creators.
              </p>
            </div>
          </div>
          <div className='mt-5 md:col-span-2 md:mt-0'>
            <div className='md:shadow rounded-t-md sm:overflow-hidden'>
              <div className='py-5 md:bg-white space-y-6 sm:p-6'>
                <div className='grid'>
                  <div className='flex flex-row justify-between'>
                    <div className='isolate inline-flex rounded-md'>
                      <button
                        type='button'
                        className='relative inline-flex items-center gap-x-1.5 rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10'
                        onClick={() => setQuantity(Math.max(quantity - 1, 1))}
                      >
                        <Icon
                          type={IconType.minus}
                          className='-ml-0.5 h-5 w-5 text-gray-500 text-[20px]'
                        />
                      </button>
                      <button
                        type='button'
                        className='relative -ml-px inline-flex items-center bg-white px-3 py-2 text-md font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10'
                      >
                        {quantity}
                      </button>
                      <button
                        type='button'
                        className='relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10'
                        onClick={() => setQuantity(Math.min(quantity + 1, 100))}
                      >
                        <Icon
                          type={IconType.plus}
                          className='-ml-0.5 h-5 w-5 text-gray-500 text-[20px]'
                        />
                      </button>
                    </div>

                    <p className='flex items-baseline justify-end gap-x-2'>
                      <span className='text-5xl font-bold tracking-tight text-gray-900'>
                        {mapCurrencyToSign(currency)} {totalPriceCents / 100}
                      </span>
                      {brand.countryCode === CountryCode.GB && (
                        <span className='text-sm font-semibold leading-6 tracking-wide text-gray-600'>
                          {` `}+ VAT
                        </span>
                      )}
                    </p>
                  </div>
                  {hooksCount > 0 && (
                    <div className='mt-2 text-gray-700 text-sm'>
                      For each creator, the total cost is{' '}
                      {mapCurrencyToSign(currency)}
                      {(pricePerVideoCents +
                        pricePerVariationCents * hooksCount +
                        pricePerAddons) /
                        100}
                      , which consists of {mapCurrencyToSign(currency)}
                      {pricePerVideoCents / 100} for the main video and{' '}
                      {mapCurrencyToSign(currency)}
                      {(pricePerVariationCents * hooksCount) / 100} for{' '}
                      {hooksCount} extra variation{hooksCount > 1 ? 's' : ''}
                      {hooksCount > 1 ? (
                        <>
                          {' '}
                          ({mapCurrencyToSign(currency)}
                          {pricePerVariationCents / 100} each)
                        </>
                      ) : null}
                      {pricePerAddons > 0 && (
                        <>
                          {' '}
                          and {mapCurrencyToSign(brand.currency)}
                          {pricePerAddons / 100} for addons
                        </>
                      )}
                      .
                    </div>
                  )}
                  {renderPayWithCreditInput()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='hidden sm:block' aria-hidden='true'>
        <div className='py-5'>
          <div className='border-t border-gray-200' />
        </div>
      </div>
    </div>
  )
}

export { EditQuantity }
