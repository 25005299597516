import { TextField } from '../../../../components'

interface Props {
  errors: any
  register: any
}

const SetDuration = ({ errors, register }: Props) => {
  return (
    <div>
      <div className='mt-10 sm:mt-0'>
        <div className='md:grid md:grid-cols-3 md:gap-6'>
          <div className='md:col-span-1'>
            <div className=''>
              <h3 className='text-lg font-semibold font-normal font-sans leading-6 text-gray-900'>
                Video duration
              </h3>
              <p className='mt-1 text-sm font-normal leading-5 text-gray-500 font-sans'>
                How long is the uploaded video?
              </p>
            </div>
          </div>
          <div className='mt-5 md:col-span-2 md:mt-0'>
            <div className='md:shadow rounded-t-md sm:overflow-hidden'>
              <div className='py-5 md:bg-white space-y-6 sm:p-6'>
                <div className='grid gap-6'>
                  <div className='col-span-3 sm:col-span-2'>
                    <TextField
                      error={errors.duration?.message}
                      label='Video duration'
                      placeholder='0'
                      rightElement={
                        <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3.5'>
                          <span
                            className='text-gray-500 sm:text-sm'
                            id='duration'
                          >
                            seconds (s)
                          </span>
                        </div>
                      }
                      {...register('duration', {
                        required: `Please set the duration.`,
                        min: {
                          value: 1,
                          message: `Please set the duration more than 0.`,
                        },
                        max: {
                          value: 500,
                          message: `Please set the duration less than 500.`,
                        },
                      })}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='hidden sm:block' aria-hidden='true'>
        <div className='py-5'>
          <div className='border-t border-gray-200' />
        </div>
      </div>
    </div>
  )
}

export { SetDuration }
