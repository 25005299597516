import { Collaboration } from '../../../../gql/types'
import { Avatar } from '../../../../elements'
import {
  Badge,
  BadgeSize,
  BadgeType,
  CollaborationStatus as CollaborationStatusComponent,
  TooltipInfo,
} from '../../../../components'
import { GridButtons } from '../GridButtons'
import { Link } from 'react-router-dom'
import { BrandRoutes } from '../../../BrandRoutes'
import { useState } from 'react'
import { CollaborationStatusTime } from '../../../CampaignView/components/CollaborationStatusTime'
import { UpdateProductTrackingLinkModal } from '../../../shared/UpdateProductTrackingLinkModal'

interface IProps {
  collaboration: Collaboration
}

const GridItem = ({ collaboration }: IProps) => {
  const [
    isUpdateProductTrackingLinkModalOpen,
    setIsUpdateProductTrackingLinkModalOpen,
  ] = useState(false)

  return (
    <div
      key={collaboration.id}
      className='col-span-1 divide-y divide-gray-200 rounded-lg bg-white border border-gray-200 shadow mx-2'
    >
      <Link
        to={BrandRoutes.collaborationViewProfile
          .replace(':id', `${collaboration.campaign.brand.id}`)
          .replace(':uuid', collaboration.uuid)}
        className='cursor-pointer flex gap-2 font-medium text-gray-900 hover:text-gray-800'
      >
        <div className='cursor-pointer flex w-full items-center justify-between align-top space-x-4 p-4'>
          <div className='flex flex-col'>
            <div className='flex text-gray-900 max-w-[150px] xs:max-w-[250px] sm:max-w-[400px] overflow-hidden truncate'>
              {collaboration.creator.firstName}
            </div>
            <div className='truncate mt-1 mb-0 flex flex-shrink-0 text-sm text-blue-600 hover:text-blue-500'>
              view profile
            </div>
          </div>
          <Avatar
            type='user'
            src={collaboration.creator.avatar?.url}
            size='sm'
            alt={collaboration.creator.firstName || ''}
          />
        </div>
      </Link>
      <dl className='space-y-3 p-4'>
        <div className='grid grid-cols-2 items-center'>
          <dt className='text-sm font-medium text-gray-500'>Status</dt>
          <dd
            className='-ml-2 text-sm text-gray-900'
            onClick={() => setIsUpdateProductTrackingLinkModalOpen(true)}
          >
            <CollaborationStatusComponent
              status={collaboration.status}
              onProductShippingClick={() =>
                setIsUpdateProductTrackingLinkModalOpen(true)
              }
            />
            {collaboration?.hooksCount > 0 && (
              <div className='mt-1 mb-0'>
                <Badge
                  type={BadgeType.indigo}
                  iconPosition='left'
                  text={
                    <TooltipInfo
                      valueKey='hooks-badge'
                      content={`+${collaboration?.hooksCount} variations`}
                      tooltipText='Each variation is an altered version of the original video, featuring a unique hook or call-to-action. The duration of each variation is up to 5 seconds.'
                    />
                  }
                  size={BadgeSize.small}
                  className='!mr-0'
                />
              </div>
            )}
            {collaboration?.addonsCount > 0 && (
              <div className='mt-1 mb-0'>
                <Badge
                  type={BadgeType.purple}
                  iconPosition='left'
                  text={
                    <TooltipInfo
                      valueKey='addons-badge'
                      content={`${collaboration?.addonsCount} addon${collaboration?.addonsCount > 1 ? 's' : ''} required`}
                      tooltipText='Each addon is an altered version of the original video with captions, music or additional elements.'
                    />
                  }
                  size={BadgeSize.small}
                  className='!mr-0'
                />
              </div>
            )}
          </dd>
        </div>
        <div className='grid grid-cols-2 items-center'>
          <dt className='text-sm font-medium text-gray-500'>Details</dt>
          <dd className='text-sm text-gray-900'>
            <CollaborationStatusTime
              status={collaboration.status}
              updatedAt={collaboration.updatedAt}
            />
          </dd>
        </div>
      </dl>
      <GridButtons collaboration={collaboration} />
      <UpdateProductTrackingLinkModal
        collaboration={collaboration}
        isOpen={isUpdateProductTrackingLinkModalOpen}
        setIsOpen={setIsUpdateProductTrackingLinkModalOpen}
      />
    </div>
  )
}

export { GridItem }
