import { FunctionComponent } from 'react'
import { Collaboration } from '../../../gql/types'
import { Avatar } from '../../../elements'
import { formatLongMessage } from '../../../utils/helpers'
import { StarRating } from '../../../components'

interface IProps {
  collaboration: Collaboration
}

interface IDefaultProps {}

const defaultProps: IDefaultProps = {}

const BrandCard: FunctionComponent<IProps> = ({ collaboration }) => {
  const campaign = collaboration.campaign
  const brand = campaign.brand

  return (
    <div className='bg-white shadow sm:overflow-hidden rounded-lg'>
      <div className='px-4 py-5 sm:px-6'>
        <div className='relative flex items-center space-x-3'>
          <div className='flex-shrink-0'>
            <Avatar
              type='brand'
              size='md'
              src={brand.logo?.url}
              alt={brand.name}
            />
          </div>
          <div className='min-w-0 flex-1'>
            <span className='absolute inset-0' aria-hidden='true' />
            <h3 className='text-lg leading-6 font-medium text-gray-900'>
              {/* Brand */}
              {brand.name}
            </h3>
            <p className='mt-1 text-sm text-gray-500'>
              More details and information about the brand.
            </p>
          </div>
        </div>
      </div>

      <div className='border-t border-gray-200 px-4 py-5 sm:p-0'>
        <dl className='sm:divide-y sm:divide-gray-200'>
          {/* <div className='py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
            <dt className='text-sm font-medium text-gray-500'>Name</dt>
            <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
              {brand.name}
            </dd>
          </div> */}
          <div className='py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
            <dt className='text-sm font-medium text-gray-500'>Website</dt>
            <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
              <a
                href={brand.website}
                className='text-blue-600 hover:text-blue-500'
                target='_blank'
                rel='noopener noreferrer'
              >
                {brand.website}
              </a>
            </dd>
          </div>
          <div className='py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
            <dt className='text-sm font-medium text-gray-500'>Reviews</dt>
            <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
              <StarRating
                ratingScore={
                  collaboration?.campaign?.brand.metadata?.ratingsScore
                }
                ratingCount={
                  collaboration?.campaign?.brand?.metadata?.ratingsCount
                }
              />
            </dd>
          </div>
          <div className='py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
            <dt className='text-sm font-medium text-gray-500'>About</dt>
            <dd
              className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'
              dangerouslySetInnerHTML={{
                __html: formatLongMessage(brand.description),
              }}
            ></dd>
          </div>
        </dl>
      </div>
    </div>
  )
}

BrandCard.defaultProps = defaultProps

export { BrandCard }
