import { useState } from 'react'
import { Brand, BrandOnboardingStep } from '../../../../gql/types'
import LOGO from '../../../../assets/logo/lancape_no_bg.png'
import classnames from 'classnames'
import { Icon, IconType } from '../../../../components'

const steps = [
  {
    id: '01',
    name: 'MY BRAND',
    description: `Brand & Product`,
    href: '#',
    status: 'upcoming',
    stepOnboarding: [
      BrandOnboardingStep.setWebsite,
      BrandOnboardingStep.setDetails,
      BrandOnboardingStep.setCampaignDetails,
      BrandOnboardingStep.setProductDetails,
    ],
  },
  {
    id: '02',
    name: 'VIDEO',
    description: 'Making a creative brief',
    href: '#',
    status: 'upcoming',
    stepOnboarding: [
      BrandOnboardingStep.setVideoType,
      BrandOnboardingStep.setModelType,
      // BrandOnboardingStep.setPlatform,
      BrandOnboardingStep.setVideoDuration,
      BrandOnboardingStep.setRequirements,
      BrandOnboardingStep.setAddons,
      BrandOnboardingStep.setHooks,
    ],
  },
  {
    id: '03',
    name: 'Creator',
    description: `Finding a matching creator`,
    href: '#',
    status: 'upcoming',
    stepOnboarding: [BrandOnboardingStep.setCreatorDetails],
  },
]

const StepUpcoming = ({ step, stepIdx }) => {
  return (
    <a href={step.href} className='group'>
      <span
        className='absolute top-0 left-0 w-1 h-full bg-transparent group-hover:bg-gray-200 lg:w-full lg:h-1 lg:bottom-0 lg:top-auto'
        aria-hidden='true'
      />
      <span
        className={classnames(
          stepIdx !== 0 ? 'lg:pl-9' : '',
          'px-6 py-5 flex items-start text-sm font-medium',
        )}
      >
        <span className='flex-shrink-0'>
          <span className='w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full'>
            <span className='text-gray-500'>{step.id}</span>
          </span>
        </span>
        <span className='mt-0.5 ml-4 min-w-0 flex flex-col'>
          <span className='text-sm font-medium text-gray-500'>{step.name}</span>
          <span className='text-sm font-medium text-gray-500'>
            {step.description}
          </span>
        </span>
      </span>
    </a>
  )
}

const StepCurrent = ({ step, stepIdx }) => {
  return (
    <>
      <a href={step.href} aria-current='step'>
        <span
          className='absolute top-0 left-0 w-1 h-full bg-gray-800 lg:w-full lg:h-1 lg:bottom-0 lg:top-auto'
          aria-hidden='true'
        />
        <span
          className={classnames(
            stepIdx !== 0 ? 'lg:pl-9' : '',
            'px-6 py-5 flex items-start text-sm font-medium',
          )}
        >
          <span className='flex-shrink-0'>
            <span className='w-10 h-10 flex items-center justify-center border-2 border-gray-800 rounded-full'>
              <span className='text-gray-800'>{step.id}</span>
            </span>
          </span>
          <span className='mt-0.5 ml-4 min-w-0 flex flex-col'>
            <span className='text-sm font-medium text-gray-800'>
              {step.name}
            </span>
            <span className='text-sm font-medium text-gray-500'>
              {step.description}
            </span>
          </span>
        </span>
      </a>
    </>
  )
}

const StepComplete = ({ step, stepIdx }) => {
  return (
    <a href={step.href} className='group'>
      <span
        className='absolute top-0 left-0 w-1 h-full bg-transparent group-hover:bg-gray-200 lg:w-full lg:h-1 lg:bottom-0 lg:top-auto'
        aria-hidden='true'
      />
      <span
        className={classnames(
          stepIdx !== 0 ? 'lg:pl-9' : '',
          'px-6 py-5 flex items-start text-sm font-medium',
        )}
      >
        <span className='flex-shrink-0'>
          <span className='w-10 h-10 flex items-center justify-center bg-gray-800 rounded-full'>
            <Icon
              type={IconType.check}
              className='w-6 h-6 text-white'
              aria-hidden='true'
            />
          </span>
        </span>
        <span className='mt-0.5 ml-4 min-w-0 flex flex-col'>
          <span className='text-sm font-medium'>{step.name}</span>
          <span className='text-sm font-medium text-gray-500'>
            {step.description}
          </span>
        </span>
      </span>
    </a>
  )
}

interface Props {
  brand: Brand
  stepCurrent: BrandOnboardingStep
}

const Steps = ({ brand, stepCurrent }: Props) => {
  const [indexCurrent, setIndexCurrent] = useState(null)
  const [percentStepCurrent, setPercentStepCurrent] = useState('')
  const [control, setControl] = useState('')

  const onSetPercent = (stepCurrent: BrandOnboardingStep) => {
    switch (stepCurrent) {
      case BrandOnboardingStep.setWebsite:
        setPercentStepCurrent('20%')
        break
      case BrandOnboardingStep.setDetails:
        setPercentStepCurrent('40%')
        break
      case BrandOnboardingStep.setCampaignDetails:
        setPercentStepCurrent('60%')
        break
      case BrandOnboardingStep.setProductDetails:
        setPercentStepCurrent('80%')
        break
      case BrandOnboardingStep.setVideoType:
        setPercentStepCurrent('20%')
        break
      case BrandOnboardingStep.setModelType:
        setPercentStepCurrent('35%')
        break
      // case BrandOnboardingStep.setPlatform:
      //   setPercentStepCurrent('60%')
      //   break
      case BrandOnboardingStep.setVideoDuration:
        setPercentStepCurrent('50%')
        break
      case BrandOnboardingStep.setRequirements:
        setPercentStepCurrent('65%')
        break
      case BrandOnboardingStep.setAddons:
        setPercentStepCurrent('80%')
        break
      case BrandOnboardingStep.setHooks:
        setPercentStepCurrent('95%')
        break
      case BrandOnboardingStep.setCreatorDetails:
        setPercentStepCurrent('80%')
        break
      case BrandOnboardingStep.setOrderSummary:
        setPercentStepCurrent('80%')
        break
      case BrandOnboardingStep.completed:
        setPercentStepCurrent('100%')
        break
      default:
        break
    }
    setControl(stepCurrent)
  }

  return (
    <>
      {/* lg screens */}
      <div className='hidden lg:block'>
        <div className='lg:border-t lg:border-b lg:border-gray-200'>
          <nav className='mx-auto max-w-7xl' aria-label='Progress'>
            <ol className='rounded-md overflow-hidden lg:flex lg:border-l lg:border-r lg:border-gray-200 lg:rounded-none'>
              {steps.map((step, stepIdx) => (
                <li
                  key={step.id}
                  className='relative overflow-hidden lg:flex-1'
                >
                  <div
                    className={classnames(
                      stepIdx === 0 ? 'border-b-0 rounded-t-md' : '',
                      stepIdx === steps.length - 1
                        ? 'border-t-0 rounded-b-md'
                        : '',
                      'border border-gray-200 overflow-hidden lg:border-0',
                    )}
                  >
                    {step.stepOnboarding.includes(stepCurrent) ? (
                      <>
                        {indexCurrent === null
                          ? setIndexCurrent(stepIdx)
                          : null}
                        <StepCurrent step={step} stepIdx={stepIdx} />
                      </>
                    ) : stepIdx < indexCurrent ? (
                      <>
                        <StepComplete step={step} stepIdx={stepIdx} />
                      </>
                    ) : (
                      <StepUpcoming step={step} stepIdx={stepIdx} />
                    )}

                    {stepIdx !== 0 ? (
                      <>
                        {/* Separator */}
                        <div
                          className='hidden absolute top-0 left-0 w-3 inset-0 lg:block'
                          aria-hidden='true'
                        >
                          <svg
                            className='h-full w-full text-gray-300'
                            viewBox='0 0 12 82'
                            fill='none'
                            preserveAspectRatio='none'
                          >
                            <path
                              d='M0.5 0V31L10.5 41L0.5 51V82'
                              stroke='currentcolor'
                              vectorEffect='non-scaling-stroke'
                            />
                          </svg>
                        </div>
                      </>
                    ) : null}
                  </div>
                </li>
              ))}
            </ol>
          </nav>
        </div>
      </div>

      {/* sm & md screens */}
      <div className='px-4 pt-4 lg:hidden'>
        <div className=''>
          <img className='h-12 w-auto' src={LOGO} alt='Workflow' />
        </div>
        <nav aria-label='progress' className='mt-4'>
          <ol className='flex space-y-0 space-x-4 md:space-x-8'>
            {steps.map((step, stepIdx) => (
              <li key={step.id} className='flex-1'>
                <div>
                  {step.stepOnboarding.includes(stepCurrent) ? (
                    <>
                      {indexCurrent === null ? setIndexCurrent(stepIdx) : null}
                      {control !== stepCurrent
                        ? onSetPercent(stepCurrent)
                        : null}
                      <div className='overflow-hidden rounded-full bg-gray-200'>
                        <a
                          href={step.href}
                          className='flex flex-col h-2 rounded-full bg-gray-800'
                          aria-current='step'
                          style={{ width: percentStepCurrent }}
                        ></a>
                      </div>
                    </>
                  ) : stepIdx < indexCurrent ? (
                    <>
                      <a
                        href={step.href}
                        className='flex flex-col h-2 rounded-full bg-gray-800'
                        aria-current='step'
                      ></a>
                    </>
                  ) : (
                    <div className='h-2 rounded-full bg-gray-200' />
                  )}
                </div>
              </li>
            ))}
            {/* {steps.map((step) => (
              <li key={step.name} className='flex-1'>
                {step.status === 'complete' ? (
                  <a
                    href={step.href}
                    className='group flex flex-col border-indigo-600 py-2 hover:border-indigo-800 border-l-0 border-t-4 pl-0 pt-4 md:pb-0'
                  >
                  </a>
                ) : step.status === 'current' ? (
                  <a
                    href={step.href}
                    className='flex flex-col border-indigo-600 py-2 pl-4 border-l-0 border-t-4pt-4 pb-0'
                    aria-current='step'
                  >
                  </a>
                ) : (
                  <a
                    href={step.href}
                    className='group flex flex-col border-gray-200 py-2 hover:border-gray-300 border-l-0 border-t-4 pl-0 pt-4 pb-0'
                  >
                  </a>
                )}
              </li>
            ))}  */}
          </ol>
        </nav>
      </div>
    </>
  )
}

export { Steps }
