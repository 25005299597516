import React from 'react'
import { ImageFileInput } from '../'
import { Avatar } from '../../elements'

interface IDefaultProps {}

const defaultProps: IDefaultProps = {
  title: '',
}

interface IProps extends Partial<IDefaultProps> {
  logoUpload?: string
  title?: string
  onChange: (file: File) => void
}

const LogoUpload: React.FC<IProps> = ({ logoUpload, title, onChange }) => {
  return (
    <>
      <p
        // className='text-sm font-medium leading-6 text-gray-900'
        className='text-sm font-medium text-gray-700'
        aria-hidden='true'
      >
        {title}
        {!title && <>Photo</>}
      </p>
      <div className='mt-1 lg:hidden'>
        <div className='flex items-center'>
          <div
            className='inline-block flex-shrink-0 overflow-hidden rounded-md'
            aria-hidden='true'
          >
            <Avatar size='sm' src={logoUpload} type='brand' alt='Logo' />
          </div>
          <div className='relative ml-5'>
            <ImageFileInput
              id='mobile-logoUrl'
              name='logoUrl'
              onChange={onChange}
              className='peer absolute h-full w-full rounded-md opacity-0'
            />
            <label
              htmlFor='mobile-logoUrl'
              className='pointer-events-none block rounded-md py-2 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 peer-hover:ring-gray-400 peer-focus:ring-2 peer-focus:ring-sky-500'
            >
              <span>{!logoUpload ? 'Upload' : 'Change'}</span>
              <span className='sr-only'>logo</span>
            </label>
          </div>
        </div>
      </div>

      <div className='mt-1 relative hidden overflow-hidden rounded-md lg:block'>
        <div className='flex items-center'>
          <Avatar size='sm' src={logoUpload} type='brand' alt='Logo' />
          <label
            htmlFor='desktop-logoUrl'
            className='block rounded-md py-3 px-3 ml-5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 peer-hover:ring-gray-400 peer-focus:ring-2 peer-focus:ring-sky-500'
          >
            <span>{!logoUpload ? 'Upload' : 'Change'}</span>
            <span className='sr-only'>logo</span>
            <ImageFileInput
              onChange={onChange}
              id='desktop-logoUrl'
              name='logoUrl'
              className='absolute inset-0 h-full w-full cursor-pointer rounded-md border-gray-300 opacity-0'
            />
          </label>
        </div>
      </div>
    </>
  )
}

LogoUpload.defaultProps = defaultProps
LogoUpload.displayName = 'LogoUpload'

export { LogoUpload }
