import { gql } from '@apollo/client'
import { BrandFragment } from './brands'
import { CreatorFragment } from './creators'
import { PaginationFragment } from './common'
import { GetMessagesResponse, MessageThread } from './types'

export const MessageFragment = gql`
  fragment MessageFragment on Message {
    id
    uuid
    message
    type
    createdAt
    updatedAt
    checkedStatus
    adminMessage
    isEditClipRequest
  }
`

export const MessageThreadFragment = gql`
  fragment MessageThreadFragment on MessageThread {
    id
    uuid
    createdAt
    updatedAt
    brandUnreadMessagesCount
    creatorUnreadMessagesCount
    lastMessage
    lastMessageSentAt
    brand {
      ...BrandFragment
    }
    creator {
      ...CreatorFragment
    }
  }
  ${CreatorFragment}
  ${BrandFragment}
`

export const GET_MESSAGES_BY_THREAD = gql`
  query GetMessagesByThread($threadId: Int!, $options: GetMessagesOptions!) {
    getMessagesByThread(threadId: $threadId, options: $options) {
      items {
        ...MessageFragment
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${MessageFragment}
  ${PaginationFragment}
`

export const CREATE_MESSAGES = gql`
  mutation CreateMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      ...MessageFragment
    }
  }
  ${MessageFragment}
`

export const GET_MESSAGES_THREADS = gql`
  query GetMessagesThreads($options: GetMessagesThreadsOptions!) {
    getMessagesThreads(options: $options) {
      items {
        ...MessageThreadFragment
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${MessageThreadFragment}
  ${PaginationFragment}
`

export const GET_MESSAGE_THREAD_BY_UUID = gql`
  query GetMessageThreadByUuid($uuid: String!) {
    getMessageThreadByUuid(uuid: $uuid) {
      ...MessageThreadFragment
    }
  }
  ${MessageThreadFragment}
`

export const MARK_THREAD_MESSAGES_AS_READ_BY_BRAND = gql`
  mutation MarkThreadMessagesAsReadByBrand($uuid: String!) {
    markThreadMessagesAsReadByBrand(uuid: $uuid) {
      ...MessageThreadFragment
    }
  }
  ${MessageThreadFragment}
`

export const MARK_ALL_MESSAGES_AS_READ_BY_BRAND = gql`
  mutation MarkAllMessagesAsReadByBrand($id: Int!) {
    markAllMessagesAsReadByBrand(id: $id) {
      ...BrandFragment
    }
  }
  ${BrandFragment}
`

export const MARK_THREAD_MESSAGES_AS_UNREAD_BY_BRAND = gql`
  mutation MarkThreadMessagesAsUnreadByBrand($uuid: String!) {
    markThreadMessagesAsUnreadByBrand(uuid: $uuid) {
      ...MessageThreadFragment
    }
  }
  ${MessageThreadFragment}
`

export const MARK_THREAD_MESSAGES_AS_READ_BY_CREATOR = gql`
  mutation MarkThreadMessagesAsReadByCreator($uuid: String!) {
    markThreadMessagesAsReadByCreator(uuid: $uuid) {
      ...MessageThreadFragment
    }
  }
  ${MessageThreadFragment}
`

export const MARK_ALL_MESSAGES_AS_READ_BY_CREATOR = gql`
  mutation MarkAllMessagesAsReadByCreator($id: Int!) {
    markAllMessagesAsReadByCreator(id: $id) {
      ...CreatorFragment
    }
  }
  ${CreatorFragment}
`

export const MARK_THREAD_MESSAGES_AS_UNREAD_BY_CREATOR = gql`
  mutation MarkThreadMessagesAsUnreadByCreator($uuid: String!) {
    markThreadMessagesAsUnreadByCreator(uuid: $uuid) {
      ...MessageThreadFragment
    }
  }
  ${MessageThreadFragment}
`

export interface GetMessagesByThreadResponse {
  getMessagesByThread: GetMessagesResponse
}

export interface GetMessageThreadByUuidResponse {
  getMessageThreadByUuid: MessageThread
}
