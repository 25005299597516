import React from 'react'
import { useQuery } from '@apollo/client'
import { Loader } from '../../../../../components'
import { GET_CREATOR_EARNINGS } from '../../../../../gql'
import {
  Creator,
  CreatorEarningsResponse,
  QueryGetCreatorEarningsByCreatorIdArgs,
} from '../../../../../gql/types'
import { formatPriceInCents } from '../../../../../utils/PricingUtil'

interface IProps {
  creator: Creator
}

export const EarningsStats: React.FC<IProps> = ({ creator }) => {
  const { loading, error, data } = useQuery<
    { getCreatorEarningsByCreatorId: CreatorEarningsResponse },
    QueryGetCreatorEarningsByCreatorIdArgs
  >(GET_CREATOR_EARNINGS, {
    variables: {
      creatorId: creator.id,
    },
  })

  if (loading) {
    return <Loader />
  }

  const response = data?.getCreatorEarningsByCreatorId
  if (error || !response) {
    console.log(error)
    return <p>There has been an error. Try refreshing the page</p>
  }

  const earningsStats = response.earnings.map((earning) => [
    {
      name: `Total ${earning.currency} earnings`,
      stat: formatPriceInCents({
        priceInCents: earning.totalEarningCents,
        currency: earning.currency,
      }),
      colorClassName: 'text-gray-900',
    },
    {
      name: `Total ${earning.currency} payments`,
      stat: formatPriceInCents({
        priceInCents: earning.totalPaymentCents,
        currency: earning.currency,
      }),
      colorClassName: 'text-green-600',
    },
    {
      name: `Total ${earning.currency} pending payments`,
      stat: formatPriceInCents({
        priceInCents: earning.totalPendingPaymentCents,
        currency: earning.currency,
      }),
      colorClassName: 'text-red-600',
    },
  ])

  return (
    <div>
      <dl className='grid grid-cols-1 gap-5 sm:grid-cols-3'>
        {earningsStats.map((items) =>
          items.map((item) => (
            <div
              key={item.name}
              className='overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6'
            >
              <dt className='truncate text-sm font-medium text-gray-500'>
                {item.name}
              </dt>
              <dd
                className={`mt-1 text-3xl font-semibold tracking-tight ${item.colorClassName}`}
              >
                {item.stat}
              </dd>
            </div>
          )),
        )}
      </dl>
    </div>
  )
}
