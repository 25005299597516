import { useMutation } from '@apollo/client'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  Button,
  ButtonColor,
  NotificationType,
  RadioGroup,
} from '../../../components'
import { UPDATE_BRAND, UPDATE_CAMPAIGN } from '../../../gql'
import {
  Brand,
  BrandOnboardingStep,
  Campaign,
  VideoType,
} from '../../../gql/types'
import { AnalyticsEvent, trackEvent } from '../../../utils/analytics'
import { videoTypeOptions } from '../../../utils/mappers'

import { Bullets } from '../components/Bullets'
import { Steps } from '../components/Steps'
import { useNotification } from '../../../utils/hooks'

type EditVideoTypeFormData = {
  videoType: VideoType
}

interface Props {
  brand: Brand
  campaign: Campaign
}

const SetVideoType = ({ brand, campaign }: Props) => {
  const { addNotification } = useNotification()

  const [updateBrand, { loading: loadingUpdateBrand }] =
    useMutation(UPDATE_BRAND)
  const [updateCampaign, { loading: loadingUpdateCampaign }] =
    useMutation(UPDATE_CAMPAIGN)

  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors },
    setValue,
    watch,
  } = useForm<EditVideoTypeFormData>({
    defaultValues: {
      videoType: campaign?.videoType ?? null,
    },
  })

  useEffect(() => {
    trackEvent(AnalyticsEvent.viewBrandsOnboardingSetVideoType)
  }, [])

  const goBack = async () => {
    try {
      await updateBrand({
        variables: {
          id: brand.id,
          input: {
            onboarding: BrandOnboardingStep.setCampaignDetails,
          },
        },
      })
    } catch (error) {
      addNotification(error.message, NotificationType.error)
    }
  }

  const onSubmit = async ({ videoType }: EditVideoTypeFormData) => {
    try {
      await Promise.all([
        updateCampaign({
          variables: {
            id: campaign.id,
            input: {
              videoType,
            },
          },
        }),
        updateBrand({
          variables: {
            id: brand.id,
            input: {
              onboarding: BrandOnboardingStep.setModelType,
            },
          },
        }),
      ])
    } catch (error) {
      console.log('@error SetVideoType', error.message)
      if (error.message) {
        setError('videoType', { type: 'custom', message: error.message })
        return
      }

      addNotification(error.message, NotificationType.error)
    }
  }

  return (
    <div className='min-h-screen flex flex-col pb-14'>
      <div className='flex-grow'>
        <Steps brand={brand} stepCurrent={BrandOnboardingStep.setVideoType} />

        <div className='min-h-full flex flex-col justify-center pt-4 px-4 md:mt-8 lg:px-8'>
          <div className='sm:mx-auto sm:w-full sm:max-w-2xl'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='md:shadow rounded-t-md sm:overflow-hidden'>
                <div className='py-5 md:bg-white space-y-6 sm:p-6'>
                  <div className='sm:mx-auto'>
                    <h2 className='mt-0 md:text-center text-3xl font-extrabold text-gray-900'>
                      Video type
                    </h2>
                    <p className='mt-2 md:text-center text-sm text-gray-600'>
                      Let’s create your first brief!
                    </p>
                  </div>

                  <div className='grid gap-6'>
                    <div className='col-span-3 sm:col-span-2'>
                      <Controller
                        name='videoType'
                        control={control}
                        rules={{ required: `Tell us what is your video type.` }}
                        render={({ field }) => (
                          <RadioGroup
                            options={videoTypeOptions}
                            field={field}
                            error={errors?.videoType?.message}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='flex justify-between md:shadow rounded-b-md py-3 md:bg-gray-50 sm:px-6'>
                <Button
                  title='Go back'
                  type='button'
                  colour={ButtonColor.white}
                  loading={loadingUpdateBrand}
                  disabled={loadingUpdateBrand}
                  onClick={goBack}
                />
                <Button
                  title='Continue'
                  type='submit'
                  loading={loadingUpdateBrand || loadingUpdateCampaign}
                  disabled={loadingUpdateBrand || loadingUpdateCampaign}
                />
              </div>
            </form>
          </div>
        </div>
      </div>

      <Bullets brand={brand} stepCurrent={BrandOnboardingStep.setVideoType} />
    </div>
  )
}

export { SetVideoType }
