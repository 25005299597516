import { Fragment, FunctionComponent } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { Icon, IconType } from '../Icon'
import { CountryCode, Currency } from '../../gql/types'
import { mapCountryCodeToFlagAndText } from '../../utils/mappers'

interface IDefaultProps {
  error?: string
  className?: string
}

interface IProps extends Partial<IDefaultProps> {
  label?: string
  options: any
  field: any
  defaultValues?: any
}

const defaultProps: IDefaultProps = {
  error: null,
  className: null,
}

const Select: FunctionComponent<IProps> = (props: IProps) => {
  const { options, field, label, className, error, defaultValues } = props
  const { onChange, name, value } = field

  return (
    <>
      <Listbox value={value} onChange={onChange}>
        {({ open }) => (
          <>
            {label && (
              <Listbox.Label className='block text-sm leading-5 font-medium text-gray-700'>
                {label}
              </Listbox.Label>
            )}
            <div className='relative mt-1'>
              <Listbox.Button
                // pl-3 pr-10 ?
                className={classNames(
                  'relative w-full cursor-pointer rounded-md border border-gray-300 bg-white py-2 px-3 text-left shadow-sm focus:border-gray-800 focus:outline-none focus:ring-gray-800 text-sm',
                  className,
                )}
              >
                <span className='block truncate mr-5'>
                  {value
                    ? Object.values(Currency).includes(value as any as Currency)
                      ? (value as any).toUpperCase()
                      : Object.values(CountryCode).includes(
                            value as any as CountryCode,
                          )
                        ? (mapCountryCodeToFlagAndText(value) as any)
                        : (value as any)
                    : defaultValues ?? 'Select'}
                </span>
                <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                  <Icon
                    type={IconType.chevronDown}
                    className='h-5 w-5 text-gray-400'
                    aria-hidden='true'
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave='transition ease-in duration-100'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <Listbox.Options className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm'>
                  {options.map((option, optionIdx) => (
                    <Listbox.Option
                      key={optionIdx}
                      className={({ active }) =>
                        classNames(
                          active ? 'text-white bg-gray-500' : 'text-gray-900',
                          //  pl-3 pr-9 ?
                          'relative cursor-pointer select-none py-2 px-3',
                        )
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'block truncate',
                            )}
                          >
                            {option}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-gray-900',
                                'absolute inset-y-0 right-0 flex items-center pr-2',
                              )}
                            >
                              <Icon
                                type={IconType.check}
                                className='h-5 w-5'
                                aria-hidden='true'
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      {error && (
        <div className='mt-1 relative flex'>
          <div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
            <Icon
              type={IconType.exclamationCircleFill}
              className='h-5 w-5 text-red-500'
              aria-hidden='true'
            />
          </div>
          <p className='mt-2 text-sm text-red-600'>{error}</p>
        </div>
      )}
    </>
  )
}

export { Select }
