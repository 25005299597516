import { Campaign, CampaignHook } from '../../../gql/types'
import {
  modelTypeLocalizedString,
  videoDurationLocalizedString,
  videoTypeLocalizedString,
  voiceTypeLocalizedString,
} from '../../../utils/GqlStrings'
import { mapCampaignAddonsTypeToText } from '../../../utils/mappers'
import { BriefTextRow } from '../BriefRow'

interface IVideoProps {
  campaign: Campaign
  hooks?: string[]
  viewMode?: 'brand' | 'creator'
}

export const VideoBrief = ({ campaign, hooks, viewMode }: IVideoProps) => {
  const { addons } = campaign

  return (
    <div className='@container'>
      <div className='grid @xl:grid-cols-4 @xl:gap-6 grid-cols-1 gap-2'>
        <div>
          <h3 className='text-base font-semibold leading-6 text-gray-900'>
            Video Brief
          </h3>
        </div>
        <div className='overflow-hidden bg-white shadow rounded-lg col-span-3'>
          <div className='border-t border-gray-200 px-4'>
            <dl className='@md:divide-y @md:divide-gray-200'>
              <BriefTextRow
                title='Type'
                value={videoTypeLocalizedString(campaign.videoType)}
              />
              <BriefTextRow
                title='Model'
                value={modelTypeLocalizedString(campaign.modelType)}
              />
              <BriefTextRow
                title='Voice'
                value={voiceTypeLocalizedString(campaign.voiceType)}
              />
              <BriefTextRow
                title='Duration'
                value={videoDurationLocalizedString(campaign.videoDuration)}
              />
              <BriefTextRow
                title='Mentions & Scenes'
                value={campaign.requirements}
                formatHTML={true}
              />
              {hooks?.length > 0 && (
                <div>
                  <hr />
                  {viewMode === 'creator' && (
                    <p className='mt-4 text-sm text-gray-900'>
                      This campaign requires {hooks.length} extra variation
                      {hooks.length > 1 ? 's' : ''}. Please make sure to create
                      and upload {hooks.length} distinct version
                      {hooks.length > 1 ? 's' : ''} of{' '}
                      {videoDurationLocalizedString(campaign.videoDuration)},
                      each as a separate file. Check the details for each
                      variation below.
                    </p>
                  )}
                  {hooks?.map((hook, index) => (
                    <div key={index}>
                      <BriefTextRow
                        title={`Variation ${index + 1}`}
                        value={hook}
                        formatHTML={true}
                      />
                    </div>
                  ))}
                </div>
              )}
              {addons?.length > 0 && (
                <div>
                  <hr />
                  {viewMode === 'creator' && (
                    <p className='mt-4 text-sm text-gray-900'>
                      This campaign requires {addons.length} extra addon
                      {addons.length > 1 ? 's' : ''}. Check the details below.
                    </p>
                  )}
                  {addons?.map((addon, index) => (
                    <div key={index}>
                      <BriefTextRow
                        title={`Addon`}
                        value={mapCampaignAddonsTypeToText(addon)}
                        formatHTML={true}
                      />
                    </div>
                  ))}
                </div>
              )}
            </dl>
          </div>
        </div>
      </div>
    </div>
  )
}
