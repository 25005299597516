import { useQuery } from '@apollo/client'
import { useState } from 'react'
import { ListOptions, Loader } from '../../components'
import {
  GET_BRAND_TEMPLATES,
  QueryGetBrandTemplatesResponse,
} from '../../gql/templates'
import {
  Brand,
  GetTemplatesSort,
  QueryGetBrandTemplatesArgs,
  User,
} from '../../gql/types'
import { Header } from './components/Header'
import { List } from './components/List'

export type EditTemplateFormData = {
  name: string
  message: string
}

interface IProps {
  user: User
  brand: Brand
}

const Templates = ({ user, brand }: IProps) => {
  const limit = 6
  const [skip, setSkip] = useState(0)

  // get templates
  const { loading, error, data, refetch } = useQuery<
    QueryGetBrandTemplatesResponse,
    QueryGetBrandTemplatesArgs
  >(GET_BRAND_TEMPLATES, {
    variables: {
      brandId: brand.id,
      options: {
        query: '',
        filters: {
          // ownerUserId: [user.id],
        },
        sort: GetTemplatesSort.newest,
        skip: 0,
        limit: 100,
      },
    },
  })

  const [query, setQuery] = useState('')

  const refetchData = (query) => {
    refetch({
      options: {
        query: query,
        filters: {},
        sort: GetTemplatesSort.newest,
        limit,
        skip,
      },
    })
  }

  const onSearch = () => {
    refetchData(query)
  }

  if (error) {
    console.log(error)
    return <p>There has been an error. Try refreshing the page</p>
  }

  const setPage = (page: number) => {
    setSkip(page * limit)
  }

  if (!brand) {
    return <Loader />
  }

  return (
    <div>
      <Header user={user} brandId={brand.id} />

      {loading && <Loader />}
      {!loading && data && (
        <div className='pb-4 w-auto h-fit'>
          <div className='-my-2 -mx-4 overflow-x-auto'>
            <div className='inline-block min-w-full py-2 px-1 align-middle'>
              <div className='shadow ring-1 ring-black ring-opacity-5 rounded-t'>
                <ListOptions
                  query={query}
                  setQuery={setQuery}
                  onSearch={onSearch}
                />
                {/* <div className='hidden lg:block'> */}
                <List
                  templates={data?.getBrandTemplates?.items}
                  pagination={data?.getBrandTemplates?.pagination}
                  setPage={setPage}
                  loading={loading}
                />
                {/* </div> */}
                <div className='block lg:hidden'>
                  {/* <Grid
                    products={data.getProductsByBrandId.items}
                    pagination={data.getProductsByBrandId.pagination}
                    setPage={setPage}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export { Templates }
