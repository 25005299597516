import { FunctionComponent, useEffect, useState } from 'react'
import Masonry from 'react-masonry-css'
import {
  Creator,
  GetPortfolioSort,
  PortfolioItem,
  PortfolioItemType,
  QueryGetPortfolioByCreatorArgs,
} from '../../gql/types'
import { useQuery } from '@apollo/client'
import {
  GET_PORTFOLIO_BY_CREATOR,
  GetPortfolioByCreatorResponse,
} from '../../gql'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import InfiniteScroll from 'react-infinite-scroller'
import { DownloadFileButton } from '../DownloadFileButton'
import { DeletePortfolioItemButton } from '../DeletePortfolioItemButton'
import { VideoZoom } from '../VideoZoom'
import { Avatar } from '../../elements'
import { PortfolioQuickView } from '../PortfolioQuickView'
import { LIMIT_DEFAULT } from '../../utils/constants'

interface IDefaultProps {
  useWindow: boolean
  allowDownload: boolean
  allowDelete: boolean
  limitAndUseSlider: boolean
}

interface IProps extends Partial<IDefaultProps> {
  creator: Creator
}

const defaultProps: IDefaultProps = {
  useWindow: true,
  allowDownload: false,
  allowDelete: false,
  limitAndUseSlider: false,
}

const PortfolioGridNew: FunctionComponent<IProps> = ({
  creator,
  useWindow,
  allowDownload,
  allowDelete,
  limitAndUseSlider,
}) => {
  let [isPortofolioSliderOpen, setIsPortofolioSliderOpen] = useState(false)

  const [numCols, setNumCols] = useState(6)
  const [gridElement, setGridElement] = useState<HTMLDivElement | null>(null)

  const getNumColumns = (width: number) => {
    if (width >= 1536) return 6
    else if (width >= 1280) return 5
    else if (width >= 1024) return 4
    else if (width >= 768) return 3
    else return 2
  }

  const limit = LIMIT_DEFAULT

  const { loading, data, fetchMore } = useQuery<
    GetPortfolioByCreatorResponse,
    QueryGetPortfolioByCreatorArgs
  >(GET_PORTFOLIO_BY_CREATOR, {
    variables: {
      creatorId: creator.id,
      options: {
        limit,
        skip: 0,
        sort: GetPortfolioSort.newest,
      },
    },
    skip: !creator,
  })

  useEffect(() => {
    if (!gridElement) return

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const newNumCols = getNumColumns(entry.contentRect.width)
        setNumCols(newNumCols)
      }
    })

    resizeObserver.observe(gridElement)

    return () => {
      resizeObserver.disconnect()
    }
  }, [gridElement])

  const fetchData = () => {
    if (loading || limitAndUseSlider) {
      return
    }

    // if we have all the data, don't fetch more
    if (
      data?.getPortfolioByCreator?.items?.length >=
      data?.getPortfolioByCreator?.pagination?.numberOfHits
    ) {
      return
    }

    fetchMore({
      variables: {
        creatorId: creator.id,
        options: {
          limit,
          skip: data?.getPortfolioByCreator?.items?.length ?? 0,
          sort: GetPortfolioSort.newest,
        },
      },
    })
  }

  return (
    <>
      <InfiniteScroll
        loadMore={fetchData}
        hasMore={
          data?.getPortfolioByCreator?.items?.length <
          data?.getPortfolioByCreator?.pagination?.numberOfHits
        }
        initialLoad={false}
        useWindow={useWindow}
      >
        <div ref={setGridElement}>
          <Masonry
            breakpointCols={numCols}
            className='flex p-3 -ml-6 md:-ml-6 lg:-ml-8 xl:-ml-8 2xl:-ml-10'
            columnClassName='pl-6 space-y-6 md:pl-6 md:space-y-6 lg:pl-8 lg:space-y-8 xl:pl-8 xl:space-y-8 2xl:pl-10 2xl:space-y-10 '
          >
            {data?.getPortfolioByCreator?.items?.map(
              (portfolioItem: PortfolioItem, index) => (
                <div
                  key={index}
                  className='shadow-2xl overflow-hidden rounded-xl ring-1 ring-black ring-opacity-20 w-full relative'
                >
                  {portfolioItem.type === PortfolioItemType.video && (
                    <VideoZoom
                      src={portfolioItem?.video?.url}
                      thumbnailSrc={portfolioItem?.thumbnail?.url}
                      className='cursor-pointer rounded-xl w-full object-cover hover:opacity-75'
                    />
                  )}
                  <div className='absolute left-2 top-2 gap-y-1 overflow-hidden text-sm leading-6 text-black bg-gray-100 rounded-xl px-2 py-1'>
                    <div className='flex gap-x-2 items-center '>
                      <Avatar
                        type='user'
                        size='xxs'
                        src={portfolioItem?.creator?.avatar?.url}
                        alt={portfolioItem?.creator?.firstName}
                      />
                      {portfolioItem?.creator?.firstName}
                    </div>
                  </div>
                  {allowDownload && (
                    <div className='absolute right-10 bottom-2 gap-y-1 overflow-hidden text-sm leading-6 text-white'>
                      <DownloadFileButton
                        fileUrl={portfolioItem?.video?.url}
                        fileName={portfolioItem?.video?.name}
                      />
                    </div>
                  )}
                  {allowDelete && (
                    <div className='absolute right-2 bottom-2 gap-y-1 overflow-hidden text-sm leading-6 text-white'>
                      <DeletePortfolioItemButton
                        portfolioId={portfolioItem.id}
                      />
                    </div>
                  )}
                </div>
              ),
            )}
            {loading &&
              [...Array(limit)].map((x, i) => (
                <div key={i}>
                  <Skeleton className='shadow-2xl overflow-hidden !rounded-xl ring-1 ring-black ring-opacity-20 w-full relative aspect-[9/16]' />
                </div>
              ))}
          </Masonry>
        </div>
      </InfiniteScroll>
      {data?.getPortfolioByCreator?.items?.length >=
        data?.getPortfolioByCreator?.pagination?.numberOfHits &&
        !limitAndUseSlider && (
          <div className='flex justify-center py-2'>
            <span className='text-gray-500 text-sm'>
              No more content to load
            </span>
          </div>
        )}
      {limitAndUseSlider &&
        data?.getPortfolioByCreator?.pagination?.numberOfHits > limit && (
          <div
            className='flex justify-center my-2 text-blue-500 hover:text-blue-700 cursor-pointer'
            onClick={() => setIsPortofolioSliderOpen(true)}
          >
            see more
          </div>
        )}
      {isPortofolioSliderOpen && (
        <PortfolioQuickView
          creator={creator}
          open={isPortofolioSliderOpen}
          setOpen={setIsPortofolioSliderOpen}
        />
      )}
    </>
  )
}

PortfolioGridNew.defaultProps = defaultProps

export { PortfolioGridNew }
