import { useState } from 'react'
import { Icon, IconType, OrderClipModal, Slider } from '../../../components'
import { CreatorInterests } from '../../../components/CreatorInterests'
import { Divider } from '../../../components/Divider'
import { VideoPlayer } from '../../../components/VideoPlayer'
import { Avatar } from '../../../elements'
import { Collaboration, CollaborationStatus, Creator } from '../../../gql/types'
import { formatLongMessage } from '../../../utils/helpers'
import { AcceptApplicantButton } from '../AcceptApplicantButton'
import { PortfolioGridNew } from '../../../components/PortfolioGridNew'

interface IProps {
  open: boolean
  setOpen: any
  creator: Creator
  collaboration?: Collaboration
  onCollaborationAccepted?: () => void
}

const ViewCreatorSlider = ({
  open,
  setOpen,
  creator,
  collaboration,
  onCollaborationAccepted,
}: IProps) => {
  const [isOrderClipOpen, setIsOrderClipOpen] = useState(false)

  return (
    <>
      {collaboration && isOrderClipOpen && (
        <OrderClipModal
          campaign={collaboration.campaign}
          title={`You've used up your paid clips`}
          description={`To approve more creators and get more clips, select the number of additional videos you would like and hit 'Confirm your order' below.`}
          open={isOrderClipOpen}
          setOpen={setIsOrderClipOpen}
        />
      )}

      <Slider setOpen={setOpen} open={open}>
        <div className='flex h-full flex-col overflow-y-scroll bg-white shadow-xl'>
          {/* Header */}
          <div className='sm:sticky top-0 z-10 bg-white'>
            <div className='px-4 pt-6 sm:px-6'>
              <div className='flex items-start justify-between'>
                <Avatar
                  type='user'
                  src={creator.avatar?.url}
                  size='md'
                  alt={creator.firstName}
                />
                <div className='ml-3 flex h-7 items-center'>
                  <button
                    type='button'
                    className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none'
                    onClick={() => setOpen(false)}
                  >
                    <span className='sr-only'>Close panel</span>
                    <Icon
                      type={IconType.x}
                      className='h-6 w-6'
                      aria-hidden='true'
                    />
                  </button>
                </div>
              </div>
              <h3 className='text-xl font-bold text-gray-900 sm:text-2xl mt-6'>
                {creator?.firstName}
              </h3>
              {collaboration &&
                collaboration.status === CollaborationStatus.creatorApplied && (
                  <AcceptApplicantButton
                    collaboration={collaboration}
                    onCollaborationAccepted={onCollaborationAccepted}
                  />
                )}
            </div>
            <Divider className='py-6' />
          </div>

          {/* Main */}
          <div className='pb-1 sm:pb-6 px-4 h-full flex flex-col sm:px-6 overflow-auto'>
            <div className='flex-1'>
              <h4 className='text-sm font-medium text-gray-700'>Interests</h4>
              <div className='mt-1.5 sm:items-end flex-wrap'>
                {creator.interests?.map((interest, index) => (
                  <CreatorInterests key={index} interest={interest} />
                ))}
              </div>
            </div>
            {creator.bio && (
              <div className='flex-1 mt-6'>
                <h4 className='text-sm font-medium text-gray-700'>About</h4>
                <div className='mt-1.5 sm:items-end flex-wrap'>
                  {creator.bio}
                </div>
              </div>
            )}
            {creator.occupation && (
              <div className='flex-1 mt-6'>
                <h4 className='text-sm font-medium text-gray-700'>
                  Occupation
                </h4>
                <div className='mt-1.5 sm:items-end flex-wrap'>
                  {creator.occupation}
                </div>
              </div>
            )}
            {collaboration && collaboration.creatorApplicationMessage && (
              <div className='flex-1 mt-6'>
                <h4 className='text-sm font-medium text-gray-700'>
                  Application
                </h4>
                <div
                  className='mt-1.5 sm:items-end flex-wrap'
                  dangerouslySetInnerHTML={{
                    __html: formatLongMessage(
                      collaboration.creatorApplicationMessage,
                    ),
                  }}
                ></div>
              </div>
            )}
            {creator.introVideo?.url && (
              <div className='sm:flex-1 mt-6'>
                <h4 className='text-sm font-medium text-gray-700'>
                  Introduction Video
                </h4>
                <div className='mt-4 sm:items-end flex'>
                  <VideoPlayer
                    src={creator.introVideo?.url}
                    poster={creator.thumbnail?.url}
                    className='flex-1 aspec-w-9 aspec-h-16 rounded-lg shadow-lg object-cover'
                    controls
                  />
                </div>
              </div>
            )}

            <h4 className='mt-6 text-sm font-medium text-gray-700'>
              Portfolio
            </h4>
            <PortfolioGridNew creator={creator} useWindow={false} />
          </div>
        </div>
      </Slider>
    </>
  )
}

export { ViewCreatorSlider }
