import classNames from 'classnames'
import { forwardRef, ReactNode } from 'react'
import { ChangeHandler, RefCallBack } from 'react-hook-form'
import { Icon, IconType } from '../Icon'

interface IDefaultProps {
  leftElement?: ReactNode
  error?: string
  className?: string
  disabled?: boolean
  minRows?: number
  autoFocus?: any
}

interface IProps extends Partial<IDefaultProps> {
  label?: string
  placeholder?: string
  name: string
  onChange?: ChangeHandler
  onBlur?: ChangeHandler
  ref: RefCallBack
  field?: any
}

const defaultProps: IDefaultProps = {
  error: null,
  className: null,
  minRows: 7,
}

const TextArea = forwardRef<HTMLTextAreaElement, IProps>((props, ref) => {
  const {
    label,
    placeholder,
    onChange,
    onBlur,
    name,
    error,
    className,
    disabled,
    minRows,
    field,
    autoFocus,
    ...rest
  } = props

  // const { id, onChange, onBlur, value, name } = field

  return (
    <>
      {label && (
        <label className='block text-sm font-medium text-gray-700'>
          {label}
        </label>
      )}
      <div className='mt-1 relative flex rounded-md shadow-sm'>
        <input
          className='sr-only'
          {...(field && { ...field })}
          autoFocus={autoFocus}
        />
        <textarea
          rows={minRows}
          className={classNames(
            'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-500 focus:outline-none focus:ring-gray-800 focus:border-gray-800 text-sm',
            className,
          )}
          placeholder={placeholder}
          name={name}
          {...(onChange && { onChange: onChange })}
          {...(onBlur && { onBlur: onBlur })}
          ref={ref}
          {...rest}
          disabled={disabled}
        />
        {error && (
          <div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
            <Icon
              type={IconType.exclamationCircleFill}
              className='h-5 w-5 text-red-500'
              aria-hidden='true'
            />
          </div>
        )}
      </div>
      {error && <p className='mt-2 text-sm text-red-600'>{error}</p>}
    </>
  )
})

TextArea.displayName = 'TextArea'
TextArea.defaultProps = defaultProps

export { TextArea }
