import { useState } from 'react'
import { Brand, BrandOnboardingStep } from '../../../../gql/types'

const steps = [
  {
    id: '01',
    name: 'MY BRAND',
    description: `Brand & Product`,
    href: '#',
    status: 'upcoming',
    stepOnboarding: [
      BrandOnboardingStep.setWebsite,
      BrandOnboardingStep.setDetails,
      BrandOnboardingStep.setCampaignDetails,
      BrandOnboardingStep.setProductDetails,
    ],
  },
  {
    id: '02',
    name: 'VIDEO',
    description: 'Making a creative brief',
    href: '#',
    status: 'upcoming',
    stepOnboarding: [
      BrandOnboardingStep.setVideoType,
      BrandOnboardingStep.setModelType,
      // BrandOnboardingStep.setPlatform,
      BrandOnboardingStep.setVideoDuration,
      BrandOnboardingStep.setRequirements,
      BrandOnboardingStep.setAddons,
      BrandOnboardingStep.setHooks,
    ],
  },
  {
    id: '03',
    name: 'Creator',
    description: `Finding a matching creator`,
    href: '#',
    status: 'upcoming',
    stepOnboarding: [BrandOnboardingStep.setCreatorDetails],
  },
]

const StepUpcoming = ({ step }) => {
  return (
    <>
      <a
        href={step.href}
        className='block w-2.5 h-2.5 mx-1 bg-gray-200 rounded-full hover:bg-gray-400'
      >
        <span className='sr-only'>{step.name}</span>
      </a>
    </>
  )
}

const StepCurrent = ({ step }) => {
  return (
    <a
      href={step.href}
      className='relative flex items-center justify-center'
      aria-current='step'
    >
      <span className='absolute w-5 h-5 p-px flex' aria-hidden='true'>
        <span className='w-full h-full rounded-full bg-gray-300' />
      </span>
      <span
        className='relative block w-2.5 h-2.5 mx-1 bg-gray-800 rounded-full'
        aria-hidden='true'
      />
      <span className='sr-only'>{step.name}</span>
    </a>
  )
}

const StepComplete = ({ step }) => {
  return (
    <>
      <a
        href={step.href}
        className='block w-2.5 h-2.5 mx-1 bg-gray-800 rounded-full hover:bg-gray-900'
      >
        <span className='sr-only'>{step.name}</span>
      </a>
    </>
  )
}

interface Props {
  brand: Brand
  stepCurrent: BrandOnboardingStep
}

const Bullets = ({ brand, stepCurrent }: Props) => {
  const [indexCurrent, setIndexCurrent] = useState(null)

  return (
    <div className='hidden lg:block'>
      <nav
        className='flex items-center justify-center mb-12 mt-6'
        aria-label='Progress'
      >
        <ol className='ml-8 flex items-center space-x-3'>
          {steps.map((step, stepIdx) => (
            <li key={step.name}>
              {step.stepOnboarding.includes(stepCurrent) ? (
                <>
                  {indexCurrent === null ? setIndexCurrent(stepIdx) : null}
                  <StepCurrent step={step} />
                </>
              ) : stepIdx < indexCurrent ? (
                <>
                  <StepComplete step={step} />
                </>
              ) : (
                <StepUpcoming step={step} />
              )}
            </li>
          ))}
        </ol>
      </nav>
    </div>
  )
}

export { Bullets }
