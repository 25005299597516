import { Icon, IconType } from '../../../../../components'
import { Product } from '../../../../../gql/types'
import { forwardRef, useState } from 'react'
import { Combobox } from '@headlessui/react'
import classNames from 'classnames'
import { RefCallBack } from 'react-hook-form'
import { Avatar } from '../../../../../elements'

interface IDefaultProps {
  error?: string
}

interface IProps extends Partial<IDefaultProps> {
  options: any
  field: any
  ref: RefCallBack
}

const defaultProps: IDefaultProps = {
  error: null,
}

const DropdownSearch = forwardRef<HTMLDivElement, IProps>((props, ref) => {
  const { options, field, error, ...rest } = props
  const { onChange, name, value } = field

  const [query, setQuery] = useState('')

  const filteredProducts =
    query === ''
      ? options
      : options.filter((product) => {
          return product.name.toLowerCase().includes(query.toLowerCase())
        })

  return (
    <div>
      <Combobox as='div' value={value} onChange={onChange} name={name}>
        <Combobox.Label className='block text-sm font-medium text-gray-700'>
          Product
        </Combobox.Label>
        <div className='relative mt-2'>
          <Combobox.Button as='div' className='w-full'>
            <Combobox.Input
              className='cursor-pointer w-full px-3 py-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset placeholder-gray-500 focus:outline-none focus:ring-gray-800 focus:border-gray-800 text-sm'
              // onChange={(event) => setQuery(event.target.value)}
              displayValue={(selectedProduct: Product) => selectedProduct?.name}
              placeholder='Search for a product'
              onChange={onChange}
              ref={ref}
              {...rest}
            />
            <div className='cursor-pointer absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none'>
              <Icon
                type={IconType.chevronUpDown}
                className='h-5 w-5 text-gray-400'
                aria-hidden='true'
              />
            </div>
          </Combobox.Button>

          {filteredProducts.length > 0 && (
            <Combobox.Options className='absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
              {filteredProducts.map((product) => (
                <Combobox.Option
                  key={product.id}
                  value={product}
                  className={({ active }) =>
                    classNames(
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                      active ? 'bg-gray-600 text-white' : 'text-gray-900',
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      <div className='flex items-center'>
                        <Avatar
                          type='product'
                          src={product?.photo?.url}
                          className='flex-shrink-0'
                          size='xxs'
                          alt=''
                        />
                        <span
                          className={classNames(
                            'ml-3 truncate',
                            selected && 'font-semibold',
                          )}
                        >
                          {product.name}
                        </span>
                      </div>

                      {selected && (
                        <span
                          className={classNames(
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-white' : 'text-gray-600',
                          )}
                        >
                          <Icon
                            type={IconType.check}
                            className='h-5 w-5'
                            aria-hidden='true'
                          />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>
      {error && (
        <div className='mt-1 relative flex'>
          <div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
            <Icon
              type={IconType.exclamationCircleFill}
              className='h-5 w-5 text-red-500'
              aria-hidden='true'
            />
          </div>
          <p className='mt-2 text-sm text-red-600'>{error}</p>
        </div>
      )}
    </div>
  )
})

DropdownSearch.displayName = 'DropdownSearch'
DropdownSearch.defaultProps = defaultProps

export { DropdownSearch }
