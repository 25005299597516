import { CountryCode, Maybe } from '../gql/types'

export const userFullname = ({
  firstName,
  lastName,
}: {
  firstName?: Maybe<string> | string
  lastName?: Maybe<string> | string
}) => {
  const nameParts = [firstName, lastName].filter(Boolean)
  return nameParts.join(' ')
}

export const buildFullAddress = ({
  addressLine1,
  addressLine2,
  addressCity,
  addressCounty,
  addressState,
  addressPostcode,
  countryCode,
}: {
  addressLine1?: Maybe<string> | string
  addressLine2?: Maybe<string> | string
  addressCity?: Maybe<string> | string
  addressCounty?: Maybe<string> | string
  addressState?: Maybe<string> | string
  addressPostcode?: Maybe<string> | string
  countryCode?: Maybe<CountryCode> | CountryCode
}) => {
  const regionNames = new Intl.DisplayNames(['en'], { type: 'region' })
  const countryName = countryCode ? regionNames.of(countryCode) : null

  const addressParts = [
    addressLine1,
    addressLine2,
    addressCity,
    addressCounty,
    addressState,
    addressPostcode,
    countryName,
  ].filter(Boolean)
  return addressParts.join(', ')
}
