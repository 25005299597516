import { useMutation } from '@apollo/client'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import {
  NotificationType,
  TextField,
  Button,
  ButtonColor,
} from '../../../../../components'
import Multiselect from '../../../../../elements/Multiselect'
import { INVITE_TEAM_MEMBER, GET_TEAM_INVITATIONS } from '../../../../../gql'
import {
  Brand,
  UserRole,
  GetTeamInvitationsSort,
} from '../../../../../gql/types'
import { ErrorCode } from '../../../../../utils/ErrorCode'
import { SKIP_DEFAULT, LIMIT_DEFAULT } from '../../../../../utils/constants'
import { findErrorMethodName } from '../../../../../utils/helpers'
import { useNotification } from '../../../../../utils/hooks'
import { BrandRoutes } from '../../../../BrandRoutes'

interface IProps {
  brand: Brand
  isOpen: boolean
  setIsOpen: (event: any) => void
}

export const mapRoleToText = (role: UserRole): string => {
  switch (role) {
    case UserRole.owner:
      return `Owner`
    case UserRole.advertiser:
      return `Advertiser`
  }
}

const InviteMemberModal = ({ brand, isOpen, setIsOpen }: IProps) => {
  const { addNotification } = useNotification()
  const navigate = useNavigate()
  const location = useLocation()
  const { id: brandId } = useParams()

  const [selectedRoles, setSelectedRoles] = useState([UserRole.advertiser])

  const [inviteTeamMember, { loading: inviteTeamMemberLoading }] = useMutation(
    INVITE_TEAM_MEMBER,
    {
      refetchQueries: [
        {
          query: GET_TEAM_INVITATIONS,
          variables: {
            options: {
              query: null,
              filters: {
                brandId: brand?.id,
              },
              sort: GetTeamInvitationsSort.newest,
              skip: SKIP_DEFAULT,
              limit: LIMIT_DEFAULT,
            },
          },
        },
      ],
    },
  )

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
  })

  const closeModal = () => {
    setIsOpen(false)
  }

  const onSubmit = async (data) => {
    try {
      await inviteTeamMember({
        variables: {
          brandId: brand.id,
          input: {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            roles: selectedRoles,
          },
        },
      })

      addNotification(`Invite sent.`, NotificationType.success)
      reset()
      closeModal()
      setSelectedRoles([UserRole.owner])
      if (
        location.pathname !== BrandRoutes.invitations.replace(':id', brandId)
      ) {
        navigate(BrandRoutes.invitations.replace(':id', brandId))
      }
    } catch (error) {
      if (findErrorMethodName(error, ErrorCode.emailAlreadyTaken)) {
        setError('email', {
          type: 'custom',
          message:
            'User with this email address is already team member of this brand.',
        })
      }
      if (findErrorMethodName(error, ErrorCode.userAlreadyInvited)) {
        setError('email', {
          type: 'custom',
          message:
            'User with this email address is already invited to this brand.',
        })
      }

      addNotification(
        error.message
          ? error.message
          : `Something went wrong. Please try again.`,
        NotificationType.error,
      )
    }
  }

  const optionToMultiselectOption = (option) => {
    return {
      label: mapRoleToText(option),
      value: option,
    }
  }

  const multiselectOptionToOption = (multiselectOption) => {
    return Object.keys(UserRole).find(
      (option) => option === multiselectOption.value,
    )
  }

  const setSelectedRolesMultiselect = (multiselectOptions) => {
    if (multiselectOptions.length < 1) {
      addNotification('Please set at least 1 role.', NotificationType.error)
      return
    }

    const newSelectedRoles = []
    multiselectOptions.forEach((multiselectOption) => {
      newSelectedRoles.push(multiselectOptionToOption(multiselectOption))
    })
    return setSelectedRoles(newSelectedRoles)
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                  <Dialog.Title
                    as='h3'
                    className='text-lg font-medium leading-6 text-gray-900'
                  >
                    Invite team member
                  </Dialog.Title>
                  <p className='mt-2 text-base text-gray-500'>
                    We're thrilled to see your team expanding!
                  </p>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='mt-4'>
                      <TextField
                        placeholder={`Example: Jane`}
                        label='First name'
                        name='firstName'
                        error={errors.firstName?.message}
                        {...register('firstName', {
                          required: `Please set an first name.`,
                          maxLength: {
                            value: 100,
                            message: `Your first cannot have more than 100 letters.`,
                          },
                        })}
                      />
                    </div>

                    <div className='mt-4'>
                      <TextField
                        placeholder={`Example: Cat`}
                        label='Last name'
                        name='lastName'
                        error={errors.lastName?.message}
                        {...register('lastName', {
                          required: `Please set a last name.`,
                          maxLength: {
                            value: 100,
                            message: `Your first cannot have more than 100 letters.`,
                          },
                        })}
                      />
                    </div>

                    <div className='mt-4'>
                      <TextField
                        label='Email address'
                        name='email'
                        error={errors.email?.message}
                        {...register('email', {
                          required: `Please set an email address.`,
                          pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: `Please use a real email address.`,
                          },
                          maxLength: {
                            value: 100,
                            message: `Please use a real email address.`,
                          },
                        })}
                      />
                    </div>

                    <div className='mt-4'>
                      <Multiselect
                        // label='Roles'
                        // id='roles'
                        options={Object.keys(UserRole).map((option) =>
                          optionToMultiselectOption(option),
                        )}
                        onChange={setSelectedRolesMultiselect}
                        selected={selectedRoles.map((option) =>
                          optionToMultiselectOption(option),
                        )}
                        // isSearchable={false}
                      />
                    </div>

                    <p className='text-sm text-gray-400 mt-4'>
                      The invitation is valid for 5 days, after which it will
                      expire.
                    </p>

                    <div className='mt-4 flex items-center justify-between'>
                      <div className='py-2'>
                        <Button
                          type='button'
                          title='Cancel'
                          colour={ButtonColor.white}
                          onClick={closeModal}
                        />
                      </div>

                      <Button title='Send' loading={inviteTeamMemberLoading} />
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export { InviteMemberModal }
