import { useMutation } from '@apollo/client'
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
// import { useNotification } from '../../common/hooks'
import { Loader, NotificationType } from '../../components'
import {
  CONFIRM_PAYMENT,
  MutationConfirmPaymentResponse,
  UPDATE_BRAND,
} from '../../gql'
import {
  BrandOnboardingStep,
  MutationConfirmPaymentArgs,
  User,
} from '../../gql/types'
import { trackFirstPurchase, trackPurchase } from '../../utils/analytics'
import { BrandRoutes } from '../BrandRoutes'
import { useNotification } from '../../utils/hooks'

interface IProps {
  user: User
}

const ConfirmPayment = ({ user }: IProps) => {
  const navigate = useNavigate()
  const { addNotification } = useNotification()

  const [confirmPayment] = useMutation<
    MutationConfirmPaymentResponse,
    MutationConfirmPaymentArgs
  >(CONFIRM_PAYMENT)

  const [updateBrand] = useMutation(UPDATE_BRAND)

  const [searchParams] = useSearchParams()
  const sessionId = searchParams.get('session_id')
  const redirectUrl: string = searchParams.get('redirectUrl')

  useEffect(() => {
    const confirmPaymentSuccess = async () => {
      try {
        const { data } = await confirmPayment({
          variables: {
            stripeSessionId: sessionId,
          },
        })

        const brand = data.confirmPayment.brand

        // track all purchases and value to be able to optimize for higher value clients
        trackPurchase({
          transactionId: data.confirmPayment.transactionId,
          currency: data.confirmPayment.currency,
          priceCents: data.confirmPayment.priceCents,
        })

        // if brand didn't complete onboarding, this is the first purchase, track it
        if (brand.onboarding !== BrandOnboardingStep.completed) {
          trackFirstPurchase({
            transactionId: data.confirmPayment.transactionId,
            currency: data.confirmPayment.currency,
            priceCents: data.confirmPayment.priceCents,
          })

          await updateBrand({
            variables: {
              id: brand.id,
              input: {
                onboarding: BrandOnboardingStep.completed,
              },
            },
          })
        }

        navigate(
          redirectUrl ?? BrandRoutes.campaigns.replace(':id', `${brand.id}`),
        )

        addNotification(
          'Your Clip order has been confirmed.',
          NotificationType.success,
        )
      } catch (error) {
        navigate(BrandRoutes.onboarding)
        addNotification(error.message, NotificationType.error)
      }
    }

    if (sessionId) {
      confirmPaymentSuccess()
    }

    if (!sessionId) {
      navigate(BrandRoutes.onboarding)
      addNotification(
        `Invalid url. Looks like you have landed on the wrong page.`,
        NotificationType.error,
      )
    }
  }, [confirmPayment, sessionId, updateBrand])

  return <Loader />
}

export { ConfirmPayment }
