import classNames from 'classnames'
import moment from 'moment-timezone'
import { Avatar } from '../../elements'
import { StarRating } from '../StarRating'
import classnames from 'classnames'
import { Pagination } from '../../gql/types'
import { Pagination as PaginationComponent } from '../Pagination'

interface IProps {
  title?: string
  subtitle?: string
  ratings: {
    id: string
    name: string
    profilePicUrl: string
    avatarType?: 'user' | 'brand' | 'product'
    url?: string
    stars: number
    review?: string
    createdAt: number
  }[]
  className?: string

  pagination?: Pagination
  setPage?(page: number): void
}

const ListReviews = ({
  title,
  subtitle,
  ratings,
  className,
  pagination,
  setPage,
}: IProps) => {
  return (
    <div
      className={classNames(
        'bg-white shadow overflow-hidden rounded-lg px-5 py-4',
        className,
      )}
    >
      {title && (
        <div className='pb-6'>
          <h3 className='text-lg leading-6 font-medium text-gray-900'>
            {title}
          </h3>
          {subtitle && <p className='mt-1 text-sm text-gray-500'>{subtitle}</p>}
        </div>
      )}
      {(!ratings || ratings.length === 0) && (
        <p className='text-sm'>No reviews available.</p>
      )}
      {ratings &&
        ratings.map((rating, index) => (
          <div
            key={rating.id}
            className={classnames(
              'flex text-sm text-gray-500 space-x-4',
              index > 0 ? 'mt-6' : null,
              index === 0 ? '' : 'border-t border-gray-200 pt-6',
            )}
          >
            <div className='flex-none'>
              <Avatar
                type={rating.avatarType}
                src={rating.profilePicUrl}
                size='sm'
                alt={rating.name}
              />
            </div>
            <div>
              <div className='font-medium text-gray-900'>
                <a href={rating.url} target='_blank' rel='noopener noreferrer'>
                  {rating.name}
                </a>
              </div>
              <div>
                <time dateTime={moment(rating.createdAt).format('MM/DD/YYYY')}>
                  {moment(rating.createdAt).format('MMMM DD, YYYY')}
                </time>
              </div>

              <div className='flex items-center mt-2'>
                <StarRating ratingScore={rating.stars} />
              </div>
              <div className='sr-only'>{rating.stars} out of 5 stars</div>

              {rating.review && (
                <div className='mt-2 prose prose-sm max-w-none text-gray-500'>
                  {rating.review}
                </div>
              )}
            </div>
          </div>
        ))}
      {pagination && (
        <PaginationComponent
          pagination={pagination}
          setPage={setPage}
          className='mt-4'
        />
      )}
    </div>
  )
}

export { ListReviews }
