import { useMutation } from '@apollo/client'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  Button,
  ButtonColor,
  CheckboxGroup,
  CheckboxType,
  NotificationType,
  RadioGroup,
  TextArea,
} from '../../../components'
import { Select } from '../../../components/Select'
import {
  MutationUpdateCampaignResponse,
  UPDATE_BRAND,
  UPDATE_CAMPAIGN,
} from '../../../gql'
import {
  AgeGroups,
  Brand,
  BrandOnboardingStep,
  Campaign,
  CountryCode,
  CreatorQuality,
  Ethnicity,
  Gender,
  MutationUpdateCampaignArgs,
} from '../../../gql/types'
import {
  AnalyticsEvent,
  trackAddToCart,
  trackEvent,
} from '../../../utils/analytics'
import {
  ethnicityLocalizedString,
  ethnicityLocalizedStringInverse,
  genderLocalizedString,
  genderLocalizedStringInverse,
} from '../../../utils/GqlStrings'
import {
  ageGroupsOptions,
  countryOptions,
  mapFlagAndTextToCountryCode,
  qualityOptions,
} from '../../../utils/mappers'
import { Bullets } from '../components/Bullets'
import { Steps } from '../components/Steps'
import { useNotification } from '../../../utils/hooks'
import { usePricing } from '../../../hooks'
import { capitalize } from '../../../utils/helpers'
import {
  formatPriceInCents,
  getPriceByQuality,
  getVideoPricing,
} from '../../../utils/PricingUtil'

export type EditCreatorDetailsFormData = {
  modelGender: string
  creatorPreferences: string
  ageGroups: (AgeGroups | 'Any')[]
  ethnicity: string
  countryCode: CountryCode
  quality: CreatorQuality
}

interface Props {
  brand: Brand
  campaign: Campaign
}

const SetCreatorDetails = ({ brand, campaign }: Props) => {
  const { addNotification } = useNotification()
  const { pricing } = usePricing()

  const [updateBrand, { loading: loadingUpdateBrand }] =
    useMutation(UPDATE_BRAND)
  const [updateCampaign, { loading: loadingUpdateCampaign }] = useMutation<
    MutationUpdateCampaignResponse,
    MutationUpdateCampaignArgs
  >(UPDATE_CAMPAIGN)

  const {
    register,
    handleSubmit,
    control,
    setError,
    watch,
    setValue,
    formState: { errors },
  } = useForm<EditCreatorDetailsFormData>({
    defaultValues: {
      modelGender: campaign.modelGender
        ? capitalize(campaign.modelGender)
        : 'Any',
      ethnicity: campaign.ethnicity ? capitalize(campaign.ethnicity) : 'Any',
      ageGroups: campaign.ageGroups ?? ['Any'],
      creatorPreferences: campaign.creatorPreferences,
      countryCode: campaign.countryCode ?? brand.countryCode ?? null,
      quality: campaign.quality ?? CreatorQuality.regular,
    },
  })

  useEffect(() => {
    trackEvent(AnalyticsEvent.viewBrandsOnboardingSetCreatorDetails)
  }, [])

  const goBack = async () => {
    try {
      await updateBrand({
        variables: {
          id: brand.id,
          input: {
            onboarding: BrandOnboardingStep.setHooks,
          },
        },
      })
    } catch (error) {
      addNotification(error.message, NotificationType.error)
    }
  }

  const onSubmit = async (data: EditCreatorDetailsFormData) => {
    try {
      trackAddToCart()
    } catch (error) {
      console.log('Error tracking add to cart', error.message)
    }

    let ageGroups = null
    if (!data.ageGroups.find((el) => el === 'Any')) {
      ageGroups = data.ageGroups
    }

    try {
      await updateCampaign({
        variables: {
          id: campaign.id,
          input: {
            modelGender: genderLocalizedStringInverse(data.modelGender),
            creatorPreferences: data.creatorPreferences,
            ageGroups: ageGroups,
            ethnicity: ethnicityLocalizedStringInverse(data.ethnicity),
            countryCode: mapFlagAndTextToCountryCode(data.countryCode),
            quality: data.quality,
          },
        },
      })

      await updateBrand({
        variables: {
          id: brand.id,
          input: {
            onboarding: brand.creditCents
              ? BrandOnboardingStep.completed
              : BrandOnboardingStep.setOrderSummary,
          },
        },
      })
    } catch (error) {
      console.log('@error SetCreatorDetails', error.message)
      addNotification(error.message, NotificationType.error)
    }
  }

  const videoPricing = getVideoPricing({
    currency: campaign.currency,
    videoDuration: campaign.videoDuration,
    pricing,
  })
  const extraPriceCents = getPriceByQuality(
    CreatorQuality.premium,
    videoPricing.price,
  ).extraPriceCents

  return (
    <div className='min-h-screen flex flex-col pb-14'>
      <div className='flex-grow'>
        <Steps
          brand={brand}
          stepCurrent={BrandOnboardingStep.setCreatorDetails}
        />

        <div className='min-h-full flex flex-col justify-center pt-4 px-4 md:mt-8 lg:px-8'>
          <div className='sm:mx-auto sm:w-full sm:max-w-2xl'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='md:shadow rounded-t-md sm:overflow-hidden'>
                <div className='py-5 md:bg-white space-y-6 sm:p-6'>
                  <div className='sm:mx-auto'>
                    <h2 className='mt-0 md:text-center text-3xl font-extrabold text-gray-900'>
                      Creator details
                    </h2>
                    <p className='mt-2 md:text-center text-sm text-gray-600'>
                      Let’s create your first brief!
                    </p>
                  </div>

                  <div className='grid gap-6'>
                    <div className='col-span-3 sm:col-span-2'>
                      <Controller
                        name='quality'
                        control={control}
                        rules={{ required: `Tell us what is creator quality.` }}
                        render={({ field }) => (
                          <RadioGroup
                            options={qualityOptions(
                              formatPriceInCents({
                                priceInCents: extraPriceCents,
                                currency: campaign.currency,
                              }),
                            )}
                            field={field}
                            error={errors?.quality?.message}
                            className='grid-cols-1 sm:grid-cols-2'
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className='grid gap-6'>
                    <div className='col-span-3 sm:col-span-2'>
                      <Controller
                        name='modelGender'
                        control={control}
                        rules={{ required: `Please set a model gender.` }}
                        render={({ field }) => (
                          <Select
                            options={[
                              'Any',
                              genderLocalizedString(Gender.female),
                              genderLocalizedString(Gender.male),
                            ]}
                            field={field}
                            label='Model Gender'
                            error={errors?.modelGender?.message}
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className='grid gap-6'>
                    <div className='col-span-3 sm:col-span-2'>
                      <Controller
                        name='ethnicity'
                        control={control}
                        rules={{ required: `Please set an ethnicity.` }}
                        render={({ field }) => (
                          <Select
                            options={[
                              'Any',
                              ...Object.values(Ethnicity)
                                .filter(
                                  (ethnicity) => ethnicity !== Ethnicity.other,
                                )
                                .map((ethnicity) =>
                                  ethnicityLocalizedString(ethnicity),
                                ),
                            ]}
                            field={field}
                            label='Ethnicity'
                            error={errors?.ethnicity?.message}
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className='grid gap-6'>
                    <div className='col-span-3 sm:col-span-2'>
                      <CheckboxGroup
                        selected={watch('ageGroups')}
                        {...register('ageGroups', {
                          required: 'Please select at least an age group.',
                        })}
                        className='-mt-2 -mb-4 sm:flex sm:space-y-0 sm:items-center sm:space-x-4'
                        label='Age Groups'
                        error={errors?.ageGroups?.message}
                      >
                        {[
                          {
                            name: 'Any',
                            type: 'Any',
                            description: '',
                          },
                          ...ageGroupsOptions,
                        ].map(({ name, type }, index) => {
                          return (
                            <CheckboxGroup.CheckboxItem
                              type={CheckboxType.checkbox}
                              key={index}
                              value={type}
                              title={name}
                              {...register('ageGroups')}
                              className='-ml-3'
                            />
                          )
                        })}
                      </CheckboxGroup>
                    </div>
                  </div>

                  <div className='grid gap-6'>
                    <div className='col-span-3 sm:col-span-2'>
                      <Controller
                        name='countryCode'
                        control={control}
                        rules={{ required: `Please set a country.` }}
                        render={({ field }) => (
                          <Select
                            options={countryOptions}
                            field={field}
                            label='Country/Region'
                            error={errors?.countryCode?.message}
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className='grid gap-6'>
                    <div className='col-span-3 sm:col-span-2'>
                      <TextArea
                        error={errors.creatorPreferences?.message}
                        label='Other preferences'
                        placeholder='Looking for a specific type of creator? (e.g. creators with pets)'
                        {...register('creatorPreferences', {
                          maxLength: {
                            value: 10000,
                            message: `Your description text is too long.`,
                          },
                        })}
                      />
                      <p className='mt-2 text-left text-sm text-gray-600'>
                        Creators will see this before they apply to your
                        campaign.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className='flex justify-between md:shadow rounded-b-md py-3 md:bg-gray-50 sm:px-6'>
                <Button
                  title='Go back'
                  type='button'
                  colour={ButtonColor.white}
                  loading={loadingUpdateBrand}
                  disabled={loadingUpdateBrand}
                  onClick={goBack}
                />
                <Button
                  title='Continue'
                  type='submit'
                  loading={loadingUpdateBrand || loadingUpdateCampaign}
                  disabled={loadingUpdateBrand || loadingUpdateCampaign}
                />
              </div>
            </form>
          </div>
        </div>
      </div>

      <Bullets
        brand={brand}
        stepCurrent={BrandOnboardingStep.setCreatorDetails}
      />
    </div>
  )
}

export { SetCreatorDetails }
