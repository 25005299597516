// eslint-disable-next-line
export default {
  API_URL: 'https://api.useclip.com',
  CLIENT_ID: '',
  CLIENT_VERSION: '',
  MIXPANEL_KEY: '',
  STRIPE_KEY:
    'pk_live_51Mb8TlFtRUv2zngbIFR98k4YiXyQ7waxki9HtdJjM1qwmEKHBo8uD3d0ajXUmxjIX1XfYp2cZV5c0LWDKUPVxhVm00Vd418Loc',
  ENABLE_BLACK_FRIDAY_DEAL: false,
  ENABLE_CHRISTMAS_DEAL: true,
}
