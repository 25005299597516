import { Controller, useFieldArray, useForm } from 'react-hook-form'
import {
  Button,
  ButtonColor,
  NotificationType,
  RadioGroup,
} from '../../../components'
import {
  Brand,
  BrandOnboardingStep,
  Campaign,
  CampaignAddon,
} from '../../../gql/types'
import { PricingData, getVideoPricing } from '../../../utils/PricingUtil'
import { addonsOptions } from '../../../utils/mappers'
import { AnalyticsEvent, trackEvent } from '../../../utils/analytics'
import { isEmptyString, useNotification } from '../../../utils/hooks'
import { useMutation } from '@apollo/client'
import { UPDATE_BRAND, UPDATE_CAMPAIGN } from '../../../gql'
import { useEffect } from 'react'
import { Steps } from '../components/Steps'
import { Bullets } from '../components/Bullets'

type EditAddonsFormData = {
  addons: CampaignAddon
}

interface Props {
  brand: Brand
  campaign: Campaign
  pricing: PricingData
}

const SetAddons = ({ brand, campaign, pricing }: Props) => {
  const { addNotification } = useNotification()

  const [updateBrand, { loading: loadingUpdateBrand }] =
    useMutation(UPDATE_BRAND)
  const [updateCampaign, { loading: loadingUpdateCampaign }] =
    useMutation(UPDATE_CAMPAIGN)

  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors },
    setValue,
    watch,
  } = useForm<EditAddonsFormData>({
    defaultValues: {
      addons: campaign.addons?.[0] || null,
    },
  })

  useEffect(() => {
    trackEvent(AnalyticsEvent.viewBrandsOnboardingSetAddons)
  }, [])

  const goBack = async () => {
    try {
      await updateBrand({
        variables: {
          id: brand.id,
          input: {
            onboarding: BrandOnboardingStep.setRequirements,
          },
        },
      })
    } catch (error) {
      addNotification(error.message, NotificationType.error)
    }
  }

  const onSubmit = async ({ addons }: EditAddonsFormData) => {
    try {
      await Promise.all([
        updateCampaign({
          variables: {
            id: campaign.id,
            input: {
              addons: addons ? [addons] : [],
            },
          },
        }),
        updateBrand({
          variables: {
            id: brand.id,
            input: {
              onboarding: BrandOnboardingStep.setHooks,
            },
          },
        }),
      ])
    } catch (error) {
      console.log('@error SetAddons', error.message)
      if (error.message) {
        setError('addons', { type: 'custom', message: error.message })
        return
      }
    }
  }

  const videoPricing = getVideoPricing({
    currency: brand.currency,
    videoDuration: campaign.videoDuration,
    pricing,
  })

  return (
    <div className='min-h-screen flex flex-col pb-14'>
      <div className='flex-grow'>
        <Steps brand={brand} stepCurrent={BrandOnboardingStep.setAddons} />

        <div className='min-h-full flex flex-col justify-center pt-4 px-4 md:mt-8 lg:px-8'>
          <div className='sm:mx-auto sm:w-full sm:max-w-2xl'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='md:shadow rounded-t-md sm:overflow-hidden'>
                <div className='py-5 md:bg-white space-y-6 sm:p-6'>
                  <div className='sm:mx-auto'>
                    <h2 className='mt-0 md:text-center text-3xl font-extrabold text-gray-900'>
                      Addons
                    </h2>
                    <p className='mt-2 md:text-center text-sm text-gray-600'>
                      Both the original and edited footage will be accessible
                      for download.
                    </p>
                  </div>

                  <div className='grid gap-6'>
                    <div className='col-span-3 sm:col-span-2'>
                      <Controller
                        name='addons'
                        control={control}
                        render={({ field }) => (
                          <RadioGroup
                            options={addonsOptions(
                              videoPricing.addons,
                              brand.currency,
                            )}
                            field={field}
                            error={errors?.addons?.message}
                            allowDeselect={true}
                            deselectTitle={`Remove Addon`}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='flex justify-between md:shadow rounded-b-md py-3 md:bg-gray-50 sm:px-6'>
                <Button
                  title='Go back'
                  type='button'
                  colour={ButtonColor.white}
                  loading={loadingUpdateBrand}
                  disabled={loadingUpdateBrand}
                  onClick={goBack}
                />
                <Button
                  title='Continue'
                  type='submit'
                  loading={loadingUpdateBrand || loadingUpdateCampaign}
                  disabled={loadingUpdateBrand || loadingUpdateCampaign}
                />
              </div>
            </form>
          </div>
        </div>
      </div>

      <Bullets brand={brand} stepCurrent={BrandOnboardingStep.setAddons} />
    </div>
  )
}

export { SetAddons }
