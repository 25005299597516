import classnames from 'classnames'
import { Invoice } from '../../../../gql/types'
import {
  Badge,
  BadgeSize,
  BadgeType,
  Icon,
  IconType,
} from '../../../../components'
import { mapCurrencyToSign } from '../../../../utils/mappers'
import moment from 'moment-timezone'
import { Link } from 'react-router-dom'

interface IDefaultProps {}

interface IProps extends Partial<IDefaultProps> {
  invoice: Invoice
  index: number
}

const ListItem = ({ invoice, index }: IProps) => {
  return (
    <tr
      key={invoice.id}
      className={classnames(index % 2 === 0 ? 'bg-gray-50' : 'bg-white')}
    >
      <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
        <div className='flex gap-2'>
          {moment.unix(invoice.paidAt).format('ll')}
          <a href={invoice.url} target='_blank' rel='noopener noreferrer'>
            <div className='flex gap-2'>
              <Icon
                type={IconType.externalLink}
                className='h-5 w-5 text-gray-400'
                aria-hidden='true'
              />
            </div>
          </a>
        </div>
      </td>

      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        <div className='flex gap-2'>
          {mapCurrencyToSign(invoice.currency)} {invoice.paidTotal}
        </div>
      </td>

      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        <Badge
          type={invoice.status === 'paid' ? BadgeType.success : BadgeType.gray}
          text={invoice.status}
          size={BadgeSize.small}
        />
      </td>

      <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-sm text-gray-500 sm:pr-6'>
        <Link to={invoice.pdf}>
          <div className='flex gap-2'>
            Download invoice
            <Icon
              type={IconType.download}
              className='h-5 w-5 text-gray-400'
              aria-hidden='true'
            />
          </div>
        </Link>
      </td>
    </tr>
  )
}

export { ListItem }
