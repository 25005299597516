import React from 'react'
import { Avatar } from '../../elements'
import { ImageFileInput } from '..'

interface IProps {
  title?: string
  avatarUrl?: string
  onChange: (file: File) => void
}

const AvatarUpdate: React.FC<IProps> = ({ title, avatarUrl, onChange }) => {
  const [url, setUrl] = React.useState<string | undefined>(avatarUrl)

  const onFileChange = (file: File) => {
    setUrl(URL.createObjectURL(file))
    onChange(file)
  }

  return (
    <div>
      <p className='text-sm font-medium text-gray-700' aria-hidden='true'>
        {title ?? 'Photo'}
      </p>
      <div className='flex items-center mt-1'>
        <div aria-hidden='true'>
          <Avatar type='user' size='md' src={url} alt='' />
        </div>
        <div className='relative ml-5'>
          <ImageFileInput
            name='user-photo'
            onChange={onFileChange}
            className='peer absolute h-full w-full rounded-md opacity-0'
          />
          <label className='pointer-events-none block rounded-md py-2 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 peer-hover:ring-gray-400 peer-focus:ring-2 peer-focus:ring-sky-500'>
            <span>Change</span>
            <span className='sr-only'>user photo</span>
          </label>
        </div>
      </div>
    </div>
  )
}

AvatarUpdate.displayName = 'AvatarUpdate'

export { AvatarUpdate }
