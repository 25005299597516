import React, { useEffect } from 'react'

import { useLazyQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import { BrandRoutes } from '../../brands/BrandRoutes'
import { CollaborationStatusTime } from '../../brands/CampaignView/components/CollaborationStatusTime'
import { Avatar } from '../../elements'
import {
  GET_COLLABORATIONS_BY_BRAND_CREATOR,
  GetCollaborationsByBrandAndCreator,
} from '../../gql'
import {
  Brand,
  CollaborationStatus,
  Creator,
  GetCollaborationsSort,
  QueryGetCollaborationsByBrandAndCreatorArgs,
} from '../../gql/types'
import {
  modelTypeLocalizedString,
  videoDurationLocalizedString,
  videoTypeLocalizedString,
} from '../../utils/GqlStrings'
import {
  PricingData,
  formattedVideoCreatorEarnings,
} from '../../utils/PricingUtil'
import { mapCurrencyToSign } from '../../utils/mappers'
import { Badge, BadgeSize, BadgeType } from '../Badge'
import { CollaborationStatus as CollaborationStatusComponent } from '../CollaborationStatus'
import { Divider } from '../Divider'
import { Icon, IconType } from '../Icon'
import { Loader } from '../Loader'
import { Slider, SliderProps } from '../Slider'
import { CreatorRoutes } from '../../creators/CreatorRoutes'
import { isCampaignPremium } from '../../utils/hooks'

interface IProps extends Omit<SliderProps, 'children'> {
  creator: Creator
  brand: Brand
  pricing: PricingData
  type: 'brand' | 'creator'
}

export const ViewCollaborationsSlider: React.FC<IProps> = ({
  setOpen,
  open,
  creator,
  brand,
  pricing,
  type,
}) => {
  const limit = 30
  const skip = 0

  const [fetch, { loading, error, data }] = useLazyQuery<
    GetCollaborationsByBrandAndCreator,
    QueryGetCollaborationsByBrandAndCreatorArgs
  >(GET_COLLABORATIONS_BY_BRAND_CREATOR, {
    variables: {
      creatorId: creator.id,
      brandId: brand.id,
      options: {
        query: '',
        filters: {
          status: [
            CollaborationStatus.creatorAccepted,
            CollaborationStatus.productShipped,
            CollaborationStatus.productDelivered,
            CollaborationStatus.contentUploaded,
            CollaborationStatus.contentAccepted,
          ],
        },
        sort: GetCollaborationsSort.lastUpdated,
        limit,
        skip,
      },
    },
  })

  const getCreatorEarningText = (campaign) => {
    return formattedVideoCreatorEarnings({
      quality: campaign.quality,
      currency: campaign?.currency,
      videoDuration: campaign?.videoDuration,
      pricing: pricing,
    })
  }

  useEffect(() => {
    if (open) {
      fetch()
    }
  }, [fetch, open])

  return (
    <Slider setOpen={setOpen} open={open}>
      <div className='flex h-full flex-col overflow-y-scroll bg-white shadow-xl'>
        {/* Header */}

        <div className='sm:sticky top-0 z-10 bg-white'>
          <div className='px-4 pt-6 sm:px-6'>
            <div className='flex items-start justify-between'>
              <h3 className='text-xl font-bold text-gray-900'>Campaigns</h3>
              <button
                type='button'
                className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none'
                onClick={() => setOpen(false)}
              >
                <span className='sr-only'>Close panel</span>
                <Icon
                  type={IconType.x}
                  className='h-6 w-6'
                  aria-hidden='true'
                />
              </button>
            </div>
          </div>
          <Divider className='py-6' />
        </div>

        {/* Main */}
        <div className='pb-1 sm:pb-6 px-4 sm:px-6'>
          {loading && <Loader />}
          {error && <p>There has been an error. Try refreshing the page</p>}
          {data?.getCollaborationsByBrandAndCreator && (
            <div className='@container max-w-7xl'>
              <ul className='grid grid-cols-1 gap-x-6 gap-y-3 @md:grid-cols-3 @lg:grid-cols-4 divide-y divide-gray-200'>
                {data?.getCollaborationsByBrandAndCreator?.items.length ===
                  0 && <p>No campaigns active.</p>}
                {data?.getCollaborationsByBrandAndCreator?.items.map(
                  (collaboration) => (
                    <div key={collaboration.id} className='group'>
                      <dl>
                        <div className='py-5 grid grid-cols-2 gap-y-6 gap-x-4'>
                          {type === 'brand' && (
                            <div>
                              <dt className='text-sm font-medium text-gray-500'>
                                Campaign
                              </dt>
                              <dd className='mt-1 text-sm text-gray-900 @md:col-span-2 @md:mt-0'>
                                <Link
                                  to={BrandRoutes.campaignView
                                    .replace(':id', `${brand.id}`)
                                    .replace(
                                      ':campaignId',
                                      `${collaboration?.campaign.id}`,
                                    )}
                                  className='mr-1 text-sm font-semibold text-blue-600 hover:text-blue-500'
                                >
                                  <div className='flex items-center'>
                                    {collaboration?.campaign?.title}
                                  </div>
                                </Link>
                              </dd>
                            </div>
                          )}

                          {type === 'creator' && (
                            <div>
                              <dt className='text-sm font-medium text-gray-500'>
                                Brand
                              </dt>
                              <dd className='mt-1 text-sm text-gray-900 @md:col-span-2 @md:mt-0'>
                                <Link
                                  to={CreatorRoutes.collaborationViewBriefDetails
                                    .replace(':id', `${creator.id}`)
                                    .replace(':uuid', `${collaboration.uuid}`)}
                                  className='mr-1 text-sm font-semibold text-blue-600 hover:text-blue-500'
                                >
                                  {collaboration.campaign.product.name}
                                </Link>
                              </dd>
                            </div>
                          )}

                          <div>
                            <dt className='text-sm font-medium text-gray-500'>
                              Collaboration Status
                            </dt>
                            <dd className='mt-1 text-sm text-gray-900 @md:col-span-2 @md:mt-0'>
                              <CollaborationStatusComponent
                                status={collaboration.status}
                                onProductShippingClick={() => {
                                  if (collaboration.productTrackingLink) {
                                    window.open(
                                      collaboration.productTrackingLink,
                                      '_blank',
                                    )
                                  }
                                }}
                              />
                            </dd>
                          </div>
                        </div>

                        <div className='py-5 grid grid-cols-1 gap-y-6 gap-x-4'>
                          <div>
                            <dt className='text-sm font-medium text-gray-500'>
                              Campaign Details
                            </dt>
                            <dd className='mt-1 text-sm text-gray-900 @md:col-span-2 @md:mt-0'>
                              <div className='truncate mt-1 mb-0 flex flex-shrink-0'>
                                <Badge
                                  type={BadgeType.gray}
                                  iconPosition='left'
                                  text={videoTypeLocalizedString(
                                    collaboration?.campaign?.videoType,
                                  )}
                                  size={BadgeSize.medium}
                                />
                                <Badge
                                  type={BadgeType.gray}
                                  iconPosition='left'
                                  text={videoDurationLocalizedString(
                                    collaboration?.campaign?.videoDuration,
                                  )}
                                  size={BadgeSize.medium}
                                />
                                <Badge
                                  type={BadgeType.gray}
                                  iconPosition='left'
                                  text={modelTypeLocalizedString(
                                    collaboration?.campaign?.modelType,
                                  )}
                                  size={BadgeSize.medium}
                                />
                              </div>
                            </dd>
                          </div>
                        </div>

                        {type === 'creator' && (
                          <div className='py-5 grid grid-cols-2 gap-y-6 gap-x-4'>
                            <div>
                              <dt className='text-sm font-medium text-gray-500'>
                                Earnings
                              </dt>
                              <dd className='mt-1 text-sm text-gray-900 @md:col-span-2 @md:mt-0'>
                                {getCreatorEarningText(collaboration?.campaign)}{' '}
                                + free product
                              </dd>
                            </div>
                          </div>
                        )}

                        {/* <div className='py-5 grid grid-cols-1 gap-y-6 gap-x-4'>
                          <div>
                            <dt className='text-sm font-medium text-gray-500'>
                              Product
                            </dt>
                            <dd className='mt-1 text-sm text-gray-900 @md:col-span-2 @md:mt-0'>
                              <span className='flex items-center'>
                                <div className='p-1 mr-3 [&>*]:m-0.25 min-w-[50px]'>
                                  <Avatar
                                    type='product'
                                    src={
                                      collaboration?.campaign?.product.photo
                                        ?.url
                                    }
                                    size='sm'
                                    alt={collaboration?.campaign?.product.name}
                                  />
                                </div>

                                <div>
                                  <div>
                                    <a
                                      href={
                                        collaboration?.campaign?.product.link
                                      }
                                      className='text-blue-500'
                                      target='_blank'
                                      rel='noopener noreferrer'
                                    >
                                      {collaboration?.campaign?.product.name.slice(
                                        0,
                                        40,
                                      )}
                                      {collaboration?.campaign?.product.name
                                        .length > 40
                                        ? '...'
                                        : ''}
                                    </a>
                                  </div>

                                  <div className='truncate mt-1 mb-0 flex flex-shrink-0 text-gray-500'>
                                    worth{' '}
                                    {mapCurrencyToSign(
                                      collaboration?.campaign?.product.currency,
                                    )}
                                    {collaboration?.campaign?.product.price}
                                  </div>
                                </div>
                              </span>
                            </dd>
                          </div>
                        </div> */}
                      </dl>
                    </div>
                  ),
                )}
              </ul>
            </div>
          )}
        </div>
      </div>
    </Slider>
  )
}
