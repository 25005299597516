import { makeVar, useQuery } from '@apollo/client'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { GET_DEFAULT_BRAND, QueryGetDefaultBrandResponse } from '../gql'
import {
  Brand,
  CountryCode,
  Currency,
  QueryGetDefaultBrandArgs,
} from '../gql/types'
import { init } from '../utils/analytics'
import { usePricing, useSelf } from '../hooks'
import { getIp } from '../utils/ip'

export type LocaleType = {
  currency: Currency
  countryCode: CountryCode
  timezone: string
  utcOffset: number
}

export const Locale = makeVar<LocaleType>({
  currency: Currency.USD,
  countryCode: CountryCode.US,
  timezone: 'Europe/London',
  utcOffset: 60,
})

export const useInit = () => {
  const [loadingExternalServices, setLoadingExternalServices] = useState(true)

  const { user, loading: loadingUser } = useSelf()
  const { pricing, loading: loadingPricing } = usePricing()

  // init mixpanel
  init()

  const initExternalServices = async () => {
    setLoadingExternalServices(true)

    try {
      const { currency, countryCode } = await getIp()

      const mapCurrency = (currency) => {
        switch (currency) {
          case 'GBP':
            return Currency.GBP
          case 'AUD':
            return Currency.AUD
          default:
            return Currency.USD
          // case 'EUR':
          //   return Currency.EUR
        }
      }

      Locale({
        countryCode,
        currency: mapCurrency(currency),
        timezone: moment.tz.guess(),
        utcOffset: moment.tz(moment.tz.guess()).utcOffset(),
      })
    } catch (error) {
      console.log('Error loading locale', error)
    }

    setLoadingExternalServices(false)
  }

  useEffect(() => {
    initExternalServices()
  }, [])

  return {
    user,
    pricing,
    loading: loadingExternalServices || loadingUser || loadingPricing,
  }
}

export const useBrandInit = (
  userId: number,
  brandId: number,
): {
  brand: Brand
  loading: boolean
  // error: any
} => {
  const {
    loading: loadingBrand,
    error: loadingBrandError,
    data: brandData,
  } = useQuery<QueryGetDefaultBrandResponse, QueryGetDefaultBrandArgs>(
    GET_DEFAULT_BRAND,
    {
      variables: {
        userId: userId,
        brandId: brandId,
      },
    },
  )

  useEffect(() => {
    if (loadingBrandError) {
      console.log(
        'THere is an error pulling the default brand',
        loadingBrandError,
      )
    }
  }, [loadingBrandError])

  return {
    brand: brandData?.getDefaultBrand,
    loading: loadingBrand,
  }
}
