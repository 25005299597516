import {
  GetClipsSort,
  QueryGetClipsByCampaignIdArgs,
  User,
} from '../../../gql/types'
import { useQuery } from '@apollo/client'
import {
  GET_CLIPS_BY_CAMPAIGN_ID,
  GetClipsByCampaignResponse,
} from '../../../gql'
import { Header } from '../components/Header'
import { useParams } from 'react-router'
import { ContentGrid } from '../../../components/ContentGrid'
import { LIMIT_DEFAULT } from '../../../utils/constants'

interface IProps {
  user: User
}

export const Clips = ({ user }: IProps) => {
  const { campaignId } = useParams()
  const limit = LIMIT_DEFAULT

  const { loading, error, data, fetchMore } = useQuery<
    GetClipsByCampaignResponse,
    QueryGetClipsByCampaignIdArgs
  >(GET_CLIPS_BY_CAMPAIGN_ID, {
    variables: {
      campaignId: +(campaignId as string),
      options: {
        query: '',
        filters: {},
        sort: GetClipsSort.newest,
        limit,
        skip: 0,
      },
    },
  })

  const fetchData = () => {
    if (loading) {
      return
    }

    // if we have all the data, don't fetch more
    if (
      data?.getClipsByCampaignId?.items?.length >=
      data?.getClipsByCampaignId?.pagination?.numberOfHits
    ) {
      return
    }

    fetchMore({
      variables: {
        campaignId: +(campaignId as string),
        options: {
          query: '',
          filters: {},
          sort: GetClipsSort.newest,
          limit,
          skip: data?.getClipsByCampaignId?.items?.length ?? 0,
        },
      },
    })
  }

  if (error) {
    console.log(error)
    return <p>There has been an error. Try refreshing the page</p>
  }

  return (
    <div className='overflow-y-scroll'>
      <Header />

      <ContentGrid
        clips={data?.getClipsByCampaignId?.items || []}
        hasMore={
          data?.getClipsByCampaignId?.items?.length <
          data?.getClipsByCampaignId?.pagination?.numberOfHits
        }
        fetchData={fetchData}
        loading={loading}
        allowDownload={true}
        type='brand'
      />
    </div>
  )
}
