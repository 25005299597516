import { useMutation, useQuery } from '@apollo/client'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
  Button,
  ButtonColor,
  Loader,
  LoaderType,
  NotificationType,
  TextField,
} from '../../../components'
import {
  CREATE_CAMPAIGN,
  GET_LATEST_CREATED_CAMPAIGN_BY_BRAND_ID,
  GET_LATEST_CREATED_PRODUCT_BY_BRAND_ID,
  UPDATE_BRAND,
  UPDATE_CAMPAIGN,
} from '../../../gql'
import { Brand, BrandOnboardingStep, Campaign } from '../../../gql/types'
import { AnalyticsEvent, trackEvent } from '../../../utils/analytics'
import { Bullets } from '../components/Bullets'
import { Steps } from '../components/Steps'
import {
  useLatestCampaign,
  useLatestProduct,
  useNotification,
} from '../../../utils/hooks'

export type EditCampaignDetailsFormData = {
  title: string
}

interface Props {
  brand: Brand
  campaign: Campaign
}

const SetCampaignDetails = ({ brand, campaign }: Props) => {
  const { addNotification } = useNotification()
  const { product } = useLatestProduct(brand.id)
  const [updateBrand, { loading: loadingUpdateBrand }] =
    useMutation(UPDATE_BRAND)

  const [createCampaign, { loading: loadingCreateCampaign }] = useMutation(
    CREATE_CAMPAIGN,
    {
      refetchQueries: [
        {
          query: GET_LATEST_CREATED_CAMPAIGN_BY_BRAND_ID,
          variables: {
            brandId: brand.id,
          },
        },
      ],
    },
  )

  const [updateCampaign, { loading: loadingUpdateCampaign }] =
    useMutation(UPDATE_CAMPAIGN)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    values: {
      title: campaign?.title ?? `${product?.name} Campaign`,
    },
  })

  useEffect(() => {
    trackEvent(AnalyticsEvent.viewBrandsOnboardingSetCampaignDetails)
  }, [])

  const goBack = async () => {
    try {
      await updateBrand({
        variables: {
          id: brand.id,
          input: {
            onboarding: BrandOnboardingStep.setProductDetails,
          },
        },
      })
    } catch (error) {
      addNotification(error.message, NotificationType.error)
    }
  }

  const onSubmit = async (data: EditCampaignDetailsFormData) => {
    try {
      if (!campaign) {
        await createCampaign({
          variables: {
            brandId: brand.id,
            input: {
              title: data.title,
              productId: product.id,
            },
          },
        })
      }

      if (campaign) {
        await updateCampaign({
          variables: {
            id: campaign.id,
            input: {
              title: data.title,
            },
          },
        })
      }

      await updateBrand({
        variables: {
          id: brand.id,
          input: {
            onboarding: BrandOnboardingStep.setVideoType,
          },
        },
      })
    } catch (error) {
      console.log('@error SetCampaignDetails', error.message)
      addNotification(error.message, NotificationType.error)
    }
  }

  return (
    <div className='min-h-screen flex flex-col'>
      <div className='flex-grow'>
        <Steps
          brand={brand}
          stepCurrent={BrandOnboardingStep.setCampaignDetails}
        />

        <div className='min-h-full flex flex-col justify-center pt-4 px-4 lg:px-8 md:mt-20'>
          <div className='sm:mx-auto sm:w-full sm:max-w-2xl'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='md:shadow rounded-t-md sm:overflow-hidden'>
                <div className='py-5 md:bg-white space-y-6 sm:p-6'>
                  <div className='mx-auto'>
                    <h2 className='mt-0 md:text-center text-3xl font-extrabold text-gray-900'>
                      Create campaign
                    </h2>
                  </div>

                  <div className='grid gap-6'>
                    <div className='col-span-3 sm:col-span-2'>
                      <TextField
                        error={errors.title?.message}
                        label='Campaign title'
                        placeholder='Example: First Campaign'
                        {...register('title', {
                          required: `Please set a campaign title.`,
                          maxLength: {
                            value: 100,
                            message: `Your campaign titlecannot have more than 100 characters.`,
                          },
                        })}
                      />
                      <p className='mt-2 text-sm text-gray-600'>
                        The campaign title will not be visible to creators.
                        Creators will only see your brand and product details.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className='flex justify-between md:shadow rounded-b-md py-3 md:bg-gray-50 sm:px-6'>
                <Button
                  title='Go back'
                  type='button'
                  colour={ButtonColor.white}
                  loading={loadingUpdateBrand}
                  disabled={loadingUpdateBrand}
                  onClick={goBack}
                />
                <Button
                  title='Continue'
                  type='submit'
                  loading={
                    loadingUpdateBrand ||
                    loadingCreateCampaign ||
                    loadingUpdateCampaign
                  }
                  disabled={
                    loadingUpdateBrand ||
                    loadingCreateCampaign ||
                    loadingUpdateCampaign
                  }
                />
              </div>
            </form>
          </div>
        </div>
      </div>

      <Bullets
        brand={brand}
        stepCurrent={BrandOnboardingStep.setCampaignDetails}
      />
    </div>
  )
}

export { SetCampaignDetails }
