import { useQuery } from '@apollo/client'
import { useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import {
  Button,
  ButtonColor,
  ButtonSize,
  ButtonStyle,
  CampaignStatus,
  Icon,
  IconType,
  Loader,
  PageTabs,
  TooltipInfo,
} from '../../../../components'
import { GET_CAMPAIGN_BY_ID, GetCampaignByIdResponse } from '../../../../gql'
import { BrandRoutes } from '../../../BrandRoutes'
import { QueryGetCampaignByIdArgs } from '../../../../gql/types'
import { CampaignActionsDropdown } from '../../../shared/CampaignActionsDropdown'
import { InviteCreatorModal } from '../../../Campaigns/InviteCreatorModal'

const Header = () => {
  const { id, campaignId } = useParams()
  const [isInviteCreatorModalOpen, setIsInviteCreatorModalOpen] =
    useState(false)

  const { loading, error, data } = useQuery<
    GetCampaignByIdResponse,
    QueryGetCampaignByIdArgs
  >(GET_CAMPAIGN_BY_ID, {
    variables: {
      id: Number(campaignId),
    },
  })

  const navigation = useMemo(
    () => [
      {
        text: 'Campaign Details',
        count: null,
        to: BrandRoutes.campaignView
          .replace(':id', `${id}`)
          .replace(':campaignId', `${campaignId}`),
      },
      {
        text: 'Applicants',
        count: data?.getCampaignById?.applicantsCount,
        to: BrandRoutes.campaignApplicants
          .replace(':id', id)
          .replace(':campaignId', campaignId),
      },
      {
        text: 'Collaborators',
        count: data?.getCampaignById?.collaboratorsCount,
        to: BrandRoutes.campaignCollaborators
          .replace(':id', id)
          .replace(':campaignId', campaignId),
      },
      {
        text: 'Clips',
        count: data?.getCampaignById?.clipsCount,
        to: BrandRoutes.campaignClips
          .replace(':id', id)
          .replace(':campaignId', campaignId),
      },
    ],
    [id, data],
  )

  if (loading) {
    return <Loader />
  }

  if (error) {
    console.log(error)
    return <p>There has been an error. Try refreshing the page</p>
  }

  const campaign = data.getCampaignById

  return (
    <div className='flex flex-col -mx-4 sm:-mx-6 md:mx-0 py-4'>
      <nav className='flex' aria-label='Breadcrumb'>
        <ol role='list' className='flex items-center space-x-4'>
          <li key='Campaigns'>
            <div className='flex items-center'>
              <Link
                to={BrandRoutes.campaigns.replace(':id', `${id}`)}
                className='text-sm font-medium text-gray-600 hover:text-gray-700 -mr-2'
              >
                Campaigns
              </Link>
            </div>
          </li>
          <li key={campaign?.title}>
            <div className='flex items-center'>
              <Icon
                type={IconType.chevronRight}
                className='h-5 w-5 flex-shrink-0 text-gray-400'
                aria-hidden='true'
              />
              <Link
                to={BrandRoutes.campaignView
                  .replace(':id', `${id}`)
                  .replace(':campaignId', `${campaign.id}`)}
                className='ml-2 text-sm font-semibold text-gray-700 hover:text-gray-700'
              >
                {campaign?.title}
              </Link>
            </div>
          </li>
        </ol>
      </nav>

      <div className='grid grid-cols-1 lg:flex lg:flex-row lg:justify-between mt-5'>
        <div className='col-span-1'>
          <div className='flex flex-row justify-between'>
            <h1>
              <TooltipInfo
                valueKey='campaign-title'
                content={
                  <div className='font-bold text-3xl font-outfit'>
                    {campaign?.title.substring(0, 30)}
                  </div>
                }
                tooltipText={`The campaign title is not visible to creators. Creators will only see your brand and product details.`}
              />
            </h1>
            <div className='flex flex-row items-center pl-2 pt-[2px] hidden sm:block'>
              <CampaignStatus status={campaign?.status} />
              <Button
                styleType={ButtonStyle.fill}
                // title='Invite creators'
                type='button'
                icon={
                  <Icon
                    type={IconType.personAdd}
                    className='h-5 w-5 text-white'
                    aria-hidden='true'
                  />
                }
                size={ButtonSize.small}
                colour={ButtonColor.primary}
                onClick={() =>
                  setIsInviteCreatorModalOpen(!isInviteCreatorModalOpen)
                }
                iconPosition='left'
                tooltip='Invite creators'
                className='lg:hidden ml-2'
              />
            </div>
          </div>
          <div className='flex flex-row items-center pt-[2px] block sm:hidden'>
            <CampaignStatus status={campaign?.status} />
            <Button
              styleType={ButtonStyle.fill}
              // title='Invite creators'
              type='button'
              icon={
                <Icon
                  type={IconType.personAdd}
                  className='h-5 w-5 text-white'
                  aria-hidden='true'
                />
              }
              size={ButtonSize.small}
              colour={ButtonColor.primary}
              onClick={() =>
                setIsInviteCreatorModalOpen(!isInviteCreatorModalOpen)
              }
              iconPosition='left'
              tooltip='Invite creators'
              className='lg:hidden'
            />
          </div>
        </div>
        <div className='flex flex-row items-center pt-[2px]'>
          <Button
            styleType={ButtonStyle.fill}
            // title='Invite creators'
            type='button'
            icon={
              <Icon
                type={IconType.personAdd}
                className='h-5 w-5 text-white'
                aria-hidden='true'
              />
            }
            size={ButtonSize.small}
            colour={ButtonColor.primary}
            onClick={() =>
              setIsInviteCreatorModalOpen(!isInviteCreatorModalOpen)
            }
            iconPosition='left'
            tooltip='Invite creators'
            className='hidden lg:block mr-2'
          />
          <CampaignActionsDropdown
            campaign={campaign}
            className='md:block hidden'
          />
        </div>
      </div>

      {/* Navigation */}
      <div className='flex flex-row justify-between'>
        <CampaignActionsDropdown campaign={campaign} className='md:hidden' />
      </div>

      {/* Navigation */}
      <PageTabs
        navigation={navigation}
        menuPosition='right'
        className='mt-4 md:mt-0'
      />

      {isInviteCreatorModalOpen && (
        <InviteCreatorModal
          campaign={campaign}
          isOpen={isInviteCreatorModalOpen}
          setIsOpen={setIsInviteCreatorModalOpen}
        />
      )}
    </div>
  )
}

export { Header }
