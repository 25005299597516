import React, {
  createContext,
  ReactElement,
  useCallback,
  useState,
} from 'react'
import { Notification, NotificationType } from '../components'

export const UiContext = createContext({
  notification: null,
  addNotification: (message: string, type: NotificationType) => {},
  removeNotification: () => {},
})

const UiContextProvider = ({ children }) => {
  const [notification, setNotification] = useState(null)

  const removeNotification = () => setNotification(null)

  const addNotification = (message: string, type: NotificationType) => {
    setNotification({
      message,
      type,
    })
  }

  const contextValue = {
    notification,
    addNotification: useCallback(
      (message: string, type: NotificationType) =>
        addNotification(message, type),
      [],
    ),
    removeNotification: useCallback(() => removeNotification(), []),
  }

  return (
    <UiContext.Provider value={contextValue}>
      {notification && (
        <Notification
          type={notification.type}
          message={notification.message}
          onClose={removeNotification}
          isOpen={notification ? true : false}
        />
      )}

      {children}
    </UiContext.Provider>
  )
}

export { UiContextProvider }
