import { Campaign } from '../../../gql/types'
import { BriefExternalLinkRow } from '../BriefRow'
import { useState } from 'react'
import { UpdateInspirationLinksModal } from '../../../brands/shared/UpdateInspirationLinksModal'

interface IInspirationProps {
  campaign: Campaign
  viewMode: 'brand' | 'creator'
}

export const InspirationLinks = ({ campaign, viewMode }: IInspirationProps) => {
  const [
    isUpdateInspirationLinksModalOpen,
    setIsUpdateInspirationLinksModalOpen,
  ] = useState(false)

  return (
    <div>
      <div className='py-2 lg:max-w-7xl'>
        <h3 className='mt-2 text-base font-semibold leading-6 text-gray-900'>
          Links
        </h3>
        {viewMode === 'brand' && (
          <p className='py-2 text-sm w-full'>
            <button
              className='text-blue-600 hover:text-blue-500'
              type='button'
              onClick={() => {
                setIsUpdateInspirationLinksModalOpen(true)
              }}
            >
              Add inspiration links
            </button>{' '}
            for your campaign. Share links to UGC examples that you would like
            creators to use as inspiration for videos (public links to
            Instagram, TikTok, Facebook, Google Drive or others).
          </p>
        )}
        {(!campaign?.inspirationVideosLinks ||
          campaign?.inspirationVideosLinks?.length === 0) &&
          viewMode === 'creator' && (
            <p className='py-4 text-sm w-full'>
              No inspiration links added to campaign.
            </p>
          )}
        {campaign?.inspirationVideosLinks?.length > 0 && (
          <ul role='list' className='@md:divide-y @md:divide-gray-200'>
            {campaign.inspirationVideosLinks.map((link, index) => (
              <li key={index} className='truncate'>
                <BriefExternalLinkRow
                  title={`Link ${index + 1}`}
                  value={link}
                />
              </li>
            ))}
          </ul>
        )}
      </div>
      {isUpdateInspirationLinksModalOpen && (
        <UpdateInspirationLinksModal
          campaign={campaign}
          isOpen={isUpdateInspirationLinksModalOpen}
          setIsOpen={setIsUpdateInspirationLinksModalOpen}
        />
      )}
    </div>
  )
}
