import { useQuery } from '@apollo/client'
import { FunctionComponent, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { CampaignDetailsBrief, Loader } from '../../../components'
import { GET_COLLABORATION_BY_UUID } from '../../../gql'
import {
  Collaboration,
  MessageCheckedStatus,
  QueryGetCollaborationByUuidArgs,
} from '../../../gql/types'
import { Header } from '../components/Header'
import { AnalyticsEvent, trackEvent } from '../../../utils/analytics'
import { Divider } from '../../../components/Divider'
import { formatLongMessage, replaceURLs } from '../../../utils/helpers'

interface GetCollaborationData {
  getCollaborationByUuid: Collaboration
}

interface IDefaultProps {}

interface IProps extends Partial<IDefaultProps> {}

const defaultProps: IDefaultProps = {}

const BriefDetails: FunctionComponent<IProps> = () => {
  const { uuid } = useParams()

  const {
    loading: getCollaborationLoading,
    error: getCollaborationError,
    data: getCollaborationData,
  } = useQuery<GetCollaborationData, QueryGetCollaborationByUuidArgs>(
    GET_COLLABORATION_BY_UUID,
    {
      variables: {
        uuid: uuid,
      },
    },
  )

  useEffect(() => {
    if (getCollaborationData?.getCollaborationByUuid) {
      trackEvent(AnalyticsEvent.viewCreatorsCollaborationView, {
        creatorId: getCollaborationData?.getCollaborationByUuid?.creator?.id,
      })
    }
  }, [getCollaborationData?.getCollaborationByUuid])

  if (getCollaborationLoading) {
    return <Loader />
  }

  if (getCollaborationError) {
    return null
  }

  const collaboration = getCollaborationData?.getCollaborationByUuid

  return (
    <div className='pb-4'>
      <Header collaboration={collaboration} />

      <CampaignDetailsBrief
        campaign={collaboration.campaign}
        hooks={collaboration.hooks?.map((hook) => hook.description)}
        viewMode='creator'
      />

      <Divider className='py-8' />
      <div className='grid @xl:grid-cols-4 @xl:gap-6 grid-cols-1 gap-2 mb-12'>
        <div>
          <h3 className='text-base font-semibold leading-6 text-gray-900'>
            Your application
          </h3>
        </div>
        <div className='overflow-hidden bg-white shadow rounded-lg col-span-3'>
          <div className='border-t border-gray-200 p-4'>
            <dl className='@md:divide-y @md:divide-gray-200'>
              {collaboration.creatorApplicationMessage &&
              collaboration.applicationMessageCheckedStatus ===
                MessageCheckedStatus.ok ? (
                <div
                  className='space-y-6 text-sm text-gray-800'
                  dangerouslySetInnerHTML={{
                    __html: replaceURLs(
                      collaboration.creatorApplicationMessage,
                    ).replace(/\n/g, '<div></div>'),
                  }}
                />
              ) : (
                <div className='text-sm text-gray-800'>
                  No application message.
                </div>
              )}
            </dl>
          </div>
        </div>
      </div>
    </div>
  )
}

BriefDetails.defaultProps = defaultProps

export { BriefDetails }
