import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { PublicRoutes } from '../../../../utils/PublicRoutes'

interface IDefaultProps {}

interface IProps extends Partial<IDefaultProps> {
  messages?: string[]
}

const defaultProps: IDefaultProps = {}

const Error: FunctionComponent<IProps> = ({ messages }) => {
  return (
    <div className='min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8'>
      <div className='mx-auto max-w-max'>
        <main className='sm:flex'>
          <div className='sm:ml-6'>
            <div className='sm:pl-6'>
              <h1 className='text-4xl font-bold tracking-tight text-gray-900 sm:text-3xl'>
                Oops!
              </h1>
              <p className='mt-1 text-base text-gray-500'>
                Looks like something went wrong.
              </p>
              {messages && (
                <div>
                  {messages.map((message) => (
                    <p className='mt-8 text-base text-gray-500'>{message}</p>
                  ))}
                </div>
              )}
            </div>
            <div className='mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6'>
              <Link
                to={PublicRoutes.login}
                className='cursor-pointer inline-flex items-center rounded-md border border-transparent bg-slate-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2'
              >
                Go back home
              </Link>
              <a
                href='https://useclip.com/contact'
                target='_blank'
                rel='noopener noreferrer'
                className='cursor-pointer inline-flex items-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2'
              >
                Contact support
              </a>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

Error.defaultProps = defaultProps

export { Error }
