import { useMemo } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import {
  Button,
  ButtonColor,
  CollaborationStatus as CollaborationStatusComponent,
  Icon,
  IconType,
  PageTabs,
} from '../../../../components'
import { Collaboration, CollaborationStatus } from '../../../../gql/types'
import { CreatorRoutes } from '../../../CreatorRoutes'

interface IProps {
  collaboration: Collaboration
}

const Header = ({ collaboration }: IProps) => {
  const { id, uuid } = useParams()
  const { pathname } = useLocation()

  const navigation = useMemo(
    () => [
      {
        text: 'Brief Details',
        count: 0,
        to: CreatorRoutes.collaborationViewBriefDetails
          .replace(':id', `${id}`)
          .replace(':uuid', uuid),
      },
      {
        text: 'Brand Details',
        count: collaboration?.campaign?.brand?.metadata?.ratingsCount,
        to: CreatorRoutes.collaborationViewBrandDetails
          .replace(':id', `${id}`)
          .replace(':uuid', uuid),
      },
      {
        text: `Clips`,
        count: 0,
        to: CreatorRoutes.collaborationViewClips
          .replace(':id', `${id}`)
          .replace(':uuid', uuid),
      },
    ],
    [id],
  )

  const breadcrumb = useMemo(() => {
    if (pathname.includes('brief-details')) {
      return 'Brief Details'
    }

    if (pathname.includes('brand-details')) {
      return 'Brand Details'
    }

    if (pathname.includes('clips')) {
      return 'Clips'
    }

    return 'Details'
  }, [pathname])

  return (
    <div className='flex flex-col py-4'>
      <nav className='flex' aria-label='Breadcrumb'>
        <ol role='list' className='flex items-center space-x-4'>
          <li key='Active campaigns'>
            <div className='flex items-center'>
              <Link
                to={CreatorRoutes.collaborations.replace(':id', `${id}`)}
                className='text-sm font-medium text-gray-600 hover:text-gray-700'
              >
                Active campaigns
              </Link>
            </div>
          </li>
          {/* <li key={collaboration.campaign.product.name}>
            <div className='flex items-center'>
              <Icon
                type={IconType.chevronRight}
                className='h-5 w-5 flex-shrink-0 text-gray-400'
                aria-hidden='true'
              />
              <Link
                to={CreatorRoutes.collaborationViewBriefDetails
                  .replace(':id', `${id}`)
                  .replace(':uuid', uuid)}
                className='ml-4 text-sm font-medium text-gray-600 hover:text-gray-700'
              >
                {collaboration.campaign.product.name}
              </Link>
            </div>
          </li> */}
          <li key={breadcrumb}>
            <div className='flex items-center'>
              <Icon
                type={IconType.chevronRight}
                className='h-5 w-5 flex-shrink-0 text-gray-400'
                aria-hidden='true'
              />
              <div className='ml-4 text-sm font-semibold text-gray-700 hover:text-gray-700'>
                {breadcrumb}
              </div>
            </div>
          </li>
        </ol>
      </nav>

      <div className='grid grid-cols-1 lg:flex lg:flex-row lg:justify-between mt-5'>
        <div className='col-span-1'>
          <div className='flex flex-row justify-between'>
            <h1 className='font-bold text-3xl font-outfit'>
              {collaboration.campaign.product.name}
            </h1>
            <div className='flex flex-row items-center pl-2 pt-[2px]'>
              <CollaborationStatusComponent status={collaboration.status} />
            </div>
          </div>
        </div>
        {collaboration.isActive && (
          <div className='mt-2 sm:mt-2 sm:flex-none'>
            <Button
              title='Message Brand'
              type='button'
              colour={ButtonColor.lightGray}
              to={
                CreatorRoutes.inboxChat
                  .replace(':id', `${id}`)
                  .replace(
                    ':uuid',
                    `${collaboration.campaign.brand.id}-${id}`,
                  ) +
                '?collaborationId=' +
                collaboration.id
              }
              className='w-full'
            />
          </div>
        )}
      </div>

      {/* Navigation */}
      <PageTabs navigation={navigation} menuPosition='right' className='mt-2' />
    </div>
  )
}

export { Header }
