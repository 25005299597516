import { Controller } from 'react-hook-form'
import {
  CheckboxGroup,
  CheckboxType,
  RadioGroup,
  Select,
  TextArea,
} from '../../../../components'
import { Ethnicity, Gender } from '../../../../gql/types'
import {
  ethnicityLocalizedString,
  genderLocalizedString,
} from '../../../../utils/GqlStrings'
import {
  ageGroupsOptions,
  countryOptions,
  qualityOptions,
} from '../../../../utils/mappers'

interface Props {
  control: any
  errors: any
  register: any
  watch: any
  priceExtraFormatted: string
}

const SetCreatorDetails = ({
  control,
  errors,
  register,
  watch,
  priceExtraFormatted,
}: Props) => {
  return (
    <div>
      <div className='mt-10 sm:mt-0'>
        <div className='md:grid md:grid-cols-3 md:gap-6'>
          <div className='md:col-span-1'>
            <div className=''>
              <h3 className='text-lg font-semibold leading-6 text-gray-900 font-sans'>
                Creator
              </h3>
              <p className='mt-1 text-sm font-normal font-sans leading-5 text-gray-500'>
                What type of creator are you looking for? Try to keep your main
                audience in mind.
              </p>
            </div>
          </div>
          <div className='mt-5 md:col-span-2 md:mt-0'>
            <div className='md:shadow rounded-t-md sm:overflow-hidden'>
              <div className='py-5 md:bg-white space-y-6 sm:p-6'>
                <div className='grid gap-6'>
                  <div className='col-span-3 sm:col-span-2'>
                    <Controller
                      name='quality'
                      control={control}
                      rules={{
                        required: `Please select what type of creator you are looking for.`,
                      }}
                      render={({ field }) => (
                        <RadioGroup
                          options={qualityOptions(priceExtraFormatted)}
                          field={field}
                          error={errors?.quality?.message}
                          className='grid-cols-2'
                        />
                      )}
                    />
                  </div>
                </div>

                <div className='grid gap-6'>
                  <div className='col-span-3 sm:col-span-2'>
                    <Controller
                      name='modelGender'
                      control={control}
                      rules={{ required: `Please set a model gender.` }}
                      render={({ field }) => (
                        <Select
                          options={[
                            'Any',
                            genderLocalizedString(Gender.female),
                            genderLocalizedString(Gender.male),
                          ]}
                          field={field}
                          label='Model Gender'
                          error={errors?.modelGender?.message}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className='grid gap-6'>
                  <div className='col-span-3 sm:col-span-2'>
                    <Controller
                      name='ethnicity'
                      control={control}
                      rules={{ required: `Please set an ethnicity.` }}
                      render={({ field }) => (
                        <Select
                          options={[
                            'Any',
                            ...Object.values(Ethnicity)
                              .filter(
                                (ethnicity) => ethnicity !== Ethnicity.other,
                              )
                              .map((ethnicity) =>
                                ethnicityLocalizedString(ethnicity),
                              ),
                          ]}
                          field={field}
                          label='Ethnicity'
                          error={errors?.ethnicity?.message}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className='grid gap-6'>
                  <div className='col-span-3 sm:col-span-2'>
                    <CheckboxGroup
                      selected={watch('ageGroups')}
                      {...register('ageGroups', {
                        required: 'Please select at least an age group.',
                      })}
                      className='space-y-2 -mb-4 sm:grid sm:grid-cols-2 sm:space-y-0 sm:-space-x-2 lg:flex lg:items-center lg:space-y-0 lg:space-x-4 xl:space-x-10'
                      label='Age Groups'
                      error={errors?.ageGroups?.message}
                    >
                      {[
                        {
                          name: 'Any',
                          type: 'Any',
                          description: '',
                        },
                        ...ageGroupsOptions,
                      ].map(({ name, type }, index) => {
                        return (
                          <CheckboxGroup.CheckboxItem
                            type={CheckboxType.checkbox}
                            key={index}
                            value={type}
                            title={name}
                            {...register('ageGroups')}
                          />
                        )
                      })}
                    </CheckboxGroup>
                  </div>
                </div>

                <div className='grid gap-6'>
                  <div className='col-span-3 sm:col-span-2'>
                    <Controller
                      name='countryCode'
                      control={control}
                      // rules={{ required: `Please set a country.` }}
                      render={({ field }) => (
                        <Select
                          options={countryOptions}
                          field={field}
                          label='Country/Region'
                          error={errors?.countryCode?.message}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className='grid gap-6'>
                  <div className='col-span-3 sm:col-span-2'>
                    <TextArea
                      error={errors.creatorPreferences?.message}
                      label='Other preferences'
                      placeholder='Looking for a specific type of creator? (e.g. creators with pets)'
                      {...register('creatorPreferences', {
                        maxLength: {
                          value: 10000,
                          message: `Your description text is too long.`,
                        },
                      })}
                    />
                    <p className='mt-2 text-left text-sm text-gray-600'>
                      Creators will see this before they apply to your campaign.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='hidden sm:block' aria-hidden='true'>
        <div className='py-5'>
          <div className='border-t border-gray-200' />
        </div>
      </div>
    </div>
  )
}

export { SetCreatorDetails }
