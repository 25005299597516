import { useMutation, useQuery } from '@apollo/client'
import { FunctionComponent, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, NotificationType, TextArea } from '../../../components'
import { Star } from '../../../components/Star'
import { Avatar } from '../../../elements'
import { CREATE_RATING, GET_RATINGS } from '../../../gql'
import {
  Collaboration,
  CollaborationStatus,
  Pagination,
  Rating,
  RatingType,
} from '../../../gql/types'
import { AnalyticsEvent, trackEvent } from '../../../utils/analytics'
import { useNotification } from '../../../utils/hooks'

interface GetCreatorToBrandRatingsData {
  getRatings: {
    items: Rating[]
    pagination: Pagination
  }
}

interface IDefaultProps {}

interface IProps extends Partial<IDefaultProps> {
  collaboration: Collaboration
}

const defaultProps: IDefaultProps = {}

export type CreateRatingFormData = {
  type: string
  stars: number
  review: string
  brandUuid: string
  creatorUuid: string
}

const WriteReview: FunctionComponent<IProps> = ({ collaboration }) => {
  const { campaign, creator } = collaboration

  const { addNotification } = useNotification()
  const [starsRating, setStarsRating] = useState(null)
  const stars = [1, 2, 3, 4, 5]
  const [isLeaveReviewOpen, setIsLeaveReviewOpen] = useState(false)

  const { data, loading } = useQuery<GetCreatorToBrandRatingsData>(
    GET_RATINGS,
    {
      variables: {
        options: {
          filters: {
            brandId: campaign.brand.id,
            creatorId: creator.id,
            type: RatingType.creatorToBrand,
          },
        },
      },
    },
  )

  const [createRating, { loading: createRatingLoading }] = useMutation(
    CREATE_RATING,
    {
      refetchQueries: [
        {
          query: GET_RATINGS,
          variables: {
            options: {
              filters: {
                brandId: campaign.brand.id,
                creatorId: creator.id,
                type: RatingType.creatorToBrand,
              },
            },
          },
        },
        {
          query: GET_RATINGS,
          variables: {
            options: {
              filters: {
                brandId: campaign.brand.id,
                type: RatingType.creatorToBrand,
              },
              skip: 0,
              limit: 6,
            },
          },
        },
      ],
    },
  )

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      type: RatingType.creatorToBrand,
      stars: 0,
      review: '',
      brandUuid: collaboration.campaign.brand.uuid,
      creatorUuid: creator.uuid,
    },
  })

  const onSubmit = async (data: CreateRatingFormData) => {
    try {
      await createRating({
        variables: {
          input: {
            type: RatingType.creatorToBrand,
            stars: Number(data.stars),
            review: data.review,
            collaborationUuid: collaboration.uuid,
          },
        },
      })

      trackEvent(AnalyticsEvent.creatorLeftBrandReview, {
        collaborationId: collaboration.id,
        brandId: collaboration.campaign.brand.id,
        creatorId: collaboration.creator.id,
      })

      addNotification(`Review posted.`, NotificationType.success)
    } catch (error) {
      addNotification(
        `Something went wrong. Please try again.`,
        NotificationType.error,
      )
    }
  }

  // do not show leave a review button if creator already rated brand
  const isCreatorToBrandRating = data?.getRatings?.items[0]

  // do not show leave a review button if collaboration status not in contentAccepted
  const isCreatorAllowedToLeaveReview =
    collaboration.status === CollaborationStatus.contentAccepted

  if (loading || !isCreatorAllowedToLeaveReview || isCreatorToBrandRating) {
    return null
  }

  return (
    <div className='bg-white shadow overflow-hidden sm:rounded-lg p-5 mb-4'>
      <h3 className='text-base font-semibold leading-6 text-gray-900'>
        Rate your collaboration
      </h3>
      <p className='mt-1 text-sm font-normal leading-5 text-gray-500 font-sans'>
        Leave a review for {collaboration.campaign.brand.name}.
      </p>
      {!isLeaveReviewOpen && (
        <Button
          title={`Write a public review`}
          loading={createRatingLoading}
          onClick={() => setIsLeaveReviewOpen(true)}
          className='mt-6 w-full sm:min-w-[250px] sm:max-w-[250px]'
        />
      )}

      {isLeaveReviewOpen && (
        <div className='mt-6'>
          <Avatar
            type='brand'
            src={collaboration.campaign.brand.logo?.url}
            size='md'
            alt={collaboration.campaign.brand.name}
          />

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='mt-2 flex flex-row cursor-pointer'>
              {Object.values(stars).map((star, index) => {
                return (
                  <Star
                    key={star + 1}
                    value={index + 1}
                    name='star'
                    changeStarsRating={(index) => setStarsRating(index)}
                    isActiveStar={starsRating > index ? true : false}
                    className='cursor-pointer'
                    {...register('stars', {
                      required: `Please rate this brand.`,
                    })}
                  />
                )
              })}
            </div>

            <div className='mt-2'>
              {errors.stars?.message ? (
                <div
                  className=''
                  style={{ color: '#f7685b', fontSize: '0.875rem' }}
                >
                  {errors.stars?.message}
                </div>
              ) : null}
            </div>

            <div className='mt-2'>
              <TextArea
                placeholder={`Rate working with ${collaboration.campaign.brand.name}`}
                name='review'
                {...register('review', {
                  maxLength: {
                    value: 10000,
                    message: `Please keep your review under 10,000 characters.`,
                  },
                })}
              />
            </div>

            <div className='mt-2'>
              <Button
                title={'Submit'}
                className='w-full sm:min-w-[250px] sm:max-w-[250px]'
                loading={createRatingLoading}
              />
            </div>
          </form>
        </div>
      )}
    </div>
  )
}

WriteReview.defaultProps = defaultProps

export { WriteReview }
