import { loadStripe } from '@stripe/stripe-js'
import React, { useRef, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import {
  Badge,
  BadgeSize,
  BadgeType,
  Button,
  ButtonColor,
  NotificationType,
  RadioGroup,
  TooltipInfo,
} from '../../../components'
import env from '../../../config/env'
import {
  Collaboration,
  CreatorQuality,
  CountryCode,
  CampaignAddon,
  CollaborationAddon,
} from '../../../gql/types'
import {
  formatPriceInCents,
  formattedAddonsPrice,
  getVideoPricing,
} from '../../../utils/PricingUtil'
import { useNotification } from '../../../utils/hooks'
import { usePricing } from '../../../hooks'
import { UrlBuilder } from '../../../utils/UrlBuilder'
import classNames from 'classnames'
import { useCreateAddonsCheckout } from '../../../hooks/payments'
import { addonsOptions } from '../../../utils/mappers'

const stripePromise = loadStripe(env.STRIPE_KEY)

type EditCampaignFormData = {
  quality: CreatorQuality
  addons: CampaignAddon | null
}

interface IProps {
  collaboration: Collaboration
  open: boolean
  setOpen: (event: any) => void
  description: string
  title: string
}

export const OrderAddons: React.FC<IProps> = ({
  collaboration,
  open,
  setOpen,
  title,
  description,
}) => {
  const campaign = collaboration.campaign
  const { brand } = campaign
  const { addNotification } = useNotification()
  const { pricing } = usePricing()
  const [creditPayment, setCreditPayment] = useState(false)
  const [initiateCheckoutLoading, setInitiateCheckoutLoading] = useState(false)
  const { createAddonsCheckout, loading } = useCreateAddonsCheckout()
  const cancelButtonRef = useRef(null)

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
  } = useForm<EditCampaignFormData>({
    defaultValues: {
      quality: campaign.quality ?? CreatorQuality.regular,
      addons: (collaboration?.addons[0]?.description as CampaignAddon) ?? null,
    },
  })

  const initiateCheckout = async () => {
    setInitiateCheckoutLoading(true)

    try {
      const { data } = await createAddonsCheckout({
        variables: {
          brandId: brand.id,
          input: {
            // @ts-ignore
            addons: watch('addons'),
            collaborationId: collaboration.id,
            successRedirectUrlPath: UrlBuilder.buildCampaignCollaboratorsUrl(
              brand.id,
              collaboration.campaign.id,
            ),
            cancelRedirectUrlPath: UrlBuilder.buildCampaignCollaboratorsUrl(
              brand.id,
              collaboration.campaign.id,
            ),
          },
        },
      })

      // if no stripe session it means the purchase was made with credits
      if (!data?.createAddonsCheckout?.stripeSessionId) {
        addNotification(
          `Your addon order has been confirmed.`,
          NotificationType.success,
        )
      }

      // if stripe session id present - redirect to stripe
      if (data?.createAddonsCheckout?.stripeSessionId) {
        // if stripe session id present - redirect to stripe
        const stripe = await stripePromise

        // When the customer clicks on the button, redirect them to Checkout.
        const result = await stripe.redirectToCheckout({
          sessionId: data?.createAddonsCheckout?.stripeSessionId,
        })

        if (result.error) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
          addNotification(
            `Something went wrong. Please try again or contact support at hello@useclip.com.`,
            NotificationType.error,
          )
        }
      }

      setOpen(false)
      reset()
    } catch (error) {
      addNotification(
        // `Something went wrong. Please try again or contact support at hello@useclip.com.`,
        error.message,
        NotificationType.error,
      )
    }

    setInitiateCheckoutLoading(false)
  }

  const renderAddonsPrice = () => {
    const addons = watch('addons') ? [watch('addons')] : []

    // if (!addons || addons?.length === 0) {
    //   return null
    // }

    const formattedPrice = formattedAddonsPrice({
      addons: watch('addons'),
      currency: campaign.currency,
      duration: campaign.videoDuration,
      pricing,
    })

    return (
      <div className='border rounded-lg shadow-sm px-3 py-3 flex items-center'>
        <div className='flex flex-row justify-between items-center text-sm w-full'>
          <div className='font-medium text-gray-500 ml-1'>
            {formattedPrice}
            {brand.countryCode === CountryCode.GB && <span>{` `}+ VAT</span>}
          </div>
          <div>
            <Badge
              type={BadgeType.indigo}
              iconPosition='left'
              text={
                <TooltipInfo
                  valueKey='addons-badge'
                  content={`${addons?.length} addon${addons?.length > 1 ? 's' : ''}`}
                  tooltipText='Each addon is an altered version of the original video with captions, music or additional elements.'
                />
              }
              size={BadgeSize.small}
              className='!mr-0'
            />
          </div>
        </div>
      </div>
    )
  }

  const renderPayWithCreditInput = () => {
    if (campaign.currency !== brand.currency) {
      return null
    }

    if (brand.creditCents <= 0) {
      return null
    }

    const formattedCredit = formatPriceInCents(
      {
        priceInCents: brand.creditCents,
        currency: campaign.currency,
      },
      { minimumFractionDigits: 0 },
    )

    return (
      <div className='relative flex items-start mt-3'>
        <div className='flex h-6 items-center'>
          <input
            id='payWithCredit'
            aria-describedby='payWithCredit-description'
            name='payWithCredit'
            type='checkbox'
            // checked={creditPayment}
            checked={true}
            disabled
            onChange={() => setCreditPayment((prev) => !prev)}
            className={classNames(
              'h-4 w-4 rounded border-gray-300 text-gray-600 disabled:accent-gray-300 focus:ring-gray-500 cursor-pointer',
              creditPayment
                ? 'focus:ring-gray-800 accent-primary-800'
                : 'accent-gray-300',
            )}
          />
        </div>
        <div className='ml-3 text-sm leading-6'>
          <label
            htmlFor='payWithCredit'
            className='font-medium text-gray-900 cursor-pointer'
          >
            Use credits
          </label>
          <label
            htmlFor='payWithCredit'
            id='payWithCredit-description'
            className='block leading-4 text-gray-500 cursor-pointer'
          >
            You have {formattedCredit} remaining credits. Credits will
            automatically be applied.
          </label>
        </div>
      </div>
    )
  }

  const videoPricing = getVideoPricing({
    currency: collaboration.campaign.currency,
    videoDuration: collaboration.campaign.videoDuration,
    pricing,
  })

  return (
    <form onSubmit={handleSubmit(initiateCheckout)}>
      <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
        <div className='sm:flex sm:items-start'>
          <div className='mt-3 sm:mt-0 sm:ml-0 sm:text-left'>
            <h3 className='text-lg leading-6 font-medium text-gray-900'>
              Buy more addons
            </h3>
            <div className='my-2'>
              <p className='text-sm text-gray-500'>
                {collaboration.addons.length === 0 && description}
              </p>
            </div>
            <div className='h-[450px] sm:h-[245px] overflow-y-auto p-2 -mx-2'>
              {collaboration.addons.length > 0 && (
                <p className='text-sm text-gray-500'>
                  You have already purchased an addon for this collaboration.
                </p>
              )}
              {collaboration.addons.length === 0 && (
                <Controller
                  name='addons'
                  control={control}
                  render={({ field }) => (
                    <RadioGroup
                      options={addonsOptions(
                        videoPricing.addons,
                        collaboration.campaign.currency,
                      )}
                      field={field}
                      error={errors?.addons?.message}
                    />
                  )}
                />
              )}
            </div>
            {collaboration.addons.length > 0 && (
              <div className='px-3 pt-[99px] flex justify-start items-center'></div>
            )}
            {collaboration.addons.length === 0 && (
              <>
                <div className='w-full items-center'>{renderAddonsPrice()}</div>
                {renderPayWithCreditInput()}
              </>
            )}
          </div>
        </div>
      </div>
      <div className='bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse'>
        <Button
          colour={ButtonColor.primary}
          title='Confirm your order'
          type='submit'
          loading={initiateCheckoutLoading}
          disabled={
            collaboration?.addons.length > 0 &&
            watch('addons') === collaboration?.addons[0]?.description
          }
          className='w-full sm:w-auto'
        />
        <button
          type='button'
          className='mt-3 mr-3 w-full inline-flex justify-center items-center rounded-md border border-gray-300 shadow-sm px-4 py-1 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
          onClick={() => setOpen(false)}
          ref={cancelButtonRef}
        >
          Cancel
        </button>
      </div>
    </form>
  )
}
