import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import {
  Badge,
  BadgeSize,
  BadgeType,
  Button,
  ButtonColor,
  ButtonSize,
  Icon,
  IconType,
  TooltipInfo,
} from '../../../../components'
import { CollaborationStatus as CollaborationStatusComponent } from '../../../../components/CollaborationStatus'
import { Avatar } from '../../../../elements'
import { Collaboration, CollaborationStatus } from '../../../../gql/types'
import { BrandRoutes } from '../../../BrandRoutes'
import { CollaborationStatusTime } from '../../../CampaignView/components/CollaborationStatusTime'
import { AcceptContentModal } from '../../../shared/AcceptContentModal'
import { CollaborationClipsSlider } from '../../../shared/CollaborationClipsSlider'
import { ShipProductModal } from '../../../shared/ShipProductModal'
import { UpdateProductTrackingLinkModal } from '../../../shared/UpdateProductTrackingLinkModal'
import { OrderHooksModal } from '../../../shared/OrderHooksModal'
import { OrderAddonsVariationsModal } from '../../../shared/OrderAddonsVariationsModal'

interface IProps {
  collaboration: Collaboration
}

export const CollaborationCard: React.FC<IProps> = ({ collaboration }) => {
  const { id } = useParams()
  const [isShipProductModalOpen, setIsShipProductModalOpen] = useState(false)
  const [
    isUpdateProductTrackingLinkModalOpen,
    setIsUpdateProductTrackingLinkModalOpen,
  ] = useState(false)
  const [isViewClipsSliderOpen, setIsViewClipsSliderOpen] = useState(false)
  // const [isViewProfileSliderOpen, setIsViewProfileSliderOpen] = useState(false)
  const [isAcceptContentModalOpen, setIsAcceptContentModalOpen] =
    useState(false)
  const [isOrderHooksOpen, setIsOrderHooksOpen] = useState(false)
  const [isOrderOpen, setIsOrderOpen] = useState(false)

  const RowButton = (collaboration: Collaboration) => {
    switch (collaboration.status) {
      case CollaborationStatus.creatorAccepted:
        return (
          <Button
            title='Ship product'
            type='button'
            colour={ButtonColor.primary}
            onClick={() => {
              setIsShipProductModalOpen(true)
            }}
          />
        )
      case CollaborationStatus.contentUploaded:
        return (
          <Button
            title='Clips'
            type='button'
            colour={ButtonColor.primary}
            onClick={() => setIsViewClipsSliderOpen(true)}
          />
        )
      case CollaborationStatus.contentAccepted:
        return (
          <Button
            title='Clips'
            type='button'
            colour={ButtonColor.primary}
            onClick={() => setIsViewClipsSliderOpen(true)}
          />
        )
      case CollaborationStatus.creatorApplied:
      case CollaborationStatus.productShipped:
      case CollaborationStatus.productDelivered:
        return undefined
    }
  }

  return (
    <>
      <td className='hidden'>
        <CollaborationClipsSlider
          open={isViewClipsSliderOpen}
          setOpen={setIsViewClipsSliderOpen}
          collaboration={collaboration}
        />

        {/* <ViewCreatorSlider
          open={isViewProfileSliderOpen}
          setOpen={setIsViewProfileSliderOpen}
          creator={collaboration.creator}
          collaboration={collaboration}
        /> */}

        <ShipProductModal
          collaboration={collaboration}
          isOpen={isShipProductModalOpen}
          setIsOpen={setIsShipProductModalOpen}
        />

        <AcceptContentModal
          collaboration={collaboration}
          isOpen={isAcceptContentModalOpen}
          setIsOpen={setIsAcceptContentModalOpen}
        />

        <UpdateProductTrackingLinkModal
          collaboration={collaboration}
          isOpen={isUpdateProductTrackingLinkModalOpen}
          setIsOpen={setIsUpdateProductTrackingLinkModalOpen}
        />
      </td>
      <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
        <Link
          to={BrandRoutes.collaborationViewProfile
            .replace(':id', `${collaboration.campaign.brand.id}`)
            .replace(':uuid', collaboration.uuid)}
          className='cursor-pointer flex gap-2 font-medium text-gray-900 hover:text-gray-800'
        >
          <div className='flex items-center'>
            <Avatar
              type='user'
              src={collaboration.creator.avatar?.url}
              size='sm'
              alt={collaboration.creator.firstName || ''}
            />
            <div className='ml-2'>
              <div>{collaboration.creator?.firstName}</div>
              <div className='truncate mb-0 flex flex-shrink-0 text-blue-600 hover:text-blue-500'>
                view profile
              </div>
            </div>
          </div>
        </Link>
      </td>

      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        <CollaborationStatusComponent
          status={collaboration.status}
          onProductShippingClick={() =>
            setIsUpdateProductTrackingLinkModalOpen(true)
          }
        />
      </td>

      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        {collaboration?.hooksCount > 0 && (
          <div className='mt-1 mb-0'>
            <Badge
              type={BadgeType.indigo}
              iconPosition='left'
              text={
                <TooltipInfo
                  valueKey='hooks-badge'
                  content={`${collaboration?.hooksCount} variations`}
                  tooltipText='Each variation is an altered version of the original video, featuring a unique hook or call-to-action. The duration of each variation is up to 5 seconds.'
                />
              }
              size={BadgeSize.medium}
              className='!mr-0'
            />
          </div>
        )}
        {collaboration?.addonsCount > 0 && (
          <div className='mt-1 mb-0'>
            <Badge
              type={BadgeType.purple}
              iconPosition='left'
              text={
                <TooltipInfo
                  valueKey='addons-badge'
                  content={`${collaboration?.addonsCount} addon${collaboration?.addonsCount > 1 ? 's' : ''} required`}
                  tooltipText='Each addon is an altered version of the original video with captions, music or additional elements.'
                />
              }
              size={BadgeSize.medium}
              className='!mr-0'
            />
          </div>
        )}
        {collaboration?.hooksCount === 0 &&
          collaboration?.addonsCount === 0 && <p className=''>-</p>}
      </td>

      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        <CollaborationStatusTime
          status={collaboration.status}
          updatedAt={collaboration.updatedAt}
        />
      </td>

      <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 space-x-2'>
        {collaboration.isActive && (
          <Button
            // title='Message'
            type='button'
            colour={ButtonColor.white}
            to={
              BrandRoutes.inboxChat
                .replace(':id', `${id}`)
                .replace(':uuid', `${id}-${collaboration.creator.id}`) +
              '?collaborationId=' +
              collaboration.id
            }
            icon={
              <Icon
                type={IconType.inbox}
                className='h-4 w-4'
                aria-hidden='true'
              />
            }
            iconPosition='left'
            tooltip='Message'
          />
        )}
        {/* <Button
          // title='Buy hooks'
          type='button'
          colour={ButtonColor.secondary}
          onClick={() => setIsOrderHooksOpen(true)}
          size={ButtonSize.medium}
          icon={
            <Icon
              type={IconType.videoCamera}
              className='h-4 w-4 text-white'
              aria-hidden='true'
            />
          }
          iconPosition='left'
          tooltip='Buy more variations'
        /> */}
        <Button
          // title='Buy more addons/variations'
          type='button'
          colour={ButtonColor.secondary}
          onClick={() => setIsOrderOpen(true)}
          icon={
            <Icon
              type={IconType.plus}
              className='h-4 w-4 text-white'
              aria-hidden='true'
            />
          }
          iconPosition='left'
          tooltip='Buy more variations/addons'
        />
        {/* <Button
          title='Profile'
          type='button'
          colour={ButtonColor.lightGray}
          onClick={() => setIsViewProfileSliderOpen(true)}
        /> */}
        {RowButton(collaboration)}

        {/* <OrderHooksModal
          collaboration={collaboration}
          title={`Buy more variations`}
          description={`Each variation is an altered version of the original video, featuring a unique hook or call-to-action. The duration of each variation is up to 5 seconds.`}
          open={isOrderHooksOpen}
          setOpen={setIsOrderHooksOpen}
        /> */}
        {setIsOrderOpen && (
          <OrderAddonsVariationsModal
            collaboration={collaboration}
            open={isOrderOpen}
            setOpen={setIsOrderOpen}
          />
        )}
      </td>
    </>
  )
}
