import { useState } from 'react'
import { Button } from '../../../../components'
import { User } from '../../../../gql/types'
import { CreateTemplateModal } from '../CreateTemplateModal'

interface IProps {
  user: User
  brandId: number
}

const Header = ({ user, brandId }: IProps) => {
  const [isCreateTemplateModalOpen, setIsCreateTemplateModalOpen] =
    useState(false)

  return (
    <div className='sm:flex sm:items-center -mx-4 sm:-mx-6 md:mx-0 py-4'>
      <div className='sm:flex-auto'>
        <div className='flex flex-row'>
          <h1 className='font-bold text-3xl font-outfit'>Templates</h1>
        </div>
      </div>
      <div className='mt-2 sm:ml-4 sm:mt-0 sm:flex-none'>
        <Button
          title='New template'
          onClick={() => setIsCreateTemplateModalOpen(true)}
          className='w-full'
        />
      </div>

      {isCreateTemplateModalOpen && (
        <CreateTemplateModal
          userId={user.id}
          isOpen={isCreateTemplateModalOpen}
          setIsOpen={setIsCreateTemplateModalOpen}
          brandId={brandId}
        />
      )}
    </div>
  )
}

export { Header }
