import { Controller } from 'react-hook-form'
import { RadioGroup } from '../../../../components'

interface Props {
  control: any
  errors: any
}

const SetIsVoiceOver = ({ control, errors }: Props) => {
  return (
    <div>
      <div className='mt-10 sm:mt-0'>
        <div className='md:grid md:grid-cols-3 md:gap-6'>
          <div className='md:col-span-1'>
            <div className=''>
              <h3 className='text-lg font-semibold font-sans leading-6 text-gray-900'>
                Voice
              </h3>
              <p className='mt-1 text-sm font-normal font-sans leading-5 text-gray-500'>
                Does the video have voiceover?
              </p>
            </div>
          </div>
          <div className='mt-5 md:col-span-2 md:mt-0'>
            <div className='md:shadow rounded-t-md sm:overflow-hidden'>
              <div className='py-5 md:bg-white space-y-6 sm:p-6'>
                <div className='grid gap-6'>
                  <div className='col-span-3 sm:col-span-2'>
                    <Controller
                      name='isVoiceOver'
                      control={control}
                      rules={{
                        required: `Please select if video has voiceover.`,
                      }}
                      render={({ field }) => (
                        <RadioGroup
                          options={[
                            {
                              name: 'Yes',
                              type: 'TRUE',
                              description: '',
                            },
                            {
                              name: 'No',
                              type: 'FALSE',
                              description: '',
                            },
                          ]}
                          field={field}
                          error={errors?.isVoiceOver?.message}
                          className='sm:grid sm:grid-cols-2 lg:grid-cols-3 my-1'
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='hidden sm:block' aria-hidden='true'>
        <div className='py-5'>
          <div className='border-t border-gray-200' />
        </div>
      </div>
    </div>
  )
}

export { SetIsVoiceOver }
