import { useMutation } from '@apollo/client'
import moment from 'moment'
import { FunctionComponent, useState } from 'react'
import {
  Button,
  ButtonColor,
  DeleteModal,
  Icon,
  IconType,
  Loader,
  NotificationType,
} from '../../../../components'
import { DELETE_TEMPLATE } from '../../../../gql'
import { Template } from '../../../../gql/types'
import { useNotification } from '../../../../utils/hooks'
import { EditTemplateModal } from '../EditTemplateModal'
import { userFullname } from '../../../../utils/UserUtil'

interface IDefaultProps {}

interface IProps extends Partial<IDefaultProps> {
  template: Template
}

const ListItem: FunctionComponent<IProps> = ({ template }) => {
  const [openTemplate, setOpenTemplate] = useState(null)
  const { addNotification } = useNotification()
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false)

  const [deleteTemplateById, { loading: deleteTemplateLoading }] = useMutation(
    DELETE_TEMPLATE,
    {
      update(cache) {
        const normalizedId = cache.identify(template)
        cache.evict({ id: normalizedId })
        cache.gc()
      },
    },
  )

  if (deleteTemplateLoading) {
    return <Loader />
  }

  const onDelete = async () => {
    try {
      await deleteTemplateById({
        variables: {
          templateId: template.id,
        },
      })

      addNotification(
        'Your template have been deleted',
        NotificationType.success,
      )
    } catch (error) {
      addNotification(error.message, NotificationType.error)
    }
  }

  return (
    <>
      <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
        <div className='text-ellipsis overflow-hidden w-20 md:w-40'>
          {template.name}
        </div>
      </td>

      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        <div className='text-ellipsis overflow-hidden w-40 md:w-60'>
          {template.message}
        </div>
      </td>

      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        <div className='text-ellipsis overflow-hidden w-16 md:w-32'>
          {userFullname(template.owner)}
        </div>
      </td>

      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        {moment(template?.updatedAt).format('ll')}
      </td>

      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 space-x-2'>
        <Button
          type='button'
          colour={ButtonColor.white}
          onClick={() => setIsDeleteConfirmationOpen(true)}
          icon={
            <Icon
              type={IconType.trash}
              className='h-4 w-4'
              aria-hidden='true'
            />
          }
          iconPosition='left'
          tooltip='Delete'
        />

        <Button
          title='Edit'
          type='button'
          colour={ButtonColor.primary}
          onClick={() => setOpenTemplate(true)}
        />
      </td>

      {openTemplate && (
        <EditTemplateModal
          template={template}
          isOpen={openTemplate}
          setIsOpen={setOpenTemplate}
        />
      )}
      {isDeleteConfirmationOpen && (
        <DeleteModal
          title='Confirm'
          description='Are you sure you want to delete this template? All of your data will be permanently removed. This action cannot be undone.'
          open={isDeleteConfirmationOpen}
          setOpen={setIsDeleteConfirmationOpen}
          onDelete={onDelete}
        />
      )}
    </>
  )
}

export { ListItem }
