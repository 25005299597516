import { useMutation } from '@apollo/client'
import classnames from 'classnames'
import moment from 'moment-timezone'
import { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  Button,
  ButtonColor,
  Icon,
  IconType,
  NotificationType,
  Pagination as PaginationComponent,
} from '../../../../components'
import { DeleteModal } from '../../../../components/DeleteModal'
import { Avatar } from '../../../../elements'
import { DELETE_PRODUCT, GET_PRODUCTS_BY_BRAND_ID } from '../../../../gql'
import {
  GetProductsSort,
  Pagination,
  Product,
  ProductType,
} from '../../../../gql/types'
import { removeProtocol } from '../../../../utils/helpers'
import { useNotification } from '../../../../utils/hooks'
import { mapCurrencyToSign } from '../../../../utils/mappers'
import { BrandRoutes } from '../../../BrandRoutes'

const TableHead = () => {
  return (
    <thead className='bg-white'>
      <tr>
        <th
          scope='col'
          className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-600 sm:pl-6'
        >
          Name
        </th>
        <th
          scope='col'
          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-600'
        >
          Type
        </th>
        <th
          scope='col'
          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-600'
        >
          Link
        </th>
        <th
          scope='col'
          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-600'
        >
          Price
        </th>
        <th
          scope='col'
          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-600'
        >
          Date created
        </th>
        <th scope='col' className='relative px-3 py-3.5'>
          <span className='sr-only'>Edit</span>
        </th>
        <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
          <span className='sr-only'>Delete</span>
        </th>
      </tr>
    </thead>
  )
}

interface IProps {
  products: Product[]
  pagination: Pagination
  setPage(page: number): void
}

const List = ({ products, pagination, setPage }: IProps) => {
  const { id } = useParams()
  const { addNotification } = useNotification()
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false)
  const [productCurrent, setProductCurrent] = useState(null)

  const [deleteProduct, { loading }] = useMutation(DELETE_PRODUCT, {
    update(cache) {
      const normalizedId = cache.identify(productCurrent)
      cache.evict({ id: normalizedId })
      cache.gc()
    },
  })

  const onDelete = async () => {
    try {
      await deleteProduct({
        variables: {
          productId: productCurrent.id,
        },
      })

      addNotification(`Product deleted.`, NotificationType.success)
    } catch (error) {
      addNotification(
        `The product cannot be deleted because it is attached to a campaign.`,
        NotificationType.error,
      )
    }
  }

  return (
    <>
      <DeleteModal
        title='Confirm'
        description='Are you sure you want to delete this product? All of your data will be permanently removed. This action cannot be undone.'
        open={isDeleteConfirmationOpen}
        setOpen={setIsDeleteConfirmationOpen}
        onDelete={onDelete}
      />
      <div className='flex flex-col'>
        <table className='min-w-full divide-y divide-gray-200'>
          <TableHead />
          <tbody className='divide-y divide-gray-200 bg-white'>
            {pagination.numberOfHits === 0 && (
              <tr>
                <td colSpan={6}>
                  <p className='py-4 pl-4 pr-3 text-sm sm:pl-6 w-full'>
                    No products.
                  </p>
                </td>
              </tr>
            )}
            {products.map((product, index) => (
              <tr
                key={product.id}
                className={classnames(
                  index % 2 === 0 ? 'bg-gray-50' : 'bg-white',
                )}
              >
                <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                  <Link
                    to={BrandRoutes.productEdit
                      .replace(':id', id)
                      .replace(':productId', `${product.id}`)}
                  >
                    <div className='flex items-center'>
                      <div className='mr-3 [&>*]:m-0.25 h-12 w-12 rounded-md'>
                        {product.photo?.url && (
                          <Avatar
                            type='product'
                            src={product.photo?.url}
                            size='sm'
                            alt={product.name}
                          />
                        )}
                        {!product.photo?.url && (
                          <Icon
                            type={IconType.product}
                            style='square'
                            className='inline-block h-6 w-6 rounded-md'
                          />
                        )}
                      </div>
                      <div>
                        <div className='text-ellipsis overflow-hidden w-32 md:w-56 lg:64 font-medium text-gray-900 hover:text-gray-600'>
                          {product.name}
                        </div>
                        {product.details && (
                          <div className='text-ellipsis overflow-hidden w-32 md:w-56 lg:64 text-gray-500'>
                            {product.details}
                          </div>
                        )}
                      </div>
                    </div>
                  </Link>
                </td>

                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                  {product.type}
                </td>

                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                  <div className='flex gap-2'>
                    <a
                      href={product.link}
                      className='font-medium text-blue-600 hover:text-blue-500 '
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <div className='text-ellipsis overflow-hidden w-32 md:w-32 lg:56'>
                        {removeProtocol(product.link)}
                      </div>
                    </a>
                  </div>
                </td>

                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                  <div className='flex gap-2'>
                    {mapCurrencyToSign(product.currency)} {product.price}
                  </div>
                </td>

                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                  <div className='flex gap-2'>
                    {moment(product.createdAt).format('ll')}
                  </div>
                </td>

                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                  <div className='flex gap-2'>
                    <Button
                      title='Edit'
                      colour={ButtonColor.primary}
                      to={BrandRoutes.productEdit
                        .replace(':id', id)
                        .replace(':productId', `${product.id}`)}
                    />
                  </div>
                </td>

                <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-sm text-gray-500 sm:pr-6'>
                  <button
                    onClick={() => {
                      setProductCurrent(product)
                      setIsDeleteConfirmationOpen(true)
                    }}
                    className='flex items-center text-gray-400 hover:text-gray-600 focus:outline-none'
                  >
                    <Icon
                      type={IconType.trash}
                      className='h-5 w-5 text-primary'
                    />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {pagination && (
          <PaginationComponent pagination={pagination} setPage={setPage} />
        )}
      </div>
    </>
  )
}

export { List }
