import { useQuery } from '@apollo/client'
import { FunctionComponent, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ListReviews, Loader } from '../../components'
import { GET_RATINGS } from '../../gql'
import {
  GetRatingsSort,
  Pagination,
  Rating,
  RatingType,
  User,
} from '../../gql/types'
import { AnalyticsEvent, trackEvent } from '../../utils/analytics'
import { Header } from './components/Header'

interface GetRatings {
  getRatings: {
    items: Rating[]
    pagination: Pagination
  }
}

interface IProps {
  user: User
}

const Reviews: FunctionComponent<IProps> = ({ user }) => {
  const { id, uuid } = useParams()
  const limit = 10
  const [skip, setSkip] = useState(0)

  const {
    loading: getRatingsLoading,
    error: getRatingsError,
    data: getRatingsData,
  } = useQuery<GetRatings>(GET_RATINGS, {
    variables: {
      options: {
        query: '',
        filters: {
          brandId: parseInt(id),
          type: RatingType.creatorToBrand,
        },
        sort: GetRatingsSort.newest,
        limit,
        skip,
      },
    },
  })

  useEffect(() => {
    if (getRatingsData?.getRatings) {
      trackEvent(AnalyticsEvent.viewBrandsAllReviews, { id })
    }
  }, [getRatingsData?.getRatings])

  if (getRatingsError) {
    console.log(getRatingsError)
    return <p>There has been an error. Try refreshing the page</p>
  }

  const setPage = (page: number) => {
    setSkip(page * limit)
  }

  const ratings = getRatingsData?.getRatings?.items

  return (
    <div className='pb-12'>
      <Header />

      {getRatingsLoading && <Loader />}

      <div className='grid grid-cols-1 gap-x-8 gap-y-8 lg:grid-cols-3'>
        <div className='px-0'>
          <h2 className='text-lg leading-6 font-medium text-gray-900'>
            Public reviews
          </h2>
          <p className='mt-1 text-sm text-gray-500'>
            Feedback from creators on Clip you have worked with.
          </p>
        </div>

        <div className='lg:col-span-2'>
          <div className='grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6'>
            <div className='sm:col-span-6'>
              {!getRatingsLoading && (
                <ListReviews
                  ratings={ratings.map((rating) => ({
                    id: `${rating.id}`,
                    name: rating.creator?.firstName,
                    profilePicUrl: rating.creator.avatar?.url,
                    url: rating?.creator?.avatar?.url,
                    stars: rating.stars,
                    review: rating.review,
                    createdAt: rating.createdAt,
                    avatarType: 'user',
                  }))}
                  pagination={getRatingsData?.getRatings?.pagination}
                  setPage={setPage}
                  className='shadow ring-1 ring-gray-900/5 sm:rounded-xl'
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { Reviews }
