import { useMutation } from '@apollo/client'
import { ExclamationCircleIcon } from '@heroicons/react/24/solid'
import axios from 'axios'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import validator from 'validator'
import {
  Button,
  ButtonColor,
  ButtonSize,
  Icon,
  IconType,
  NotificationType,
  Select,
  TextArea,
  TextField,
} from '../../components'
import { LogoUpload } from '../../components/LogoUpload'
import {
  GET_BRAND_LOGO_UPLOAD_URL,
  GET_SELF,
  GET_WEBSITE_META,
  UPDATE_BRAND,
  UPLOAD_BRAND_LOGO_FROM_URL,
} from '../../gql'
import {
  Brand,
  CountryCode,
  Currency,
  MutationUpdateBrandArgs,
  MutationUploadBrandLogoFromUrlArgs,
  User,
} from '../../gql/types'
import { cleanUrl } from '../../utils/helpers'
import { useNotification } from '../../utils/hooks'
import {
  countryOptions,
  mapFlagAndTextToCountryCode,
} from '../../utils/mappers'

export type EditBrandFormData = {
  website: string
  name: string
  email: string
  description: string
  logoFileName: string
  countryCode: CountryCode
  currency: Currency
  phoneNumber: string
}

interface Props {
  user: User
  brand: Brand
}

export const Edit = ({ user, brand }: Props) => {
  const { addNotification } = useNotification()

  const [selectedFile, setSelectedFile] = useState(null)
  const [imageUrl, setImageUrl] = useState(brand.logo?.url)

  const [updateBrand, { loading: updateLoading }] = useMutation<
    any,
    MutationUpdateBrandArgs
  >(UPDATE_BRAND, {
    refetchQueries: [
      {
        query: GET_SELF,
      },
    ],
  })

  const [getWebsiteMeta, { loading: getWebsiteMetaLoading }] =
    useMutation(GET_WEBSITE_META)

  const [getBrandLogoUploadUrl, { loading: loadingBrandLogoUploadUrl }] =
    useMutation(GET_BRAND_LOGO_UPLOAD_URL)

  const [uploadBrandLogoFromUrl] = useMutation<
    any,
    MutationUploadBrandLogoFromUrlArgs
  >(UPLOAD_BRAND_LOGO_FROM_URL)

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    watch,
    clearErrors,
    control,
  } = useForm({
    defaultValues: {
      website: brand.website,
      name: brand.name,
      email: brand.email,
      description: brand.description,
      logoFileName: brand.logo?.name,
      currency: brand?.currency ?? null,
      countryCode: brand?.countryCode ?? null,
      phoneNumber: brand.phoneNumber,
    },
  })

  const updateMeta = async () => {
    if (watch('website') === '') {
      setError('website', {
        type: 'custom',
        message: 'Please set a website for your brand.',
      })
      return
    }

    clearErrors('website')

    const { data: dataMeta } = await getWebsiteMeta({
      variables: {
        website: cleanUrl(watch('website')),
      },
    })
    const meta = dataMeta?.getWebsiteMeta

    setValue('website', meta.website)
    setValue('name', meta.title)
    if (watch('name') !== '') {
      clearErrors('name')
    }
    setValue('description', meta.description)
    if (watch('description') !== '') {
      clearErrors('description')
    }

    if (!meta.imageUrl) {
      return
    }

    setImageUrl(meta.imageUrl)

    try {
      const { data } = await uploadBrandLogoFromUrl({
        variables: {
          logoUrl: meta.imageUrl,
          brandId: brand.id,
        },
      })

      setValue('logoFileName', data.uploadBrandLogoFromUrl.fileName)
      setSelectedFile(null)
      clearErrors('logoFileName')
    } catch (error) {
      console.log('error uploadBrandLogoFromUrl', error.message)
      addNotification('Error uploading logo.', NotificationType.error)
    }
  }

  const onBrandLogoUpload = async (file: File) => {
    if (!file) {
      return
    }
    setSelectedFile(file)
    clearErrors('logoFileName')

    try {
      const { data } = await getBrandLogoUploadUrl({
        variables: {
          brandId: brand.id,
          mime: file.type,
        },
      })

      await axios.put(data.getBrandLogoUploadUrl.uploadUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
      })

      setValue('logoFileName', data.getBrandLogoUploadUrl.fileName)
    } catch (error) {
      console.log('error onBrandLogoUpload', error.message)
      addNotification('Error uploading logo.', NotificationType.error)
    }
  }

  const onClickUpdate = async () => {
    if (watch('logoFileName') === null) {
      setError('logoFileName', {
        type: 'custom',
        message: 'Please set a logo for your brand.',
      })
      return
    }
    clearErrors('logoFileName')
  }

  const onSubmit = async (data: EditBrandFormData) => {
    try {
      await updateBrand({
        variables: {
          id: brand.id,
          input: {
            website: data.website,
            name: data.name,
            email: data.email,
            description: data.description,
            logoFileName: data.logoFileName,
            currency: data.currency,
            countryCode: mapFlagAndTextToCountryCode(data.countryCode),
            // countryCode: data.countryCode,
            phoneNumber: data.phoneNumber,
          },
        },
      })

      addNotification('Brand updated successfully.', NotificationType.success)
    } catch (error) {
      if (error.message) {
        addNotification(error.message, NotificationType.error)
        return
      }

      addNotification('Error updating brand.', NotificationType.error)
    }
  }

  const loading =
    updateLoading || getWebsiteMetaLoading || loadingBrandLogoUploadUrl

  const EmailField = () => {
    return (
      <TextField
        label='Email Address'
        name='email'
        placeholder='Example: hello@useclip.com'
        error={errors.email?.message}
        {...register('email', {
          required: `Please set an email.`,
          maxLength: {
            value: 100,
            message: `Please keep your email under 100 letters.`,
          },
          validate: {
            value: (email) => {
              if (validator.isEmail(email)) {
                return true
              }

              return 'Invalid email address.'
            },
          },
        })}
      />
    )
  }

  const CurrencyField = () => {
    return (
      <Controller
        name='currency'
        control={control}
        render={({ field }) => (
          <Select
            label='Currency'
            options={Object.keys(Currency).map((currency) =>
              (currency as Currency).toUpperCase(),
            )}
            field={field}
            defaultValues={brand?.currency.toUpperCase()}
          />
        )}
      />
    )
  }

  const CountryField = () => {
    return (
      <Controller
        name='countryCode'
        control={control}
        render={({ field }) => (
          <Select
            options={countryOptions}
            // options={Object.keys(CountryCode)}
            field={field}
            label='Country/Region'
            error={errors?.countryCode?.message}
          />
        )}
      />
    )
  }

  const PhoneNumberField = () => {
    return (
      <TextField
        label='Phone Number'
        name='phoneNumber'
        error={errors.phoneNumber?.message}
        placeholder='07450 917 213'
        {...register('phoneNumber', {
          minLength: {
            value: 6,
            message: `Please set a real phone number.`,
          },
          maxLength: {
            value: 15,
            message: `Please use a real phone number.`,
          },
        })}
      />
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='lg:pb-8'>
        <div>
          <h2 className='text-lg font-medium leading-6 text-gray-900'>
            Edit brand settings
          </h2>
          <p className='mt-1 text-sm text-gray-500'>
            Edit the details of your brand.
          </p>
        </div>

        <div className='mt-6 grid gap-6'>
          <div className='grid gap-6'>
            <div className='col-span-3 sm:col-span-2'>
              <TextField
                error={errors.website?.message}
                label='Website'
                placeholder='www.example.com'
                className={'rounded-none rounded-r-md'}
                leftElement={
                  <span className='inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm'>
                    https://
                  </span>
                }
                rightElement={
                  <Button
                    title={
                      <Icon type={IconType.check} className='text-white' />
                    }
                    type='button'
                    className='md:text-center text-sm text-gray-600 rounded-none max-w-20'
                    loading={getWebsiteMetaLoading}
                    size={ButtonSize.medium}
                    colour={ButtonColor.primary}
                    onClick={() => updateMeta()}
                  />
                }
                {...register('website', {
                  required: `Please set a website for your brand.`,
                  maxLength: {
                    value: 100,
                    message: `Your brand website cannot have more than 100 characters.`,
                  },
                  validate: {
                    value: (website) => {
                      if (validator.isURL(website)) {
                        return true
                      }

                      return 'Invalid website.'
                    },
                  },
                })}
              />
              {!errors.website?.message && (
                <p className='mt-2 text-sm text-gray-600'>
                  Press the check button to automatically extract essential info
                  about your brand
                </p>
              )}
            </div>
          </div>

          <div className='grid gap-6'>
            <div className='col-span-3 sm:col-span-2'>
              <LogoUpload
                title='Logo'
                logoUpload={
                  selectedFile ? URL.createObjectURL(selectedFile) : imageUrl
                }
                onChange={onBrandLogoUpload}
              />
              <div
                className='relative mt-2 text-sm text-red-600'
                id='errorLogoFileName'
              >
                {errors.logoFileName?.message && (
                  <div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
                    <ExclamationCircleIcon
                      className='h-5 w-5 text-red-500'
                      aria-hidden='true'
                    />
                  </div>
                )}
              </div>
              {errors.logoFileName?.message && (
                <p className='mt-2 text-sm text-red-600' id='errorLogoFileName'>
                  Please set a logo.
                </p>
              )}
            </div>
          </div>

          <div className='grid gap-6'>
            <div className='col-span-3 sm:col-span-2'>
              <TextField
                error={errors.name?.message}
                label='Brand Name'
                placeholder='Example: Gym Shark'
                {...register('name', {
                  required: `Please set a brand name.`,
                  maxLength: {
                    value: 100,
                    message: `Your brand name cannot have more than 100 characters.`,
                  },
                })}
              />
            </div>
          </div>

          <div className='grid gap-6'>
            <div className='col-span-3 sm:col-span-2'>
              <EmailField />
            </div>
          </div>

          <div className='grid gap-6'>
            <div className='col-span-3 sm:col-span-2'>
              <PhoneNumberField />
            </div>
          </div>

          <div className='grid gap-6'>
            <div className='col-span-3 sm:col-span-2'>
              <CountryField />
            </div>
          </div>

          <div className='grid gap-6'>
            <div className='col-span-3 sm:col-span-2'>
              <CurrencyField />
            </div>
          </div>

          <div className='grid gap-6'>
            <div className='col-span-3 sm:col-span-2'>
              <TextArea
                error={errors.description?.message}
                label='Description'
                placeholder='Example: Fitness apparel & accessories'
                {...register('description', {
                  required: `Please set a brand description.`,
                  maxLength: {
                    value: 10000,
                    message: `Description too long.`,
                  },
                })}
              />
            </div>
          </div>
        </div>

        <div className='text-right my-6'>
          <Button
            title='Update'
            type='submit'
            loading={loading}
            disabled={loading}
            onClick={onClickUpdate}
          />
        </div>
      </div>
    </form>
  )
}
