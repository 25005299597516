import { useQuery } from '@apollo/client'
import { useParams } from 'react-router'
import { ContentGrid } from '../../components/ContentGrid'
import { GET_CLIPS_BY_BRAND_ID, GetClipsByBrandResponse } from '../../gql'
import { GetClipsSort, QueryGetClipsByBrandIdArgs, User } from '../../gql/types'
import { LIMIT_DEFAULT } from '../../utils/constants'
import { Header } from './components/Header'

interface IProps {
  user: User
}

export const Clips = ({ user }: IProps) => {
  const { id } = useParams()
  const limit = LIMIT_DEFAULT

  const { loading, error, data, fetchMore } = useQuery<
    GetClipsByBrandResponse,
    QueryGetClipsByBrandIdArgs
  >(GET_CLIPS_BY_BRAND_ID, {
    variables: {
      brandId: +(id as string),
      options: {
        query: '',
        filters: {},
        sort: GetClipsSort.newest,
        limit,
        skip: 0,
      },
    },
  })

  const fetchData = () => {
    if (loading) {
      return
    }

    // if we have all the data, don't fetch more
    if (
      data?.getClipsByBrandId?.items?.length >=
      data?.getClipsByBrandId?.pagination?.numberOfHits
    ) {
      return
    }

    fetchMore({
      variables: {
        brandId: +(id as string),
        options: {
          query: '',
          filters: {},
          sort: GetClipsSort.newest,
          limit,
          skip: data?.getClipsByBrandId?.items?.length ?? 0,
        },
      },
    })
  }

  if (error) {
    console.log(error)
    return <p>There has been an error. Try refreshing the page</p>
  }

  return (
    <div>
      <Header />

      <ContentGrid
        clips={data?.getClipsByBrandId?.items || []}
        hasMore={
          data?.getClipsByBrandId?.items?.length <
          data?.getClipsByBrandId?.pagination?.numberOfHits
        }
        fetchData={fetchData}
        loading={loading}
        allowDownload={true}
        type='brand'
      />
    </div>
  )
}
