import { useQuery } from '@apollo/client'
import { Campaign, Inspiration, InspirationType } from '../../../gql/types'
import { GET_INSPIRATIONS_BY_CAMPAIGN_ID } from '../../../gql'
import { Loader } from '../../Loader'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { BrandRoutes } from '../../../brands/BrandRoutes'
import Skeleton from 'react-loading-skeleton'
import Masonry from 'react-masonry-css'
import { DownloadFileButton } from '../../DownloadFileButton'
import { VideoZoom } from '../../VideoZoom'
import { InspirationIndustry } from '../../InspirationIndustry'
import { InspirationsQuickView } from '../../InspirationsQuickView'
import { InspirationLinks } from './InspirationLinks'

interface IInspirationProps {
  campaign: Campaign
  viewMode: 'brand' | 'creator'
}

export const InspirationBrief = ({ campaign, viewMode }: IInspirationProps) => {
  let [isInspirationSliderOpen, setIsInspirationSliderOpen] = useState(false)

  const [numCols, setNumCols] = useState(6)
  const [gridElement, setGridElement] = useState<HTMLDivElement | null>(null)

  const getNumColumns = (width: number) => {
    if (width >= 1536) return 6
    else if (width >= 1280) return 5
    else if (width >= 1024) return 4
    else if (width >= 768) return 3
    else return 2
  }

  const limit = 10
  const { loading, error, data } = useQuery(GET_INSPIRATIONS_BY_CAMPAIGN_ID, {
    fetchPolicy: 'network-only',
    variables: {
      campaignId: campaign.id,
      options: {
        query: '',
        filters: {
          campaignId: Number(campaign.id),
        },
        sort: null,
        limit,
        skip: 0,
      },
    },
  })

  useEffect(() => {
    if (!gridElement) return

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const newNumCols = getNumColumns(entry.contentRect.width)
        setNumCols(newNumCols)
      }
    })

    resizeObserver.observe(gridElement)

    return () => {
      resizeObserver.disconnect()
    }
  }, [gridElement])

  if (loading && !data?.getInspirationsByCampaignId) {
    return <Loader />
  }

  if (error) {
    console.log('error GET_INSPIRATIONS_BY_CAMPAIGN_ID', error.message)
    return <p>There has been an error. Try refreshing the page</p>
  }

  const inspirations = data?.getInspirationsByCampaignId.items
  const pagination = data?.getInspirationsByCampaignId.pagination

  return (
    <div className='@container'>
      <div className='grid @xl:grid-cols-4 @xl:gap-6 grid-cols-1 gap-2'>
        <div>
          <h3 className='text-base font-semibold leading-6 text-gray-900'>
            Inspiration
          </h3>
        </div>
        <div className='overflow-hidden bg-white shadow rounded-lg col-span-3'>
          <div className='border-t border-gray-200 px-4'>
            <dl className='@md:divide-y @md:divide-gray-200'>
              <InspirationLinks campaign={campaign} viewMode={viewMode} />

              <div className='py-2 lg:max-w-7xl'>
                <h3 className='mt-2 text-base font-semibold leading-6 text-gray-900'>
                  Videos
                </h3>
                {viewMode === 'brand' && (
                  <p className='py-2 text-sm w-full'>
                    Add inspiration videos for your campaign from the{' '}
                    <Link
                      to={BrandRoutes.inspiration.replace(
                        ':id',
                        `${campaign.brand.id}`,
                      )}
                      className='text-blue-600 hover:text-blue-500'
                    >
                      Inspiration Menu
                    </Link>
                    . This shows creators the kind of videos you want, helping
                    them make content that fits your campaign better.
                  </p>
                )}
                {pagination.numberOfHits === 0 && viewMode === 'creator' && (
                  <p className='py-4 text-sm w-full'>
                    No inspiration videos added to campaign.
                  </p>
                )}
                {inspirations && inspirations.length > 0 && (
                  <div ref={setGridElement}>
                    <Masonry
                      breakpointCols={numCols}
                      className='flex p-3 -ml-6 md:-ml-6 lg:-ml-8 xl:-ml-8 2xl:-ml-10'
                      columnClassName='pl-6 space-y-6 md:pl-6 md:space-y-6 lg:pl-8 lg:space-y-8 xl:pl-8 xl:space-y-8 2xl:pl-10 2xl:space-y-10 '
                    >
                      {inspirations.map((inspiration: Inspiration, index) => (
                        <div
                          key={index}
                          className='shadow-2xl overflow-hidden rounded-xl ring-1 ring-black ring-opacity-20 w-full relative'
                        >
                          {inspiration.type === InspirationType.video && (
                            <VideoZoom
                              src={inspiration?.file?.url}
                              thumbnailSrc={inspiration?.thumbnail?.url}
                              className='cursor-pointer rounded-xl w-full object-cover hover:opacity-75'
                            />
                          )}
                          {inspiration.industry && (
                            <div className='absolute right-0 top-2 text-xs font-medium'>
                              <InspirationIndustry
                                industry={inspiration.industry}
                              />
                            </div>
                          )}
                          <div className='absolute right-2 bottom-3 gap-y-1 overflow-hidden text-sm leading-6 text-white'>
                            <DownloadFileButton
                              fileUrl={inspiration?.file?.url}
                              fileName={inspiration?.file?.name}
                            />
                          </div>
                        </div>
                      ))}
                      {loading &&
                        [...Array(limit)].map((x, i) => (
                          <div key={i}>
                            <Skeleton className='shadow-2xl overflow-hidden !rounded-xl ring-1 ring-black ring-opacity-20 w-full relative aspect-[9/16]' />
                          </div>
                        ))}
                    </Masonry>
                  </div>
                )}
                {pagination && pagination?.numberOfHits > limit && (
                  <div
                    className='flex justify-center my-2 text-blue-500 hover:text-blue-700 cursor-pointer'
                    onClick={() => setIsInspirationSliderOpen(true)}
                  >
                    see more
                  </div>
                )}
              </div>
            </dl>
          </div>
        </div>
      </div>
      {isInspirationSliderOpen && (
        <InspirationsQuickView
          campaign={campaign}
          open={isInspirationSliderOpen}
          setOpen={setIsInspirationSliderOpen}
        />
      )}
    </div>
  )
}
