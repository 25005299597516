import { useMutation } from '@apollo/client'
import { CheckIcon } from '@heroicons/react/24/solid'
import { loadStripe } from '@stripe/stripe-js'
import classNames from 'classnames'
import { FunctionComponent, useState } from 'react'
import { Loader, LoaderType, NotificationType } from '../../components'
import env from '../../config/env'
import {
  CREATE_CREDIT_PACK_CHECKOUT,
  MutationCreateCreditPackCheckoutResponse,
} from '../../gql'
import {
  Brand,
  CountryCode,
  CreditPack,
  GetCreditPacksResponse,
  MutationCreateCreditPackCheckoutArgs,
  User,
} from '../../gql/types'
import { formatPriceInCents } from '../../utils/PricingUtil'
import { useNotification } from '../../utils/hooks'
import { BrandRoutes } from '../BrandRoutes'

const stripePromise = loadStripe(env.STRIPE_KEY)

interface IProps {
  user: User
  brand: Brand
  creditPacks: GetCreditPacksResponse
  isBlackFriday?: boolean
  isChristmas?: boolean
}

export const CreditPacks: FunctionComponent<IProps> = ({
  user,
  brand,
  creditPacks,
  isBlackFriday,
  isChristmas,
}) => {
  const { packs, features } = creditPacks
  const { addNotification } = useNotification()
  const [loading, setLoading] = useState(false)
  const [selectedPack, setSelectedPack] = useState<CreditPack>()

  const [getCreditPackCheckoutSession] = useMutation<
    MutationCreateCreditPackCheckoutResponse,
    MutationCreateCreditPackCheckoutArgs
  >(CREATE_CREDIT_PACK_CHECKOUT)

  const initiateCheckout = async (creditPack: CreditPack) => {
    setLoading(true)
    setSelectedPack(creditPack)

    try {
      const urlPath = BrandRoutes.creditPacks.replace(':id', `${brand.id}`)

      const successRedirectUrlPath =
        urlPath + `?orderedCreditPack=${creditPack.type}`

      const cancelRedirectUrlPath = urlPath
      // Get Stripe.js instance
      // Call your backend to create the Checkout Session
      const stripe = await stripePromise

      const { data } = await getCreditPackCheckoutSession({
        variables: {
          brandId: brand.id,
          input: {
            type: creditPack.type,
            successRedirectUrlPath:
              BrandRoutes.billing.replace(':id', `${brand.id}`) +
              `?orderedCreditPack=${creditPack.type}`,
            cancelRedirectUrlPath,
          },
        },
      })

      // When the customer clicks on the button, redirect them to Checkout.
      const result = await stripe.redirectToCheckout({
        sessionId: data?.createCreditPackCheckout,
      })

      if (result.error) {
        setLoading(false)
        console.error(result.error)
        addNotification(
          `Something went wrong. Please try again or contact support.`,
          NotificationType.error,
        )
      }
    } catch (error) {
      setLoading(false)
      console.error(error)
      addNotification(
        `Something went wrong. Please try again or contact support.`,
        NotificationType.error,
      )
    }
  }

  const maxBonusCents = packs.reduce((acc, pack) => {
    return pack.bonusCents > acc ? pack.bonusCents : acc
  }, 0)

  const pricingCategory = ['starter', 'scale']

  const [selectedPricingCategory, setSelectedPricingCategory] = useState(
    pricingCategory[0],
  )

  console.log('PACKS', packs)

  return (
    <div
      className={classNames('py-8', isBlackFriday ? 'px-4 sm:px-8' : 'px-0')}
    >
      <div className='mx-auto max-w-7xl'>
        <div className='mx-auto max-w-4xl text-center'>
          <p className='mt-2 text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl'>
            Save Up To{' '}
            <span className='font-bold text-indigo-600'>
              {formatPriceInCents(
                {
                  priceInCents: maxBonusCents,
                  currency: brand.currency,
                },
                { minimumFractionDigits: 0 },
              )}
            </span>{' '}
            With Credit Packs {isBlackFriday ? 'this Black Friday' : null}{' '}
            {isChristmas ? 'this Christmas' : null}
          </p>
        </div>

        {isChristmas && (
          <div className='mt-10 flex items-center justify-center'>
            <div className='flex flex-row space-x-2'>
              <div
                className={classNames(
                  selectedPricingCategory === 'starter'
                    ? 'bg-black text-white'
                    : 'border border-black bg-white text-black',
                  'mr-2 cursor-pointer rounded-lg px-6 py-4 text-lg shadow-lg',
                )}
                onClick={() => {
                  setSelectedPricingCategory('starter')
                }}
              >
                Starter
              </div>
              <div
                className={classNames(
                  selectedPricingCategory === 'scale'
                    ? 'bg-black text-white'
                    : 'border border-black bg-white text-black',
                  'mr-2 cursor-pointer rounded-lg px-6 py-4 text-lg shadow-lg',
                )}
                onClick={() => {
                  setSelectedPricingCategory('scale')
                }}
              >
                Scale
              </div>
            </div>
          </div>
        )}

        <div
          className={classNames(
            'isolate mx-auto mt-16 grid max-w-md grid-cols-1 lg:grid-cols-2  gap-x-8 xl:gap-x-0 gap-y-8 sm:mt-20 xl:mx-0 lg:max-w-none ',
            isBlackFriday
              ? 'lg:grid-cols-3'
              : isChristmas
                ? 'lg:grid-cols-3'
                : 'lg:grid-cols-4',
          )}
        >
          {packs
            .filter(
              (pack) =>
                !isChristmas || pack?.type?.includes(selectedPricingCategory),
            )
            .map((pack, packIdx) => (
              <div
                key={pack.type}
                className={classNames(
                  pack.mostPopular ? 'xl:z-10' : 'lg:mt-8',
                  packIdx === 0 ? 'lg:rounded-s-3xl' : '',
                  packIdx === packs.length - 1 ? 'lg:rounded-e-3xl' : '',
                  'flex flex-col justify-between rounded-3xl xl:rounded-b-none bg-white p-5 ring-1 ring-gray-200',
                )}
              >
                <div>
                  <div className='flex items-center border-b pb-5 justify-between gap-x-4'>
                    <h3
                      id={pack.type}
                      className={classNames(
                        pack.mostPopular ? 'text-indigo-600' : 'text-gray-900',
                        'text-lg font-semibold leading-8',
                      )}
                    >
                      {pack.name}
                    </h3>
                    {pack.mostPopular ? (
                      <p className='rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-indigo-600'>
                        Most popular
                      </p>
                    ) : null}
                  </div>
                  <p className='mt-6 flex items-baseline text-2xl gap-x-1 font-semibold tracking-tight text-gray-700'>
                    Get{' '}
                    <span className='font-bold text-indigo-700'>
                      {formatPriceInCents(
                        {
                          priceInCents: pack.bonusCents,
                          currency: brand.currency,
                        },
                        { minimumFractionDigits: 0 },
                      )}
                    </span>{' '}
                    bonus
                  </p>
                  {!isBlackFriday && !isChristmas && (
                    <p className='my-4 text-sm leading-6 text-gray-700'>
                      {pack.description}
                    </p>
                  )}
                  <ul className='my-12 space-y-3 text-sm leading-6 text-gray-600'>
                    {features.map((feature) => {
                      if (feature.packs[pack.type]) {
                        return (
                          <li key={feature.name} className='flex gap-x-3'>
                            <CheckIcon
                              className='h-6 w-5 flex-none text-indigo-600'
                              aria-hidden='true'
                            />
                            {typeof feature.packs[pack.type] !== 'boolean'
                              ? feature.packs[pack.type] + ' ' + feature.name
                              : feature.name}
                          </li>
                        )
                      }
                      return null
                    })}
                  </ul>
                  <p className='mt-6 mb-2 flex items-baseline text-3xl gap-x-1 font-semibold tracking-tight text-gray-800'>
                    Pay{' '}
                    <span className='font-bold'>
                      {formatPriceInCents(
                        {
                          priceInCents: pack.priceCents,
                          currency: brand.currency,
                        },
                        { minimumFractionDigits: 0 },
                      )}
                    </span>
                    {brand.countryCode === CountryCode.GB && (
                      <span className='text-sm font-semibold leading-6 tracking-wide text-gray-600'>
                        {` `}+ VAT
                      </span>
                    )}
                  </p>
                  <p className='flex items-baseline text-sm tracking-tight text-gray-600'>
                    Get{' '}
                    {formatPriceInCents(
                      {
                        priceInCents: pack.priceCents + pack.bonusCents,
                        currency: brand.currency,
                      },
                      { minimumFractionDigits: 0 },
                    )}{' '}
                    in value
                  </p>
                </div>
                <button
                  onClick={() => initiateCheckout(pack)}
                  disabled={loading}
                  aria-describedby={pack.type}
                  className={classNames(
                    loading ? 'cursor-not-allowed' : '',
                    pack.mostPopular
                      ? 'bg-indigo-600 text-white shadow-sm hover:bg-indigo-500'
                      : 'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300',
                    'mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600',
                  )}
                >
                  {loading &&
                  selectedPack &&
                  selectedPack.type === pack.type ? (
                    <Loader type={LoaderType.button} />
                  ) : (
                    <>Order Now</>
                  )}
                </button>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
