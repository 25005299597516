import { useMutation } from '@apollo/client'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  Button,
  ButtonColor,
  NotificationType,
  RadioGroup,
} from '../../../components'
import { UPDATE_BRAND, UPDATE_CAMPAIGN } from '../../../gql'
import {
  Brand,
  BrandOnboardingStep,
  Campaign,
  ModelType,
} from '../../../gql/types'
import { AnalyticsEvent, trackEvent } from '../../../utils/analytics'
import { modelTypeOptions } from '../../../utils/mappers'
import { Bullets } from '../components/Bullets'
import { Steps } from '../components/Steps'
import { useNotification } from '../../../utils/hooks'

type EditModelTypeFormData = {
  modelType: ModelType
}

interface Props {
  brand: Brand
  campaign: Campaign
}

const SetModelType = ({ brand, campaign }: Props) => {
  const { addNotification } = useNotification()

  const [updateBrand, { loading: loadingUpdateBrand }] =
    useMutation(UPDATE_BRAND)
  const [updateCampaign, { loading: loadingUpdateCampaign }] =
    useMutation(UPDATE_CAMPAIGN)

  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors },
    setValue,
    watch,
  } = useForm<EditModelTypeFormData>({
    defaultValues: {
      modelType: campaign?.modelType ?? null,
    },
  })

  useEffect(() => {
    trackEvent(AnalyticsEvent.viewBrandsOnboardingSetModelType)
  }, [])

  const goBack = async () => {
    try {
      await updateBrand({
        variables: {
          id: brand.id,
          input: {
            onboarding: BrandOnboardingStep.setVideoType,
          },
        },
      })
    } catch (error) {
      addNotification(error.message, NotificationType.error)
    }
  }

  const onSubmit = async ({ modelType }: EditModelTypeFormData) => {
    try {
      await Promise.all([
        updateCampaign({
          variables: {
            id: campaign.id,
            input: {
              modelType: modelType,
            },
          },
        }),
        updateBrand({
          variables: {
            id: brand.id,
            input: {
              // onboarding: BrandOnboardingStep.setPlatform,
              onboarding: BrandOnboardingStep.setVoiceType,
            },
          },
        }),
      ])
    } catch (error) {
      console.log('@error SetModelType', error.message)
      if (error.message) {
        setError('modelType', { type: 'custom', message: error.message })
        return
      }
    }
  }

  return (
    <div className='min-h-screen flex flex-col pb-14'>
      <div className='flex-grow'>
        <Steps brand={brand} stepCurrent={BrandOnboardingStep.setModelType} />

        <div className='min-h-full flex flex-col justify-center pt-4 px-4 md:mt-8 lg:px-8'>
          <div className='sm:mx-auto sm:w-full sm:max-w-2xl'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='md:shadow rounded-t-md sm:overflow-hidden'>
                <div className='py-5 md:bg-white space-y-6 sm:p-6'>
                  <div className='sm:mx-auto'>
                    <h2 className='mt-0 md:text-center text-3xl font-extrabold text-gray-900'>
                      Model type
                    </h2>
                    <p className='mt-2 md:text-center text-sm text-gray-600'>
                      This will be the angle of your video
                    </p>
                  </div>

                  <div className='grid gap-6'>
                    <div className='col-span-3 sm:col-span-2'>
                      <Controller
                        name='modelType'
                        control={control}
                        rules={{ required: `Please select a model type.` }}
                        render={({ field }) => (
                          <RadioGroup
                            options={modelTypeOptions}
                            field={field}
                            error={errors?.modelType?.message}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='flex justify-between md:shadow rounded-b-md py-3 md:bg-gray-50 sm:px-6'>
                <Button
                  title='Go back'
                  type='button'
                  colour={ButtonColor.white}
                  loading={loadingUpdateBrand}
                  disabled={loadingUpdateBrand}
                  onClick={goBack}
                />
                <Button
                  title='Continue'
                  type='submit'
                  loading={loadingUpdateBrand || loadingUpdateCampaign}
                  disabled={loadingUpdateBrand || loadingUpdateCampaign}
                />
              </div>
            </form>
          </div>
        </div>
      </div>

      <Bullets brand={brand} stepCurrent={BrandOnboardingStep.setModelType} />
    </div>
  )
}

export { SetModelType }
