import { Fragment, useState } from 'react'
import {
  ClipType,
  Collaboration,
  GetUploadUrlResponse,
  MutationCreateClipArgs,
  MutationGetClipVideoUploadUrlArgs,
  CollaborationStatus,
  GetCollaborationsSort,
  CampaignAddon,
} from '../../gql/types'
import { Button, ButtonColor, NotificationType, Select, VideoUpload } from '..'
import { Dialog, Transition } from '@headlessui/react'
import { useMutation } from '@apollo/client'
import {
  CREATE_CLIP,
  GET_CLIP_VIDEO_UPLOAD_URL,
  GET_COLLABORATIONS_BY_CREATOR_ID,
} from '../../gql'
import { useNotification } from '../../utils/hooks'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import {
  clipTypeLocalizedString,
  clipTypeLocalizedStringInverse,
} from '../../utils/GqlStrings'
import { Controller } from 'react-hook-form'
import { SelectOption } from './SelectOption'
import {
  mapCampaignAddonsTypeToText,
  mapTextTypeToCampaignAddon,
} from '../../utils/mappers'

type CreateClipFormData = {
  videoFileName: string
  width: string
  height: string
  clipType: ClipType
  collaborationHook: string
  collaborationAddon: CampaignAddon
  videoType: any
}

interface IProps {
  collaboration: Collaboration
  isOpen: boolean
  setIsOpen: (event: any) => void
}

export const CreateClipModal = ({
  collaboration,
  isOpen,
  setIsOpen,
}: IProps) => {
  const { addNotification } = useNotification()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const [mime, setMime] = useState('')
  const [contentDimensions, setContentDimensions] = useState<{
    w: number | null
    h: number | null
  }>({ w: null, h: null })

  const [getUploadUrl] = useMutation<
    { getClipVideoUploadUrl: GetUploadUrlResponse },
    MutationGetClipVideoUploadUrlArgs
  >(GET_CLIP_VIDEO_UPLOAD_URL)
  const [createClip] = useMutation<any, MutationCreateClipArgs>(CREATE_CLIP, {
    refetchQueries: [
      'GetClipsByCreatorId',
      'GetCollaborationsByCreatorId',
      {
        query: GET_COLLABORATIONS_BY_CREATOR_ID,
        variables: {
          creatorId: collaboration.creator.id,
          options: {
            filters: {
              status: [
                CollaborationStatus.creatorAccepted,
                CollaborationStatus.productShipped,
                CollaborationStatus.productDelivered,
                CollaborationStatus.contentUploaded,
                CollaborationStatus.contentAccepted,
              ],
            },
            sort: GetCollaborationsSort.lastUpdated,
            limit: 20,
            skip: 0,
          },
        },
      },
    ],
  })

  const reset = () => {
    setSelectedFile(null)
    setMime('')
  }

  const {
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = useForm<CreateClipFormData>({
    defaultValues: {
      videoFileName: null,
      width: null,
      height: null,
      clipType: null,
      collaborationHook: null,
      collaborationAddon: null,
      videoType: null,
    },
  })

  const onSubmit = async (dataCreateClip: CreateClipFormData) => {
    if (!selectedFile) {
      setError('Please upload a video')
      return
    }

    setError(null)
    setLoading(true)

    try {
      //make sure to call GET_CONTENT_UPLOAD_URL every time when uploading a new file
      const { data } = await getUploadUrl({
        variables: {
          creatorId: collaboration.creator.id,
          campaignId: collaboration.campaign.id,
          mime,
        },
      })

      // push content to S3
      await axios.put(data.getClipVideoUploadUrl.uploadUrl, selectedFile, {
        headers: {
          'Content-Type': mime,
        },
      })

      await createClip({
        variables: {
          collaborationId: collaboration.id,
          input: {
            videoFileName: data.getClipVideoUploadUrl.fileName,
            width: contentDimensions.w,
            height: contentDimensions.h,
            type: clipTypeLocalizedStringInverse(dataCreateClip.clipType),
            collaborationHookDescription: dataCreateClip?.collaborationHook
              ? (dataCreateClip.collaborationHook as string)
              : null,
            collaborationAddon: mapTextTypeToCampaignAddon(
              dataCreateClip?.collaborationAddon,
            ) as CampaignAddon,
          },
        },
      })

      closeModal()
      addNotification(`Clip uploaded`, NotificationType.success)
      reset()
    } catch (error) {
      console.log('Error Creating clip', error.message)

      addNotification(
        `Something went wrong. Please try again.`,
        NotificationType.error,
      )
    }

    setLoading(false)
  }

  const handleFileInput = (
    file: File,
    size?: {
      w: number | null
      h: number | null
    },
  ) => {
    if (!file) {
      setSelectedFile(null)
      setMime('')
      return
    }

    setSelectedFile(file)
    setMime(file.type)
    setError(null)
    setContentDimensions(size)
  }

  const closeModal = () => {
    setIsOpen(false)
    reset()
  }

  const getClipTypeOptions = () => {
    let options = Object.keys(ClipType)

    options = options.filter((clipType) => clipType !== ClipType.rawFile)

    if (collaboration?.firstVersionClip) {
      options = options.filter((clipType) => clipType !== ClipType.firstVersion)
    }

    // if edited version already uploaded or first version not uploaded yet ignore
    if (
      collaboration?.editedVersionClip ||
      !collaboration?.firstVersionClip ||
      !collaboration?.editsRequestMessage
    ) {
      options = options.filter(
        (clipType) => clipType !== ClipType.editedVersion,
      )
    }

    if (
      !collaboration?.hooks ||
      collaboration.hooks.length === 0 ||
      collaboration.pendingUploadHooksCount === 0
    ) {
      options = options.filter((clipType) => clipType !== ClipType.hook)
    }

    if (
      !collaboration?.addons ||
      collaboration.addons.length === 0 ||
      collaboration.pendingUploadAddonsCount === 0
    ) {
      options = options.filter((clipType) => clipType !== ClipType.addon)
    }

    return options.map((clipType) =>
      clipTypeLocalizedString(clipType as ClipType),
    )
  }

  const getMainEditedVideoInfo = () => {
    if (!collaboration?.firstVersionClip && !collaboration?.editedVersionClip) {
      return (
        <>
          {/* <p>
            Please make sure to upload the <i>Main Video</i> for this
            collaboration - the <b>UGC raw</b> video that respects the brief.
          </p>
          <p className='mt-2'>
            Do not add captions or music to the main video (transitions, stop motion are fine).
          </p> */}
          <p>
            Please upload the <i>Main Video</i> for this collaboration according
            to the brief, don't add captions or music.
          </p>
        </>
      )
    }

    if (
      collaboration?.firstVersionClip &&
      !collaboration?.editedVersionClip &&
      collaboration?.editsRequestMessage
    ) {
      return (
        <span>
          Upload the <i>Edited Video</i> with the edits requested by brand.
        </span>
      )
    }

    if (collaboration?.firstVersionClip && collaboration?.editedVersionClip) {
      return (
        <span>
          You have already uploaded the main and edited videos for this
          collaboration. If you would like to upload more files use the{' '}
          <i>Other</i> option.
        </span>
      )
    }

    return null
  }

  const getVariationsAddonsInfo = () => {
    if (
      collaboration?.pendingUploadHooksCount > 0 &&
      collaboration?.pendingUploadAddonsCount > 0
    ) {
      return (
        <div>
          <p>
            Submit {collaboration?.pendingUploadHooksCount} variation
            {collaboration?.pendingUploadHooksCount > 1 ? 's' : ''} and{' '}
            {collaboration?.pendingUploadAddonsCount} video for addons according
            to the brief.
          </p>
        </div>
      )
    }

    if (collaboration?.pendingUploadHooksCount > 0) {
      return (
        <div>
          <p>
            Submit {collaboration?.pendingUploadHooksCount} variation
            {collaboration?.pendingUploadHooksCount > 1 ? 's' : ''} according to
            the brief.
          </p>
        </div>
      )
    }

    if (collaboration?.pendingUploadAddonsCount > 0) {
      return (
        <div>
          <p>
            Submit {collaboration?.pendingUploadAddonsCount} video for addons
            according to the brief.
          </p>
        </div>
      )
    }

    return null
  }

  const getInfoAboutVariationsAndAddons = () => {
    if (
      (collaboration?.pendingUploadHooksCount > 0 ||
        collaboration?.pendingUploadAddonsCount > 0) &&
      collaboration.status !== CollaborationStatus.contentAccepted
    ) {
      return (
        <div className='text-sm text-gray-500 mt-2'>
          <p className='mt-2'>
            <span>
              Wait for the brand's approval of the <i>Main Video</i> before
              uploading variations or addons.
            </span>
          </p>
        </div>
      )
    }
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-25' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                <Dialog.Title
                  as='h3'
                  className='text-lg font-medium leading-6 text-gray-900'
                >
                  Upload Clip
                </Dialog.Title>
                {/* <div className='text-sm text-gray-500 mt-2'> */}
                {/* Please make sure to upload just <b>one</b> Clip - the{' '}
                  <b>UGC raw</b> final version that respects the brand's brief. */}
                {/* </div> */}
                <div className='text-sm text-gray-500 mt-2'>
                  {getMainEditedVideoInfo()}
                </div>
                <div className='text-sm text-gray-500 mt-2'>
                  {getVariationsAddonsInfo()}
                </div>
                <div className='text-sm text-gray-500 mt-2'>
                  {getInfoAboutVariationsAndAddons()}
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='mt-2 max-h-96 overflow-y-auto'>
                    <Controller
                      name='clipType'
                      control={control}
                      rules={{ required: `Please set a video type.` }}
                      render={({ field }) => (
                        <Select
                          label='Select video type'
                          options={getClipTypeOptions()}
                          field={field}
                          error={errors?.clipType?.message}
                        />
                      )}
                    />

                    {collaboration?.hooks?.length !== 0 &&
                      clipTypeLocalizedStringInverse(watch('clipType')) ===
                        ClipType.hook && (
                        <div className='mt-2'>
                          <Controller
                            name='collaborationHook'
                            control={control}
                            rules={{ required: `Please set a hook.` }}
                            render={({ field }) => (
                              <SelectOption
                                options={Object.values(collaboration.hooks).map(
                                  (hook) => ({
                                    description: hook.description as string,
                                    disabled: hook?.clipId ? true : false,
                                  }),
                                )}
                                field={field}
                                label='Select variation'
                                error={errors?.collaborationHook?.message}
                              />
                            )}
                          />
                        </div>
                      )}

                    {collaboration?.addons &&
                      collaboration?.addons?.length !== 0 &&
                      clipTypeLocalizedStringInverse(watch('clipType')) ===
                        ClipType.addon && (
                        <div className='mt-2'>
                          <Controller
                            name='collaborationAddon'
                            control={control}
                            rules={{ required: `Please set a addon.` }}
                            render={({ field }) => (
                              <SelectOption
                                options={Object.values(
                                  collaboration.addons,
                                ).map((addon) => ({
                                  description: mapCampaignAddonsTypeToText(
                                    addon.description as CampaignAddon,
                                  ),
                                  // TO DO: check if addon is already uploaded
                                  // disabled: addon?.clipId ? true : false,
                                  disabled: addon?.clipId ? true : false,
                                }))}
                                field={field}
                                label='Select addon'
                                error={errors?.collaborationAddon?.message}
                              />
                            )}
                          />
                        </div>
                      )}

                    <div className='mt-6'>
                      <VideoUpload onChange={handleFileInput} />
                      {error && (
                        <p className='text-red-500 text-sm mt-2'>{error}</p>
                      )}
                    </div>
                  </div>

                  <div className='mt-4 flex justify-between'>
                    <div className='py-2'>
                      <Button
                        type='button'
                        title='Cancel'
                        colour={ButtonColor.white}
                        onClick={closeModal}
                      />
                    </div>

                    <div className='py-2'>
                      <Button
                        // type='button'
                        type='submit'
                        title='Upload'
                        loading={loading}
                      />
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
