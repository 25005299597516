import React from 'react'
import { User } from '../../../gql/types'
import { Header } from '../components/Header'
import { EarningsStats } from './components/EarningsStats'
import { EditEarningsForm } from './components/EditEarningsForm'

interface IProps {
  user: User
}

export const CreatorEarnings: React.FC<IProps> = ({ user }) => {
  const creator = user.creator

  return (
    <div>
      <Header />
      {!creator && <p>There has been an error. Try refreshing the page</p>}
      {creator && (
        <>
          <h2 className='text-lg font-medium leading-6 text-gray-900'>
            Your earnings
          </h2>

          <div className='mt-6'>
            <EarningsStats creator={creator} />
          </div>

          <div className='mt-6'>
            <EditEarningsForm creator={creator} />
          </div>
        </>
      )}
    </div>
  )
}
