import {
  GetInspirationsResponse,
  QueryGetInspirationsByBrandIdArgs,
  GetInspirationsSort,
  User,
  Inspiration,
  InspirationType,
  GetCampaignsSort,
  QueryGetCampaignsByBrandIdArgs,
} from '../../gql/types'
import { useQuery } from '@apollo/client'
import {
  GET_CAMPAIGNS_BY_BRAND_ID,
  GET_INSPIRATIONS_BY_BRAND_ID,
  GetCampaignsByBrandIdResponse,
} from '../../gql'
import { Header } from './components/Header'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import Masonry from 'react-masonry-css'
import { DownloadFileButton } from '../../components/DownloadFileButton'
import { VideoZoom } from '../../components/VideoZoom'
import InfiniteScroll from 'react-infinite-scroller'
import { InspirationIndustry } from '../../components'
import { AddCampaignsToInspiration } from './components/AddCampaignsToInspiration'

interface IProps {
  user: User
}

const Inspirations = ({ user }: IProps) => {
  const { id } = useParams()

  const [numCols, setNumCols] = useState(6)
  const [gridElement, setGridElement] = useState<HTMLDivElement | null>(null)

  const getNumColumns = (width: number) => {
    if (width >= 1536) return 6
    else if (width >= 1280) return 5
    else if (width >= 1024) return 4
    else if (width >= 768) return 3
    else return 2
  }

  const limit = 10

  const { loading, error, data, fetchMore } = useQuery<
    { getInspirationsByBrandId: GetInspirationsResponse },
    QueryGetInspirationsByBrandIdArgs
  >(GET_INSPIRATIONS_BY_BRAND_ID, {
    fetchPolicy: 'network-only',
    variables: {
      brandId: Number(id),
      options: {
        query: '',
        filters: {},
        sort: GetInspirationsSort.lastUpdated,
        skip: 0,
        limit,
      },
    },
  })

  const { data: campaignsData } = useQuery<
    GetCampaignsByBrandIdResponse,
    QueryGetCampaignsByBrandIdArgs
  >(GET_CAMPAIGNS_BY_BRAND_ID, {
    variables: {
      brandId: parseInt(id),
      options: {
        query: '',
        sort: GetCampaignsSort.newest,
      },
    },
  })

  const campaigns = campaignsData?.getCampaignsByBrandId?.items

  const fetchData = () => {
    if (loading) {
      return
    }

    // if we have all the data, don't fetch more
    if (
      data?.getInspirationsByBrandId?.items?.length >=
      data?.getInspirationsByBrandId?.pagination?.numberOfHits
    ) {
      return
    }

    fetchMore({
      variables: {
        brandId: Number(id),
        options: {
          query: '',
          filters: {},
          sort: GetInspirationsSort.lastUpdated,
          limit,
          skip: data?.getInspirationsByBrandId?.items?.length ?? 0,
        },
      },
    })
  }

  useEffect(() => {
    if (!gridElement) return

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const newNumCols = getNumColumns(entry.contentRect.width)
        setNumCols(newNumCols)
      }
    })

    resizeObserver.observe(gridElement)

    return () => {
      resizeObserver.disconnect()
    }
  }, [gridElement])

  //  attach at inspiration - campaigns by brand - GET_INSPIRATIONS_BY_BRAND - var brandId si options
  if (error) {
    console.log(error)
    return <p>There has been an error. Try refreshing the page</p>
  }

  return (
    <div className='flex h-full flex-col overflow-y-scroll'>
      <Header />

      <InfiniteScroll
        loadMore={fetchData}
        hasMore={
          data?.getInspirationsByBrandId?.items?.length <
          data?.getInspirationsByBrandId?.pagination?.numberOfHits
        }
        initialLoad={false}
        useWindow={true}
      >
        <div ref={setGridElement}>
          <Masonry
            breakpointCols={numCols}
            className='flex p-1 -ml-6 md:-ml-6 lg:-ml-8 xl:-ml-8 2xl:-ml-10'
            columnClassName='pl-6 space-y-6 md:pl-6 md:space-y-6 lg:pl-8 lg:space-y-8 xl:pl-8 xl:space-y-8 2xl:pl-10 2xl:space-y-10 '
          >
            {data?.getInspirationsByBrandId?.items?.map(
              (inspiration: Inspiration, index) => (
                <div key={index}>
                  <div className='shadow-2xl overflow-hidden rounded-xl ring-1 ring-black ring-opacity-20 w-full relative'>
                    {inspiration.type === InspirationType.video && (
                      <VideoZoom
                        src={inspiration?.file?.url}
                        thumbnailSrc={inspiration?.thumbnail?.url}
                        className='cursor-pointer rounded-xl w-full object-cover hover:opacity-75'
                      />
                    )}
                    {inspiration.industry && (
                      <div className='absolute right-0 top-2 text-xs font-medium'>
                        <InspirationIndustry industry={inspiration.industry} />
                      </div>
                    )}
                    <div className='absolute right-2 bottom-3 gap-y-1 overflow-hidden text-sm leading-6 text-white'>
                      <DownloadFileButton
                        fileUrl={inspiration?.file?.url}
                        fileName={inspiration?.file?.name}
                      />
                    </div>
                  </div>
                  <div className='-mt-2 p-1 overflow-hidden text-sm leading-6 text-white'>
                    <AddCampaignsToInspiration
                      inspiration={inspiration}
                      campaigns={campaigns}
                    />
                  </div>
                </div>
              ),
            )}
            {loading &&
              [...Array(limit)].map((x, i) => (
                <div key={i}>
                  <Skeleton className='shadow-2xl overflow-hidden !rounded-xl ring-1 ring-black ring-opacity-20 w-full relative aspect-[9/16]' />
                </div>
              ))}
          </Masonry>
        </div>
      </InfiniteScroll>
      {data?.getInspirationsByBrandId?.items?.length >=
        data?.getInspirationsByBrandId?.pagination?.numberOfHits && (
        <div className='flex justify-center py-2'>
          <span className='text-gray-500 text-sm'>No more content to load</span>
        </div>
      )}
    </div>
  )
}

export { Inspirations }
