import { Navigate } from 'react-router-dom'
import { Loader, LoaderType } from '../../components/Loader'
import { Brand, BrandOnboardingStep, User } from '../../gql/types'
import { BrandRoutes } from '../BrandRoutes'
import { SetBrandDetails } from './SetBrandDetails'
import { SetBrandWebsite } from './SetBrandWebsite'
import { SetCampaignDetails } from './SetCampaignDetails'
import { SetCreatorDetails } from './SetCreatorDetails'
import { SetModelType } from './SetModelType'
import { SetOrderSummary } from './SetOrderSummary'
import { SetProductDetails } from './SetProductDetails'
import { SetRequirements } from './SetRequirements'
import { SetVideoDuration } from './SetVideoDuration'
import { SetVideoType } from './SetVideoType'
import { useLatestCampaign } from '../../utils/hooks'
import { PricingData } from '../../utils/PricingUtil'
import { SetVariations } from './SetVariations'
import { SetAddons } from './SetAddons'
import { SetVoiceType } from './SetVoiceType'

interface Props {
  user: User
  pricing: PricingData
  currentBrand: Brand
}

const Onboarding = ({ user, pricing, currentBrand }: Props) => {
  const { onboarding } = currentBrand

  const { campaign, loading: loadingLatestCampaign } = useLatestCampaign(
    currentBrand.id,
  )

  if (loadingLatestCampaign) {
    return <Loader type={LoaderType.page} />
  }

  return (
    <div>
      {onboarding === BrandOnboardingStep.setWebsite && (
        <SetBrandWebsite brand={currentBrand} />
      )}
      {onboarding === BrandOnboardingStep.setDetails && (
        <SetBrandDetails brand={currentBrand} />
      )}
      {onboarding === BrandOnboardingStep.setProductDetails && (
        <SetProductDetails brand={currentBrand} />
      )}
      {onboarding === BrandOnboardingStep.setCampaignDetails && (
        <SetCampaignDetails brand={currentBrand} campaign={campaign} />
      )}
      {onboarding === BrandOnboardingStep.setVideoType && (
        <SetVideoType brand={currentBrand} campaign={campaign} />
      )}
      {onboarding === BrandOnboardingStep.setModelType && (
        <SetModelType brand={currentBrand} campaign={campaign} />
      )}
      {onboarding === BrandOnboardingStep.setVoiceType && (
        <SetVoiceType brand={currentBrand} campaign={campaign} />
      )}
      {/* {onboarding === BrandOnboardingStep.setPlatform && (
        <SetPlatform brand={brand} campaign={campaign} />
      )} */}
      {onboarding === BrandOnboardingStep.setVideoDuration && (
        <SetVideoDuration
          brand={currentBrand}
          campaign={campaign}
          pricing={pricing}
        />
      )}
      {onboarding === BrandOnboardingStep.setRequirements && (
        <SetRequirements brand={currentBrand} campaign={campaign} />
      )}
      {onboarding === BrandOnboardingStep.setAddons && (
        <SetAddons brand={currentBrand} campaign={campaign} pricing={pricing} />
      )}
      {onboarding === BrandOnboardingStep.setHooks && (
        <SetVariations
          brand={currentBrand}
          campaign={campaign}
          pricing={pricing}
        />
      )}
      {onboarding === BrandOnboardingStep.setCreatorDetails && (
        <SetCreatorDetails brand={currentBrand} campaign={campaign} />
      )}
      {onboarding === BrandOnboardingStep.setOrderSummary && (
        <SetOrderSummary
          brand={currentBrand}
          campaign={campaign}
          pricing={pricing}
        />
      )}
      {onboarding === BrandOnboardingStep.completed && (
        <Navigate
          to={BrandRoutes.campaigns.replace(':id', `${currentBrand.id}`)}
        />
      )}
    </div>
  )
}

export { Onboarding }
