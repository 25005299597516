import React, { useState } from 'react'
import { Pagination, PortfolioItem } from '../../gql/types'
import { VideoPlayerProvider } from '../VideoPlayer/Provider'
import { VideoPlayer } from '../VideoPlayer'
import { Pagination as PaginationComponent } from '../Pagination'
import { Avatar } from '../../elements'
import { useMutation } from '@apollo/client'
import { DELETE_PORTFOLIO_ITEM } from '../../gql'
import { useNotification } from '../../utils/hooks'
import { NotificationType } from '../Notification'
import { DeleteModal } from '../DeleteModal'
import { Icon, IconType } from '../Icon'

export interface PortfolioGridProps {
  items: PortfolioItem[]
  pagination?: Pagination
  setPage?(page: number): void
  isFullScreen?: boolean
  isDeleteAvailable?: boolean
}

export const PortfolioGrid: React.FC<PortfolioGridProps> = ({
  items,
  pagination,
  setPage,
  isFullScreen,
  isDeleteAvailable,
}) => {
  const { addNotification } = useNotification()
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false)
  const [portfolioItemCurrent, setPortfolioItemCurrent] = useState(null)

  const [deletePortfolioItem, { loading }] = useMutation(
    DELETE_PORTFOLIO_ITEM,
    {
      update(cache) {
        const normalizedId = cache.identify(portfolioItemCurrent)
        cache.evict({ id: normalizedId })
        cache.gc()
      },
    },
  )

  const onDelete = async () => {
    try {
      await deletePortfolioItem({
        variables: {
          portfolioItemId: portfolioItemCurrent.id,
        },
      })

      addNotification(`Portfolio video deleted.`, NotificationType.success)
    } catch (error) {
      addNotification(
        `Something went wrong. Please try again.`,
        NotificationType.error,
      )
    }
  }

  let gridClassName = 'grid grid-cols-2 gap-x-6 gap-y-6 '
  if (isFullScreen) {
    gridClassName += `md:grid-cols-3 lg:grid-cols-4`
  }

  if (!isFullScreen) {
    gridClassName += `grid-cols-2`
  }

  return (
    <div className='@container px-4 max-w-7xl'>
      <DeleteModal
        title='Confirm'
        description='Are you sure you want to delete this videos from your portfolio? All of your data will be permanently removed. This action cannot be undone.'
        open={isDeleteConfirmationOpen}
        setOpen={setIsDeleteConfirmationOpen}
        onDelete={onDelete}
      />

      <ul className={gridClassName}>
        <VideoPlayerProvider>
          {items.map((item: PortfolioItem) => (
            <div key={item.id} className='group'>
              <div className='relative isolate flex flex-col justify-end shadow-sm shadow-black/20 dark:shadow-white/20 overflow-hidden rounded-2xl bg-gray-900 aspect-[0.7]'>
                <VideoPlayer
                  src={item.video.url}
                  poster={item?.thumbnail?.url}
                  className='absolute inset-0 -z-10 h-full w-full'
                  controls
                />
                <div className='absolute left-2 top-2 gap-y-1 overflow-hidden text-sm leading-6 text-white'>
                  <div className='flex gap-x-2 items-center'>
                    <Avatar
                      type='user'
                      size='xxs'
                      src={item.creator.avatar?.url}
                      alt={item.creator?.firstName}
                    />
                    {item.creator?.firstName}
                  </div>
                </div>
                {isDeleteAvailable && (
                  <div className='absolute right-2 top-2 gap-y-1 overflow-hidden text-sm leading-6 text-white'>
                    <button
                      type='button'
                      onClick={() => {
                        setPortfolioItemCurrent(item)
                        setIsDeleteConfirmationOpen(true)
                      }}
                      className='rounded-full bg-gray-100 p-1 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-200'
                    >
                      <Icon
                        type={IconType.trash}
                        className='h-5 w-5 text-gray-700'
                      />
                    </button>
                  </div>
                )}
              </div>
            </div>
          ))}
        </VideoPlayerProvider>
      </ul>
      {pagination && (
        <PaginationComponent
          pagination={pagination}
          setPage={setPage}
          className='px-0 sm:px-0 mt-4'
        />
      )}
    </div>
  )
}
