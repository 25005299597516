import { FunctionComponent, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { PageTabs } from '../../../../components/PageTabs'
import { BrandRoutes } from '../../../BrandRoutes'
import { UserRole } from '../../../../gql/types'
import { useUserBrand } from '../../../../hooks'

const Header: FunctionComponent = () => {
  const { id } = useParams()
  const userBrand = useUserBrand(parseInt(id))
  const navigation = useMemo(() => {
    let list = [
      {
        text: 'Brand',
        count: null,
        to: BrandRoutes.brand.replace(':id', `${id}`),
      },
      {
        text: 'Team',
        count: null,
        to: BrandRoutes.team.replace(':id', `${id}`),
      },
      {
        text: 'Products',
        count: null,
        to: BrandRoutes.products.replace(':id', `${id}`),
      },
    ]

    if (
      userBrand &&
      userBrand?.roles &&
      userBrand?.roles?.includes(UserRole.owner)
    ) {
      list.splice(2, 0, {
        text: 'Invitations',
        count: null,
        to: BrandRoutes.invitations.replace(':id', `${id}`),
      })
      list.push(
        {
          text: 'Billing',
          count: null,
          to: BrandRoutes.billing.replace(':id', `${id}`),
        },
        {
          text: 'Credit Packs',
          count: null,
          to: BrandRoutes.creditPacks.replace(':id', `${id}`),
        },
      )
    }

    return list
  }, [id, userBrand])

  return (
    <div className='flex flex-col -mx-4 sm:-mx-6 md:mx-0 py-4'>
      <div className='flex flex-row'>
        <h1 className='font-bold text-3xl font-outfit'>Brand settings</h1>
      </div>

      {/* Navigation */}
      <PageTabs
        navigation={navigation}
        menuPosition='right'
        className='mt-4 sm:mt-0'
      />
    </div>
  )
}

export { Header }
