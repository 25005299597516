import { Link, useParams } from 'react-router-dom'
import { Button, ButtonStyle } from '../../../../components'
import { BrandRoutes } from '../../../BrandRoutes'

const Header = () => {
  const { id } = useParams()

  return (
    // <div className='sm:flex items-center -mx-4 sm:-mx-6 md:mx-0 py-4'>
    <div className='sm:flex -mx-4 sm:-mx-6 md:mx-0 py-4'>
      <div className='sm:w-0 sm:flex-1'>
        <h1 className='font-bold text-3xl font-outfit'>Inspiration</h1>
        <p className='mt-2 text-sm text-gray-500 max-w-2xl'>
          We suggest using our preselected inspiration videos, or adding your
          own, to illustrate to creators what you want for your campaign.
        </p>
      </div>

      <div className='mt-4 flex justify-between sm:ml-6 sm:mt-0 sm:flex-shrink-0 sm:justify-start'>
        <Link to={BrandRoutes.newInspiration.replace(':id', `${id}`)}>
          <Button
            styleType={ButtonStyle.fill}
            title='Add Inspiration Video'
            type='button'
            // icon={
            //   <Icon
            //     type={IconType.plusCircle}
            //     className='h-5 w-5 text-white'
            //     aria-hidden='true'
            //   />
            // }
            iconPosition='left'
          />
        </Link>
      </div>
    </div>
  )
}

export { Header }
