import {
  AddonPriceItem,
  AgeGroups,
  BrandType,
  CampaignAddon,
  CountryCode,
  CreatorQuality,
  Currency,
  ModelType,
  Platform,
  ProductType,
  VideoDuration,
  VideoType,
  VoiceType,
} from '../gql/types'
import moment from 'moment-timezone'
import { Icon, IconType } from '../components'
import {
  PricingData,
  formatPriceInCents,
  formattedVideoPrice,
} from './PricingUtil'

export const mapCountryCodeToFlagAndText = (countryCode: string) => {
  switch (countryCode) {
    case CountryCode.US:
      return '🇺🇸 United States'
    case CountryCode.GB:
      return '🇬🇧 United Kingdom'
    case CountryCode.AU:
      return '🇦🇺 Australia'
    default:
      return countryCode
  }
}

export const mapCountryCodeToFlag = (countryCode: string) => {
  switch (countryCode) {
    case CountryCode.US:
      return '🇺🇸'
    case CountryCode.GB:
      return '🇬🇧'
    case CountryCode.AU:
      return '🇦🇺'
    default:
      return countryCode
  }
}

export const mapFlagAndTextToCountryCode = (flagAndtext: string) => {
  switch (flagAndtext) {
    case '🇺🇸 United States':
      return CountryCode.US
    case '🇬🇧 United Kingdom':
      return CountryCode.GB
    case '🇦🇺 Australia':
      return CountryCode.AU
    default:
      return flagAndtext
  }
}

export const countryOptions = [
  mapCountryCodeToFlagAndText(CountryCode.US),
  mapCountryCodeToFlagAndText(CountryCode.GB),
  mapCountryCodeToFlagAndText(CountryCode.AU),
]

export const mapCurrencyToSign = (currency: Currency) => {
  switch (currency) {
    case Currency.USD:
      return '$'
    case Currency.GBP:
      return '£'
    case Currency.EUR:
      return '€'
    case Currency.AUD:
      return 'A$'
    default:
      return currency
  }
}

export const getAgeGroupByBirthday = (birthday: Date): AgeGroups => {
  const age = moment().diff(moment(birthday), 'years')
  if (age <= 24) {
    return AgeGroups.ageGroup1824
  }

  if (age >= 25 && age <= 34) {
    return AgeGroups.ageGroup2534
  }

  if (age >= 35 && age <= 44) {
    return AgeGroups.ageGroup3544
  }

  if (age > 44) {
    return AgeGroups.ageGroup45plus
  }
}

export const ageGroupsOptions = [
  {
    name: '18-24',
    type: AgeGroups.ageGroup1824,
    description: '',
  },
  {
    name: '25-34',
    type: AgeGroups.ageGroup2534,
    description: '',
  },
  {
    name: '35-44',
    type: AgeGroups.ageGroup3544,
    description: '',
  },
  {
    name: '45+',
    type: AgeGroups.ageGroup45plus,
    description: '',
  },
]

export const videoTypeOptions = [
  {
    name: 'Up to the creator',
    description: 'Our creators will send you their most creative takes',
    type: VideoType.upToTheCreator,
    icon: (
      <Icon
        type={IconType.sparkles}
        className='w-8 h-8 text-gray-600'
        aria-hidden='true'
      />
    ),
  },
  {
    name: 'Testimonial',
    description:
      'Honest statement about your product from a customer’s perspective',
    type: VideoType.testimonial,
    icon: (
      <Icon
        type={IconType.megaphone}
        className='w-8 h-8 text-gray-600'
        aria-hidden='true'
      />
    ),
  },
  {
    name: 'Unboxing',
    description:
      'Taking your product out of its original box and doing a short review',
    type: VideoType.unboxing,
    icon: (
      <Icon
        type={IconType.gift}
        className='w-8 h-8 text-gray-600'
        aria-hidden='true'
      />
    ),
  },
  {
    name: 'How to',
    description:
      'Creators will record themselves explaining how your product works',
    type: VideoType.howTo,
    icon: (
      <Icon
        type={IconType.academic}
        className='w-8 h-8 text-gray-600'
        aria-hidden='true'
      />
    ),
  },
]

export const productTypeOptions = [
  {
    name: 'Physical',
    description: 'Delivery required',
    type: ProductType.physical,
    icon: (
      <Icon
        type={IconType.cube}
        className='w-8 h-8 text-gray-600'
        aria-hidden='true'
      />
    ),
  },
  {
    name: 'Digital',
    description: 'Delivery not required',
    type: ProductType.digital,
    icon: (
      <Icon
        type={IconType.computer}
        className='w-8 h-8 text-gray-600'
        aria-hidden='true'
      />
    ),
  },
]

export const modelTypeOptions = [
  {
    name: 'Body & Face',
    description: 'Creators will film themselves using or showing your product',
    type: ModelType.bodyFace,
    icon: (
      <Icon
        type={IconType.framePerson}
        className='w-6 h-6 text-gray-600'
        aria-hidden='true'
      />
    ),
  },
  {
    name: 'Hands only',
    description: 'Close-up shot of the product in someone’s hands',
    type: ModelType.handsOnly,
    icon: (
      <Icon
        type={IconType.handRaised}
        className='w-6 h-6 text-gray-600'
        aria-hidden='true'
      />
    ),
  },
]

export const voiceTypeOptions = [
  {
    name: 'Voiceover',
    description: 'Creator will add a voiceover narration to your video.',
    type: VoiceType.overVoice,
    icon: (
      <Icon
        type={IconType.voiceOver}
        className='w-6 h-6 text-gray-600'
        aria-hidden='true'
      />
    ),
  },
  {
    name: 'No Voice',
    description: 'Creator will produce your video without any voiceover.',
    type: VoiceType.noVoice,
    icon: (
      <Icon
        type={IconType.noVoice}
        className='w-6 h-6 text-gray-600'
        aria-hidden='true'
      />
    ),
  },
]

export const platformTypeOptions = [
  {
    name: 'Instagram',
    description: 'Optimize video for the best engagement on Instagram',
    type: Platform.instagram,
    icon: (
      <Icon
        type={IconType.instagram}
        className='w-6 h-6 text-gray-600'
        aria-hidden='true'
      />
    ),
  },
  {
    name: 'Tiktok',
    description: 'Optimize video for the best engagement on Tiktok',
    type: Platform.tiktok,
    icon: (
      <Icon
        type={IconType.tiktok}
        className='w-6 h-6 text-gray-600'
        aria-hidden='true'
      />
    ),
  },
]

export const qualityOptions = (premiumPriceFormatted: string) => [
  {
    name: 'Regular creators',
    description: (
      <div>
        <p>
          Emerging creators launching their careers but with great video editing
          skills.
        </p>
        <p className='mt-4 font-bold'>INCLUDED</p>
      </div>
    ),
    type: CreatorQuality.regular,
    icon: (
      <Icon
        type={IconType.camera}
        className='w-6 h-6 text-gray-600'
        aria-hidden='true'
      />
    ),
  },
  {
    name: 'Premium creators',
    description: (
      <div>
        <p>
          A curated collection of highly-rated, skilled creators with a lot of
          experience.
        </p>
        <p className='mt-4 font-bold'>
          + {premiumPriceFormatted} <sub>/ each video</sub>
        </p>
      </div>
    ),
    type: CreatorQuality.premium,
    icon: (
      <Icon
        type={IconType.premium}
        className='w-6 h-6 text-amber-400'
        aria-hidden='true'
      />
    ),
  },
]

export const brandTypeOptions = [
  {
    name: 'Brand',
    // description: 'Brand',
    type: BrandType.brand,
    icon: (
      <Icon
        type={IconType.store}
        className='w-4 h-4 text-gray-600'
        aria-hidden='true'
      />
    ),
  },
  {
    name: 'Agency',
    // description: 'Agency',
    type: BrandType.agency,
    icon: (
      <Icon
        type={IconType.megaphone}
        className='w-4 h-4 text-gray-600'
        aria-hidden='true'
      />
    ),
  },
]

export const videoDurationOptions = ({
  currency,
  pricing,
  quality,
}: {
  currency: Currency
  pricing: PricingData
  quality?: CreatorQuality
}) => [
  {
    name: '15 seconds',
    description: formattedVideoPrice({
      videoDuration: VideoDuration.quarter,
      currency,
      pricing,
      quality,
    }),
    type: VideoDuration.quarter,
    icon: (
      <Icon
        type={IconType.clock}
        className='w-6 h-6 text-gray-600'
        aria-hidden='true'
      />
    ),
    save: null,
  },
  {
    name: '30 seconds',
    description: formattedVideoPrice({
      videoDuration: VideoDuration.half,
      currency,
      pricing,
      quality,
    }),
    type: VideoDuration.half,
    icon: (
      <Icon
        type={IconType.clock}
        className='w-6 h-6 text-gray-600'
        aria-hidden='true'
      />
    ),
    save: '30%',
  },
  {
    name: '60 seconds',
    description: formattedVideoPrice({
      videoDuration: VideoDuration.minute,
      currency,
      pricing,
      quality,
    }),
    type: VideoDuration.minute,
    icon: (
      <Icon
        type={IconType.clock}
        className='w-6 h-6 text-gray-600'
        aria-hidden='true'
      />
    ),
    save: '60%',
  },
]

export const addonsOptions = (addonsPrice?: any, currency?: Currency) => [
  {
    name: 'Captions',
    description: (
      <div>
        <p>The creator will add captions to the final version of the video.</p>
        <p className='font-bold mt-2'>
          +{' '}
          {formatPriceInCents({
            priceInCents: addonsPrice.find(
              (p) => p.addon === CampaignAddon.subtitles,
            ).priceCents,
            currency: currency,
          })}{' '}
          <sub>/ each video</sub>
        </p>
      </div>
    ),
    type: CampaignAddon.subtitles,
    icon: (
      <Icon
        type={IconType.subtitles}
        className='w-6 h-6 text-gray-600'
        aria-hidden='true'
      />
    ),
  },
  {
    name: 'Captions & Music',
    description: (
      <div>
        <p>
          The creator will add both captions and music to the final version of
          the video.
        </p>
        <p className='font-bold mt-2'>
          +{' '}
          {formatPriceInCents({
            priceInCents: addonsPrice.find(
              (p) => p.addon === CampaignAddon.subtitlesMusic,
            ).priceCents,
            currency: currency,
          })}{' '}
          <sub>/ each video</sub>
        </p>
      </div>
    ),
    type: CampaignAddon.subtitlesMusic,
    icon: (
      <Icon
        type={IconType.music}
        className='w-6 h-6 text-gray-600'
        aria-hidden='true'
      />
    ),
  },
]

export const mapVideoDurationToSeconds = (videoDuration: VideoDuration) => {
  switch (videoDuration) {
    case VideoDuration.half:
      return 30
    case VideoDuration.minute:
      return 60
    case VideoDuration.quarter:
      return 15
    default:
      return videoDuration
  }
}

export const mapCampaignAddonsTypeToText = (addon: CampaignAddon) => {
  switch (addon) {
    case CampaignAddon.subtitles:
      return 'Captions - an extra video file that includes captions'
    case CampaignAddon.subtitlesMusic:
      return 'Captions & Music - an extra video file that includes captions and music'
    default:
      return addon
  }
}

export const mapTextTypeToCampaignAddon = (text: string) => {
  switch (text) {
    case 'Captions - an extra video file that includes captions':
      return CampaignAddon.subtitles
    case 'Captions & Music - an extra video file that includes captions and music':
      return CampaignAddon.subtitlesMusic
    default:
      return text
  }
}
