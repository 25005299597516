import { Transition, Dialog } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { Icon, IconType, InspirationIndustry } from '..'
import { Divider } from '../Divider'
import { Campaign, Inspiration, InspirationType } from '../../gql/types'
import Skeleton from 'react-loading-skeleton'
import Masonry from 'react-masonry-css'
import { DownloadFileButton } from '../DownloadFileButton'
import { VideoZoom } from '../VideoZoom'
import { useQuery } from '@apollo/client'
import { GET_INSPIRATIONS_BY_CAMPAIGN_ID } from '../../gql'
import InfiniteScroll from 'react-infinite-scroller'
import { LIMIT_DEFAULT } from '../../utils/constants'

interface IProps {
  campaign: Campaign
  open: boolean
  setOpen: any
}

const InspirationsQuickView = ({ campaign, open, setOpen }: IProps) => {
  const [numCols, setNumCols] = useState(6)
  const [gridElement, setGridElement] = useState<HTMLDivElement | null>(null)

  const getNumColumns = (width: number) => {
    if (width >= 1536) return 6
    else if (width >= 1280) return 5
    else if (width >= 1024) return 4
    else if (width >= 768) return 3
    else return 2
  }

  const limit = LIMIT_DEFAULT
  const { loading, data, fetchMore } = useQuery(
    GET_INSPIRATIONS_BY_CAMPAIGN_ID,
    {
      fetchPolicy: 'network-only',
      variables: {
        campaignId: campaign.id,
        options: {
          query: '',
          filters: {
            campaignId: Number(campaign.id),
          },
          sort: null,
          limit,
          skip: 0,
        },
      },
      skip: !open,
    },
  )

  const fetchData = () => {
    if (loading) {
      return
    }

    // if we have all the data, don't fetch more
    if (
      data?.getInspirationsByCampaignId?.items?.length >=
      data?.getInspirationsByCampaignId?.pagination?.numberOfHits
    ) {
      return
    }

    fetchMore({
      variables: {
        campaignId: campaign.id,
        options: {
          query: '',
          filters: {
            campaignId: Number(campaign.id),
          },
          sort: null,
          limit,
          skip: data?.getInspirationsByCampaignId?.items?.length ?? 0,
        },
      },
    })
  }

  useEffect(() => {
    if (!gridElement) return

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const newNumCols = getNumColumns(entry.contentRect.width)
        setNumCols(newNumCols)
      }
    })

    resizeObserver.observe(gridElement)

    return () => {
      resizeObserver.disconnect()
    }
  }, [gridElement])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={setOpen}>
        <div className='fixed inset-0' />

        <div className='fixed inset-0 overflow-hidden'>
          <div className='absolute inset-0 overflow-hidden'>
            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16'>
              <Transition.Child
                as={Fragment}
                enter='transform transition ease-in-out duration-500 sm:duration-700'
                enterFrom='translate-x-full'
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-500 sm:duration-700'
                leaveFrom='translate-x-0'
                leaveTo='translate-x-full'
              >
                <Dialog.Panel className='pointer-events-auto w-screen max-w-2xl'>
                  <div className='flex h-full flex-col overflow-y-scroll bg-white shadow-xl'>
                    {/* Header */}
                    <div className='sm:sticky top-0 z-10 bg-white'>
                      <div className='px-4 pt-6 sm:px-6'>
                        <div className='flex items-start justify-between'>
                          <h3 className='text-xl font-bold text-gray-900 sm:text-2xl'>
                            Inspirations
                          </h3>
                          <div className='ml-3 flex h-7 items-center'>
                            <button
                              type='button'
                              className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none'
                              onClick={() => setOpen(false)}
                            >
                              <span className='sr-only'>Close panel</span>
                              <Icon
                                type={IconType.x}
                                className='h-6 w-6'
                                aria-hidden='true'
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <Divider className='py-6' />
                    </div>
                    {/* Main */}
                    <div className='pb-1 sm:pb-3 px-2 sm:px-4 space-y-2 mt-5 overflow-auto'>
                      <InfiniteScroll
                        loadMore={fetchData}
                        hasMore={
                          data?.getInspirationsByCampaignId?.items?.length <
                          data?.getInspirationsByCampaignId?.pagination
                            ?.numberOfHits
                        }
                        initialLoad={false}
                        useWindow={false}
                      >
                        <div ref={setGridElement}>
                          <Masonry
                            breakpointCols={numCols}
                            className='flex p-1 -ml-6 md:-ml-6 lg:-ml-8 xl:-ml-8 2xl:-ml-10'
                            columnClassName='pl-6 space-y-6 md:pl-6 md:space-y-6 lg:pl-8 lg:space-y-8 xl:pl-8 xl:space-y-8 2xl:pl-10 2xl:space-y-10 '
                          >
                            {data?.getInspirationsByCampaignId?.items?.map(
                              (inspiration: Inspiration, index) => (
                                <div
                                  key={index}
                                  className='shadow-2xl overflow-hidden rounded-xl ring-1 ring-black ring-opacity-20 w-full relative'
                                >
                                  {inspiration.type ===
                                    InspirationType.video && (
                                    <VideoZoom
                                      src={inspiration?.file?.url}
                                      thumbnailSrc={inspiration?.thumbnail?.url}
                                      className='cursor-pointer rounded-xl w-full object-cover hover:opacity-75'
                                    />
                                  )}
                                  {inspiration.industry && (
                                    <div className='absolute right-0 top-2 text-xs font-medium'>
                                      <InspirationIndustry
                                        industry={inspiration.industry}
                                      />
                                    </div>
                                  )}
                                  <div className='absolute right-2 bottom-3 gap-y-1 overflow-hidden text-sm leading-6 text-white'>
                                    <DownloadFileButton
                                      fileUrl={inspiration?.file?.url}
                                      fileName={inspiration?.file?.name}
                                    />
                                  </div>
                                </div>
                              ),
                            )}
                            {loading &&
                              [...Array(limit)].map((x, i) => (
                                <div key={i}>
                                  <Skeleton className='shadow-2xl overflow-hidden !rounded-xl ring-1 ring-black ring-opacity-20 w-full relative aspect-[9/16]' />
                                </div>
                              ))}
                          </Masonry>
                        </div>
                      </InfiniteScroll>
                      {data?.getClipsByBrandId?.items?.length >=
                        data?.getClipsByBrandId?.pagination?.numberOfHits && (
                        <div className='flex justify-center py-2'>
                          <span className='text-gray-500 text-sm'>
                            No more content to load
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export { InspirationsQuickView }
