import { useState } from 'react'
import {
  ConfirmationModal,
  DeleteModal,
  Icon,
  IconType,
  Loader,
  LoaderType,
  NotificationType,
  OrderClipModal,
} from '../../../../components'
import {
  Campaign,
  CampaignStatus,
  GetCampaignsSort,
  MutationCompleteCampaignArgs,
  MutationPauseCampaignArgs,
  MutationUnpauseCampaignArgs,
} from '../../../../gql/types'
import { useMutation } from '@apollo/client'
import {
  COMPLETE_CAMPAIGN,
  DELETE_CAMPAIGN,
  DUPLICATE_CAMPAIGN,
  GET_CAMPAIGNS_BY_BRAND_ID,
  PAUSE_CAMPAIGN,
  UNPAUSE_CAMPAIGN,
} from '../../../../gql'
import { useNotification } from '../../../../utils/hooks'
import moment from 'moment'
import { Link, useNavigate } from 'react-router-dom'
import { BrandRoutes } from '../../../../brands/BrandRoutes'

interface IProps {
  campaign: Campaign
  className?: string

  onCampaignUpdated?: () => void
}

interface IDefaultProps {}

const defaultProps: IDefaultProps = {}

interface IProps extends Partial<IDefaultProps> {
  campaign: Campaign
  onActionFinished?: () => void
}

const GridButtons = ({
  campaign,
  onActionFinished,
  className,
  onCampaignUpdated,
}: IProps) => {
  const { addNotification } = useNotification()
  const { innerWidth: screenWidth, innerHeight: screenHeight } = window

  const [isOrderClipOpen, setIsOrderClipOpen] = useState(false)
  const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false)
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false)
  const navigate = useNavigate()

  const [pauseCampaign, { loading: pauseCampaignLoading }] =
    useMutation<MutationPauseCampaignArgs>(PAUSE_CAMPAIGN, {
      variables: {
        id: campaign.id,
      },
      onCompleted: () => {
        onCampaignUpdated?.()
      },
    })

  const [unpauseCampaign, { loading: unpauseCampaignLoading }] =
    useMutation<MutationUnpauseCampaignArgs>(UNPAUSE_CAMPAIGN, {
      variables: {
        id: campaign.id,
      },
      onCompleted: () => {
        onCampaignUpdated?.()
      },
    })

  const [completeCampaign, { loading: completeCampaignLoading }] =
    useMutation<MutationCompleteCampaignArgs>(COMPLETE_CAMPAIGN, {
      variables: {
        id: campaign.id,
      },
      onCompleted: () => {
        onCampaignUpdated?.()
      },
    })

  const [deleteCampaign, { loading: deleteCampaignLoading }] = useMutation(
    DELETE_CAMPAIGN,
    {
      update(cache) {
        const normalizedId = cache.identify(campaign)
        cache.evict({ id: normalizedId })
        cache.gc()
      },
    },
  )

  const [duplicateCampaign, { loading: duplicateCampaignLoading }] =
    useMutation(DUPLICATE_CAMPAIGN, {
      refetchQueries: [
        {
          query: GET_CAMPAIGNS_BY_BRAND_ID,
          variables: {
            brandId: campaign.brand.id,
            options: {
              query: '',
              filters: {},
              sort: GetCampaignsSort.lastUpdated,
              skip: 0,
              limit: 20,
            },
          },
        },
      ],
    })

  const onEdit = () => {
    const campaignEditPath = BrandRoutes.campaignEdit
      .replace(':id', `${campaign.brand.id}`)
      .replace(':campaignId', `${campaign.id}`)
    // navigate(campaignEditPath)
    // navigate(0)
    window.location.href = campaignEditPath
  }

  const onDelete = async () => {
    try {
      await deleteCampaign({
        variables: {
          campaignId: campaign.id,
        },
      })

      addNotification(`Campaign deleted.`, NotificationType.success)
    } catch (error) {
      addNotification(`The campaign cannot be deleted.`, NotificationType.error)
    }
  }

  const onPause = async () => {
    try {
      await pauseCampaign()

      addNotification(
        `Campaign ${campaign.title} has been paused.`,
        NotificationType.success,
      )
    } catch (error) {
      console.log('error pausing campaign', error)
      addNotification(
        `Something went wrong. Please try again.`,
        NotificationType.error,
      )
    }
  }

  const onUnpause = async () => {
    try {
      await unpauseCampaign()

      addNotification(
        `Campaign ${campaign.title} has been unpaused.`,
        NotificationType.success,
      )
    } catch (error) {
      console.log('error unpausing campaign', error)
      addNotification(
        `Something went wrong. Please try again.`,
        NotificationType.error,
      )
    }
  }

  const onDuplicate = async () => {
    try {
      const { data } = await duplicateCampaign({
        variables: {
          id: campaign.id,
        },
      })

      addNotification(
        `Campaign '${campaign.title}' has been duplicated.`,
        NotificationType.success,
      )
      navigate(
        BrandRoutes.campaignEdit
          .replace(':id', `${campaign.brand.id}`)
          .replace(':campaignId', `${data.duplicateCampaign.id}`),
      )
    } catch (error) {
      console.log('error duplicate campaign', error)
      addNotification(
        `Something went wrong. Please try again.`,
        NotificationType.error,
      )
    }
  }

  const onComplete = async () => {
    try {
      await completeCampaign()

      addNotification(
        `Campaign ${campaign.title} is marked as completed.`,
        NotificationType.success,
      )
    } catch (error) {
      console.log('error completing campaign', error)
      addNotification(
        `Something went wrong. Please try again.`,
        NotificationType.error,
      )
    }
  }

  const getOptions = () => {
    const options = []

    // if campaign created less than 24h ago allow edits
    // only allow for active and paused campaigns
    if (
      (campaign.status === CampaignStatus.active ||
        campaign.status === CampaignStatus.paused) &&
      moment().diff(moment(campaign.purchasedAt), 'hours') < 24
    ) {
      options.push({
        name: 'Edit',
        icon: IconType.edit,
        onClick: () => onEdit(),
      })
    }

    switch (campaign.status) {
      case CampaignStatus.draft:
        options.push({
          name: 'Edit',
          icon: IconType.edit,
          onClick: () => onEdit(),
        })

        options.push({
          name: 'Delete',
          icon: IconType.trash,
          onClick: () => setIsDeleteConfirmationOpen(true),
        })

        break
      case CampaignStatus.completed:
        break
      case CampaignStatus.active:
        options.push({
          name: 'Buy more clips',
          icon: IconType.card,
          onClick: () => setIsOrderClipOpen(true),
        })

        options.push({
          name: 'Pause',
          icon: IconType.pause,
          onClick: () => onPause(),
        })

        options.push({
          name: 'Mark as completed',
          icon: IconType.check,
          onClick: () => setIsCompleteModalOpen(true),
        })
        break
      case CampaignStatus.paused:
        options.push({
          name: 'Unpause',
          icon: IconType.play,
          onClick: () => onUnpause(),
        })

        options.push({
          name: 'Mark as completed',
          icon: IconType.check,
          onClick: () => setIsCompleteModalOpen(true),
        })
        break
    }

    options.push({
      name: 'Duplicate',
      icon: IconType.duplicate,
      onClick: () => onDuplicate(),
    })

    return options
  }

  const options = getOptions()

  if (!options || options.length === 0) {
    return <></>
  }

  const loading =
    pauseCampaignLoading ||
    unpauseCampaignLoading ||
    completeCampaignLoading ||
    duplicateCampaignLoading

  if (loading) {
    return <Loader type={LoaderType.button} />
  }

  let countButtons = 0

  return (
    <>
      <div>
        <div className='-mt-px flex divide-x divide-gray-200'>
          {/* {options.map((item) => (
             countButtons<2 ? 
             <div className='-ml-px flex w-0 flex-1 justify-center'>
             <Link
               to={null}
               className='group flex items-center px-4 py-4 text-sm cursor-pointer text-gray-800'
               onClick={() => item.onClick?.()}
             >
               <Icon
                 type={item.icon}
                 className='mr-3 h-4 w-4 text-gray-400 group-hover:text-gray-500'
                 aria-hidden='true'
               />
                {item.name}
             </Link>
             <span className='sr-only'>{countButtons++}</span>
           </div>
            : null
          ))} */}
          {campaign.status === CampaignStatus.draft && countButtons < 2 ? (
            <div className='-ml-px flex w-0 flex-1 justify-center'>
              <Link
                to={null}
                className='group flex items-center px-4 py-4 text-sm cursor-pointer text-gray-800'
                onClick={() => onEdit()}
              >
                <Icon
                  type={IconType.edit}
                  className='mr-3 h-4 w-4 text-gray-400 group-hover:text-gray-500'
                  aria-hidden='true'
                />
                Edit
              </Link>
              <span className='sr-only'>{countButtons++}</span>
            </div>
          ) : (
            <div className='-ml-px flex w-0 flex-1 justify-center'>
              <Link
                to={null}
                className='group flex items-center px-4 py-4 text-sm cursor-pointer text-gray-800'
                onClick={() => onDuplicate()}
              >
                <Icon
                  type={IconType.duplicate}
                  className='mr-3 h-4 w-4 text-gray-400 group-hover:text-gray-500'
                  aria-hidden='true'
                />
                Duplicate
              </Link>
              <span className='sr-only'>{countButtons++}</span>
            </div>
          )}
          {campaign.status === CampaignStatus.active && countButtons < 2 && (
            <div className='-ml-px flex w-0 flex-1 justify-center cursor-pointer'>
              <Link
                to={null}
                className='group flex items-center px-4 py-4 text-sm text-gray-800'
                onClick={() => setIsOrderClipOpen(true)}
              >
                <Icon
                  type={IconType.card}
                  className='mr-3 h-4 w-4 text-gray-400 group-hover:text-gray-500'
                  aria-hidden='true'
                />
                Buy more clips
              </Link>
              <span className='sr-only'>{countButtons++}</span>
            </div>
          )}
          {campaign.status === CampaignStatus.paused && countButtons < 2 && (
            <div className='-ml-px flex w-0 flex-1 justify-center'>
              <Link
                to={null}
                className='group flex items-center px-4 py-4 text-sm cursor-pointer text-gray-800'
                onClick={() => onUnpause()}
              >
                <Icon
                  type={IconType.play}
                  className='mr-3 h-4 w-4 text-gray-400 group-hover:text-gray-500'
                  aria-hidden='true'
                />
                Unpause
              </Link>
              <span className='sr-only'>{countButtons++}</span>
            </div>
          )}
          {countButtons < 2 && (
            <div className='-ml-px flex w-0 flex-1 justify-center'>
              <Link
                to={null}
                className='group flex items-center px-4 py-4 text-sm cursor-pointer text-gray-800'
                onClick={() => onDuplicate()}
              >
                <Icon
                  type={IconType.duplicate}
                  className='mr-3 h-4 w-4 text-gray-400 group-hover:text-gray-500'
                  aria-hidden='true'
                />
                Duplicate
              </Link>
              <span className='sr-only'>{countButtons++}</span>
            </div>
          )}
        </div>
      </div>

      {isOrderClipOpen && (
        <OrderClipModal
          campaign={campaign}
          title={`Buy more Clips`}
          description={`Select the number of additional videos you would like and hit 'Confirm your order' below.`}
          open={isOrderClipOpen}
          setOpen={setIsOrderClipOpen}
        />
      )}

      <ConfirmationModal
        title='Confirm'
        description={`Are you sure you want to mark this campaign as completed? You'll not be able to accept anymore applicants or purchase on more Clips on this campaign. This action cannot be undone.`}
        open={isCompleteModalOpen}
        setOpen={setIsCompleteModalOpen}
        okButtonTitle='Mark as completed'
        onAction={() => onComplete()}
      />

      <DeleteModal
        title='Confirm'
        description='Are you sure you want to delete this campaign? All of your data will be permanently removed. This action cannot be undone.'
        open={isDeleteConfirmationOpen}
        setOpen={setIsDeleteConfirmationOpen}
        onDelete={onDelete}
      />
    </>
  )
}

GridButtons.defaultProps = defaultProps

export { GridButtons }
