import { useMutation, useQuery } from '@apollo/client'
import { loadStripe } from '@stripe/stripe-js'
import { useEffect, useState } from 'react'
import env from '../../../config/env'
import { UPDATE_BRAND } from '../../../gql'
import { Brand, BrandOnboardingStep, Campaign } from '../../../gql/types'
import {
  AnalyticsEvent,
  trackEvent,
  trackInitiateCheckout,
} from '../../../utils/analytics'
import { Confirmation } from './Confirmation'
import { Details } from './Details'
import { useNotification } from '../../../utils/hooks'
import { PricingData } from '../../../utils/PricingUtil'
import { useCreateCampaignCheckout } from '../../../hooks/payments'
import { Loader, NotificationType, ReviewsIO } from '../../../components'
import { ReviewItem, ReviewsCarousel } from './ReviewsCarousel'
import { UrlBuilder } from '../../../utils/UrlBuilder'
import { useNavigate } from 'react-router-dom'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(env.STRIPE_KEY)

export type EditBrandWebsiteFormData = {
  website: string
}

interface Props {
  brand: Brand
  campaign: Campaign
  pricing: PricingData
}

export const carouselReviews: ReviewItem[] = [
  {
    title: 'Would recommend to any brands looking for content for ads.',
    body: 'Clip took the hassle out of finding and reaching out to creators which can be a time-consuming process. The whole process from start to finish was seamless and we were able to connect with a creator of our choosing who delivered high-quality content very efficiently.',
    author: 'Jake Cottrill - Partnerships Manager at Yumi Nutrition',
    image:
      'https://clip-media-production.s3.eu-west-2.amazonaws.com/landing/customersProfilePic/jakeCotrill.jpeg',
  },
  {
    title: 'Great service and excellent!',
    body: "Plenty of applicants for the campaigns I've run, and the system works well.",
    author: 'Jack MacHugh - Managing Director at 50 Pound Social',
    image:
      'https://clip-media-production.s3.eu-west-2.amazonaws.com/landing/customersProfilePic/jack_m.png',
  },
  {
    title: "Overall, we've enjoyed our experience with CLIP!",
    body: 'I came across CLIP while searching for platforms to help with UGC content. The briefing includes details such as length you are looking for, face or product preference, and the process and platform were really useful. We purchased a number of packages, and every UGC creator involved had great communication and passionate to produce content that was on brand and also to the brief.',
    author: 'Hannah Cholerton - Senior Brand Executive at SkinGlo',
    image:
      'https://clip-media-production.s3.eu-west-2.amazonaws.com/landing/customersProfilePic/hannah_a.jpeg',
  },
  {
    title: "We're so grateful for your work.",
    body: "And we were we were really happy with the results of our UGC and experienced many top-performing videos for our marketing campaign. Can't wait to work with Clip Hub again! The Best Ads, The Best Team!",
    author: 'Darren Francey - Director of Sales at ONNOR',
    image:
      'https://clip-media-production.s3.eu-west-2.amazonaws.com/landing/customersProfilePic/darrenF.jpeg',
  },
  {
    title: 'I will be using Clip over and over again.',
    body: 'Wow this is amazing. I received several choices of talent in just a few days. The 30sec Clip went up on TikTok and in just 3 days nearly 50,000 views and 4,834 likes.',
    author: 'Paul Guyett - CEO at Leffler',
    image:
      'https://clip-media-production.s3.eu-west-2.amazonaws.com/landing/customersProfilePic/lefflerLogo.png',
  },
  {
    title: 'UGC content is the way forward',
    body: 'Maria understood our requirements amazingly and produced a great video for us leading to a higher conversions on our ads. Clip are making it so easy for brands to connect with great creators.',
    author: 'Adil Ghaffar - CEO at Jolli Nutrition',
    image:
      'https://clip-media-production.s3.eu-west-2.amazonaws.com/landing/customersProfilePic/adilG.png',
  },
  {
    title:
      'Our audience engagement has skyrocketed since we started using this app.',
    body: "People relate to and resonate with content created by their peers, and we've seen a significant increase in likes, comments, and shares across our digital platforms. It's also cost-effective: using UGC significantly reduces our content production cost.",
    author: 'Natasha Whiting - Co-Founder & CEO at Kollo Health',
    image:
      'https://clip-media-production.s3.eu-west-2.amazonaws.com/landing/customersProfilePic/natasha_whitling.jpeg',
  },
  {
    title: 'In summary, this UGC app has been a game-changer for our brand.',
    body: "It has simplified our content creation process and breathed new life into our marketing efforts. The brilliance of this app lies in its ability to harness the power of user-generated content, and we couldn't be happier with the results. If you're looking to elevate your brand's online presence, foster community, and boost engagement, this app is an absolute must-have. It's transformed our brand's life, and it can do the same for yours!",
    author: 'Manuel Benages - Co-Owner at Wellness by Manuel',
    image:
      'https://clip-media-production.s3.eu-west-2.amazonaws.com/landing/customersProfilePic/manuel.png',
  },
  {
    title: 'Definitely recommend it!',
    body: 'Excellent platform for businesses to collaborate with creators, quick, straightforward, and affordable.',
    author: 'Emily Turner - Founder & CEO at Eartha Underwear',
    image:
      'https://clip-media-production.s3.eu-west-2.amazonaws.com/landing/customersProfilePic/nikolaF.png',
  },
]

const SetOrderSummary = ({ brand, campaign, pricing }: Props) => {
  const { addNotification } = useNotification()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const [updateBrand] = useMutation(UPDATE_BRAND)

  const { createCampaignCheckout } = useCreateCampaignCheckout()

  const goBack = async () => {
    try {
      await updateBrand({
        variables: {
          id: brand.id,
          input: {
            onboarding: BrandOnboardingStep.setCreatorDetails,
          },
        },
      })
    } catch (error) {
      addNotification(error.message, NotificationType.error)
    }
  }

  const initiateCheckout = async (quantity: number) => {
    setLoading(true)

    try {
      trackInitiateCheckout()
    } catch (error) {
      console.log('Error tracking initiate checkout', error.message)
    }

    try {
      const { data } = await createCampaignCheckout({
        variables: {
          brandId: brand.id,
          input: {
            campaignId: campaign.id,
            quantity,
            successRedirectUrlPath: UrlBuilder.buildCampaignsUrl(brand.id),
            cancelRedirectUrlPath: UrlBuilder.buildCampaignEditUrl(
              brand.id,
              campaign.id,
            ),
          },
        },
      })

      // if not present, campaign created with credits
      if (!data.createCampaignCheckout.stripeSessionId) {
        navigate(UrlBuilder.buildCampaignsUrl(brand.id))
        addNotification('Campaign launched.', NotificationType.success)
      }

      // if stripe session id present - redirect to stripe
      if (data.createCampaignCheckout.stripeSessionId) {
        const stripe = await stripePromise

        // When the customer clicks on the button, redirect them to Checkout.
        const result = await stripe.redirectToCheckout({
          sessionId: data.createCampaignCheckout.stripeSessionId,
        })

        if (result.error) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
          addNotification(
            `Something went wrong. Please try again or contact support at hello@useclip.com`,
            NotificationType.error,
          )
        }
      }
    } catch (error) {
      addNotification(
        `Something went wrong. Please try again or contact support at hello@useclip.com`,
        NotificationType.error,
      )
    }

    setLoading(false)
  }

  useEffect(() => {
    trackEvent(AnalyticsEvent.viewBrandsOnboardingSetOrderSummary)
  }, [])

  useEffect(() => {
    const updateBrandOnboardingToCompleted = async () => {
      try {
        if (brand.creditCents > 0) {
          await updateBrand({
            variables: {
              id: brand.id,
              input: {
                onboarding: BrandOnboardingStep.completed,
              },
            },
          })
        }
      } catch (error) {
        addNotification(error.message, NotificationType.error)
      }
    }

    updateBrandOnboardingToCompleted()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brand])

  return (
    <div className='min-h-screen flex flex-col'>
      <div className='flex-grow'>
        <div className='min-h-full flex flex-col justify-center pt-4 px-4 lg:px-8'>
          <div className='mx-auto max-w-3xl grid grid-cols-1 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-8'>
            <Confirmation
              brand={brand}
              campaign={campaign}
              campaignHooks={campaign.hooks}
              pricing={pricing}
              completeOrder={initiateCheckout}
              loading={loading}
              goBack={goBack}
            />

            <Details brand={brand} campaign={campaign} />
          </div>
          {/* Reviews carousel */}
          <ReviewsIO type='ribbon' />
          <ReviewsCarousel reviews={carouselReviews} />
        </div>
      </div>
    </div>
  )
}

export { SetOrderSummary }
