export enum PublicRoutes {
  login = '/',
  registerBrand = '/register/brand',
  registerCreator = '/register/creator',
  registerTeamMember = '/register/team-member',
  verifyEmail = '/email/verify',
  // login and password reset
  forgotPassword = '/recover-password',
  resetPassword = '/reset-password',
}
