import { Campaign, CampaignStatus } from '../../../../gql/types'
import moment from 'moment-timezone'
import { Avatar } from '../../../../elements'
import {
  CampaignStatus as BrandCampaignStatus,
  IconType,
  Icon,
  Button,
  ButtonStyle,
  ButtonSize,
  ButtonColor,
  TooltipInfo,
} from '../../../../components'
import { GridButtons } from '../GridButtons'
import { isCampaignPremium } from '../../../../utils/hooks'
import { Link, useParams } from 'react-router-dom'
import { BrandRoutes } from '../../../BrandRoutes'
import { CampaignActionsDropdown } from '../../../shared/CampaignActionsDropdown'
import { mapCountryCodeToFlag } from '../../../../utils/mappers'
import { useState } from 'react'
import { InviteCreatorModal } from '../../InviteCreatorModal'

interface IProps {
  campaign: Campaign
}

const GridItem = ({ campaign }: IProps) => {
  const { id } = useParams()
  const [isInviteCreatorModalOpen, setIsInviteCreatorModalOpen] =
    useState(false)

  return (
    <div
      key={campaign.id}
      className='rounded-lg bg-white divide-y divide-gray-200 border border-gray-200 shadow mx-2'
    >
      <div className='cursor-pointer flex w-full items-center justify-between align-top space-x-4 p-4'>
        <div className='flex font-medium text-gray-900'>
          <Link
            to={
              campaign.status !== CampaignStatus.draft
                ? BrandRoutes.campaignView
                    .replace(':id', `${id}`)
                    .replace(':campaignId', `${campaign.id}`)
                : BrandRoutes.campaignEdit
                    .replace(':id', `${id}`)
                    .replace(':campaignId', `${campaign.id}`)
            }
            className='flex items-center gap-x-2'
          >
            <Avatar
              src={campaign?.product?.photo?.url}
              alt={campaign?.product?.name}
              size='xs'
              type='product'
              className='flex-none'
            />
            <div>
              <div className='max-w-[135px] sm:max-w-[250px] md:max-w-[300px] overflow-hidden truncate'>
                {campaign?.title}
              </div>

              <div className='flex text-sm text-gray-500'>
                {campaign?.product?.name}
                {/* <div className='ml-1 flex space-x-2'>
                  {mapCountryCodeToFlag(campaign.countryCode)}
                </div> */}
              </div>
            </div>
          </Link>

          <div className='ml-1 flex space-x-2'>
            {mapCountryCodeToFlag(campaign.countryCode)}
          </div>
        </div>

        <div className='flex items-center gap-x-2'>
          <CampaignActionsDropdown campaign={campaign} isGridItem={true} />
          <Button
            styleType={ButtonStyle.fill}
            // title='Invite creators'
            type='button'
            icon={
              <Icon
                type={IconType.personAdd}
                className='h-5 w-5 text-white'
                aria-hidden='true'
              />
            }
            size={ButtonSize.small}
            colour={ButtonColor.primary}
            onClick={() =>
              setIsInviteCreatorModalOpen(!isInviteCreatorModalOpen)
            }
            iconPosition='left'
            tooltip='Invite creators'
          />
        </div>
      </div>

      <dl className='space-y-3 p-4'>
        <div className='grid grid-cols-2 items-center'>
          <dt className='text-sm font-medium text-gray-500'>Status</dt>
          <dd className='-ml-2 text-sm text-gray-900'>
            <BrandCampaignStatus status={campaign.status} />
          </dd>
        </div>
        <div className='grid grid-cols-2 items-center'>
          <dt className='text-sm font-medium text-gray-500'>Applicants</dt>
          <dd className='text-sm text-gray-900'>
            <Link
              to={BrandRoutes.campaignApplicants
                .replace(':id', `${id}`)
                .replace(':campaignId', `${campaign.id}`)}
              className='font-medium text-gray-900 hover:text-gray-800'
            >
              <div className='flex gap-2 rounded-xl hover:bg-gray-200'>
                {campaign.applicantsCount}
                {isCampaignPremium(campaign) && (
                  <Icon
                    type={IconType.premium}
                    className='w-5 h-5 text-amber-400'
                    aria-hidden='true'
                  />
                )}
              </div>
            </Link>
          </dd>
        </div>
        <div className='grid grid-cols-2 items-center'>
          <dt className='text-sm font-medium text-gray-500'>Approved / Paid</dt>
          <dd className='text-sm text-gray-900'>
            <Link
              to={BrandRoutes.campaignCollaborators
                .replace(':id', `${id}`)
                .replace(':campaignId', `${campaign.id}`)}
              className='font-medium text-gray-900 hover:text-gray-800'
            >
              <div className='flex rounded-xl hover:bg-gray-200'>
                {`${campaign.collaboratorsCount} / ${campaign.videoQuantity}`}
              </div>
            </Link>
          </dd>
        </div>
        <div className='grid grid-cols-2 items-center'>
          <dt className='text-sm font-medium text-gray-500'>Created</dt>
          <dd className='text-sm text-gray-900'>
            {moment(campaign.createdAt).format('ll')}
          </dd>
        </div>
      </dl>
      <GridButtons campaign={campaign} />

      {isInviteCreatorModalOpen && (
        <InviteCreatorModal
          campaign={campaign}
          isOpen={isInviteCreatorModalOpen}
          setIsOpen={setIsInviteCreatorModalOpen}
        />
      )}
    </div>
  )
}

export { GridItem }
