import React from 'react'
import { VideoType } from '../../gql/types'
import { BoltIcon } from '@heroicons/react/24/solid'
import { Icon, IconType } from '../Icon'

export const componentsIcons: { [key: string]: any } = {
  button: BoltIcon,
}

export const videoTypesIcons: { [key in VideoType]: any } = {
  upToTheCreator: {
    icon: (
      <Icon
        type={IconType.sparkles}
        className='w-8 h-8 text-gray-600 fill-gray-100'
        aria-hidden='true'
      />
    ),
    iconClean: (
      <Icon
        type={IconType.sparkles}
        className='w-5 h-5 text-gray-400'
        aria-hidden='true'
      />
    ),
  },
  testimonial: {
    icon: (
      <Icon
        type={IconType.megaphone}
        className='w-8 h-8 text-gray-600 fill-gray-100'
        aria-hidden='true'
      />
    ),
    iconClean: (
      <Icon
        type={IconType.megaphone}
        className='w-5 h-5 text-gray-400'
        aria-hidden='true'
      />
    ),
  },
  unboxing: {
    icon: (
      <Icon
        type={IconType.gift}
        className='w-8 h-8 text-gray-600 fill-gray-100'
        aria-hidden='true'
      />
    ),
    iconClean: (
      <Icon
        type={IconType.gift}
        className='w-5 h-5 text-gray-400'
        aria-hidden='true'
      />
    ),
  },
  howTo: {
    icon: (
      <Icon
        type={IconType.academic}
        className='w-8 h-8 text-gray-600 fill-gray-100'
        aria-hidden='true'
      />
    ),
    iconClean: (
      <Icon
        type={IconType.academic}
        className='w-5 h-5 text-gray-400'
        aria-hidden='true'
      />
    ),
  },
}
