import { gql } from '@apollo/client'

export const PaginationFragment = gql`
  fragment PaginationFragment on Pagination {
    numberOfHits
    pageNumber
    hitsPerPage
    numberOfPages
  }
`
