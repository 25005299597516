import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import {
  Button,
  ButtonColor,
  ButtonStyle,
  CampaignDetailsBrief,
  Icon,
  IconType,
  StarRating,
} from '../../../components'
import { Divider } from '../../../components/Divider'
import { Avatar } from '../../../elements'
import { Campaign } from '../../../gql/types'
import { formatLongMessage } from '../../../utils/helpers'
import { useLocation, useParams } from 'react-router-dom'
import { CreatorRoutes } from '../../CreatorRoutes'

interface IProps {
  open: boolean
  setOpen: any
  campaign: Campaign

  openApplyToCampaignModal?: () => void
}

interface IBrandDetailsProps {
  campaign: Campaign
}

const BrandDetails = ({ campaign }: IBrandDetailsProps) => {
  return (
    <div className='@container'>
      <div className='grid @xl:grid-cols-4 @xl:gap-6 grid-cols-1 gap-2'>
        <div>
          <h3 className='text-base font-semibold leading-6 text-gray-900'>
            Brand
          </h3>
        </div>
        <div className='overflow-hidden bg-white shadow rounded-lg col-span-3'>
          <div className='border-t border-gray-200 px-4'>
            <dl className='@md:divide-y @md:divide-gray-200'>
              <div className='py-5 @md:grid @md:grid-cols-3 @md:gap-4'>
                <Avatar
                  type='brand'
                  size='md'
                  src={campaign.brand.logo?.url}
                  alt={campaign.brand.name}
                />
              </div>

              <div className='py-5 @md:grid @md:grid-cols-3 @md:gap-4'>
                <dt className='text-sm font-medium text-gray-500'>Name</dt>
                <dd className='mt-1 text-sm text-gray-900 @md:col-span-2 @md:mt-0'>
                  {campaign.brand.name}
                </dd>
              </div>

              <div className='py-5 @md:grid @md:grid-cols-3 @md:gap-4'>
                <dt className='text-sm font-medium text-gray-500'>Website</dt>
                <dd className='mt-1 text-sm text-gray-900 @md:col-span-2 @md:mt-0'>
                  <a
                    href={campaign.brand.website}
                    className='text-blue-600 hover:text-blue-500'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {campaign.brand.website}
                  </a>
                </dd>
              </div>

              <div className='py-5 @md:grid @md:grid-cols-3 @md:gap-4'>
                <dt className='text-sm font-medium text-gray-500'>Reviews</dt>
                <dd className='mt-1 text-sm text-gray-900 @md:col-span-2 @md:mt-0'>
                  <StarRating
                    ratingScore={campaign?.brand.metadata?.ratingsScore}
                    ratingCount={campaign?.brand?.metadata?.ratingsCount}
                  />
                </dd>
              </div>

              <div className='py-5 @md:grid @md:grid-cols-3 @md:gap-4'>
                <dt className='text-sm font-medium text-gray-500'>
                  Description
                </dt>
                <dd
                  className='mt-1 text-sm text-gray-900 @md:col-span-2 @md:mt-0'
                  dangerouslySetInnerHTML={{
                    __html: formatLongMessage(campaign.brand.description),
                  }}
                ></dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  )
}

const CampaignSlider = ({
  open,
  setOpen,
  campaign,
  openApplyToCampaignModal,
}: IProps) => {
  const { pathname } = useLocation()
  const { id } = useParams()

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={setOpen}>
        <div className='fixed inset-0' />

        <div className='fixed inset-0 overflow-hidden'>
          <div className='absolute inset-0 overflow-hidden'>
            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16'>
              <Transition.Child
                as={Fragment}
                enter='transform transition ease-in-out duration-500 sm:duration-700'
                enterFrom='translate-x-full'
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-500 sm:duration-700'
                leaveFrom='translate-x-0'
                leaveTo='translate-x-full'
              >
                <Dialog.Panel className='pointer-events-auto w-screen max-w-md'>
                  <div className='flex h-full flex-col overflow-y-scroll bg-white shadow-xl'>
                    {/* Header */}

                    <div className='sm:sticky top-0 z-10 bg-white'>
                      <div className='px-4 pt-6 sm:px-6'>
                        <div className='flex items-start justify-between'>
                          <span className='relative inline-block flex-shrink-0'>
                            <Avatar
                              type='product'
                              size='md'
                              src={campaign.product.photo?.url}
                              alt={campaign.product.name}
                            />
                          </span>
                          <div className='ml-3 flex h-7 items-center'>
                            <button
                              type='button'
                              className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none'
                              onClick={() => setOpen(false)}
                            >
                              <span className='sr-only'>Close panel</span>
                              <Icon
                                type={IconType.x}
                                className='h-6 w-6'
                                aria-hidden='true'
                              />
                            </button>
                          </div>
                        </div>
                        <h3 className='text-xl font-bold text-gray-900 mt-2'>
                          {campaign.product.name}
                        </h3>
                        {openApplyToCampaignModal && (
                          <div className='mt-2 flex flex-wrap font-bold'>
                            <Button
                              title={
                                pathname ===
                                CreatorRoutes.invitations.replace(
                                  ':id',
                                  `${id}`,
                                )
                                  ? 'Apply'
                                  : 'Apply'
                              }
                              type='button'
                              // loading={loading}
                              // icon={
                              //   <Icon
                              //     type={IconType.heart}
                              //     className='h-5 w-5 mr-3'
                              //     aria-hidden='true'
                              //   />
                              // }
                              // iconPosition='left'
                              styleType={ButtonStyle.fill}
                              colour={ButtonColor.primary}
                              className='flex-1 font-bold'
                              onClick={() => openApplyToCampaignModal()}
                            />
                          </div>
                        )}
                      </div>
                      <Divider className='py-6' />
                    </div>

                    {/* Main */}
                    <div className='pb-1 sm:pb-6 px-4 sm:px-6'>
                      <BrandDetails campaign={campaign} />
                      <Divider className='py-8' />
                      <CampaignDetailsBrief
                        campaign={campaign}
                        hooks={campaign?.hooks?.map((hook) => hook.description)}
                        viewMode='creator'
                      />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export { CampaignSlider }
