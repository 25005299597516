import React, { createContext, useContext, useState } from 'react'

interface VideoPlayerContextProps {
  playingVideo: HTMLVideoElement | null
  setPlayingVideo: (video: HTMLVideoElement | null) => void
}

const VideoPlayerContext = createContext<VideoPlayerContextProps>({
  playingVideo: null,
  setPlayingVideo: () => {},
})

export const useVideoPlayer = (): VideoPlayerContextProps => {
  return useContext(VideoPlayerContext)
}

interface VideoPlayerProviderProps {
  children: React.ReactNode
}

export const VideoPlayerProvider: React.FC<VideoPlayerProviderProps> = ({
  children,
}) => {
  const [playingVideo, setPlayingVideo] = useState<HTMLVideoElement | null>(
    null,
  )

  const value = {
    playingVideo,
    setPlayingVideo,
  }

  return (
    <VideoPlayerContext.Provider value={value}>
      {children}
    </VideoPlayerContext.Provider>
  )
}
