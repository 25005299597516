import { Fragment, useState } from 'react'
import {
  Creator,
  GetPortfolioSort,
  GetUploadUrlResponse,
  MutationCreatePortfolioItemArgs,
  MutationGetPortfolioItemUploadUrlArgs,
} from '../../../../gql/types'
import {
  Button,
  ButtonColor,
  NotificationType,
  VideoUpload,
} from '../../../../components'
import { Dialog, Transition } from '@headlessui/react'
import { useMutation } from '@apollo/client'
import {
  CREATE_PORTFOLIO_ITEM,
  GET_PORTFOLIO_BY_CREATOR,
  GET_PORTFOLIO_ITEM_UPLOAD_URL,
} from '../../../../gql'
import { useNotification } from '../../../../utils/hooks'
import axios from 'axios'

interface IProps {
  creator: Creator
  isOpen: boolean
  setIsOpen: (event: any) => void
  onPortfolioItemCreated?: (content: any) => void
}

export const CreatePortfolioItemModal = ({
  creator,
  isOpen,
  setIsOpen,
  onPortfolioItemCreated,
}: IProps) => {
  const { addNotification } = useNotification()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const [mime, setMime] = useState('')
  const [contentDimensions, setContentDimensions] = useState<{
    w: number | null
    h: number | null
  }>({ w: null, h: null })

  const [getPortfolioItemUploadUrl] = useMutation<
    { getPortfolioItemUploadUrl: GetUploadUrlResponse },
    MutationGetPortfolioItemUploadUrlArgs
  >(GET_PORTFOLIO_ITEM_UPLOAD_URL)

  const [createPortfolioItem] = useMutation<
    any,
    MutationCreatePortfolioItemArgs
  >(CREATE_PORTFOLIO_ITEM, {
    refetchQueries: [
      {
        query: GET_PORTFOLIO_BY_CREATOR,
        variables: {
          creatorId: creator.id,
          options: {
            limit: 12,
            skip: 0,
            sort: GetPortfolioSort.newest,
          },
        },
      },
    ],
  })

  const reset = () => {
    setSelectedFile(null)
    setMime('')
  }

  const onSubmit = async (event: React.MouseEvent) => {
    event.preventDefault()

    if (!selectedFile) {
      setError('Please upload a video')
      return
    }

    setError(null)
    setLoading(true)

    try {
      //make sure to call GET_CONTENT_UPLOAD_URL every time when uploading a new file
      const { data } = await getPortfolioItemUploadUrl({
        variables: {
          creatorId: creator.id,
          mime,
        },
      })

      // push content to S3
      await axios.put(data.getPortfolioItemUploadUrl.uploadUrl, selectedFile, {
        headers: {
          'Content-Type': mime,
        },
      })

      const res = await createPortfolioItem({
        variables: {
          creatorId: creator.id,
          input: {
            videoFileName: data.getPortfolioItemUploadUrl.fileName,
            videoWidth: contentDimensions.w,
            videoHeight: contentDimensions.h,
          },
        },
      })

      if (onPortfolioItemCreated && res?.data?.createPortfolioItem) {
        onPortfolioItemCreated(res.data.createPortfolioItem)
      }

      closeModal()
      addNotification(`Portfolio video uploaded`, NotificationType.success)
      reset()
    } catch (error) {
      console.log('Error Creating portfolio video', error.message)

      addNotification(
        `Something went wrong. Please try again.`,
        NotificationType.error,
      )
    }

    setLoading(false)
  }

  const handleFileInput = (
    file: File,
    size?: {
      w: number | null
      h: number | null
    },
  ) => {
    if (!file) {
      setSelectedFile(null)
      setMime('')
      return
    }

    setSelectedFile(file)
    setMime(file.type)
    setError(null)
    setContentDimensions(size)
  }

  const closeModal = () => {
    setIsOpen(false)
    reset()
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-25' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                <Dialog.Title
                  as='h3'
                  className='text-lg font-medium leading-6 text-gray-900'
                >
                  Upload portfolio video
                </Dialog.Title>

                <div>
                  <div className='mt-6'>
                    <VideoUpload onChange={handleFileInput} />
                    {error && (
                      <p className='text-red-500 text-sm mt-2'>{error}</p>
                    )}
                  </div>

                  <div className='mt-4 flex justify-between'>
                    <div className='py-2'>
                      <Button
                        type='button'
                        title='Cancel'
                        colour={ButtonColor.white}
                        onClick={closeModal}
                      />
                    </div>

                    <div className='py-2'>
                      <Button
                        type='button'
                        title='Upload'
                        loading={loading}
                        onClick={onSubmit}
                      />
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
