export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  StringOrBoolean: { input: any; output: any; }
};

export type GetUploadUrlResponse = {
  __typename?: 'GetUploadUrlResponse';
  uploadUrl: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
};

export type Pagination = {
  __typename?: 'Pagination';
  numberOfHits: Scalars['Int']['output'];
  pageNumber: Scalars['Int']['output'];
  hitsPerPage: Scalars['Int']['output'];
  numberOfPages: Scalars['Int']['output'];
};

export type UserBrand = {
  __typename?: 'UserBrand';
  id: Scalars['Int']['output'];
  uuid: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
  isDefaultContactUser: Scalars['Boolean']['output'];
  isActive: Scalars['Boolean']['output'];
  roles?: Maybe<Array<UserRole>>;
  brand: Brand;
  user: User;
};

export enum UserRole {
  owner = 'owner',
  advertiser = 'advertiser'
}

export type UserNotifications = {
  __typename?: 'UserNotifications';
  id: Scalars['Int']['output'];
  uuid: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
  isMarketingEnabled: Scalars['Boolean']['output'];
  isReviewsEnabled: Scalars['Boolean']['output'];
  isApplicantsReminderEnabled: Scalars['Boolean']['output'];
  isDailyDigestEnabled: Scalars['Boolean']['output'];
  user: User;
};

export type User = {
  __typename?: 'User';
  id: Scalars['Int']['output'];
  uuid: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
  type: UserType;
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  email: Scalars['String']['output'];
  isEmailConfirmed: Scalars['Boolean']['output'];
  countryCode: CountryCode;
  phoneNumber: Scalars['String']['output'];
  phoneNumberCountry: CountryCode;
  currency: Currency;
  timezone: Scalars['String']['output'];
  utcOffset: Scalars['Float']['output'];
  fbp: Scalars['String']['output'];
  fbc: Scalars['String']['output'];
  isAdmin: Scalars['Boolean']['output'];
  adminRoles?: Maybe<Array<AdminRole>>;
  creator?: Maybe<Creator>;
  bio?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Array<Note>>;
  reviewerClips?: Maybe<Array<Clip>>;
  reviewerCampaigns?: Maybe<Array<Campaign>>;
  notifications?: Maybe<UserNotifications>;
  deletedCollaborations?: Maybe<Array<Collaboration>>;
  avatar?: Maybe<Image>;
};

export enum UserType {
  agency = 'agency',
  brand = 'brand',
  creator = 'creator'
}

export enum CountryCode {
  AF = 'AF',
  AX = 'AX',
  AL = 'AL',
  DZ = 'DZ',
  AS = 'AS',
  AD = 'AD',
  AO = 'AO',
  AI = 'AI',
  AQ = 'AQ',
  AG = 'AG',
  AR = 'AR',
  AM = 'AM',
  AW = 'AW',
  AU = 'AU',
  AT = 'AT',
  AZ = 'AZ',
  BS = 'BS',
  BH = 'BH',
  BD = 'BD',
  BB = 'BB',
  BY = 'BY',
  BE = 'BE',
  BZ = 'BZ',
  BJ = 'BJ',
  BM = 'BM',
  BT = 'BT',
  BO = 'BO',
  BQ = 'BQ',
  BA = 'BA',
  BW = 'BW',
  BV = 'BV',
  BR = 'BR',
  IO = 'IO',
  UM = 'UM',
  VG = 'VG',
  VI = 'VI',
  BN = 'BN',
  BG = 'BG',
  BF = 'BF',
  BI = 'BI',
  KH = 'KH',
  CM = 'CM',
  CA = 'CA',
  CV = 'CV',
  KY = 'KY',
  CF = 'CF',
  TD = 'TD',
  CL = 'CL',
  CN = 'CN',
  CX = 'CX',
  CC = 'CC',
  CO = 'CO',
  KM = 'KM',
  CG = 'CG',
  CD = 'CD',
  CK = 'CK',
  CR = 'CR',
  HR = 'HR',
  CU = 'CU',
  CW = 'CW',
  CY = 'CY',
  CZ = 'CZ',
  DK = 'DK',
  DJ = 'DJ',
  DM = 'DM',
  DO = 'DO',
  EC = 'EC',
  EG = 'EG',
  SV = 'SV',
  GQ = 'GQ',
  ER = 'ER',
  EE = 'EE',
  ET = 'ET',
  FK = 'FK',
  FO = 'FO',
  FJ = 'FJ',
  FI = 'FI',
  FR = 'FR',
  GF = 'GF',
  PF = 'PF',
  TF = 'TF',
  GA = 'GA',
  GM = 'GM',
  GE = 'GE',
  DE = 'DE',
  GH = 'GH',
  GI = 'GI',
  GR = 'GR',
  GL = 'GL',
  GD = 'GD',
  GP = 'GP',
  GU = 'GU',
  GT = 'GT',
  GG = 'GG',
  GN = 'GN',
  GW = 'GW',
  GY = 'GY',
  HT = 'HT',
  HM = 'HM',
  VA = 'VA',
  HN = 'HN',
  HK = 'HK',
  HU = 'HU',
  IS = 'IS',
  IN = 'IN',
  ID = 'ID',
  CI = 'CI',
  IC = 'IC',
  IR = 'IR',
  IQ = 'IQ',
  IE = 'IE',
  IM = 'IM',
  IL = 'IL',
  IT = 'IT',
  JM = 'JM',
  JP = 'JP',
  JE = 'JE',
  JO = 'JO',
  KZ = 'KZ',
  KE = 'KE',
  KI = 'KI',
  KW = 'KW',
  KG = 'KG',
  LA = 'LA',
  LV = 'LV',
  LB = 'LB',
  LS = 'LS',
  LR = 'LR',
  LY = 'LY',
  LI = 'LI',
  LT = 'LT',
  LU = 'LU',
  MO = 'MO',
  MK = 'MK',
  MG = 'MG',
  MW = 'MW',
  MY = 'MY',
  MV = 'MV',
  ML = 'ML',
  MT = 'MT',
  MH = 'MH',
  MQ = 'MQ',
  MR = 'MR',
  MU = 'MU',
  YT = 'YT',
  MX = 'MX',
  FM = 'FM',
  MD = 'MD',
  MC = 'MC',
  MN = 'MN',
  ME = 'ME',
  MS = 'MS',
  MA = 'MA',
  MZ = 'MZ',
  MM = 'MM',
  NA = 'NA',
  NR = 'NR',
  NP = 'NP',
  NL = 'NL',
  NC = 'NC',
  NZ = 'NZ',
  NI = 'NI',
  NE = 'NE',
  NG = 'NG',
  NU = 'NU',
  NF = 'NF',
  MP = 'MP',
  NO = 'NO',
  OM = 'OM',
  PK = 'PK',
  PW = 'PW',
  PS = 'PS',
  PA = 'PA',
  PG = 'PG',
  PY = 'PY',
  PE = 'PE',
  PH = 'PH',
  PN = 'PN',
  PL = 'PL',
  PT = 'PT',
  PR = 'PR',
  QA = 'QA',
  XK = 'XK',
  RE = 'RE',
  RO = 'RO',
  RU = 'RU',
  RW = 'RW',
  BL = 'BL',
  SH = 'SH',
  KN = 'KN',
  LC = 'LC',
  MF = 'MF',
  PM = 'PM',
  VC = 'VC',
  WS = 'WS',
  SM = 'SM',
  ST = 'ST',
  SA = 'SA',
  SN = 'SN',
  RS = 'RS',
  SC = 'SC',
  SL = 'SL',
  SG = 'SG',
  SX = 'SX',
  SK = 'SK',
  SI = 'SI',
  SB = 'SB',
  SO = 'SO',
  ZA = 'ZA',
  GS = 'GS',
  KR = 'KR',
  SS = 'SS',
  ES = 'ES',
  LK = 'LK',
  SD = 'SD',
  SR = 'SR',
  SJ = 'SJ',
  SZ = 'SZ',
  SE = 'SE',
  CH = 'CH',
  SY = 'SY',
  TW = 'TW',
  TJ = 'TJ',
  TZ = 'TZ',
  TH = 'TH',
  TL = 'TL',
  TG = 'TG',
  TK = 'TK',
  TO = 'TO',
  TT = 'TT',
  TN = 'TN',
  TR = 'TR',
  TM = 'TM',
  TC = 'TC',
  TV = 'TV',
  UG = 'UG',
  UA = 'UA',
  AE = 'AE',
  GB = 'GB',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VU = 'VU',
  VE = 'VE',
  VN = 'VN',
  WF = 'WF',
  EH = 'EH',
  YE = 'YE',
  ZM = 'ZM',
  ZW = 'ZW'
}

export enum Currency {
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
  AUD = 'AUD'
}

export enum AdminRole {
  superAdmin = 'superAdmin',
  reviewerAdmin = 'reviewerAdmin'
}

export type Note = {
  __typename?: 'Note';
  id: Scalars['Int']['output'];
  uuid: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
  type: NoteType;
  text: Scalars['String']['output'];
  owner: User;
  brand?: Maybe<Brand>;
  creator?: Maybe<Creator>;
  campaign?: Maybe<Campaign>;
  collaboration?: Maybe<Collaboration>;
};

export enum NoteType {
  admin = 'admin'
}

export type Payment = {
  __typename?: 'Payment';
  id: Scalars['Int']['output'];
  uuid: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
  amountTotalCents: Scalars['Float']['output'];
  amountSubtotalCents: Scalars['Float']['output'];
  amountDiscountCents?: Maybe<Scalars['Float']['output']>;
  amountShippingCents?: Maybe<Scalars['Float']['output']>;
  amountTaxCents?: Maybe<Scalars['Float']['output']>;
  currency: Currency;
  type: PaymentType;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  stripeIntentId?: Maybe<Scalars['String']['output']>;
  stripeStatus?: Maybe<Scalars['String']['output']>;
  stripeInvoiceId?: Maybe<Scalars['String']['output']>;
  creditPackType?: Maybe<CreditPackType>;
  videosQuantity?: Maybe<Scalars['Int']['output']>;
  videoDuration?: Maybe<VideoDuration>;
  isPremium?: Maybe<Scalars['Boolean']['output']>;
  amountPaidVideosCents?: Maybe<Scalars['Float']['output']>;
  hooksCount?: Maybe<Scalars['Int']['output']>;
  amountPaidHooksCents?: Maybe<Scalars['Float']['output']>;
  addonsCount?: Maybe<Scalars['Int']['output']>;
  amountPaidAddonsCents?: Maybe<Scalars['Float']['output']>;
  addons?: Maybe<Array<CampaignAddon>>;
  amountCreditsAppliedCents?: Maybe<Scalars['Int']['output']>;
  brand?: Maybe<Brand>;
  campaign?: Maybe<Campaign>;
  collaboration?: Maybe<Collaboration>;
};

export enum PaymentType {
  campaign = 'campaign',
  creditPack = 'creditPack',
  hooks = 'hooks',
  addons = 'addons'
}

export enum CreditPackType {
  starter = 'starter',
  pro = 'pro',
  premium = 'premium',
  ultimate = 'ultimate',
  key = 'key',
  growth = 'growth',
  promo = 'promo',
  starterLowTierChristmas = 'starterLowTierChristmas',
  starterMediumTierChristmas = 'starterMediumTierChristmas',
  starterHighTierChristmas = 'starterHighTierChristmas',
  scaleLowTierChristmas = 'scaleLowTierChristmas',
  scaleMediumTierChristmas = 'scaleMediumTierChristmas',
  scaleHighTierChristmas = 'scaleHighTierChristmas'
}

export enum VideoDuration {
  quarter = 'quarter',
  half = 'half',
  minute = 'minute'
}

export enum CampaignAddon {
  subtitles = 'subtitles',
  subtitlesMusic = 'subtitlesMusic'
}

export type Rating = {
  __typename?: 'Rating';
  id: Scalars['Int']['output'];
  uuid: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
  type: RatingType;
  stars: Scalars['Int']['output'];
  review?: Maybe<Scalars['String']['output']>;
  contentQualityRating?: Maybe<Scalars['Int']['output']>;
  professionalismRating?: Maybe<Scalars['Int']['output']>;
  communicationRating?: Maybe<Scalars['Int']['output']>;
  creativityRating?: Maybe<Scalars['Int']['output']>;
  timelinessRating?: Maybe<Scalars['Int']['output']>;
  adherenceToBriefRating?: Maybe<Scalars['Int']['output']>;
  brand: Brand;
  creator: Creator;
  collaboration: Collaboration;
};

export enum RatingType {
  brandToCreator = 'brandToCreator',
  creatorToBrand = 'creatorToBrand'
}

export type CollaborationAddon = {
  __typename?: 'CollaborationAddon';
  description: Scalars['String']['output'];
  clipId?: Maybe<Scalars['Int']['output']>;
  creatorPaymentStatus?: Maybe<CreatorPaymentStatus>;
  creatorEarningsCents?: Maybe<Scalars['Int']['output']>;
};

export enum CreatorPaymentStatus {
  pending = 'pending',
  paid = 'paid',
  unpayable = 'unpayable'
}

export type CollaborationHook = {
  __typename?: 'CollaborationHook';
  description: Scalars['String']['output'];
  clipId?: Maybe<Scalars['Int']['output']>;
  creatorPaymentStatus?: Maybe<CreatorPaymentStatus>;
  creatorEarningsCents?: Maybe<Scalars['Int']['output']>;
};

export type CollaborationMetadata = {
  __typename?: 'CollaborationMetadata';
  id: Scalars['Int']['output'];
  uuid: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
  isProductDelivered: Scalars['Boolean']['output'];
  creatorAcceptedAt?: Maybe<Scalars['DateTime']['output']>;
  productShippedAt?: Maybe<Scalars['DateTime']['output']>;
  productDeliveredAt?: Maybe<Scalars['DateTime']['output']>;
  contentUploadedAt?: Maybe<Scalars['DateTime']['output']>;
  contentAcceptedAt?: Maybe<Scalars['DateTime']['output']>;
  isCreatorAcceptedEmailSent: Scalars['Boolean']['output'];
  productShippedEmailsCount: Scalars['Int']['output'];
  pendingContentEmailsToCreatorCount: Scalars['Int']['output'];
  pendingContentEmailsToBrandCount: Scalars['Int']['output'];
};

export type CollaborationStatusHistory = {
  __typename?: 'CollaborationStatusHistory';
  id: Scalars['Int']['output'];
  uuid: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
  status: CollaborationStatus;
};

export enum CollaborationStatus {
  creatorApplied = 'creatorApplied',
  creatorAccepted = 'creatorAccepted',
  productShipped = 'productShipped',
  productDelivered = 'productDelivered',
  contentUploaded = 'contentUploaded',
  contentAccepted = 'contentAccepted',
  contentRejected = 'contentRejected'
}

export type Collaboration = {
  __typename?: 'Collaboration';
  id: Scalars['Int']['output'];
  uuid: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
  status: CollaborationStatus;
  creatorApplicationMessage?: Maybe<Scalars['String']['output']>;
  applicationMessageCheckedStatus: MessageCheckedStatus;
  productTrackingLink?: Maybe<Scalars['String']['output']>;
  creatorPaymentStatus?: Maybe<CreatorPaymentStatus>;
  creatorEarningsCents?: Maybe<Scalars['Int']['output']>;
  creatorEarningsCurrency?: Maybe<Currency>;
  creator: Creator;
  campaign: Campaign;
  editsRequestMessage?: Maybe<Scalars['String']['output']>;
  clips: Array<Clip>;
  statusHistory: Array<CollaborationStatusHistory>;
  ratings?: Maybe<Array<Rating>>;
  firstVersionClip?: Maybe<Clip>;
  editedVersionClip?: Maybe<Clip>;
  metadata: CollaborationMetadata;
  notes?: Maybe<Array<Note>>;
  hooks?: Maybe<Array<CollaborationHook>>;
  addons?: Maybe<Array<CollaborationAddon>>;
  isFavorite: Scalars['Boolean']['output'];
  payments: Array<Payment>;
  deletedBy?: Maybe<User>;
  totalCreatorEarningsCents: Scalars['Int']['output'];
  pendingCreatorEarningsCents: Scalars['Int']['output'];
  paidCreatorEarningsCents: Scalars['Int']['output'];
  hooksCount: Scalars['Int']['output'];
  uploadedHooksCount: Scalars['Int']['output'];
  pendingUploadHooksCount: Scalars['Int']['output'];
  pendingUploadAddonsCount: Scalars['Int']['output'];
  addonsCount: Scalars['Int']['output'];
  uploadedAddonsCount: Scalars['Int']['output'];
  isActive: Scalars['Int']['output'];
};

export enum MessageCheckedStatus {
  pending = 'pending',
  ok = 'ok',
  flagged = 'flagged',
  info = 'info'
}

export type CreatorMetadata = {
  __typename?: 'CreatorMetadata';
  id: Scalars['Int']['output'];
  uuid: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
  ratingsScore?: Maybe<Scalars['Float']['output']>;
  ratingsCount: Scalars['Int']['output'];
  warningsCount: Scalars['Int']['output'];
  approvedAt?: Maybe<Scalars['DateTime']['output']>;
  rejectedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CampaignInvitation = {
  __typename?: 'CampaignInvitation';
  id: Scalars['Int']['output'];
  uuid: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
  status: CampaignInvitationStatus;
  campaign: Campaign;
  creator: Creator;
  expirationDate: Scalars['Int']['output'];
};

export enum CampaignInvitationStatus {
  invited = 'invited',
  accepted = 'accepted',
  canceled = 'canceled'
}

export type Tracking = {
  __typename?: 'Tracking';
  id: Scalars['Int']['output'];
  uuid: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
  source?: Maybe<Scalars['String']['output']>;
  medium?: Maybe<Scalars['String']['output']>;
  campaign?: Maybe<Scalars['String']['output']>;
  term?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
};

export type Creator = {
  __typename?: 'Creator';
  id: Scalars['Int']['output'];
  uuid: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  status: CreatorStatus;
  onboarding: CreatorOnboardingStep;
  gender?: Maybe<Gender>;
  ethnicity?: Maybe<Ethnicity>;
  occupation?: Maybe<Scalars['String']['output']>;
  birthday?: Maybe<Scalars['DateTime']['output']>;
  interests?: Maybe<Array<CreatorInterests>>;
  pausedReason?: Maybe<CreatorPausedReason>;
  pausedDescription?: Maybe<Scalars['String']['output']>;
  qualityScore?: Maybe<Scalars['Int']['output']>;
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  addressCity?: Maybe<Scalars['String']['output']>;
  addressCounty?: Maybe<Scalars['String']['output']>;
  addressState?: Maybe<Scalars['String']['output']>;
  addressPostcode?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<CountryCode>;
  avatarFileName?: Maybe<Scalars['String']['output']>;
  introVideoFileName?: Maybe<Scalars['String']['output']>;
  thumbnailFileName?: Maybe<Scalars['String']['output']>;
  introVideoWidth?: Maybe<Scalars['Int']['output']>;
  introVideoHeight?: Maybe<Scalars['Int']['output']>;
  paypalEmail?: Maybe<Scalars['String']['output']>;
  collaborations: Array<Collaboration>;
  clips: Array<Clip>;
  bio?: Maybe<Scalars['String']['output']>;
  currency: Currency;
  unreadMessagesCount: Scalars['Int']['output'];
  isNeverPause: Scalars['Boolean']['output'];
  metadata: CreatorMetadata;
  notes?: Maybe<Array<Note>>;
  phoneNumber: Scalars['String']['output'];
  tracking?: Maybe<Tracking>;
  isPremium?: Maybe<Scalars['Boolean']['output']>;
  avatar?: Maybe<Image>;
  introVideo?: Maybe<Video>;
  thumbnail?: Maybe<Image>;
};

export enum CreatorStatus {
  draft = 'draft',
  pending = 'pending',
  active = 'active',
  paused = 'paused',
  rejected = 'rejected',
  shadowBanned = 'shadowBanned'
}

export enum CreatorOnboardingStep {
  setProfile = 'setProfile',
  setAbout = 'setAbout',
  setInterests = 'setInterests',
  setIntroVideo = 'setIntroVideo',
  setPortfolio = 'setPortfolio',
  setShippingAddress = 'setShippingAddress',
  setPaypalEmailAddress = 'setPaypalEmailAddress',
  agreeTerms = 'agreeTerms',
  completed = 'completed'
}

export enum Gender {
  male = 'male',
  female = 'female',
  other = 'other'
}

export enum Ethnicity {
  asian = 'asian',
  black = 'black',
  hispanic = 'hispanic',
  indian = 'indian',
  white = 'white',
  other = 'other'
}

export enum CreatorInterests {
  art = 'art',
  baby = 'baby',
  beauty = 'beauty',
  business = 'business',
  coffee = 'coffee',
  craft = 'craft',
  design = 'design',
  drinks = 'drinks',
  DIY = 'DIY',
  entertainment = 'entertainment',
  erotic = 'erotic',
  fitness = 'fitness',
  food = 'food',
  family = 'family',
  fashion = 'fashion',
  finance = 'finance',
  fundraising = 'fundraising',
  gifts = 'gifts',
  green = 'green',
  hair = 'hair',
  health = 'health',
  hiking = 'hiking',
  home = 'home',
  interiors = 'interiors',
  investments = 'investments',
  jewellery = 'jewellery',
  juice = 'juice',
  lifestyle = 'lifestyle',
  luxury = 'luxury',
  marine = 'marine',
  makeup = 'makeup',
  movies = 'movies',
  mountain = 'mountain',
  music = 'music',
  natural = 'natural',
  organic = 'organic',
  outdoors = 'outdoors',
  parenting = 'parenting',
  pet = 'pet',
  photography = 'photography',
  plants = 'plants',
  plant = 'plant',
  premium = 'premium',
  print = 'print',
  affordable = 'affordable',
  snack = 'snack',
  sewing = 'sewing',
  skincare = 'skincare',
  sustainability = 'sustainability',
  technology = 'technology',
  traditional = 'traditional',
  travel = 'travel',
  cycling = 'cycling',
  innovative = 'innovative',
  vegan = 'vegan',
  watercraft = 'watercraft',
  wellness = 'wellness',
  yoga = 'yoga'
}

export enum CreatorPausedReason {
  adminRequested = 'adminRequested',
  pendingContent = 'pendingContent',
  lowQualityContent = 'lowQualityContent',
  creatorRequested = 'creatorRequested'
}

export type PortfolioItem = {
  __typename?: 'PortfolioItem';
  id: Scalars['Int']['output'];
  uuid: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
  type: PortfolioItemType;
  thumbnailFileName?: Maybe<Scalars['String']['output']>;
  videoWidth?: Maybe<Scalars['Int']['output']>;
  videoHeight?: Maybe<Scalars['Int']['output']>;
  creator: Creator;
  clip?: Maybe<Clip>;
  video: Video;
  thumbnail?: Maybe<Image>;
};

export enum PortfolioItemType {
  video = 'video',
  photo = 'photo'
}

export type Clip = {
  __typename?: 'Clip';
  id: Scalars['Int']['output'];
  uuid: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
  type?: Maybe<ClipType>;
  thumbnailFileName?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  qualityScore?: Maybe<Scalars['Int']['output']>;
  category?: Maybe<Category>;
  creator: Creator;
  campaign: Campaign;
  collaboration: Collaboration;
  portfolioItems: Array<PortfolioItem>;
  hook?: Maybe<Scalars['String']['output']>;
  addon?: Maybe<CampaignAddon>;
  reviewer?: Maybe<User>;
  reviewMessage?: Maybe<Scalars['String']['output']>;
  video: Video;
  thumbnail?: Maybe<Image>;
};

export enum ClipType {
  firstVersion = 'firstVersion',
  editedVersion = 'editedVersion',
  hook = 'hook',
  addon = 'addon',
  otherFile = 'otherFile',
  rawFile = 'rawFile'
}

export enum Category {
  fashion = 'fashion',
  consumerGoods = 'consumerGoods',
  homeware = 'homeware',
  wellness = 'wellness',
  skincare = 'skincare',
  jewellery = 'jewellery',
  food = 'food',
  beauty = 'beauty',
  fitness = 'fitness',
  kidsAndFamily = 'kidsAndFamily',
  pet = 'pet',
  hair = 'hair',
  makeup = 'makeup',
  outdoors = 'outdoors',
  essentials = 'essentials',
  electronics = 'electronics',
  software = 'software',
  services = 'services',
  gaming = 'gaming',
  fintech = 'fintech',
  other = 'other'
}

export type Inspiration = {
  __typename?: 'Inspiration';
  id: Scalars['Int']['output'];
  uuid: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
  type: InspirationType;
  url?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  externalUrl?: Maybe<Scalars['String']['output']>;
  brandName?: Maybe<Scalars['String']['output']>;
  videoType: VideoType;
  source?: Maybe<Platform>;
  productType?: Maybe<ProductType>;
  model: ModelType;
  gender?: Maybe<Gender>;
  ageGroups?: Maybe<AgeGroups>;
  ethnicity?: Maybe<Ethnicity>;
  duration: Scalars['Int']['output'];
  isTextOver: Scalars['Boolean']['output'];
  isVoiceOver: Scalars['Boolean']['output'];
  tags?: Maybe<Array<Scalars['String']['output']>>;
  industry?: Maybe<Industry>;
  isAllVisible: Scalars['Boolean']['output'];
  campaigns?: Maybe<Array<Campaign>>;
  brand?: Maybe<Brand>;
  file?: Maybe<Video>;
  thumbnail?: Maybe<Image>;
};

export enum InspirationType {
  video = 'video',
  photo = 'photo'
}

export enum VideoType {
  upToTheCreator = 'upToTheCreator',
  testimonial = 'testimonial',
  unboxing = 'unboxing',
  howTo = 'howTo'
}

export enum Platform {
  instagram = 'instagram',
  tiktok = 'tiktok'
}

export enum ProductType {
  physical = 'physical',
  digital = 'digital'
}

export enum ModelType {
  bodyFace = 'bodyFace',
  handsOnly = 'handsOnly'
}

export enum AgeGroups {
  ageGroup1824 = 'ageGroup1824',
  ageGroup2534 = 'ageGroup2534',
  ageGroup3544 = 'ageGroup3544',
  ageGroup45plus = 'ageGroup45plus'
}

export enum Industry {
  fashion = 'fashion',
  jewellery = 'jewellery',
  homeware = 'homeware',
  beauty = 'beauty',
  skincare = 'skincare',
  makeup = 'makeup',
  hair = 'hair',
  wellness = 'wellness',
  fitness = 'fitness',
  essentials = 'essentials',
  outdoors = 'outdoors',
  health = 'health',
  food = 'food',
  baby = 'baby',
  toys = 'toys',
  pet = 'pet',
  software = 'software',
  other = 'other'
}

export type Product = {
  __typename?: 'Product';
  id: Scalars['Int']['output'];
  uuid: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  type: ProductType;
  link?: Maybe<Scalars['String']['output']>;
  details?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  currency: Currency;
  photoFileName?: Maybe<Scalars['String']['output']>;
  brand: Brand;
  photo?: Maybe<Image>;
};

export type CampaignHook = {
  __typename?: 'CampaignHook';
  description: Scalars['String']['output'];
};

export type AddonPriceItem = {
  __typename?: 'AddonPriceItem';
  addon: Scalars['String']['output'];
  priceCents: Scalars['Float']['output'];
  creatorEarningsCents: Scalars['Float']['output'];
};

export type HookPriceItem = {
  __typename?: 'HookPriceItem';
  priceCents: Scalars['Float']['output'];
  creatorEarningsCents: Scalars['Float']['output'];
};

export type PriceItem = {
  __typename?: 'PriceItem';
  quality: Scalars['String']['output'];
  priceCents: Scalars['Float']['output'];
  creatorEarningsCents: Scalars['Float']['output'];
  extraPriceCents?: Maybe<Scalars['Float']['output']>;
  extraCreatorEarningsCents?: Maybe<Scalars['Float']['output']>;
};

export type VideoPricing = {
  __typename?: 'VideoPricing';
  videoDuration: VideoDuration;
  currency: Currency;
  price?: Maybe<Array<PriceItem>>;
  hook?: Maybe<HookPriceItem>;
  creatorQuickDeliveryCents: Scalars['Int']['output'];
  addons?: Maybe<Array<AddonPriceItem>>;
};

export type Campaign = {
  __typename?: 'Campaign';
  id: Scalars['Int']['output'];
  uuid: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
  title: Scalars['String']['output'];
  status: CampaignStatus;
  brand: Brand;
  videoType?: Maybe<VideoType>;
  modelType?: Maybe<ModelType>;
  voiceType?: Maybe<VoiceType>;
  videoDuration?: Maybe<VideoDuration>;
  requirements?: Maybe<Scalars['String']['output']>;
  platform?: Maybe<Platform>;
  modelGender?: Maybe<Scalars['String']['output']>;
  ethnicity?: Maybe<Ethnicity>;
  creatorPreferences?: Maybe<Scalars['String']['output']>;
  quality: CreatorQuality;
  ageGroups?: Maybe<Array<AgeGroups>>;
  applicantsCount: Scalars['Int']['output'];
  collaboratorsCount: Scalars['Int']['output'];
  clipsCount: Scalars['Int']['output'];
  videoQuantity: Scalars['Int']['output'];
  purchasedAt?: Maybe<Scalars['DateTime']['output']>;
  product: Product;
  collaborations: Array<Collaboration>;
  clips: Array<Clip>;
  countryCode?: Maybe<CountryCode>;
  currency: Currency;
  notes?: Maybe<Array<Note>>;
  hooks?: Maybe<Array<CampaignHook>>;
  inspirationVideosLinks?: Maybe<Array<Scalars['String']['output']>>;
  addons?: Maybe<Array<CampaignAddon>>;
  boostedAt?: Maybe<Scalars['DateTime']['output']>;
  creatorEarningsCentsBoosted?: Maybe<Scalars['Int']['output']>;
  reviewer?: Maybe<User>;
  payments: Array<Payment>;
  isPremium?: Maybe<Scalars['Boolean']['output']>;
  creatorPerMainVideoEarningsCents: Scalars['Int']['output'];
  totalCreatorEarningsCents: Scalars['Int']['output'];
  hooksCount: Scalars['Int']['output'];
  addonsCount: Scalars['Int']['output'];
  videoPricePerUnitCents: Scalars['Int']['output'];
  hookPricePerUnitCents: Scalars['Int']['output'];
};

export enum CampaignStatus {
  draft = 'draft',
  active = 'active',
  paused = 'paused',
  completed = 'completed'
}

export enum VoiceType {
  overVoice = 'overVoice',
  noVoice = 'noVoice'
}

export enum CreatorQuality {
  regular = 'regular',
  premium = 'premium'
}

export type Template = {
  __typename?: 'Template';
  id: Scalars['Int']['output'];
  uuid: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
  type: TemplateType;
  name?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  owner: User;
  brand?: Maybe<Brand>;
};

export enum TemplateType {
  admin = 'admin',
  brand = 'brand',
  creator = 'creator'
}

export type BrandMetadata = {
  __typename?: 'BrandMetadata';
  id: Scalars['Int']['output'];
  uuid: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
  ratingsScore?: Maybe<Scalars['Float']['output']>;
  ratingsCount: Scalars['Int']['output'];
  warningsCount: Scalars['Int']['output'];
};

export type Brand = {
  __typename?: 'Brand';
  id: Scalars['Int']['output'];
  uuid: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
  type: BrandType;
  name: Scalars['String']['output'];
  email: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  isPhoneNumberValidated?: Maybe<Scalars['Boolean']['output']>;
  onboarding: BrandOnboardingStep;
  website?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  businessType?: Maybe<BusinessType>;
  countryCode: CountryCode;
  currency: Currency;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  stripeSubscriptionId?: Maybe<Scalars['String']['output']>;
  unreadMessagesCount: Scalars['Int']['output'];
  metadata: BrandMetadata;
  inspirations?: Maybe<Array<Inspiration>>;
  notes?: Maybe<Array<Note>>;
  creditCents: Scalars['Int']['output'];
  isAutoApprovedMessages: Scalars['Boolean']['output'];
  klaviyoId?: Maybe<Scalars['String']['output']>;
  tracking?: Maybe<Tracking>;
  payments: Array<Payment>;
  logo?: Maybe<Image>;
};

export enum BrandType {
  brand = 'brand',
  agency = 'agency'
}

export enum BrandOnboardingStep {
  setWebsite = 'setWebsite',
  setDetails = 'setDetails',
  setProductDetails = 'setProductDetails',
  setCampaignDetails = 'setCampaignDetails',
  setVideoType = 'setVideoType',
  setModelType = 'setModelType',
  setVoiceType = 'setVoiceType',
  setPlatform = 'setPlatform',
  setVideoDuration = 'setVideoDuration',
  setRequirements = 'setRequirements',
  setAddons = 'setAddons',
  setHooks = 'setHooks',
  setCreatorDetails = 'setCreatorDetails',
  setOrderSummary = 'setOrderSummary',
  completed = 'completed'
}

export enum BusinessType {
  ecommerce = 'ecommerce',
  onlineServices = 'onlineServices',
  inPersonServices = 'inPersonServices',
  marketplace = 'marketplace',
  software = 'software',
  other = 'other'
}

export type TeamInvitation = {
  __typename?: 'TeamInvitation';
  id: Scalars['Int']['output'];
  uuid: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
  token: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  roles: Array<UserRole>;
  brand?: Maybe<Brand>;
  status: TeamInvitationStatus;
  expirationDate: Scalars['Int']['output'];
};

export enum TeamInvitationStatus {
  invited = 'invited',
  accepted = 'accepted',
  canceled = 'canceled'
}

export type GetTeamInvitationsResponse = {
  __typename?: 'GetTeamInvitationsResponse';
  items: Array<TeamInvitation>;
  pagination: Pagination;
};

export type GetUserBrandsResponse = {
  __typename?: 'GetUserBrandsResponse';
  items: Array<UserBrand>;
  pagination: Pagination;
};

export type KlaviyoLocation = {
  __typename?: 'KlaviyoLocation';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  ip?: Maybe<Scalars['String']['output']>;
};

export type Image = {
  __typename?: 'Image';
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type GetBrandsResponse = {
  __typename?: 'GetBrandsResponse';
  items: Array<Brand>;
  pagination: Pagination;
};

export type GetWebsiteMetaResponse = {
  __typename?: 'GetWebsiteMetaResponse';
  website?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
};

export type GetCreatorsEmailsResponse = {
  __typename?: 'GetCreatorsEmailsResponse';
  emails: Array<Scalars['String']['output']>;
};

export type GetCreatorsResponse = {
  __typename?: 'GetCreatorsResponse';
  items: Array<Creator>;
  pagination: Pagination;
};

export type GetProductsResponse = {
  __typename?: 'GetProductsResponse';
  items: Array<Product>;
  pagination: Pagination;
};

export type GetCampaignsResponse = {
  __typename?: 'GetCampaignsResponse';
  items: Array<Campaign>;
  pagination: Pagination;
};

export type Session = {
  __typename?: 'Session';
  id: Scalars['Int']['output'];
  uuid: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
  token: Scalars['String']['output'];
  expiresAt: Scalars['DateTime']['output'];
  active: Scalars['Boolean']['output'];
  user: User;
};

export type CreateUserResponse = {
  __typename?: 'CreateUserResponse';
  user: User;
  session: Session;
};

export type GetUsersResponse = {
  __typename?: 'GetUsersResponse';
  items: Array<User>;
  pagination: Pagination;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  user: User;
  session: Session;
};

export type GetClipsResponse = {
  __typename?: 'GetClipsResponse';
  items: Array<Clip>;
  pagination: Pagination;
};

export type GetPortfolioResponse = {
  __typename?: 'GetPortfolioResponse';
  items: Array<PortfolioItem>;
  pagination: Pagination;
};

export type GetInspirationsResponse = {
  __typename?: 'GetInspirationsResponse';
  items: Array<Inspiration>;
  pagination: Pagination;
};

export type GetInspirationUploadUrlResponse = {
  __typename?: 'GetInspirationUploadUrlResponse';
  inspirationUploadUrl: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
};

export type Video = {
  __typename?: 'Video';
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type MessageThread = {
  __typename?: 'MessageThread';
  id: Scalars['Int']['output'];
  uuid: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
  brand: Brand;
  creator: Creator;
  brandUnreadMessagesCount: Scalars['Int']['output'];
  creatorUnreadMessagesCount: Scalars['Int']['output'];
  lastMessage?: Maybe<Scalars['String']['output']>;
  lastMessageSentAt?: Maybe<Scalars['DateTime']['output']>;
  status: ThreadStatus;
};

export enum ThreadStatus {
  active = 'active',
  archived = 'archived'
}

export type Message = {
  __typename?: 'Message';
  id: Scalars['Int']['output'];
  uuid: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
  thread: MessageThread;
  type: MessageType;
  message?: Maybe<Scalars['String']['output']>;
  checkedStatus: MessageCheckedStatus;
  adminMessage?: Maybe<Scalars['String']['output']>;
  isEditClipRequest: Scalars['Boolean']['output'];
};

export enum MessageType {
  brandToCreator = 'brandToCreator',
  creatorToBrand = 'creatorToBrand'
}

export type GetMessagesResponse = {
  __typename?: 'GetMessagesResponse';
  items: Array<Message>;
  pagination: Pagination;
};

export type GetMessagesThreadsResponse = {
  __typename?: 'GetMessagesThreadsResponse';
  items: Array<MessageThread>;
  pagination: Pagination;
};

export type GetCollaborationsResponse = {
  __typename?: 'GetCollaborationsResponse';
  items: Array<Collaboration>;
  pagination: Pagination;
};

export type CreatorEarnings = {
  __typename?: 'CreatorEarnings';
  totalEarningCents: Scalars['Int']['output'];
  totalPaymentCents: Scalars['Int']['output'];
  totalPendingPaymentCents: Scalars['Int']['output'];
  currency: Currency;
};

export type CreatorPayout = {
  __typename?: 'CreatorPayout';
  creator: Creator;
  totalPayoutCents: Scalars['Int']['output'];
  currency: Currency;
};

export type GetTemplatesResponse = {
  __typename?: 'GetTemplatesResponse';
  items: Array<Template>;
  pagination: Pagination;
};

export type GetCampaignInvitationsResponse = {
  __typename?: 'GetCampaignInvitationsResponse';
  items: Array<CampaignInvitation>;
  pagination: Pagination;
};

export type CreatorEarningsResponse = {
  __typename?: 'CreatorEarningsResponse';
  creator: Creator;
  earnings: Array<CreatorEarnings>;
};

export type GetCreatorsPayoutsResponse = {
  __typename?: 'GetCreatorsPayoutsResponse';
  items: Array<CreatorPayout>;
  pagination: Pagination;
};

export type GetNotesResponse = {
  __typename?: 'GetNotesResponse';
  items: Array<Note>;
  pagination: Pagination;
};

export type CreditPack = {
  __typename?: 'CreditPack';
  name: Scalars['String']['output'];
  type: CreditPackType;
  priceCents: Scalars['Int']['output'];
  bonusCents: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  mostPopular: Scalars['Boolean']['output'];
};

export type FeaturePacks = {
  __typename?: 'FeaturePacks';
  starter?: Maybe<Scalars['StringOrBoolean']['output']>;
  pro?: Maybe<Scalars['StringOrBoolean']['output']>;
  premium?: Maybe<Scalars['StringOrBoolean']['output']>;
  ultimate?: Maybe<Scalars['StringOrBoolean']['output']>;
  key?: Maybe<Scalars['StringOrBoolean']['output']>;
  growth?: Maybe<Scalars['StringOrBoolean']['output']>;
  promo?: Maybe<Scalars['StringOrBoolean']['output']>;
  starterLowTierChristmas?: Maybe<Scalars['StringOrBoolean']['output']>;
  starterMediumTierChristmas?: Maybe<Scalars['StringOrBoolean']['output']>;
  starterHighTierChristmas?: Maybe<Scalars['StringOrBoolean']['output']>;
  scaleLowTierChristmas?: Maybe<Scalars['StringOrBoolean']['output']>;
  scaleMediumTierChristmas?: Maybe<Scalars['StringOrBoolean']['output']>;
  scaleHighTierChristmas?: Maybe<Scalars['StringOrBoolean']['output']>;
};

export type CreditPackFeature = {
  __typename?: 'CreditPackFeature';
  name: Scalars['String']['output'];
  packs: FeaturePacks;
};

export type GetCreditPacksResponse = {
  __typename?: 'GetCreditPacksResponse';
  packs: Array<CreditPack>;
  features: Array<CreditPackFeature>;
};

export type DemoLead = {
  __typename?: 'DemoLead';
  id: Scalars['Int']['output'];
  uuid: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  email: Scalars['String']['output'];
  brandWebsite: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  brandType: BrandType;
  brandSize: Scalars['String']['output'];
  signedUp: Scalars['String']['output'];
  countryCode?: Maybe<CountryCode>;
  hearAboutUs: Scalars['String']['output'];
};

export type Payout = {
  __typename?: 'Payout';
  id: Scalars['Int']['output'];
  uuid: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
  creator: Creator;
  status: Scalars['String']['output'];
  paypalEmail: Scalars['String']['output'];
  totalPayoutCents: Scalars['Float']['output'];
  currency: Currency;
  paypalPayoutBatchId: Scalars['String']['output'];
  paypalPayoutItemId: Scalars['String']['output'];
  paypalSenderBatchId: Scalars['String']['output'];
};

export type GetDemoLeadsResponse = {
  __typename?: 'GetDemoLeadsResponse';
  items: Array<DemoLead>;
  pagination: Pagination;
};

export type ConfirmPaymentResponse = {
  __typename?: 'ConfirmPaymentResponse';
  transactionId: Scalars['String']['output'];
  brand: Brand;
  currency: Currency;
  priceCents: Scalars['Float']['output'];
};

export type CreateCampaignCheckoutResponse = {
  __typename?: 'CreateCampaignCheckoutResponse';
  stripeSessionId?: Maybe<Scalars['String']['output']>;
  brand: Brand;
  campaign: Campaign;
  creditsUsedCents: Scalars['Float']['output'];
};

export type CreateHooksCheckoutResponse = {
  __typename?: 'CreateHooksCheckoutResponse';
  stripeSessionId?: Maybe<Scalars['String']['output']>;
  brand: Brand;
  collaboration: Collaboration;
  creditsUsedCents: Scalars['Float']['output'];
};

export type Invoice = {
  __typename?: 'Invoice';
  id: Scalars['String']['output'];
  created: Scalars['Float']['output'];
  paidAt?: Maybe<Scalars['Float']['output']>;
  pdf?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  paidTotal: Scalars['Float']['output'];
  currency: Currency;
};

export type GetInvoicesResponse = {
  __typename?: 'GetInvoicesResponse';
  invoices: Array<Invoice>;
};

export type GetPricingResponse = {
  __typename?: 'GetPricingResponse';
  videoPricing: Array<VideoPricing>;
};

export type CreateAddonsCheckoutResponse = {
  __typename?: 'CreateAddonsCheckoutResponse';
  stripeSessionId?: Maybe<Scalars['String']['output']>;
  brand: Brand;
  collaboration: Collaboration;
  creditsUsedCents: Scalars['Float']['output'];
};

export type GetRatingsResponse = {
  __typename?: 'GetRatingsResponse';
  items: Array<Rating>;
  pagination: Pagination;
};

export type Query = {
  __typename?: 'Query';
  getSelf: User;
  getUsers: GetUsersResponse;
  getUserById: User;
  getUserByCreatorId: User;
  getUserBrand: UserBrand;
  getBrands: GetBrandsResponse;
  getUserBrands: GetBrandsResponse;
  getBrandById: Brand;
  getDefaultBrand: Brand;
  getCampaigns: GetCampaignsResponse;
  getCampaignsByBrandAndInspiration: GetCampaignsResponse;
  getCampaignsByBrandId: GetCampaignsResponse;
  getLatestCreatedCampaignByBrandId?: Maybe<Campaign>;
  getCampaignById: Campaign;
  getCreators: GetCreatorsResponse;
  getCreatorById: Creator;
  getCreatorsEmails: GetCreatorsEmailsResponse;
  getCreatorsByBrand: Array<Creator>;
  getProducts: GetProductsResponse;
  getProductsByBrandId: GetProductsResponse;
  getProductById: Product;
  getLatestCreatedProductByBrandId?: Maybe<Product>;
  getCollaborationsByCreatorId: GetCollaborationsResponse;
  getCollaborationsByBrandId: GetCollaborationsResponse;
  getCollaborationsByCampaignId: GetCollaborationsResponse;
  getCollaborationsByBrandAndCreator: GetCollaborationsResponse;
  getCreatorsPayouts: GetCreatorsPayoutsResponse;
  getCreatorEarningsByCreatorId: CreatorEarningsResponse;
  getCollaborations: GetCollaborationsResponse;
  getCollaborationByUuid: Collaboration;
  getNotes: GetNotesResponse;
  getInspirationById: Inspiration;
  getInspirations: GetInspirationsResponse;
  getInspirationsByBrandId: GetInspirationsResponse;
  getInspirationsByCampaignId: GetInspirationsResponse;
  getMessagesThreads: GetMessagesThreadsResponse;
  getMessageThreadByUuid: MessageThread;
  getMessages: GetMessagesResponse;
  getMessagesByThread: GetMessagesResponse;
  getTemplates: GetTemplatesResponse;
  getBrandTemplates: GetTemplatesResponse;
  getTeamMembersInvitations: GetTeamInvitationsResponse;
  getTeamMembers: GetUserBrandsResponse;
  getTeamInvitationByToken: TeamInvitation;
  getCampaignInvitations: GetCampaignInvitationsResponse;
  getCampaignInvitationsByCreatorId: GetCampaignInvitationsResponse;
  getClips: GetClipsResponse;
  getClipsByBrandId: GetClipsResponse;
  getClipsByCreatorId: GetClipsResponse;
  getClipsByBrandAndCreator: GetClipsResponse;
  getClipsByCampaignId: GetClipsResponse;
  getPortfolioByCreator: GetPortfolioResponse;
  getRatings: GetRatingsResponse;
  getCreditPacks: GetCreditPacksResponse;
  getBlackFridayPacks: GetCreditPacksResponse;
  getChristmasPacks: GetCreditPacksResponse;
  getDemoLeads: GetDemoLeadsResponse;
  getPricing: GetPricingResponse;
  getInvoicesByBrandId: GetInvoicesResponse;
};


export type QueryGetUsersArgs = {
  options: GetUsersOptions;
};


export type QueryGetUserByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetUserByCreatorIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetUserBrandArgs = {
  brandId: Scalars['Int']['input'];
};


export type QueryGetBrandsArgs = {
  options: GetBrandsOptions;
};


export type QueryGetUserBrandsArgs = {
  options: GetBrandsOptions;
};


export type QueryGetBrandByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetDefaultBrandArgs = {
  userId: Scalars['Int']['input'];
  brandId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetCampaignsArgs = {
  options: GetCampaignsOptions;
};


export type QueryGetCampaignsByBrandAndInspirationArgs = {
  brandId: Scalars['Int']['input'];
  inspirationId: Scalars['Int']['input'];
};


export type QueryGetCampaignsByBrandIdArgs = {
  brandId: Scalars['Int']['input'];
  options: GetCampaignsOptions;
};


export type QueryGetLatestCreatedCampaignByBrandIdArgs = {
  brandId: Scalars['Int']['input'];
};


export type QueryGetCampaignByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetCreatorsArgs = {
  options: GetCreatorsOptions;
};


export type QueryGetCreatorByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetCreatorsEmailsArgs = {
  options: GetCreatorsOptions;
};


export type QueryGetCreatorsByBrandArgs = {
  brandId: Scalars['Int']['input'];
};


export type QueryGetProductsArgs = {
  options: GetProductsOptions;
};


export type QueryGetProductsByBrandIdArgs = {
  brandId: Scalars['Int']['input'];
  options: GetProductsOptions;
};


export type QueryGetProductByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetLatestCreatedProductByBrandIdArgs = {
  brandId: Scalars['Int']['input'];
};


export type QueryGetCollaborationsByCreatorIdArgs = {
  creatorId: Scalars['Int']['input'];
  options: GetCollaborationsOptions;
};


export type QueryGetCollaborationsByBrandIdArgs = {
  brandId: Scalars['Int']['input'];
  options: GetCollaborationsOptions;
};


export type QueryGetCollaborationsByCampaignIdArgs = {
  campaignId: Scalars['Int']['input'];
  options: GetCollaborationsOptions;
};


export type QueryGetCollaborationsByBrandAndCreatorArgs = {
  brandId: Scalars['Int']['input'];
  creatorId: Scalars['Int']['input'];
  options: GetCollaborationsOptions;
};


export type QueryGetCreatorsPayoutsArgs = {
  options: GetCreatorsPayoutsOptions;
};


export type QueryGetCreatorEarningsByCreatorIdArgs = {
  creatorId: Scalars['Int']['input'];
};


export type QueryGetCollaborationsArgs = {
  options: GetCollaborationsOptions;
};


export type QueryGetCollaborationByUuidArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryGetNotesArgs = {
  options: GetNotesOptions;
};


export type QueryGetInspirationByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetInspirationsArgs = {
  options: GetInspirationsOptions;
};


export type QueryGetInspirationsByBrandIdArgs = {
  brandId: Scalars['Int']['input'];
  options: GetInspirationsOptions;
};


export type QueryGetInspirationsByCampaignIdArgs = {
  campaignId: Scalars['Int']['input'];
  options: GetInspirationsOptions;
};


export type QueryGetMessagesThreadsArgs = {
  options: GetMessagesThreadsOptions;
};


export type QueryGetMessageThreadByUuidArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryGetMessagesArgs = {
  options: GetMessagesOptions;
};


export type QueryGetMessagesByThreadArgs = {
  threadId: Scalars['Int']['input'];
  options: GetMessagesOptions;
};


export type QueryGetTemplatesArgs = {
  options: GetTemplatesOptions;
};


export type QueryGetBrandTemplatesArgs = {
  brandId: Scalars['Int']['input'];
  options: GetTemplatesOptions;
};


export type QueryGetTeamMembersInvitationsArgs = {
  options: GetTeamInvitationsOptions;
};


export type QueryGetTeamMembersArgs = {
  options: GetUserBrandsOptions;
};


export type QueryGetTeamInvitationByTokenArgs = {
  token: Scalars['String']['input'];
};


export type QueryGetCampaignInvitationsArgs = {
  options: GetCampaignInvitationsOptions;
};


export type QueryGetCampaignInvitationsByCreatorIdArgs = {
  creatorId: Scalars['Int']['input'];
  options: GetCampaignInvitationsOptions;
};


export type QueryGetClipsArgs = {
  options: GetClipsOptions;
};


export type QueryGetClipsByBrandIdArgs = {
  brandId: Scalars['Int']['input'];
  options: GetClipsOptions;
};


export type QueryGetClipsByCreatorIdArgs = {
  creatorId: Scalars['Int']['input'];
  options: GetClipsOptions;
};


export type QueryGetClipsByBrandAndCreatorArgs = {
  brandId: Scalars['Int']['input'];
  creatorId: Scalars['Int']['input'];
  options: GetClipsOptions;
};


export type QueryGetClipsByCampaignIdArgs = {
  campaignId: Scalars['Int']['input'];
  options: GetClipsOptions;
};


export type QueryGetPortfolioByCreatorArgs = {
  creatorId: Scalars['Int']['input'];
  options: GetPortfolioOptions;
};


export type QueryGetRatingsArgs = {
  options: GetRatingsOptions;
};


export type QueryGetCreditPacksArgs = {
  currency: Scalars['String']['input'];
};


export type QueryGetBlackFridayPacksArgs = {
  currency: Scalars['String']['input'];
};


export type QueryGetChristmasPacksArgs = {
  currency: Scalars['String']['input'];
};


export type QueryGetDemoLeadsArgs = {
  options: GetDemoLeadsOptions;
};


export type QueryGetInvoicesByBrandIdArgs = {
  brandId: Scalars['Int']['input'];
};

export type GetUsersOptions = {
  query?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<GetUsersFilters>;
  sort?: InputMaybe<GetUsersSort>;
  skip?: Scalars['Int']['input'];
  limit?: Scalars['Int']['input'];
};

export type GetUsersFilters = {
  type?: InputMaybe<Array<UserType>>;
};

export enum GetUsersSort {
  newest = 'newest',
  oldest = 'oldest',
  lastUpdated = 'lastUpdated'
}

export type GetBrandsOptions = {
  query?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<GetBrandsFilters>;
  sort?: InputMaybe<GetBrandsSort>;
  skip?: Scalars['Int']['input'];
  limit?: Scalars['Int']['input'];
};

export type GetBrandsFilters = {
  userIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  brandIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  businessType?: InputMaybe<Array<BusinessType>>;
  onboarding?: InputMaybe<Array<BrandOnboardingStep>>;
  countryCode?: InputMaybe<Array<CountryCode>>;
};

export enum GetBrandsSort {
  newest = 'newest',
  oldest = 'oldest',
  lastUpdated = 'lastUpdated'
}

export type GetCampaignsOptions = {
  query?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<GetCampaignsFilters>;
  sort?: InputMaybe<GetCampaignsSort>;
  skip?: Scalars['Int']['input'];
  limit?: Scalars['Int']['input'];
};

export type GetCampaignsFilters = {
  status?: InputMaybe<Array<CampaignStatus>>;
  countryCode?: InputMaybe<Array<CountryCode>>;
  modelGender?: InputMaybe<Array<Gender>>;
  ageGroups?: InputMaybe<Array<AgeGroups>>;
  ignoreCollaborationsWithCreatorIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  isIgnoreFilledCampaigns?: InputMaybe<Scalars['Boolean']['input']>;
  createdAfter?: InputMaybe<Scalars['DateTime']['input']>;
  createdBefore?: InputMaybe<Scalars['DateTime']['input']>;
  quality?: InputMaybe<Array<CreatorQuality>>;
  isCampaignStalled?: InputMaybe<Scalars['Boolean']['input']>;
  videoType?: InputMaybe<Array<VideoType>>;
  videoDuration?: InputMaybe<Array<VideoDuration>>;
  productType?: InputMaybe<Array<ProductType>>;
  isCampaignToBoost?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum GetCampaignsSort {
  newest = 'newest',
  oldest = 'oldest',
  lastUpdated = 'lastUpdated',
  lastPurchased = 'lastPurchased',
  oldestPurchased = 'oldestPurchased',
  lastBoosted = 'lastBoosted'
}

export type GetCreatorsOptions = {
  query?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<GetCreatorsFilters>;
  sort?: InputMaybe<GetCreatorsSort>;
  skip?: Scalars['Int']['input'];
  limit?: Scalars['Int']['input'];
};

export type GetCreatorsFilters = {
  creatorIdsToIgnore?: InputMaybe<Array<Scalars['Int']['input']>>;
  campaignIdsToIgnore?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryCode?: InputMaybe<Array<CountryCode>>;
  status?: InputMaybe<Array<CreatorStatus>>;
  gender?: InputMaybe<Array<Gender>>;
  ethnicity?: InputMaybe<Array<Ethnicity>>;
  ageGroups?: InputMaybe<Array<AgeGroups>>;
  onboarding?: InputMaybe<Array<CreatorOnboardingStep>>;
  qualityScore?: InputMaybe<Array<Scalars['Int']['input']>>;
  interests?: InputMaybe<Array<CreatorInterests>>;
};

export enum GetCreatorsSort {
  newest = 'newest',
  oldest = 'oldest',
  lastUpdated = 'lastUpdated',
  lastApproved = 'lastApproved',
  lastRejected = 'lastRejected'
}

export type GetProductsOptions = {
  query?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<GetProductsFilters>;
  sort?: InputMaybe<GetProductsSort>;
  skip?: Scalars['Int']['input'];
  limit?: Scalars['Int']['input'];
};

export type GetProductsFilters = {
  type?: InputMaybe<Array<ProductType>>;
};

export enum GetProductsSort {
  newest = 'newest',
  oldest = 'oldest',
  lastUpdated = 'lastUpdated'
}

export type GetCollaborationsOptions = {
  query?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<GetCollaborationsFilters>;
  sort?: InputMaybe<GetCollaborationsSort>;
  skip?: Scalars['Int']['input'];
  limit?: Scalars['Int']['input'];
};

export type GetCollaborationsFilters = {
  creatorId?: InputMaybe<Scalars['Float']['input']>;
  brandId?: InputMaybe<Scalars['Float']['input']>;
  campaignId?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<CollaborationStatus>>;
  campaignStatus?: InputMaybe<Array<CampaignStatus>>;
  applicationMessageCheckedStatus?: InputMaybe<Array<MessageCheckedStatus>>;
  productShippedAfter?: InputMaybe<Scalars['DateTime']['input']>;
  productShippedBefore?: InputMaybe<Scalars['DateTime']['input']>;
  productDeliveredAfter?: InputMaybe<Scalars['DateTime']['input']>;
  productDeliveredBefore?: InputMaybe<Scalars['DateTime']['input']>;
  contentUploadedAfter?: InputMaybe<Scalars['DateTime']['input']>;
  contentUploadedBefore?: InputMaybe<Scalars['DateTime']['input']>;
  isCreatorAcceptedEmailSent?: InputMaybe<Scalars['Boolean']['input']>;
  isProductDelivered?: InputMaybe<Scalars['Boolean']['input']>;
  isEditRequested?: InputMaybe<Scalars['Boolean']['input']>;
  quality?: InputMaybe<Array<CreatorQuality>>;
  creatorAcceptedBefore?: InputMaybe<Scalars['DateTime']['input']>;
  creatorAcceptedAfter?: InputMaybe<Scalars['DateTime']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  isCreatorActive?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum GetCollaborationsSort {
  newest = 'newest',
  oldest = 'oldest',
  lastUpdated = 'lastUpdated',
  newestCreatorAccepted = 'newestCreatorAccepted',
  oldestCreatorAccepted = 'oldestCreatorAccepted',
  newestProductShipped = 'newestProductShipped',
  oldestProductShipped = 'oldestProductShipped',
  newestProductDelivered = 'newestProductDelivered',
  oldestProductDelivered = 'oldestProductDelivered',
  applicantsQualityScore = 'applicantsQualityScore'
}

export type GetCreatorsPayoutsOptions = {
  query?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  limit?: Scalars['Int']['input'];
};

export type GetNotesOptions = {
  filters?: InputMaybe<GetNotesFilters>;
  sort?: InputMaybe<GetNotesSort>;
  skip?: Scalars['Int']['input'];
  limit?: Scalars['Int']['input'];
};

export type GetNotesFilters = {
  ownerUserId?: InputMaybe<Scalars['Int']['input']>;
  brandId?: InputMaybe<Scalars['Int']['input']>;
  creatorId?: InputMaybe<Scalars['Int']['input']>;
  campaignId?: InputMaybe<Scalars['Int']['input']>;
  collaborationId?: InputMaybe<Scalars['Int']['input']>;
};

export enum GetNotesSort {
  newest = 'newest',
  oldest = 'oldest'
}

export type GetInspirationsOptions = {
  query?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<GetInspirationsFilters>;
  sort?: InputMaybe<GetInspirationsSort>;
  skip?: Scalars['Int']['input'];
  limit?: Scalars['Int']['input'];
};

export type GetInspirationsFilters = {
  type?: InputMaybe<Array<InspirationType>>;
  brandId?: InputMaybe<Scalars['Float']['input']>;
  campaignId?: InputMaybe<Scalars['Float']['input']>;
  isAllVisible?: InputMaybe<Scalars['Boolean']['input']>;
  brandIdOrAllVisible?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum GetInspirationsSort {
  newest = 'newest',
  oldest = 'oldest',
  lastUpdated = 'lastUpdated'
}

export type GetMessagesThreadsOptions = {
  query?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<GetMessagesThreadsFilters>;
  sort?: InputMaybe<GetMessagesThreadsSort>;
  skip?: Scalars['Int']['input'];
  limit?: Scalars['Int']['input'];
};

export type GetMessagesThreadsFilters = {
  creatorId?: InputMaybe<Scalars['Int']['input']>;
  brandId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Array<ThreadStatus>>;
  isApplication?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum GetMessagesThreadsSort {
  newest = 'newest',
  oldest = 'oldest',
  lastUpdated = 'lastUpdated'
}

export type GetMessagesOptions = {
  query?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<GetMessagesFilters>;
  sort?: InputMaybe<GetMessagesSort>;
  skip?: Scalars['Int']['input'];
  limit?: Scalars['Int']['input'];
};

export type GetMessagesFilters = {
  type?: InputMaybe<Array<MessageType>>;
  threadId?: InputMaybe<Scalars['Int']['input']>;
  checkedStatus?: InputMaybe<Array<MessageCheckedStatus>>;
};

export enum GetMessagesSort {
  newest = 'newest',
  oldest = 'oldest'
}

export type GetTemplatesOptions = {
  query?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<GetTemplatesFilters>;
  sort?: InputMaybe<GetTemplatesSort>;
  skip?: Scalars['Int']['input'];
  limit?: Scalars['Int']['input'];
};

export type GetTemplatesFilters = {
  ownerUserId?: InputMaybe<Array<Scalars['Int']['input']>>;
  type?: InputMaybe<Array<TemplateType>>;
  userId?: InputMaybe<Scalars['Int']['input']>;
  brandId?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export enum GetTemplatesSort {
  newest = 'newest',
  oldest = 'oldest'
}

export type GetTeamInvitationsOptions = {
  query?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<GetTeamInvitationsFilters>;
  sort?: InputMaybe<GetTeamInvitationsSort>;
  skip?: Scalars['Int']['input'];
  limit?: Scalars['Int']['input'];
};

export type GetTeamInvitationsFilters = {
  brandId?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<TeamInvitationStatus>;
  expirationDateAfter?: InputMaybe<Scalars['Int']['input']>;
};

export enum GetTeamInvitationsSort {
  newest = 'newest',
  oldest = 'oldest'
}

export type GetUserBrandsOptions = {
  query?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<GetUserBrandsFilters>;
  sort?: InputMaybe<GetUserBrandsSort>;
  skip?: Scalars['Int']['input'];
  limit?: Scalars['Int']['input'];
};

export type GetUserBrandsFilters = {
  userId?: InputMaybe<Scalars['Int']['input']>;
  brandId?: InputMaybe<Scalars['Int']['input']>;
  roles?: InputMaybe<Array<UserRole>>;
  isDefaultContactUser?: InputMaybe<Scalars['Boolean']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum GetUserBrandsSort {
  newest = 'newest',
  oldest = 'oldest'
}

export type GetCampaignInvitationsOptions = {
  query?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<GetCampaignInvitationsFilters>;
  sort?: InputMaybe<GetCampaignInvitationsSort>;
  skip?: Scalars['Int']['input'];
  limit?: Scalars['Int']['input'];
};

export type GetCampaignInvitationsFilters = {
  brandId?: InputMaybe<Scalars['Int']['input']>;
  creatorId?: InputMaybe<Scalars['Int']['input']>;
  campaignId?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<CampaignInvitationStatus>>;
  expirationDateAfter?: InputMaybe<Scalars['Int']['input']>;
};

export enum GetCampaignInvitationsSort {
  newest = 'newest',
  oldest = 'oldest'
}

export type GetClipsOptions = {
  query?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<GetClipsFilters>;
  sort?: InputMaybe<GetClipsSort>;
  skip?: Scalars['Int']['input'];
  limit?: Scalars['Int']['input'];
};

export type GetClipsFilters = {
  creatorId?: InputMaybe<Scalars['Int']['input']>;
  brandId?: InputMaybe<Scalars['Int']['input']>;
  excludedBrandId?: InputMaybe<Scalars['Int']['input']>;
  campaignId?: InputMaybe<Scalars['Int']['input']>;
  qualityScore?: InputMaybe<Array<Scalars['Int']['input']>>;
  creatorCountryCode?: InputMaybe<Array<CountryCode>>;
  collaborationStatus?: InputMaybe<Array<CollaborationStatus>>;
  videoType?: InputMaybe<Array<VideoType>>;
  videoDuration?: InputMaybe<Array<VideoDuration>>;
  productType?: InputMaybe<Array<ProductType>>;
  reviewerId?: InputMaybe<Array<Scalars['Int']['input']>>;
  isClipReviewed?: InputMaybe<Scalars['Boolean']['input']>;
  category?: InputMaybe<Array<Category>>;
};

export enum GetClipsSort {
  newest = 'newest',
  oldest = 'oldest',
  lastUpdated = 'lastUpdated'
}

export type GetPortfolioOptions = {
  query?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<GetPortfolioFilters>;
  sort?: InputMaybe<GetPortfolioSort>;
  skip?: Scalars['Int']['input'];
  limit?: Scalars['Int']['input'];
};

export type GetPortfolioFilters = {
  creatorId?: InputMaybe<Scalars['Int']['input']>;
};

export enum GetPortfolioSort {
  newest = 'newest',
  oldest = 'oldest'
}

export type GetRatingsOptions = {
  query?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<GetRatingsFilters>;
  sort?: InputMaybe<GetRatingsSort>;
  skip?: Scalars['Int']['input'];
  limit?: Scalars['Int']['input'];
};

export type GetRatingsFilters = {
  type?: InputMaybe<RatingType>;
  stars?: InputMaybe<Array<Scalars['Int']['input']>>;
  brandId?: InputMaybe<Scalars['Int']['input']>;
  creatorId?: InputMaybe<Scalars['Int']['input']>;
};

export enum GetRatingsSort {
  newest = 'newest',
  oldest = 'oldest'
}

export type GetDemoLeadsOptions = {
  query?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<GetDemoLeadsFilters>;
  sort?: InputMaybe<GetDemoLeadsSort>;
  skip?: Scalars['Int']['input'];
  limit?: Scalars['Int']['input'];
};

export type GetDemoLeadsFilters = {
  createdAfter?: InputMaybe<Scalars['Int']['input']>;
  createdBefore?: InputMaybe<Scalars['Int']['input']>;
  brandType?: InputMaybe<Array<BrandType>>;
};

export enum GetDemoLeadsSort {
  newest = 'newest',
  oldest = 'oldest'
}

export type Mutation = {
  __typename?: 'Mutation';
  createUser: CreateUserResponse;
  verifyUserEmail: Scalars['Boolean']['output'];
  login: LoginResponse;
  loginAdmin: LoginResponse;
  updateUser: User;
  updateUserPassword: Scalars['Boolean']['output'];
  getUserAvatarUploadUrl: GetUploadUrlResponse;
  resetPassword: Scalars['String']['output'];
  updatePasswordWithToken: Scalars['Boolean']['output'];
  deleteUser: Scalars['Boolean']['output'];
  updateUserNotifications: UserNotifications;
  createBrand: Brand;
  updateBrand: Brand;
  getWebsiteMeta: GetWebsiteMetaResponse;
  getBrandLogoUploadUrl: GetUploadUrlResponse;
  updateBrandCredit: Brand;
  uploadBrandLogoFromUrl: GetUploadUrlResponse;
  sendEmailToBrand: Scalars['Boolean']['output'];
  updateCampaign: Campaign;
  createCampaign: Campaign;
  deleteCampaign: Scalars['Boolean']['output'];
  pauseCampaign: Campaign;
  unpauseCampaign: Campaign;
  completeCampaign: Campaign;
  updateCampaignVideosQuantity: Campaign;
  updateCampaignStatus: Campaign;
  duplicateCampaign: Campaign;
  boostCampaign: Campaign;
  updateCreator: Creator;
  approveCreator: Creator;
  rejectCreator: Creator;
  pauseCreator: Creator;
  unpauseCreator: Creator;
  shadowBanCreator: Creator;
  activateCreator: Creator;
  getCreatorIntroVideoUploadUrl: GetUploadUrlResponse;
  requestApproval: Creator;
  sendEmailToCreator: Scalars['Boolean']['output'];
  createProduct: Product;
  updateProduct: Product;
  deleteProduct: Scalars['Boolean']['output'];
  getProductPhotoUploadUrl: GetUploadUrlResponse;
  applyToCampaign: Collaboration;
  acceptCollaboration: Collaboration;
  shipProduct: Collaboration;
  acceptContent: Collaboration;
  updateProductTrackingLink: Collaboration;
  markCreatorAsPaid: Scalars['Boolean']['output'];
  markCollaborationCreatorAcceptedEmailSent: Collaboration;
  markCollaborationProductShippedEmailSent: Collaboration;
  markCollaborationProductDeliveredEmailSentToCreator: Collaboration;
  markCollaborationProductDeliveredEmailSentToBrand: Collaboration;
  markCollaborationProductDelivered: Collaboration;
  markCollaborationRejected: Collaboration;
  markCreatorAsUnpayable: Scalars['Boolean']['output'];
  markCollaborationAsUnpayable: Collaboration;
  deleteCollaboration: Scalars['Boolean']['output'];
  deleteCollaborationByCreator: Scalars['Boolean']['output'];
  duplicateCollaboration: Collaboration;
  createEditsRequestMessage: Collaboration;
  updateApplicationMessageCheckedStatus: Collaboration;
  markApplicantAsFavorite: Collaboration;
  removeApplicantAsFavorite: Collaboration;
  createNote: Note;
  createInspiration: Inspiration;
  getInspirationUploadUrl: GetInspirationUploadUrlResponse;
  updateInspiration: Inspiration;
  deleteInspiration: Scalars['String']['output'];
  addCampaign: Inspiration;
  removeCampaign: Inspiration;
  createMessage: Message;
  markThreadMessagesAsReadByBrand: MessageThread;
  markAllMessagesAsReadByBrand: Brand;
  markThreadMessagesAsUnreadByBrand: MessageThread;
  markThreadMessagesAsReadByCreator: MessageThread;
  markAllMessagesAsReadByCreator: Creator;
  markThreadMessagesAsUnreadByCreator: MessageThread;
  messageCheckedOk: Message;
  messageFlagged: Message;
  infoMessage: Message;
  deleteMessage: Scalars['String']['output'];
  createTemplate: Template;
  updateTemplate: Template;
  deleteTemplateById: Scalars['Boolean']['output'];
  inviteTeamMember: TeamInvitation;
  acceptTeamMemberInvitation: TeamInvitation;
  signupTeamMember: LoginResponse;
  cancelTeamMemberInvitation: Brand;
  resendTeamMemberInvitation: TeamInvitation;
  updateTeamMemberRoles: UserBrand;
  removeTeamMember: Brand;
  inviteCreatorsToCampaign: GetCampaignInvitationsResponse;
  createClip: Clip;
  updateClip: Clip;
  sendReviewClip: Clip;
  ignoreReviewClip: Clip;
  getClipVideoUploadUrl: GetUploadUrlResponse;
  deleteClip: Scalars['Boolean']['output'];
  getPortfolioItemUploadUrl: GetUploadUrlResponse;
  createPortfolioItem: PortfolioItem;
  deletePortfolioItem: Scalars['Boolean']['output'];
  createRating: Rating;
  updateRating: Rating;
  createDemoLead: DemoLead;
  createCampaignCheckout: CreateCampaignCheckoutResponse;
  createHooksCheckout: CreateHooksCheckoutResponse;
  createAddonsCheckout: CreateAddonsCheckoutResponse;
  createCreditPackCheckout: Scalars['String']['output'];
  getCustomerPortalSession: Scalars['String']['output'];
  confirmPayment: ConfirmPaymentResponse;
  confirmCreditPackPayment: ConfirmPaymentResponse;
  confirmHooksPayment: ConfirmPaymentResponse;
  confirmAddonsPayment: ConfirmPaymentResponse;
  payCreatorPendingEarnings: Payout;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationVerifyUserEmailArgs = {
  token: Scalars['String']['input'];
};


export type MutationLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationLoginAdminArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationUpdateUserArgs = {
  id: Scalars['Int']['input'];
  input: UpdateUserInput;
};


export type MutationUpdateUserPasswordArgs = {
  oldPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};


export type MutationGetUserAvatarUploadUrlArgs = {
  userId: Scalars['Int']['input'];
  mime: Scalars['String']['input'];
};


export type MutationResetPasswordArgs = {
  email: Scalars['String']['input'];
};


export type MutationUpdatePasswordWithTokenArgs = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['Int']['input'];
};


export type MutationUpdateUserNotificationsArgs = {
  userId: Scalars['Int']['input'];
  input: UpdateUserNotificationsInput;
};


export type MutationCreateBrandArgs = {
  userId: Scalars['Int']['input'];
  input: CreateBrandInput;
};


export type MutationUpdateBrandArgs = {
  id: Scalars['Int']['input'];
  input: UpdateBrandInput;
};


export type MutationGetWebsiteMetaArgs = {
  website: Scalars['String']['input'];
};


export type MutationGetBrandLogoUploadUrlArgs = {
  mime: Scalars['String']['input'];
  brandId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationUpdateBrandCreditArgs = {
  id: Scalars['Int']['input'];
  input: UpdateBrandCreditInput;
};


export type MutationUploadBrandLogoFromUrlArgs = {
  logoUrl: Scalars['String']['input'];
  brandId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationSendEmailToBrandArgs = {
  brandId: Scalars['Int']['input'];
  input: SendEmailToBrandInput;
};


export type MutationUpdateCampaignArgs = {
  id: Scalars['Int']['input'];
  input: UpdateCampaignInput;
};


export type MutationCreateCampaignArgs = {
  brandId: Scalars['Int']['input'];
  input: CreateCampaignInput;
};


export type MutationDeleteCampaignArgs = {
  campaignId: Scalars['Int']['input'];
};


export type MutationPauseCampaignArgs = {
  id: Scalars['Int']['input'];
};


export type MutationUnpauseCampaignArgs = {
  id: Scalars['Int']['input'];
};


export type MutationCompleteCampaignArgs = {
  id: Scalars['Int']['input'];
};


export type MutationUpdateCampaignVideosQuantityArgs = {
  campaignId: Scalars['Int']['input'];
  videoQuantity: Scalars['Int']['input'];
};


export type MutationUpdateCampaignStatusArgs = {
  id: Scalars['Int']['input'];
  status: CampaignStatus;
};


export type MutationDuplicateCampaignArgs = {
  id: Scalars['Int']['input'];
};


export type MutationBoostCampaignArgs = {
  id: Scalars['Int']['input'];
  creatorEarningsCentsBoosted: Scalars['Int']['input'];
  isEmailNotificationEnabled: Scalars['Boolean']['input'];
};


export type MutationUpdateCreatorArgs = {
  id: Scalars['Int']['input'];
  input: UpdateCreatorInput;
};


export type MutationApproveCreatorArgs = {
  id: Scalars['Int']['input'];
};


export type MutationRejectCreatorArgs = {
  id: Scalars['Int']['input'];
};


export type MutationPauseCreatorArgs = {
  id: Scalars['Int']['input'];
  input: PauseCreatorInput;
};


export type MutationUnpauseCreatorArgs = {
  id: Scalars['Int']['input'];
};


export type MutationShadowBanCreatorArgs = {
  id: Scalars['Int']['input'];
};


export type MutationActivateCreatorArgs = {
  id: Scalars['Int']['input'];
};


export type MutationGetCreatorIntroVideoUploadUrlArgs = {
  creatorId: Scalars['Int']['input'];
  mime: Scalars['String']['input'];
};


export type MutationRequestApprovalArgs = {
  id: Scalars['Int']['input'];
};


export type MutationSendEmailToCreatorArgs = {
  creatorId: Scalars['Int']['input'];
  input: SendEmailToCreatorInput;
};


export type MutationCreateProductArgs = {
  brandId: Scalars['Int']['input'];
  input: CreateProductInput;
};


export type MutationUpdateProductArgs = {
  id: Scalars['Int']['input'];
  input: UpdateProductInput;
};


export type MutationDeleteProductArgs = {
  productId: Scalars['Int']['input'];
};


export type MutationGetProductPhotoUploadUrlArgs = {
  brandId: Scalars['Int']['input'];
  mime: Scalars['String']['input'];
};


export type MutationApplyToCampaignArgs = {
  creatorId: Scalars['Int']['input'];
  campaignId: Scalars['Int']['input'];
  input: ApplyToCampaignInput;
};


export type MutationAcceptCollaborationArgs = {
  collaborationId: Scalars['Int']['input'];
};


export type MutationShipProductArgs = {
  collaborationId: Scalars['Int']['input'];
  input: ShipProductInput;
};


export type MutationAcceptContentArgs = {
  collaborationId: Scalars['Int']['input'];
};


export type MutationUpdateProductTrackingLinkArgs = {
  collaborationId: Scalars['Int']['input'];
  input: UpdateProductTrackingLinkInput;
};


export type MutationMarkCreatorAsPaidArgs = {
  creatorId: Scalars['Int']['input'];
  currency: Currency;
};


export type MutationMarkCollaborationCreatorAcceptedEmailSentArgs = {
  collaborationId: Scalars['Int']['input'];
};


export type MutationMarkCollaborationProductShippedEmailSentArgs = {
  collaborationId: Scalars['Int']['input'];
};


export type MutationMarkCollaborationProductDeliveredEmailSentToCreatorArgs = {
  collaborationId: Scalars['Int']['input'];
};


export type MutationMarkCollaborationProductDeliveredEmailSentToBrandArgs = {
  collaborationId: Scalars['Int']['input'];
};


export type MutationMarkCollaborationProductDeliveredArgs = {
  collaborationId: Scalars['Int']['input'];
  input: ProductDeliveredInput;
};


export type MutationMarkCollaborationRejectedArgs = {
  collaborationId: Scalars['Int']['input'];
};


export type MutationMarkCreatorAsUnpayableArgs = {
  creatorId: Scalars['Int']['input'];
  currency: Currency;
};


export type MutationMarkCollaborationAsUnpayableArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteCollaborationArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteCollaborationByCreatorArgs = {
  collaborationId: Scalars['Int']['input'];
  creatorId: Scalars['Int']['input'];
};


export type MutationDuplicateCollaborationArgs = {
  id: Scalars['Int']['input'];
};


export type MutationCreateEditsRequestMessageArgs = {
  collaborationId: Scalars['Int']['input'];
  input: CreateEditsRequestMessageInput;
};


export type MutationUpdateApplicationMessageCheckedStatusArgs = {
  collaborationId: Scalars['Int']['input'];
  checkedStatus: MessageCheckedStatus;
};


export type MutationMarkApplicantAsFavoriteArgs = {
  collaborationId: Scalars['Int']['input'];
};


export type MutationRemoveApplicantAsFavoriteArgs = {
  collaborationId: Scalars['Int']['input'];
};


export type MutationCreateNoteArgs = {
  input: CreateNoteInput;
};


export type MutationCreateInspirationArgs = {
  input: CreateInspirationInput;
};


export type MutationGetInspirationUploadUrlArgs = {
  mime: Scalars['String']['input'];
};


export type MutationUpdateInspirationArgs = {
  id: Scalars['Int']['input'];
  input: UpdateInspirationInput;
};


export type MutationDeleteInspirationArgs = {
  inspirationId: Scalars['Int']['input'];
};


export type MutationAddCampaignArgs = {
  inspirationId: Scalars['Int']['input'];
  campaignId: Scalars['Int']['input'];
};


export type MutationRemoveCampaignArgs = {
  inspirationId: Scalars['Int']['input'];
  campaignId: Scalars['Int']['input'];
};


export type MutationCreateMessageArgs = {
  input: CreateMessageInput;
};


export type MutationMarkThreadMessagesAsReadByBrandArgs = {
  uuid: Scalars['String']['input'];
};


export type MutationMarkAllMessagesAsReadByBrandArgs = {
  id: Scalars['Int']['input'];
};


export type MutationMarkThreadMessagesAsUnreadByBrandArgs = {
  uuid: Scalars['String']['input'];
};


export type MutationMarkThreadMessagesAsReadByCreatorArgs = {
  uuid: Scalars['String']['input'];
};


export type MutationMarkAllMessagesAsReadByCreatorArgs = {
  id: Scalars['Int']['input'];
};


export type MutationMarkThreadMessagesAsUnreadByCreatorArgs = {
  uuid: Scalars['String']['input'];
};


export type MutationMessageCheckedOkArgs = {
  id: Scalars['Int']['input'];
};


export type MutationMessageFlaggedArgs = {
  id: Scalars['Int']['input'];
  warningMessage: Scalars['String']['input'];
};


export type MutationInfoMessageArgs = {
  id: Scalars['Int']['input'];
  infoMessage: Scalars['String']['input'];
};


export type MutationDeleteMessageArgs = {
  messageId: Scalars['Int']['input'];
};


export type MutationCreateTemplateArgs = {
  userId: Scalars['Int']['input'];
  input: CreateTemplateInput;
};


export type MutationUpdateTemplateArgs = {
  templateId: Scalars['Int']['input'];
  input: UpdateTemplateInput;
};


export type MutationDeleteTemplateByIdArgs = {
  templateId: Scalars['Int']['input'];
};


export type MutationInviteTeamMemberArgs = {
  brandId: Scalars['Int']['input'];
  input: InviteTeamMemberInput;
};


export type MutationAcceptTeamMemberInvitationArgs = {
  userId: Scalars['Int']['input'];
  token: Scalars['String']['input'];
};


export type MutationSignupTeamMemberArgs = {
  input: TeamMemberSignupInput;
};


export type MutationCancelTeamMemberInvitationArgs = {
  email: Scalars['String']['input'];
  brandId: Scalars['Int']['input'];
};


export type MutationResendTeamMemberInvitationArgs = {
  email: Scalars['String']['input'];
  brandId: Scalars['Int']['input'];
};


export type MutationUpdateTeamMemberRolesArgs = {
  userId: Scalars['Int']['input'];
  brandId: Scalars['Int']['input'];
  input: UpdateTeamMemberRolesInput;
};


export type MutationRemoveTeamMemberArgs = {
  userId: Scalars['Int']['input'];
  brandId: Scalars['Int']['input'];
};


export type MutationInviteCreatorsToCampaignArgs = {
  campaignId: Scalars['Int']['input'];
  input: InviteCreatorsToCampaignInput;
};


export type MutationCreateClipArgs = {
  collaborationId: Scalars['Int']['input'];
  input: CreateClipInput;
};


export type MutationUpdateClipArgs = {
  id: Scalars['Int']['input'];
  input: UpdateClipInput;
};


export type MutationSendReviewClipArgs = {
  clipId: Scalars['Int']['input'];
  input: SendReviewClipInput;
};


export type MutationIgnoreReviewClipArgs = {
  clipId: Scalars['Int']['input'];
};


export type MutationGetClipVideoUploadUrlArgs = {
  creatorId: Scalars['Int']['input'];
  campaignId: Scalars['Int']['input'];
  mime: Scalars['String']['input'];
};


export type MutationDeleteClipArgs = {
  clipId: Scalars['Int']['input'];
};


export type MutationGetPortfolioItemUploadUrlArgs = {
  creatorId: Scalars['Int']['input'];
  mime: Scalars['String']['input'];
};


export type MutationCreatePortfolioItemArgs = {
  creatorId: Scalars['Int']['input'];
  input: CreatePortfolioInput;
};


export type MutationDeletePortfolioItemArgs = {
  portfolioItemId: Scalars['Int']['input'];
};


export type MutationCreateRatingArgs = {
  input: CreateRatingInput;
};


export type MutationUpdateRatingArgs = {
  id: Scalars['Int']['input'];
  input: UpdateRatingInput;
};


export type MutationCreateDemoLeadArgs = {
  input: CreateDemoLeadInput;
};


export type MutationCreateCampaignCheckoutArgs = {
  brandId: Scalars['Int']['input'];
  input: CreateCampaignCheckoutInput;
};


export type MutationCreateHooksCheckoutArgs = {
  brandId: Scalars['Int']['input'];
  input: CreateHooksCheckoutInput;
};


export type MutationCreateAddonsCheckoutArgs = {
  brandId: Scalars['Int']['input'];
  input: CreateAddonsCheckoutInput;
};


export type MutationCreateCreditPackCheckoutArgs = {
  brandId: Scalars['Int']['input'];
  input: CreateCreditPackCheckoutInput;
};


export type MutationGetCustomerPortalSessionArgs = {
  brandId: Scalars['Int']['input'];
  input: CreateCustomerPortalSessionInput;
};


export type MutationConfirmPaymentArgs = {
  stripeSessionId: Scalars['String']['input'];
};


export type MutationConfirmCreditPackPaymentArgs = {
  stripeSessionId: Scalars['String']['input'];
};


export type MutationConfirmHooksPaymentArgs = {
  stripeSessionId: Scalars['String']['input'];
};


export type MutationConfirmAddonsPaymentArgs = {
  stripeSessionId: Scalars['String']['input'];
};


export type MutationPayCreatorPendingEarningsArgs = {
  creatorId: Scalars['Int']['input'];
  currency: Currency;
};

export type CreateUserInput = {
  type: UserType;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  brandType?: InputMaybe<BrandType>;
  countryCode: CountryCode;
  phoneNumber: Scalars['String']['input'];
  phoneNumberCountry: CountryCode;
  currency: Currency;
  timezone: Scalars['String']['input'];
  utcOffset: Scalars['Float']['input'];
  isMarketingEmailsSubscribed: Scalars['Boolean']['input'];
  fbp?: InputMaybe<Scalars['String']['input']>;
  fbc?: InputMaybe<Scalars['String']['input']>;
  paypalEmail?: InputMaybe<Scalars['String']['input']>;
  brandName?: InputMaybe<Scalars['String']['input']>;
  utmSource?: InputMaybe<Scalars['String']['input']>;
  utmMedium?: InputMaybe<Scalars['String']['input']>;
  utmCampaign?: InputMaybe<Scalars['String']['input']>;
  utmTerm?: InputMaybe<Scalars['String']['input']>;
  utmContent?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  avatarFileName?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  paypalEmail?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserNotificationsInput = {
  isMarketingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isReviewsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isApplicantsReminderEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isDailyDigestEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateBrandInput = {
  name: Scalars['String']['input'];
  countryCode: Scalars['String']['input'];
  website?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  logoFileName?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<BrandType>;
  isMarketingEmailsSubscribed: Scalars['Boolean']['input'];
  utmSource?: InputMaybe<Scalars['String']['input']>;
  utmMedium?: InputMaybe<Scalars['String']['input']>;
  utmCampaign?: InputMaybe<Scalars['String']['input']>;
  utmTerm?: InputMaybe<Scalars['String']['input']>;
  utmContent?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateBrandInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  businessType?: InputMaybe<BusinessType>;
  onboarding?: InputMaybe<BrandOnboardingStep>;
  modelGender?: InputMaybe<Scalars['String']['input']>;
  logoFileName?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<Currency>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  isAutoApprovedMessages?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateBrandCreditInput = {
  creditCents: Scalars['Int']['input'];
};

export type SendEmailToBrandInput = {
  subject: Scalars['String']['input'];
  message: Scalars['String']['input'];
};

export type UpdateCampaignInput = {
  title?: InputMaybe<Scalars['String']['input']>;
  videoType?: InputMaybe<VideoType>;
  modelType?: InputMaybe<ModelType>;
  voiceType?: InputMaybe<VoiceType>;
  platform?: InputMaybe<Platform>;
  videoDuration?: InputMaybe<VideoDuration>;
  requirements?: InputMaybe<Scalars['String']['input']>;
  modelGender?: InputMaybe<Scalars['String']['input']>;
  ethnicity?: InputMaybe<Ethnicity>;
  creatorPreferences?: InputMaybe<Scalars['String']['input']>;
  quality?: InputMaybe<CreatorQuality>;
  ageGroups?: InputMaybe<Array<AgeGroups>>;
  productId?: InputMaybe<Scalars['Int']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  hooks?: InputMaybe<Array<Scalars['String']['input']>>;
  inspirationVideosLinks?: InputMaybe<Array<Scalars['String']['input']>>;
  addons?: InputMaybe<Array<CampaignAddon>>;
};

export type CreateCampaignInput = {
  title: Scalars['String']['input'];
  videoType?: InputMaybe<VideoType>;
  modelType?: InputMaybe<ModelType>;
  voiceType?: InputMaybe<VoiceType>;
  platform?: InputMaybe<Platform>;
  videoDuration?: InputMaybe<VideoDuration>;
  requirements?: InputMaybe<Scalars['String']['input']>;
  modelGender?: InputMaybe<Scalars['String']['input']>;
  ethnicity?: InputMaybe<Ethnicity>;
  creatorPreferences?: InputMaybe<Scalars['String']['input']>;
  quality?: InputMaybe<CreatorQuality>;
  ageGroups?: InputMaybe<Array<AgeGroups>>;
  productId: Scalars['Int']['input'];
  countryCode?: InputMaybe<Scalars['String']['input']>;
  hooks?: InputMaybe<Array<Scalars['String']['input']>>;
  inspirationVideosLinks?: InputMaybe<Array<Scalars['String']['input']>>;
  addons?: InputMaybe<Array<CampaignAddon>>;
};

export type UpdateCreatorInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  onboarding?: InputMaybe<CreatorOnboardingStep>;
  gender?: InputMaybe<Gender>;
  ethnicity?: InputMaybe<Ethnicity>;
  occupation?: InputMaybe<Scalars['String']['input']>;
  birthday?: InputMaybe<Scalars['DateTime']['input']>;
  introVideoFileName?: InputMaybe<Scalars['String']['input']>;
  interests?: InputMaybe<Array<CreatorInterests>>;
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  addressCity?: InputMaybe<Scalars['String']['input']>;
  addressCounty?: InputMaybe<Scalars['String']['input']>;
  addressState?: InputMaybe<Scalars['String']['input']>;
  addressPostcode?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  qualityScore?: InputMaybe<Scalars['Int']['input']>;
  avatarFileName?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  paypalEmail?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<Currency>;
  introVideoWidth?: InputMaybe<Scalars['Float']['input']>;
  introVideoHeight?: InputMaybe<Scalars['Float']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  isNeverPause?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PauseCreatorInput = {
  pausedReason: CreatorPausedReason;
  pausedDescription?: InputMaybe<Scalars['String']['input']>;
};

export type SendEmailToCreatorInput = {
  subject: Scalars['String']['input'];
  message: Scalars['String']['input'];
};

export type CreateProductInput = {
  name: Scalars['String']['input'];
  type: ProductType;
  link?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  currency: Currency;
  photoFileName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProductInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ProductType>;
  link?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  currency: Currency;
  photoFileName?: InputMaybe<Scalars['String']['input']>;
};

export type ApplyToCampaignInput = {
  message?: InputMaybe<Scalars['String']['input']>;
  invitationId?: InputMaybe<Scalars['Float']['input']>;
};

export type ShipProductInput = {
  productTrackingLink?: InputMaybe<Scalars['String']['input']>;
  productShippedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateProductTrackingLinkInput = {
  productTrackingLink?: InputMaybe<Scalars['String']['input']>;
};

export type ProductDeliveredInput = {
  productDeliveredAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CreateEditsRequestMessageInput = {
  editsRequestMessage: Scalars['String']['input'];
};

export type CreateNoteInput = {
  text: Scalars['String']['input'];
  creatorId?: InputMaybe<Scalars['Int']['input']>;
  brandId?: InputMaybe<Scalars['Int']['input']>;
  campaignId?: InputMaybe<Scalars['Int']['input']>;
  collaborationId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateInspirationInput = {
  type: InspirationType;
  fileName: Scalars['String']['input'];
  externalUrl?: InputMaybe<Scalars['String']['input']>;
  brandName?: InputMaybe<Scalars['String']['input']>;
  videoType: VideoType;
  source?: InputMaybe<Platform>;
  productType?: InputMaybe<ProductType>;
  model?: InputMaybe<ModelType>;
  gender?: InputMaybe<Gender>;
  ageGroups?: InputMaybe<AgeGroups>;
  ethnicity?: InputMaybe<Ethnicity>;
  duration: Scalars['Int']['input'];
  isTextOver: Scalars['Boolean']['input'];
  isVoiceOver: Scalars['Boolean']['input'];
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  industry?: InputMaybe<Industry>;
  isAllVisible: Scalars['Boolean']['input'];
  brandId?: InputMaybe<Scalars['Int']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
  height?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateInspirationInput = {
  type: InspirationType;
  fileName: Scalars['String']['input'];
  externalUrl: Scalars['String']['input'];
  brandName: Scalars['String']['input'];
  videoType: VideoType;
  source: Platform;
  productType: ProductType;
  model: ModelType;
  gender: Gender;
  ageGroups?: InputMaybe<AgeGroups>;
  ethnicity: Ethnicity;
  duration: Scalars['Int']['input'];
  isTextOver: Scalars['Boolean']['input'];
  isVoiceOver: Scalars['Boolean']['input'];
  tags: Array<Scalars['String']['input']>;
  industry?: InputMaybe<Industry>;
  isAllVisible: Scalars['Boolean']['input'];
};

export type CreateMessageInput = {
  creatorId: Scalars['Int']['input'];
  brandId: Scalars['Int']['input'];
  type: MessageType;
  message: Scalars['String']['input'];
};

export type CreateTemplateInput = {
  name: Scalars['String']['input'];
  subject?: InputMaybe<Scalars['String']['input']>;
  message: Scalars['String']['input'];
  type: TemplateType;
  brandId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateTemplateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
};

export type InviteTeamMemberInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  roles: Array<UserRole>;
};

export type TeamMemberSignupInput = {
  token: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  fbp?: InputMaybe<Scalars['String']['input']>;
  fbc?: InputMaybe<Scalars['String']['input']>;
  isMarketingEmailsSubscribed: Scalars['Boolean']['input'];
  phoneNumberCountry: CountryCode;
  phoneNumber: Scalars['String']['input'];
};

export type UpdateTeamMemberRolesInput = {
  roles?: InputMaybe<Array<UserRole>>;
  isDefaultContactUser?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InviteCreatorsToCampaignInput = {
  creatorIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type CreateClipInput = {
  videoFileName: Scalars['String']['input'];
  width?: InputMaybe<Scalars['Float']['input']>;
  height?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<ClipType>;
  collaborationHookDescription?: InputMaybe<Scalars['String']['input']>;
  collaborationAddon?: InputMaybe<CampaignAddon>;
};

export type UpdateClipInput = {
  qualityScore?: InputMaybe<Scalars['Int']['input']>;
  category?: InputMaybe<Category>;
};

export type SendReviewClipInput = {
  message: Scalars['String']['input'];
};

export type CreatePortfolioInput = {
  videoFileName: Scalars['String']['input'];
  videoWidth?: InputMaybe<Scalars['Float']['input']>;
  videoHeight?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateRatingInput = {
  type: Scalars['String']['input'];
  stars: Scalars['Float']['input'];
  review?: InputMaybe<Scalars['String']['input']>;
  collaborationUuid: Scalars['String']['input'];
};

export type UpdateRatingInput = {
  contentQualityRating?: InputMaybe<Scalars['Int']['input']>;
  professionalismRating?: InputMaybe<Scalars['Int']['input']>;
  communicationRating?: InputMaybe<Scalars['Int']['input']>;
  creativityRating?: InputMaybe<Scalars['Int']['input']>;
  timelinessRating?: InputMaybe<Scalars['Int']['input']>;
  adherenceToBriefRating?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateDemoLeadInput = {
  name: Scalars['String']['input'];
  email: Scalars['String']['input'];
  brandWebsite: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  brandType: BrandType;
  brandSize: Scalars['String']['input'];
  signedUp: Scalars['String']['input'];
  countryCode?: InputMaybe<CountryCode>;
  hearAboutUs: Scalars['String']['input'];
};

export type CreateCampaignCheckoutInput = {
  quantity: Scalars['Int']['input'];
  campaignId: Scalars['Int']['input'];
  successRedirectUrlPath?: InputMaybe<Scalars['String']['input']>;
  cancelRedirectUrlPath?: InputMaybe<Scalars['String']['input']>;
};

export type CreateHooksCheckoutInput = {
  hooks: Array<Scalars['String']['input']>;
  collaborationId: Scalars['Int']['input'];
  successRedirectUrlPath?: InputMaybe<Scalars['String']['input']>;
  cancelRedirectUrlPath?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAddonsCheckoutInput = {
  addons: Array<CampaignAddon>;
  collaborationId: Scalars['Int']['input'];
  successRedirectUrlPath?: InputMaybe<Scalars['String']['input']>;
  cancelRedirectUrlPath?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCreditPackCheckoutInput = {
  type: CreditPackType;
  successRedirectUrlPath?: InputMaybe<Scalars['String']['input']>;
  cancelRedirectUrlPath?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCustomerPortalSessionInput = {
  returnUrl?: InputMaybe<Scalars['String']['input']>;
};


      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    