import React, { Fragment, useState } from 'react'
import {
  ConfirmationModal,
  DeleteModal,
  Dropdown,
  Icon,
  IconType,
  Loader,
  LoaderType,
  NotificationType,
  OrderClipModal,
} from '../../../components'
import {
  Campaign,
  CampaignStatus,
  GetCampaignsSort,
  MutationCompleteCampaignArgs,
  MutationPauseCampaignArgs,
  MutationUnpauseCampaignArgs,
} from '../../../gql/types'
import { useMutation } from '@apollo/client'
import {
  COMPLETE_CAMPAIGN,
  DELETE_CAMPAIGN,
  DUPLICATE_CAMPAIGN,
  GET_CAMPAIGNS_BY_BRAND_ID,
  PAUSE_CAMPAIGN,
  UNPAUSE_CAMPAIGN,
} from '../../../gql'
import { useNotification } from '../../../utils/hooks'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { BrandRoutes } from '../../../brands/BrandRoutes'
import { Menu, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { OrderHooksModal } from '../OrderHooksModal'
import { UpdateInspirationLinksModal } from '../UpdateInspirationLinksModal'

interface IProps {
  campaign: Campaign
  className?: string
  isGridItem?: boolean

  onCampaignUpdated?: () => void
}

export const CampaignActionsDropdown: React.FC<IProps> = ({
  campaign,
  className,
  onCampaignUpdated,
  isGridItem,
}) => {
  const { addNotification } = useNotification()
  const navigate = useNavigate()
  const { innerWidth: screenWidth, innerHeight: screenHeight } = window

  const [isOrderClipOpen, setIsOrderClipOpen] = useState(false)
  const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false)
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false)
  const [
    isUpdateInspirationLinksModalOpen,
    setIsUpdateInspirationLinksModalOpen,
  ] = useState(false)

  const [pauseCampaign, { loading: pauseCampaignLoading }] =
    useMutation<MutationPauseCampaignArgs>(PAUSE_CAMPAIGN, {
      variables: {
        id: campaign.id,
      },
      onCompleted: () => {
        onCampaignUpdated?.()
      },
    })

  const [unpauseCampaign, { loading: unpauseCampaignLoading }] =
    useMutation<MutationUnpauseCampaignArgs>(UNPAUSE_CAMPAIGN, {
      variables: {
        id: campaign.id,
      },
      onCompleted: () => {
        onCampaignUpdated?.()
      },
    })

  const [completeCampaign, { loading: completeCampaignLoading }] =
    useMutation<MutationCompleteCampaignArgs>(COMPLETE_CAMPAIGN, {
      variables: {
        id: campaign.id,
      },
      onCompleted: () => {
        onCampaignUpdated?.()
      },
    })

  const [deleteCampaign, { loading: deleteCampaignLoading }] = useMutation(
    DELETE_CAMPAIGN,
    {
      update(cache) {
        const normalizedId = cache.identify(campaign)
        cache.evict({ id: normalizedId })
        cache.gc()
      },
    },
  )

  const [duplicateCampaign, { loading: duplicateCampaignLoading }] =
    useMutation(DUPLICATE_CAMPAIGN, {
      refetchQueries: [
        {
          query: GET_CAMPAIGNS_BY_BRAND_ID,
          variables: {
            brandId: campaign.brand.id,
            options: {
              query: '',
              filters: {},
              sort: GetCampaignsSort.lastUpdated,
              skip: 0,
              limit: 20,
            },
          },
        },
      ],
    })

  const onEdit = () => {
    const campaignEditPath = BrandRoutes.campaignEdit
      .replace(':id', `${campaign.brand.id}`)
      .replace(':campaignId', `${campaign.id}`)
    // navigate(campaignEditPath)
    // navigate(0)
    window.location.href = campaignEditPath
  }

  const onDelete = async () => {
    try {
      await deleteCampaign({
        variables: {
          campaignId: campaign.id,
        },
      })

      addNotification(`Campaign deleted.`, NotificationType.success)
    } catch (error) {
      addNotification(`The campaign cannot be deleted.`, NotificationType.error)
    }
  }

  const onPause = async () => {
    try {
      await pauseCampaign()

      addNotification(
        `Campaign ${campaign.title} has been paused.`,
        NotificationType.success,
      )
    } catch (error) {
      console.log('error pausing campaign', error)
      addNotification(
        `Something went wrong. Please try again.`,
        NotificationType.error,
      )
    }
  }

  const onUnpause = async () => {
    try {
      await unpauseCampaign()

      addNotification(
        `Campaign ${campaign.title} has been unpaused.`,
        NotificationType.success,
      )
    } catch (error) {
      console.log('error unpausing campaign', error)
      addNotification(
        `Something went wrong. Please try again.`,
        NotificationType.error,
      )
    }
  }

  const onDuplicate = async () => {
    try {
      const { data } = await duplicateCampaign({
        variables: {
          id: campaign.id,
        },
      })

      addNotification(
        `Campaign '${campaign.title}' has been duplicated.`,
        NotificationType.success,
      )
      navigate(
        BrandRoutes.campaignEdit
          .replace(':id', `${campaign.brand.id}`)
          .replace(':campaignId', `${data.duplicateCampaign.id}`),
      )
    } catch (error) {
      console.log('error duplicate campaign', error)
      addNotification(
        `Something went wrong. Please try again.`,
        NotificationType.error,
      )
    }
  }

  const onComplete = async () => {
    try {
      await completeCampaign()

      addNotification(
        `Campaign ${campaign.title} is marked as completed.`,
        NotificationType.success,
      )
    } catch (error) {
      console.log('error completing campaign', error)
      addNotification(
        `Something went wrong. Please try again.`,
        NotificationType.error,
      )
    }
  }

  const getOptions = () => {
    const options = []

    // if campaign created less than 24h ago allow edits
    // only allow for active and paused campaigns
    if (
      (campaign.status === CampaignStatus.active ||
        campaign.status === CampaignStatus.paused) &&
      moment().diff(moment(campaign.purchasedAt), 'hours') < 24
    ) {
      options.push({
        name: 'Edit',
        icon: IconType.edit,
        onClick: () => onEdit(),
      })
    }

    switch (campaign.status) {
      case CampaignStatus.draft:
        options.push({
          name: 'Edit',
          icon: IconType.edit,
          onClick: () => onEdit(),
        })

        options.push({
          name: 'Delete',
          icon: IconType.trash,
          onClick: () => setIsDeleteConfirmationOpen(true),
        })

        break
      case CampaignStatus.completed:
        break
      case CampaignStatus.active:
        options.push({
          name: 'Buy more clips',
          icon: IconType.card,
          onClick: () => setIsOrderClipOpen(true),
        })

        options.push({
          name: 'Add inspiration links',
          icon: IconType.link,
          onClick: () => setIsUpdateInspirationLinksModalOpen(true),
        })

        options.push({
          name: 'Pause campaign',
          icon: IconType.pause,
          onClick: () => onPause(),
        })

        options.push({
          name: 'Mark as completed',
          icon: IconType.check,
          onClick: () => setIsCompleteModalOpen(true),
        })

        break
      case CampaignStatus.paused:
        options.push({
          name: 'Unpause',
          icon: IconType.play,
          onClick: () => onUnpause(),
        })

        options.push({
          name: 'Mark as completed',
          icon: IconType.check,
          onClick: () => setIsCompleteModalOpen(true),
        })
        break
    }

    options.push({
      name: 'Duplicate campaign',
      icon: IconType.duplicate,
      onClick: () => onDuplicate(),
    })

    return options
  }

  const options = getOptions()

  if (!options || options.length === 0) {
    return <></>
  }

  const loading =
    pauseCampaignLoading ||
    unpauseCampaignLoading ||
    completeCampaignLoading ||
    duplicateCampaignLoading

  if (loading) {
    return <Loader type={LoaderType.button} />
  }

  return (
    <>
      {isOrderClipOpen && (
        <OrderClipModal
          campaign={campaign}
          title={`Buy more Clips`}
          description={`Select the number of additional videos you would like and hit 'Confirm your order' below.`}
          open={isOrderClipOpen}
          setOpen={setIsOrderClipOpen}
        />
      )}

      <ConfirmationModal
        title='Confirm'
        description={`Are you sure you want to mark this campaign as completed? You'll not be able to accept anymore applicants or purchase on more Clips on this campaign. This action cannot be undone.`}
        open={isCompleteModalOpen}
        setOpen={setIsCompleteModalOpen}
        okButtonTitle='Mark as completed'
        onAction={() => onComplete()}
      />

      <DeleteModal
        title='Confirm'
        description='Are you sure you want to delete this campaign? All of your data will be permanently removed. This action cannot be undone.'
        open={isDeleteConfirmationOpen}
        setOpen={setIsDeleteConfirmationOpen}
        onDelete={onDelete}
      />

      {isUpdateInspirationLinksModalOpen && (
        <UpdateInspirationLinksModal
          campaign={campaign}
          isOpen={isUpdateInspirationLinksModalOpen}
          setIsOpen={setIsUpdateInspirationLinksModalOpen}
        />
      )}

      {!isGridItem && (
        <Dropdown
          title='Actions'
          className={`${className} mt-4 lg:mt-0 lg:w-min`}
          // menuPosition='left'
          dropdownClassName={screenWidth > 600 ? 'right-0' : 'left-0'}
          fullWidth={true}
          options={options.map((item) => ({
            text: (
              <div className='inline-flex max-w-full'>
                <div className='ml-1 truncate'>{item.name}</div>
              </div>
            ),
            icon: item.icon,
            onClick: () => {
              item.onClick?.()
            },
          }))}
        />
      )}

      {isGridItem && (
        //    <Dropdown
        //    title={<Icon
        //     type={IconType.dotsThreeHorizontal}
        //     className='h-5 w-5'
        //     aria-hidden='true'
        //   />}
        //    className={`${className} mt-4 lg:mt-0 lg:w-min`}
        //    menuPosition='left'
        //    options={options.map((item) => ({
        //      text: (
        //        <div className='inline-flex max-w-full'>
        //          <div className='ml-1 truncate'>{item.name}</div>
        //        </div>
        //      ),
        //      icon: item.icon,
        //      onClick: () => {
        //        item.onClick?.()
        //      },
        //    }))}
        //  />
        <Menu as='div' className='relative ml-auto'>
          <Menu.Button className='-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500'>
            <span className='sr-only'>Open options</span>
            <Icon
              type={IconType.dotsThreeHorizontal}
              className='h-5 w-5'
              aria-hidden='true'
            />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <Menu.Items className='absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none'>
              {options.map((item, index) => (
                <Menu.Item key={index}>
                  {({ active }) => (
                    <div
                      key={index}
                      className={classNames(
                        active ? 'bg-gray-50' : '',
                        'block px-3 py-1 text-sm leading-6 text-gray-900',
                      )}
                      onClick={() => {
                        item.onClick?.()
                      }}
                    >
                      {item.name}
                      <span className='sr-only'>, {item.name}</span>
                    </div>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </Menu>
      )}
    </>
  )
}
