import { Creator, CreatorStatus } from '../../../gql/types'

interface IProps {
  creator: Creator
}

const AccountPaused = () => {
  return (
    <div className='shadow ring-1 ring-black ring-opacity-5 rounded mt-6 p-6 bg-widget bg-white'>
      <div className='flex flex-col'>
        <h3 className='font-outfit text-xl'>Account paused</h3>
        <hr className='my-6' />
        <div className='text-base text-gray-500'>
          <p>
            Your Clip account is paused. Please contact us at{' '}
            <a
              href='mailto:hello@useclip.com'
              className='font-medium text-blue-600 hover:text-blue-500'
            >
              hello@useclip.com
            </a>{' '}
            as soon as possible to reactivate your account.
          </p>
        </div>
      </div>
    </div>
  )
}

const AccountStatus = ({ creator }: IProps) => {
  if (creator.status === CreatorStatus.active) {
    return null
  }

  if (creator.status === CreatorStatus.paused) {
    return <AccountPaused />
  }

  return null
}

export { AccountStatus }
