import { Collaboration, Pagination } from '../../../../gql/types'
import classnames from 'classnames'
import { Pagination as PaginationComponent } from '../../../../components'
import { CollaborationCard } from '../CollaborationCard'

interface IProps {
  collaborations: Collaboration[]
  pagination?: Pagination
  setPage(page: number): void
  onRefetch: () => void
}

const TableHead = () => {
  return (
    <thead className='bg-white'>
      <tr>
        <th
          scope='col'
          className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-600 sm:pl-6'
        >
          Creator
        </th>

        <th
          scope='col'
          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-600'
        >
          Status
        </th>
        <th
          scope='col'
          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-600'
        >
          Extra
        </th>
        <th
          scope='col'
          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-600'
        >
          Details
        </th>

        <th
          scope='col'
          className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-600 sm:pl-6'
        />
      </tr>
    </thead>
  )
}

const List = ({ collaborations, pagination, setPage, onRefetch }: IProps) => {
  return (
    <>
      <div className='flex flex-col bg-white'>
        <table className='min-w-full divide-y divide-gray-200 '>
          <TableHead />
          <tbody className='divide-y divide-gray-200 bg-white'>
            {pagination.numberOfHits === 0 && (
              <tr>
                <td colSpan={6}>
                  <p className='py-4 pl-4 pr-3 text-sm sm:pl-6 w-full'>
                    No collaborators found.
                  </p>
                </td>
              </tr>
            )}

            {collaborations.map((collaboration, index) => (
              <tr
                key={collaboration.id}
                className={classnames(
                  index % 2 === 0 ? 'bg-gray-50' : 'bg-white',
                )}
              >
                <CollaborationCard collaboration={collaboration} />
              </tr>
            ))}
          </tbody>
        </table>

        {pagination && (
          <PaginationComponent pagination={pagination} setPage={setPage} />
        )}
      </div>
    </>
  )
}

export { List }
