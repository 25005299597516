import React, { createRef, ReactElement } from 'react'
import classNames from 'classnames'
import { mergeRefs } from '../../utils/helpers'

export enum CheckboxType {
  radio = 'radio',
  checkbox = 'checkbox',
}

interface IDefaultProps {
  className?: string
  type: CheckboxType
  selected?: string | string[] | null | boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
}

interface IProps extends Partial<IDefaultProps> {
  value: any // string | boolean
  name: string
  title: string | ReactElement
  subtitle?: string
}

const defaultProps: IDefaultProps = {
  type: CheckboxType.checkbox,
  selected: null,
}

const CheckboxItem = React.forwardRef(
  (
    {
      className,
      type,
      selected,
      value,
      name,
      onChange,
      disabled,
      title,
      subtitle,
    }: IProps,
    ref,
  ) => {
    const localInputRef = createRef<HTMLInputElement>()

    let isSelected = false
    if (Array.isArray(selected) && type === 'checkbox' && value) {
      isSelected = selected.includes(value)
    }
    if (type === 'radio') {
      isSelected = selected === value
    }

    const handleOnClick = () => {
      localInputRef?.current?.click()
    }

    return (
      <div
        onClick={handleOnClick}
        className={classNames(
          className,
          'relative flex items-start p-4 cursor-pointer',
          { ['cursor-not-allowed']: disabled },
        )}
      >
        <div className='flex h-5 items-center'>
          <input
            className={classNames(
              'h-4 w-4 rounded border-gray-300 text-gray-600 disabled:accent-gray-300 focus:ring-gray-500 cursor-pointer',
              selected
                ? 'focus:ring-gray-800 accent-primary-800'
                : 'accent-gray-300 ',
            )}
            onChange={onChange}
            onClick={handleOnClick}
            ref={mergeRefs(ref, localInputRef)}
            value={value}
            type={type}
            name={name}
            disabled={disabled}
          />
        </div>
        <div
          className={classNames('ml-3 min-w-0 flex-1 text-sm', {
            ['']: isSelected,
          })}
        >
          <p
            className={classNames('font-medium text-gray-700 select-none', {
              ['font-bold text-gray-900']: isSelected,
            })}
          >
            {title}
          </p>
          {subtitle && (
            <p
              className={classNames('font-normal text-gray-600', {
                ['text-gray-600']: isSelected,
              })}
            >
              {subtitle}
            </p>
          )}
        </div>
      </div>
    )
  },
)

CheckboxItem.defaultProps = defaultProps

export { CheckboxItem }
