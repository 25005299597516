import { useMutation } from '@apollo/client'
import { useEffect } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import {
  Button,
  ButtonColor,
  Icon,
  IconType,
  NotificationType,
  TextArea,
} from '../../../components'
import { UPDATE_BRAND, UPDATE_CAMPAIGN } from '../../../gql'
import { Brand, BrandOnboardingStep, Campaign } from '../../../gql/types'
import { PricingData, getVideoPricing } from '../../../utils/PricingUtil'
import { AnalyticsEvent, trackEvent } from '../../../utils/analytics'
import { isEmptyString, useNotification } from '../../../utils/hooks'
import { mapCurrencyToSign } from '../../../utils/mappers'
import { Bullets } from '../components/Bullets'
import { Steps } from '../components/Steps'

type EditHooksFormData = {
  hooks: any[]
}

interface Props {
  brand: Brand
  campaign: Campaign
  pricing: PricingData
}

const SetVariations = ({ brand, campaign, pricing }: Props) => {
  const { addNotification } = useNotification()

  const videoPricing = getVideoPricing({
    currency: brand?.currency,
    videoDuration: campaign.videoDuration,
    pricing,
  })

  const [updateBrand, { loading: loadingUpdateBrand }] =
    useMutation(UPDATE_BRAND)
  const [updateCampaign, { loading: loadingUpdateCampaign }] =
    useMutation(UPDATE_CAMPAIGN)

  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors },
    setValue,
    watch,
  } = useForm<EditHooksFormData>({
    defaultValues: {
      hooks: campaign.hooks?.map((hook) => hook.description) || [],
    },
  })

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'hooks', // unique name for your Field Array
  })

  const canAddMoreVariations = fields.length < 10

  useEffect(() => {
    trackEvent(AnalyticsEvent.viewBrandsOnboardingSetHooks)
  }, [])

  const onSubmit = async ({ hooks }: EditHooksFormData) => {
    try {
      await Promise.all([
        updateCampaign({
          variables: {
            id: campaign.id,
            input: {
              hooks: hooks.filter((hook) => !isEmptyString(hook)),
            },
          },
        }),
        updateBrand({
          variables: {
            id: brand.id,
            input: {
              onboarding: BrandOnboardingStep.setCreatorDetails,
            },
          },
        }),
      ])
    } catch (error) {
      console.log('@error SetHooks', error.message)
      if (error.message) {
        setError('hooks', { type: 'custom', message: error.message })
        return
      }
    }
  }

  const goBack = async () => {
    try {
      await updateBrand({
        variables: {
          id: brand.id,
          input: {
            onboarding: BrandOnboardingStep.setAddons,
          },
        },
      })
    } catch (error) {
      addNotification(error.message, NotificationType.error)
    }
  }

  return (
    <div className='min-h-screen flex flex-col pb-14'>
      <div className='flex-grow'>
        <Steps brand={brand} stepCurrent={BrandOnboardingStep.setHooks} />

        <div className='min-h-full flex flex-col justify-center pt-4 px-4 md:mt-8 lg:px-8'>
          <div className='sm:mx-auto sm:w-full sm:max-w-2xl'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='md:shadow rounded-t-md sm:overflow-hidden'>
                <div className='py-5 md:bg-white space-y-6 sm:p-6'>
                  <div className='sm:mx-auto'>
                    <h2 className='mt-0 text-3xl font-extrabold text-gray-900'>
                      Would you like to add extra variations?
                    </h2>
                    <p className='mt-2 text-sm text-gray-600'>
                      Variations can be hooks or CTAs - different starting
                      points or end messages to catch viewers' interest. Each
                      variation is an altered version of the original video,
                      featuring a unique hook or call-to-action. The duration of
                      each variation is up to 5 seconds.
                    </p>
                    <p className='mt-2 text-sm text-gray-600'>
                      If you purchase variations for a campaign, each creator
                      will produce all the requested variations. Alternatively,
                      after receiving a video, you can buy individual variations
                      directly from your preferred creators.
                    </p>
                    <p className='mt-2 text-sm text-gray-600'>
                      Each variation costs{' '}
                      {mapCurrencyToSign(videoPricing.currency)}
                      {videoPricing.hook.priceCents / 100} extra.
                    </p>
                  </div>

                  <div className=''>
                    <ul>
                      {fields.map((item, index) => (
                        <li key={item.id} className='relative'>
                          <Controller
                            render={({ field }) => (
                              <TextArea
                                error={errors.hooks?.message}
                                placeholder={`Example: Start with a different hook "This is the best birthday gift."`}
                                minRows={4}
                                {...register(`hooks.${index}`)}
                                autoFocus={index === fields.length}
                              />
                            )}
                            name={`hooks.${index}`}
                            control={control}
                          />
                          <div
                            onClick={() => remove(index)}
                            className='absolute right-1 top-1 cursor-pointer'
                          >
                            <Icon
                              type={IconType.x}
                              className='h-5 w-5 text-primary'
                              aria-hidden='true'
                            />
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div>
                    {canAddMoreVariations && (
                      <Button
                        title='New variation'
                        type='button'
                        colour={ButtonColor.primary}
                        onClick={() => append('')}
                        icon={
                          <Icon
                            type={IconType.plus}
                            className='h-5 w-5 text-white'
                            aria-hidden='true'
                          />
                        }
                        iconPosition='left'
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className='flex justify-between md:shadow rounded-b-md py-3 md:bg-gray-50 sm:px-6'>
                <Button
                  title='Go back'
                  type='button'
                  colour={ButtonColor.white}
                  loading={loadingUpdateBrand}
                  disabled={loadingUpdateBrand}
                  onClick={goBack}
                />
                <Button
                  title='Continue'
                  type='submit'
                  loading={loadingUpdateBrand || loadingUpdateCampaign}
                  disabled={loadingUpdateBrand || loadingUpdateCampaign}
                />
              </div>
            </form>
          </div>
        </div>
      </div>

      <Bullets brand={brand} stepCurrent={BrandOnboardingStep.setHooks} />
    </div>
  )
}

export { SetVariations }
