import { BrandRoutes } from '../brands/BrandRoutes'

class UrlBuilder {
  static buildCampaignsUrl(brandId: number, queryString?: string): string {
    return `${BrandRoutes.campaigns.replace(':id', `${brandId}`)}${
      queryString ? `?${queryString}` : ''
    }`
  }

  static buildCampaignEditUrl(brandId: number, campaignId: number): string {
    return BrandRoutes.campaignEdit
      .replace(':id', `${brandId}`)
      .replace(':campaignId', `${campaignId}`)
  }

  static buildCampaignApplicantsUrl(
    brandId: number,
    campaignId: number,
    queryString?: string,
  ): string {
    return (
      BrandRoutes.campaignApplicants
        .replace(':id', `${brandId}`)
        .replace(':campaignId', `${campaignId}`) +
      (queryString ? `?${queryString}` : '')
    )
  }

  static buildCampaignCollaboratorsUrl(
    brandId: number,
    campaignId: number,
    queryString?: string,
  ): string {
    return (
      BrandRoutes.campaignCollaborators
        .replace(':id', `${brandId}`)
        .replace(':campaignId', `${campaignId}`) +
      (queryString ? `?${queryString}` : '')
    )
  }
}

export { UrlBuilder }
