import { Product } from '../../../../gql/types'
import { Avatar } from '../../../../elements'
import { GridButtons } from '../GridButtons'
import { mapCurrencyToSign } from '../../../../utils/mappers'
import { Link, useParams } from 'react-router-dom'
import { BrandRoutes } from '../../../BrandRoutes'
import { removeProtocol } from '../../../../utils/helpers'
import moment from 'moment-timezone'

interface IProps {
  product: Product
}

const GridItem = ({ product }: IProps) => {
  const { id } = useParams()

  return (
    <div
      key={product.id}
      className='col-span-1 divide-y divide-gray-200 rounded-lg bg-white border border-gray-200 shadow mx-2'
    >
      <Link
        to={BrandRoutes.productEdit
          .replace(':id', id)
          .replace(':productId', `${product.id}`)}
      >
        <div className='cursor-pointer flex w-full items-center justify-between align-top space-x-4 p-4'>
          <div className='flex flex-col'>
            <div className='flex text-gray-900 max-w-[150px] xs:max-w-[250px] sm:max-w-[400px] overflow-hidden truncate'>
              {product.name}
            </div>
            <div className='mt-1 mb-0 flex flex-shrink-0 text-sm text-gray-500'>
              {product.details && (
                <div className='max-w-[150px] xs:max-w-[250px] sm:max-w-[400px] overflow-hidden truncate'>
                  {product.details}
                </div>
              )}
            </div>
          </div>

          <Avatar
            type='product'
            src={product.photo?.url}
            size='sm'
            alt={product.name}
          />
        </div>
      </Link>
      <dl className='space-y-3 p-4'>
        <div className='grid grid-cols-2 items-center'>
          <dt className='text-sm font-medium text-gray-500'>Type</dt>
          <dd className='-ml-2 text-sm text-gray-900'>{product.type}</dd>
        </div>
        <div className='grid grid-cols-2 items-start'>
          <dt className='text-sm font-medium text-gray-500'>Link</dt>
          <dd className='-ml-2 flex flex-col text-sm text-gray-900'>
            <div className='flex gap-2'>
              <a
                href={product.link}
                className='font-medium text-blue-600 hover:text-blue-500 '
                target='_blank'
                rel='noopener noreferrer'
              >
                <div className='text-ellipsis overflow-hidden w-32 md:w-32 lg:56'>
                  {removeProtocol(product.link)}
                </div>
              </a>
            </div>
          </dd>
        </div>
        <div className='grid grid-cols-2 items-center'>
          <dt className='text-sm font-medium text-gray-500'>Price</dt>
          <dd className='-ml-2 text-sm text-gray-900'>
            <div className='flex gap-2'>
              {mapCurrencyToSign(product.currency)} {product.price}
            </div>
          </dd>
        </div>
        <div className='grid grid-cols-2 items-center'>
          <dt className='text-sm font-medium text-gray-500'>Created</dt>
          <dd className='-ml-2 text-sm text-gray-900'>
            <div className='flex gap-2'>
              {moment(product.createdAt).format('ll')}
            </div>
          </dd>
        </div>
      </dl>
      <GridButtons product={product} />
    </div>
  )
}

export { GridItem }
