import { useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { Link, useSearchParams } from 'react-router-dom'
import validator from 'validator'
import LOGO from '../../assets/logo/lancape_no_bg.png'
import { LOGIN } from '../../gql'
import { Session, User } from '../../gql/types'
import { AnalyticsEvent, trackEvent } from '../../utils/analytics'
import { ErrorCode } from '../../utils/ErrorCode'
import { AUTH_TOKEN } from '../../hooks'
import { PublicRoutes } from '../../utils/PublicRoutes'
import { Button, Icon, IconType, TextField } from '../../components'
import { captureQueryParams } from '../../utils/params'

const Login = () => {
  const [searchParams] = useSearchParams()
  captureQueryParams(searchParams)

  const [login, { loading }] = useMutation<{
    login: {
      user: User
      session: Session
    }
  }>(LOGIN)

  useEffect(() => {
    trackEvent(AnalyticsEvent.viewLogin)
  }, [])

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const onSubmit = async (form) => {
    try {
      const { data } = await login({
        variables: {
          email: form.email,
          password: form.password,
        },
      })

      localStorage.setItem(AUTH_TOKEN, data.login.session.token)
      window.location.reload()
    } catch (error) {
      if (error.message === ErrorCode.invalidEmailForLogin) {
        setError('email', {
          type: 'custom',
          message: 'This email is not registered.',
        })
        return
      }

      if (error.message === ErrorCode.invalidPasswordForLogin) {
        setError('password', {
          type: 'custom',
          message: 'Your password is invalid.',
        })
        return
      }
    }
  }

  return (
    <>
      <div className='min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
        <div className='sm:mx-auto sm:w-full sm:max-w-lg'>
          <img className='mx-auto h-12 w-auto' src={LOGO} alt='Workflow' />
          <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>
            Sign in your account
          </h2>
          <p className='mt-2 text-center text-sm text-gray-600'>
            Do you want to sign up?{' '}
            <Link
              to={PublicRoutes.registerBrand}
              className='font-medium text-indigo-500 hover:text-indigo-600'
            >
              {/* Join as a brand */}
              Register here
            </Link>
          </p>
        </div>

        <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-lg'>
          <div className='py-8 px-4 sm:px-10'>
            <form className='space-y-6' onSubmit={handleSubmit(onSubmit)}>
              <div>
                <TextField
                  error={errors.email?.message}
                  label='Email'
                  placeholder='you@example.com'
                  {...register('email', {
                    required: `Please set an email.`,
                    maxLength: {
                      value: 100,
                      message: `Please keep your email under 100 letters.`,
                    },
                    validate: {
                      value: (email) => {
                        if (validator.isEmail(email)) {
                          return true
                        }

                        return 'Invalid email address.'
                      },
                    },
                  })}
                />
              </div>

              <div>
                <label
                  htmlFor='password'
                  className='block text-sm font-medium text-gray-700'
                >
                  Password
                </label>
                <div className='relative mt-1'>
                  <input
                    type='password'
                    placeholder='******'
                    className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-500 focus:outline-none focus:ring-gray-800 focus:border-gray-800 sm:text-sm'
                    {...register('password', {
                      required: `Please set a password.`,
                      minLength: {
                        value: 6,
                        message: `Please set a password with minimum 6 letters.`,
                      },
                      maxLength: {
                        value: 100,
                        message: `Please keep your password under 100 letters.`,
                      },
                    })}
                  />
                  {errors.password?.message && (
                    <div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
                      <Icon
                        type={IconType.exclamationCircleFill}
                        className='h-5 w-5 text-red-500'
                        aria-hidden='true'
                      />
                    </div>
                  )}
                </div>
                {errors.password?.message && (
                  <p className='mt-2 text-sm text-red-600' id='email-error'>
                    {errors.password?.message}
                  </p>
                )}
              </div>

              <div>
                <Button
                  title='Login'
                  type='submit'
                  loading={loading}
                  disabled={loading}
                  width='w-full'
                />
              </div>
            </form>

            <div className='mt-6'>
              <div className='relative'>
                <div className='relative flex justify-center text-sm'>
                  <p className='px-2 text-center text-sm text-gray-600'>
                    Forgot your password?{' '}
                    <Link
                      to={PublicRoutes.forgotPassword}
                      className='font-medium text-indigo-500 hover:text-indigo-600'
                    >
                      Reset password
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { Login }
