import classNames from 'classnames'
import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react'
import { useInView } from 'framer-motion'
import React from 'react'
import { Avatar } from '../../../elements'

export interface ReviewItem {
  title?: string
  body: string
  author: string
  image?: string
}

interface IDefaultProps {}

interface IProps extends Partial<IDefaultProps> {
  reviews: ReviewItem[]
  header?: string
  title?: string
  subtitle?: string
  className?: string
}
const defaultProps: IDefaultProps = {}

function Review({
  title,
  body,
  author,
  className,
  image,
  ...props
}: Omit<React.ComponentPropsWithoutRef<'figure'>, keyof ReviewItem> &
  ReviewItem) {
  let animationDelay = useMemo(() => {
    let possibleAnimationDelays = ['0s', '0.1s', '0.2s', '0.3s', '0.4s', '0.5s']
    return possibleAnimationDelays[
      Math.floor(Math.random() * possibleAnimationDelays.length)
    ]
  }, [])

  return (
    <figure
      className={classNames(
        'animate-fade-in rounded-3xl bg-gray-50 p-6 opacity-0 shadow-lg shadow-gray-900/4',
        className,
      )}
      style={{ animationDelay }}
      {...props}
    >
      <blockquote className='text-gray-900'>
        <p className="mt-4 text-lg font-semibold leading-6 before:content-['“'] after:content-['”']">
          {title}
        </p>
        <p className='mt-3 text-base leading-7'>{body}</p>
      </blockquote>
      <div className='flex space-x-2 mt-3 items-center'>
        <div className='w-12'>
          <Avatar src={image} alt={author} size='sm' type='user' />
        </div>
        <figcaption className='text-sm text-gray-600'>{author}</figcaption>
      </div>
    </figure>
  )
}

function splitArray<T>(array: Array<T>, numParts: number) {
  let result: Array<Array<T>> = []
  for (let i = 0; i < array.length; i++) {
    let index = i % numParts
    if (!result[index]) {
      result[index] = []
    }
    result[index].push(array[i])
  }
  return result
}

function ReviewColumn({
  reviews,
  className,
  reviewClassName,
  msPerPixel = 0,
}: {
  reviews: ReviewItem[]
  className?: string
  reviewClassName?: (reviewIndex: number) => string
  msPerPixel?: number
}) {
  let columnRef = useRef<React.ElementRef<'div'>>(null)
  let [columnHeight, setColumnHeight] = useState(0)
  let duration = `${columnHeight * msPerPixel}ms`

  useEffect(() => {
    if (!columnRef.current) {
      return
    }

    let resizeObserver = new window.ResizeObserver(() => {
      setColumnHeight(columnRef.current?.offsetHeight ?? 0)
    })

    resizeObserver.observe(columnRef.current)

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  return (
    <div
      ref={columnRef}
      className={classNames('animate-marquee space-y-8 py-4', className)}
      style={{ '--marquee-duration': duration } as React.CSSProperties}
    >
      {reviews.concat(reviews).map((review, reviewIndex) => (
        <Review
          key={reviewIndex}
          aria-hidden={reviewIndex >= reviews.length}
          className={reviewClassName?.(reviewIndex % reviews.length)}
          {...review}
        />
      ))}
    </div>
  )
}

function ReviewGrid({ reviews }) {
  let containerRef = useRef<React.ElementRef<'div'>>(null)
  let isInView = useInView(containerRef, { once: true, amount: 0.4 })
  let columns = splitArray(reviews, 3)
  let column1 = columns[0]
  let column2 = columns[1]
  let column3 = splitArray(columns[2], 2)

  return (
    <div
      ref={containerRef}
      className='relative -mx-4 mt-16 grid h-[49rem] max-h-[150vh] grid-cols-1 items-start gap-8 overflow-hidden px-4 sm:mt-20 md:grid-cols-2 lg:grid-cols-3'
    >
      {isInView && (
        <>
          <ReviewColumn
            reviews={
              [...column1, ...column3.flat(), ...column2] as ReviewItem[]
            }
            reviewClassName={(reviewIndex) =>
              classNames(
                reviewIndex >= column1.length + column3[0].length &&
                  'md:hidden',
                reviewIndex >= column1.length && 'lg:hidden',
              )
            }
            msPerPixel={10}
          />
          <ReviewColumn
            reviews={[...column2, ...column3[1]] as ReviewItem[]}
            className='hidden md:block'
            reviewClassName={(reviewIndex) =>
              reviewIndex >= column2.length ? 'lg:hidden' : ''
            }
            msPerPixel={15}
          />
          <ReviewColumn
            reviews={column3.flat() as ReviewItem[]}
            className='hidden lg:block'
            msPerPixel={10}
          />
        </>
      )}
    </div>
  )
}

const ReviewsCarousel: FunctionComponent<IProps> = ({
  reviews,
  header,
  title,
  subtitle,
  className,
}) => {
  return (
    <div className={classNames('py-2', className)}>
      <div className='mx-auto max-w-7xl px-6 lg:px-8'>
        <div className='mx-auto max-w-2xl sm:text-center'>
          {header && (
            <h2 className='text-base font-semibold leading-7 text-secondary'>
              {header}
            </h2>
          )}
          {title && (
            <p className='mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
              {title}
            </p>
          )}
          {subtitle && (
            <p className='mt-6 text-lg leading-8 text-gray-600'>{subtitle}</p>
          )}
        </div>
        <ReviewGrid reviews={reviews} />
      </div>
    </div>
  )
}

ReviewsCarousel.defaultProps = defaultProps

export { ReviewsCarousel }
