import { Invoice } from '../../../../gql/types'
import { ListItem } from './ListItem'

const TableHead = () => {
  return (
    <thead className='bg-white'>
      <tr>
        <th
          scope='col'
          className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-600 sm:pl-6'
        >
          Date
        </th>
        <th
          scope='col'
          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-600'
        >
          Amount
        </th>
        <th
          scope='col'
          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-600'
        >
          Status
        </th>
        <th
          scope='col'
          className='relative py-3.5 pl-3 pr-4 text-left text-sm font-semibold text-gray-600 sm:pr-6'
        >
          <span className='sr-only'> Download invoice PDF</span>
        </th>
      </tr>
    </thead>
  )
}

interface IProps {
  invoices: Invoice[]
}

const InvoicesList = ({ invoices }: IProps) => {
  return (
    <>
      <div className='flex flex-col mt-4'>
        <table className='min-w-full divide-y divide-gray-200'>
          <TableHead />
          <tbody className='divide-y divide-gray-200 bg-white'>
            {invoices?.length === 0 && (
              <tr>
                <td colSpan={6}>
                  <p className='p-4 text-sm w-full'>No invoices.</p>
                </td>
              </tr>
            )}
            {invoices?.map((invoice, index) => (
              <ListItem key={index} invoice={invoice} index={index} />
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export { InvoicesList }
