import { useLocation, useParams } from 'react-router-dom'
import {
  Button,
  ButtonColor,
  ButtonSize,
  Icon,
  IconType,
} from '../../../../components'
import { CreatorRoutes } from '../../../CreatorRoutes'

interface IDefaultProps {}

const defaultProps: IDefaultProps = {}

interface IProps extends Partial<IDefaultProps> {
  setIsCampaignSliderOpen: (event: any) => void
  setIsApplyToCampaignModalOpen: (event: any) => void
}

const GridButtons = ({
  setIsCampaignSliderOpen,
  setIsApplyToCampaignModalOpen,
}: IProps) => {
  const { pathname } = useLocation()
  const { id } = useParams()

  return (
    <>
      <div>
        <div className='-mt-px flex divide-x divide-gray-200'>
          <div className='-ml-px flex w-0 flex-1 justify-center'>
            {/* <div
              className='group flex items-center px-4 py-4 text-sm text-gray-800'
              onClick={() => setIsCampaignSliderOpen(true)}
            >
              Details
            </div> */}
            <Button
              title='Details'
              type='button'
              colour={ButtonColor.white}
              onClick={() => {
                setIsCampaignSliderOpen(true)
              }}
              className='w-full py-3'
              icon={
                <Icon
                  type={IconType.layers}
                  className='h-4 w-4 '
                  aria-hidden='true'
                />
              }
              iconPosition='left'
            />
          </div>

          <div className='-ml-px flex w-0 flex-1 justify-center cursor-pointer'>
            <Button
              title={
                pathname === CreatorRoutes.invitations.replace(':id', `${id}`)
                  ? 'Apply'
                  : 'Apply'
              }
              type='button'
              colour={ButtonColor.primary}
              onClick={() => setIsApplyToCampaignModalOpen(true)}
              className='w-full py-3'
            />
          </div>
        </div>
      </div>
    </>
  )
}

GridButtons.defaultProps = defaultProps

export { GridButtons }
