import { useMutation } from '@apollo/client'
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Loader, NotificationType } from '../../components'
import {
  CONFIRM_CREDIT_PACK_PAYMENT,
  MutationConfirmCreditPackPaymentResponse,
} from '../../gql'
import { MutationConfirmCreditPackPaymentArgs, User } from '../../gql/types'
import { trackPurchase } from '../../utils/analytics'
import { DEAL_CODE } from '../../utils/constants'
import { useNotification } from '../../utils/hooks'
import { BrandRoutes } from '../BrandRoutes'

interface IProps {
  user: User
}

export const ConfirmCreditPackPayment = ({ user }: IProps) => {
  const { addNotification } = useNotification()
  const navigate = useNavigate()

  const [confirmCreditPackPayment] = useMutation<
    MutationConfirmCreditPackPaymentResponse,
    MutationConfirmCreditPackPaymentArgs
  >(CONFIRM_CREDIT_PACK_PAYMENT)

  const [searchParams] = useSearchParams()
  const sessionId = searchParams.get('session_id')
  const redirectUrl: string = searchParams.get('redirectUrl')

  useEffect(() => {
    const confirmSubscriptionSuccess = async () => {
      try {
        const { data } = await confirmCreditPackPayment({
          variables: {
            stripeSessionId: sessionId,
          },
        })

        trackPurchase({
          transactionId: data.confirmCreditPackPayment.transactionId,
          currency: data.confirmCreditPackPayment.currency,
          priceCents: data.confirmCreditPackPayment.priceCents,
        })

        localStorage.removeItem(DEAL_CODE)

        navigate(
          redirectUrl ??
            BrandRoutes.creditPacks.replace(
              ':id',
              `${data.confirmCreditPackPayment.brand.id}`,
            ),
        )

        addNotification(
          'Your credit pack order has been confirmed.',
          NotificationType.success,
        )
      } catch (error) {
        addNotification(
          'Error in credit pack order confirmation.',
          NotificationType.error,
        )
      }
    }

    if (sessionId) {
      confirmSubscriptionSuccess()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmCreditPackPayment, sessionId])

  return <Loader />
}
