import classnames from 'classnames'
import { FunctionComponent } from 'react'
import { Icon, IconType } from '..'

interface IDefaultProps {
  className?: string
  iconContainerClassName?: string
  iconClassName?: string
}

interface IProps extends Partial<IDefaultProps> {
  ratingScore: number
  ratingCount?: number
}

const defaultProps: IDefaultProps = {
  className: null,
  iconContainerClassName: null,
  iconClassName: null,
}

const stars = [1, 2, 3, 4, 5]

const StarRating: FunctionComponent<IProps> = ({
  ratingScore,
  ratingCount,
  className,
  iconContainerClassName,
  iconClassName,
  ...rest
}) => {
  if (ratingScore === null || ratingScore === 0) {
    return <p className='text-sm text-gray-500'>No reviews</p>
  }

  const score = Math.round(ratingScore)
  return (
    <div className='flex flex-col'>
      <div className={classnames('flex flex-row text-xl', className)} {...rest}>
        {stars.map((r) =>
          r <= score ? (
            <div className={iconContainerClassName} key={r}>
              <Icon
                key={r}
                type={IconType.starFilled}
                className={classnames('text-slate-700', iconClassName)}
              />
            </div>
          ) : (
            <div className={iconContainerClassName} key={r}>
              <Icon
                key={r}
                type={IconType.starOutlined}
                className={classnames('text-slate-700', iconClassName)}
              />
            </div>
          ),
        )}
      </div>
      {ratingCount && (
        <p className='text-xs text-gray-500'>out of {ratingCount} reviews</p>
      )}
    </div>
  )
}

StarRating.defaultProps = defaultProps

export { StarRating }
