import { useQuery } from '@apollo/client'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Loader } from '../../components'
import {
  GET_COLLABORATIONS_BY_CREATOR_ID,
  GetCollaborationsByCreatorIdResponse,
} from '../../gql'
import {
  CollaborationStatus,
  GetCollaborationsSort,
  QueryGetCollaborationsByCreatorIdArgs,
  User,
} from '../../gql/types'
import { Header } from './components/Header'
import { List } from './components/List'
import { PricingData } from '../../utils/PricingUtil'
import { Grid } from './components/Grid'

interface IProps {
  user: User
  pricing: PricingData
}

const ActiveCampaigns = ({ user, pricing }: IProps) => {
  const { id } = useParams()

  const limit = 20
  const [skip, setSkip] = useState(0)

  const { loading, error, data } = useQuery<
    GetCollaborationsByCreatorIdResponse,
    QueryGetCollaborationsByCreatorIdArgs
  >(GET_COLLABORATIONS_BY_CREATOR_ID, {
    variables: {
      creatorId: parseInt(id),
      options: {
        filters: {
          status: [
            CollaborationStatus.creatorAccepted,
            CollaborationStatus.productShipped,
            CollaborationStatus.productDelivered,
            CollaborationStatus.contentUploaded,
            CollaborationStatus.contentAccepted,
            CollaborationStatus.contentRejected,
          ],
        },
        sort: GetCollaborationsSort.lastUpdated,
        limit,
        skip,
      },
    },
  })

  const setPage = (page: number) => {
    setSkip(page * limit)
  }

  return (
    <div>
      <Header />

      {loading && <Loader />}
      {!loading && (
        <div className='pb-4 w-auto h-fit'>
          <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='inline-block min-w-full py-2 px-1 align-middle md:px-6 lg:px-8'>
              <div className='shadow md:w-auto ring-1 ring-black ring-opacity-5 rounded-t'>
                <div className='hidden lg:block'>
                  <List
                    pricing={pricing}
                    collaborations={data?.getCollaborationsByCreatorId?.items}
                    pagination={data?.getCollaborationsByCreatorId?.pagination}
                    setPage={setPage}
                  />
                </div>
                <div className='block lg:hidden'>
                  <Grid
                    pricing={pricing}
                    collaborations={data?.getCollaborationsByCreatorId?.items}
                    pagination={data?.getCollaborationsByCreatorId?.pagination}
                    setPage={setPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export { ActiveCampaigns }
