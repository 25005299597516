import { FunctionComponent } from 'react'
import { Creator } from '../../../gql/types'
import classNames from 'classnames'
import { PortfolioGridNew } from '../../../components/PortfolioGridNew'

interface IDefaultProps {
  className?: string
}

interface IProps extends Partial<IDefaultProps> {
  creator: Creator
}

const defaultProps: IDefaultProps = {
  className: null,
}

const PortfolioCard: FunctionComponent<IProps> = ({ creator, className }) => {
  return (
    <div
      className={classNames(
        'bg-white shadow sm:overflow-hidden rounded-lg mt-4',
        className,
      )}
    >
      <div className='px-4 py-5 sm:px-6'>
        <h3 className='text-lg leading-6 font-medium text-gray-900'>
          Portfolio
        </h3>
      </div>

      <div className='border-t border-gray-200 sm:px-2 py-4'>
        <PortfolioGridNew creator={creator} limitAndUseSlider={true} />
      </div>
    </div>
  )
}

PortfolioCard.defaultProps = defaultProps

export { PortfolioCard }
