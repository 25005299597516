import { Pagination as PaginationComponent } from '../../../../components'
import { Campaign, Creator, Pagination } from '../../../../gql/types'
import { PricingData } from '../../../../utils/PricingUtil'
import { GridItem } from '../GridItem'

interface IProps {
  creator: Creator
  campaigns: { campaign: Campaign; invitationId?: number | null }[]
  pagination: Pagination
  pricing: PricingData
  setPage(page: number): void
  onRefetch: () => void
}

const Grid = ({
  creator,
  campaigns,
  pagination,
  pricing,
  setPage,
  onRefetch,
}: IProps) => {
  const onAppliedToCampaign = () => {
    onRefetch()
  }

  return (
    <>
      <ul
        role='list'
        className='py-2 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8'
      >
        {campaigns?.map((item) => (
          <GridItem
            key={`gridItem-${item.campaign?.uuid}`}
            creator={creator}
            campaign={item.campaign}
            invitationId={item.invitationId}
            pricing={pricing}
            onAppliedToCampaign={onAppliedToCampaign}
          />
        ))}
        {campaigns?.length === 0 && (
          <div className='py-4 pl-4 pr-3 text-sm sm:pl-6 w-full'>
            No opportunities found.
          </div>
        )}
      </ul>
      {pagination && (
        <PaginationComponent
          pagination={pagination}
          setPage={setPage}
          className='bg-white'
        />
      )}
    </>
  )
}

export { Grid }
