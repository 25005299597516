import { useState } from 'react'
import { Pagination as PaginationComponent } from '../../../../../components'
import { Collaboration, Pagination } from '../../../../../gql/types'
import { GridItem } from '../GridItem'
import { ViewCreatorSlider } from '../../../../shared/ViewCreatorSlider'

interface IProps {
  collaborations: Collaboration[]
  pagination?: Pagination
  setPage(page: number): void
  onRefetch: () => void
}

const Grid = ({ collaborations, pagination, setPage, onRefetch }: IProps) => {
  const [isCreatorSliderOpen, setIsCreatorSliderOpen] = useState(false)
  const [selectedCollaboration, setSelectedCollaboration] = useState<
    Collaboration | undefined
  >(undefined)

  const onCollaborationAccepted = () => {
    setIsCreatorSliderOpen(false)
    setSelectedCollaboration(undefined)
    onRefetch()
  }

  return (
    <>
      <ul
        role='list'
        className='py-2 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8'
      >
        {collaborations.map((collaboration, index) => (
          <GridItem
            collaboration={collaboration}
            key={`gridItem-${collaboration?.uuid}`}
            setSelectedCollaboration={setSelectedCollaboration}
            setIsCreatorSliderOpen={setIsCreatorSliderOpen}
          />
        ))}
        {collaborations?.length === 0 && (
          <div className='py-4 pl-4 pr-3 text-sm sm:pl-6 w-full'>
            No applicants yet. Check back later.
          </div>
        )}
      </ul>
      {pagination && (
        <PaginationComponent pagination={pagination} setPage={setPage} />
      )}

      {selectedCollaboration && (
        <ViewCreatorSlider
          open={isCreatorSliderOpen}
          setOpen={setIsCreatorSliderOpen}
          creator={selectedCollaboration.creator}
          collaboration={selectedCollaboration}
          onCollaborationAccepted={() => onCollaborationAccepted()}
        />
      )}
    </>
  )
}

export { Grid }
