import { Icon, IconType, Slider } from '../../../../components'
import { Divider } from '../../../../components/Divider'
import { Avatar } from '../../../../elements'
import { Brand } from '../../../../gql/types'
import { formatLongMessage } from '../../../../utils/helpers'

interface IProps {
  open: boolean
  setOpen: any
  brand: Brand
}

const ViewProfileSlider = ({ open, setOpen, brand }: IProps) => {
  return (
    <Slider setOpen={setOpen} open={open}>
      <div className='flex h-full flex-col overflow-y-scroll bg-white shadow-xl'>
        {/* Header */}

        <div className='sm:sticky top-0 z-10 bg-white'>
          <div className='px-4 pt-6 sm:px-6'>
            <div className='flex items-center justify-between'>
              <div className='flex items-center'>
                <Avatar
                  type='brand'
                  src={brand.logo?.url}
                  size='md'
                  alt={brand.name || ''}
                />
                <h3 className='ml-2 text-xl font-bold text-gray-900 sm:text-2xl'>
                  {brand.name}
                </h3>
              </div>
              <div className='ml-3 flex items-center'>
                <button
                  type='button'
                  className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none'
                  onClick={() => setOpen(false)}
                >
                  <span className='sr-only'>Close panel</span>
                  <Icon
                    type={IconType.x}
                    className='h-6 w-6'
                    aria-hidden='true'
                  />
                </button>
              </div>
            </div>
          </div>
          <Divider className='py-6' />
        </div>

        {/* Main */}
        <div className='pb-1 sm:pb-6 px-4 flex flex-col sm:px-6'>
          <div className='py-5 @md:grid @md:grid-cols-3 @md:gap-4'>
            <dt className='text-sm font-medium text-gray-500'>Website</dt>
            <dd className='mt-1 text-sm text-gray-900 @md:col-span-2 @md:mt-0'>
              <a
                href={brand.website}
                className='text-blue-600 hover:text-blue-500'
                target='_blank'
                rel='noopener noreferrer'
              >
                {brand.website}
              </a>
            </dd>
          </div>

          <div className='py-5 @md:grid @md:grid-cols-3 @md:gap-4'>
            <dt className='text-sm font-medium text-gray-500'>Description</dt>
            <dd
              className='mt-1 text-sm text-gray-900 @md:col-span-2 @md:mt-0'
              dangerouslySetInnerHTML={{
                __html: formatLongMessage(brand.description),
              }}
            ></dd>
          </div>
        </div>
      </div>
    </Slider>
  )
}

export { ViewProfileSlider }
