import { useState } from 'react'
import { Button, ButtonColor, Icon, IconType } from '../../../../components'
import { Collaboration, CollaborationStatus } from '../../../../gql/types'
import { Link, useParams } from 'react-router-dom'
import { BrandRoutes } from '../../../../brands/BrandRoutes'
import { CollaborationClipsSlider } from '../../../shared/CollaborationClipsSlider'
import { ShipProductModal } from '../../../shared/ShipProductModal'
import { AcceptContentModal } from '../../../shared/AcceptContentModal'
import { ViewCreatorSlider } from '../../../shared/ViewCreatorSlider'
import { OrderHooksModal } from '../../../shared/OrderHooksModal'
import { OrderAddonsVariationsModal } from '../../../shared/OrderAddonsVariationsModal'

interface IProps {
  collaboration: Collaboration
  className?: string
}

interface IDefaultProps {}

const defaultProps: IDefaultProps = {}

interface IProps extends Partial<IDefaultProps> {}

const GridButtons = ({ collaboration, className }: IProps) => {
  const { id } = useParams()
  const [isShipProductModalOpen, setIsShipProductModalOpen] = useState(false)
  const [isViewClipsSliderOpen, setIsViewClipsSliderOpen] = useState(false)
  const [isViewProfileSliderOpen, setIsViewProfileSliderOpen] = useState(false)
  const [isAcceptContentModalOpen, setIsAcceptContentModalOpen] =
    useState(false)
  const [isOrderHooksOpen, setIsOrderHooksOpen] = useState(false)
  const [isOrderOpen, setIsOrderOpen] = useState(false)

  const RowButton = (collaboration: Collaboration) => {
    switch (collaboration.status) {
      case CollaborationStatus.creatorAccepted:
        return (
          <Button
            title='Ship product'
            type='button'
            colour={ButtonColor.primary}
            onClick={() => {
              setIsShipProductModalOpen(true)
            }}
            className='w-full'
          />
        )
      case CollaborationStatus.contentUploaded:
        return (
          <Button
            title='Clips'
            type='button'
            colour={ButtonColor.primary}
            onClick={() => setIsViewClipsSliderOpen(true)}
            className='w-full'
          />
        )
      case CollaborationStatus.contentAccepted:
        return (
          <Button
            title='Clips'
            type='button'
            colour={ButtonColor.primary}
            onClick={() => setIsViewClipsSliderOpen(true)}
            className='w-full'
          />
        )
      case CollaborationStatus.creatorApplied:
      case CollaborationStatus.productShipped:
      case CollaborationStatus.productDelivered:
        return undefined
        return (
          <Button
            title='Profile'
            type='button'
            colour={ButtonColor.primary}
            onClick={() => setIsViewProfileSliderOpen(true)}
            className='w-full'
          />
        )
    }
  }

  return (
    <>
      <div>
        <div className='-mt-px flex divide-x divide-gray-200'>
          <div className='-ml-px flex w-0 flex-1 justify-center'>
            {collaboration.isActive && (
              <Button
                // title='Message'
                type='button'
                colour={ButtonColor.white}
                to={
                  BrandRoutes.inboxChat
                    .replace(':id', `${id}`)
                    .replace(':uuid', `${id}-${collaboration.creator.id}`) +
                  '?collaborationId=' +
                  collaboration.id
                }
                icon={
                  <Icon
                    type={IconType.inbox}
                    className='h-4 w-4'
                    aria-hidden='true'
                  />
                }
                iconPosition='left'
                tooltip='Message'
                className='w-full py-3'
              />
            )}
            {/* <Button
              // title='Buy hooks'
              type='button'
              colour={ButtonColor.secondary}
              onClick={() => setIsOrderHooksOpen(true)}
              icon={
                <Icon
                  type={IconType.videoCamera}
                  className='h-4 w-4 text-white'
                  aria-hidden='true'
                />
              }
              iconPosition='left'
              tooltip='Buy more variations'
              className='w-full py-3'
            /> */}
            <Button
              // title='Buy more addons/variations'
              type='button'
              colour={ButtonColor.secondary}
              onClick={() => setIsOrderOpen(true)}
              icon={
                <Icon
                  type={IconType.plus}
                  className='h-4 w-4 text-white'
                  aria-hidden='true'
                />
              }
              iconPosition='left'
              tooltip='Buy more variations/addons'
              className='w-full py-3'
            />
          </div>
          <div className='-ml-px flex w-0 flex-1 justify-center'>
            {RowButton(collaboration)}
          </div>
        </div>
        <CollaborationClipsSlider
          open={isViewClipsSliderOpen}
          setOpen={setIsViewClipsSliderOpen}
          collaboration={collaboration}
        />

        <ViewCreatorSlider
          open={isViewProfileSliderOpen}
          setOpen={setIsViewProfileSliderOpen}
          creator={collaboration.creator}
          collaboration={collaboration}
        />

        <ShipProductModal
          collaboration={collaboration}
          isOpen={isShipProductModalOpen}
          setIsOpen={setIsShipProductModalOpen}
        />

        <AcceptContentModal
          collaboration={collaboration}
          isOpen={isAcceptContentModalOpen}
          setIsOpen={setIsAcceptContentModalOpen}
        />

        {/* <OrderHooksModal
          collaboration={collaboration}
          title={`Buy more variations`}
          description={`Each variation is an altered version of the original video, featuring a unique hook or call-to-action. The duration of each variation is up to 5 seconds.`}
          open={isOrderHooksOpen}
          setOpen={setIsOrderHooksOpen}
        /> */}
        {setIsOrderOpen && (
          <OrderAddonsVariationsModal
            collaboration={collaboration}
            open={isOrderOpen}
            setOpen={setIsOrderOpen}
          />
        )}
      </div>
    </>
  )
}

GridButtons.defaultProps = defaultProps

export { GridButtons }
