import {
  Brand,
  Currency,
  GetProductsSort,
  MutationGetProductPhotoUploadUrlArgs,
  Product,
  ProductType,
} from '../../../../gql/types'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import {
  GetProductPhotoUploadUrlResponse,
  GET_PRODUCTS_BY_BRAND_ID,
  GET_PRODUCT_PHOTO_UPLOAD_URL,
  UPDATE_PRODUCT,
} from '../../../../gql'
import { Controller, useForm } from 'react-hook-form'
import { cleanUrl, removeProtocol } from '../../../../utils/helpers'
import { BrandRoutes } from '../../../BrandRoutes'
import {
  Button,
  Icon,
  IconType,
  Loader,
  NotificationType,
  RadioGroup,
  Select,
  TextArea,
  TextField,
} from '../../../../components'
import {
  mapCurrencyToSign,
  productTypeOptions,
} from '../../../../utils/mappers'
import validator from 'validator'
import { useNotification } from '../../../../utils/hooks'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { PhotoUploadUrl } from '../../components/PhotoUploadUrl'

type EditNewProductFormData = {
  productPhotoUrl: string
  productName: string
  productType: ProductType
  productLink: string
  productDetails: string
  productPrice: number
  productCurrency: Currency
}

interface IProps {
  product: Product
  brand: Brand
}

const Edit = ({ product, brand }: IProps) => {
  const { addNotification } = useNotification()
  const navigate = useNavigate()
  const [photoUrl, setPhotoUrl] = useState(
    product.photo?.url && product.photo?.url !== null
      ? new URL(product.photo?.url).pathname.replace(/^.+?[/]/, '')
      : null,
  )
  const [selectedFile, setSelectedFile] = useState(null)
  const [errorSelectedFile, setErrorSelectedFile] = useState(false)

  const [updateProduct, { loading, error }] = useMutation(UPDATE_PRODUCT, {
    refetchQueries: [
      {
        query: GET_PRODUCTS_BY_BRAND_ID,
        variables: {
          brandId: brand.id,
          options: {
            query: '',
            filters: {},
            sort: GetProductsSort.newest,
          },
        },
      },
    ],
  })

  const [getProductPhotoUploadUrl, { loading: loadingProductPhotoUploadUrl }] =
    useMutation<
      GetProductPhotoUploadUrlResponse,
      MutationGetProductPhotoUploadUrlArgs
    >(GET_PRODUCT_PHOTO_UPLOAD_URL)

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setError,
    clearErrors,
  } = useForm<EditNewProductFormData>({
    defaultValues: {
      productPhotoUrl: product.photo?.url ?? null,
      productName: product.name ?? null,
      productType: product.type ?? ProductType.physical,
      productLink: product.link ? removeProtocol(product.link) : null,
      productDetails: product.details ?? null,
      productPrice: product.price ?? null,
      productCurrency: product.currency ?? null,
    },
  })

  useEffect(() => {
    if (watch('productPrice') === null) {
      return
    }

    if (
      watch('productPrice') > 0 ||
      (watch('productPrice') === 0 &&
        watch('productType') === ProductType.digital)
    ) {
      setError('productPrice', { type: 'custom', message: null })
      return
    }

    setError('productPrice', {
      type: 'custom',
      message: `Please set a correct product price.`,
    })
  }, [watch('productPrice'), watch('productType')])

  const onProductPhotoUpload = async (file: File) => {
    if (!file) {
      setErrorSelectedFile(true)
      return
    }

    setSelectedFile(file)
    setErrorSelectedFile(false)
    clearErrors('productPhotoUrl')

    try {
      const { data } = await getProductPhotoUploadUrl({
        variables: {
          brandId: brand.id,
          mime: file.type,
        },
      })

      await axios.put(data?.getProductPhotoUploadUrl?.uploadUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
      })

      setPhotoUrl(data.getProductPhotoUploadUrl.fileName)
    } catch (error) {
      console.log('error onProductPhotoUpload', error.message)
    }
  }

  const onSubmit = async (data: EditNewProductFormData) => {
    if (!selectedFile && product.photo?.url === null) {
      setErrorSelectedFile(true)
      return
    }

    const productPrice = +data.productPrice
    const productLink =
      data.productLink && data.productLink !== ''
        ? cleanUrl(data.productLink)
        : null

    try {
      await updateProduct({
        variables: {
          id: product.id,
          input: {
            name: data.productName,
            type: data.productType,
            link: productLink,
            details: data.productDetails,
            price: productPrice,
            currency: data.productCurrency,
            photoFileName: selectedFile ? photoUrl : product.photo?.name,
          },
        },
      })

      addNotification('Product updated successfully', NotificationType.success)
      navigate(BrandRoutes.products.replace(':id', `${brand.id}`))
    } catch (error) {
      console.log('@error updateProduct', error.message)
      addNotification(
        `Something went wrong. Please try again.`,
        NotificationType.error,
      )
    }
  }

  if (loading) {
    return <Loader />
  }

  if (error) {
    console.log('error GET_BRAND_BY_ID', error)
    return <p>There has been an error. Try refreshing the page</p>
  }

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='space-y-8 divide-y divide-gray-200'
      >
        <div className='space-y-8 divide-y divide-gray-200'>
          <div>
            <div>
              <h3 className='text-lg font-semibold leading-6 text-gray-900 font-sans'>
                Product
              </h3>
              <p className='mt-1 text-sm font-normal leading-5 text-gray-500 font-sans'>
                What are your products’ features? Write out any details you
                consider set your product apart.
              </p>
            </div>

            <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
              <div className='sm:col-span-6'>
                <PhotoUploadUrl
                  photoUpload={
                    selectedFile
                      ? URL.createObjectURL(selectedFile)
                      : product.photo?.url ?? null
                  }
                  onChange={onProductPhotoUpload}
                />
                <p className='mt-1 text-sm font-normal leading-5 text-gray-500 font-sans'>
                  Please use a square image.
                </p>
                {errorSelectedFile && !photoUrl && (
                  <div
                    className='relative mt-2 text-sm text-red-600'
                    id='errorSelectedFile'
                  >
                    <div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
                      <Icon
                        type={IconType.exclamationCircleFill}
                        className='h-5 w-5 text-red-500'
                        aria-hidden='true'
                      />
                    </div>
                    Please set a photo.
                  </div>
                )}
              </div>

              <div className='sm:col-span-6'>
                <TextField
                  error={errors.productName?.message}
                  label='Name'
                  placeholder='Add a product'
                  {...register('productName', {
                    required: `Please set a product name.`,
                    minLength: {
                      value: 2,
                      message: `Your product name must be longer than or equal to 2 characters.`,
                    },
                    maxLength: {
                      value: 100,
                      message: `Your product name cannot have more than 100 characters.`,
                    },
                  })}
                />
              </div>

              <div className='sm:col-span-6'>
                <TextField
                  error={errors.productPrice?.message}
                  label='Price'
                  placeholder='0.00'
                  className='pl-7'
                  leftElement={
                    <span className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
                      <span className='text-gray-600 sm:text-sm'>
                        {mapCurrencyToSign(watch('productCurrency'))}
                      </span>
                    </span>
                  }
                  rightElement={
                    <div className='absolute inset-y-0 right-0 flex items-center'>
                      <Controller
                        name='productCurrency'
                        control={control}
                        render={({ field }) => (
                          <Select
                            options={Object.keys(Currency).map((currency) =>
                              (currency as Currency).toUpperCase(),
                            )}
                            field={field}
                            className='-mt-1 w-16 rounded-l-none bg-transparent'
                          />
                        )}
                      />
                    </div>
                  }
                  {...register('productPrice', {
                    required: `Please set a product price.`,
                    maxLength: {
                      value: 100,
                      message: `Product price too long.`,
                    },
                    valueAsNumber: true,
                    validate: (value) => {
                      if (
                        value > 0 ||
                        (value === 0 &&
                          watch('productType') === ProductType.digital)
                      ) {
                        return true
                      }

                      return `Please set a correct product price.`
                    },
                  })}
                />
                <p className='mt-1 block text-sm font-medium text-gray-500'>
                  The product price must be a positive numerical value, with
                  zero allowable only for digital products.
                </p>
              </div>

              <div className='sm:col-span-6'>
                <Controller
                  name='productType'
                  control={control}
                  rules={{ required: `Please set a product type.` }}
                  render={({ field }) => (
                    <RadioGroup
                      options={productTypeOptions}
                      field={field}
                      className='mt-1 sm:grid-cols-2'
                      error={errors?.productType?.message}
                    />
                  )}
                />
              </div>

              <div className='sm:col-span-6'>
                <TextField
                  error={errors.productLink?.message}
                  label='Product link'
                  placeholder='www.example.com/product'
                  className={'rounded-none rounded-r-md'}
                  leftElement={
                    <span className='inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm'>
                      https://
                    </span>
                  }
                  {...register('productLink', {
                    // required: `Please set a link for your product.`,
                    maxLength: {
                      value: 1000,
                      message: `Your product link is too long.`,
                    },
                    validate: {
                      value: (productLink) => {
                        if (
                          productLink === null ||
                          productLink === '' ||
                          validator.isURL(productLink)
                        ) {
                          return true
                        }

                        return 'Invalid link.'
                      },
                    },
                  })}
                />
              </div>

              <div className='sm:col-span-6'>
                <TextArea
                  error={errors.productDetails?.message}
                  label='Other details'
                  placeholder='Add product details...'
                  {...register('productDetails', {
                    // required: `Please add more details about your product.`,
                    maxLength: {
                      value: 10000,
                      message: `Your product description too long.`,
                    },
                  })}
                />
                <p className='mt-1 block text-sm font-medium text-gray-500'>
                  Any info that can be useful to our creators
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className='p-5'>
          <div className='flex justify-end'>
            <Button
              type='submit'
              title='Update'
              loading={loading}
              disabled={loading || loadingProductPhotoUploadUrl}
              onClick={() => {
                if (!selectedFile || selectedFile === null) {
                  setErrorSelectedFile(true)
                }
              }}
            />
          </div>
        </div>
      </form>
    </>
  )
}

export { Edit }
