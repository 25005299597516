import {
  CampaignAddon,
  CreatorQuality,
  Currency,
  PriceItem,
  Product,
  VideoDuration,
  VideoPricing,
} from '../gql/types'

export interface PricingData {
  videoPricing: VideoPricing[]
}

/**
 * We calculate how much a video cost per video duration, quality and currency
 * We also calculate how much a hook cost per video duration and currency
 *
 * We then multiply the price per video by the quantity of videos
 * We then multiply the price per hook by the quantity of hooks
 *
 * We then add the price per videos and the price per hooks
 */
export const getCampaignPricing = ({
  currency,
  duration,
  quantity,
  quality,
  hooksCount,
  addons,
  pricing,
}: {
  currency: Currency
  duration: VideoDuration
  quantity: number
  quality: CreatorQuality
  hooksCount: number
  addons: CampaignAddon[]
  pricing: PricingData
}): number => {
  const videoPricing = getVideoPricing({
    currency: currency,
    videoDuration: duration,
    pricing,
  })

  // we calculate the price for the videos only
  const pricePerVideoUnit = getPriceByQuality(quality, videoPricing.price)
  const totalVideosPriceCents = quantity * pricePerVideoUnit.priceCents

  // we calculate the price for the hooks only
  let totalHooksPriceCents = 0
  if (hooksCount && hooksCount > 0) {
    totalHooksPriceCents = hooksCount * videoPricing.hook.priceCents * quantity
  }

  // we calculate the price for the addons only
  let totalAddonsPriceCents = 0
  if (addons && addons.length > 0) {
    totalAddonsPriceCents =
      getAddonsPricePerUnit({ addons, currency, duration, pricing }) * quantity
  }

  // total price is the sum of the videos and the hooks
  const totalPriceCents =
    totalVideosPriceCents + totalHooksPriceCents + totalAddonsPriceCents

  return totalPriceCents
}

export const getHookPricePerUnit = ({
  hooksCount,
  currency,
  duration,
  pricing,
}) => {
  const videoPricing = getVideoPricing({
    currency: currency,
    videoDuration: duration,
    pricing,
  })

  return hooksCount * videoPricing.hook.priceCents
}

export const getAddonsPricePerUnit = ({
  addons,
  currency,
  duration,
  pricing,
}) => {
  const videoPricing = getVideoPricing({
    currency: currency,
    videoDuration: duration,
    pricing,
  })
  let addonsPricePerUnit = 0

  if (typeof addons === 'object' || Array.isArray(addons)) {
    addons?.map((addon) => {
      addonsPricePerUnit += videoPricing.addons?.find(
        (videoPricingAddon) => videoPricingAddon.addon === addon,
      )?.priceCents
    })
  }

  if (typeof addons === 'string') {
    addonsPricePerUnit += videoPricing.addons?.find(
      (videoPricingAddon) => videoPricingAddon.addon === addons,
    )?.priceCents
  }

  return addonsPricePerUnit
}

export const getVideoPricing = ({
  currency,
  videoDuration,
  pricing,
}: {
  currency: Currency
  videoDuration: VideoDuration
  pricing: PricingData
}) => {
  if (!pricing || !pricing.videoPricing) {
    return null
  }

  const videoPricing = pricing.videoPricing.find(
    (p) => p.videoDuration === videoDuration && p.currency === currency,
  )

  if (videoPricing) {
    return videoPricing
  }

  return pricing.videoPricing.find(
    (p) => p.videoDuration === videoDuration && p.currency === Currency.USD,
  )
}

export const formattedVideoPrice = ({
  currency,
  videoDuration,
  pricing,
  isPremium,
  quality,
}: {
  currency: Currency
  videoDuration: VideoDuration
  pricing: PricingData
  isPremium?: boolean
  quality?: CreatorQuality
}) => {
  const videoPricing = getVideoPricing({
    currency,
    videoDuration,
    pricing,
  })

  return formatPriceInCents({
    priceInCents: getPriceByQuality(quality, videoPricing?.price).priceCents,
    currency: videoPricing.currency,
  })
}

export const formattedHooksPrice = ({
  hooksCount,
  currency,
  duration,
  pricing,
}) => {
  const priceInCents = getHookPricePerUnit({
    hooksCount,
    currency,
    duration,
    pricing,
  })

  return formatPriceInCents({
    priceInCents,
    currency,
  })
}

export const formattedAddonsPrice = ({
  addons,
  currency,
  duration,
  pricing,
}) => {
  const priceInCents = getAddonsPricePerUnit({
    addons,
    currency,
    duration,
    pricing,
  })

  return formatPriceInCents({
    priceInCents,
    currency,
  })
}

export const getPriceByQuality = (
  quality: CreatorQuality,
  price: PriceItem[],
) => {
  return price.find((p) => p.quality === quality)
}

export const formattedVideoCreatorEarnings = ({
  quality,
  currency,
  videoDuration,
  pricing,
}: {
  quality: CreatorQuality
  currency: Currency
  videoDuration: VideoDuration
  pricing: PricingData
}) => {
  const videoPricing = getVideoPricing({
    currency,
    videoDuration,
    pricing,
  })

  return formatPriceInCents({
    priceInCents: getPriceByQuality(quality, videoPricing?.price)
      .creatorEarningsCents,
    currency: videoPricing.currency,
  })
}

export const formattedHookCreatorEarnings = ({
  quality,
  currency,
  videoDuration,
  pricing,
}: {
  quality: CreatorQuality
  currency: Currency
  videoDuration: VideoDuration
  pricing: PricingData
}) => {
  const videoPricing = getVideoPricing({
    currency,
    videoDuration,
    pricing,
  })

  return formatPriceInCents({
    priceInCents: videoPricing?.hook?.creatorEarningsCents,
    currency: videoPricing.currency,
  })
}

type FormattedPriceProps = {
  price: number
  currency: Currency
  maximumFractionDigits?: number
}

export const formattedPrice = (
  props: FormattedPriceProps | Product,
  options?: Intl.NumberFormatOptions,
) => {
  const locale = Intl.DateTimeFormat().resolvedOptions().locale
  return Intl.NumberFormat(locale, {
    style: 'currency',
    currency: props.currency.toUpperCase(),
    ...options,
  }).format(props.price)
}

type PriceCentsProps = {
  priceInCents: number
  currency: Currency
  locale?: string
}

export const formatPriceInCents = (
  { priceInCents, currency, locale }: PriceCentsProps,
  options?: Intl.NumberFormatOptions,
): string => {
  const price = priceInCents / 100

  const detectedLocale = locale || navigator.language

  const formatter = new Intl.NumberFormat(detectedLocale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    ...options,
  })

  let formattedPrice = formatter.format(price)

  // Check if the currency is USD and the formatted price contains 'US'
  if (currency === 'USD' && formattedPrice.includes('US$')) {
    formattedPrice = formattedPrice.replace('US$', '$')
  }

  // Check if the currency is USD and the formatted price contains 'US'
  // if (currency === 'AUD' && formattedPrice.includes('A$')) {
  //   formattedPrice = formattedPrice.replace('A$', '$');
  // }

  return formattedPrice
}
