import React, { forwardRef, ReactNode } from 'react'
import { Icon, IconType } from '../Icon'
import { CheckboxItem } from './CheckboxItem'

// these are all the optional props
// @ts-expect-error
export interface IDefaultProps extends React.HTMLProps<HTMLDivElement> {
  className?: string
  children: React.ReactNode
  selected: string | string[] | null
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onClick?: any
  error: string
}

// these are all the required props
export interface IProps extends Partial<IDefaultProps> {
  label?: string
}

const defaultProps: IDefaultProps = {
  children: [],
  selected: '',
  error: null,
}

type IFC<P> = React.FunctionComponent<P> & {
  CheckboxItem: typeof CheckboxItem
}

// @ts-ignore
const CheckboxGroup: IFC<IProps> = forwardRef<HTMLDivElement, IProps>(
  ({ children, className, selected, onChange, onClick, label, error }, ref) => {
    const createChildElement = (
      child: ReactNode | React.ReactElement,
      index: number = 0,
    ) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          key: `${child.props.name}${index}`,
          selected: selected,
          onChange: onChange,
        } as any)
      }
      return null
    }

    return (
      <>
        <div>
          {label && (
            <label className='block text-sm leading-5 font-medium text-gray-700'>
              {label}
            </label>
          )}

          <div className={className} onClick={onClick}>
            {Array.isArray(children) && children.map(createChildElement)}
            {!Array.isArray(children) && createChildElement(children, 0)}
          </div>
        </div>
        {error && (
          <div className='mt-1 relative flex'>
            <div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
              <Icon
                type={IconType.exclamationCircleFill}
                className='h-5 w-5 text-red-500'
                aria-hidden='true'
              />
            </div>
            <p className='mt-2 text-sm text-red-600'>{error}</p>
          </div>
        )}
      </>
    )
  },
)

CheckboxGroup.CheckboxItem = CheckboxItem
CheckboxGroup.defaultProps = defaultProps

export { CheckboxGroup }
