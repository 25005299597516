import { gql } from '@apollo/client'
import { PaginationFragment } from './common'
import { UserFragment } from './users'
import { GetTemplatesResponse, Template } from './types'

export const TemplateFragment = gql`
  fragment TemplateFragment on Template {
    id
    createdAt
    updatedAt
    type
    name
    subject
    message
    owner {
      ...UserFragment
    }
  }
  ${UserFragment}
`

export const GET_BRAND_TEMPLATES = gql`
  query GetBrandTemplates($brandId: Int!, $options: GetTemplatesOptions!) {
    getBrandTemplates(brandId: $brandId, options: $options) {
      items {
        ...TemplateFragment
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${TemplateFragment}
  ${PaginationFragment}
`

export const CREATE_BRAND_TEMPLATE = gql`
  mutation CreateTemplate($userId: Int!, $input: CreateTemplateInput!) {
    createTemplate(userId: $userId, input: $input) {
      ...TemplateFragment
    }
  }
  ${TemplateFragment}
`

export const UPDATE_TEMPLATE = gql`
  mutation UpdateTemplate($templateId: Int!, $input: UpdateTemplateInput!) {
    updateTemplate(templateId: $templateId, input: $input) {
      ...TemplateFragment
    }
  }
  ${TemplateFragment}
`

export const DELETE_TEMPLATE = gql`
  mutation DeleteTemplateById($templateId: Int!) {
    deleteTemplateById(templateId: $templateId)
  }
`

export interface QueryGetBrandTemplatesResponse {
  getBrandTemplates: GetTemplatesResponse
}

export interface MutationCreateBrandTemplateResponse {
  createTemplate: Template
}

export interface MutationUpdateTemplateResponse {
  updateTemplate: Template
}
