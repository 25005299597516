import { useParams } from 'react-router-dom'
import {
  CollaborationStatus,
  GetCollaborationsSort,
  User,
} from '../../gql/types'
import { Header } from './components/Header'
import { useState } from 'react'
import { useQuery } from '@apollo/client'
import { GET_COLLABORATIONS_BY_BRAND_ID } from '../../gql'
import { Loader } from '../../components'
import { List } from './components/List'
import { Grid } from './components/Grid'

interface IProps {
  user: User
}

const Delivery = ({ user }: IProps) => {
  const { id, searchStatus } = useParams()

  const limit = 20
  const [skip, setSkip] = useState(0)

  const { loading, error, data, refetch } = useQuery(
    GET_COLLABORATIONS_BY_BRAND_ID,
    {
      fetchPolicy: 'network-only',
      variables: {
        brandId: parseInt(id),
        options: {
          filters: {
            status:
              searchStatus === 'due'
                ? [CollaborationStatus.creatorAccepted]
                : searchStatus === 'shipped'
                  ? [
                      CollaborationStatus.productShipped,
                      CollaborationStatus.productDelivered,
                    ]
                  : searchStatus === 'contentUploaded'
                    ? [CollaborationStatus.contentUploaded]
                    : [CollaborationStatus.contentAccepted],
          },
          sort: GetCollaborationsSort.lastUpdated,
          limit,
          skip,
        },
      },
    },
  )

  const setPage = (page: number) => {
    setSkip(page * limit)
  }

  if (loading && !data?.getCollaborationsByBrandId) {
    return <Loader />
  }

  if (error) {
    console.log(error)
    return <p>There has been an error. Try refreshing the page</p>
  }

  return (
    <div>
      <Header />

      {loading && <Loader />}
      {!loading && (
        <div className='pb-4 w-auto h-fit'>
          <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='inline-block min-w-full py-2 px-1 align-middle md:px-6 lg:px-8'>
              <div className='shadow ring-1 ring-black ring-opacity-5 rounded-t'>
                <div className='hidden lg:block'>
                  <List
                    collaborations={data?.getCollaborationsByBrandId.items}
                    pagination={data?.getCollaborationsByBrandId?.pagination}
                    setPage={setPage}
                    onRefetch={() => refetch()}
                  />
                </div>
                <div className='block lg:hidden'>
                  <Grid
                    collaborations={data?.getCollaborationsByBrandId.items}
                    pagination={data?.getCollaborationsByBrandId?.pagination}
                    setPage={setPage}
                    onRefetch={() => refetch()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export { Delivery }
