import { useQuery } from '@apollo/client'
import { FunctionComponent, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Loader } from '../../../components'
import {
  GET_COLLABORATION_BY_UUID,
  GetCollaborationByUuidResponse,
} from '../../../gql'
import { QueryGetCollaborationByUuidArgs } from '../../../gql/types'
import { AnalyticsEvent, trackEvent } from '../../../utils/analytics'
import { Header } from '../components/Header'
import { PortfolioCard } from './PortfolioCard'
import { ProfileCard } from './ProfileCard'

interface IDefaultProps {}

interface IProps extends Partial<IDefaultProps> {}

const defaultProps: IDefaultProps = {}

const CreatorProfile: FunctionComponent<IProps> = () => {
  const { brandId, uuid } = useParams()

  const {
    loading: getCollaborationLoading,
    error: getCollaborationError,
    data: getCollaborationData,
  } = useQuery<GetCollaborationByUuidResponse, QueryGetCollaborationByUuidArgs>(
    GET_COLLABORATION_BY_UUID,
    {
      variables: {
        uuid: uuid,
      },
    },
  )

  useEffect(() => {
    if (getCollaborationData?.getCollaborationByUuid) {
      trackEvent(AnalyticsEvent.viewBrandsCollaborationProfile, {
        creatorId: getCollaborationData?.getCollaborationByUuid?.creator?.id,
      })
    }
  }, [getCollaborationData?.getCollaborationByUuid])

  if (getCollaborationLoading) {
    return <Loader />
  }

  if (getCollaborationError) {
    return null
  }

  const collaboration = getCollaborationData?.getCollaborationByUuid

  return (
    <div className='pb-8'>
      <Header collaboration={collaboration} />

      <ProfileCard collaboration={collaboration} className='mt-4' />
      <PortfolioCard creator={collaboration.creator} />
    </div>
  )
}

CreatorProfile.defaultProps = defaultProps

export { CreatorProfile }
