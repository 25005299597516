import { Pagination as IPagination } from '../../gql/types'
import { Icon, IconType } from '../Icon'
import classnames from 'classnames'

interface IProps {
  pagination: IPagination
  setPage(page: number): void
  className?: string
}

const Pagination = ({ pagination, setPage, className }: IProps) => {
  if (pagination.numberOfHits === 0) {
    return null
  }

  return (
    <div
      className={classnames(
        className,
        'flex items-center justify-between border-t border-gray-200 px-4 py-3 sm:px-6',
      )}
    >
      <div className='flex flex-1 items-center justify-between'>
        <div>
          <p className='text-sm text-gray-700'>
            Showing{' '}
            <span className='font-medium'>
              {pagination.pageNumber * pagination.hitsPerPage + 1}
            </span>{' '}
            to{' '}
            <span className='font-medium'>
              {Math.min(
                (pagination.pageNumber + 1) * pagination.hitsPerPage,
                pagination.numberOfHits,
              )}
            </span>{' '}
            of <span className='font-medium'>{pagination.numberOfHits}</span>{' '}
            results
          </p>
        </div>
        <div>
          <nav
            className='isolate inline-flex rounded-md shadow-sm space-x-1'
            aria-label='Pagination'
          >
            {pagination.pageNumber - 1 >= 0 && (
              <button
                onClick={() => setPage(pagination.pageNumber - 1)}
                className='relative inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium hover:bg-gray-50 focus:ring-gray-300 text-gray-700 focus:z-20'
              >
                <Icon
                  type={IconType.chevronLeft}
                  className='h-5 w-5 hover:text-slate-700'
                  aria-hidden='true'
                />
                <span>Previous</span>
              </button>
            )}
            {pagination.pageNumber + 1 < pagination.numberOfPages && (
              <button
                onClick={() => setPage(pagination.pageNumber + 1)}
                className='relative inline-flex items-center rounded-md border border-gray-300 bg-white px-2 pl-4 py-2 text-sm font-medium hover:bg-gray-50 focus:ring-gray-300 text-gray-700 focus:z-20'
              >
                <span>Next</span>
                <Icon
                  type={IconType.chevronRight}
                  className='h-5 w-5 hover:text-slate-700'
                  aria-hidden='true'
                />
              </button>
            )}
          </nav>
        </div>
      </div>
    </div>
  )
}

export { Pagination }
