import { loadStripe } from '@stripe/stripe-js'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, ButtonColor, NotificationType } from '../../components'
import env from '../../config/env'
import {
  AgeGroups,
  Brand,
  Campaign,
  CampaignAddon,
  CampaignHook,
  CampaignStatus,
  CreatorQuality,
  Ethnicity,
  ModelType,
  Platform,
  Product,
  VideoDuration,
  VideoType,
  VoiceType,
} from '../../gql/types'
import { useUpdateCampaignMutation } from '../../hooks'
import { useCreateCampaignCheckout } from '../../hooks/payments'
import {
  ethnicityLocalizedStringInverse,
  genderLocalizedStringInverse,
} from '../../utils/GqlStrings'
import {
  PricingData,
  getCampaignPricing,
  getVideoPricing,
  getPriceByQuality,
  getAddonsPricePerUnit,
} from '../../utils/PricingUtil'
import { UrlBuilder } from '../../utils/UrlBuilder'
import { capitalize, cleanUrl } from '../../utils/helpers'
import {
  isEmptyString,
  isObjectEmpty,
  useNotification,
} from '../../utils/hooks'
import {
  mapCountryCodeToFlagAndText,
  mapFlagAndTextToCountryCode,
} from '../../utils/mappers'
import { BrandRoutes } from '../BrandRoutes'
import {
  EditAddons,
  EditCreatorDetails,
  EditModelType,
  EditVoiceType,
  EditProductDetails,
  EditQuantity,
  EditRequirements,
  EditTitle,
  EditVariations,
  EditVideoDuration,
  EditVideoType,
} from './components'
import { EditInspirationVideosLinks } from './components/EditInspirationVideosLinks'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(env.STRIPE_KEY)

type EditCampaignFormData = {
  title: string
  requestedProduct: Product
  videoType: VideoType
  modelType: ModelType
  voiceType: VoiceType
  scenes: string[]
  videoDuration: VideoDuration
  platform: Platform
  modelGender: string
  ethnicity: Ethnicity
  creatorPreferences: string
  quality: CreatorQuality
  ageGroups: (AgeGroups | 'Any')[]
  requirements: string
  countryCode: string
  hooks: string[]
  inspirationVideosLinks: string[]
  addons?: CampaignAddon
}

interface IProps {
  brand: Brand
  campaign: Campaign
  pricing: PricingData
  campaignHooks: CampaignHook[]
}

const Edit = ({ brand, campaign, pricing, campaignHooks }: IProps) => {
  const { id } = useParams()
  const brandId = parseInt(id)
  const campaignHooksDescription = campaignHooks
    ? campaignHooks.map((hook) => hook.description)
    : []

  const [initiateCheckoutLoading, setInitiateCheckoutLoading] = useState(false)
  const navigate = useNavigate()
  const { addNotification } = useNotification()
  const [quantity, setQuantity] = useState(3)
  const [errorsEditCampaign, setErrorsEditCampaign] = useState(null)

  const { createCampaignCheckout } = useCreateCampaignCheckout()
  const { updateCampaign, loading: loadingUpdateCampaign } =
    useUpdateCampaignMutation()

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    trigger,
    setError,
  } = useForm<EditCampaignFormData>({
    defaultValues: {
      title: campaign.title,
      requestedProduct: campaign.product,
      videoType: campaign.videoType,
      modelType: campaign.modelType,
      videoDuration: campaign?.videoDuration ?? VideoDuration.half,
      voiceType: campaign.voiceType,
      platform: campaign.platform,
      modelGender: campaign.modelGender
        ? capitalize(campaign.modelGender)
        : 'Any',
      ethnicity: campaign.ethnicity ? capitalize(campaign.ethnicity) : 'Any',
      creatorPreferences: campaign.creatorPreferences,
      quality: campaign.quality ?? CreatorQuality.regular,
      ageGroups: campaign.ageGroups ?? ['Any'],
      requirements: campaign.requirements,
      countryCode: mapCountryCodeToFlagAndText(
        campaign.countryCode ?? brand.countryCode,
      ),
      hooks: campaignHooksDescription,
      inspirationVideosLinks: campaign?.inspirationVideosLinks ?? [],
      addons: campaign?.addons?.[0] ?? null,
    },
  })

  useEffect(() => {
    if (isObjectEmpty(errors)) {
      setErrorsEditCampaign(null)
      return
    }

    if (trigger('title')) {
      setError('title', null)
    }

    if (trigger('requestedProduct')) {
      setError('requestedProduct', null)
    }

    if (trigger('videoType')) {
      setError('videoType', null)
    }

    if (trigger('modelType')) {
      setError('modelType', null)
    }

    if (trigger('voiceType')) {
      setError('voiceType', null)
    }

    if (trigger('hooks')) {
      setError('hooks', null)
    }

    if (trigger('inspirationVideosLinks')) {
      setError('inspirationVideosLinks', null)
    }

    if (!isObjectEmpty(errors)) {
      setErrorsEditCampaign(
        'Please check to ensure all required fields are filled out.',
      )
      return
    }

    if (isObjectEmpty(errors)) {
      setErrorsEditCampaign(null)
      return
    }
  }, [
    watch('title'),
    watch('requestedProduct'),
    watch('videoType'),
    watch('modelType'),
    watch('voiceType'),
    JSON.stringify(watch('hooks')),
    JSON.stringify(watch('inspirationVideosLinks')),
    errors,
  ])

  const totalPriceCents = getCampaignPricing({
    currency: campaign?.currency,
    duration: watch('videoDuration'),
    quantity,
    quality: watch('quality'),
    hooksCount: watch('hooks')?.length ?? 0,
    addons: watch('addons') ? [watch('addons')] : [],
    pricing,
  })

  // API activates campaign if enough credit
  // API sends user to checkout if not enough credit
  const initiateCheckout = async () => {
    setInitiateCheckoutLoading(true)

    try {
      await updateCampaignDetails()

      const { data } = await createCampaignCheckout({
        variables: {
          brandId: brand.id,
          input: {
            campaignId: campaign.id,
            quantity,
            successRedirectUrlPath: UrlBuilder.buildCampaignsUrl(brand.id),
            cancelRedirectUrlPath: UrlBuilder.buildCampaignEditUrl(
              brand.id,
              campaign.id,
            ),
          },
        },
      })

      // if not present, campaign created with credits
      if (!data.createCampaignCheckout.stripeSessionId) {
        navigate(UrlBuilder.buildCampaignsUrl(brand.id))
        addNotification('Campaign launched.', NotificationType.success)
      }

      // if stripe session id present - redirect to stripe
      if (data.createCampaignCheckout.stripeSessionId) {
        const stripe = await stripePromise

        // When the customer clicks on the button, redirect them to Checkout.
        const result = await stripe.redirectToCheckout({
          sessionId: data.createCampaignCheckout.stripeSessionId,
        })

        if (result.error) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
        }
      }
    } catch (error) {
      addNotification(error.message, NotificationType.error)
    }

    setInitiateCheckoutLoading(false)
  }

  const updateCampaignDetails = async (
    {
      isNavigateToCampaignEnabled,
    }: {
      isNavigateToCampaignEnabled: boolean
    } = {
      isNavigateToCampaignEnabled: false,
    },
  ) => {
    const isFormValidated = await trigger([
      'title',
      'requestedProduct',
      'videoType',
      'modelType',
      'voiceType',
      'videoDuration',
      'modelGender',
      'ethnicity',
      'creatorPreferences',
      'ageGroups',
      'requirements',
      'countryCode',
      'inspirationVideosLinks',
      'hooks',
    ])

    if (!isFormValidated) {
      setErrorsEditCampaign(
        'Please check to ensure all required fields are filled out.',
      )
      return
    }

    setErrorsEditCampaign(null)

    let ageGroups = null
    if (!watch('ageGroups').find((el) => el === 'Any')) {
      ageGroups = watch('ageGroups')
    }

    try {
      await updateCampaign({
        variables: {
          id: campaign.id,
          input: {
            title: watch('title'),
            productId: watch('requestedProduct').id,
            videoType: watch('videoType'),
            modelType: watch('modelType'),
            voiceType: watch('voiceType'),
            videoDuration: watch('videoDuration'),
            modelGender: genderLocalizedStringInverse(watch('modelGender')),
            ethnicity: ethnicityLocalizedStringInverse(watch('ethnicity')),
            creatorPreferences: watch('creatorPreferences'),
            quality: watch('quality'),
            ageGroups,
            requirements: watch('requirements'),
            countryCode: mapFlagAndTextToCountryCode(watch('countryCode')),
            hooks: watch('hooks').filter((hook) => !isEmptyString(hook)),
            inspirationVideosLinks: watch('inspirationVideosLinks')
              .filter(
                (inspirationVideosLink) =>
                  !isEmptyString(inspirationVideosLink),
              )
              .map((inspirationVideosLink) => cleanUrl(inspirationVideosLink)),
            addons: watch('addons') ? [watch('addons')] : [],
          },
        },
      })

      addNotification(`Campaign details updated.`, NotificationType.success)

      if (isNavigateToCampaignEnabled) {
        navigate(
          BrandRoutes.campaignView
            .replace(':id', `${brand.id}`)
            .replace(`:campaignId`, `${campaign.id}`),
        )
      }
    } catch (error) {
      console.log('@error Update campaign details', error.message)
      addNotification(error.message, NotificationType.error)
    }
  }

  return (
    <div className='pb-12'>
      <div className='pt-6'>
        <div className='mx-auto px-4 sm:px-6 md:px-8'>
          <EditTitle
            errors={errors}
            register={register}
            watch={watch}
            campaignTitle={campaign.title}
          />
          <EditProductDetails brand={brand} control={control} errors={errors} />
          <EditVideoType control={control} errors={errors} watch={watch} />
          <EditModelType control={control} errors={errors} />
          <EditVoiceType control={control} errors={errors} />
          {/* <EditPlatform control={control} errors={errors} /> */}
          {campaign.status === CampaignStatus.draft && (
            <EditVideoDuration
              control={control}
              errors={errors}
              pricing={pricing}
              currency={campaign.currency}
              quality={watch('quality')}
            />
          )}
          <EditRequirements errors={errors} register={register} />
          {campaign.status === CampaignStatus.draft && (
            <EditAddons
              errors={errors}
              register={register}
              control={control}
              watch={watch}
              currency={campaign.currency}
              pricing={pricing}
            />
          )}
          {campaign.status === CampaignStatus.draft && (
            <EditVariations
              errors={errors}
              register={register}
              watch={watch}
              control={control}
              videoPricing={getVideoPricing({
                currency: campaign?.currency,
                videoDuration: watch('videoDuration'),
                pricing,
              })}
            />
          )}
          <EditInspirationVideosLinks
            errors={errors}
            register={register}
            watch={watch}
            control={control}
          />
          <EditCreatorDetails
            control={control}
            errors={errors}
            register={register}
            watch={watch}
            campaign={campaign}
            pricing={pricing}
          />
          {campaign.status === CampaignStatus.draft && (
            <EditQuantity
              brand={brand}
              quantity={quantity}
              setQuantity={setQuantity}
              totalPriceCents={totalPriceCents}
              pricePerVideoCents={
                getPriceByQuality(
                  watch('quality'),
                  getVideoPricing({
                    currency: campaign?.currency,
                    videoDuration: watch('videoDuration'),
                    pricing,
                  })?.price,
                )?.priceCents
              }
              pricePerVariationCents={
                getVideoPricing({
                  currency: campaign?.currency,
                  videoDuration: watch('videoDuration'),
                  pricing,
                })?.hook?.priceCents
              }
              pricePerAddons={getAddonsPricePerUnit({
                currency: campaign?.currency,
                addons: watch('addons'),
                duration: watch('videoDuration'),
                pricing,
              })}
              hooksCount={watch('hooks')?.length ?? 0}
              currency={campaign.currency}
            />
          )}
        </div>
      </div>
      <div className='text-right px-4 sm:px-8'>
        {campaign.status === CampaignStatus.draft && (
          <>
            <Button
              title='Save Draft'
              type='button'
              colour={ButtonColor.lightGray}
              className='mr-3'
              loading={loadingUpdateCampaign}
              disabled={loadingUpdateCampaign}
              onClick={() => updateCampaignDetails()}
            />
            <Button
              title='Complete Order'
              type='button'
              loading={loadingUpdateCampaign || initiateCheckoutLoading}
              disabled={loadingUpdateCampaign || initiateCheckoutLoading}
              onClick={initiateCheckout}
            />
          </>
        )}
        {(campaign.status === CampaignStatus.active ||
          campaign.status === CampaignStatus.paused) && (
          <Button
            title='Update Campaign'
            type='button'
            loading={loadingUpdateCampaign}
            disabled={loadingUpdateCampaign}
            onClick={() =>
              updateCampaignDetails({
                isNavigateToCampaignEnabled: true,
              })
            }
          />
        )}
        {errorsEditCampaign && (
          <p className='mt-2 text-sm text-red-600'>{errorsEditCampaign}</p>
        )}
      </div>
    </div>
  )
}

export { Edit }
