import { useMutation } from '@apollo/client'
import { FunctionComponent, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, NotificationType } from '../../../components'
import { Star } from '../../../components/Star'
import { UPDATE_RATING } from '../../../gql'
import { Rating } from '../../../gql/types'
import { AnalyticsEvent, trackEvent } from '../../../utils/analytics'
import { useNotification } from '../../../utils/hooks'

interface IDefaultProps {
  className?: string
}

interface IProps extends Partial<IDefaultProps> {
  rating: Rating
}

const defaultProps: IDefaultProps = {}

export type UpdateRatingFormData = {
  contentQualityRating: number
  professionalismRating: number
  communicationRating: number
  creativityRating: number
  timelinessRating: number
  adherenceToBriefRating: number
}

const UpdateRating: FunctionComponent<IProps> = ({ rating }) => {
  const { brand, creator, collaboration } = rating

  const { addNotification } = useNotification()
  const [isRatingUpdated, setIsRatingUpdated] = useState(false)
  const contentQualityRatingStars = [1, 2, 3, 4, 5]
  const professionalismRatingStars = [1, 2, 3, 4, 5]
  const communicationRatingStars = [1, 2, 3, 4, 5]
  const creativityRatingStars = [1, 2, 3, 4, 5]
  const timelinessRatingStars = [1, 2, 3, 4, 5]
  const adherenceToBriefRatingStars = [1, 2, 3, 4, 5]

  const [updateRating, { loading, error }] = useMutation(UPDATE_RATING)

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      contentQualityRating: null,
      professionalismRating: null,
      communicationRating: null,
      creativityRating: null,
      timelinessRating: null,
      adherenceToBriefRating: null,
    },
  })

  const onSubmit = async (data: UpdateRatingFormData) => {
    try {
      await updateRating({
        variables: {
          id: rating.id,
          input: {
            contentQualityRating: data.contentQualityRating
              ? Number(data.contentQualityRating)
              : null,
            professionalismRating: data.professionalismRating
              ? Number(data.professionalismRating)
              : null,
            communicationRating: data.communicationRating
              ? Number(data.communicationRating)
              : null,
            creativityRating: data.creativityRating
              ? Number(data.creativityRating)
              : null,
            timelinessRating: data.timelinessRating
              ? Number(data.timelinessRating)
              : null,
            adherenceToBriefRating: data.adherenceToBriefRating
              ? Number(data.adherenceToBriefRating)
              : null,
          },
        },
      })

      trackEvent(AnalyticsEvent.brandUpdatedRating, {
        collaborationId: collaboration.id,
        brandId: brand.id,
        creatorId: creator.id,
      })

      addNotification(`Updated`, NotificationType.success)
      setIsRatingUpdated(true)
    } catch (error) {
      addNotification(
        `Something went wrong. Please try again.`,
        NotificationType.error,
      )
    }
  }

  if (error) {
    console.log('error rating Update', error)
    // return <p>There has been an error. Try refreshing the page</p>
  }

  if (isRatingUpdated) {
    return null
  }

  return (
    <div className='mt-4 bg-white shadow overflow-hidden sm:rounded-lg p-4'>
      <h3 className='text-base font-semibold leading-6 text-gray-900'>
        More details about working with {creator.firstName}
      </h3>
      <p className='mt-1 text-sm font-normal leading-5 text-gray-500 font-sans'>
        This helps us gain a better understanding of the creator's strengths.
      </p>

      <div className='mt-6'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='mt-4'>
            <p className='text-sm font-medium text-gray-900'>
              Quality of content
            </p>
            <div className='mt-1 flex items-center cursor-pointer'>
              {Object.values(contentQualityRatingStars).map(
                (contentQualityRatingStar, index) => {
                  return (
                    <Star
                      key={contentQualityRatingStar + 1}
                      value={index + 1}
                      name='contentQualityRatingStar'
                      // changeStarsRating={(index) => setStarsRating(index)}
                      changeStarsRating={(index) =>
                        watch('contentQualityRating')
                      }
                      isActiveStar={
                        watch('contentQualityRating') > index ? true : false
                      }
                      className='cursor-pointer'
                      {...register('contentQualityRating')}
                    />
                  )
                },
              )}
            </div>
          </div>

          <div className='mt-4'>
            <p className='text-sm font-medium text-gray-900'>Professionalism</p>
            <div className='mt-1 flex items-center cursor-pointer'>
              {Object.values(professionalismRatingStars).map(
                (professionalismRatingStar, index) => {
                  return (
                    <Star
                      key={professionalismRatingStar + 1}
                      value={index + 1}
                      name='professionalismRatingStar'
                      changeStarsRating={(index) =>
                        watch('professionalismRating')
                      }
                      isActiveStar={
                        watch('professionalismRating') > index ? true : false
                      }
                      className='cursor-pointer'
                      {...register('professionalismRating')}
                    />
                  )
                },
              )}
            </div>
          </div>

          <div className='mt-4'>
            <p className='text-sm font-medium text-gray-900'>Communication</p>
            <div className='mt-1 flex items-center cursor-pointer'>
              {Object.values(communicationRatingStars).map(
                (communicationRatingStar, index) => {
                  return (
                    <Star
                      key={communicationRatingStar + 1}
                      value={index + 1}
                      name='communicationRatingStars'
                      changeStarsRating={(index) =>
                        watch('communicationRating')
                      }
                      isActiveStar={
                        watch('communicationRating') > index ? true : false
                      }
                      className='cursor-pointer'
                      {...register('communicationRating')}
                    />
                  )
                },
              )}
            </div>
          </div>

          <div className='mt-4'>
            <p className='text-sm font-medium text-gray-900'>Creativity</p>
            <div className='mt-1 flex items-center cursor-pointer'>
              {Object.values(creativityRatingStars).map(
                (creativityRatingStar, index) => {
                  return (
                    <Star
                      key={creativityRatingStar + 1}
                      value={index + 1}
                      name='creativityRatingStar'
                      changeStarsRating={(index) => watch('creativityRating')}
                      isActiveStar={
                        watch('creativityRating') > index ? true : false
                      }
                      className='cursor-pointer'
                      {...register('creativityRating')}
                    />
                  )
                },
              )}
            </div>
          </div>

          <div className='mt-4'>
            <p className='text-sm font-medium text-gray-900'>Delivery time</p>
            <div className='mt-1 flex items-center cursor-pointer'>
              {Object.values(timelinessRatingStars).map(
                (timelinessRatingStar, index) => {
                  return (
                    <Star
                      key={timelinessRatingStar + 1}
                      value={index + 1}
                      name='timelinessRatingStar'
                      changeStarsRating={(index) => watch('timelinessRating')}
                      isActiveStar={
                        watch('timelinessRating') > index ? true : false
                      }
                      className='cursor-pointer'
                      {...register('timelinessRating')}
                    />
                  )
                },
              )}
            </div>
          </div>

          <div className='mt-4'>
            <p className='text-sm font-medium text-gray-900'>
              Followed the brief
            </p>
            <div className='mt-1 flex items-center cursor-pointer'>
              {Object.values(adherenceToBriefRatingStars).map(
                (adherenceToBriefRatingStar, index) => {
                  return (
                    <Star
                      key={adherenceToBriefRatingStar + 1}
                      value={index + 1}
                      name='adherenceToBriefRatingStar'
                      changeStarsRating={(index) =>
                        watch('adherenceToBriefRating')
                      }
                      isActiveStar={
                        watch('adherenceToBriefRating') > index ? true : false
                      }
                      className='cursor-pointer'
                      {...register('adherenceToBriefRating')}
                    />
                  )
                },
              )}
            </div>
          </div>

          <div className='mt-2'>
            <Button
              title={'Submit'}
              className='w-full sm:min-w-[250px] sm:max-w-[250px]'
              loading={loading}
            />
          </div>
        </form>
      </div>
    </div>
  )
}

UpdateRating.defaultProps = defaultProps

export { UpdateRating }
