import { useState, FunctionComponent, Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { VideoPlayer } from '../VideoPlayer'
import { Icon, IconType } from '../Icon'

interface IDefaultProps {
  border: boolean
  src: string
  thumbnailSrc?: string
  className: string
}

interface IProps extends Partial<IDefaultProps> {}

const defaultProps: IDefaultProps = {
  className: null,
  border: false,
  src: null,
  thumbnailSrc: null,
}

const VideoZoom: FunctionComponent<IProps> = ({
  src,
  thumbnailSrc,
  border,
  className,
  ...rest
}) => {
  const [click, setClick] = useState(false)
  const [open, setOpen] = useState(true)
  const cancelButtonRef = useRef(null)

  const closeModal = () => {
    setOpen(false)
  }

  return (
    <>
      <div className='relative'>
        <VideoPlayer
          src={src}
          poster={thumbnailSrc}
          className={className}
          onClick={() => {
            setClick(true)
            setOpen(true)
          }}
        />
        <div className='absolute inset-0 flex items-center justify-center pointer-events-none mt-4'>
          <div className='cursor-pointer rounded-full bg-white bg-opacity-70 text-gray-800 pointer-events-none'>
            <Icon
              type={IconType.play}
              className='w-10 h-10 text-white'
              onClick={() => {
                setClick(true)
                setOpen(true)
              }}
            />
          </div>
        </div>
      </div>
      {click && (
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as='div'
            className='relative z-10'
            onClose={closeModal}
            onClick={() => {
              setOpen(false)
              setClick(false)
            }}
          >
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>
            <div className='fixed z-10 inset-0 overflow-y-auto cursor-pointer'>
              <div className='flex items-center justify-center min-h-full p-4 text-center sm:p-0'>
                <Transition.Child
                  as={Fragment}
                  enter='ease-out duration-300'
                  enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                  enterTo='opacity-100 translate-y-0 sm:scale-100'
                  leave='ease-in duration-200'
                  leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                  leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                >
                  <div ref={cancelButtonRef}>
                    <VideoPlayer
                      {...rest}
                      src={src}
                      poster={thumbnailSrc}
                      className='relative max-h-screen max-w-screen p-2'
                      autoPlay
                      loop
                      controls
                    />
                  </div>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}
    </>
  )
}

VideoZoom.defaultProps = defaultProps

export { VideoZoom }
