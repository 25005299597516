import { FunctionComponent } from 'react'
import { Brand, User } from '../../../gql/types'
import { Edit } from '../../Edit'

interface IProps {
  user: User
  brand: Brand
}

export const BrandEdit: FunctionComponent<IProps> = ({ user, brand }) => {
  return (
    <div>
      <Edit user={user} brand={brand} />
    </div>
  )
}
