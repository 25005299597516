import {
  Collaboration,
  CollaborationStatus,
  ProductType,
} from '../../../../gql/types'
import { Avatar } from '../../../../elements'
import {
  Badge,
  BadgeType,
  BadgeSize,
  CollaborationStatus as CollaborationStatusComponent,
  TooltipInfo,
} from '../../../../components'
import { GridButtons } from '../GridButtons'
import { isCampaignPremium } from '../../../../utils/hooks'
import { mapCurrencyToSign } from '../../../../utils/mappers'
import { useState } from 'react'
import { PricingData, formatPriceInCents } from '../../../../utils/PricingUtil'
import { Link } from 'react-router-dom'
import { ViewClipsSlider } from '../ViewClipsSlider'
import { CreateClipModal } from '../../../../components/CreateClipModal'
import { CreatorRoutes } from '../../../CreatorRoutes'
import { WithdrawFromCollaborationModal } from '../WithdrawFromCollaborationModal'

interface IProps {
  collaboration: Collaboration
  pricing: PricingData
}

const GridItem = ({ collaboration, pricing }: IProps) => {
  const {
    campaign,
    hooksCount,
    addonsCount,
    status,
    productTrackingLink,
    creatorEarningsCurrency,
    totalCreatorEarningsCents,
    pendingUploadHooksCount,
    pendingUploadAddonsCount,
    creator,
    uuid,
    pendingCreatorEarningsCents,
    paidCreatorEarningsCents,
  } = collaboration

  const [isCreateClipModalOpen, setIsCreateClipModalOpen] = useState(false)
  const [isViewClipsModalOpen, setIsViewClipsModalOpen] = useState(false)
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false)

  const onProductShippingClick = () => {
    if (productTrackingLink) {
      window.open(productTrackingLink, '_blank')
    }
  }

  const getTotalCreatorEarningsText = () => {
    return formatPriceInCents({
      priceInCents: totalCreatorEarningsCents,
      currency: creatorEarningsCurrency,
    })
  }

  const getStatusText = () => {
    switch (status) {
      case CollaborationStatus.creatorAccepted:
        return `You need to shoot the video(s).`
      case CollaborationStatus.productShipped:
        return `You need to shoot the video(s).`
      case CollaborationStatus.productDelivered:
        return `You need to shoot the video(s).`
      case CollaborationStatus.contentUploaded:
        if (pendingUploadHooksCount > 0) {
          return (
            <>
              You have {pendingUploadHooksCount} variation
              {pendingUploadHooksCount > 1 ? 's' : ''} to upload.
            </>
          )
        }

        return null
      case CollaborationStatus.contentAccepted:
        if (pendingUploadHooksCount > 0) {
          return (
            <>
              You have {pendingUploadHooksCount} variation
              {pendingUploadHooksCount > 1 ? 's' : ''} to upload.
            </>
          )
        }

        if (pendingUploadAddonsCount > 0) {
          return (
            <>
              You have {pendingUploadAddonsCount} addon
              {pendingUploadAddonsCount > 1 ? 's' : ''} to upload.
            </>
          )
        }

        const displayText = []
        if (paidCreatorEarningsCents > 0) {
          displayText.push(
            <div>
              You have been paid{' '}
              {formatPriceInCents({
                priceInCents: paidCreatorEarningsCents,
                currency: creatorEarningsCurrency,
              })}
              .
            </div>,
          )
        }

        if (pendingCreatorEarningsCents > 0) {
          displayText.push(
            <div>
              You have a payout of{' '}
              {formatPriceInCents({
                priceInCents: pendingCreatorEarningsCents,
                currency: creatorEarningsCurrency,
              })}{' '}
              pending.
            </div>,
          )
        }

        return <>{displayText.concat(' ')}</>
      case CollaborationStatus.creatorApplied:
      default:
        return null
    }
  }

  const getStatusComponent = () => {
    if (
      status === CollaborationStatus.contentAccepted &&
      pendingUploadHooksCount > 0
    ) {
      return (
        <Badge
          type={BadgeType.lime}
          iconPosition='left'
          text={
            <TooltipInfo
              valueKey='status-pendingUploadHooksCount'
              content={`Pending variations`}
              tooltipText='There are more variations required for this campaign. Each variation should be max 5 seconds different than the original video. The earnings include all variations to be uploaded.'
            />
          }
          size={BadgeSize.medium}
          className='!mr-0'
        />
      )
    }

    if (
      status === CollaborationStatus.contentAccepted &&
      pendingUploadAddonsCount > 0
    ) {
      return (
        <Badge
          type={BadgeType.lime}
          iconPosition='left'
          text={
            <TooltipInfo
              valueKey='status-pendingUploadAddonsCount'
              content={`Pending addons`}
              tooltipText='There are more addons required for this campaign. The earnings include all addons to be uploaded.'
            />
          }
          size={BadgeSize.medium}
          className='!mr-0'
        />
      )
    }

    return (
      <CollaborationStatusComponent
        status={status}
        onProductShippingClick={() => onProductShippingClick()}
      />
    )
  }

  return (
    <div
      key={`gridItem-${collaboration?.uuid}`}
      className='col-span-1 divide-y divide-gray-200 rounded-lg bg-white border border-gray-200 shadow mx-2'
    >
      <Link
        to={CreatorRoutes.collaborationViewBriefDetails
          .replace(':id', `${creator.id}`)
          .replace(':uuid', uuid)}
      >
        <div className='cursor-pointer flex w-full items-center justify-between align-top space-x-4 p-4'>
          <div className='flex flex-col'>
            <div className='flex text-gray-900 max-w-[150px] xs:max-w-[250px] sm:max-w-[400px] overflow-hidden truncate'>
              {campaign.product.name}
            </div>
            <div className='mt-1 mb-0 flex flex-shrink-0 text-sm text-gray-500'>
              {campaign.product.price === 0 &&
                campaign.product.type === ProductType.digital && (
                  <span>digital</span>
                )}
              {campaign.product.price > 0 && (
                <span>
                  worth {mapCurrencyToSign(campaign.product.currency)}{' '}
                  {campaign.product.price}
                </span>
              )}
              {isCampaignPremium(campaign) && (
                <Badge
                  type={BadgeType.amber}
                  text={`premium`}
                  size={BadgeSize.small}
                />
              )}
            </div>
          </div>

          <Avatar
            type='product'
            src={campaign.product.photo?.url}
            size='sm'
            alt={campaign.product.name}
          />
        </div>
      </Link>
      <dl className='space-y-3 p-4'>
        <div className='flex flex-col text-left'>
          <dt className='text-sm font-medium text-gray-500'>Earnings</dt>
          <dd className='mt-1 text-sm text-gray-900'>
            <div>{getTotalCreatorEarningsText()} + free product</div>
            {hooksCount > 0 && (
              <div className='mt-1 mb-0'>
                <Badge
                  type={BadgeType.indigo}
                  iconPosition='left'
                  text={
                    <TooltipInfo
                      valueKey='hooks-badge'
                      content={`${hooksCount} variation${hooksCount > 1 ? 's' : ''} required`}
                      tooltipText='Each variation is an altered version of the original video, featuring a unique hook or call-to-action. The duration of each variation is up to 5 seconds.'
                    />
                  }
                  size={BadgeSize.small}
                  className='!mr-0'
                />
              </div>
            )}
            {addonsCount > 0 && (
              <div className='mt-1 mb-0'>
                <Badge
                  type={BadgeType.purple}
                  iconPosition='left'
                  text={
                    <TooltipInfo
                      valueKey='addons-badge'
                      content={`${addonsCount} addon${collaboration?.addonsCount > 1 ? 's' : ''} required`}
                      tooltipText='Each addon is an altered version of the original video with captions, music or additional elements.'
                    />
                  }
                  size={BadgeSize.medium}
                  className='!mr-0'
                />
              </div>
            )}
          </dd>
        </div>
        <div className='flex flex-col text-left'>
          <dt className='text-sm font-medium text-gray-500'>Status</dt>
          <dd className='mt-1 flex flex-col text-sm text-gray-900'>
            <div className='text-sm text-gray-500'>{getStatusComponent()}</div>
          </dd>
        </div>
        {collaboration.status !== CollaborationStatus.creatorApplied && (
          <div className='flex flex-col text-left'>
            <dt className='text-sm font-medium text-gray-500'>Details</dt>
            <dd className='mt-1 flex flex-col text-sm text-gray-900'>
              <div className='text-sm text-gray-500'>{getStatusText()}</div>
            </dd>
          </div>
        )}
      </dl>
      <GridButtons
        collaboration={collaboration}
        setIsCreateClipModalOpen={setIsCreateClipModalOpen}
        setIsViewClipsModalOpen={setIsViewClipsModalOpen}
        setIsWithdrawModalOpen={setIsWithdrawModalOpen}
      />

      <ViewClipsSlider
        open={isViewClipsModalOpen}
        setOpen={setIsViewClipsModalOpen}
        collaboration={collaboration}
        onCreateClip={() => setIsCreateClipModalOpen(true)}
      />

      {isCreateClipModalOpen && (
        <CreateClipModal
          collaboration={collaboration}
          isOpen={isCreateClipModalOpen}
          setIsOpen={setIsCreateClipModalOpen}
        />
      )}

      <WithdrawFromCollaborationModal
        creator={collaboration.creator}
        collaboration={collaboration}
        isOpen={isWithdrawModalOpen}
        setIsOpen={setIsWithdrawModalOpen}
      />
    </div>
  )
}

export { GridItem }
