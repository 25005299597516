import { RadioGroup as HeadlessUIRadioGroup } from '@headlessui/react'
import classNames from 'classnames'
import { FunctionComponent } from 'react'
import { Badge, BadgeSize, BadgeType } from '../Badge'
import { Icon, IconType } from '../Icon'
import { Button, ButtonStyle } from '../Button'

interface IDefaultProps {
  error?: string
  className?: string
  allowDeselect?: boolean // New optional property
  deselectTitle?: string
}

interface IProps extends Partial<IDefaultProps> {
  label?: string
  options: any
  field: any
}

const defaultProps: IDefaultProps = {
  error: null,
  className: null,
  allowDeselect: false, // Default to false
  deselectTitle: null,
}

const RadioGroup: FunctionComponent<IProps> = (props: IProps) => {
  const {
    options,
    field,
    label,
    className,
    error,
    allowDeselect,
    deselectTitle,
  } = props
  const { onChange, name, value } = field

  // Function to handle deselection
  const handleDeselect = () => {
    onChange(null)
  }

  return (
    <div>
      <HeadlessUIRadioGroup value={value} onChange={onChange} name={name}>
        {label && (
          <HeadlessUIRadioGroup.Label className='block text-sm font-medium text-gray-700'>
            {label}
          </HeadlessUIRadioGroup.Label>
        )}
        <div
          className={classNames(
            'grid grid-cols-1 gap-y-3 gap-x-2 sm:gap-x-4',
            className,
          )}
        >
          {options.map((option, optionIdx: number) => (
            <HeadlessUIRadioGroup.Option
              key={optionIdx}
              value={option.type}
              className={({ checked, active }) =>
                classNames(
                  checked ? 'border-transparent' : 'border-gray-300',
                  active ? 'border-gray-800 ring-2 ring-gray-800' : '',
                  'relative block bg-white border rounded-lg shadow-sm px-5 py-3 cursor-pointer sm:flex sm:justify-between focus:outline-none',
                )
              }
            >
              {({ active, checked }) => (
                <>
                  {option?.videoDuration && option?.save && (
                    <div className='absolute inset-x-0 top-0 translate-y-px transform z-10'>
                      <div className='flex -translate-y-1/2 transform justify-center'>
                        <Badge
                          type={BadgeType.success}
                          text={`Save ${option?.save}`}
                          size={BadgeSize.small}
                        />
                      </div>
                    </div>
                  )}
                  <span className='flex items-center'>
                    <div className='min-w-0 flex-1 sm:flex sm:items-center sm:justify-start'>
                      {option?.icon && (
                        <div className='flex-shrink-0 -ml-2 pr-3'>
                          <div
                            className={classNames(
                              active && checked ? 'ring ring-offset-1' : '',
                              !active && checked ? 'ring-3' : '',
                              'h-10 w-10 bg-gray-100 ml-1 relative p-1.5 ring-gray-100 rounded-full flex items-center justify-center cursor-pointer focus:outline-none',
                            )}
                          >
                            {option?.icon}
                          </div>
                        </div>
                      )}
                      <span className='mt-2 sm:mt-0 text-sm flex flex-col'>
                        <HeadlessUIRadioGroup.Label
                          as='span'
                          className='text-sm font-medium text-gray-900'
                        >
                          {option?.name}
                        </HeadlessUIRadioGroup.Label>
                        <HeadlessUIRadioGroup.Description
                          as='span'
                          className='text-sm font-normal leading-5 text-gray-500'
                        >
                          <span className='block sm:inline '>
                            {option?.description}
                          </span>
                        </HeadlessUIRadioGroup.Description>
                      </span>
                    </div>
                  </span>
                  <HeadlessUIRadioGroup.Description
                    as='span'
                    className='mt-2 flex text-sm sm:mt-0 sm:flex-col sm:ml-4 sm:text-right'
                  ></HeadlessUIRadioGroup.Description>
                  <span
                    className={classNames(
                      active ? 'border' : 'border-2',
                      checked ? 'border-gray-800' : 'border-transparent',
                      'absolute -inset-px rounded-lg pointer-events-none',
                    )}
                    aria-hidden='true'
                  />
                </>
              )}
            </HeadlessUIRadioGroup.Option>
          ))}
        </div>
      </HeadlessUIRadioGroup>
      {error && (
        <div className='mt-1 relative flex'>
          <div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
            <Icon
              type={IconType.exclamationCircleFill}
              className='h-5 w-5 text-red-500'
              aria-hidden='true'
            />
          </div>
          <p className='mt-2 text-sm text-red-600'>{error}</p>
        </div>
      )}
      <div className='mt-1'>
        {allowDeselect && (
          <Button
            type='button'
            styleType={ButtonStyle.ghost}
            title={`${value && value.length > 0 ? deselectTitle ?? 'Remove' : ''}`}
            onClick={handleDeselect}
            className={`-ml-4 -mb-4 text-sm ${value && value.length > 0 ? 'underline text-gray-900' : 'no-underline text-gray-200'}`}
            disabled={!value || value.length === 0}
          />
        )}
      </div>
    </div>
  )
}

RadioGroup.defaultProps = defaultProps

export { RadioGroup }
