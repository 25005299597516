import { useMutation } from '@apollo/client'
import { useEffect, useState } from 'react'
import axios from 'axios'
import {
  Button,
  ButtonColor,
  NotificationType,
  VideoUpload,
} from '../../../components'
import {
  GetCreatorIntroVideoUploadUrlResponse,
  GET_CREATOR_INTRO_VIDEO_UPLOAD_URL,
  UPDATE_CREATOR,
} from '../../../gql'
import {
  Creator,
  CreatorOnboardingStep,
  MutationGetCreatorIntroVideoUploadUrlArgs,
} from '../../../gql/types'
import { AnalyticsEvent, trackEvent } from '../../../utils/analytics'
import { Bullets } from '../components/Bullets'
import { Steps } from '../components/Steps'
import { useNotification } from '../../../utils/hooks'

export type EditCreatorProfileFormData = {
  introVideoUrl: string
}

interface Props {
  creator: Creator
}

const SetIntroVideo = ({ creator }: Props) => {
  const { addNotification } = useNotification()

  const [updateCreator] = useMutation(UPDATE_CREATOR)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const [mime, setMime] = useState('')
  const [contentDimensions, setContentDimensions] = useState<{
    w: number | null
    h: number | null
  }>({ w: null, h: null })

  const [getUploadUrl] = useMutation<
    GetCreatorIntroVideoUploadUrlResponse,
    MutationGetCreatorIntroVideoUploadUrlArgs
  >(GET_CREATOR_INTRO_VIDEO_UPLOAD_URL)

  useEffect(() => {
    trackEvent(AnalyticsEvent.viewCreatorsOnboardingSetIntroVideo)
  }, [])

  const goBack = async () => {
    setLoading(true)

    try {
      await updateCreator({
        variables: {
          id: creator.id,
          input: {
            onboarding: CreatorOnboardingStep.setInterests,
          },
        },
      })
    } catch (error) {
      addNotification(error.message, NotificationType.error)
    }

    setLoading(false)
  }

  const onSubmit = async (event: React.MouseEvent) => {
    event.preventDefault()

    if (!selectedFile && !creator?.introVideo?.url) {
      setError('Please upload a video')
      return
    }

    if (!selectedFile && creator?.introVideo?.url) {
      await updateCreator({
        variables: {
          id: creator.id,
          input: {
            onboarding: CreatorOnboardingStep.setPortfolio,
          },
        },
      })
      return
    }

    setError(null)
    setLoading(true)

    try {
      //make sure to call GET_CONTENT_UPLOAD_URL every time when uploading a new file
      const { data } = await getUploadUrl({
        variables: {
          creatorId: creator.id,
          mime,
        },
      })

      // push content to S3
      await axios.put(
        data.getCreatorIntroVideoUploadUrl.uploadUrl,
        selectedFile,
        {
          headers: {
            'Content-Type': mime,
          },
        },
      )

      await updateCreator({
        variables: {
          id: creator.id,
          input: {
            introVideoFileName: data.getCreatorIntroVideoUploadUrl.fileName,
            onboarding: CreatorOnboardingStep.setPortfolio,
            introVideoWidth: contentDimensions.w,
            introVideoHeight: contentDimensions.h,
          },
        },
      })
    } catch (error) {
      console.log('@error SetIntroVideo', error.message)
    }

    setLoading(false)
  }

  const handleFileInput = (
    file: File,
    size?: {
      w: number | null
      h: number | null
    },
  ) => {
    if (!file) {
      setSelectedFile(null)
      setMime('')
      return
    }

    setSelectedFile(file)
    setMime(file.type)
    setError(null)
    setContentDimensions(size)
  }

  return (
    <div className='min-h-screen flex flex-col'>
      <div className='flex-grow'>
        <Steps
          creator={creator}
          stepCurrent={CreatorOnboardingStep.setIntroVideo}
        />

        <div className='min-h-full flex flex-col justify-center pt-4 px-4 md:mt-8 lg:px-8'>
          <div className='sm:mx-auto sm:w-full sm:max-w-2xl'>
            <div className='md:shadow rounded-t-md sm:overflow-hidden mb-8'>
              <div className='py-5 md:bg-white space-y-6 sm:p-6'>
                <div className='sm:mx-auto'>
                  <h2 className='mt-0 md:text-center text-3xl font-extrabold text-gray-900'>
                    Video pitch
                  </h2>
                  <p className='mt-2 text-sm text-gray-600'>
                    Show us what you're made of.
                  </p>
                  <p className='mt-2 text-sm text-gray-600'>
                    Before you can start accepting tasks and getting paid, you
                    need to film a <b>30 second video pitch</b> showing off your
                    skills and personality. You may not have experience with our
                    platform yet, but that's okay. We value those who are
                    excited to learn and have a positive mindset.{' '}
                    <a
                      href='https://media.useclip.com/intro-videos-examples/ijustmetagirlnamedmaria.mp4'
                      target='_blank'
                      className='text-sm text-blue-600 hover:text-blue-500'
                      rel='noreferrer'
                    >
                      Click here
                    </a>{' '}
                    to see an example video.
                  </p>
                </div>

                <div className=''>
                  <p className='mt-4 text-lg text-gray-900'>
                    <b>Criteria</b>
                  </p>
                  <p className='mt-2 ml-4 text-sm text-gray-600'>
                    1. Make sure to speak to the camera
                  </p>
                  <p className='mt-2 ml-4 text-sm text-gray-600'>
                    2. Duration: 30s
                  </p>
                  <p className='mt-2 ml-4 text-sm text-gray-600'>
                    3. Aspect ratio: 9:16 (tall video)
                  </p>
                </div>

                <div className=''>
                  <p className='mt-4 text-lg text-gray-900'>
                    <b>Video script</b>
                  </p>
                  <p className='mt-2 ml-4 text-sm text-gray-600'>
                    1. Brief description of yourself (up to 10s)
                  </p>
                  <p className='mt-2 ml-4 text-sm text-gray-600'>
                    2. Give us a peek of your past projects with other brands.
                    Talk about your hobbies, interests and lifestyle. Tell us
                    what you love. Think about it as a short compilation of you
                    and your skills.
                  </p>
                  <p className='mt-2 ml-4 text-sm text-gray-600'>
                    3. Finish the video with a medium shot of you, with the
                    camera positioned in front of you.{' '}
                    <a
                      href='https://media.useclip.com/intro-videos-examples/sunnysideofourstreet.mp4'
                      target='_blank'
                      className='text-sm text-blue-600 hover:text-blue-500'
                      rel='noreferrer'
                    >
                      Click here
                    </a>{' '}
                    to see an example video.
                  </p>
                </div>

                <div className=''>
                  <p className='mt-4 text-lg text-gray-900'>
                    <b>Script mentions</b>
                  </p>
                  <p className='mt-2 ml-4 text-sm text-gray-600'>
                    1. Simply introduce yourself.
                  </p>
                  <p className='mt-2 ml-4 text-sm text-gray-600'>
                    2. Mention your passions, any hobbies or places that you
                    particularly enjoy. Mention your experience and passion for
                    creating content for brands. Highlight your skills and
                    strengths as a content creator.
                  </p>
                  <p className='mt-2 ml-4 text-sm text-gray-600'>
                    3. Let the brands know what makes you a valuable content
                    creator. Give one top reason why brands should consider you.{' '}
                    <a
                      href='https://media.useclip.com/intro-videos-examples/calligraphyscraps.mp4'
                      target='_blank'
                      className='text-sm text-blue-600 hover:text-blue-500'
                      rel='noreferrer'
                    >
                      Click here
                    </a>{' '}
                    to see an example video.
                  </p>
                </div>

                {/* <div className=''>
                  <p className='mt-4 text-lg text-gray-900'>
                    <b>Scenario</b>
                  </p>
                  <p className='mt-2 text-sm text-gray-600 bg-white p-4'>
                    1. Provide a brief self-introduction, including your name,
                    in a medium shot format captured by a steady camera. Please
                    ensure you are speaking directly to the camera for a
                    duration of 10 seconds or less.
                  </p>
                  <p className='mt-2 text-sm text-gray-600 bg-white p-4'>
                    2. What are you enthusiastic about? Maybe you have a
                    significant other, a furry friend, or little ones to capture
                    on camera? Or perhaps you have some exciting pastimes or
                    breathtaking filming locations? If so, Intro Video is the
                    place to show it off. Additionally, you should also share
                    your expertise and passion for producing content for
                    different brands. Let the brands know about all the
                    exceptional traits you possess as a content creator.
                  </p>
                  <p className='mt-2 text-sm text-gray-600 bg-white p-4'>
                    3. CTA (Click-To-Action). Give one main reason why brands
                    should choose you.
                  </p>
                </div>

                <div>
                  <p className='mt-4 text-lg text-gray-900'>
                    <b>Requirements</b>
                  </p>
                  <p className='mt-2 text-sm text-gray-600 bg-white p-4'>
                    ✨ Duration: 30s to 60s
                  </p>
                  <p className='mt-2 text-sm text-gray-600 bg-white p-4'>
                    ✨ Aspect ratio: 9:16
                  </p>

                  <p className='mt-2 text-sm text-gray-600 bg-white p-4'>
                    ✨ Ensure that your video footage remains unedited and free
                    of any added elements, such as music or text. Keep it in its
                    raw, original form.
                  </p>
                  <p className='mt-2 text-sm text-gray-600 bg-white p-4'>
                    ✨ Ensure the clarity of your video by cleaning your camera
                    lens, shooting in a location with adequate lighting, and
                    avoiding grainy, shadowy, or pixelated footage. Utilize the
                    natural light from the sun or strategically position
                    household lamps to enhance the overall quality of the video.
                    Don't be hesitant to experiment with different lighting
                    setups.
                  </p>
                  <p className='mt-2 text-sm text-gray-600 bg-white p-4'>
                    ✨ Make sure the audio quality in your video is clear,
                    crisp, and without any echo, static, or background noises.
                    To prevent reverberation, consider shooting in a room with
                    furniture or carpeting.
                  </p>
                  <p className='mt-2 text-sm text-gray-600 bg-white p-4'>
                    ✨ When filming yourself, maintain stability and hold the
                    camera at eye level to make it easier for viewers to track
                    your movements and focus on your gaze.
                  </p>
                  <p className='mt-2 text-sm text-gray-600 bg-white p-4'>
                    ✨ Select a neutral and uncluttered background that does not
                    distract from the main subject. Ensure that the space around
                    you provides enough room to fit the entire frame, and avoid
                    shooting in confined spaces such as a car. Minimize the
                    appearance of any unnecessary objects in the background to
                    keep the focus on the subject.
                  </p>
                  <p className='mt-2 text-sm text-gray-600 bg-white p-4'>
                    ✨ It is crucial to sound enthusiastic and passionate in
                    your video. Speak with clarity and effectively convey why
                    you are a suitable candidate. This is your opportunity to
                    generate excitement about working with you from potential
                    brands. The outcome of your application will be based on the
                    content of your video.
                  </p>
                </div> */}

                <VideoUpload
                  onChange={handleFileInput}
                  url={creator?.introVideo?.url ?? null}
                />
                {error && <p className='text-sm text-red-500'>{error}</p>}
              </div>

              <div className='flex justify-between md:shadow rounded-b-md py-3 md:bg-gray-50 text-right sm:px-6'>
                <Button
                  title='Go back'
                  type='button'
                  colour={ButtonColor.white}
                  loading={loading}
                  disabled={loading}
                  onClick={goBack}
                />
                <Button
                  title='Continue'
                  type='button'
                  loading={loading}
                  disabled={loading}
                  onClick={onSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Bullets
        creator={creator}
        stepCurrent={CreatorOnboardingStep.setIntroVideo}
      />
    </div>
  )
}

export { SetIntroVideo }
