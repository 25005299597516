import { Fragment, FunctionComponent, ReactElement, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Button, ButtonColor, Icon, IconType } from '.'

interface IDefaultProps {
  title: string
  subTitle: string
}

interface IProps extends Partial<IDefaultProps> {
  children?: ReactElement | ReactElement[] | null
  open: boolean
  setOpen: (value: boolean) => void
  icon?: ReactElement | ReactElement[] | null
}

const defaultProps: IDefaultProps = {
  title: '',
  subTitle: '',
}

const InfoModal: FunctionComponent<IProps> = ({
  children,
  title,
  subTitle,
  open,
  setOpen,
  icon,
}: IProps) => {
  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
                <div className='absolute right-0 top-0 hidden pr-4 pt-4 sm:block'>
                  <button
                    type='button'
                    className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2'
                    onClick={() => setOpen(false)}
                  >
                    <span className='sr-only'>Close</span>
                    <Icon
                      type={IconType.x}
                      className='w-6 h-6'
                      aria-hidden='true'
                    />
                  </button>
                </div>
                <div className='sm:flex sm:items-start'>
                  <div className='mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-50 sm:mx-0 sm:h-10 sm:w-10'>
                    {icon}
                  </div>
                  <div className='mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left'>
                    <Dialog.Title
                      as='h3'
                      className='text-base font-semibold leading-6 text-gray-900'
                    >
                      {title}
                    </Dialog.Title>
                    <div className='mt-2'>
                      <p className='text-sm text-gray-500'>{subTitle}</p>
                    </div>
                    {children && <div className='mt-2'>{children}</div>}
                  </div>
                </div>
                <div className='mt-5 sm:mt-4 sm:flex sm:flex-row-reverse'>
                  <Button
                    title='Close'
                    type='button'
                    colour={ButtonColor.lightGray}
                    onClick={() => setOpen(false)}
                    className='mt-3 w-full sm:mt-0 sm:w-auto'
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

InfoModal.defaultProps = defaultProps

export { InfoModal }
