import { Pagination as PaginationComponent } from '../../../../components'
import { Collaboration, Pagination, Product } from '../../../../gql/types'
import { PricingData } from '../../../../utils/PricingUtil'
import { GridItem } from '../GridItem'

interface IProps {
  products: Product[]
  pagination?: Pagination
  setPage(page: number): void
}

const Grid = ({ products, pagination, setPage }: IProps) => {
  return (
    <>
      <ul
        role='list'
        className='py-2 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8'
      >
        {products.map((product) => (
          <GridItem key={product.id} product={product} />
        ))}
        {products?.length === 0 && (
          <div className='py-4 pl-4 pr-3 text-sm sm:pl-6 w-full'>
            No products.
          </div>
        )}
      </ul>
      {pagination && (
        <PaginationComponent
          pagination={pagination}
          setPage={setPage}
          className='bg-white'
        />
      )}
    </>
  )
}

export { Grid }
