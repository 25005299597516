import local from './local'
import development from './development'
import production from './production'

let env = development
switch (process.env.REACT_APP_ENV) {
  case 'local':
    env = local
    break
  case 'development':
    env = development
    break
  case 'production':
    env = production
    break
  default:
    env = local
}

export default env
