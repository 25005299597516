import {
  Collaboration,
  MutationMarkApplicantAsFavoriteArgs,
  MutationRemoveApplicantAsFavoriteArgs,
} from '../../../../../gql/types'
import { Avatar } from '../../../../../elements'
import {
  Badge,
  BadgeSize,
  BadgeType,
  Button,
  ButtonStyle,
  CollaborationStatus as CollaborationStatusComponent,
  Icon,
  IconType,
  NotificationType,
  TooltipInfo,
} from '../../../../../components'
import { GridButtons } from '../GridButtons'
import { Link } from 'react-router-dom'
import { BrandRoutes } from '../../../../BrandRoutes'
import { useState } from 'react'
import { CollaborationStatusTime } from '../../../../CampaignView/components/CollaborationStatusTime'
import { useNotification } from '../../../../../utils/hooks'
import { useMutation } from '@apollo/client'
import {
  MARK_APPLICANT_AS_FAVORITE,
  REMOVE_APPLICANT_AS_FAVORITE,
} from '../../../../../gql'

interface IProps {
  collaboration: Collaboration
}

const GridItem = ({ collaboration }: IProps) => {
  const [
    isUpdateProductTrackingLinkModalOpen,
    setIsUpdateProductTrackingLinkModalOpen,
  ] = useState(false)
  const { addNotification } = useNotification()

  const [markApplicantAsFavorite, { loading: markApplicantAsFavoriteLoading }] =
    useMutation<Collaboration, MutationMarkApplicantAsFavoriteArgs>(
      MARK_APPLICANT_AS_FAVORITE,
    )
  const [
    removeApplicantAsFavorite,
    { loading: removeApplicantAsFavoriteLoading },
  ] = useMutation<Collaboration, MutationRemoveApplicantAsFavoriteArgs>(
    REMOVE_APPLICANT_AS_FAVORITE,
  )

  const onMarkApplicantAsFavorite = async () => {
    try {
      await markApplicantAsFavorite({
        variables: {
          collaborationId: collaboration.id,
        },
      })

      addNotification(
        'Collaborator has been marked as a favorite.',
        NotificationType.success,
      )
    } catch (error) {
      console.log(error)
      addNotification(
        'There has been an error marking this applicant as favorite',
        NotificationType.error,
      )
    }
  }

  const onRemoveApplicantAsFavorite = async () => {
    try {
      await removeApplicantAsFavorite({
        variables: {
          collaborationId: collaboration.id,
        },
      })

      addNotification(
        'Collaborator has been removed from favorites.',
        NotificationType.success,
      )
    } catch (error) {
      console.log(error)
      addNotification(
        'There has been an error removing this applicant as favorite',
        NotificationType.error,
      )
    }
  }

  return (
    <div
      key={collaboration.id}
      className='col-span-1 divide-y divide-gray-200 rounded-lg bg-white border border-gray-200 shadow mx-2'
    >
      <div className='cursor-pointer flex w-full items-center justify-between align-top space-x-4 p-4'>
        <Link
          to={BrandRoutes.collaborationViewProfile
            .replace(':id', `${collaboration.campaign.brand.id}`)
            .replace(':uuid', collaboration.uuid)}
          className='cursor-pointer flex gap-2 font-medium text-gray-900 hover:text-gray-800'
        >
          <div className='flex items-center'>
            <Avatar
              type='user'
              src={collaboration.creator.avatar?.url}
              size='sm'
              alt={collaboration.creator.firstName || ''}
            />
            <div className='ml-2'>
              <div className='flex text-gray-900 max-w-[150px] xs:max-w-[250px] sm:max-w-[400px] overflow-hidden truncate'>
                {collaboration.creator.firstName}
              </div>
              <div className='truncate mt-1 mb-0 flex flex-shrink-0 text-sm text-blue-600 hover:text-blue-500'>
                view profile
              </div>
            </div>
          </div>
        </Link>
        {collaboration.isFavorite && (
          <Button
            type='button'
            styleType={ButtonStyle.ghost}
            onClick={onRemoveApplicantAsFavorite}
            loading={removeApplicantAsFavoriteLoading}
            disabled={removeApplicantAsFavoriteLoading}
            icon={
              <Icon
                type={IconType.heartFilled}
                className='h-4 w-4 text-red-500'
                aria-hidden='true'
              />
            }
            iconPosition='left'
            tooltip='Remove collaborator as favorite'
          />
        )}
        {!collaboration.isFavorite && (
          <Button
            type='button'
            styleType={ButtonStyle.ghost}
            onClick={onMarkApplicantAsFavorite}
            loading={markApplicantAsFavoriteLoading}
            disabled={markApplicantAsFavoriteLoading}
            icon={
              <Icon
                type={IconType.heartFilled}
                className='h-4 w-4 text-gray-400'
                aria-hidden='true'
              />
            }
            iconPosition='left'
            tooltip='Mark collaborator as favorite'
          />
        )}
      </div>
      <dl className='space-y-3 p-4'>
        <div className='grid grid-cols-2 items-center'>
          <dt className='text-sm font-medium text-gray-500'>Status</dt>
          <dd className='-ml-2 text-sm text-gray-900'>
            <CollaborationStatusComponent
              status={collaboration.status}
              onProductShippingClick={() =>
                setIsUpdateProductTrackingLinkModalOpen(true)
              }
            />
            {collaboration?.hooksCount > 0 && (
              <div className='mt-1 mb-0'>
                <Badge
                  type={BadgeType.indigo}
                  iconPosition='left'
                  text={
                    <TooltipInfo
                      valueKey='hooks-badge'
                      content={`${collaboration?.hooksCount} variations`}
                      tooltipText='Each variation is an altered version of the original video, featuring a unique hook or call-to-action. The duration of each variation is up to 5 seconds.'
                    />
                  }
                  size={BadgeSize.small}
                  className='!mr-0'
                />
              </div>
            )}
            {collaboration?.addonsCount > 0 && (
              <div className='mt-1 mb-0'>
                <Badge
                  type={BadgeType.purple}
                  iconPosition='left'
                  text={
                    <TooltipInfo
                      valueKey='addons-badge'
                      content={`${collaboration?.addonsCount} addon${collaboration?.addonsCount > 1 ? 's' : ''} required`}
                      tooltipText='Each addon is an altered version of the original video with captions, music or additional elements.'
                    />
                  }
                  size={BadgeSize.small}
                  className='!mr-0'
                />
              </div>
            )}
          </dd>
        </div>
        <div className='grid grid-cols-2 items-center'>
          <dt className='text-sm font-medium text-gray-500'>Details</dt>
          <dd className='text-sm text-gray-900'>
            <CollaborationStatusTime
              status={collaboration.status}
              updatedAt={collaboration.updatedAt}
            />
          </dd>
        </div>
      </dl>
      <GridButtons collaboration={collaboration} />
    </div>
  )
}

export { GridItem }
