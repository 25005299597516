import { useMutation } from '@apollo/client'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import validator from 'validator'
import {
  Button,
  ButtonColor,
  NotificationType,
  TextField,
} from '../../../components'
import { UPDATE_PRODUCT_TRACKING_LINK } from '../../../gql'
import { Collaboration, MutationShipProductArgs } from '../../../gql/types'
import { buildFullAddress } from '../../../utils/UserUtil'
import { cleanUrl } from '../../../utils/helpers'
import { useNotification } from '../../../utils/hooks'

interface IProps {
  collaboration: Collaboration
  isOpen: boolean
  setIsOpen: (event: any) => void
}

export const UpdateProductTrackingLinkModal = ({
  collaboration,
  isOpen,
  setIsOpen,
}: IProps) => {
  const { addNotification } = useNotification()

  const [updateProductTrackingLink, { loading }] =
    useMutation<MutationShipProductArgs>(UPDATE_PRODUCT_TRACKING_LINK, {
      refetchQueries: ['GetCollaborationsByCampaignId'],
    })

  const { productTrackingLink } = collaboration

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      productTrackingLink,
    },
  })

  useEffect(() => {
    reset({
      productTrackingLink,
    })
  }, [productTrackingLink, reset])

  const onSubmit = async (data) => {
    const productTrackingLink =
      data.productTrackingLink && data.productTrackingLink !== ''
        ? cleanUrl(data.productTrackingLink)
        : null

    try {
      await updateProductTrackingLink({
        variables: {
          collaborationId: collaboration.id,
          input: {
            productTrackingLink,
          },
        },
      })

      closeModal()
      addNotification(`Product tracking link updated`, NotificationType.success)
    } catch (error) {
      addNotification(
        `Something went wrong. Please try again.`,
        NotificationType.error,
      )
    }
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-25' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                <Dialog.Title
                  as='h3'
                  className='text-lg font-medium leading-6 text-gray-900'
                >
                  Update tracking link
                </Dialog.Title>

                <div className='flex flex-col my-5'>
                  <div className='flex flex-row text-left'>
                    <div className='w-1/3 text-sm font-semibold text-gray-900'>
                      Name
                    </div>
                    <div className='w-2/3 text-sm font-normal text-gray-600'>
                      {collaboration.creator?.firstName}
                    </div>
                  </div>
                  <div className='flex flex-row text-left mt-2'>
                    <div className='w-1/3 text-sm font-semibold text-gray-900'>
                      Address
                    </div>
                    <div className='w-2/3 text-sm font-normal text-gray-600'>
                      {buildFullAddress(collaboration.creator)}
                    </div>
                  </div>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='mt-4'>
                    <TextField
                      error={errors.productTrackingLink?.message}
                      label='Tracking link'
                      placeholder='Enter the tracking link'
                      {...register('productTrackingLink', {
                        // required: `Please enter the tracking link.`,
                        maxLength: {
                          value: 1000,
                          message:
                            'The tracking link cannot have more than 1000 characters.',
                        },
                        validate: {
                          value: (productTrackingLink) => {
                            if (
                              productTrackingLink === null ||
                              productTrackingLink === '' ||
                              validator.isURL(productTrackingLink)
                            ) {
                              return true
                            }

                            return 'The URL is invalid.'
                          },
                        },
                      })}
                    />
                  </div>

                  <div className='mt-4 flex justify-between'>
                    <div className='py-2'>
                      <Button
                        type='button'
                        title='Cancel'
                        colour={ButtonColor.white}
                        onClick={closeModal}
                      />
                    </div>

                    <div className='py-2'>
                      <Button type='submit' title='Update' loading={loading} />
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
