import { useMutation } from '@apollo/client'
import qs from 'qs'
import { FunctionComponent, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { BrandRoutes } from '../../brands/BrandRoutes'
import { Loader, NotificationType } from '../../components'
import { CreatorRoutes } from '../../creators/CreatorRoutes'
import { VERIFY_USER_EMAIL } from '../../gql'
import { AUTH_TOKEN, useNotification } from '../../utils/hooks'
import { PublicRoutes } from '../../utils/PublicRoutes'

interface IDefaultProps {}

interface IProps extends Partial<IDefaultProps> {}

const defaultProps: IDefaultProps = {}

const VerifyEmail: FunctionComponent<IProps> = () => {
  const { addNotification } = useNotification()
  const location = useLocation()
  const navigate = useNavigate()

  const { token } = qs.parse(location.search, { ignoreQueryPrefix: true })
  const [verifyUserEmail] = useMutation(VERIFY_USER_EMAIL)

  useEffect(() => {
    const verifyUserEmailWithToken = async () => {
      if (!token) {
        addNotification(`Invalid verify email link.`, NotificationType.error)
        return navigate(PublicRoutes.login)
      }

      try {
        await verifyUserEmail({
          variables: {
            token,
          },
        })

        addNotification(`Email verified.`, NotificationType.success)
        navigate(PublicRoutes.login)
      } catch (error) {
        navigate(PublicRoutes.login)
      }
    }

    verifyUserEmailWithToken()
  }, [token, verifyUserEmail])

  return <Loader />
}

VerifyEmail.defaultProps = defaultProps

export { VerifyEmail }
