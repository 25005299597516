import { useQuery } from '@apollo/client'
import { FunctionComponent, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, ButtonColor, ButtonStyle, Loader } from '../../../components'
import { CreateClipModal } from '../../../components/CreateClipModal'
import {
  GET_COLLABORATION_BY_UUID,
  GetCollaborationByUuidResponse,
} from '../../../gql'
import { QueryGetCollaborationByUuidArgs } from '../../../gql/types'
import { Header } from '../components/Header'
import { ClipsList } from './ClipsList'

interface IDefaultProps {}

interface IProps extends Partial<IDefaultProps> {}

const defaultProps: IDefaultProps = {}

const CollaborationClips: FunctionComponent<IProps> = () => {
  const { uuid } = useParams()
  const [isCreateClipModalOpen, setIsCreateClipModalOpen] = useState(false)

  const {
    loading: getCollaborationLoading,
    error: getCollaborationError,
    data: getCollaborationData,
  } = useQuery<GetCollaborationByUuidResponse, QueryGetCollaborationByUuidArgs>(
    GET_COLLABORATION_BY_UUID,
    {
      variables: {
        uuid: uuid,
      },
    },
  )

  if (getCollaborationLoading) {
    return <Loader />
  }

  if (getCollaborationError) {
    return <p>There has been an error. Try refreshing the page</p>
  }

  const collaboration = getCollaborationData?.getCollaborationByUuid

  return (
    <div className='flex flex-col pb-4 overflow-y-scroll'>
      <Header collaboration={collaboration} />

      {isCreateClipModalOpen && (
        <CreateClipModal
          collaboration={collaboration}
          isOpen={isCreateClipModalOpen}
          setIsOpen={setIsCreateClipModalOpen}
        />
      )}

      <div className='mb-4 w-full md:w-[180px] sm:flex-none'>
        <Button
          title='Upload Clip'
          type='button'
          styleType={ButtonStyle.fill}
          colour={ButtonColor.primary}
          className='flex-1 font-bold w-full'
          onClick={() => setIsCreateClipModalOpen(true)}
        />
      </div>

      <ClipsList collaboration={collaboration} useWindow={true} />
    </div>
  )
}

CollaborationClips.defaultProps = defaultProps

export { CollaborationClips }
