import { useEffect } from 'react'
import { Creator } from '../../../gql/types'
import { AnalyticsEvent, trackEvent } from '../../../utils/analytics'
import { Confirmation } from './Confirmation'

interface Props {
  creator: Creator
}

const ApplicationCompleted = ({ creator }: Props) => {
  useEffect(() => {
    trackEvent(AnalyticsEvent.viewCreatorsOnboardingApplicationCompleted)
  }, [])

  return (
    <div className='min-h-screen flex flex-col'>
      <div className='flex-grow'>
        <div className='min-h-full flex flex-col justify-center pt-4 px-4 md:mt-8 lg:px-8'>
          <div className='sm:mx-auto sm:w-full sm:max-w-2xl'>
            <Confirmation creator={creator} />
            {/* <LastMinuteDeal creator={creator} /> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export { ApplicationCompleted }
