import React, { useRef } from 'react'
import { Icon, IconType, VideoFileInput } from '..'
import { getVideoCover } from '@rajesh896/video-thumbnails-generator'

interface IProps {
  url?: string
  onChange: (
    file: File,
    size?: {
      w: number | null
      h: number | null
    },
  ) => void
}

export const VideoUpload: React.FC<IProps> = ({ url, onChange }) => {
  const videoContainerElementRef = useRef<HTMLDivElement | null>(null)

  const [selectedFile, setSelectedFile] = React.useState<File | null>(null)

  const onFileChange = async (
    file: File,
    size?: {
      w: number | null
      h: number | null
    },
  ) => {
    setSelectedFile(file)
    onChange(file, size)

    await generateCover(file)
  }

  const generateCover = async (file: File) => {
    if (!videoContainerElementRef.current) return

    const fileURL = URL.createObjectURL(file)
    const divElement = videoContainerElementRef.current

    try {
      const thumb = await getVideoCover(fileURL, 0.01)

      divElement.style.backgroundImage = `url(${thumb})`
      divElement.style.backgroundSize = 'cover'
      divElement.style.backgroundPosition = 'center center'
      divElement.style.backgroundRepeat = 'no-repeat'
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className='flex flex-col justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6'>
      <div className='mb-4 flex mx-auto' ref={videoContainerElementRef}>
        <video
          className='max-h-[350px]'
          src={
            selectedFile ? URL.createObjectURL(selectedFile) : url ? url : ''
          }
          controls
          hidden={!selectedFile && !url}
        />
      </div>
      <div className='space-y-1 text-center mx-auto'>
        {!selectedFile && !url && (
          <Icon
            type={IconType.addVideo}
            className='mb-3 mx-auto h-10 w-10 text-gray-400'
          />
        )}
        <div className='flex text-sm text-gray-600'>
          <label
            htmlFor='file-upload'
            className='relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500'
          >
            <span>{selectedFile || url ? 'Change video' : 'Upload video'}</span>
            <VideoFileInput
              id='file-upload'
              name='file-upload'
              className='sr-only'
              onChange={onFileChange}
            />
          </label>
        </div>
      </div>
    </div>
  )
}
