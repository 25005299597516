import { useQuery } from '@apollo/client'
import { FunctionComponent } from 'react'
import { Loader } from '../../../components'
import {
  GET_COLLABORATION_BY_UUID,
  GetCollaborationByUuidResponse,
} from '../../../gql'
import { QueryGetCollaborationByUuidArgs } from '../../../gql/types'
import { useParams } from 'react-router-dom'
import { ClipsList } from './ClipsList'
import { Header } from '../components/Header'

interface IDefaultProps {}

interface IProps extends Partial<IDefaultProps> {}

const defaultProps: IDefaultProps = {}

const CollaborationClips: FunctionComponent<IProps> = () => {
  const { uuid } = useParams()

  const {
    loading: getCollaborationLoading,
    error: getCollaborationError,
    data: getCollaborationData,
  } = useQuery<GetCollaborationByUuidResponse, QueryGetCollaborationByUuidArgs>(
    GET_COLLABORATION_BY_UUID,
    {
      variables: {
        uuid: uuid,
      },
    },
  )

  if (getCollaborationLoading) {
    return <Loader />
  }

  if (getCollaborationError) {
    return <p>There has been an error. Try refreshing the page</p>
  }

  const collaboration = getCollaborationData?.getCollaborationByUuid

  return (
    <div className='pb-8'>
      <Header collaboration={collaboration} />

      <ClipsList collaboration={collaboration} className='mt-4' />
    </div>
  )
}

CollaborationClips.defaultProps = defaultProps

export { CollaborationClips }
