import React from 'react'
import { Badge, BadgeType, Icon, IconType } from '../../components'
import { CollaborationStatus as CollaborationStatusType } from '../../gql/types'

interface IProps {
  status: CollaborationStatusType
  onProductShippingClick?: () => void
}

interface BadgeProps {
  text: string
  icon?: IconType
  badgeType: BadgeType
}

export const CollaborationStatus: React.FC<IProps> = ({
  status,
  onProductShippingClick,
}) => {
  const getBadgeProps = (): BadgeProps | undefined => {
    switch (status) {
      case CollaborationStatusType.creatorApplied:
        return {
          text: 'Pending approval',
          badgeType: BadgeType.gray,
        }
      case CollaborationStatusType.creatorAccepted:
        return {
          text: 'Pending shipment',
          badgeType: BadgeType.orange,
          // icon: IconType.loading,
        }
      case CollaborationStatusType.productShipped:
        return {
          text: 'Product shipped',
          badgeType: BadgeType.amber,
          // icon: IconType.loading,
        }
      case CollaborationStatusType.productDelivered:
        return {
          text: 'Shooting clips',
          badgeType: BadgeType.blue,
          // icon: IconType.cameraLens,
        }
      case CollaborationStatusType.contentUploaded:
        return {
          text: 'Content approval',
          // text: 'Content in review', // 'Pending content approval',
          // text: 'Clip in review',
          badgeType: BadgeType.indigo,
          // icon: IconType.cameraLens,
        }
      case CollaborationStatusType.contentAccepted:
        return {
          text: 'Finished',
          badgeType: BadgeType.emerald,
          icon: IconType.checkCircle,
        }
      case CollaborationStatusType.contentRejected:
        return {
          text: 'Content rejected',
          badgeType: BadgeType.error,
          icon: IconType.xCircle,
        }
    }
  }

  const getButtonProps = () => {
    switch (status) {
      case CollaborationStatusType.creatorApplied:
      case CollaborationStatusType.creatorAccepted:
      case CollaborationStatusType.productDelivered:
      case CollaborationStatusType.contentUploaded:
      case CollaborationStatusType.contentAccepted:
      case CollaborationStatusType.contentAccepted:
        return null
      case CollaborationStatusType.productShipped:
        return (
          <button type='button' onClick={() => onProductShippingClick?.()}>
            <Icon type={IconType.shipping} className='h-4 w-4' />
          </button>
        )
    }
  }

  const badgeProps = getBadgeProps()

  if (!badgeProps) {
    return <></>
  }

  return (
    <div className='flex flex-row'>
      <Badge
        type={badgeProps.badgeType}
        text={badgeProps.text}
        icon={getButtonProps()}
        iconPosition='right'
        className='max-w-min whitespace-nowrap !mr-0'
      />
    </div>
  )
}
