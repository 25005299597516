import classNames from 'classnames'
import { Link, useLocation } from 'react-router-dom'
import { Dropdown } from '../Dropdown'

interface IProps {
  navigation: {
    text: string
    to: string
    count?: number
  }[]
  className?: string
  menuPosition?: 'left' | 'right' | 'top'
}

const PageTabs = ({ className, navigation, menuPosition }: IProps) => {
  const { pathname } = useLocation()

  return (
    <div className={classNames(className)}>
      <div className='hidden md:block'>
        <div className='border-b border-gray-200'>
          <nav className='-mb-px flex space-x-8' aria-label='Tabs'>
            {navigation.map(({ to, text, count }) => (
              <Link
                key={to}
                to={to}
                className={classNames(
                  pathname === to
                    ? 'border-slate-500 text-slate-700'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                  'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm',
                )}
                aria-current={pathname === to ? 'page' : undefined}
              >
                {text}
                {count && count > 0 ? (
                  <span
                    className={classNames(
                      pathname === to
                        ? 'bg-slate-500 text-white'
                        : 'bg-slate-500 text-white',
                      'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block',
                    )}
                  >
                    {count}
                  </span>
                ) : null}
              </Link>
            ))}
          </nav>
        </div>
      </div>
      <div className='md:hidden'>
        <Dropdown
          title='Menu'
          menuPosition={menuPosition}
          options={navigation.map((item) => ({
            text: item.text,
            to: item.to,
          }))}
          fullWidth={true}
          isSelectedItemTitle={true}
        />
      </div>
    </div>
  )
}

export { PageTabs }
