import classNames from 'classnames'
import { useEffect } from 'react'
import { Icon, IconType } from '../Icon'

interface IProps {
  query: string
  setQuery(value: string): void
  onSearch(): void
  filterOptions?: {
    id: string
    name: string
    options: {
      value: any
      label: string
      icon?: JSX.Element
    }[]
  }[]
  filtersValue?: any
  onFiltersUpdate?(section, option): void
  sortOptions?: {
    name: string
    value: any
  }[]
  onSort?(sort: any): void
  leftComponent?: React.ReactNode
}

const ListOptions = ({
  query,
  setQuery,
  onSearch,
  filterOptions,
  filtersValue,
  onFiltersUpdate,
  sortOptions,
  onSort,
  leftComponent,
}: IProps) => {
  useEffect(() => {
    const handleEnterKeydown = async (e: KeyboardEvent): Promise<void> => {
      if (e.key === 'Enter') {
        onSearch()
      }
    }

    window.addEventListener('keydown', handleEnterKeydown)
    return () => window.removeEventListener('keydown', handleEnterKeydown)
  }, [onSearch])

  return (
    <div className='flex justify-between items-center w-full h-16  bg-white rounded-t border-b divide-gray-200'>
      <div className='ml-2'>
        {!filterOptions && leftComponent}
        {filterOptions?.map((section) => (
          <div key={section.id}>
            {section.options.map((option, optionIdx) => (
              <button
                key={optionIdx}
                type='button'
                className={classNames(
                  'h-9 w-fit order-2 inline-flex items-center border border-gray-200 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-100 focus:outline-none',
                  optionIdx === 0
                    ? 'rounded-md rounded-tr-none rounded-br-none'
                    : null,
                  optionIdx === section.options.length - 1
                    ? 'rounded-md rounded-tl-none rounded-bl-none'
                    : null,
                  optionIdx !== 0 && optionIdx !== section.options.length
                    ? 'border-y'
                    : null,
                  filtersValue[section.id][option.value] === true
                    ? 'bg-[#e2e8f0] hover:bg-gray-100'
                    : null,
                )}
                onClick={() => onFiltersUpdate(section, option)}
              >
                {option.label}
                <span className='ml-1'>{option.icon}</span>
              </button>
            ))}
          </div>
        ))}
      </div>

      <div className='mr-2 w-full md:w-[400px]'>
        <div className='flex items-center'>
          <label className='sr-only text-gray-500'>Search</label>

          <div className='relative w-full'>
            <div className='flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none'>
              <Icon
                type={IconType.search}
                className='mr-3 h-5 w-5 text-gray-500'
                aria-hidden='true'
              />
            </div>

            <input
              type='text'
              id='campaign-search'
              className='bg-white border border-gray-200 text-gray-900 text-sm rounded-lg focus:ring-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:placeholder-gray-500 dark:text-white dark:focus:ring-blue-500 focus:outline-none'
              placeholder='Search'
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              required
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export { ListOptions }
