import { useParams } from 'react-router-dom'

const Header = () => {
  const { id } = useParams()

  return (
    // <div className='sm:flex items-center -mx-4 sm:-mx-6 md:mx-0 py-4'>
    <div className='sm:flex -mx-4 sm:-mx-6 md:mx-0 py-4'>
      <div className='sm:w-0 sm:flex-1'>
        <h1 className='font-bold text-3xl font-outfit'>Inspiration</h1>
        <p className='mt-2 text-sm text-gray-500 max-w-2xl'>
          You can watch our videos for your inspiration.
        </p>
      </div>
    </div>
  )
}

export { Header }
