import React from 'react'
import { useMutation } from '@apollo/client'
import {
  Creator,
  MutationUpdateCreatorArgs,
  UpdateCreatorInput,
} from '../../../../../gql/types'
import { useForm } from 'react-hook-form'
import { Button, NotificationType, TextField } from '../../../../../components'
import { useNotification } from '../../../../../utils/hooks'
import { UPDATE_CREATOR } from '../../../../../gql'

interface IProps {
  creator: Creator
}

export const EditEarningsForm: React.FC<IProps> = ({ creator }) => {
  const { addNotification } = useNotification()

  const [update, { loading }] = useMutation<Creator, MutationUpdateCreatorArgs>(
    UPDATE_CREATOR,
  )

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateCreatorInput>({
    defaultValues: {
      paypalEmail: creator.paypalEmail,
    },
  })

  const onSubmit = async (data: UpdateCreatorInput) => {
    try {
      await update({
        variables: {
          id: creator.id,
          input: {
            paypalEmail: data.paypalEmail,
          },
        },
      })

      addNotification(
        'Your details updated successfully',
        NotificationType.success,
      )
    } catch (error) {
      console.log('error EditProfile', error.message)

      addNotification(
        'There was an error updating your details',
        NotificationType.error,
      )
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='pb-8'>
      <div className='flex flex-col lg:flex-row'>
        <div className='flex-grow space-y-6'>
          <div className='col-span-12 sm:col-span-12'>
            <TextField
              label='Paypal Email Address'
              name='paypalEmail'
              placeholder={creator.paypalEmail}
              error={errors.paypalEmail?.message}
              {...register('paypalEmail', {
                required: `Please set an paypal email address.`,
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: `Please use a real paypal email address.`,
                },
                maxLength: {
                  value: 100,
                  message: `Please use a real paypal email address.`,
                },
              })}
            />
          </div>
        </div>
      </div>
      <div className='text-right mt-4'>
        <Button
          title='Update'
          type='submit'
          loading={loading}
          disabled={loading}
        />
      </div>
    </form>
  )
}
