import { CampaignStatus as CampaignStatusType } from '../../gql/types'
import { Badge, BadgeType } from '../Badge'

const CampaignStatus = ({ status }: { status: CampaignStatusType }) => {
  let badgeType
  switch (status) {
    case CampaignStatusType.draft:
      badgeType = BadgeType.gray
      break
    case CampaignStatusType.active:
      badgeType = BadgeType.success
      break
    case CampaignStatusType.paused:
      badgeType = BadgeType.warning
      break
    case CampaignStatusType.completed:
      badgeType = BadgeType.lime
      break
    default:
      throw new Error(`Invalid campaign status ${status}`)
  }

  return (
    <Badge
      type={badgeType}
      text={status.charAt(0).toUpperCase() + status.slice(1)}
    />
  )
}

export { CampaignStatus }
