import { useMutation } from '@apollo/client'
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Loader, NotificationType } from '../../components'
import {
  CONFIRM_ADDONS_PAYMENT,
  MutationConfirmAddonsPaymentResponse,
  UPDATE_BRAND,
} from '../../gql'
import { MutationConfirmAddonsPaymentArgs, User } from '../../gql/types'
import { trackPurchase } from '../../utils/analytics'
import { BrandRoutes } from '../BrandRoutes'
import { useNotification } from '../../utils/hooks'

interface IProps {
  user: User
}

const ConfirmAddonsPayment = ({ user }: IProps) => {
  const navigate = useNavigate()
  const { addNotification } = useNotification()

  const [confirmAddonsPayment] = useMutation<
    MutationConfirmAddonsPaymentResponse,
    MutationConfirmAddonsPaymentArgs
  >(CONFIRM_ADDONS_PAYMENT)

  const [updateBrand] = useMutation(UPDATE_BRAND)

  const [searchParams] = useSearchParams()
  const sessionId = searchParams.get('session_id')
  const redirectUrl: string = searchParams.get('redirectUrl')

  useEffect(() => {
    const confirmPaymentSuccess = async () => {
      try {
        const { data } = await confirmAddonsPayment({
          variables: {
            stripeSessionId: sessionId,
          },
        })

        const brand = data.confirmAddonsPayment.brand

        // track all purchases and value to be able to optimize for higher value clients
        trackPurchase({
          transactionId: data.confirmAddonsPayment.transactionId,
          currency: data.confirmAddonsPayment.currency,
          priceCents: data.confirmAddonsPayment.priceCents,
        })

        // // if brand didn't complete onboarding, this is the first purchase, track it
        // if (brand.onboarding !== BrandOnboardingStep.completed) {
        //   await updateBrand({
        //     variables: {
        //       id: brand.id,
        //       input: {
        //         onboarding: BrandOnboardingStep.completed,
        //       },
        //     },
        //   })

        //   trackFirstPurchase({
        //     transactionId: data.confirmAddonsPayment.transactionId,
        //     currency: data.confirmAddonsPayment.currency,
        //     priceCents: data.confirmAddonsPayment.priceCents,
        //   })
        // }

        navigate(
          redirectUrl ?? BrandRoutes.campaigns.replace(':id', `${brand.id}`),
        )

        addNotification(
          'Your addon order has been confirmed.',
          NotificationType.success,
        )
      } catch (error) {
        navigate(BrandRoutes.onboarding)
        addNotification(error.message, NotificationType.error)
      }
    }

    if (sessionId) {
      confirmPaymentSuccess()
    }

    if (!sessionId) {
      navigate(BrandRoutes.onboarding)
      addNotification(
        `Invalid url. Looks like you have landed on the wrong page.`,
        NotificationType.error,
      )
    }
  }, [confirmAddonsPayment, sessionId, updateBrand])

  return <Loader />
}

export { ConfirmAddonsPayment }
