import { useMutation } from '@apollo/client'
import { Dialog, Transition } from '@headlessui/react'
import moment from 'moment-timezone'
import { Fragment } from 'react'
import { useForm } from 'react-hook-form'
import validator from 'validator'
import {
  Button,
  ButtonColor,
  NotificationType,
  TextField,
} from '../../../components'
import { SHIP_PRODUCT } from '../../../gql'
import { Collaboration, MutationShipProductArgs } from '../../../gql/types'
import { buildFullAddress, userFullname } from '../../../utils/UserUtil'
import { cleanUrl } from '../../../utils/helpers'
import { useNotification } from '../../../utils/hooks'

interface IProps {
  collaboration: Collaboration
  isOpen: boolean
  setIsOpen: (event: any) => void
}

export const ShipProductModal = ({
  collaboration,
  isOpen,
  setIsOpen,
}: IProps) => {
  const { addNotification } = useNotification()

  const [shipProduct, { loading }] = useMutation<MutationShipProductArgs>(
    SHIP_PRODUCT,
    {
      refetchQueries: ['GetCollaborationsByCampaignId'],
    },
  )

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      productTrackingLink: '',
      productShippedAt: moment().format('YYYY-MM-DD'),
    },
  })

  const onSubmit = async (data) => {
    const productTrackingLink =
      data.productTrackingLink && data.productTrackingLink !== ''
        ? cleanUrl(data.productTrackingLink)
        : null

    try {
      await shipProduct({
        variables: {
          collaborationId: collaboration.id,
          input: {
            productTrackingLink,
            productShippedAt: data.productShippedAt,
          },
        },
      })

      closeModal()
      addNotification(`Product shipped`, NotificationType.success)
      reset()
    } catch (error) {
      addNotification(
        `Something went wrong. Please try again.`,
        NotificationType.error,
      )
    }
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-25' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                <Dialog.Title
                  as='h3'
                  className='text-lg font-medium leading-6 text-gray-900'
                >
                  Ship product
                </Dialog.Title>
                {/* <div className='mt-2'>
                  <p className='text-sm text-gray-500'>
                    Go to your website and ship the product to the creator.
                  </p>
                </div> */}

                <div className='flex flex-col my-5'>
                  <div className='flex flex-row text-left'>
                    <div className='w-1/3 text-sm font-semibold text-gray-900'>
                      Name
                    </div>
                    <div className='w-2/3 text-sm font-normal text-gray-600'>
                      {userFullname(collaboration.creator)}
                    </div>
                  </div>
                  <div className='flex flex-row text-left mt-2'>
                    <div className='w-1/3 text-sm font-semibold text-gray-900'>
                      Address
                    </div>
                    <div className='w-2/3 text-sm font-normal text-gray-600'>
                      {buildFullAddress(collaboration.creator)}
                    </div>
                  </div>
                  {/* {collaboration.creator?.phoneNumber && (
                    <div className='flex flex-row mt-2'>
                      <div className='w-1/3 text-sm font-semibold text-gray-900'>
                        Phone Number
                      </div>
                      <div className='w-2/3 text-sm font-normal text-gray-600'>
                        {collaboration.creator?.phoneNumber}
                      </div>
                    </div>
                  )} */}
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='mt-4'>
                    <TextField
                      error={errors.productTrackingLink?.message}
                      label='Tracking link'
                      placeholder='Enter the tracking link'
                      {...register('productTrackingLink', {
                        maxLength: {
                          value: 1000,
                          message:
                            'The tracking link cannot have more than 1000 characters.',
                        },
                        validate: {
                          value: (productTrackingLink) => {
                            if (
                              productTrackingLink === null ||
                              productTrackingLink === '' ||
                              validator.isURL(productTrackingLink)
                            ) {
                              return true
                            }

                            return 'The URL is invalid.'
                          },
                        },
                      })}
                    />
                  </div>

                  <div className='mt-4'>
                    <TextField
                      type='date'
                      // min={
                      //   moment(
                      //     collaboration?.metadata?.creatorAcceptedAt,
                      //   ).format('YYYY-MM-DD') ??
                      //   moment(collaboration?.createdAt).format('YYYY-MM-DD')
                      // }
                      // max={moment().format('YYYY-MM-DD')}
                      error={errors.productShippedAt?.message}
                      label='Shipping date'
                      {...register('productShippedAt', {
                        required: `Kindly provide the shipping date for the product.`,
                      })}
                    />
                  </div>

                  <p className='text-sm text-gray-500 mt-2'>
                    Providing a tracking URL is optional but strongly advised to
                    check the progress of the UGC videos and make sure creators
                    respect timelines.
                  </p>

                  <div className='mt-4 flex justify-between'>
                    <div className='py-2'>
                      <Button
                        type='button'
                        title='Cancel'
                        colour={ButtonColor.white}
                        onClick={closeModal}
                      />
                    </div>

                    <div className='py-2'>
                      <Button
                        type='submit'
                        title='Mark as shipped'
                        loading={loading}
                      />
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
