import React from 'react'
import classNames from 'classnames'
import { Icon, IconType, ImageFileInput } from '../../../../components'

interface IDefaultProps {}

const defaultProps: IDefaultProps = {}

interface IProps extends Partial<IDefaultProps> {
  photoUpload?: string
  title?: string
  onChange: (file: File) => void
}

const PhotoUploadUrl: React.FC<IProps> = ({ photoUpload, title, onChange }) => {
  return (
    <>
      <p
        // className='text-sm font-medium leading-6 text-gray-900'
        className='text-sm font-medium text-gray-700'
        aria-hidden='true'
      >
        {title ?? 'Photo'}
      </p>
      <div className='mt-1 lg:hidden'>
        <div className='flex items-center'>
          <div
            className='inline-block h-12 w-12 flex-shrink-0 overflow-hidden rounded-md'
            aria-hidden='true'
          >
            {photoUpload && (
              <img
                className={classNames(`h-12 w-12 rounded-md`)}
                src={photoUpload}
                alt=''
              />
            )}
            {!photoUpload && (
              <Icon
                type={IconType.product}
                style='square'
                className='inline-block h-6 w-6 rounded-md'
              />
            )}
          </div>
          <div className='relative ml-5'>
            <ImageFileInput
              id='mobile-product-photo'
              name='product-photo'
              onChange={onChange}
              className='peer absolute h-full w-full rounded-md opacity-0'
            />
            <label
              htmlFor='mobile-product-photo'
              className='pointer-events-none block rounded-md py-2 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 peer-hover:ring-gray-400 peer-focus:ring-2 peer-focus:ring-sky-500'
            >
              <span>{!photoUpload ? 'Upload' : 'Change'}</span>
              <span className='sr-only'> product photo</span>
            </label>
          </div>
        </div>
      </div>

      <div className='mt-1 relative hidden overflow-hidden rounded-md lg:block'>
        <div className='flex items-center'>
          {photoUpload && (
            <img
              className={classNames(`inline-block h-12 w-12 rounded-md`)}
              src={photoUpload}
              alt=''
            />
          )}
          {!photoUpload && (
            <Icon
              type={IconType.product}
              style='square'
              className='inline-block h-6 w-6 rounded-md'
            />
          )}
          <label
            htmlFor='desktop-product-photo'
            className='block rounded-md py-3 px-3 ml-5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 peer-hover:ring-gray-400 peer-focus:ring-2 peer-focus:ring-sky-500'
          >
            <span>{!photoUpload ? 'Upload' : 'Change'}</span>
            <span className='sr-only'> product photo</span>
            <ImageFileInput
              onChange={onChange}
              id='desktop-product-photo'
              name='product-photo'
              className='absolute inset-0 h-full w-full cursor-pointer rounded-md border-gray-300 opacity-0'
            />
          </label>
        </div>
      </div>
    </>
  )
}

PhotoUploadUrl.defaultProps = defaultProps
PhotoUploadUrl.displayName = 'PhotoUploadUrl'

export { PhotoUploadUrl }
