import { ApolloCache, useMutation } from '@apollo/client'
import {
  UPDATE_CAMPAIGN,
  CREATE_CAMPAIGN,
  GET_CAMPAIGNS_BY_BRAND_ID,
  MutationCreateCampaignResponse,
  MutationUpdateCampaignResponse,
} from '../gql'
import {
  AgeGroups,
  CountryCode,
  CreatorQuality,
  Ethnicity,
  Gender,
  GetCampaignsSort,
  ModelType,
  MutationCreateCampaignArgs,
  MutationUpdateCampaignArgs,
  VideoDuration,
  VideoType,
} from '../gql/types'
import { useParams } from 'react-router-dom'

interface CreateCampaignInput {
  brandId: number
  title: string
  productId: number
  videoType: VideoType
  modelType: ModelType
  videoDuration: VideoDuration
  modelGender: Gender
  ethnicity: Ethnicity
  creatorPreferences: string
  quality: CreatorQuality
  ageGroups: AgeGroups[]
  requirements: string
  countryCode: CountryCode
  hooks: string[]
}

export const useCreateCampaignMutation = () => {
  const { id: brandId } = useParams()

  const [createCampaign, { loading }] = useMutation<
    MutationCreateCampaignResponse,
    MutationCreateCampaignArgs
  >(CREATE_CAMPAIGN, {
    update: (cache: ApolloCache<any>, { data: { createCampaign } }) => {
      console.log('campaign created', createCampaign)
      // addCampaignToGetCampaigns(cache, newCampaign, {
      //   options: {
      //     query: null,
      //     filters: {
      //       brandId,
      //     },
      //     sort: GetCampaignsSort.newest,
      //     skip: 0,
      //     limit: 10,
      //   },
      // })
    },
    refetchQueries: [
      {
        query: GET_CAMPAIGNS_BY_BRAND_ID,
        variables: {
          brandId: parseInt(brandId),
          options: {
            query: '',
            filters: {},
            sort: GetCampaignsSort.lastUpdated,
            skip: 0,
            limit: 20,
          },
        },
      },
    ],
  })

  return { createCampaign, loading }
}

export const useUpdateCampaignMutation = () => {
  const { id: brandId } = useParams()

  const [updateCampaign, { loading }] = useMutation<
    MutationUpdateCampaignResponse,
    MutationUpdateCampaignArgs
  >(UPDATE_CAMPAIGN, {
    update: (cache: ApolloCache<any>, { data: { updateCampaign } }) => {
      // console.log('campaign updated', updateCampaign)
    },
  })

  return { updateCampaign, loading }
}

// export const addCampaignToGetCampaigns = (
//   cache: ApolloCache<any>,
//   campaign: Campaign,
//   variables: object,
// ) => {
//   const existingCampaignsData = cache.readQuery<QueryGetCampaignsResponse>({
//     query: GET_CAMPAIGNS,
//     variables: variables,
//   })

//   if (!existingCampaignsData || !existingCampaignsData.getCampaigns) {
//     return
//   }

//   const updatedCampaigns = {
//     ...existingCampaignsData.getCampaigns,
//     items: [campaign, ...existingCampaignsData.getCampaigns.items],
//   }

//   const updatedPagination = {
//     ...existingCampaignsData.getCampaigns.pagination,
//     numberOfHits:
//       existingCampaignsData.getCampaigns.pagination.numberOfHits + 1,
//   }

//   cache.writeQuery({
//     query: GET_CAMPAIGNS,
//     variables,
//     data: {
//       getCampaigns: {
//         ...updatedCampaigns,
//         pagination: updatedPagination,
//       },
//     },
//   })
// }
