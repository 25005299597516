import { useMemo, useState } from 'react'
import LOGO from '../../../assets/logo/lancape_no_bg.png'
import { Button, Icon, IconType } from '../../../components'
import {
  Brand,
  Campaign,
  CampaignAddon,
  CampaignHook,
  CountryCode,
} from '../../../gql/types'
import {
  PricingData,
  formatPriceInCents,
  formattedVideoPrice,
  getVideoPricing,
} from '../../../utils/PricingUtil'
import { mapVideoDurationToSeconds } from '../../../utils/mappers'

interface Props {
  completeOrder: (quantity: number) => void
  brand: Brand
  campaign: Campaign
  campaignHooks: CampaignHook[]
  pricing: PricingData
  loading: boolean
  goBack(): void
}

const Confirmation = ({
  brand,
  campaign,
  campaignHooks,
  pricing,
  completeOrder,
  loading,
  goBack,
}: Props) => {
  const [quantity, setQuantity] = useState(3)

  const stats = useMemo(
    () => [
      {
        name: 'Length',
        stat: mapVideoDurationToSeconds(campaign.videoDuration),
        previousStat: 'seconds',
        change: '12%',
        changeType: 'increase',
      },
      {
        name: 'Format',
        stat: '9:16',
        previousStat: 'vertical',
        change: '2.02%',
        changeType: 'increase',
      },
      {
        name: 'Price',
        stat: (
          <p>
            {formattedVideoPrice({
              currency: campaign.currency,
              pricing,
              videoDuration: campaign.videoDuration,
              quality: campaign.quality,
            })}
            {brand.countryCode === CountryCode.GB && (
              <span className='text-sm font-semibold leading-6 tracking-wide text-gray-600'>
                {` `}+ VAT
              </span>
            )}
          </p>
        ),
        previousStat: 'per video',
        change: '4.05%',
        changeType: 'decrease',
      },
    ],
    [campaign, pricing],
  )

  const hooksStats = useMemo(
    () => [
      {
        name: 'Variations',
        stat: campaignHooks?.length ?? 0,
        previousStat: 'per video',
      },
      {
        name: 'Price',
        stat: (
          <p>
            {formatPriceInCents({
              priceInCents: getVideoPricing({
                currency: campaign.currency,
                videoDuration: campaign.videoDuration,
                pricing,
              }).hook.priceCents,
              currency: campaign.currency,
            })}
            {brand.countryCode === CountryCode.GB && (
              <span className='text-sm font-semibold leading-6 tracking-wide text-gray-600'>
                {` `}+ VAT
              </span>
            )}
          </p>
        ),
        previousStat: 'per variation',
      },
    ],
    [campaign, pricing],
  )

  const addonsStats = useMemo(
    () => [
      {
        name: 'Addons',
        stat: campaign.addons?.length ?? 0,
        previousStat: 'per video',
      },
      {
        name: 'Price',
        stat: (
          <p>
            {formatPriceInCents({
              priceInCents: getVideoPricing({
                currency: campaign.currency,
                videoDuration: campaign.videoDuration,
                pricing,
              })
                .addons?.filter((addon) =>
                  campaign.addons?.includes(addon.addon as CampaignAddon),
                )
                .reduce((acc, addon) => acc + addon.priceCents, 0),
              currency: campaign.currency,
            })}
            {brand.countryCode === CountryCode.GB && (
              <span className='text-sm font-semibold leading-6 tracking-wide text-gray-600'>
                {` `}+ VAT
              </span>
            )}
          </p>
        ),
        previousStat: 'per addon',
      },
    ],
    [campaign, pricing],
  )

  return (
    <div className='lg:col-span-1 lg:block xl:col-span-1'>
      <div className='space-y-6 sm:p-6'>
        <img className='h-12 w-auto' src={LOGO} alt='Workflow' />
        <div className='sm:mx-auto'>
          <h2 className='mt-0 text-left text-3xl font-extrabold text-gray-900'>
            Lovely jubbly! ✨
          </h2>
          <p className='mt-2 text-left text-sm text-gray-600'>
            You just created your first video brief.
          </p>
        </div>

        <div className='shadow rounded-md sm:overflow-hidden'>
          <div className='px-4 py-5 bg-white space-y-6 sm:p-6'>
            <div className='grid gap-6'>
              <div className='col-span-3 sm:col-span-2'>
                <div className='sm:mx-auto'>
                  <div className='mt-0 text-left text-sm leading-5 font-medium text-gray-900'>
                    Confirm your order
                  </div>
                  <ul className='text-left text-sm text-gray-600'>
                    <li className='ml-2 mt-2'>✅ Over 1500 vetted creators.</li>
                    <li className='ml-2 mt-2'>
                      🏆 We only approve the top 3% of creators.
                    </li>
                    <li className='ml-2 mt-2'>
                      📄 All videos come with <b>licensing included</b>.
                    </li>
                    <li className='ml-2 mt-2'>
                      💸 Creators are paid only after you approve it.
                    </li>
                    <li className='ml-2 mt-2'>
                      ✏️ You get one round of edits included in the price.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <span className='isolate inline-flex'>
              <p className='text-sm text-gray-600 leading-10 mr-4'>
                Number of videos
              </p>
              <button
                type='button'
                className='relative inline-flex items-center gap-x-1.5 rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10'
                onClick={() => setQuantity(Math.max(quantity - 1, 0))}
              >
                <Icon
                  type={IconType.minus}
                  className='-ml-0.5 h-5 w-5 text-gray-500 text-[20px]'
                />
              </button>
              <button
                type='button'
                className='relative -ml-px inline-flex items-center bg-white px-3 py-2 text-md font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10'
              >
                {quantity}
              </button>
              <button
                type='button'
                className='relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10'
                onClick={() => setQuantity(Math.min(quantity + 1, 100))}
              >
                <Icon
                  type={IconType.plus}
                  className='-ml-0.5 h-5 w-5 text-gray-500 text-[20px]'
                />
              </button>
            </span>

            <div className='flex flex-row space-x-2'>
              <Button
                title='Complete Order'
                type='button'
                className='inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700'
                onClick={() => completeOrder(quantity)}
                loading={loading}
              />
              <a
                href='https://calendly.com/clip-demo/30min'
                target='_blank'
                rel='noopener noreferrer'
                className='inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-700 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200'
              >
                Book a call
              </a>
            </div>

            {/* {campaignHooks?.length > 0 && (
              <p className='text-sm text-gray-600'>
                Your order includes {campaignHooks?.length} variation{campaign.hooks?.length > 1 ? 's' : ''} per each
                video.
              </p>
            )}
            {campaign.addons?.length > 0 && (
              <p className='text-sm text-gray-600'>
                Your order includes {campaign.addons?.length} addon{campaign.addons?.length > 1 ? 's' : ''} per each
                video.
              </p>
            )} */}
            <p className='mt-2 text-left text-sm text-gray-600'>
              <b
                className='text-blue-600 hover:text-blue-500 cursor-pointer'
                onClick={goBack}
              >
                Go Back
              </b>{' '}
              if you want to edit your campaign.
            </p>
          </div>
        </div>

        {/* Video duration, format and price */}
        <dl className='mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow md:grid-cols-3 md:divide-y-0 md:divide-x'>
          {stats.map((item, idx) => (
            <div key={idx} className='px-4 py-5 sm:p-6'>
              <dt className='text-base font-normal text-gray-900'>
                {item.name}
              </dt>
              <dd className='mt-1 flex items-baseline justify-between md:block lg:flex'>
                <div className='flex flex-col items-baseline text-2xl font-semibold text-gray-800'>
                  {item.stat}
                  <span className='ml-1 text-sm font-medium text-gray-500'>
                    {item.previousStat}
                  </span>
                </div>
              </dd>
            </div>
          ))}
        </dl>
        {campaignHooks?.length > 0 && (
          <dl className='mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow md:grid-cols-3 md:divide-y-0 md:divide-x'>
            {hooksStats.map((item, idx) => (
              <div key={idx} className='px-4 py-5 sm:p-6'>
                <dt className='text-base font-normal text-gray-900'>
                  {item.name}
                </dt>
                <dd className='mt-1 flex items-baseline justify-between md:block lg:flex'>
                  <div className='flex flex-col items-baseline text-2xl font-semibold text-gray-800'>
                    {item.stat}
                    <span className='ml-1 text-sm font-medium text-gray-500'>
                      {item.previousStat}
                    </span>
                  </div>
                </dd>
              </div>
            ))}
          </dl>
        )}
        {campaign.addons?.length > 0 && (
          <dl className='mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow md:grid-cols-3 md:divide-y-0 md:divide-x'>
            {addonsStats.map((item, idx) => (
              <div key={idx} className='px-4 py-5 sm:p-6'>
                <dt className='text-base font-normal text-gray-900'>
                  {item.name}
                </dt>
                <dd className='mt-1 flex items-baseline justify-between md:block lg:flex'>
                  <div className='flex flex-col items-baseline text-2xl font-semibold text-gray-800'>
                    {item.stat}
                    <span className='ml-1 text-sm font-medium text-gray-500'>
                      {item.previousStat}
                    </span>
                  </div>
                </dd>
              </div>
            ))}
          </dl>
        )}
      </div>
    </div>
  )
}

export { Confirmation }
