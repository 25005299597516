import { Icon, IconType } from '../../../components'

const steps = [
  { name: 'You create a video brief', href: '#', status: 'complete' },
  { name: 'Pay to confirm your order', href: '#', status: 'current' },
  { name: 'Creators apply to your campaign', href: '#', status: 'upcoming' },
  {
    name: 'You send the product to approved creators',
    href: '#',
    status: 'upcoming',
  },
  { name: 'Creators receive the product', href: '#', status: 'upcoming' },
  {
    name: 'Creators submit videos for approval',
    href: '#',
    status: 'upcoming',
  },
  { name: 'You approve the videos', href: '#', status: 'upcoming' },
  // { name: 'You watch your engagement grow', href: '#', status: 'upcoming' },
  {
    name: 'Use the videos across your marketing channels and watch your engagement grow',
    href: '#',
    status: 'upcoming',
  },
]

const Timeline = () => {
  return (
    <>
      <section aria-labelledby='timeline-list'>
        <div className='overflow-hidden bg-white shadow sm:rounded-lg'>
          <div className='px-4 py-5 sm:px-6'>
            <h3 className='text-lg font-medium leading-6 text-gray-900 font-semibold font-sans'>
              Timeline
            </h3>
            <p className='mt-1 max-w-2xl text-sm text-gray-500'>
              Personal details and application.
            </p>
          </div>
          <div className='border-t border-gray-200'>
            <div
              className='flex justify-start px-4 py-5 sm:px-6'
              aria-label='Timeline'
            >
              <ol className='space-y-6'>
                {steps.map((step) => (
                  <li key={step.name}>
                    {step.status === 'complete' ? (
                      <span className='flex items-start'>
                        <span className='relative flex h-5 w-5 flex-shrink-0 items-center justify-center'>
                          <Icon
                            type={IconType.checkCircle}
                            className='h-full w-full text-gray-600 group-hover:text-gray-800'
                            aria-hidden='true'
                          />
                        </span>
                        <span className='ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900 leading-4 font-sans'>
                          {step.name}
                        </span>
                      </span>
                    ) : step.status === 'current' ? (
                      <div className='flex items-start'>
                        <span
                          className='relative flex h-5 w-5 flex-shrink-0 items-center justify-center'
                          aria-hidden='true'
                        >
                          <span className='absolute h-4 w-4 rounded-full bg-gray-200' />
                          <span className='relative block h-2 w-2 rounded-full bg-gray-800' />
                        </span>
                        <span className='ml-3 text-sm font-medium text-gray-800 leading-4 font-sans'>
                          {step.name}
                        </span>
                      </div>
                    ) : (
                      <div className='flex items-start'>
                        <div
                          className='relative flex h-5 w-5 flex-shrink-0 items-center justify-center'
                          aria-hidden='true'
                        >
                          <div className='h-2 w-2 rounded-full bg-gray-300 group-hover:bg-gray-400' />
                        </div>
                        <p className='ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900'>
                          {step.name}
                        </p>
                      </div>
                    )}
                  </li>
                ))}
              </ol>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export { Timeline }
