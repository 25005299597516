import { AgeGroups, Campaign, CreatorQuality } from '../../../gql/types'
import { BriefTextRow } from '../BriefRow'
import {
  ageGroupsOptions,
  mapCountryCodeToFlagAndText,
  qualityOptions,
} from '../../../utils/mappers'
import { capitalize } from '../../../utils/helpers'
import { Icon, IconType } from '../../Icon'
import { Badge, BadgeSize, BadgeType } from '../..'
import { isCampaignPremium } from '../../../utils/hooks'

interface ICreatorRequirementsProps {
  campaign: Campaign
}

export const CreatorRequirementsBrief = ({
  campaign,
}: ICreatorRequirementsProps) => {
  return (
    <div className='@container'>
      <div className='grid @xl:grid-cols-4 @xl:gap-6 grid-cols-1 gap-2'>
        <div>
          <h3 className='text-base font-semibold leading-6 text-gray-900'>
            Creator
          </h3>
        </div>
        <div className='overflow-hidden bg-white shadow rounded-lg col-span-3'>
          <div className='border-t border-gray-200 px-4'>
            <dl className='@md:divide-y @md:divide-gray-200'>
              <BriefTextRow
                title='Shooting Skills'
                value={
                  isCampaignPremium(campaign) ? (
                    <div className='flex flex-row space-x-1'>
                      <Icon
                        type={IconType.premium}
                        className='w-5 h-5 text-amber-400'
                        aria-hidden='true'
                      />
                      <Badge
                        type={BadgeType.amber}
                        text={`premium`}
                        size={BadgeSize.small}
                      />
                    </div>
                  ) : (
                    <div className='flex flex-row space-x-1'>
                      <Icon
                        type={IconType.camera}
                        className='w-5 h-5'
                        aria-hidden='true'
                      />
                      <Badge
                        type={BadgeType.gray}
                        text={`regular`}
                        size={BadgeSize.small}
                      />
                    </div>
                  )
                }
              />
              <BriefTextRow
                title='Gender'
                value={
                  campaign.modelGender
                    ? capitalize(campaign.modelGender)
                    : 'Any'
                }
              />
              <BriefTextRow
                title='Age group'
                value={ageGroupValue(campaign.ageGroups)}
              />
              <BriefTextRow
                title='Ethnicity'
                value={
                  campaign.ethnicity ? capitalize(campaign.ethnicity) : 'Any'
                }
              />
              <BriefTextRow
                title='Other preferences'
                value={campaign.creatorPreferences}
                formatHTML={true}
              />
              <BriefTextRow
                title='Country'
                value={mapCountryCodeToFlagAndText(campaign.countryCode)}
              />
            </dl>
          </div>
        </div>
      </div>
    </div>
  )
}

const ageGroupValue = (ageGroups: AgeGroups[]) => {
  if (!ageGroups) {
    return 'Any'
  }

  return ageGroups
    .map(
      (ageGroup) =>
        ageGroupsOptions.find((option) => option.type === ageGroup)?.name,
    )
    .join('; ')
}
