import { TextField } from '../../../../components'

interface Props {
  errors: any
  register: any
}

const SetTitle = ({ errors, register }: Props) => {
  return (
    <div>
      <div className='mt-10 sm:mt-0'>
        <div className='md:grid md:grid-cols-3 md:gap-6'>
          <div className='md:col-span-1'>
            <div className=''>
              <h3 className='text-lg font-semibold font-sans leading-6 text-gray-900'>
                Title
              </h3>
              <p className='mt-1 text-sm font-normal leading-5 text-gray-500 font-sans'>
                This information will not be visible to creators. Creators will
                only see your brand and product details.
              </p>
            </div>
          </div>
          <div className='mt-5 md:col-span-2 md:mt-0'>
            <div className='md:shadow rounded-t-md sm:overflow-hidden'>
              <div className='py-5 md:bg-white space-y-6 sm:p-6'>
                <div className='grid gap-6'>
                  <div className='col-span-3 sm:col-span-2'>
                    <TextField
                      error={errors.title?.message}
                      label='Campaign title'
                      placeholder='Example: First Campaign'
                      {...register('title', {
                        required: `Please set a campaign title.`,
                        minLength: {
                          value: 2,
                          message: `Your campaign title must be longer than or equal to 2 characters.`,
                        },
                        maxLength: {
                          value: 100,
                          message: `Your campaign title cannot have more than 100 characters.`,
                        },
                      })}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='hidden sm:block' aria-hidden='true'>
        <div className='py-5'>
          <div className='border-t border-gray-200' />
        </div>
      </div>
    </div>
  )
}

export { SetTitle }
