import { Fragment, useMemo, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Collaboration } from '../../../gql/types'
import classNames from 'classnames'
import { Dialog, Transition } from '@headlessui/react'
import { Button, ButtonColor } from '../../../components'
import { OrderHooksModal } from '../OrderHooksModal'
import { OrderHooks } from './OrderHooks'
import { OrderAddons } from './OrderAddons'

interface IProps {
  collaboration: Collaboration
  open: boolean
  setOpen: (event: any) => void
  description?: string
  title?: string
}

export const OrderAddonsVariationsModal: React.FC<IProps> = ({
  collaboration,
  open,
  setOpen,
  title,
  description,
}) => {
  const [currentTab, setCurrentTab] = useState('variations')
  const cancelButtonRef = useRef(null)

  const tabs = [
    {
      name: 'Variations',
      key: 'variations',
      current: currentTab === 'variations',
    },
    { name: 'Addons', key: 'addons', current: currentTab === 'addons' },
  ]

  const handleTabChange = (event) => {
    const selectedTab = event.target.value
    setCurrentTab(selectedTab)
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-10'
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed z-10 inset-0 overflow-y-auto'>
          <div className='flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:max-w-xl sm:w-full'>
                <div>
                  {/* <div className='sm:hidden'>
                    <label htmlFor='tabs' className='sr-only'>
                      Select a tab
                    </label>
                    <select
                      id='tabs'
                      name='tabs'
                      className='block w-full rounded-md border-gray-300 py-2 pl-4 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                      value={currentTab}
                      onChange={handleTabChange}
                    >
                      {tabs.map((tab) => (
                        <option key={tab.key} value={tab.key}>
                          {tab.name}
                        </option>
                      ))}
                    </select>
                  </div> */}
                  <div className='block'>
                    <div className='border-b border-gray-200'>
                      <nav
                        className='ml-4 -mb-px flex space-x-8'
                        aria-label='Tabs'
                      >
                        {tabs.map((tab) => (
                          <button
                            key={tab.key}
                            onClick={() => setCurrentTab(tab.key)}
                            className={classNames(
                              tab.current
                                ? 'border-indigo-500 text-indigo-600'
                                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                              'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium',
                            )}
                            aria-current={tab.current ? 'page' : undefined}
                          >
                            {tab.name}
                          </button>
                        ))}
                      </nav>
                    </div>
                  </div>
                </div>
                <div className='px-0 py-0'>
                  {currentTab === 'variations' && (
                    <OrderHooks
                      collaboration={collaboration}
                      title={`Buy more variations`}
                      description={`Each variation is an altered version of the original video, featuring a unique hook or call-to-action. The duration of each variation is up to 5 seconds.`}
                      open={open}
                      setOpen={setOpen}
                    />
                  )}
                  {currentTab === 'addons' && (
                    <OrderAddons
                      collaboration={collaboration}
                      title={`Buy more addons`}
                      description={`Each addon is an altered version of the original video with captions, music or additional elements.`}
                      open={open}
                      setOpen={setOpen}
                    />
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
