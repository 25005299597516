import axios from 'axios'
import { CountryCode } from '../gql/types'
import { sleep } from './helpers'

const IP_API_KEY = 'CsABl7SBvWePwzc'

/**
 * http://pro.ip-api.com/
 */
const getIp = async (): Promise<{
  countryCode: CountryCode
  currency: string
}> => {
  const response = await axios.get(
    `https://pro.ip-api.com/json/?fields=countryCode,currency&key=${IP_API_KEY}`,
  )

  await sleep(1000)

  return response.data
}

export { getIp }
