import { FunctionComponent, useState } from 'react'
import { Button } from '../../../components'
import { User } from '../../../gql/types'
import { Header } from '../components/Header'
import { CreatePortfolioItemModal } from './CreatePortfolioItemModal'
import { PortfolioGridNew } from '../../../components/PortfolioGridNew'

interface IProps {
  user: User
}

const CreatorPortfolio: FunctionComponent<IProps> = ({ user }) => {
  const [isCreatePortfolioItemModalOpen, setIsCreatePortfolioItemModalOpen] =
    useState(false)

  return (
    <div>
      <Header />

      <CreatePortfolioItemModal
        creator={user.creator}
        isOpen={isCreatePortfolioItemModalOpen}
        setIsOpen={setIsCreatePortfolioItemModalOpen}
      />

      <div className='mb-4 sm:flex-none'>
        <Button
          title='Upload video to your portfolio'
          type='button'
          onClick={() => setIsCreatePortfolioItemModalOpen(true)}
        />
      </div>

      <PortfolioGridNew
        creator={user?.creator}
        allowDownload={true}
        allowDelete={true}
      />
    </div>
  )
}

export { CreatorPortfolio }
