import { FunctionComponent, useEffect } from 'react'
import { User } from '../../gql/types'
import { EditPassword } from './components/EditPassword'
import { EditPersonalInformation } from './components/EditPersonalInformation'
import { Header } from './components/Header'
import { EditNotifications } from './components/EditNotifications'
import { AnalyticsEvent, trackEvent } from '../../utils/analytics'

interface IProps {
  user: User
}

const Profile: FunctionComponent<IProps> = ({ user }) => {
  useEffect(() => {
    trackEvent(AnalyticsEvent.viewBrandsProfile)
  }, [])

  return (
    <div className='pb-12'>
      <Header />
      <EditPersonalInformation user={user} />
      <EditNotifications user={user} />
      <EditPassword />
    </div>
  )
}

export { Profile }
