import { gql } from '@apollo/client'
import { BrandFragment } from './brands'
import { PaginationFragment } from './common'
import { ProductFragment } from './products'
import { Campaign, GetCampaignsResponse } from './types'

export const CampaignFragment = gql`
  fragment CampaignFragment on Campaign {
    id
    uuid
    createdAt
    title
    status
    videoType
    modelType
    voiceType
    platform
    videoDuration
    modelGender
    ethnicity
    creatorPreferences
    ageGroups
    collaboratorsCount
    applicantsCount
    clipsCount
    videoQuantity
    countryCode
    currency
    brand {
      id
      name
      logo {
        url
        name
      }
      creditCents
      currency
      countryCode
    }
    requirements
    purchasedAt
    quality
    product {
      ...ProductFragment
    }

    isPremium
    inspirationVideosLinks

    hooksCount
    hooks {
      description
    }

    addons
    addonsCount

    totalCreatorEarningsCents

    videoPricePerUnitCents
    hookPricePerUnitCents

    boostedAt
  }
  ${ProductFragment}
`

export const GET_CAMPAIGNS = gql`
  query GetCampaigns($options: GetCampaignsOptions!) {
    getCampaigns(options: $options) {
      items {
        ...CampaignFragment
        product {
          ...ProductFragment
        }
        brand {
          ...BrandFragment
        }
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${CampaignFragment}
  ${ProductFragment}
  ${PaginationFragment}
  ${BrandFragment}
`

export const GET_CAMPAIGNS_BY_BRAND_ID = gql`
  query GetCampaignsByBrandId($brandId: Int!, $options: GetCampaignsOptions!) {
    getCampaignsByBrandId(brandId: $brandId, options: $options) {
      items {
        ...CampaignFragment
        product {
          ...ProductFragment
        }
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${CampaignFragment}
  ${ProductFragment}
  ${PaginationFragment}
`

export const GET_CAMPAIGNS_BY_BRAND_AND_INSPIRATION = gql`
  query GetCampaignsByBrandAndInspiration(
    $brandId: Int!
    $inspirationId: Int!
  ) {
    getCampaignsByBrandAndInspiration(
      brandId: $brandId
      inspirationId: $inspirationId
    ) {
      items {
        ...CampaignFragment
        product {
          ...ProductFragment
        }
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${CampaignFragment}
  ${ProductFragment}
  ${PaginationFragment}
`

export const CREATE_CAMPAIGN = gql`
  mutation CreateCampaign($brandId: Int!, $input: CreateCampaignInput!) {
    createCampaign(brandId: $brandId, input: $input) {
      ...CampaignFragment
      product {
        ...ProductFragment
      }
    }
  }
  ${CampaignFragment}
  ${ProductFragment}
`

export const UPDATE_CAMPAIGN = gql`
  mutation UpdateCampaign($id: Int!, $input: UpdateCampaignInput!) {
    updateCampaign(id: $id, input: $input) {
      ...CampaignFragment
    }
  }
  ${CampaignFragment}
`

export const PAUSE_CAMPAIGN = gql`
  mutation PauseCampaign($id: Int!) {
    pauseCampaign(id: $id) {
      ...CampaignFragment
    }
  }
  ${CampaignFragment}
`

export const UNPAUSE_CAMPAIGN = gql`
  mutation UnpauseCampaign($id: Int!) {
    unpauseCampaign(id: $id) {
      ...CampaignFragment
    }
  }
  ${CampaignFragment}
`

export const COMPLETE_CAMPAIGN = gql`
  mutation CompleteCampaign($id: Int!) {
    completeCampaign(id: $id) {
      ...CampaignFragment
    }
  }
  ${CampaignFragment}
`

export const GET_LATEST_CREATED_CAMPAIGN_BY_BRAND_ID = gql`
  query GetLatestCreatedCampaignByBrandId($brandId: Int!) {
    getLatestCreatedCampaignByBrandId(brandId: $brandId) {
      ...CampaignFragment
      product {
        ...ProductFragment
      }
      brand {
        ...BrandFragment
      }
    }
  }
  ${CampaignFragment}
  ${BrandFragment}
  ${ProductFragment}
`

export const GET_CAMPAIGN_BY_ID = gql`
  query GetCampaignById($id: Int!) {
    getCampaignById(id: $id) {
      ...CampaignFragment
      brand {
        metadata {
          ratingsScore
          ratingsCount
        }
      }
      product {
        ...ProductFragment
      }
    }
  }
  ${CampaignFragment}
  ${ProductFragment}
`

export const DELETE_CAMPAIGN = gql`
  mutation DeleteCampaign($campaignId: Int!) {
    deleteCampaign(campaignId: $campaignId)
  }
`

export const DUPLICATE_CAMPAIGN = gql`
  mutation DuplicateCampaign($id: Int!) {
    duplicateCampaign(id: $id) {
      ...CampaignFragment
    }
  }
  ${CampaignFragment}
`

export interface QueryGetCampaignsResponse {
  getCampaigns: GetCampaignsResponse
}

export interface MutationCreateCampaignResponse {
  createCampaign: Campaign
}

export interface MutationUpdateCampaignResponse {
  updateCampaign: Campaign
}

export interface GetCampaignByIdResponse {
  getCampaignById: Campaign
}

export interface GetCampaignsByBrandIdResponse {
  getCampaignsByBrandId: GetCampaignsResponse
}

export interface GetLatestCreatedCampaignByBrandIdResponse {
  getLatestCreatedCampaignByBrandId: Campaign
}

export interface QueryGetCampaignByIdResponse {
  getCampaignById: Campaign
}
