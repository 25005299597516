import { FunctionComponent, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { PageTabs } from '../../../../components/PageTabs'
import { BrandRoutes } from '../../../BrandRoutes'

const Header: FunctionComponent = () => {
  const { id } = useParams()
  const navigation = useMemo(
    () => [
      {
        text: 'Profile',
        count: null,
        to: BrandRoutes.accountProfile.replace(':id', `${id}`),
      },
      {
        text: 'Reviews',
        count: null,
        to: BrandRoutes.accountReviews.replace(':id', `${id}`),
      },
    ],
    [id],
  )

  return (
    <div className='flex flex-col py-4'>
      <div className='flex flex-row'>
        <h1 className='font-bold text-3xl font-outfit'>Account</h1>
      </div>

      {/* Navigation */}
      <PageTabs
        navigation={navigation}
        menuPosition='right'
        className='mt-4 md:mt-0'
      />
    </div>
  )
}

export { Header }
