import { useMutation } from '@apollo/client'
import qs from 'qs'
import { FunctionComponent } from 'react'
import { useForm } from 'react-hook-form'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import { Button, NotificationType, TextField } from '../../components'
import { UPDATE_PASSWORD_WITH_TOKEN } from '../../gql'
import { useNotification } from '../../utils/hooks'
import { PublicRoutes } from '../../utils/PublicRoutes'
import LOGO from '../../assets/logo/lancape_no_bg.png'

export type ResetPasswordFormData = {
  password: string
  repeatPassword: string
}

interface IDefaultProps {}

interface IProps extends Partial<IDefaultProps> {}

const defaultProps: IDefaultProps = {}

const ResetPassword: FunctionComponent<IProps> = () => {
  const { addNotification } = useNotification()

  const location = useLocation()
  const navigate = useNavigate()

  const { token } = qs.parse(location.search, { ignoreQueryPrefix: true })

  const [updatePasswordWithToken, { loading: updatePasswordLoading }] =
    useMutation(UPDATE_PASSWORD_WITH_TOKEN)

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
  } = useForm({
    defaultValues: {
      password: '',
      repeatPassword: '',
    },
  })

  const onSubmit = async (data: ResetPasswordFormData) => {
    if (data.password !== data.repeatPassword) {
      addNotification(`The passwords don't match.`, NotificationType.error)
      return
    }

    try {
      await updatePasswordWithToken({
        variables: {
          password: data.password,
          token,
        },
      })

      navigate(PublicRoutes.login)
    } catch (error) {
      addNotification(error.message, NotificationType.error)
    }
  }

  if (!token) {
    return <Navigate to={PublicRoutes.login} />
  }

  return (
    <>
      <div className='min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
        <div className='sm:mx-auto sm:w-full sm:max-w-lg'>
          <img className='mx-auto h-12 w-auto' src={LOGO} alt='Workflow' />
          <h3 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>
            Update your password
          </h3>
          {/* <p className='mt-2 text-center text-sm text-gray-600'>
            Set a new password below and then we'll redirect you to the login page.
          </p> */}
        </div>

        <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-lg'>
          <div className='py-8 px-4 sm:px-10'>
            <form className='space-y-6' onSubmit={handleSubmit(onSubmit)}>
              <div>
                <TextField
                  type='password'
                  placeholder={`******`}
                  label='Password'
                  name='password'
                  error={errors.password?.message}
                  {...register('password', {
                    required: `Please set a password.`,
                    minLength: {
                      value: 6,
                      message: `Please set a password with minimum 6 letters.`,
                    },
                    maxLength: {
                      value: 100,
                      message: `Please keep your password under 100 letters.`,
                    },
                  })}
                />
              </div>

              <div>
                <TextField
                  type='password'
                  placeholder={`******`}
                  label='Repeat password'
                  name='repeatPassword'
                  error={errors.repeatPassword?.message}
                  {...register('repeatPassword', {
                    required: `Please set a repeat password.`,
                    minLength: {
                      value: 6,
                      message: `Please set a repeat password with minimum 6 letters.`,
                    },
                    maxLength: {
                      value: 100,
                      message: `Please keep your repeat password under 100 letters.`,
                    },
                    validate: (value) => {
                      if (value === watch('password')) {
                        return true
                      }

                      return `The passwords don't match.`
                    },
                  })}
                />
              </div>

              <div>
                <Button
                  title='Update password'
                  type='submit'
                  loading={updatePasswordLoading}
                  disabled={updatePasswordLoading}
                  width='w-full'
                />
              </div>
            </form>

            <div className='mt-6'>
              <div className='relative'>
                <div className='relative flex justify-center text-sm'>
                  <p className='px-2 text-center text-sm text-gray-600'>
                    Go back to{' '}
                    <Link
                      to={PublicRoutes.login}
                      className='font-medium text-indigo-500 hover:text-indigo-600'
                    >
                      Login
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

ResetPassword.defaultProps = defaultProps

export { ResetPassword }
