import { gql } from '@apollo/client'
import { PaginationFragment } from './common'
import { BrandFragment, CampaignFragment, CreatorFragment } from '.'
import { GetCampaignInvitationsResponse } from './types'

export const CampaignInvitationFragment = gql`
  fragment CampaignInvitationFragment on CampaignInvitation {
    id
    creator {
      ...CreatorFragment
    }
    campaign {
      ...CampaignFragment
      brand {
        ...BrandFragment
      }
    }
    status
    expirationDate
    updatedAt
    createdAt
  }
  ${CampaignFragment}
  ${CreatorFragment}
  ${BrandFragment}
`

export const INVITE_CREATORS_TO_CAMPAIGN = gql`
  mutation InviteCreatorsToCampaign(
    $input: InviteCreatorsToCampaignInput!
    $campaignId: Int!
  ) {
    inviteCreatorsToCampaign(input: $input, campaignId: $campaignId) {
      items {
        ...CampaignInvitationFragment
      }
    }
  }
  ${CampaignInvitationFragment}
`

export const GET_CAMPAIGN_INVITATIONS_BY_CREATOR_ID = gql`
  query GetCampaignInvitationsByCreatorId(
    $creatorId: Int!
    $options: GetCampaignInvitationsOptions!
  ) {
    getCampaignInvitationsByCreatorId(
      creatorId: $creatorId
      options: $options
    ) {
      items {
        ...CampaignInvitationFragment
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${CampaignInvitationFragment}
  ${PaginationFragment}
`

export const RESEND_CAMPAIGN_INVITATION = gql`
  mutation ResendCampaignInvitation($email: String!, $creatorId: Int!) {
    resendCampaignInvitation(email: $email, creatorId: $creatorId) {
      ...CampaignInvitationFragment
    }
  }
  ${CampaignInvitationFragment}
`

export const CANCEL_CAMPAIGN_INVITATION = gql`
  mutation CancelCampaignInvitation($email: String!, $creatorId: Int!) {
    cancelCampaignInvitation(email: $email, creatorId: $creatorId) {
      ...CreatorFragment
    }
  }
  ${CreatorFragment}
`

export interface QueryGetCampaignInvitationsByCreatorIdResponse {
  getCampaignInvitationsByCreatorId: GetCampaignInvitationsResponse
}

export interface MutationInviteCreatorsToCampaignResponse {
  inviteCreatorsToCampaign: GetCampaignInvitationsResponse
}
