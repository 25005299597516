import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { Button, ButtonStyle, Icon, IconType } from '../../../../components'
import { BrandRoutes } from '../../../BrandRoutes'

const Header = () => {
  const { id } = useParams()

  return (
    <div className='sm:flex sm:items-center -mx-4 sm:-mx-6 md:mx-0 py-4'>
      <div className='sm:flex-auto'>
        <h2 className='text-lg font-medium leading-6 text-gray-900'>
          Products
        </h2>
        <p className='mt-1 text-sm text-gray-500'>
          Update your brand's products.
        </p>
      </div>
      <div className='mt-4 sm:ml-16 sm:mt-0 sm:flex-none'>
        <Link to={BrandRoutes.newProduct.replace(':id', `${id}`)}>
          <Button
            styleType={ButtonStyle.fill}
            title='New Product'
            type='button'
            icon={
              <Icon
                type={IconType.edit}
                className='h-5 w-5 text-white'
                aria-hidden='true'
              />
            }
            iconPosition='left'
          />
        </Link>
      </div>
    </div>
  )
}

export { Header }
