import { gql } from '@apollo/client'
import { Creator, GetUploadUrlResponse } from './types'

export const CreatorMetadataFragment = gql`
  fragment CreatorMetadataFragment on CreatorMetadata {
    id
    ratingsScore
    ratingsCount
    approvedAt
    rejectedAt
    warningsCount
  }
`

export const CreatorFragment = gql`
  fragment CreatorFragment on Creator {
    id
    createdAt
    updatedAt
    firstName
    lastName
    email
    status
    onboarding
    gender
    ethnicity
    occupation
    birthday
    interests
    addressLine1
    addressLine2
    addressCity
    addressCounty
    addressState
    addressPostcode
    countryCode
    paypalEmail
    bio
    qualityScore
    pausedReason
    pausedDescription
    metadata {
      ratingsScore
      ratingsCount
    }
    unreadMessagesCount
    isPremium
    phoneNumber

    introVideo {
      url
    }

    avatar {
      url
    }

    thumbnail {
      url
    }
  }
`

export const UPDATE_CREATOR = gql`
  mutation UpdateCreator($id: Int!, $input: UpdateCreatorInput!) {
    updateCreator(id: $id, input: $input) {
      ...CreatorFragment
    }
  }
  ${CreatorFragment}
`

export const GET_CREATOR_INTRO_VIDEO_UPLOAD_URL = gql`
  mutation GetCreatorIntroVideoUploadUrl($creatorId: Int!, $mime: String!) {
    getCreatorIntroVideoUploadUrl(creatorId: $creatorId, mime: $mime) {
      uploadUrl
      fileName
    }
  }
`

export const PAUSE_CREATOR = gql`
  mutation PauseCreator($id: Int!, $input: PauseCreatorInput!) {
    pauseCreator(id: $id, input: $input) {
      ...CreatorFragment

      metadata {
        ...CreatorMetadataFragment
      }
    }
  }

  ${CreatorFragment}
  ${CreatorMetadataFragment}
`

export const UNPAUSE_CREATOR = gql`
  mutation UnpauseCreator($id: Int!) {
    unpauseCreator(id: $id) {
      ...CreatorFragment

      metadata {
        ...CreatorMetadataFragment
      }
    }
  }

  ${CreatorFragment}
  ${CreatorMetadataFragment}
`

export const REQUEST_APPROVAL = gql`
  mutation RequestApproval($id: Int!) {
    requestApproval(id: $id) {
      id
      status
      pausedReason
    }
  }
`

export const GET_CREATOR_EARNINGS = gql`
  query GetCreatorEarningsByCreatorId($creatorId: Int!) {
    getCreatorEarningsByCreatorId(creatorId: $creatorId) {
      creator {
        ...CreatorFragment
      }
      earnings {
        totalEarningCents
        totalPaymentCents
        totalPendingPaymentCents
        currency
      }
    }
  }
  ${CreatorFragment}
`

export const GET_CREATORS_BY_BRAND = gql`
  query GetCreatorsByBrand($brandId: Int!) {
    getCreatorsByBrand(brandId: $brandId) {
      id
      firstName
      lastName
      avatar {
        url
      }
    }
  }
`

export interface UpdateCreatorResponse {
  updateCreator: Creator
}

export interface GetCreatorIntroVideoUploadUrlResponse {
  getCreatorIntroVideoUploadUrl: GetUploadUrlResponse
}

export interface QueryGetCreatorsByBrandResponse {
  getCreatorsByBrand: Creator[]
}
