import { useMutation } from '@apollo/client'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import validator from 'validator'
import {
  Button,
  Icon,
  IconType,
  Loader,
  NotificationType,
  RadioGroup,
  Select,
  TextArea,
  TextField,
} from '../../../components'
import {
  CREATE_PRODUCT,
  GET_PRODUCTS_BY_BRAND_ID,
  GET_PRODUCT_PHOTO_UPLOAD_URL,
  GetProductPhotoUploadUrlResponse,
} from '../../../gql'
import {
  Brand,
  Currency,
  GetProductsSort,
  MutationGetProductPhotoUploadUrlArgs,
  ProductType,
  User,
} from '../../../gql/types'
import { cleanUrl } from '../../../utils/helpers'
import { useNotification } from '../../../utils/hooks'
import { mapCurrencyToSign, productTypeOptions } from '../../../utils/mappers'
import { BrandRoutes } from '../../BrandRoutes'
import { PhotoUploadUrl } from '../components/PhotoUploadUrl'
type EditNewProductFormData = {
  productName: string
  productType: ProductType
  productLink: string | null
  productDetails: string
  productPrice: number
  currency: Currency
}

interface IProps {
  user: User
  brand: Brand
}

const NewProduct = ({ user, brand }: IProps) => {
  const { addNotification } = useNotification()
  const navigate = useNavigate()
  const [photoUrl, setPhotoUrl] = useState(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const [errorSelectedFile, setErrorSelectedFile] = useState(false)

  const [createProduct, { loading: loadingCreateProduct }] = useMutation(
    CREATE_PRODUCT,
    {
      refetchQueries: [
        {
          query: GET_PRODUCTS_BY_BRAND_ID,
          variables: {
            brandId: brand.id,
            options: {
              query: '',
              filters: {},
              sort: GetProductsSort.newest,
              limit: 6,
              skip: 0,
            },
          },
        },
      ],
    },
  )

  const [getProductPhotoUploadUrl, { loading: loadingProductPhotoUploadUrl }] =
    useMutation<
      GetProductPhotoUploadUrlResponse,
      MutationGetProductPhotoUploadUrlArgs
    >(GET_PRODUCT_PHOTO_UPLOAD_URL)

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setError,
  } = useForm<EditNewProductFormData>({
    defaultValues: {
      productName: null,
      productType: ProductType.physical,
      productLink: null,
      productDetails: null,
      productPrice: null,
      currency: brand?.currency ?? null,
    },
  })

  useEffect(() => {
    if (watch('productPrice') === null) {
      return
    }

    if (
      watch('productPrice') > 0 ||
      (watch('productPrice') === 0 &&
        watch('productType') === ProductType.digital)
    ) {
      setError('productPrice', { type: 'custom', message: null })
      return
    }

    setError('productPrice', {
      type: 'custom',
      message: `Please set a correct product price.`,
    })
  }, [watch('productPrice'), watch('productType')])

  if (!brand) {
    return <Loader />
  }

  const onProductPhotoUpload = async (file: File) => {
    setSelectedFile(file)
    if (!file) {
      setErrorSelectedFile(null)
      return
    }

    setErrorSelectedFile(false)
    try {
      const { data } = await getProductPhotoUploadUrl({
        variables: {
          brandId: brand.id,
          mime: file.type,
        },
      })

      await axios.put(data.getProductPhotoUploadUrl.uploadUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
      })

      setPhotoUrl(data.getProductPhotoUploadUrl.fileName)
    } catch (error) {
      console.log('error onProductPhotoUpload', error.message)
    }
  }

  const onSubmit = async (newProduct: EditNewProductFormData) => {
    const productPrice = +newProduct.productPrice
    const productLink =
      newProduct.productLink && newProduct.productLink !== ''
        ? cleanUrl(newProduct.productLink)
        : null

    try {
      await createProduct({
        variables: {
          brandId: brand.id,
          input: {
            name: newProduct.productName,
            type: newProduct.productType,
            link: productLink,
            details: newProduct.productDetails,
            price: productPrice,
            currency: newProduct.currency ?? brand?.currency,
            photoFileName: photoUrl,
          },
        },
      })

      addNotification('Product created successfully', NotificationType.success)
      navigate(BrandRoutes.products.replace(':id', `${brand.id}`))
    } catch (error) {
      console.log('@error createProduct', error.message)
      addNotification(
        `Something went wrong. Please try again.`,
        NotificationType.error,
      )
    }
  }

  return (
    <div className='py-4'>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='space-y-8 divide-y divide-gray-200'
      >
        <div className='space-y-8 divide-y divide-gray-200'>
          <div>
            <div>
              <h3 className='text-lg font-semibold leading-6 text-gray-900 font-sans'>
                Product
              </h3>
              <p className='mt-1 text-sm font-normal leading-5 text-gray-500 font-sans'>
                What are your products’ features? Write out any details you
                consider set your product apart.
              </p>
            </div>

            <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
              <div className='sm:col-span-6'>
                <PhotoUploadUrl
                  photoUpload={
                    selectedFile ? URL.createObjectURL(selectedFile) : null
                  }
                  onChange={onProductPhotoUpload}
                />
                <p className='mt-1 text-sm font-normal leading-5 text-gray-500 font-sans'>
                  Please use a square image.
                </p>
                {errorSelectedFile && !photoUrl && (
                  <div
                    className='relative mt-2 text-sm text-red-600'
                    id='errorSelectedFile'
                  >
                    <div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
                      <Icon
                        type={IconType.exclamationCircleFill}
                        className='h-5 w-5 text-red-500'
                        aria-hidden='true'
                      />
                    </div>
                    Please set a photo.
                  </div>
                )}
              </div>

              <div className='sm:col-span-6'>
                <TextField
                  error={errors.productName?.message}
                  label='Name'
                  placeholder='Add a product'
                  {...register('productName', {
                    required: `Please set a product name.`,
                    minLength: {
                      value: 2,
                      message: `Your product name must be longer than or equal to 2 characters.`,
                    },
                    maxLength: {
                      value: 100,
                      message: `Your product name cannot have more than 100 characters.`,
                    },
                  })}
                />
              </div>

              <div className='sm:col-span-6'>
                <TextField
                  error={errors.productPrice?.message}
                  label='Price'
                  placeholder='0.00'
                  className='pl-7'
                  leftElement={
                    <span className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
                      <span className='text-gray-600 sm:text-sm'>
                        {mapCurrencyToSign(watch('currency') ?? brand.currency)}
                      </span>
                    </span>
                  }
                  rightElement={
                    <div className='absolute inset-y-0 right-0 flex items-center'>
                      <Controller
                        name='currency'
                        control={control}
                        render={({ field }) => (
                          <Select
                            // options={Object.keys(Currency)}
                            options={Object.keys(Currency).map((currency) =>
                              (currency as Currency).toUpperCase(),
                            )}
                            field={field}
                            defaultValues={brand?.currency.toUpperCase()}
                            className='-mt-1 w-16 rounded-l-none'
                          />
                        )}
                      />
                    </div>
                  }
                  {...register('productPrice', {
                    required: `Please set a product price.`,
                    maxLength: {
                      value: 100,
                      message: `Product price too long.`,
                    },
                    valueAsNumber: true,
                    validate: (value) => {
                      if (
                        value > 0 ||
                        (value === 0 &&
                          watch('productType') === ProductType.digital)
                      ) {
                        return true
                      }

                      return `Please set a correct product price.`
                    },
                  })}
                />
                <p className='mt-1 text-sm font-normal leading-5 text-gray-500 font-sans'>
                  The product price must be a positive numerical value, with
                  zero allowable only for digital products.
                </p>
              </div>

              <div className='sm:col-span-6'>
                <Controller
                  name='productType'
                  control={control}
                  rules={{ required: `Please set a product type.` }}
                  render={({ field }) => (
                    <RadioGroup
                      options={productTypeOptions}
                      field={field}
                      className='mt-1 sm:grid-cols-2'
                      error={errors?.productType?.message}
                    />
                  )}
                />
              </div>

              <div className='sm:col-span-6'>
                <TextField
                  error={errors.productLink?.message}
                  label='Product link'
                  placeholder='www.example.com/product'
                  className={'rounded-none rounded-r-md'}
                  leftElement={
                    <span className='inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm'>
                      https://
                    </span>
                  }
                  {...register('productLink', {
                    // required: `Please set a link for your product.`,
                    maxLength: {
                      value: 1000,
                      message: `Your product link is too long.`,
                    },
                    validate: {
                      value: (productLink) => {
                        if (
                          productLink === null ||
                          productLink === '' ||
                          validator.isURL(productLink)
                        ) {
                          return true
                        }

                        return 'Invalid link.'
                      },
                    },
                  })}
                />
              </div>

              <div className='sm:col-span-6'>
                <TextArea
                  error={errors.productDetails?.message}
                  label='Other details'
                  placeholder='Add product details...'
                  {...register('productDetails', {
                    // required: `Please add more details about your product.`,
                    maxLength: {
                      value: 10000,
                      message: `Your product description too long.`,
                    },
                  })}
                />
                <p className='mt-1 text-sm font-normal leading-5 text-gray-500 font-sans'>
                  Any info that can be useful to our creators
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className='p-5'>
          <div className='flex justify-end'>
            <Button
              type='submit'
              title='Save'
              loading={loadingCreateProduct}
              disabled={loadingCreateProduct || loadingProductPhotoUploadUrl}
              onClick={() => {
                if (!selectedFile || selectedFile === null) {
                  setErrorSelectedFile(true)
                }
              }}
            />
          </div>
        </div>
      </form>
    </div>
  )
}

export { NewProduct }
