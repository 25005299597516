import { Pagination as PaginationComponent } from '../../../../components'
import { Collaboration, Pagination } from '../../../../gql/types'
import { PricingData } from '../../../../utils/PricingUtil'
import { GridItem } from '../GridItem'

interface IProps {
  pricing: PricingData
  collaborations: Collaboration[]
  pagination?: Pagination
  setPage(page: number): void
}

const Grid = ({ pricing, collaborations, pagination, setPage }: IProps) => {
  return (
    <>
      <ul
        role='list'
        className='py-2 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8'
      >
        {collaborations.map((collaboration) => (
          <GridItem
            key={collaboration.id}
            collaboration={collaboration}
            pricing={pricing}
          />
        ))}
      </ul>
      {collaborations?.length === 0 && (
        <div className='py-4 pl-4 pr-3 text-sm sm:pl-6 w-full'>
          No campaigns.
        </div>
      )}
      {pagination && (
        <PaginationComponent
          pagination={pagination}
          setPage={setPage}
          className='bg-white'
        />
      )}
    </>
  )
}

export { Grid }
