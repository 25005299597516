import * as React from 'react'
import { Link } from 'react-router-dom'
import { PublicRoutes } from '../../utils/PublicRoutes'

const NotFound = ({ statusCode }) => {
  let title = '',
    description = ''
  if (statusCode === 404) {
    title = 'Page not found'
    description = 'Please check the URL in the address bar and try again.'
  }

  return (
    <div className='min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8'>
      <div className='mx-auto max-w-max'>
        <main className='sm:flex'>
          {/* <p className='text-4xl font-bold tracking-tight text-gray-600 sm:text-5xl'>
            {statusCode}
          </p> */}
          <div className='sm:ml-6'>
            <div className=' sm:pl-6'>
              <h1 className='text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl'>
                {title}
              </h1>
              <p className='mt-1 text-base text-gray-500'>{description}</p>
            </div>
            <div className='mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6'>
              <Link
                to={PublicRoutes.login}
                className='cursor-pointer inline-flex items-center rounded-md border border-transparent bg-slate-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2'
              >
                Go back home
              </Link>
              <a
                href='https://useclip.com/contact'
                target='_blank'
                rel='noopener'
                className='cursor-pointer inline-flex items-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2'
              >
                Contact support
              </a>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

export { NotFound }
