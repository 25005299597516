import classnames from 'classnames'
import { Pagination as IPagination, Template } from '../../../../gql/types'
import {
  Loader,
  Pagination as PaginationComponent,
} from '../../../../components'
import { ListItem } from './ListItem'

const TableHead = () => {
  return (
    <thead className='bg-white'>
      <tr>
        <th
          scope='col'
          className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-600 sm:pl-6'
        >
          Name
        </th>
        <th
          scope='col'
          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-600'
        >
          Message
        </th>
        <th
          scope='col'
          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-600'
        >
          Owner
        </th>
        <th
          scope='col'
          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-600'
        >
          Updated
        </th>
        <th
          scope='col'
          className='pl-3 pr-4 py-3.5 text-left text-sm font-semibold text-gray-900 sm:pr-6'
        >
          <span className='sr-only'>Actions</span>
        </th>
      </tr>
    </thead>
  )
}

interface IProps {
  templates: Template[]
  pagination: IPagination
  setPage(page: number): void
  loading: boolean
}

const List = ({ templates, pagination, setPage, loading }: IProps) => {
  if (loading) {
    return <Loader />
  }

  return (
    <>
      <div className='flex flex-col bg-white'>
        <table className='min-w-full divide-y divide-gray-200'>
          <TableHead />
          <tbody className='divide-y divide-gray-200 bg-white'>
            {pagination.numberOfHits === 0 && (
              <tr>
                <td colSpan={6}>
                  <p className='py-4 pl-4 pr-3 text-sm sm:pl-6 w-full'>
                    No templates.
                  </p>
                </td>
              </tr>
            )}
            {templates.map((template, index) => (
              <tr
                key={template.id}
                className={classnames(
                  index % 2 === 0 ? 'bg-gray-50' : 'bg-white',
                )}
              >
                <ListItem template={template} />
              </tr>
            ))}
          </tbody>
        </table>
        {pagination && (
          <PaginationComponent pagination={pagination} setPage={setPage} />
        )}
      </div>
    </>
  )
}

export { List }
