import { Controller } from 'react-hook-form'
import { RadioGroup } from '../../../../components'
import { videoTypeOptions } from '../../../../utils/mappers'
import { VideoType } from '../../../../gql/types'

interface Props {
  control: any
  errors: any
}

const SetVideoType = ({ control, errors }: Props) => {
  return (
    <div>
      <div className='mt-10 sm:mt-0'>
        <div className='md:grid md:grid-cols-3 md:gap-6'>
          <div className='md:col-span-1'>
            <div className=''>
              <h3 className='text-lg font-semibold font-sans leading-6 text-gray-900'>
                Video
              </h3>
              <p className='mt-1 text-sm font-normal font-sans leading-5 text-gray-500'>
                What type of video is it?
              </p>
            </div>
          </div>
          <div className='mt-5 md:col-span-2 md:mt-0'>
            <div className='md:shadow rounded-t-md sm:overflow-hidden'>
              <div className='py-5 md:bg-white space-y-6 sm:p-6'>
                <div className='grid gap-6'>
                  <div className='col-span-3 sm:col-span-2'>
                    <Controller
                      name='videoType'
                      control={control}
                      rules={{ required: `Please select a video type.` }}
                      render={({ field }) => (
                        <RadioGroup
                          options={videoTypeOptions.map((value) => {
                            switch (value.type) {
                              case VideoType.upToTheCreator:
                                value.name = 'Creator take'
                                value.description = `This is a video with a creative take from the creator's perspective`
                                break
                              case VideoType.testimonial:
                                value.description = `This is a testimonial video`
                                break
                              case VideoType.unboxing:
                                value.description = `This is an unboxing video`
                                break
                              case VideoType.howTo:
                                value.description = `Creator shows how the product works`
                                break
                            }

                            return value
                          })}
                          field={field}
                          error={errors?.videoType?.message}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='hidden sm:block' aria-hidden='true'>
        <div className='py-5'>
          <div className='border-t border-gray-200' />
        </div>
      </div>
    </div>
  )
}

export { SetVideoType }
