import { useMutation, useQuery } from '@apollo/client'
import { FunctionComponent } from 'react'
import { Button, ButtonColor, Loader } from '../../../components'
import { GET_BRAND_INVOICES, GET_CUSTOMER_PORTAL_SESSION } from '../../../gql'
import {
  Brand,
  GetInvoicesResponse,
  MutationGetCustomerPortalSessionArgs,
  User,
} from '../../../gql/types'
import { BrandRoutes } from '../../BrandRoutes'
import { ClipCredits } from '../components/ClipCredits'
import { Header } from '../components/Header'
import { InvoicesList } from './InvoicesList'

interface IProps {
  user: User
  brand: Brand
}

export const Billing: FunctionComponent<IProps> = ({ brand }) => {
  const [getCustomerPortalSession, { loading, error }] = useMutation<
    any,
    MutationGetCustomerPortalSessionArgs
  >(GET_CUSTOMER_PORTAL_SESSION)

  const {
    loading: getInvoicesByBrandIdLoading,
    error: getInvoicesByBrandIdError,
    data: getInvoicesByBrandIdData,
  } = useQuery<
    { getInvoicesByBrandId: GetInvoicesResponse },
    { brandId: Number }
  >(GET_BRAND_INVOICES, {
    variables: {
      brandId: brand.id,
    },
  })

  const redirectToCustomerPortal = async () => {
    try {
      const returnUrl = BrandRoutes.billing.replace(':id', `${brand.id}`)

      const {
        data: { getCustomerPortalSession: url },
      } = await getCustomerPortalSession({
        variables: {
          brandId: brand.id,
          input: {
            returnUrl,
          },
        },
      })

      window.open(url, '_blank', 'noreferrer')
    } catch (error) {
      console.error(error)
    }
  }

  const invoices = getInvoicesByBrandIdData?.getInvoicesByBrandId?.invoices

  return (
    <div>
      <Header />

      <div className='flex flex-col space-y-2 max-w-xs'>
        {!brand && <Loader />}
        {brand && <ClipCredits brand={brand} />}

        <Button
          title='Update Payment Method'
          type='button'
          colour={ButtonColor.primary}
          loading={loading}
          disabled={loading}
          onClick={() => redirectToCustomerPortal()}
        />
      </div>

      {(error || getInvoicesByBrandIdError) && (
        <p className='mt-2'>There has been an error. Try refreshing the page</p>
      )}
      {getInvoicesByBrandIdLoading && <Loader />}
      {!getInvoicesByBrandIdLoading && (
        <div className='flow-root'>
          {invoices?.length !== 0 && (
            <p className='mt-4 text-base font-semibold leading-6 text-gray-900'>
              Billing history
            </p>
          )}
          <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
              <div className='shadow ring-1 ring-black ring-opacity-5 sm:rounded-t mb-5'>
                <InvoicesList invoices={invoices} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
