import { Creator, CreatorPausedReason, CreatorStatus } from '../gql/types'

export const isCreatorAllowedToAccessOpportunities = (creator: Creator) => {
  if (
    creator.status === CreatorStatus.draft ||
    creator.status === CreatorStatus.pending
  ) {
    return false
  }

  if (creator.status === CreatorStatus.rejected) {
    return false
  }

  if (creator.status === CreatorStatus.paused) {
    return false
  }

  return true
}

export const mapRoute = () => {}
