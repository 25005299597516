import { Link, useParams } from 'react-router-dom'
import {
  Button,
  ButtonColor,
  DeleteModal,
  Icon,
  IconType,
  NotificationType,
} from '../../../../components'
import { Product } from '../../../../gql/types'
import { useNotification } from '../../../../utils/hooks'
import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { DELETE_PRODUCT } from '../../../../gql'
import { BrandRoutes } from '../../../BrandRoutes'

interface IDefaultProps {}

const defaultProps: IDefaultProps = {}

interface IProps extends Partial<IDefaultProps> {
  product: Product
}

const GridButtons = ({ product }: IProps) => {
  const { id } = useParams()
  const { addNotification } = useNotification()
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false)

  const [deleteProduct, { loading }] = useMutation(DELETE_PRODUCT, {
    update(cache) {
      const normalizedId = cache.identify(product)
      cache.evict({ id: normalizedId })
      cache.gc()
    },
  })

  const onDelete = async () => {
    try {
      await deleteProduct({
        variables: {
          productId: product.id,
        },
      })

      addNotification(`Product deleted.`, NotificationType.success)
    } catch (error) {
      addNotification(
        `The product cannot be deleted because it is attached to a campaign.`,
        NotificationType.error,
      )
    }
  }

  return (
    <>
      <div>
        <div className='-mt-px flex divide-x divide-gray-200'>
          <div className='-ml-px flex w-0 flex-1 justify-center cursor-pointer'>
            {/* <Button
              title='Delete'
              type='button'
              colour={ButtonColor.white}
              icon={
                <Icon type={IconType.trash} className='h-5 w-5 text-primary' />
              }
              iconPosition='left'
              onClick={() => {
                setIsDeleteConfirmationOpen(true)
              }}
              className='py-3 w-full'
            /> */}
            <Link
              to={null}
              className='group flex items-center px-4 py-4 text-sm cursor-pointer text-gray-800'
              onClick={() => setIsDeleteConfirmationOpen(true)}
            >
              <Icon
                type={IconType.trash}
                className='mr-3 h-4 w-4 text-gray-400 group-hover:text-gray-500'
                aria-hidden='true'
              />
              Delete
            </Link>
          </div>
          <div className='-ml-px flex w-0 flex-1 justify-center cursor-pointer'>
            <Button
              title='Edit'
              type='button'
              colour={ButtonColor.primary}
              to={BrandRoutes.productEdit
                .replace(':id', id)
                .replace(':productId', `${product.id}`)}
              className='w-full'
            />
          </div>
        </div>
      </div>

      <DeleteModal
        title='Confirm'
        description='Are you sure you want to delete this product? All of your data will be permanently removed. This action cannot be undone.'
        open={isDeleteConfirmationOpen}
        setOpen={setIsDeleteConfirmationOpen}
        onDelete={onDelete}
      />
    </>
  )
}

GridButtons.defaultProps = defaultProps

export { GridButtons }
