import React, { ReactElement, FunctionComponent, ReactNode } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { Loader, LoaderType } from '../Loader'
import { Tooltip } from 'react-tooltip'

export enum ButtonColor {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  black = 'black',
  success = 'success',
  indigo = 'indigo',
  blue = 'blue',
  orange = 'orange',
  error = 'error',
  warning = 'warning',
  lightGray = 'lightGray',
  white = 'white',
  cyan = 'cyan',
}

export enum ButtonStyle {
  fill = 'fill',
  ghost = 'ghost',
  outline = 'outline',
}

export enum ButtonSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

interface IDefaultProps {
  className?: string
  type: 'submit' | 'button' | 'reset'
  title: ReactNode
  disabled: boolean
  onClick?: (event: React.MouseEvent) => void
  styleType: ButtonStyle
  colour: ButtonColor
  icon?: ReactElement
  iconPosition: 'left' | 'right'
  to?: string
  href?: string
  target?: string
  id?: string
  size: ButtonSize
  inactive: boolean
  loading?: boolean
  width?: string
  tooltip?: string
  ref?: any
}

interface IProps extends Partial<IDefaultProps> {}

const defaultProps: IDefaultProps = {
  className: '',
  type: 'submit',
  styleType: ButtonStyle.fill,
  colour: ButtonColor.primary,
  title: '',
  disabled: false,
  icon: undefined,
  iconPosition: 'right',
  to: undefined,
  href: undefined,
  target: undefined,
  id: undefined,
  size: ButtonSize.medium,
  inactive: false,
  loading: false,
  width: undefined,
  tooltip: undefined,
  ref: undefined,
}

const Button: FunctionComponent<IProps> = (props: IProps) => {
  const {
    className,
    type,
    title,
    disabled,
    onClick,
    styleType,
    colour,
    icon,
    iconPosition,
    href,
    to,
    target,
    id,
    size,
    inactive,
    loading,
    width,
    ref,
  } = props

  let bgColor = 'bg-primary-800 hover:bg-primary-900',
    color = 'text-white',
    borderColor = 'border-primary-800'

  let iconElement
  if (icon) {
    iconElement = React.cloneElement(icon)
  }

  switch (colour) {
    case ButtonColor.primary:
      bgColor = 'bg-gray-800 shadow-sm hover:bg-gray-900 focus:ring-gray-900'
      color = styleType === ButtonStyle.fill ? 'text-white' : 'text-gray-800'
      borderColor = 'border-gray-800'
      break
    case ButtonColor.secondary:
      bgColor =
        'bg-indigo-500 shadow-sm hover:bg-indigo-600 focus:ring-indigo-600'
      color = styleType === ButtonStyle.fill ? 'text-white' : 'text-indigo-500'
      borderColor = 'border-indigo-800'
      break
    case ButtonColor.tertiary:
      bgColor = 'bg-gray-200 shadow-sm hover:bg-gray-300 focus:ring-gray-300'
      color = styleType === ButtonStyle.fill ? 'text-gray-700' : 'text-gray-800'
      borderColor = 'border-gray-800'
      break
    case ButtonColor.lightGray:
      bgColor = 'bg-gray-200 shadow-sm hover:bg-gray-300 focus:ring-gray-300'
      color = styleType === ButtonStyle.fill ? 'text-gray-700' : 'text-gray-800'
      borderColor = 'border-gray-200'
      break
    case ButtonColor.black:
      bgColor = 'bg-black shadow-sm hover:bg-black focus:ring-black'
      color = styleType === ButtonStyle.fill ? 'text-white' : 'text-black'
      borderColor = 'border-black'
      break
    case ButtonColor.white:
      bgColor = 'bg-white shadow-sm hover:bg-gray-50 focus:ring-gray-300'
      color = styleType === ButtonStyle.fill ? 'text-gray-700' : 'text-gray-800'
      borderColor = 'border-gray-300'
      break
    case ButtonColor.success:
      bgColor = 'bg-green-600 shadow-sm hover:bg-green-700 focus:ring-green-700'
      color = styleType === ButtonStyle.fill ? 'text-white' : 'text-green-800'
      borderColor = 'border-green-800'
      break
    case ButtonColor.indigo:
      bgColor =
        'bg-indigo-500 shadow-sm hover:bg-indigo-600 focus:ring-indigo-600'
      color = styleType === ButtonStyle.fill ? 'text-white' : 'text-indigo-500'
      borderColor = 'border-indigo-500'
      break
    case ButtonColor.cyan:
      bgColor = 'bg-cyan-500 shadow-sm hover:bg-cyan-600 focus:ring-cyan-600'
      color = styleType === ButtonStyle.fill ? 'text-white' : 'text-cyan-500'
      borderColor = 'border-cyan-500'
      break
    case ButtonColor.blue:
      bgColor = 'bg-blue-600 shadow-sm hover:bg-blue-700 focus:ring-blue-700'
      color = 'text-blue-800'

      if (styleType === ButtonStyle.fill) {
        color = 'text-white'
      }

      if (styleType === ButtonStyle.ghost) {
        color =
          'text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-0'
      }

      borderColor = 'border-blue-800'
      break
    case ButtonColor.orange:
      bgColor =
        'bg-orange-400 shadow-sm hover:bg-orange-500 focus:ring-orange-500'
      color = styleType === ButtonStyle.fill ? 'text-white' : 'text-orange-800'
      borderColor = 'border-orange-800'
      break
    case ButtonColor.error:
      bgColor = 'bg-red-800 shadow-sm hover:bg-red-900 focus:ring-red-900'
      color = styleType === ButtonStyle.fill ? 'text-white' : 'text-red-800'
      borderColor = 'border-red-800'
      break
    case ButtonColor.warning:
      bgColor =
        'bg-yellow-500 shadow-sm hover:bg-yellow-600 focus:ring-yellow-600'
      color = styleType === ButtonStyle.fill ? 'text-white' : 'text-yellow-500'
      borderColor = 'border-yellow-600'
      break
    default:
      break
  }

  let shadow = 'shadow-sm'
  if (styleType === ButtonStyle.ghost) {
    bgColor =
      'bg-transparent focus:outline-none focus:ring-0 focus:ring-transparent'
    borderColor = null
    shadow = null
  }

  if (styleType === ButtonStyle.outline) {
    bgColor = 'bg-transparent focus:ring-current'
  }

  let fontSize = 'text-sm',
    fontWeight = 'font-medium',
    py = 'py-2',
    px = 'px-4'
  switch (size) {
    case ButtonSize.small:
      fontSize = 'text-xs'
      py = 'py-1'
      px = 'px-2'
      break
    case ButtonSize.medium:
      fontSize = 'text-sm'
      fontWeight = 'font-medium'
      py = 'py-2'
      px = 'px-4'
      break
    case ButtonSize.large:
      fontSize = 'text-sm'
      py = 'py-3'
      px = 'px-6'
      break
    default:
      break
  }

  let propsForTooltip = {} as any
  if (props.tooltip) {
    propsForTooltip = {
      ['data-tooltip-id']: props.tooltip.replace(/\\s+/g, ''),
      ['data-tooltip-content']: props.tooltip,
    }
  }

  if (to) {
    return (
      <Link
        to={to}
        onClick={onClick}
        className={classNames(
          `${bgColor} ${color} ${borderColor} ${size} ${colour} ${styleType} ${inactive} ${disabled} ${py} ${px} ${fontWeight} ${fontSize} ${shadow} ${
            // width === 'w-full' ? 'w-full' : 'w-full md:w-fit'
            width === 'w-full' ? 'w-full' : 'min-w-max'
          }`,
          'inline-flex justify-center text-center items-center align-middle border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2',
          className,
          disabled && 'opacity-70',
        )}
        style={{ width }}
        {...propsForTooltip}
      >
        {icon && iconPosition === 'left' && (
          <span className={title ? 'mr-2' : null}>{iconElement}</span>
        )}
        {title && <span>{title}</span>}
        {icon && iconPosition === 'right' && (
          <span className={title ? 'ml-2' : null}>{iconElement}</span>
        )}
        <Tooltip
          id={props.tooltip?.replace(/\\s+/g, '')}
          className='!bg-primary !text-xs max-w-xs !z-50'
        />
      </Link>
    )
  }

  if (href && !disabled) {
    return (
      <a
        href={href}
        target={target}
        rel='noopener noreferrer'
        type={type}
        onClick={onClick}
        id={id}
        className={classNames({ [className as string]: className })}
        style={{ width }}
      >
        {icon && iconPosition === 'left' && (
          <span className={title ? 'mr-2' : null}>{iconElement}</span>
        )}
        {title && <span>{title}</span>}
        {icon && iconPosition === 'right' && (
          <span className={title ? 'ml-2' : null}>{iconElement}</span>
        )}
      </a>
    )
  }

  return (
    <button
      type={type}
      onClick={onClick}
      id={id}
      disabled={disabled || loading}
      className={classNames(
        `${bgColor} ${color} ${borderColor} ${size} ${colour} ${styleType} ${inactive} ${disabled} ${py} ${px} ${fontWeight} ${fontSize} ${shadow} ${
          // width === 'w-full' ? 'w-full' : 'w-full md:w-fit'
          width === 'w-full' ? 'w-full' : 'min-w-max'
        }`,
        'inline-flex justify-center text-center items-center align-middle border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2',
        className,
        disabled && 'opacity-70',
      )}
      style={{ width }}
      ref={ref}
      {...propsForTooltip}
    >
      {icon && iconPosition === 'left' && !loading && (
        <span className={title ? 'mr-2' : null}>{iconElement}</span>
      )}
      {title && !loading && <span>{title}</span>}
      {loading && <Loader type={LoaderType.button} />}
      {icon && iconPosition === 'right' && !loading && (
        <span className={title ? 'ml-2' : null}>{iconElement}</span>
      )}

      <Tooltip
        id={props.tooltip?.replace(/\\s+/g, '')}
        className='!bg-primary !text-xs max-w-xs !z-50'
      />
    </button>
  )
}

Button.defaultProps = defaultProps

export { Button }
