import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { PageTabs } from '../../../../components/PageTabs'
import { CreatorRoutes } from '../../../CreatorRoutes'

const Header = () => {
  const { id } = useParams()

  const navigation = useMemo(
    () => [
      {
        text: 'Profile',
        count: null,
        to: CreatorRoutes.accountProfile.replace(':id', `${id}`),
      },
      {
        text: 'Earnings',
        count: null,
        to: CreatorRoutes.accountEarnings.replace(':id', `${id}`),
      },
      {
        text: 'Portfolio',
        count: null,
        to: CreatorRoutes.accountPortfolio.replace(':id', `${id}`),
      },
      {
        text: 'Reviews',
        count: null,
        to: CreatorRoutes.accountReviews.replace(':id', `${id}`),
      },
    ],
    [id],
  )

  return (
    <div className='flex flex-col py-4'>
      <div className='grid grid-cols-1 lg:flex lg:flex-row lg:justify-between'>
        <div className='col-span-1'>
          <div className='flex flex-row justify-between'>
            <h1 className='font-bold text-3xl font-outfit'>Account</h1>
          </div>
        </div>
      </div>

      {/* Navigation */}
      <PageTabs
        navigation={navigation}
        menuPosition='right'
        className='mt-4 md:mt-0'
      />
    </div>
  )
}

export { Header }
