import { useQuery } from '@apollo/client'
import { FunctionComponent, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ListReviews, Loader } from '../../../components'
import { Divider } from '../../../components/Divider'
import {
  GET_COLLABORATION_BY_UUID,
  GET_RATINGS,
  GetCollaborationByUuidResponse,
} from '../../../gql'
import {
  Collaboration,
  Pagination,
  QueryGetCollaborationByUuidArgs,
  Rating,
  RatingType,
} from '../../../gql/types'
import { userFullname } from '../../../utils/UserUtil'
import { AnalyticsEvent, trackEvent } from '../../../utils/analytics'
import { Header } from '../components/Header'
import { BrandCard } from './BrandCard'
import { WriteReview } from './WriteReview'

interface GetRatings {
  getRatings: {
    items: Rating[]
    pagination: Pagination
  }
}

interface IDefaultProps {}

interface IProps extends Partial<IDefaultProps> {}

const defaultProps: IDefaultProps = {}

const BrandDetails: FunctionComponent<IProps> = () => {
  const { id, uuid } = useParams()
  const limit = 6
  const [skip, setSkip] = useState(0)

  const {
    loading: getCollaborationLoading,
    error: getCollaborationError,
    data: getCollaborationData,
  } = useQuery<GetCollaborationByUuidResponse, QueryGetCollaborationByUuidArgs>(
    GET_COLLABORATION_BY_UUID,
    {
      variables: {
        uuid: uuid,
      },
    },
  )

  const {
    loading: getRatingsLoading,
    error: getRatingsError,
    data: getRatingsData,
    refetch: getRatingsRefetch,
  } = useQuery<GetRatings>(GET_RATINGS, {
    variables: {
      options: {
        filters: {
          brandId:
            getCollaborationData?.getCollaborationByUuid?.campaign?.brand.id,
          type: RatingType.creatorToBrand,
        },
        skip,
        limit,
      },
    },
    skip: !getCollaborationData?.getCollaborationByUuid?.campaign.brand?.id,
  })

  // useEffect(() => {
  //   getRatingsRefetch()
  // }, [skip])

  useEffect(() => {
    if (getCollaborationData?.getCollaborationByUuid) {
      trackEvent(AnalyticsEvent.viewCreatorsCollaborationReviews, {
        brandId:
          getCollaborationData?.getCollaborationByUuid?.campaign?.brand?.id,
      })
    }
  }, [getCollaborationData?.getCollaborationByUuid])

  if (getCollaborationLoading) {
    return <Loader />
  }

  if (getCollaborationError || getRatingsError) {
    return null
  }

  const setPage = (page: number) => {
    setSkip(page * limit)
  }

  const collaboration = getCollaborationData?.getCollaborationByUuid
  const ratings = getRatingsData?.getRatings?.items

  return (
    <div className='pb-8'>
      <Header collaboration={collaboration} />

      <WriteReview collaboration={collaboration} />

      <BrandCard collaboration={collaboration} />
      <Divider className='py-4' />

      {getRatingsLoading && <Loader />}
      {!getRatingsLoading && (
        <ListReviews
          title='Reviews'
          subtitle={`Public reviews from other creators on Clip about ${collaboration.campaign.brand.name}`}
          ratings={ratings.map((rating) => ({
            id: `${rating.id}`,
            name: userFullname(rating.creator),
            profilePicUrl: rating.creator.avatar?.url,
            url: rating?.creator?.avatar?.url,
            stars: rating.stars,
            review: rating.review,
            createdAt: rating.createdAt,
            avatarType: 'user',
          }))}
          pagination={getRatingsData?.getRatings?.pagination}
          setPage={setPage}
        />
      )}
    </div>
  )
}

BrandDetails.defaultProps = defaultProps

export { BrandDetails }
