export enum ErrorCode {
  // generic
  authorizationError = 'authorizationError',
  notFound = 'notFound',

  // auth
  invalidEmailForLogin = 'invalidEmailForLogin',
  invalidPasswordForLogin = 'invalidPasswordForLogin',
  wrongCredentialsForLogin = 'wrongCredentialsForLogin',
  emailAlreadyTaken = 'emailAlreadyTaken',
  instagramUsernameAlreadyTaken = 'instagramUsernameAlreadyTaken',
  invalidPasswordFormat = 'invalidPasswordFormat',
  resetPasswordTokenExpired = 'resetPasswordTokenExpired',
  invalidPasswordForUpdate = 'invalidPasswordForUpdate',

  // matchmaking errors
  creatorAlreadyLikedBrand = 'creatorAlreadyLikedBrand',
  brandAlreadyLikedCreator = 'brandAlreadyLikedCreator',

  // Instagram
  instagramUsernameNotFound = 'instagramUsernameNotFound',
  tiktokUsernameNotFound = 'tiktokUsernameNotFound',

  // Others
  invalidWebsite = 'invalidWebsite',

  // Brand Team Members
  userLastDefaultContactPerson = 'userLastDefaultContactPerson',
  userAlreadyInvited = 'userAlreadyInvited',
  validateNameIsNotLink = 'validateNameIsNotLink',
}
