import { loadStripe } from '@stripe/stripe-js'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Button, ButtonColor, Loader, NotificationType } from '../../components'
import env from '../../config/env'
import {
  AgeGroups,
  Brand,
  Campaign,
  CampaignAddon,
  CountryCode,
  CreatorQuality,
  ModelType,
  VoiceType,
  Platform,
  Product,
  User,
  VideoDuration,
  VideoType,
} from '../../gql/types'
import {
  useCreateCampaignMutation,
  useUpdateCampaignMutation,
} from '../../hooks'
import { useCreateCampaignCheckout } from '../../hooks/payments'
import {
  ethnicityLocalizedStringInverse,
  genderLocalizedStringInverse,
} from '../../utils/GqlStrings'
import {
  PricingData,
  formatPriceInCents,
  getAddonsPricePerUnit,
  getCampaignPricing,
  getPriceByQuality,
  getVideoPricing,
} from '../../utils/PricingUtil'
import { UrlBuilder } from '../../utils/UrlBuilder'
import { cleanUrl } from '../../utils/helpers'
import {
  isEmptyString,
  isObjectEmpty,
  useNotification,
} from '../../utils/hooks'
import { mapFlagAndTextToCountryCode } from '../../utils/mappers'
import {
  SetAddons,
  SetCreatorDetails,
  SetInspirationVideosLinks,
  SetModelType,
  SetVoiceType,
  SetProductDetails,
  SetQuantity,
  SetRequirements,
  SetTitle,
  SetVariations,
  SetVideoDuration,
  SetVideoType,
} from './components'
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(env.STRIPE_KEY)

type CreateNewCampaignFormData = {
  title: string
  requestedProduct: Product
  videoType: VideoType
  modelType: ModelType
  voiceType: VoiceType
  videoDuration: VideoDuration
  platform: Platform
  modelGender: string
  ethnicity: string
  creatorPreferences: string
  quality: CreatorQuality
  ageGroups: (AgeGroups | 'Any')[]
  requirements: string
  countryCode: CountryCode
  hooks: string[]
  inspirationVideosLinks: string[]
  addons: CampaignAddon[]
}

interface IProps {
  user: User
  brand: Brand
  pricing: PricingData
}

const NewCampaign = ({ user, brand, pricing }: IProps) => {
  const navigate = useNavigate()
  const { addNotification } = useNotification()

  const [initiateCheckoutLoading, setInitiateCheckoutLoading] = useState(false)
  const [quantity, setQuantity] = useState(3)
  const [draftCampaign, setDraftCampaign] = useState(null)
  const [errorsNewCampaign, setErrorsNewCampaign] = useState(null)

  const { createCampaign, loading: loadingCreateCampaign } =
    useCreateCampaignMutation()
  const { updateCampaign, loading: loadingUpdateCampaign } =
    useUpdateCampaignMutation()

  const { createCampaignCheckout } = useCreateCampaignCheckout()

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    trigger,
    setError,
  } = useForm<CreateNewCampaignFormData>({
    values: {
      title: null,
      requestedProduct: null,
      videoType: null,
      modelType: null,
      voiceType: null,
      videoDuration: VideoDuration.half,
      platform: null,
      modelGender: 'Any',
      ethnicity: 'Any',
      creatorPreferences: null,
      quality: CreatorQuality.regular,
      ageGroups: ['Any'],
      requirements: null,
      countryCode: brand?.countryCode ?? null,
      hooks: [],
      inspirationVideosLinks: [],
      addons: [],
    },
  })

  const getExtraPriceCentsPremium = () => {
    return getPriceByQuality(
      CreatorQuality.premium,
      getVideoPricing({
        currency: brand?.currency,
        videoDuration: watch('videoDuration'),
        pricing,
      }).price,
    ).extraPriceCents
  }

  useEffect(() => {
    if (isObjectEmpty(errors)) {
      setErrorsNewCampaign(null)
      return
    }

    if (trigger('title')) {
      setError('title', null)
    }

    if (trigger('requestedProduct')) {
      setError('requestedProduct', null)
    }

    if (trigger('videoType')) {
      setError('videoType', null)
    }

    if (trigger('modelType')) {
      setError('modelType', null)
    }

    if (trigger('voiceType')) {
      setError('voiceType', null)
    }

    if (trigger('hooks')) {
      setError('hooks', null)
    }

    if (trigger('inspirationVideosLinks')) {
      setError('inspirationVideosLinks', null)
    }

    if (!isObjectEmpty(errors)) {
      setErrorsNewCampaign(
        'Please check to ensure all required fields are filled out.',
      )
      return
    }

    if (isObjectEmpty(errors)) {
      setErrorsNewCampaign(null)
      return
    }
  }, [
    watch('title'),
    watch('requestedProduct'),
    watch('videoType'),
    watch('modelType'),
    watch('voiceType'),
    JSON.stringify(watch('hooks')),
    JSON.stringify(watch('inspirationVideosLinks')),
    errors,
  ])

  const totalPriceCents = getCampaignPricing({
    currency: brand?.currency,
    duration: watch('videoDuration'),
    quantity,
    quality: watch('quality'),
    hooksCount: watch('hooks').length,
    addons: watch('addons') ? watch('addons') : [],
    pricing,
  })

  const initiateCheckout = async () => {
    setInitiateCheckoutLoading(true)

    let campaign: Campaign = null
    try {
      campaign = await saveDraft()
    } catch (error) {
      addNotification(
        `Something went wrong. Please try again or contact support at hello@useclip.com`,
        NotificationType.error,
      )
    }

    if (!campaign?.id) {
      setInitiateCheckoutLoading(false)
      return
    }

    try {
      const { data } = await createCampaignCheckout({
        variables: {
          brandId: brand.id,
          input: {
            campaignId: campaign.id,
            quantity,
            successRedirectUrlPath: UrlBuilder.buildCampaignsUrl(brand.id),
            cancelRedirectUrlPath: UrlBuilder.buildCampaignEditUrl(
              brand.id,
              campaign.id,
            ),
          },
        },
      })

      // if not present, campaign created with credits
      if (!data.createCampaignCheckout.stripeSessionId) {
        navigate(UrlBuilder.buildCampaignsUrl(brand.id))
        addNotification('Campaign launched.', NotificationType.success)
      }

      // if stripe session id present - redirect to stripe
      if (data.createCampaignCheckout.stripeSessionId) {
        const stripe = await stripePromise

        // When the customer clicks on the button, redirect them to Checkout.
        const result = await stripe.redirectToCheckout({
          sessionId: data.createCampaignCheckout.stripeSessionId,
        })

        if (result.error) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
          addNotification(
            `Something went wrong. Please try again or contact support at hello@useclip.com`,
            NotificationType.error,
          )
        }
      }
    } catch (error) {
      addNotification(
        `Something went wrong. Please try again or contact support at hello@useclip.com`,
        NotificationType.error,
      )
    }

    setInitiateCheckoutLoading(false)
  }

  const saveDraft = async () => {
    const isFormValidated = await trigger([
      'title',
      'requestedProduct',
      'videoType',
      'modelType',
      'voiceType',
      'videoDuration',
      'modelGender',
      'ethnicity',
      'creatorPreferences',
      'ageGroups',
      'requirements',
      'countryCode',
      'inspirationVideosLinks',
      'hooks',
    ])

    if (!isFormValidated) {
      setErrorsNewCampaign(
        'Please check to ensure all required fields are filled out.',
      )

      return false
    }

    setErrorsNewCampaign(null)

    let ageGroups = null
    if (!watch('ageGroups').find((el) => el === 'Any')) {
      ageGroups = watch('ageGroups')
    }

    try {
      let updatedCampaign
      if (!draftCampaign) {
        const { data } = await createCampaign({
          variables: {
            brandId: brand.id,
            input: {
              title: watch('title'),
              productId: watch('requestedProduct')?.id,
              videoType: watch('videoType'),
              modelType: watch('modelType'),
              voiceType: watch('voiceType'),
              videoDuration: watch('videoDuration'),
              modelGender: genderLocalizedStringInverse(watch('modelGender')),
              ethnicity: ethnicityLocalizedStringInverse(watch('ethnicity')),
              creatorPreferences: watch('creatorPreferences'),
              quality: watch('quality'),
              ageGroups: ageGroups,
              requirements: watch('requirements'),
              countryCode: mapFlagAndTextToCountryCode(watch('countryCode')),
              hooks: watch('hooks').filter((hook) => !isEmptyString(hook)),
              inspirationVideosLinks: watch('inspirationVideosLinks')
                .filter(
                  (inspirationVideosLink) =>
                    !isEmptyString(inspirationVideosLink),
                )
                .map((inspirationVideosLink) =>
                  cleanUrl(inspirationVideosLink),
                ),
              addons: watch('addons') ? watch('addons') : [],
            },
          },
        })

        updatedCampaign = data.createCampaign
        setDraftCampaign(updatedCampaign)
      }

      if (draftCampaign) {
        const { data } = await updateCampaign({
          variables: {
            id: draftCampaign.id,
            input: {
              title: watch('title'),
              productId: watch('requestedProduct').id,
              videoType: watch('videoType'),
              modelType: watch('modelType'),
              voiceType: watch('voiceType'),
              videoDuration: watch('videoDuration'),
              modelGender: genderLocalizedStringInverse(watch('modelGender')),
              ethnicity: ethnicityLocalizedStringInverse(watch('ethnicity')),
              creatorPreferences: watch('creatorPreferences'),
              quality: watch('quality'),
              ageGroups: ageGroups,
              requirements: watch('requirements'),
              countryCode: mapFlagAndTextToCountryCode(watch('countryCode')),
              hooks: watch('hooks').filter((hook) => !isEmptyString(hook)),
              inspirationVideosLinks: watch('inspirationVideosLinks')
                .filter(
                  (inspirationVideosLink) =>
                    !isEmptyString(inspirationVideosLink),
                )
                .map((inspirationVideosLink) =>
                  cleanUrl(inspirationVideosLink),
                ),
              addons: watch('addons'),
            },
          },
        })

        updatedCampaign = data.updateCampaign
        setDraftCampaign(updatedCampaign)
      }

      addNotification(`Campaign saved as draft.`, NotificationType.success)

      return updatedCampaign
    } catch (error) {
      console.log('@error Edit saveDraft', error.message)
      addNotification(error.message, NotificationType.error)
    }
  }

  if (!brand) {
    return <Loader />
  }

  return (
    <div className='pb-8'>
      <div className='sm:flex-auto py-4 -mx-4 sm:mx-0'>
        <div className='flex flex-row'>
          <h1 className='font-bold text-3xl font-outfit'>Create Campaign</h1>
        </div>
      </div>
      <div className='hidden sm:block' aria-hidden='true'>
        <div className='pb-6 border-t border-gray-200' />
      </div>
      <div className='-mx-8 xs:-mt-8 xs:-mx-8'>
        <div className='flex flex-1 flex-col'>
          <div className='sm:pt-6'>
            <form>
              <div className='mx-auto px-4 sm:px-6 md:px-8'>
                <SetTitle errors={errors} register={register} />
                <SetProductDetails
                  brand={brand}
                  control={control}
                  errors={errors}
                />
                <SetVideoType control={control} errors={errors} />
                <SetModelType control={control} errors={errors} />
                <SetVoiceType control={control} errors={errors} />
                {/* <SetPlatform control={control} errors={errors} /> */}
                <SetVideoDuration
                  control={control}
                  errors={errors}
                  currency={brand.currency}
                  quality={watch('quality')}
                  pricing={pricing}
                />
                <SetRequirements errors={errors} register={register} />
                <SetAddons
                  control={control}
                  errors={errors}
                  register={register}
                  watch={watch}
                  currency={brand.currency}
                  pricing={pricing}
                />
                <SetVariations
                  errors={errors}
                  register={register}
                  watch={watch}
                  control={control}
                  videoPricing={getVideoPricing({
                    currency: brand?.currency,
                    videoDuration: watch('videoDuration'),
                    pricing,
                  })}
                />
                <SetInspirationVideosLinks
                  errors={errors}
                  register={register}
                  watch={watch}
                  control={control}
                />
                <SetCreatorDetails
                  control={control}
                  errors={errors}
                  register={register}
                  watch={watch}
                  priceExtraFormatted={formatPriceInCents({
                    priceInCents: getExtraPriceCentsPremium(),
                    currency: brand?.currency,
                  })}
                />
                <SetQuantity
                  brand={brand}
                  quantity={quantity}
                  setQuantity={setQuantity}
                  totalPriceCents={totalPriceCents}
                  pricePerVideoCents={
                    getPriceByQuality(
                      watch('quality'),
                      getVideoPricing({
                        currency: brand?.currency,
                        videoDuration: watch('videoDuration'),
                        pricing,
                      })?.price,
                    )?.priceCents
                  }
                  pricePerVariationCents={
                    getVideoPricing({
                      currency: brand?.currency,
                      videoDuration: watch('videoDuration'),
                      pricing,
                    })?.hook?.priceCents
                  }
                  pricePerAddons={getAddonsPricePerUnit({
                    currency: brand?.currency,
                    addons: watch('addons') ? watch('addons') : [],
                    duration: watch('videoDuration'),
                    pricing,
                  })}
                  hooksCount={watch('hooks')?.length ?? 0}
                />
              </div>
            </form>
          </div>
          <div className='text-right px-4 sm:px-8'>
            <Button
              title='Save Draft'
              type='button'
              colour={ButtonColor.lightGray}
              className='mr-3'
              loading={loadingCreateCampaign || loadingUpdateCampaign}
              disabled={loadingCreateCampaign || loadingUpdateCampaign}
              onClick={saveDraft}
            />
            <Button
              title='Complete Order'
              type='button'
              loading={
                loadingCreateCampaign ||
                loadingUpdateCampaign ||
                initiateCheckoutLoading
              }
              disabled={
                loadingCreateCampaign ||
                loadingUpdateCampaign ||
                initiateCheckoutLoading
              }
              onClick={initiateCheckout}
            />
            {errorsNewCampaign && (
              <p className='mt-2 text-sm text-red-600'>{errorsNewCampaign}</p>
            )}
          </div>
          <p className='mt-2 text-right text-sm text-gray-600 px-4 sm:px-8'>
            You'll be able to make edits to your campaign for 24 hours after you
            complete your order.
          </p>
        </div>
      </div>
    </div>
  )
}

export { NewCampaign }
