import { useQuery } from '@apollo/client'
import { FunctionComponent, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ListReviews, Loader } from '../../../components'
import {
  GET_COLLABORATION_BY_UUID,
  GET_RATINGS,
  GetCollaborationByUuidResponse,
} from '../../../gql'
import {
  Pagination,
  QueryGetCollaborationByUuidArgs,
  Rating,
  RatingType,
} from '../../../gql/types'
import { AnalyticsEvent, trackEvent } from '../../../utils/analytics'
import { Header } from '../components/Header'
import { UpdateRating } from './UpdateRating'
import { WriteReview } from './WriteReview'

interface GetRatings {
  getRatings: {
    items: Rating[]
    pagination: Pagination
  }
}

interface IDefaultProps {}

interface IProps extends Partial<IDefaultProps> {}

const defaultProps: IDefaultProps = {}

const Reviews: FunctionComponent<IProps> = () => {
  const { id, uuid } = useParams()
  const limit = 6
  const [skip, setSkip] = useState(0)
  const [reviewSubmitted, setReviewSubmitted] = useState(null)

  const {
    loading: getCollaborationLoading,
    error: getCollaborationError,
    data: getCollaborationData,
  } = useQuery<GetCollaborationByUuidResponse, QueryGetCollaborationByUuidArgs>(
    GET_COLLABORATION_BY_UUID,
    {
      variables: {
        uuid: uuid,
      },
    },
  )

  const {
    loading: getRatingsLoading,
    error: getRatingsError,
    data: getRatingsData,
  } = useQuery<GetRatings>(GET_RATINGS, {
    variables: {
      options: {
        filters: {
          creatorId: getCollaborationData?.getCollaborationByUuid?.creator?.id,
          type: RatingType.brandToCreator,
        },
        skip,
        limit,
      },
    },
    skip: !getCollaborationData?.getCollaborationByUuid?.creator?.id,
  })

  useEffect(() => {
    if (getCollaborationData?.getCollaborationByUuid) {
      trackEvent(AnalyticsEvent.viewBrandsCollaborationReviews, {
        creatorId: getCollaborationData?.getCollaborationByUuid?.creator?.id,
      })
    }
  }, [getCollaborationData?.getCollaborationByUuid])

  if (getCollaborationLoading) {
    return <Loader />
  }

  if (getCollaborationError || getRatingsError) {
    return null
  }

  const setPage = (page: number) => {
    setSkip(page * limit)
  }

  const collaboration = getCollaborationData?.getCollaborationByUuid
  const ratings = getRatingsData?.getRatings?.items

  return (
    <div className='pb-8'>
      <Header collaboration={collaboration} />

      {!reviewSubmitted && (
        <WriteReview
          collaboration={collaboration}
          setReviewSubmitted={setReviewSubmitted}
          className='mt-4'
        />
      )}

      {reviewSubmitted && (
        <UpdateRating rating={reviewSubmitted} className='mt-4' />
      )}

      {getRatingsLoading && <Loader />}
      {!getRatingsLoading && (
        <ListReviews
          title='Reviews'
          subtitle={`Public reviews from other brands on Clip about ${collaboration.creator.firstName}`}
          ratings={ratings?.map((rating) => ({
            id: `${rating.id}`,
            name: rating.brand.name,
            profilePicUrl: rating.brand.logo?.url,
            url: rating.brand.website,
            stars: rating.stars,
            review: rating.review,
            createdAt: rating.createdAt,
            avatarType: 'brand',
          }))}
          pagination={getRatingsData?.getRatings?.pagination}
          setPage={setPage}
          className='mt-4'
        />
      )}
    </div>
  )
}

Reviews.defaultProps = defaultProps

export { Reviews }
