import { gql } from '@apollo/client'
import { BrandFragment } from './brands'
import { CampaignFragment } from './campaigns'
import { PaginationFragment } from './common'
import { CreatorFragment } from './creators'
import { ProductFragment } from './products'
import { Collaboration, Creator, GetCollaborationsResponse } from './types'

export const CollaborationFragment = gql`
  fragment CollaborationFragment on Collaboration {
    id
    uuid
    status
    creatorPaymentStatus
    creatorApplicationMessage
    applicationMessageCheckedStatus
    productTrackingLink
    creatorEarningsCents
    creatorEarningsCurrency
    createdAt
    updatedAt
    creator {
      ...CreatorFragment
    }
    campaign {
      ...CampaignFragment
    }
    editsRequestMessage
    hooksCount

    firstVersionClip {
      id
    }
    editedVersionClip {
      id
    }

    hooks {
      description
      creatorPaymentStatus
      creatorEarningsCents
      clipId
    }

    addons {
      description
      creatorPaymentStatus
      creatorEarningsCents
      clipId
    }

    totalCreatorEarningsCents
    pendingCreatorEarningsCents
    paidCreatorEarningsCents

    hooksCount
    uploadedHooksCount
    pendingUploadHooksCount

    addonsCount
    uploadedAddonsCount
    pendingUploadAddonsCount

    isFavorite
    isActive

    metadata {
      id
      creatorAcceptedAt
      productShippedAt
      productDeliveredAt
      contentUploadedAt
      contentAcceptedAt

      isCreatorAcceptedEmailSent
      isProductDelivered

      productShippedEmailsCount
      pendingContentEmailsToCreatorCount
      pendingContentEmailsToBrandCount
    }
  }
  ${CreatorFragment}
  ${CampaignFragment}
`

export const GET_COLLABORATIONS_BY_CREATOR_ID = gql`
  query GetCollaborationsByCreatorId(
    $creatorId: Int!
    $options: GetCollaborationsOptions!
  ) {
    getCollaborationsByCreatorId(creatorId: $creatorId, options: $options) {
      items {
        ...CollaborationFragment
        campaign {
          brand {
            ...BrandFragment
          }
          product {
            ...ProductFragment
          }
        }
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${CollaborationFragment}
  ${BrandFragment}
  ${ProductFragment}
  ${PaginationFragment}
`

export const GET_COLLABORATIONS_BY_BRAND_ID = gql`
  query GetCollaborationsByBrandId(
    $brandId: Int!
    $options: GetCollaborationsOptions!
  ) {
    getCollaborationsByBrandId(brandId: $brandId, options: $options) {
      items {
        ...CollaborationFragment
        campaign {
          brand {
            ...BrandFragment
          }
          product {
            ...ProductFragment
          }
        }
        creator {
          ...CreatorFragment
        }
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${CollaborationFragment}
  ${BrandFragment}
  ${CreatorFragment}
  ${ProductFragment}
  ${PaginationFragment}
`

export const GET_COLLABORATIONS_BY_BRAND_CREATOR = gql`
  query GetCollaborationsByBrandAndCreator(
    $creatorId: Int!
    $brandId: Int!
    $options: GetCollaborationsOptions!
  ) {
    getCollaborationsByBrandAndCreator(
      creatorId: $creatorId
      brandId: $brandId
      options: $options
    ) {
      items {
        ...CollaborationFragment
        creator {
          ...CreatorFragment
        }
        campaign {
          brand {
            ...BrandFragment
          }
          product {
            ...ProductFragment
          }
        }
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${CollaborationFragment}
  ${BrandFragment}
  ${CreatorFragment}
  ${ProductFragment}
  ${PaginationFragment}
`

export const APPLY_TO_CAMPAIGN = gql`
  mutation ApplyToCampaign(
    $creatorId: Int!
    $campaignId: Int!
    $input: ApplyToCampaignInput!
  ) {
    applyToCampaign(
      creatorId: $creatorId
      campaignId: $campaignId
      input: $input
    ) {
      ...CollaborationFragment
    }
  }
  ${CollaborationFragment}
`

export const ACCEPT_COLLABORATION = gql`
  mutation AcceptCollaboration($collaborationId: Int!) {
    acceptCollaboration(collaborationId: $collaborationId) {
      ...CollaborationFragment
    }
  }
  ${CollaborationFragment}
`

export const SHIP_PRODUCT = gql`
  mutation shipProduct($collaborationId: Int!, $input: ShipProductInput!) {
    shipProduct(collaborationId: $collaborationId, input: $input) {
      ...CollaborationFragment
    }
  }
  ${CollaborationFragment}
`

export const ACCEPT_CONTENT = gql`
  mutation acceptContent($collaborationId: Int!) {
    acceptContent(collaborationId: $collaborationId) {
      ...CollaborationFragment
    }
  }
  ${CollaborationFragment}
`

export const UPDATE_PRODUCT_TRACKING_LINK = gql`
  mutation updateProductTrackingLink(
    $collaborationId: Int!
    $input: UpdateProductTrackingLinkInput!
  ) {
    updateProductTrackingLink(
      collaborationId: $collaborationId
      input: $input
    ) {
      ...CollaborationFragment
    }
  }
  ${CollaborationFragment}
`

export const GET_COLLABORATIONS_BY_CAMPAIGN_ID = gql`
  query GetCollaborationsByCampaignId(
    $campaignId: Int!
    $options: GetCollaborationsOptions!
  ) {
    getCollaborationsByCampaignId(campaignId: $campaignId, options: $options) {
      items {
        ...CollaborationFragment
        creator {
          ...CreatorFragment
        }
        campaign {
          id
          title
          status
          videoType
          modelType
          platform
          modelGender
          creatorPreferences
          ageGroups
          createdAt
          updatedAt
          brand {
            id
            logo {
              url
              name
            }
          }
        }
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${CollaborationFragment}
  ${PaginationFragment}
  ${CreatorFragment}
`

export const GET_COLLABORATION_BY_UUID = gql`
  query getCollaborationByUuid($uuid: String!) {
    getCollaborationByUuid(uuid: $uuid) {
      ...CollaborationFragment
      creator {
        ...CreatorFragment
      }
      campaign {
        ...CampaignFragment
        product {
          ...ProductFragment
        }
        brand {
          ...BrandFragment
        }
      }
    }
  }
  ${CollaborationFragment}
  ${CreatorFragment}
  ${BrandFragment}
  ${CampaignFragment}
  ${ProductFragment}
`

export const CREATE_EDITS_REQUEST_MESSAGES = gql`
  mutation CreateEditsRequestMessage(
    $collaborationId: Int!
    $input: CreateEditsRequestMessageInput!
  ) {
    createEditsRequestMessage(
      collaborationId: $collaborationId
      input: $input
    ) {
      ...CollaborationFragment
    }
  }
  ${CollaborationFragment}
`

export const UPDATE_COLLABORATION_HOOK = gql`
  mutation UpdateCollaborationHook(
    $id: Int!
    $input: UpdateCollaborationHookInput!
  ) {
    updateCollaborationHook(id: $id, input: $input) {
      id
      description
      collaboration {
        id
      }
      clip {
        id
      }
    }
  }
`

export const DELETE_COLLABORATION_BY_CREATOR = gql`
  mutation DeleteCollaborationByCreator(
    $collaborationId: Int!
    $creatorId: Int!
  ) {
    deleteCollaborationByCreator(
      collaborationId: $collaborationId
      creatorId: $creatorId
    )
  }
`

export const MARK_APPLICANT_AS_FAVORITE = gql`
  mutation MarkApplicantAsFavorite($collaborationId: Int!) {
    markApplicantAsFavorite(collaborationId: $collaborationId) {
      ...CollaborationFragment
    }
  }
  ${CollaborationFragment}
`

export const REMOVE_APPLICANT_AS_FAVORITE = gql`
  mutation RemoveApplicantAsFavorite($collaborationId: Int!) {
    removeApplicantAsFavorite(collaborationId: $collaborationId) {
      ...CollaborationFragment
    }
  }
  ${CollaborationFragment}
`

export interface GetCollaborationByUuidResponse {
  getCollaborationByUuid: Collaboration
}

export interface AcceptCollaborationResponse {
  acceptCollaboration: Collaboration
}

export interface GetCollaborationsByCampaignIdResponse {
  getCollaborationsByCampaignId: GetCollaborationsResponse
}

export interface GetCollaborationsByCreatorIdResponse {
  getCollaborationsByCreatorId: GetCollaborationsResponse
}

export interface GetCollaborationsByBrandAndCreator {
  getCollaborationsByBrandAndCreator: GetCollaborationsResponse
}

export interface MutationAcceptContentResponse {
  acceptContent: Collaboration
}
