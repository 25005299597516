import axios from 'axios'
import React, { useState } from 'react'
import { Icon, IconType } from '../Icon'
import { Loader, LoaderType } from '../Loader'

interface Props {
  fileUrl: string
  fileName: string
}

const createTemporaryLinkAndClick = ({
  responseData,
  fileName,
}: {
  responseData: Blob
  fileName: string
}) => {
  const url = window.URL.createObjectURL(new Blob([responseData]))
  const link = document.createElement('a')

  link.href = url
  link.setAttribute('download', `${fileName}`)

  document.body.appendChild(link)

  link.click()
  link.parentNode.removeChild(link)
}

export const DownloadFileButton: React.FC<Props> = ({ fileUrl, fileName }) => {
  const [isDownloading, setIsDownloading] = useState<boolean>(false)

  const downloadContent = async () => {
    try {
      setIsDownloading(true)

      const response = await axios.get(fileUrl, {
        responseType: 'blob', // handle binary data
      })

      createTemporaryLinkAndClick({
        responseData: response.data,
        fileName: fileName,
      })

      setIsDownloading(false)
    } catch (error) {
      console.error('Download Error:', error)
    }
  }

  if (isDownloading) {
    return (
      <div className='flex items-center text-gray-400 hover:text-gray-600 focus:outline-none rounded-full bg-gray-100 p-1 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-200'>
        <Loader type={LoaderType.button} className='mr-0 !h-5 !w-5' />
      </div>
    )
  }

  return (
    <button
      onClick={downloadContent}
      className='flex items-center text-gray-400 hover:text-gray-600 focus:outline-none rounded-full bg-gray-100 p-1 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-200'
    >
      <Icon type={IconType.download} className='h-5 w-5 text-primary' />
    </button>
  )
}
