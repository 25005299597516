import { useQuery } from '@apollo/client'
import { useContext } from 'react'
import {
  GET_LATEST_CREATED_CAMPAIGN_BY_BRAND_ID,
  GET_LATEST_CREATED_PRODUCT_BY_BRAND_ID,
  GetLatestCreatedCampaignByBrandIdResponse,
  GetLatestCreatedProductByBrandId,
} from '../gql'
import {
  Campaign,
  CreatorQuality,
  Product,
  QueryGetLatestCreatedCampaignByBrandIdArgs,
  QueryGetLatestCreatedProductByBrandIdArgs,
} from '../gql/types'
import { UiContext } from './UiContext'

export const useNotification = () => {
  const { notification, addNotification, removeNotification } =
    useContext(UiContext)

  return { notification, addNotification, removeNotification }
}

export const useLatestCampaign = (
  brandId: number,
): {
  loading: boolean
  campaign: Campaign
} => {
  const { data, loading } = useQuery<
    GetLatestCreatedCampaignByBrandIdResponse,
    QueryGetLatestCreatedCampaignByBrandIdArgs
  >(GET_LATEST_CREATED_CAMPAIGN_BY_BRAND_ID, {
    fetchPolicy: 'network-only',
    variables: {
      brandId,
    },
  })

  return {
    loading,
    campaign: data?.getLatestCreatedCampaignByBrandId ?? null,
  }
}

export const useLatestProduct = (
  brandId: number,
): {
  loading: boolean
  product: Product
} => {
  const { data, loading } = useQuery<
    GetLatestCreatedProductByBrandId,
    QueryGetLatestCreatedProductByBrandIdArgs
  >(GET_LATEST_CREATED_PRODUCT_BY_BRAND_ID, {
    fetchPolicy: 'network-only',
    variables: {
      brandId,
    },
  })

  return {
    loading,
    product: data?.getLatestCreatedProductByBrandId ?? null,
  }
}

export const isCampaignPremium = (campaign: Campaign) => {
  if (campaign.quality === CreatorQuality.premium) {
    return true
  }

  return false
}

export const isCampaignRegular = (campaign: Campaign) => {
  if (campaign.quality === CreatorQuality.regular) {
    return true
  }

  return false
}

export const isObjectEmpty = (objectName) => {
  return (
    objectName &&
    Object.keys(objectName).length === 0 &&
    objectName.constructor === Object
  )
}

export const isEmptyString = (str) => {
  return (str?.trim() ?? '') === ''
}
