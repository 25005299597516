import classnames from 'classnames'
import { Link } from 'react-router-dom'
import {
  Button,
  ButtonColor,
  ButtonStyle,
  Icon,
  IconType,
  NotificationType,
  StarRating,
} from '../../../components'
import { CollaborationStatus } from '../../../components/CollaborationStatus'
import { Avatar } from '../../../elements'
import {
  Collaboration,
  MutationMarkApplicantAsFavoriteArgs,
  MutationRemoveApplicantAsFavoriteArgs,
} from '../../../gql/types'
import { BrandRoutes } from '../../BrandRoutes'
import { useMutation } from '@apollo/client'
import {
  MARK_APPLICANT_AS_FAVORITE,
  REMOVE_APPLICANT_AS_FAVORITE,
} from '../../../gql'
import { useNotification } from '../../../utils/hooks'

interface IProps {
  collaboration: Collaboration
  setSelectedCollaboration(collaboration: Collaboration): void
  setIsCreatorSliderOpen(isSliderOpen: boolean): void
  className?: string
}

const ApplicantRow = ({
  collaboration,
  setSelectedCollaboration,
  setIsCreatorSliderOpen,
  className,
}: IProps) => {
  const { addNotification } = useNotification()

  const [markApplicantAsFavorite, { loading: markApplicantAsFavoriteLoading }] =
    useMutation<Collaboration, MutationMarkApplicantAsFavoriteArgs>(
      MARK_APPLICANT_AS_FAVORITE,
    )
  const [
    removeApplicantAsFavorite,
    { loading: removeApplicantAsFavoriteLoading },
  ] = useMutation<Collaboration, MutationRemoveApplicantAsFavoriteArgs>(
    REMOVE_APPLICANT_AS_FAVORITE,
  )

  const onMarkApplicantAsFavorite = async () => {
    try {
      await markApplicantAsFavorite({
        variables: {
          collaborationId: collaboration.id,
        },
      })

      addNotification(
        'Applicant has been marked as a favorite.',
        NotificationType.success,
      )
    } catch (error) {
      console.log(error)
      addNotification(
        'There has been an error marking this applicant as favorite',
        NotificationType.error,
      )
    }
  }

  const onRemoveApplicantAsFavorite = async () => {
    try {
      await removeApplicantAsFavorite({
        variables: {
          collaborationId: collaboration.id,
        },
      })

      addNotification(
        'Applicant has been removed from favorites.',
        NotificationType.success,
      )
    } catch (error) {
      console.log(error)
      addNotification(
        'There has been an error removing this applicant as favorite',
        NotificationType.error,
      )
    }
  }

  return (
    <tr key={collaboration.id} className={classnames(className)}>
      <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
        <Link
          to={BrandRoutes.collaborationViewProfile
            .replace(':id', `${collaboration.campaign.brand.id}`)
            .replace(':uuid', collaboration.uuid)}
          className='cursor-pointer flex gap-2 font-medium text-gray-900 hover:text-gray-800'
        >
          <div className='flex items-center'>
            <Avatar
              type='user'
              src={collaboration.creator.avatar?.url}
              size='sm'
              alt={collaboration.creator.firstName}
            />
            <div className='ml-2'>
              <div>{collaboration.creator.firstName}</div>
              <div className='truncate mb-0 flex flex-shrink-0 text-blue-600 hover:text-blue-500'>
                view profile
              </div>
            </div>
          </div>
        </Link>
      </td>

      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        <CollaborationStatus status={collaboration.status} />
      </td>

      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        <Link
          to={BrandRoutes.collaborationViewReviews
            .replace(':id', `${collaboration.campaign.brand.id}`)
            .replace(':uuid', collaboration.uuid)}
          className='cursor-pointer flex gap-2'
        >
          <StarRating
            ratingScore={collaboration?.creator?.metadata?.ratingsScore}
            ratingCount={collaboration?.creator?.metadata?.ratingsCount}
          />
        </Link>
      </td>

      <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 space-x-2'>
        {/* <td className='text-right sm:pr-6 space-x-2 mt-4 lg:mt-0'> */}
        {collaboration.isFavorite && (
          <Button
            type='button'
            styleType={ButtonStyle.ghost}
            onClick={onRemoveApplicantAsFavorite}
            loading={removeApplicantAsFavoriteLoading}
            disabled={removeApplicantAsFavoriteLoading}
            icon={
              <Icon
                type={IconType.heartFilled}
                className='h-4 w-4 text-red-500'
                aria-hidden='true'
              />
            }
            iconPosition='left'
            tooltip='Remove applicant as favorite'
          />
        )}
        {!collaboration.isFavorite && (
          <Button
            type='button'
            styleType={ButtonStyle.ghost}
            onClick={onMarkApplicantAsFavorite}
            loading={markApplicantAsFavoriteLoading}
            disabled={markApplicantAsFavoriteLoading}
            icon={
              <Icon
                type={IconType.heartFilled}
                className='h-4 w-4 text-gray-400'
                aria-hidden='true'
              />
            }
            iconPosition='left'
            tooltip='Mark applicant as favorite'
          />
        )}
        <Button
          title='Quick view'
          type='button'
          colour={ButtonColor.primary}
          onClick={() => {
            setSelectedCollaboration(collaboration)
            setIsCreatorSliderOpen(true)
          }}
        />
        {/* <Button
        title='View profile'
        type='button'
        colour={ButtonColor.primary}
        to={BrandRoutes.collaborationViewProfile
          .replace(':id', `${collaboration.campaign.brand.id}`)
          .replace(':uuid', collaboration.uuid)}
      /> */}
      </td>
    </tr>
  )
}

export { ApplicantRow }
