import { useMutation } from '@apollo/client'
import { useState } from 'react'
import {
  Button,
  ButtonColor,
  ButtonStyle,
  NotificationType,
  OrderClipModal,
} from '../../../components'
import {
  ACCEPT_COLLABORATION,
  AcceptCollaborationResponse,
  GET_COLLABORATIONS_BY_CAMPAIGN_ID,
} from '../../../gql'
import {
  Collaboration,
  CollaborationStatus,
  GetCollaborationsSort,
  MutationAcceptCollaborationArgs,
  ProductType,
} from '../../../gql/types'
import { useNotification } from '../../../utils/hooks'

interface IProps {
  collaboration: Collaboration
  onCollaborationAccepted?: () => void
}

const AcceptApplicantButton = ({
  collaboration,
  onCollaborationAccepted,
}: IProps) => {
  const { addNotification } = useNotification()
  const [isOrderClipOpen, setIsOrderClipOpen] = useState(false)

  const [acceptCollaboration, { loading }] = useMutation<
    AcceptCollaborationResponse,
    MutationAcceptCollaborationArgs
  >(ACCEPT_COLLABORATION, {
    refetchQueries: [
      {
        query: GET_COLLABORATIONS_BY_CAMPAIGN_ID,
        variables: {
          campaignId: collaboration?.campaign?.id,
          options: {
            filters: {
              status: [
                CollaborationStatus.creatorAccepted,
                CollaborationStatus.productShipped,
                CollaborationStatus.productDelivered,
              ],
            },
            sort: GetCollaborationsSort.lastUpdated,
            skip: 0,
            limit: 20,
          },
        },
      },
    ],
  })

  const isCollaboratorsLimitReachedError = (error: any) => {
    const graphqlErrors = error?.graphQLErrors as any

    if (!graphqlErrors) {
      return false
    }

    return graphqlErrors.some(
      (graphqlError) =>
        graphqlError?.code === 'campaignCollaboratorsLimitReached',
    )
  }

  const onAcceptCollaboration = async () => {
    if (!collaboration) {
      return
    }

    try {
      await acceptCollaboration({
        variables: {
          collaborationId: collaboration.id,
        },
      })

      if (onCollaborationAccepted) {
        onCollaborationAccepted()
      }

      addNotification(
        `Applicant approved. ${
          collaboration.campaign.product?.type === ProductType.physical
            ? `It's time to ship the product.`
            : ''
        }`,
        NotificationType.success,
      )
    } catch (error) {
      console.log('error applyToCampaign', error.message)

      if (isCollaboratorsLimitReachedError(error)) {
        setIsOrderClipOpen(true)
      } else if (error.message) {
        addNotification(error.message, NotificationType.error)
      }
    }
  }

  if (collaboration.status !== CollaborationStatus.creatorApplied) {
    return null
  }

  return (
    <>
      {collaboration && isOrderClipOpen && (
        <OrderClipModal
          campaign={collaboration.campaign}
          title={`You've used up your paid clips`}
          description={`To approve more creators and get more clips, select the number of additional videos you would like and hit 'Confirm your order' below.`}
          open={isOrderClipOpen}
          setOpen={setIsOrderClipOpen}
        />
      )}

      <div className='mt-4 flex flex-wrap font-bold'>
        <Button
          title='Approve'
          type='button'
          loading={loading}
          styleType={ButtonStyle.fill}
          colour={ButtonColor.primary}
          className='flex-1 font-bold'
          onClick={() => onAcceptCollaboration()}
        />
      </div>
    </>
  )
}

export { AcceptApplicantButton }
