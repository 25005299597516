import { useMutation } from '@apollo/client'
import { Dialog, Transition } from '@headlessui/react'
import { loadStripe } from '@stripe/stripe-js'
import classNames from 'classnames'
import React, { Fragment, useRef, useState } from 'react'
import {
  Badge,
  BadgeSize,
  BadgeType,
  Button,
  ButtonStyle,
  Icon,
  IconType,
  NotificationType,
  TooltipInfo,
} from '../../../components'
import env from '../../../config/env'
import { Campaign, CampaignStatus, CountryCode } from '../../../gql/types'
import {
  formatPriceInCents,
  getAddonsPricePerUnit,
  getPriceByQuality,
  getVideoPricing,
} from '../../../utils/PricingUtil'
import { isCampaignPremium, useNotification } from '../../../utils/hooks'
import { usePricing } from '../../../hooks'
import { mapVideoDurationToSeconds } from '../../../utils/mappers'
import { BrandRoutes } from '../../BrandRoutes'
import { useCreateCampaignCheckout } from '../../../hooks/payments'
import { UrlBuilder } from '../../../utils/UrlBuilder'
import { useNavigate } from 'react-router-dom'

const stripePromise = loadStripe(env.STRIPE_KEY)

interface IProps {
  campaign: Campaign
  open: boolean
  setOpen: (event: any) => void
  description: string
  title: string
}

export const OrderClipModal: React.FC<IProps> = ({
  campaign,
  open,
  setOpen,
  title,
  description,
}) => {
  const { brand } = campaign
  const { addNotification } = useNotification()
  const navigate = useNavigate()
  const [initiateCheckoutLoading, setInitiateCheckoutLoading] = useState(false)
  const [quantity, setQuantity] = useState(3)
  const [creditPayment, setCreditPayment] = useState(false)
  const { pricing } = usePricing()
  const durationInSeconds = mapVideoDurationToSeconds(campaign.videoDuration)
  // const videoPricing = getVideoPricing({
  //   currency: campaign.currency,
  //   videoDuration: campaign.videoDuration,
  //   pricing,
  // })

  const { createCampaignCheckout } = useCreateCampaignCheckout()

  const initiateCheckout = async () => {
    setInitiateCheckoutLoading(true)

    try {
      const { data } = await createCampaignCheckout({
        variables: {
          brandId: brand.id,
          input: {
            campaignId: campaign.id,
            quantity,
            successRedirectUrlPath: UrlBuilder.buildCampaignsUrl(
              brand.id,
              `?orderedVideoQuantity=${quantity}`,
            ),
            cancelRedirectUrlPath: UrlBuilder.buildCampaignsUrl(brand.id),
          },
        },
      })

      // if no stripe session it means the purchase was made with credits
      if (!data.createCampaignCheckout.stripeSessionId) {
        addNotification(
          `${quantity} extra clips added to your campaign. Your campaign will now display to more creators and you can approve more applicants.`,
          NotificationType.success,
        )
      }

      // if stripe session id present - redirect to stripe
      if (data.createCampaignCheckout.stripeSessionId) {
        const stripe = await stripePromise

        // When the customer clicks on the button, redirect them to Checkout.
        const result = await stripe.redirectToCheckout({
          sessionId: data.createCampaignCheckout.stripeSessionId,
        })

        if (result.error) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
          addNotification(
            `Something went wrong. Please try again or contact support at hello@useclip.com.`,
            NotificationType.error,
          )
        }
      }

      setOpen(false)
    } catch (error) {
      addNotification(
        `Something went wrong. Please try again or contact support at hello@useclip.com.`,
        NotificationType.error,
      )
    }

    setInitiateCheckoutLoading(false)
  }

  const cancelButtonRef = useRef(null)

  const renderTotalPrice = () => {
    // const totalPriceCents =
    //   quantity *
    //   getPriceByQuality(campaign?.quality, videoPricing?.price)?.priceCents

    const formattedPrice = formatPriceInCents(
      {
        currency: campaign.currency,
        priceInCents: quantity * campaign.videoPricePerUnitCents,
      },
      { minimumFractionDigits: 0 },
    )
    return (
      <div className='border ml-1.5 rounded-lg shadow-sm px-2 flex justify-start items-center'>
        <Icon
          type={IconType.clock}
          className='w-5 h-5 text-gray-600'
          aria-hidden='true'
        />
        <div className='flex flex-col text-sm'>
          <span className='font-medium text-gray-900'>
            {durationInSeconds} seconds
          </span>
          <span className='font-medium text-gray-500'>
            {formattedPrice}
            {brand.countryCode === CountryCode.GB && <span>{` `}+ VAT</span>}
            {/* {isCampaignPremium(campaign) && (
              <Badge
                type={BadgeType.amber}
                text={`premium`}
                size={BadgeSize.small}
              />
            )} */}
          </span>
        </div>
      </div>
    )
  }

  const renderHooksPrice = () => {
    // const hooksPricePerVideoCents =
    //   campaign.hooks?.length * videoPricing.hook.priceCents
    // const totalHooksPriceCents = quantity * hooksPricePerVideoCents

    const formattedPrice = formatPriceInCents(
      {
        currency: campaign.currency,
        priceInCents:
          quantity * campaign.hooksCount * campaign.hookPricePerUnitCents,
      },
      { minimumFractionDigits: 0 },
    )
    return (
      <div className='border ml-1.5 rounded-lg shadow-sm px-3 flex justify-start items-center pt-1'>
        <div className='flex flex-col text-sm'>
          <Badge
            type={BadgeType.indigo}
            iconPosition='left'
            text={
              <TooltipInfo
                valueKey='hooks-badge'
                content={`${campaign.hooks?.length} variation${campaign.hooks.length > 1 ? 's' : ''}`}
                tooltipText='Each variation is an altered version of the original video, featuring a unique hook or call-to-action. The duration of each variation is up to 5 seconds.'
              />
            }
            size={BadgeSize.small}
            className='!mr-0'
          />
          <span className='font-medium text-gray-500'>
            {formattedPrice}
            {brand.countryCode === CountryCode.GB && <span>{` `}+ VAT</span>}
          </span>
        </div>
      </div>
    )
  }

  const renderAddonsPrice = () => {
    const addonsPricePerVideoCents = getAddonsPricePerUnit({
      addons: campaign.addons,
      currency: campaign.currency,
      duration: campaign.videoDuration,
      pricing,
    })
    const totalAddonsPriceCents = quantity * addonsPricePerVideoCents

    const formattedPrice = formatPriceInCents(
      {
        currency: campaign.currency,
        priceInCents: totalAddonsPriceCents,
      },
      { minimumFractionDigits: 0 },
    )
    return (
      <div className='border ml-1.5 rounded-lg shadow-sm px-3 flex justify-start items-center pt-1'>
        <div className='flex flex-col text-sm'>
          <Badge
            type={BadgeType.violet}
            iconPosition='left'
            text={
              <TooltipInfo
                valueKey='addons-badge'
                content={`${campaign.addons?.length} addon${campaign.addons.length > 1 ? 's' : ''}`}
                tooltipText='Each addon is an altered version of the original video with captions, music or additional elements.'
              />
            }
            size={BadgeSize.small}
            className='!mr-0'
          />
          <span className='font-medium text-gray-500'>
            {formattedPrice}
            {brand.countryCode === CountryCode.GB && <span>{` `}+ VAT</span>}
          </span>
        </div>
      </div>
    )
  }

  const renderPayWithCreditInput = () => {
    if (campaign.currency !== brand.currency) {
      return null
    }

    if (brand.creditCents <= 0) {
      return null
    }

    const formattedCredit = formatPriceInCents(
      {
        priceInCents: brand.creditCents,
        currency: campaign.currency,
      },
      { minimumFractionDigits: 0 },
    )

    return (
      <div className='relative flex items-start mt-3'>
        <div className='flex h-6 items-center'>
          <input
            id='payWithCredit'
            aria-describedby='payWithCredit-description'
            name='payWithCredit'
            type='checkbox'
            // checked={creditPayment}
            checked={true}
            disabled
            onChange={() => setCreditPayment((prev) => !prev)}
            className={classNames(
              'h-4 w-4 rounded border-gray-300 text-gray-600 disabled:accent-gray-300 focus:ring-gray-500 cursor-pointer',
              creditPayment
                ? 'focus:ring-gray-800 accent-primary-800'
                : 'accent-gray-300',
            )}
          />
        </div>
        <div className='ml-3 text-sm leading-6'>
          <label
            htmlFor='payWithCredit'
            className='font-medium text-gray-900 cursor-pointer'
          >
            Use credits
          </label>
          <label
            htmlFor='payWithCredit'
            id='payWithCredit-description'
            className='block leading-4 text-gray-500 cursor-pointer'
          >
            You have {formattedCredit} remaining credits. Credits will
            automatically be applied.
          </label>
        </div>
      </div>
    )
  }

  if (campaign.status !== CampaignStatus.active) {
    return null
  }

  // if (!videoPricing) {
  //   return null
  // }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-10'
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed z-10 inset-0 overflow-y-auto'>
          <div className='flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-xl sm:w-full'>
                <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
                  <div className='sm:flex sm:items-start'>
                    <div className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10'>
                      <div aria-hidden='true'>
                        <Icon
                          type={IconType.exclamationCircleFill}
                          className='h-5 w-5 text-red-500'
                          aria-hidden='true'
                        />
                      </div>
                    </div>
                    <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                      <Dialog.Title
                        as='h3'
                        className='text-lg leading-6 font-medium text-gray-900'
                      >
                        {title}
                      </Dialog.Title>
                      <div className='mt-2'>
                        <p className='text-sm text-gray-500'>{description}</p>
                      </div>
                      <div className='mt-4 flex flex-col justify-start'>
                        <p className='text-sm font-semibold text-500'>
                          Number of additional videos
                          {isCampaignPremium(campaign) && (
                            <Badge
                              type={BadgeType.amber}
                              text={`premium`}
                              size={BadgeSize.small}
                            />
                          )}
                        </p>
                        <div className='flex flex-col xs:flex-row mt-2'>
                          <span className='isolate inline-flex rounded-md'>
                            <button
                              type='button'
                              className='relative inline-flex items-center gap-x-1.5 rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10'
                              onClick={() =>
                                setQuantity((prev) => Math.max(prev - 1, 1))
                              }
                            >
                              <Icon
                                type={IconType.minus}
                                className='-ml-0.5 h-5 w-5 text-gray-500 text-[20px]'
                              />
                            </button>
                            <button
                              type='button'
                              className='relative -ml-px inline-flex items-center bg-white px-3 py-2 text-md font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10'
                            >
                              {quantity}
                            </button>
                            <button
                              type='button'
                              className='relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10'
                              onClick={() =>
                                setQuantity((prev) => Math.min(prev + 1, 100))
                              }
                            >
                              <Icon
                                type={IconType.plus}
                                className='-ml-0.5 h-5 w-5 text-gray-500 text-[20px]'
                              />
                            </button>
                          </span>
                          <div className='flex justify-start items-center mt-2 xs:mt-0 -ml-1.5 xs:ml-0'>
                            {renderTotalPrice()}
                          </div>
                        </div>
                        <div className='flex flex-col justify-start mt-2 -ml-1.5'>
                          <div className='flex justify-start items-center xs:mt-0'>
                            {campaign.hooks?.length > 0
                              ? renderHooksPrice()
                              : null}
                            {campaign.addons?.length > 0
                              ? renderAddonsPrice()
                              : null}
                          </div>
                        </div>
                        {renderPayWithCreditInput()}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse'>
                  <Button
                    styleType={ButtonStyle.fill}
                    title='Confirm your order'
                    type='button'
                    loading={initiateCheckoutLoading}
                    onClick={initiateCheckout}
                    className='w-full sm:w-auto'
                  />
                  <button
                    type='button'
                    className='mt-3 mr-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
