import { useState } from 'react'
import {
  Badge,
  BadgeSize,
  BadgeType,
  Button,
  ButtonColor,
  ButtonSize,
  Icon,
  TooltipInfo,
  IconType,
} from '../../../../components'
import { Campaign, Creator, ProductType } from '../../../../gql/types'
import {
  modelTypeLocalizedString,
  videoDurationLocalizedString,
  videoTypeLocalizedString,
  voiceTypeLocalizedString,
} from '../../../../utils/GqlStrings'
import { mapCurrencyToSign } from '../../../../utils/mappers'
import { ApplyToCampaignModal } from '../ApplyToCampaignModal'
import { CampaignSlider } from '../../../components/CampaignSlider'
import { Avatar } from '../../../../elements'
import { PricingData, formatPriceInCents } from '../../../../utils/PricingUtil'
import { isCampaignPremium } from '../../../../utils/hooks'
import { useLocation } from 'react-router-dom'
import { CreatorRoutes } from '../../../CreatorRoutes'
import moment from 'moment'

interface IProps {
  creator: Creator
  campaign: Campaign
  invitationId?: number | null
  pricing: PricingData
  onAppliedToCampaign: () => void
}

const CampaignCard = ({
  creator,
  campaign,
  invitationId,
  pricing,
  onAppliedToCampaign,
}: IProps) => {
  const { pathname } = useLocation()
  const [isCampaignSliderOpen, setIsCampaignSliderOpen] = useState(false)
  const [isApplyToCampaignModalOpen, setIsApplyToCampaignModalOpen] =
    useState(false)

  const getTotalCreatorEarningsText = () => {
    return formatPriceInCents({
      priceInCents: campaign.totalCreatorEarningsCents,
      currency: campaign.currency,
    })
  }

  return (
    <>
      <td className='hidden'>
        <CampaignSlider
          open={isCampaignSliderOpen}
          setOpen={setIsCampaignSliderOpen}
          campaign={campaign}
          openApplyToCampaignModal={() => setIsApplyToCampaignModalOpen(true)}
        />
        <ApplyToCampaignModal
          creator={creator}
          campaign={campaign}
          invitationId={invitationId}
          isOpen={isApplyToCampaignModalOpen}
          setIsOpen={setIsApplyToCampaignModalOpen}
          onAppliedToCampaign={onAppliedToCampaign}
        />
      </td>
      <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
        <span className='flex items-center'>
          <div
            className='p-1 mr-3 [&>*]:m-0.25 min-w-[50px] cursor-pointer'
            onClick={() => setIsCampaignSliderOpen(true)}
          >
            <Avatar
              type='product'
              src={campaign.product.photo?.url}
              size='sm'
              alt={campaign.product.name}
            />
          </div>

          <div
            onClick={() => setIsCampaignSliderOpen(true)}
            className='cursor-pointer'
          >
            <div>
              {/* <a
                href={campaign.product.link}
                className='text-blue-500'
                target='_blank'
                rel='noopener noreferrer'
              > */}
              <div className='text-ellipsis overflow-hidden w-40 md:w-60'>
                {campaign.product.name}
              </div>
              {/* <div className='mt-4'>purchased at: {moment(campaign.purchasedAt).format('DD-MM-YYYY')}</div> */}
              {/* <div className='mt-4'>boosted at: {campaign.boostedAt ? moment(campaign.boostedAt).format('DD-MM-YYYY') : 'not boosted'}</div> */}
              {/* </a> */}
            </div>

            <div className='truncate mt-1 mb-0 flex flex-shrink-0 text-gray-500'>
              {campaign.product.price === 0 &&
                campaign.product.type === ProductType.digital && (
                  <span>digital</span>
                )}
              {campaign.product.price > 0 && (
                <span>
                  worth {mapCurrencyToSign(campaign.product.currency)}{' '}
                  {campaign.product.price}
                </span>
              )}
              {isCampaignPremium(campaign) && (
                <Badge
                  type={BadgeType.amber}
                  text={`premium`}
                  size={BadgeSize.small}
                />
              )}
            </div>
          </div>
        </span>
      </td>

      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        <div>{getTotalCreatorEarningsText()} + free product</div>
        {campaign.hooks?.length > 0 && (
          <div className='mt-1 mb-0'>
            <Badge
              type={BadgeType.indigo}
              iconPosition='left'
              text={
                <TooltipInfo
                  valueKey='hooks-badge'
                  content={`${campaign.hooks?.length} variation${campaign.hooks?.length > 1 ? 's' : ''} required`}
                  tooltipText='Each variation is an altered version of the original video, featuring a unique hook or call-to-action. The duration of each variation is up to 5 seconds.'
                />
              }
              size={BadgeSize.medium}
              className='!mr-0'
            />
          </div>
        )}
        {campaign.addons?.length > 0 && (
          <div className='mt-1 mb-0'>
            <Badge
              type={BadgeType.purple}
              iconPosition='left'
              text={
                <TooltipInfo
                  valueKey='addons-badge'
                  content={`${campaign.addons?.length} addon${campaign.addons?.length > 1 ? 's' : ''} required`}
                  tooltipText='Each addon is an altered version of the original video with captions, music or additional elements.'
                />
              }
              size={BadgeSize.medium}
              className='!mr-0'
            />
          </div>
        )}
      </td>

      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        <div className='truncate mb-0 flex flex-shrink-0'>
          <Badge
            type={BadgeType.gray}
            iconPosition='left'
            text={videoDurationLocalizedString(campaign.videoDuration)}
            size={BadgeSize.medium}
          />
          {campaign.voiceType && (
            <Badge
              type={BadgeType.gray}
              iconPosition='left'
              text={voiceTypeLocalizedString(campaign.voiceType)}
              size={BadgeSize.medium}
            />
          )}
          <Badge
            type={BadgeType.gray}
            iconPosition='left'
            text={videoTypeLocalizedString(campaign.videoType)}
            size={BadgeSize.medium}
          />
          <Badge
            type={BadgeType.gray}
            iconPosition='left'
            text={modelTypeLocalizedString(campaign.modelType)}
            size={BadgeSize.medium}
          />
        </div>
      </td>

      <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 space-x-2'>
        <Button
          type='button'
          colour={ButtonColor.secondary}
          onClick={() => setIsCampaignSliderOpen(true)}
          size={ButtonSize.medium}
          icon={
            <Icon
              type={IconType.layers}
              className='h-4 w-4 text-white'
              aria-hidden='true'
            />
          }
          iconPosition='left'
          tooltip='View campaign details'
        />
        <Button
          title={
            pathname ===
            CreatorRoutes.invitations.replace(':id', `${creator.id}`)
              ? 'Apply'
              : 'Apply'
          }
          type='button'
          colour={ButtonColor.primary}
          onClick={() => setIsApplyToCampaignModalOpen(true)}
        />
      </td>
    </>
  )
}

export { CampaignCard }
