import { useMutation } from '@apollo/client'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import validator from 'validator'
import {
  Button,
  ButtonColor,
  Icon,
  IconType,
  Loader,
  NotificationType,
  RadioGroup,
  Select,
  TextArea,
  TextField,
} from '../../../components'
import {
  CREATE_PRODUCT,
  GET_PRODUCT_PHOTO_UPLOAD_URL,
  UPDATE_BRAND,
  UPDATE_PRODUCT,
} from '../../../gql'
import {
  Brand,
  BrandOnboardingStep,
  Currency,
  ProductType,
} from '../../../gql/types'
import { AnalyticsEvent, trackEvent } from '../../../utils/analytics'
import { cleanUrl, removeProtocol, truncate } from '../../../utils/helpers'
import { mapCurrencyToSign, productTypeOptions } from '../../../utils/mappers'
import { Bullets } from '../components/Bullets'
import { Steps } from '../components/Steps'
import { PhotoUploadUrl } from '../../Products/components/PhotoUploadUrl'
import { useLatestProduct, useNotification } from '../../../utils/hooks'

export type EditProductDetailsFormData = {
  name: string
  link: string
  details: string
  productType: ProductType
  price: number
  productPhoto: string
  currency: Currency
}

interface Props {
  brand: Brand
}

const SetProductDetails = ({ brand }: Props) => {
  const { addNotification } = useNotification()
  const { product, loading: loadingLatestProduct } = useLatestProduct(brand.id)

  const [selectedFile, setSelectedFile] = useState(null)

  const [updateBrand, { loading: loadingUpdateBrand }] =
    useMutation(UPDATE_BRAND)
  const [createProduct, { loading: loadingCreateProduct }] =
    useMutation(CREATE_PRODUCT)
  const [updateProduct, { loading: loadingUpdateProduct }] =
    useMutation(UPDATE_PRODUCT)

  const [getProductPhotoUploadUrl, { loading: loadingProductPhotoUploadUrl }] =
    useMutation(GET_PRODUCT_PHOTO_UPLOAD_URL)

  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors },
    watch,
    setValue,
    clearErrors,
  } = useForm({
    values: {
      name: product?.name ?? '',
      price: product?.price ?? null,
      link: product?.link ?? null,
      details: product?.details ?? '',
      productType: product?.type ?? ProductType.physical,
      currency: product?.currency ?? brand.currency,
      productPhoto: null,
    },
  })

  useEffect(() => {
    if (watch('price') === null) {
      return
    }

    if (
      watch('price') > 0 ||
      (watch('price') === 0 && watch('productType') === ProductType.digital)
    ) {
      setError('price', { type: 'custom', message: null })
      return
    }

    setError('price', {
      type: 'custom',
      message: `Please set a correct product price.`,
    })
  }, [watch('price'), watch('productType')])

  useEffect(() => {
    trackEvent(AnalyticsEvent.viewBrandsOnboardingSetProductDetails)
  }, [])

  const onProductPhotoUpload = async (file: File) => {
    if (!file) {
      return
    }

    setSelectedFile(file)
    clearErrors('productPhoto')

    try {
      const { data } = await getProductPhotoUploadUrl({
        variables: {
          brandId: brand.id,
          mime: file.type,
        },
      })

      await axios.put(data.getProductPhotoUploadUrl.uploadUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
      })

      setValue('productPhoto', data.getProductPhotoUploadUrl.fileName)
    } catch (error) {
      console.log('error onProductPhotoUpload', error.message)
      addNotification(error.message, NotificationType.error)
    }
  }

  const goBack = async () => {
    try {
      await updateBrand({
        variables: {
          id: brand.id,
          input: {
            onboarding: BrandOnboardingStep.setWebsite,
          },
        },
      })
    } catch (error) {
      addNotification(error.message, NotificationType.error)
    }
  }

  const onSubmit = async (data: EditProductDetailsFormData) => {
    const price = truncate(data.price, 5)
    const link = data.link && data.link !== '' ? cleanUrl(data.link) : null

    try {
      if (!product) {
        await createProduct({
          variables: {
            brandId: brand.id,
            input: {
              name: data.name,
              link,
              details: data.details,
              type: data.productType,
              price,
              currency: data.currency ?? brand.currency,
              photoFileName: data.productPhoto,
            },
          },
        })
      }

      if (product) {
        await updateProduct({
          variables: {
            id: product.id,
            input: {
              name: data.name,
              link,
              details: data.details,
              type: data.productType,
              price,
              currency: data.currency ?? brand.currency,
              photoFileName: data.productPhoto ?? undefined,
            },
          },
        })
      }

      await updateBrand({
        variables: {
          id: brand.id,
          input: {
            onboarding: BrandOnboardingStep.setCampaignDetails,
          },
        },
      })
    } catch (error) {
      console.log('@error SetProductDetails', error.message)
      addNotification(error.message, NotificationType.error)
    }
  }

  if (loadingLatestProduct) {
    return <Loader />
  }

  return (
    <div className='min-h-screen flex flex-col pb-14'>
      <div className='flex-grow'>
        <Steps
          brand={brand}
          stepCurrent={BrandOnboardingStep.setProductDetails}
        />

        <div className='min-h-full flex flex-col justify-center pt-4 px-4 md:mt-8 lg:px-8'>
          <div className='sm:mx-auto sm:w-full sm:max-w-2xl'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='md:shadow rounded-t-md sm:overflow-hidden'>
                <div className='py-5 md:bg-white space-y-6 sm:p-6'>
                  <div className='sm:mx-auto'>
                    <h2 className='mt-0 md:text-center text-3xl font-extrabold text-gray-900'>
                      Product details
                    </h2>
                    <p className='mt-2 md:text-center text-sm text-gray-600'>
                      What's the product that you want to promote?
                    </p>
                  </div>

                  <div className='grid gap-6'>
                    <div className='col-span-3 sm:col-span-2'>
                      <TextField
                        error={errors.name?.message}
                        label='Product name'
                        placeholder='Example: Body Scrub'
                        {...register('name', {
                          required: `Please set a product name.`,
                          maxLength: {
                            value: 100,
                            message: `Your product name cannot have more than 100 characters.`,
                          },
                        })}
                      />
                    </div>
                  </div>

                  <div className='grid gap-6'>
                    <div className='col-span-3 sm:col-span-2'>
                      <PhotoUploadUrl
                        photoUpload={
                          selectedFile
                            ? URL.createObjectURL(selectedFile)
                            : product?.photo?.url ?? null
                        }
                        title='Product photo (optional)'
                        onChange={onProductPhotoUpload}
                      />
                      <p className='mt-1 text-sm font-normal leading-5 text-gray-500 font-sans'>
                        Please use a square image.
                      </p>
                      {/* <div
                        className='relative mt-2 text-sm text-red-600'
                        id='errorProductPhoto'
                      >
                        {errors.productPhoto?.message && (
                          <div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
                            <Icon
                              type={IconType.exclamationCircleFill}
                              className='h-5 w-5 text-red-500'
                              aria-hidden='true'
                            />
                          </div>
                        )}
                      </div> */}
                      {/* {errors.productPhoto?.message && (
                        <p
                          className='mt-2 text-sm text-red-600'
                          id='errorProductPhoto'
                        >
                          Please set a product photo.
                        </p>
                      )} */}
                    </div>
                  </div>

                  <div className='grid gap-6'>
                    <div className='col-span-3 sm:col-span-2'>
                      <TextField
                        error={errors.price?.message}
                        label='Retail price'
                        placeholder='0.00'
                        className='pl-7'
                        leftElement={
                          <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
                            <span className='text-gray-600 sm:text-sm'>
                              {mapCurrencyToSign(
                                watch('currency') ?? brand.currency,
                              )}
                            </span>
                          </div>
                        }
                        rightElement={
                          <div className='absolute inset-y-0 right-0 flex items-center'>
                            <Controller
                              name='currency'
                              control={control}
                              render={({ field }) => (
                                <Select
                                  options={Object.keys(Currency).map(
                                    (currency) =>
                                      (currency as Currency).toUpperCase(),
                                  )}
                                  field={field}
                                  className='-mt-1 w-16 rounded-l-none'
                                />
                              )}
                            />
                          </div>
                        }
                        {...register('price', {
                          required: `Please set the retail price of your product.`,
                          maxLength: {
                            value: 100,
                            message: `Product price too long.`,
                          },
                          validate: (value) => {
                            if (
                              !value &&
                              watch('productType') === ProductType.digital
                            ) {
                              return true
                            }

                            if (
                              value == 0 &&
                              watch('productType') === ProductType.digital
                            ) {
                              return true
                            }

                            if (value > 0) {
                              return true
                            }

                            return `Please set a correct product price.`
                          },
                        })}
                      />
                      <p className='mt-1 text-sm font-normal leading-5 text-gray-500 font-sans'>
                        The retail price must be a positive numerical value,
                        with zero allowable only for digital products.
                      </p>
                    </div>
                  </div>

                  <div className='grid gap-6'>
                    <div className='col-span-3 sm:col-span-2'>
                      <Controller
                        name='productType'
                        control={control}
                        rules={{ required: `Please set a product type.` }}
                        render={({ field }) => (
                          <RadioGroup
                            options={productTypeOptions}
                            field={field}
                            className='mt-1 sm:grid-cols-2'
                            error={errors?.productType?.message}
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className='grid gap-6'>
                    <div className='col-span-3 sm:col-span-2'>
                      <TextField
                        error={errors.link?.message}
                        label='Product link (optional)'
                        placeholder='www.example.com/product'
                        className={'rounded-none rounded-r-md'}
                        leftElement={
                          <span className='inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm'>
                            https://
                          </span>
                        }
                        {...register('link', {
                          // required: `Please set a link for your product.`,
                          maxLength: {
                            value: 1000,
                            message: `Your product link is too long.`,
                          },
                          validate: {
                            value: (productLink) => {
                              if (
                                productLink === null ||
                                productLink === '' ||
                                validator.isURL(cleanUrl(productLink))
                              ) {
                                return true
                              }

                              return 'Invalid link.'
                            },
                          },
                        })}
                      />
                    </div>
                  </div>

                  <div className='grid gap-6'>
                    <div className='col-span-3 sm:col-span-2'>
                      <TextArea
                        error={errors.details?.message}
                        label='Other details (optional)'
                        placeholder='Add product details...'
                        {...register('details', {
                          // required: `Please add more details about your product.`,
                          maxLength: {
                            value: 10000,
                            message: `Your product description too long.`,
                          },
                        })}
                      />
                      <p className='mt-1 text-sm font-normal leading-5 text-gray-500 font-sans'>
                        Any info that can be useful to our creators
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className='flex justify-between md:shadow rounded-b-md py-3 md:bg-gray-50 sm:px-6'>
                <Button
                  title='Go back'
                  type='button'
                  colour={ButtonColor.white}
                  loading={loadingUpdateBrand}
                  disabled={loadingUpdateBrand}
                  onClick={goBack}
                />

                <Button
                  title='Continue'
                  type='submit'
                  loading={
                    loadingUpdateBrand ||
                    loadingCreateProduct ||
                    loadingUpdateProduct
                  }
                  disabled={
                    loadingUpdateBrand ||
                    loadingCreateProduct ||
                    loadingUpdateProduct ||
                    loadingProductPhotoUploadUrl
                  }
                  // onClick={() => {
                  //   // if product photo already set
                  //   if (product?.photo?.url) {
                  //     return
                  //   }

                  //   if (
                  //     !watch('productPhoto') ||
                  //     watch('productPhoto') === null
                  //   ) {
                  //     setError('productPhoto', {
                  //       type: 'custom',
                  //       message: 'Please set a photo for your product.',
                  //     })
                  //   }
                  // }}
                />
              </div>
            </form>
          </div>
        </div>
      </div>

      <Bullets
        brand={brand}
        stepCurrent={BrandOnboardingStep.setProductDetails}
      />
    </div>
  )
}

export { SetProductDetails }
