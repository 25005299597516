import { CheckboxItem, CheckboxType } from '../../../../components'

interface Props {
  errors: any
  watch: any
  register: any
}

const SetPermission = ({ errors, watch, register }: Props) => {
  return (
    <div>
      <div className='mt-10 sm:mt-0'>
        <CheckboxItem
          type={CheckboxType.checkbox}
          value={!watch('isAllVisible')}
          title='Grant permission to showcase this video in our Inspiration Library, making it accessible to all brands and creators as a source of inspiration.'
          {...register('isAllVisible')}
          selected={watch('isAllVisible')}
          error={errors?.isAllVisible?.message}
          className='-m-4'
        />
      </div>
    </div>
  )
}

export { SetPermission }
