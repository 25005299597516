import { FunctionComponent } from 'react'
import { CarouselWidget } from './CarouselWidget'
import { RibbonWidget } from './RibbonWidget'

interface IDefaultProps {
  type: 'carousel' | 'ribbon'
}

interface IProps extends Partial<IDefaultProps> {
  className?: string
}

const defaultProps: IDefaultProps = {
  type: 'carousel',
}

const ReviewsIO: FunctionComponent<IProps> = ({ type, className }) => {
  switch (type) {
    case 'ribbon':
      return <RibbonWidget className={className} />
    case 'carousel':
    default:
      return <CarouselWidget className={className} />
  }
}

ReviewsIO.defaultProps = defaultProps

export { ReviewsIO }
