import { useMutation, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import axios from 'axios'
import {
  Button,
  ButtonColor,
  Loader,
  NotificationType,
  PortfolioGrid,
  VideoUpload,
} from '../../../components'
import {
  UPDATE_CREATOR,
  GetPortfolioByCreatorResponse,
  GET_PORTFOLIO_BY_CREATOR,
  GET_PORTFOLIO_ITEM_UPLOAD_URL,
  CREATE_PORTFOLIO_ITEM,
} from '../../../gql'
import {
  Creator,
  CreatorOnboardingStep,
  GetPortfolioSort,
  GetUploadUrlResponse,
  MutationCreatePortfolioItemArgs,
  MutationGetPortfolioItemUploadUrlArgs,
  QueryGetPortfolioByCreatorArgs,
} from '../../../gql/types'
import { AnalyticsEvent, trackEvent } from '../../../utils/analytics'
import { useNotification } from '../../../utils/hooks'

interface Props {
  creator: Creator
}

const SetPortfolio = ({ creator }: Props) => {
  const [updateCreator, { loading: updateCreatorLoading }] =
    useMutation(UPDATE_CREATOR)
  const { addNotification } = useNotification()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const [mime, setMime] = useState('')
  const [contentDimensions, setContentDimensions] = useState<{
    w: number | null
    h: number | null
  }>({ w: null, h: null })

  const [getPortfolioItemUploadUrl] = useMutation<
    { getPortfolioItemUploadUrl: GetUploadUrlResponse },
    MutationGetPortfolioItemUploadUrlArgs
  >(GET_PORTFOLIO_ITEM_UPLOAD_URL)

  const [createPortfolioItem] = useMutation<
    any,
    MutationCreatePortfolioItemArgs
  >(CREATE_PORTFOLIO_ITEM, {
    refetchQueries: [
      {
        query: GET_PORTFOLIO_BY_CREATOR,
        variables: {
          creatorId: creator.id,
          options: {
            skip: 0,
            limit: 12,
            sort: GetPortfolioSort.newest,
          },
        },
      },
    ],
  })

  const reset = () => {
    setSelectedFile(null)
    setMime('')
  }

  const {
    loading: getPortfolioLoading,
    error: getPortfolioError,
    data: getPortfolioData,
  } = useQuery<GetPortfolioByCreatorResponse, QueryGetPortfolioByCreatorArgs>(
    GET_PORTFOLIO_BY_CREATOR,
    {
      variables: {
        creatorId: creator.id,
        options: {
          skip: 0,
          limit: 12,
          sort: GetPortfolioSort.newest,
        },
      },
    },
  )

  useEffect(() => {
    trackEvent(AnalyticsEvent.viewCreatorsOnboardingSetPortfolio)
  }, [])

  const goBack = async () => {
    try {
      await updateCreator({
        variables: {
          id: creator.id,
          input: {
            onboarding: CreatorOnboardingStep.setIntroVideo,
          },
        },
      })
    } catch (error) {
      addNotification(error.message, NotificationType.error)
    }
  }

  const onUpdateCreator = async (event: React.MouseEvent) => {
    event.preventDefault()

    setError(null)

    try {
      await updateCreator({
        variables: {
          id: creator.id,
          input: {
            onboarding: CreatorOnboardingStep.setShippingAddress,
          },
        },
      })
    } catch (error) {
      console.log('@error updateCreator', error.message)
    }

    setLoading(false)
  }

  const uploadContent = async () => {
    if (!selectedFile) {
      setError('Please upload a video.')
      return
    }

    setError(null)
    setLoading(true)

    try {
      //make sure to call GET_CONTENT_UPLOAD_URL every time when uploading a new file
      const { data } = await getPortfolioItemUploadUrl({
        variables: {
          creatorId: creator.id,
          mime,
        },
      })

      // push content to S3
      await axios.put(data.getPortfolioItemUploadUrl.uploadUrl, selectedFile, {
        headers: {
          'Content-Type': mime,
        },
      })

      await createPortfolioItem({
        variables: {
          creatorId: creator.id,
          input: {
            videoFileName: data.getPortfolioItemUploadUrl.fileName,
            videoWidth: contentDimensions.w,
            videoHeight: contentDimensions.h,
          },
        },
      })

      addNotification(`Portfolio video uploaded`, NotificationType.success)
      reset()
    } catch (error) {
      console.log('Error Creating portfolio video', error?.message)

      addNotification(
        `Something went wrong. Please try again.`,
        NotificationType.error,
      )
    }

    setLoading(false)
  }

  const handleFileInput = (
    file: File,
    size?: {
      w: number | null
      h: number | null
    },
  ) => {
    if (!file) {
      setSelectedFile(null)
      setMime('')
      return
    }

    setSelectedFile(file)
    setMime(file.type)
    setError(null)
    setContentDimensions(size)
  }

  useEffect(() => {
    if (selectedFile) {
      // Call uploadContent only when selectedFile is not null
      uploadContent()
    }
  }, [selectedFile])

  // if (loading) {
  //   return <Loader />
  // }

  if (error || getPortfolioError) {
    console.log('getPortfolioError', getPortfolioError?.message)
    console.log('error', error)
  }

  return (
    <div className='min-h-screen flex flex-col'>
      <div className='flex-grow'>
        <div className='min-h-full flex flex-col justify-center pt-4 px-4 md:mt-8 lg:px-8'>
          <div className='sm:mx-auto sm:w-full sm:max-w-2xl'>
            <div className='md:shadow rounded-t-md sm:overflow-hidden mb-8'>
              <div className='py-5 md:bg-white space-y-6 sm:p-6'>
                <div className='sm:mx-auto'>
                  <h2 className='mt-0 md:text-center text-3xl font-extrabold text-gray-900'>
                    Portfolio
                  </h2>
                  <p className='mt-2 text-sm text-gray-600'>
                    Upload 3 or more videos from your previous collaborations to
                    show quality and variety of your content styles.
                    {/* You can{' '}
                    <Link
                      to={CreatorRoutes.onboarding}
                      className='text-blue-600 hover:text-blue-500'
                      onClick={onUpdateCreator}
                    >
                      skip
                    </Link>{' '}
                    this step, but its less likely you'll get approve. */}
                  </p>
                  <p className='mt-4 text-sm text-gray-600'>
                    Your portfolio should include different styles of UGC
                    (unboxing, testimonial, how-to). Your videos should be
                    between 15 seconds to 1 minute.
                  </p>
                  {/* <p className='mt-4 text-sm text-gray-600'>
                    If you lack prior experience in brand collaborations, we suggest creating videos using everyday items from your home as substitutes for brand products.
                  </p> */}
                  <p className='mt-4 text-sm text-gray-600'>
                    <a
                      href='https://useclip.com/ugc-content'
                      className='text-blue-600'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      See examples
                    </a>{' '}
                    of videos by other creators on Clip.
                  </p>
                </div>

                <div className='mt-6 w-full'>
                  {loading && (
                    <div className='flex flex-col justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6'>
                      Video uploading...
                    </div>
                  )}
                  {!loading && <VideoUpload onChange={handleFileInput} />}
                  {error && (
                    <p className='text-red-500 text-sm mt-2'>{error}</p>
                  )}
                </div>

                {getPortfolioLoading && <Loader />}
                {!getPortfolioLoading && getPortfolioData && (
                  <div className='-mx-4'>
                    <PortfolioGrid
                      items={getPortfolioData.getPortfolioByCreator.items}
                      isFullScreen={true}
                      isDeleteAvailable={true}
                    />
                  </div>
                )}
              </div>

              <div className='flex justify-between md:shadow rounded-b-md py-3 md:bg-gray-50 text-right sm:px-6'>
                <Button
                  title='Go back'
                  type='button'
                  colour={ButtonColor.white}
                  loading={loading || updateCreatorLoading}
                  disabled={loading || updateCreatorLoading}
                  onClick={goBack}
                />
                <Button
                  title='Continue'
                  type='button'
                  loading={updateCreatorLoading}
                  disabled={loading || updateCreatorLoading}
                  // className='w-full'
                  onClick={(event) => {
                    if (
                      getPortfolioData?.getPortfolioByCreator?.items?.length < 3
                    ) {
                      setError('Please upload minim 3 videos.')
                      return
                    }
                    onUpdateCreator(event)
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { SetPortfolio }
