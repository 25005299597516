import classnames from 'classnames'
import moment from 'moment-timezone'
import { Link, useParams } from 'react-router-dom'
import {
  CampaignStatus as BrandCampaignStatus,
  Button,
  ButtonColor,
  ButtonSize,
  ButtonStyle,
  Icon,
  IconType,
  TooltipInfo,
} from '../../../../components'
import { Campaign, CampaignStatus } from '../../../../gql/types'
import { BrandRoutes } from '../../../BrandRoutes'
import { CampaignActionsDropdown } from '../../../shared/CampaignActionsDropdown'
import { isCampaignPremium } from '../../../../utils/hooks'
import { Avatar } from '../../../../elements'
import { useState } from 'react'
import { InviteCreatorModal } from '../../InviteCreatorModal'

interface IDefaultProps {}

interface IProps extends Partial<IDefaultProps> {
  campaign: Campaign
  index: number
}

const ListItem = ({ campaign, index }: IProps) => {
  const { id } = useParams()
  const [isInviteCreatorModalOpen, setIsInviteCreatorModalOpen] =
    useState(false)

  return (
    <>
      <tr
        key={campaign.id}
        className={classnames(index % 2 === 0 ? 'bg-gray-50' : 'bg-white')}
      >
        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
          <div className='flex items-center gap-x-2'>
            {/* <VideoTypeIcon type={campaign.videoType} /> */}
            <Avatar
              src={campaign?.product?.photo?.url}
              alt={campaign?.product?.name}
              size='xs'
              type='product'
            />

            <div>
              <Link
                to={
                  campaign.status !== CampaignStatus.draft
                    ? BrandRoutes.campaignView
                        .replace(':id', `${id}`)
                        .replace(':campaignId', `${campaign.id}`)
                    : BrandRoutes.campaignEdit
                        .replace(':id', `${id}`)
                        .replace(':campaignId', `${campaign.id}`)
                }
                className='font-medium text-gray-900 hover:text-gray-800'
              >
                <div className='text-ellipsis overflow-hidden w-40 md:w-60'>
                  {campaign?.title}
                </div>
              </Link>

              <div className='text-gray-500'>
                <div className='text-ellipsis overflow-hidden w-40 md:w-60'>
                  {campaign.product.name}
                </div>
              </div>
            </div>
          </div>
        </td>

        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
          <BrandCampaignStatus status={campaign.status} />
        </td>

        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
          <Link
            to={BrandRoutes.campaignApplicants
              .replace(':id', `${id}`)
              .replace(':campaignId', `${campaign.id}`)}
            className='font-medium text-gray-900 hover:text-gray-800'
          >
            <div className='flex gap-2 rounded-xl hover:bg-gray-200 p-2'>
              {campaign.applicantsCount}
              {isCampaignPremium(campaign) && (
                <Icon
                  type={IconType.premium}
                  className='w-5 h-5 text-amber-400'
                  aria-hidden='true'
                />
              )}
            </div>
          </Link>
        </td>

        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
          <Link
            to={BrandRoutes.campaignCollaborators
              .replace(':id', `${id}`)
              .replace(':campaignId', `${campaign.id}`)}
            className='font-medium text-gray-900 hover:text-gray-800'
          >
            <div className='flex rounded-xl hover:bg-gray-200 p-2'>
              {`${campaign.collaboratorsCount} / ${campaign.videoQuantity}`}
            </div>
          </Link>
        </td>

        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
          <div className='flex gap-2'>
            {moment(campaign.createdAt).format('ll')}
          </div>
        </td>

        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
          <div className='flex items-center gap-x-2'>
            <Button
              styleType={ButtonStyle.fill}
              // title='Invite creators'
              type='button'
              icon={
                <Icon
                  type={IconType.personAdd}
                  className='h-5 w-5 text-white'
                  aria-hidden='true'
                />
              }
              size={ButtonSize.small}
              colour={ButtonColor.primary}
              onClick={() =>
                setIsInviteCreatorModalOpen(!isInviteCreatorModalOpen)
              }
              iconPosition='left'
              tooltip='Invite creators'
            />
            <div className='flex gap-4 justify-center'>
              <CampaignActionsDropdown campaign={campaign} />
            </div>
          </div>
        </td>
        <td className='sr-only'>
          {isInviteCreatorModalOpen && (
            <InviteCreatorModal
              campaign={campaign}
              isOpen={isInviteCreatorModalOpen}
              setIsOpen={setIsInviteCreatorModalOpen}
            />
          )}
        </td>
      </tr>
    </>
  )
}

export { ListItem }
