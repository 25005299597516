import { useQuery } from '@apollo/client'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Loader } from '../../../components'
import {
  CampaignInvitationStatus,
  CreatorStatus,
  GetCampaignInvitationsSort,
  QueryGetCampaignInvitationsByCreatorIdArgs,
  User,
} from '../../../gql/types'
import { PricingData } from '../../../utils/PricingUtil'
import { AnalyticsEvent, trackEvent } from '../../../utils/analytics'
import { getAgeGroupByBirthday } from '../../../utils/mappers'
import { AccountStatus } from '../../components/AccountStatus'
import { isCreatorAllowedToAccessOpportunities } from '../../utils'
import { Header } from '../components/Header'
import { List } from '../components/List'
import { Grid } from '../components/Grid'
import {
  GET_CAMPAIGN_INVITATIONS_BY_CREATOR_ID,
  QueryGetCampaignInvitationsByCreatorIdResponse,
} from '../../../gql'

interface IProps {
  user: User
  pricing: PricingData
}

const Invitations = ({ user, pricing }: IProps) => {
  const { id } = useParams()
  const { creator } = user

  const ageGroup = [getAgeGroupByBirthday(creator.birthday)]
  const { loading, error, data, refetch } = useQuery<
    QueryGetCampaignInvitationsByCreatorIdResponse,
    QueryGetCampaignInvitationsByCreatorIdArgs
  >(GET_CAMPAIGN_INVITATIONS_BY_CREATOR_ID, {
    skip: creator.status === CreatorStatus.shadowBanned,
    variables: {
      creatorId: creator.id,
      options: {
        query: '',
        filters: {
          status: [CampaignInvitationStatus.invited],
        },
        sort: GetCampaignInvitationsSort.newest,
        skip: 0,
      },
    },
  })

  const setPage = (page) => {
    refetch({
      options: {
        query: '',
        filters: {},
        sort: GetCampaignInvitationsSort.newest,
        skip: page * 20,
      },
    })
  }

  useEffect(() => {
    if (isCreatorAllowedToAccessOpportunities(creator)) {
      trackEvent(AnalyticsEvent.viewCreatorsInvitations, {
        creatorId: creator.id,
      })
    }
  }, [])

  // if creator not allowed to access collaborations, display account status
  if (!isCreatorAllowedToAccessOpportunities(creator)) {
    return <AccountStatus creator={creator} />
  }

  if (error) {
    console.log(error)
    return <p>There has been an error. Try refreshing the page</p>
  }

  let message
  if (creator.status === CreatorStatus.rejected) {
    message = (
      <>
        We couldn't approve your account. Please send us a new intro video at{' '}
        <a href='mailto:hello@useclip.com'>hello@useclip.com</a>
      </>
    )
  }

  if (creator.status === CreatorStatus.paused) {
    message = (
      <>
        Your account is paused. Email us at{' '}
        <a href='mailto:hello@useclip.com'>hello@useclip.com</a>
      </>
    )
  }

  return (
    <div>
      <Header />

      {loading && <Loader />}
      {!loading && creator.status === CreatorStatus.shadowBanned && (
        <div className='pb-4 w-auto h-fit'>
          <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='inline-block min-w-full py-2 px-1 align-middle md:px-6 lg:px-8'>
              <div className='shadow md:w-auto ring-1 ring-black ring-opacity-5 rounded-t'>
                <div className='hidden lg:block'>
                  <List
                    creator={user.creator}
                    campaigns={[]}
                    pagination={{
                      numberOfHits: 0,
                      pageNumber: 0,
                      hitsPerPage: 20,
                      numberOfPages: 0,
                    }}
                    pricing={pricing}
                    setPage={setPage}
                    onRefetch={() => refetch()}
                  />
                </div>
                <div className='block lg:hidden'>
                  <Grid
                    creator={user.creator}
                    campaigns={[]}
                    pagination={{
                      numberOfHits: 0,
                      pageNumber: 0,
                      hitsPerPage: 20,
                      numberOfPages: 0,
                    }}
                    pricing={pricing}
                    setPage={setPage}
                    onRefetch={() => refetch()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!loading && creator.status === CreatorStatus.active && (
        <div className='pb-4 w-auto h-fit'>
          <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='inline-block min-w-full py-2 px-1 align-middle md:px-6 lg:px-8'>
              <div className='shadow md:w-auto ring-1 ring-black ring-opacity-5 rounded-t'>
                <div className='hidden lg:block'>
                  <List
                    creator={user.creator}
                    campaigns={data?.getCampaignInvitationsByCreatorId?.items.map(
                      (item) => {
                        const campaignItem = {
                          campaign: item.campaign,
                          invitationId: item.id,
                        }
                        return campaignItem
                      },
                    )}
                    pagination={
                      data?.getCampaignInvitationsByCreatorId?.pagination
                    }
                    pricing={pricing}
                    setPage={setPage}
                    onRefetch={() => refetch()}
                  />
                </div>
                <div className='block lg:hidden'>
                  <Grid
                    creator={user.creator}
                    campaigns={data?.getCampaignInvitationsByCreatorId?.items.map(
                      (item) => {
                        const campaignItem = {
                          campaign: item.campaign,
                          invitationId: item.id,
                        }
                        return campaignItem
                      },
                    )}
                    pagination={
                      data?.getCampaignInvitationsByCreatorId?.pagination
                    }
                    pricing={pricing}
                    setPage={setPage}
                    onRefetch={() => refetch()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export { Invitations }
