import { gql } from '@apollo/client'
import {
  CampaignFragment,
  CollaborationFragment,
  CreatorFragment,
  PaginationFragment,
} from '.'
import { GetClipsResponse } from './types'

export const ClipFragment = gql`
  fragment ClipFragment on Clip {
    id
    createdAt
    updatedAt
    type

    video {
      url
      name
    }

    thumbnail {
      url
      name
    }

    creator {
      ...CreatorFragment
    }
    campaign {
      ...CampaignFragment
    }
    collaboration {
      ...CollaborationFragment
    }
  }
  ${CreatorFragment}
  ${CampaignFragment}
  ${CollaborationFragment}
`

export const CREATE_CLIP = gql`
  mutation CreateClip($collaborationId: Int!, $input: CreateClipInput!) {
    createClip(collaborationId: $collaborationId, input: $input) {
      ...ClipFragment
    }
  }
  ${ClipFragment}
`

export const GET_CLIP_BY_ID = gql`
  query GetClipById($id: Int!) {
    getClipById(id: $id) {
      ...ClipFragment
    }
  }
  ${ClipFragment}
`

export const GET_CLIPS_BY_BRAND_ID = gql`
  query GetClipsByBrandId($brandId: Int!, $options: GetClipsOptions!) {
    getClipsByBrandId(brandId: $brandId, options: $options) {
      items {
        ...ClipFragment
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${ClipFragment}
  ${PaginationFragment}
`

export const GET_CLIPS_BY_CAMPAIGN_ID = gql`
  query GetClipsByCampaignId($campaignId: Int!, $options: GetClipsOptions!) {
    getClipsByCampaignId(campaignId: $campaignId, options: $options) {
      items {
        ...ClipFragment
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${ClipFragment}
  ${PaginationFragment}
`

export const GET_CLIPS_BY_CREATOR_ID = gql`
  query GetClipsByCreatorId($creatorId: Int!, $options: GetClipsOptions!) {
    getClipsByCreatorId(creatorId: $creatorId, options: $options) {
      items {
        ...ClipFragment
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${ClipFragment}
  ${PaginationFragment}
`

export const GET_CLIPS_BY_BRAND_CREATOR = gql`
  query GetClipsByBrandAndCreator(
    $creatorId: Int!
    $brandId: Int!
    $options: GetClipsOptions!
  ) {
    getClipsByBrandAndCreator(
      creatorId: $creatorId
      brandId: $brandId
      options: $options
    ) {
      items {
        ...ClipFragment
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${ClipFragment}
  ${PaginationFragment}
`

export const GET_CLIP_VIDEO_UPLOAD_URL = gql`
  mutation GetClipVideoUploadUrl(
    $creatorId: Int!
    $campaignId: Int!
    $mime: String!
  ) {
    getClipVideoUploadUrl(
      creatorId: $creatorId
      campaignId: $campaignId
      mime: $mime
    ) {
      uploadUrl
      fileName
    }
  }
`

export interface GetClipsByCreatorResponse {
  getClipsByCreatorId: GetClipsResponse
}

export interface GetClipsByBrandResponse {
  getClipsByBrandId: GetClipsResponse
}

export interface GetClipsByCampaignResponse {
  getClipsByCampaignId: GetClipsResponse
}

export interface GetClipsByBrandAndCreatorResponse {
  getClipsByBrandAndCreator: GetClipsResponse
}
