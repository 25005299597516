import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { Loader } from '../../components'
import { GET_CAMPAIGN_BY_ID, QueryGetCampaignByIdResponse } from '../../gql'
import { Brand, QueryGetCampaignByIdArgs, User } from '../../gql/types'
import { PricingData } from '../../utils/PricingUtil'
import { Edit } from './Edit'

interface IProps {
  user: User
  brand: Brand
  pricing: PricingData
}

const CampaignEdit = ({ user, brand, pricing }: IProps) => {
  const { campaignId } = useParams()

  const {
    loading: getCampaignLoading,
    error: getCampaignError,
    data: getCampaignData,
  } = useQuery<QueryGetCampaignByIdResponse, QueryGetCampaignByIdArgs>(
    GET_CAMPAIGN_BY_ID,
    {
      variables: {
        id: Number(campaignId),
      },
    },
  )

  if (getCampaignLoading) {
    return <Loader />
  }

  if (getCampaignError) {
    console.log('error CampaignEdit getCampaignError', getCampaignError)
    return <p>There has been an error. Try refreshing the page</p>
  }

  const campaign = getCampaignData?.getCampaignById
  const campaignHooks = getCampaignData?.getCampaignById?.hooks

  return (
    <div className='py-4'>
      <div className='sm:flex-auto'>
        <div className='flex flex-row'>
          <h1 className='font-bold text-3xl font-outfit'>Edit campaign</h1>
        </div>
      </div>
      <div className='hidden sm:block' aria-hidden='true'>
        <div className='py-5'>
          <div className='border-t border-gray-200' />
        </div>
      </div>
      <div className='-mt-5 -mx-8 xs:-mt-8 xs:-mx-8'>
        <div className='flex flex-1 flex-col'>
          <Edit
            brand={brand}
            campaign={campaign}
            pricing={pricing}
            campaignHooks={campaignHooks}
          />
        </div>
      </div>
    </div>
  )
}

export { CampaignEdit }
