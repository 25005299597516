import { useMutation } from '@apollo/client'
import axios from 'axios'
import { FunctionComponent, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Button,
  NotificationType,
  TextArea,
  TextField,
} from '../../../../components'
import { AvatarUpdate } from '../../../../components/AvatarUpdate'
import {
  GetUserAvatarUploadUrlResponse,
  GET_USER_AVATAR_UPLOAD_URL,
  UPDATE_USER,
  GET_SELF,
} from '../../../../gql'
import {
  MutationGetUserAvatarUploadUrlArgs,
  MutationUpdateUserArgs,
  UpdateUserInput,
  User,
} from '../../../../gql/types'
import { useNotification } from '../../../../utils/hooks'
import { AnalyticsEvent, trackEvent } from '../../../../utils/analytics'

interface IProps {
  user: User
}

const EditPersonalInformation: FunctionComponent<IProps> = ({ user }) => {
  const [isAvatarUploadLoading, setIsAvatarUploadLoading] = useState(false)
  const [update, { loading }] = useMutation<User, MutationUpdateUserArgs>(
    UPDATE_USER,
    {
      refetchQueries: [
        'GetSelf',
        {
          query: GET_SELF,
        },
      ],
    },
  )
  const [getAvatarUploadUrl] = useMutation<
    GetUserAvatarUploadUrlResponse,
    MutationGetUserAvatarUploadUrlArgs
  >(GET_USER_AVATAR_UPLOAD_URL)

  const { addNotification } = useNotification()

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<UpdateUserInput>({
    defaultValues: {
      avatarFileName: user.avatar?.name,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phoneNumber: user.phoneNumber,
      bio: user.bio,
    },
  })

  useEffect(() => {
    trackEvent(AnalyticsEvent.viewCreatorsPersonalInformation)
  }, [])

  const onSubmit = async (data: UpdateUserInput) => {
    try {
      await update({
        variables: {
          id: user.id,
          input: {
            avatarFileName: data.avatarFileName,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phoneNumber: data.phoneNumber,
            bio: data.bio,
          },
        },
      })

      addNotification('Profile updated successfully', NotificationType.success)
    } catch (error) {
      console.log('error EditProfile', error.message)
      addNotification(error.message, NotificationType.error)
    }
  }

  const onAvatarUpdated = async (file: File) => {
    if (!file) return

    try {
      setIsAvatarUploadLoading(true)
      const { data } = await getAvatarUploadUrl({
        variables: {
          userId: user.id,
          mime: file.type,
        },
      })

      await axios.put(data.getUserAvatarUploadUrl.uploadUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
      })

      if (data.getUserAvatarUploadUrl.fileName) {
        setValue('avatarFileName', data.getUserAvatarUploadUrl.fileName)
      }
    } catch (error) {
      console.log('error onAvatarUpdated', error.message)
    } finally {
      setIsAvatarUploadLoading(false)
    }
  }

  const EmailField = () => {
    return (
      <TextField
        label='Email Address'
        name='email'
        placeholder={`Please set your email address.`}
        error={errors.email?.message}
        {...register('email', {
          required: `Please set an email address.`,
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: `Please use a real email address.`,
          },
          maxLength: {
            value: 100,
            message: `Please use a real email address.`,
          },
        })}
      />
    )
  }

  const LastNameField = () => {
    return (
      <TextField
        label='Last Name'
        name='lastName'
        placeholder={`Please set your last name.`}
        error={errors.lastName?.message}
        {...register('lastName', {
          required: `Please set a last name.`,
          maxLength: {
            value: 100,
            message: `Please use a real last name.`,
          },
        })}
      />
    )
  }

  const PhoneNumberField = () => {
    return (
      <TextField
        label='Phone Number'
        name='phoneNumber'
        placeholder={`Please set your phone number.`}
        error={errors.phoneNumber?.message}
        {...register('phoneNumber', {
          required: `Please set a phone number.`,
          maxLength: {
            value: 15,
            message: `Please use a real phone number.`,
          },
        })}
      />
    )
  }
  const FirstNameField = () => {
    return (
      <TextField
        label='First Name'
        name='firstName'
        placeholder={`Please set your first name.`}
        error={errors.firstName?.message}
        {...register('firstName', {
          required: `Please set a first name.`,
          maxLength: {
            value: 100,
            message: `Please use a real first name.`,
          },
        })}
      />
    )
  }

  const AboutField = () => {
    return (
      <TextArea
        label='About'
        name='about'
        placeholder={`Please tell more about yourself.`}
        error={errors.bio?.message}
        {...register('bio', {
          maxLength: {
            value: 1000,
            message: `Please use a shorter description.`,
          },
        })}
        disabled={true}
      />
    )
  }

  return (
    <div className='grid grid-cols-1 gap-x-8 gap-y-8 lg:grid-cols-3'>
      <div className='px-0'>
        <div className='flex'>
          <h2 className='text-lg leading-6 font-medium text-gray-900'>
            Personal information
          </h2>
        </div>
        <p className='mt-1 text-sm text-gray-500'>
          This information facilitates collaborations and ensures a seamless
          experience.
        </p>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className='bg-white shadow ring-1 ring-gray-900/5 sm:rounded-xl lg:col-span-2'
      >
        <div className='px-4 py-6 sm:p-8'>
          <div className='grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6'>
            <div className='sm:col-span-6'>
              <AvatarUpdate
                avatarUrl={user.avatar?.url}
                onChange={onAvatarUpdated}
              />
            </div>

            <div className='sm:col-span-3'>
              <FirstNameField />
            </div>

            <div className='sm:col-span-3'>
              <LastNameField />
            </div>

            <div className='sm:col-span-3'>
              <EmailField />
            </div>

            <div className='sm:col-span-3'>
              <PhoneNumberField />
            </div>

            <div className='sm:col-span-6'>
              <AboutField />
            </div>
          </div>
        </div>
        <div className='flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8'>
          <Button
            title='Update'
            type='submit'
            loading={loading || isAvatarUploadLoading}
            disabled={loading || isAvatarUploadLoading}
          />
        </div>
      </form>
    </div>
  )
}

export { EditPersonalInformation }
