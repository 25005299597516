import { Controller } from 'react-hook-form'
import { RadioGroup } from '../../../../components'
import { Currency } from '../../../../gql/types'
import { PricingData, getVideoPricing } from '../../../../utils/PricingUtil'
import { addonsOptions } from '../../../../utils/mappers'

interface Props {
  control: any
  errors: any
  register: any
  watch: any
  currency: Currency
  pricing: PricingData
}

const SetAddons = ({
  control,
  errors,
  watch,
  register,
  currency,
  pricing,
}: Props) => {
  const videoPricing = getVideoPricing({
    currency: currency,
    videoDuration: watch('videoDuration'),
    pricing,
  })

  const addonsPrice = videoPricing.addons

  return (
    <div>
      <div className='mt-10 sm:mt-0'>
        <div className='md:grid md:grid-cols-3 md:gap-6'>
          <div className='md:col-span-1'>
            <div className=''>
              <h3 className='text-lg font-semibold font-sans leading-6 text-gray-900'>
                Addons
              </h3>
              <p className='mt-1 text-sm font-normal font-sans leading-5 text-gray-500'>
                Both the original and edited footage will be accessible for
                download.
              </p>
            </div>
          </div>
          <div className='mt-5 md:col-span-2 md:mt-0'>
            <div className='md:shadow rounded-t-md sm:overflow-hidden'>
              <div className='py-5 md:bg-white space-y-6 sm:p-6'>
                <div className='grid gap-6'>
                  <div className='col-span-3 sm:col-span-2'>
                    <Controller
                      name='addons'
                      control={control}
                      render={({ field }) => (
                        <RadioGroup
                          options={addonsOptions(videoPricing.addons, currency)}
                          field={field}
                          error={errors?.addons?.message}
                          allowDeselect={true}
                          deselectTitle={`Remove Addon`}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='hidden sm:block' aria-hidden='true'>
        <div className='py-5'>
          <div className='border-t border-gray-200' />
        </div>
      </div>
    </div>
  )
}

export { SetAddons }
