import classNames from 'classnames'

interface IProps {
  className?: string
}

export const Divider = ({ className }: IProps) => {
  return (
    <div className={classNames('relative', className)}>
      <div className='absolute inset-0 flex items-center' aria-hidden='true'>
        <div className='w-full border-t border-gray-300' />
      </div>
    </div>
  )
}
