import { gql } from '@apollo/client'
import { BrandFragment } from './brands'

export const CreditPacksFragment = gql`
  fragment CreditPacksFragment on CreditPack {
    name
    type
    priceCents
    bonusCents
    description
    mostPopular
  }
`

export const CreditPackFeatureFragment = gql`
  fragment CreditPackFeatureFragment on CreditPackFeature {
    name
    packs {
      starter
      pro
      premium
      ultimate

      key
      growth
      promo

      starterLowTierChristmas
      starterMediumTierChristmas
      starterHighTierChristmas
      scaleLowTierChristmas
      scaleMediumTierChristmas
      scaleHighTierChristmas
    }
  }
`

export const GET_CREDIT_PACKS_WITH_BRAND = gql`
  query GetCreditPacksWithBrand($brandId: Int!, $currency: String!) {
    getBrandById(id: $brandId) {
      ...BrandFragment
    }
    getCreditPacks(currency: $currency) {
      packs {
        ...CreditPacksFragment
      }
      features {
        ...CreditPackFeatureFragment
      }
    }
  }
  ${BrandFragment}
  ${CreditPacksFragment}
  ${CreditPackFeatureFragment}
`

export const GET_BLACK_FRIDAY_PACKS_WITH_BRAND = gql`
  query GetBlackFridayPacksWithBrand($brandId: Int!, $currency: String!) {
    getBrandById(id: $brandId) {
      ...BrandFragment
    }
    getBlackFridayPacks(currency: $currency) {
      packs {
        ...CreditPacksFragment
      }
      features {
        ...CreditPackFeatureFragment
      }
    }
  }
  ${BrandFragment}
  ${CreditPacksFragment}
  ${CreditPackFeatureFragment}
`

export const GET_CHRISTMAS_PACKS_WITH_BRAND = gql`
  query GetChristmasPacksWithBrand($brandId: Int!, $currency: String!) {
    getBrandById(id: $brandId) {
      ...BrandFragment
    }
    getChristmasPacks(currency: $currency) {
      packs {
        ...CreditPacksFragment
      }
      features {
        ...CreditPackFeatureFragment
      }
    }
  }
  ${BrandFragment}
  ${CreditPacksFragment}
  ${CreditPackFeatureFragment}
`

export const GET_CREDIT_PACKS = gql`
  query GetCreditPacks($currency: String!) {
    getCreditPacks(currency: $currency) {
      packs {
        ...CreditPacksFragment
      }
      features {
        ...CreditPackFeatureFragment
      }
    }
  }
  ${CreditPacksFragment}
  ${CreditPackFeatureFragment}
`
