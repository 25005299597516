import { useState } from 'react'
import {
  Badge,
  BadgeSize,
  BadgeType,
  Button,
  ButtonColor,
  CollaborationStatus as CollaborationStatusComponent,
  Icon,
  TooltipInfo,
  IconType,
  ButtonSize,
} from '../../../../components'
import { Avatar } from '../../../../elements'
import {
  Collaboration,
  CollaborationStatus,
  ProductType,
} from '../../../../gql/types'
import { mapCurrencyToSign } from '../../../../utils/mappers'
import { CampaignSlider } from '../../../components/CampaignSlider'
import { ViewClipsSlider } from '../ViewClipsSlider'
import { CreatorRoutes } from '../../../CreatorRoutes'
import { Link, useParams } from 'react-router-dom'
import { PricingData, formatPriceInCents } from '../../../../utils/PricingUtil'
import { CreateClipModal } from '../../../../components/CreateClipModal'
import { isCampaignPremium } from '../../../../utils/hooks'
import { WithdrawFromCollaborationModal } from '../WithdrawFromCollaborationModal'

interface IProps {
  collaboration: Collaboration
  pricing: PricingData
}

export const ActiveCampaignCard = ({ collaboration, pricing }: IProps) => {
  const {
    campaign,
    hooksCount,
    addonsCount,
    status,
    productTrackingLink,
    creatorEarningsCurrency,
    totalCreatorEarningsCents,
    pendingUploadHooksCount,
    pendingUploadAddonsCount,
    creator,
    uuid,
    pendingCreatorEarningsCents,
    paidCreatorEarningsCents,
  } = collaboration

  const { id } = useParams()

  const [isCampaignSliderOpen, setIsCampaignSliderOpen] = useState(false)
  const [isCreateClipModalOpen, setIsCreateClipModalOpen] = useState(false)
  const [isViewClipsModalOpen, setIsViewClipsModalOpen] = useState(false)
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false)

  const RowButton = () => {
    switch (status) {
      // case CollaborationStatus.creatorAccepted:
      // case CollaborationStatus.productShipped:
      // case CollaborationStatus.productDelivered:
      //   return (
      //     <Button
      //       title='Upload Clip'
      //       type='button'
      //       colour={ButtonColor.primary}
      //       onClick={() => setIsCreateClipModalOpen(true)}
      //     />
      //   )
      case CollaborationStatus.contentUploaded:
      case CollaborationStatus.contentAccepted:
        return (
          <Button
            // title='Clips'
            type='button'
            colour={ButtonColor.secondary}
            onClick={() => setIsViewClipsModalOpen(true)}
            icon={
              <Icon
                type={IconType.filmThree}
                className='h-4 w-4 text-white'
                aria-hidden='true'
              />
            }
            iconPosition='left'
            tooltip='See videos you have uploaded for this campaign'
          />
        )
      case CollaborationStatus.creatorApplied:
        return (
          <Button
            title='Withdraw Application'
            type='button'
            colour={ButtonColor.primary}
            onClick={() => setIsWithdrawModalOpen(true)}
          />
        )
    }
  }

  const onProductShippingClick = () => {
    if (productTrackingLink) {
      window.open(productTrackingLink, '_blank')
    }
  }

  const getTotalCreatorEarningsText = () => {
    return formatPriceInCents({
      priceInCents: totalCreatorEarningsCents,
      currency: creatorEarningsCurrency,
    })
  }

  const getStatusText = () => {
    switch (status) {
      case CollaborationStatus.creatorAccepted:
        return `You need to shoot the video(s).`
      case CollaborationStatus.productShipped:
        return `You need to shoot the video(s).`
      case CollaborationStatus.productDelivered:
        return `You need to shoot the video(s).`
      case CollaborationStatus.contentUploaded:
        return <>Await brand approval before adding extras.</>

        return null
      case CollaborationStatus.contentAccepted:
        if (pendingUploadHooksCount > 0) {
          return (
            <>
              You have {pendingUploadHooksCount} variation
              {pendingUploadHooksCount > 1 ? 's' : ''} to upload.
            </>
          )
        }

        if (pendingUploadAddonsCount > 0) {
          return (
            <>
              You have {pendingUploadAddonsCount} addon
              {pendingUploadAddonsCount > 1 ? 's' : ''} to upload.
            </>
          )
        }

        const displayText = []
        if (collaboration.paidCreatorEarningsCents > 0) {
          displayText.push(
            <div>
              You have been paid{' '}
              {formatPriceInCents({
                priceInCents: collaboration.paidCreatorEarningsCents,
                currency: collaboration.creatorEarningsCurrency,
              })}
              .
            </div>,
          )
        }

        if (collaboration.pendingCreatorEarningsCents > 0) {
          displayText.push(
            <div>
              You have a payout of{' '}
              {formatPriceInCents({
                priceInCents: collaboration.pendingCreatorEarningsCents,
                currency: collaboration.creatorEarningsCurrency,
              })}{' '}
              pending.
            </div>,
          )
        }

        return <>{displayText.concat(' ')}</>
      case CollaborationStatus.creatorApplied:
      default:
        return null
    }
  }

  const getStatusComponent = () => {
    if (
      status === CollaborationStatus.contentAccepted &&
      pendingUploadHooksCount > 0
    ) {
      return (
        <Badge
          type={BadgeType.lime}
          iconPosition='left'
          text={
            <TooltipInfo
              valueKey='status-pendingUploadHooksCount'
              content={`Pending variations`}
              tooltipText='There are more variations required for this campaign. Each variation should be max 5 seconds different than the original video. The earnings include all variations to be uploaded.'
            />
          }
          size={BadgeSize.medium}
          className='!mr-0'
        />
      )
    }

    if (
      status === CollaborationStatus.contentAccepted &&
      pendingUploadAddonsCount > 0
    ) {
      return (
        <Badge
          type={BadgeType.lime}
          iconPosition='left'
          text={
            <TooltipInfo
              valueKey='status-pendingUploadAddonsCount'
              content={`Pending addons`}
              tooltipText='There are more addons required for this campaign. The earnings include all addons to be uploaded.'
            />
          }
          size={BadgeSize.medium}
          className='!mr-0'
        />
      )
    }

    return (
      <CollaborationStatusComponent
        status={status}
        onProductShippingClick={() => onProductShippingClick()}
      />
    )
  }

  return (
    <>
      <td className='hidden'>
        <CampaignSlider
          open={isCampaignSliderOpen}
          setOpen={setIsCampaignSliderOpen}
          campaign={campaign}
        />

        <ViewClipsSlider
          open={isViewClipsModalOpen}
          setOpen={setIsViewClipsModalOpen}
          collaboration={collaboration}
          onCreateClip={() => setIsCreateClipModalOpen(true)}
        />

        {isCreateClipModalOpen && (
          <CreateClipModal
            collaboration={collaboration}
            isOpen={isCreateClipModalOpen}
            setIsOpen={setIsCreateClipModalOpen}
          />
        )}
        <WithdrawFromCollaborationModal
          creator={creator}
          collaboration={collaboration}
          isOpen={isWithdrawModalOpen}
          setIsOpen={setIsWithdrawModalOpen}
        />
      </td>
      <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
        <div className='flex items-center'>
          <div className='p-1 mr-3 [&>*]:m-0.25 min-w-[50px]'>
            <Link
              to={CreatorRoutes.collaborationViewBriefDetails
                .replace(':id', `${creator.id}`)
                .replace(':uuid', uuid)}
            >
              <Avatar
                type='product'
                src={campaign.product.photo?.url}
                size='sm'
                alt={campaign.product.name}
              />
            </Link>
          </div>

          <div>
            <div>
              <Link
                to={CreatorRoutes.collaborationViewBriefDetails
                  .replace(':id', `${creator.id}`)
                  .replace(':uuid', uuid)}
                className='cursor-pointer flex gap-2 font-medium text-blue-600 hover:text-blue-500'
              >
                <div className='text-ellipsis overflow-hidden w-40 md:w-60'>
                  {campaign.product.name}
                </div>
              </Link>
            </div>

            <div className='truncate mt-1 mb-0 flex flex-shrink-0 text-gray-500'>
              {campaign.product.price === 0 &&
                campaign.product.type === ProductType.digital && (
                  <span>digital</span>
                )}
              {campaign.product.price > 0 && (
                <span>
                  worth {mapCurrencyToSign(campaign.product.currency)}
                  {campaign.product.price}
                </span>
              )}
              {isCampaignPremium(campaign) && (
                <Badge
                  type={BadgeType.amber}
                  text={`premium`}
                  size={BadgeSize.small}
                />
              )}
            </div>
          </div>
        </div>
      </td>

      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        <div>{getTotalCreatorEarningsText()} + free product</div>
        {hooksCount > 0 && (
          <div className='mt-1 mb-0'>
            <Badge
              type={BadgeType.indigo}
              iconPosition='left'
              text={
                <TooltipInfo
                  valueKey='hooks-info-badge'
                  content={`${collaboration?.hooksCount} variation${collaboration?.hooksCount > 1 ? 's' : ''} required`}
                  tooltipText='Each variation is an altered version of the original video, featuring a unique hook or call-to-action. The duration of each variation is up to 5 seconds.'
                />
              }
              size={BadgeSize.medium}
              className='!mr-0'
            />
          </div>
        )}
        {addonsCount > 0 && (
          <div className='mt-1 mb-0'>
            <Badge
              type={BadgeType.purple}
              iconPosition='left'
              text={
                <TooltipInfo
                  valueKey='addons-info-badge'
                  content={`${addonsCount} addon${collaboration?.addonsCount > 1 ? 's' : ''} required`}
                  tooltipText='Each addon is an altered version of the original video with captions, music or additional elements.'
                />
              }
              size={BadgeSize.medium}
              className='!mr-0'
            />
          </div>
        )}
      </td>

      {/* <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        <div>Total Earnings: {totalCreatorEarningsCents}</div>
        <div>Pending: {pendingCreatorEarningsCents}</div>
        <div>Paid: {paidCreatorEarningsCents}</div>
        <div>pendingUploadHooksCount: {pendingUploadHooksCount}</div>
        <div>pendingUploadHooksCount: {pendingUploadAddonsCount}</div>
        <div>hooks count: {hooksCount}</div>
        <div>addons count collaboration: {collaboration?.addons?.length}</div>
        <div>firstVersionClip: {collaboration?.firstVersionClip?.id ?? null}</div>
        <div>editedVersionClip: {collaboration?.editedVersionClip?.id ?? null}</div>
      </td> */}

      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        <div className='text-sm text-gray-500 ml-1'>{getStatusText()}</div>
        <div className='text-sm text-gray-500 ml-1'></div>
        <div className='mt-1'>{getStatusComponent()}</div>
      </td>

      <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 space-x-2'>
        {collaboration.isActive && (
          <Button
            type='button'
            colour={ButtonColor.white}
            to={
              CreatorRoutes.inboxChat
                .replace(':id', `${id}`)
                .replace(':uuid', `${campaign.brand.id}-${id}`) +
              '?collaborationId=' +
              collaboration.id
            }
            icon={
              <Icon
                type={IconType.inbox}
                className='h-4 w-4'
                aria-hidden='true'
              />
            }
            iconPosition='left'
            tooltip='Message brand about this campaign'
          />
        )}

        {/* <Button
          // title='Details'
          type='button'
          colour={ButtonColor.lightGray}
          onClick={() => setIsCampaignSliderOpen(true)}
          size={ButtonSize.medium}
          icon={
              <Icon
                type={IconType.details}
                className='h-4 w-4'
                aria-hidden='true'
              />
          }
          iconPosition='left'
        /> */}
        {RowButton()}
        {status !== CollaborationStatus.creatorApplied && (
          <Button
            title='Upload'
            type='button'
            colour={ButtonColor.primary}
            onClick={() => setIsCreateClipModalOpen(true)}
            size={ButtonSize.medium}
          />
        )}
      </td>
    </>
  )
}
