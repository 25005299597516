import isE164PhoneNumber from 'is-e164-phone-number'

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const cleanUrl = (website: string) => {
  website = website?.trim()

  if (website?.includes('http://')) {
    website = website.replace(new URL(website).protocol, 'https:')
  }

  if (!website?.includes('http://') && !website?.includes('https://')) {
    const protocol = 'https://'
    website = protocol.concat(website)
  }

  return website
}

export const removeProtocol = (url) => {
  if (!url) {
    return ''
  }

  return url.replace(/(^\w+:|^)\/\//, '')
}

// truncate function decimal places without rounding (index=number decimals)
export const truncate = (number: number, index: number) => {
  // cutting the number
  return +number
    .toString()
    .slice(0, number.toString().indexOf('.') + (index + 1))
}

export const capitalize = (string) => {
  if (!string) {
    return ''
  }

  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const formatLongMessage = (string) =>
  replaceURLs(string)?.replace(/\n/g, '<br />')

export const mergeRefs = (...refs: any) => {
  const filteredRefs = refs.filter(Boolean)
  if (!filteredRefs.length) {
    return null
  }
  if (filteredRefs.length === 0) {
    return filteredRefs[0]
  }

  return (inst: any) => {
    for (const ref of filteredRefs) {
      if (typeof ref === 'function') {
        ref(inst)
      } else if (ref) {
        ref.current = inst
      }
    }
  }
}

// https://www.cluemediator.com/find-urls-in-string-and-make-a-link-using-javascript
export const replaceURLs = (message) => {
  if (!message) return

  var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g
  return message.replace(urlRegex, function (url) {
    var hyperlink = url
    if (!hyperlink.match('^https?://')) {
      hyperlink = 'http://' + hyperlink
    }

    return (
      '<a href="' +
      hyperlink +
      '" target="_blank" rel="noopener noreferrer" title="' +
      url +
      '" class="text-blue-600 hover:text-blue-500 hover:underline">' +
      (url.length > 40 ? url.substring(0, 40) + '...' : url) +
      '</a>'
    )
  })
}

/**
 * compares two arrays and detects if its a deletion or an addition
 */
export const compareArrays = (
  oldArr: any[],
  newArr: any[],
): {
  type: 'addition' | 'deletion'
  item: any
} => {
  // Create a copy of the old array to modify
  const arr = [...oldArr]

  // Check for new additions
  for (const newItem of newArr) {
    const index = arr.findIndex((item) => item.value === newItem.value)

    if (index === -1) {
      return { type: 'addition', item: newItem }
    }

    arr.splice(index, 1)
  }

  // Check for deletions
  if (arr.length > 0) {
    return { type: 'deletion', item: arr[0] }
  }

  // If no changes detected, return null
  return null
}

export const findErrorMethodName = (e, errorCode) => {
  for (const error of e.graphQLErrors) {
    switch (error.code) {
      case errorCode: {
        return true
      }
    }
  }
  return false
}

export const validateE164PhoneNumber = (phoneNumber: string): boolean => {
  return isE164PhoneNumber(phoneNumber)
}

export const formatPhoneNumber = (phoneNumber: string): string => {
  return phoneNumber.replace(/[^\d+]/g, '')
}

export const extractBrandId = (url: string): string | null => {
  const regex = /\/b\/(\d+)\//
  const match = url.match(regex)
  return match ? match[1] : null
}
