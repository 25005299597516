import { useMemo } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import {
  Button,
  ButtonColor,
  CollaborationStatus as CollaborationStatusComponent,
  Icon,
  IconType,
  PageTabs,
} from '../../../../components'
import { CollaborationStatus } from '../../../../gql/types'
import { BrandRoutes } from '../../../BrandRoutes'
import { AcceptApplicantButton } from '../../../shared/AcceptApplicantButton'

const Header = ({ collaboration }) => {
  const { id, uuid } = useParams()
  const { pathname } = useLocation()

  const navigation = useMemo(
    () => [
      {
        text: 'Profile',
        count: 0,
        to: BrandRoutes.collaborationViewProfile
          .replace(':id', `${id}`)
          .replace(':uuid', uuid),
      },
      {
        text: 'Reviews',
        count: collaboration?.creator?.metadata?.ratingsCount,
        to: BrandRoutes.collaborationViewReviews
          .replace(':id', `${id}`)
          .replace(':uuid', uuid),
      },
      {
        text: 'Clips',
        count: 0,
        to: BrandRoutes.collaborationViewClips
          .replace(':id', `${id}`)
          .replace(':uuid', uuid),
      },
    ],
    [id],
  )

  const breadcrumb = useMemo(() => {
    if (pathname.includes('profile')) {
      return 'Profile'
    }

    if (pathname.includes('reviews')) {
      return 'Reviews'
    }

    if (pathname.includes('clips')) {
      return 'Clips'
    }

    return 'Details'
  }, [pathname])

  return (
    <div className='flex flex-col mt-4'>
      <nav className='flex' aria-label='Breadcrumb'>
        <ol role='list' className='flex items-center'>
          <li key='Campaigns'>
            <div className='flex items-center'>
              <Link
                to={BrandRoutes.campaigns.replace(':id', `${id}`)}
                className='text-sm font-medium text-gray-600 hover:text-gray-700'
              >
                Campaigns
              </Link>
            </div>
          </li>
          <li key={collaboration.campaign?.title}>
            <div className='flex items-center'>
              <Icon
                type={IconType.chevronRight}
                className='h-5 w-5 flex-shrink-0 text-gray-400'
                aria-hidden='true'
              />
              <Link
                to={BrandRoutes.campaignView
                  .replace(':id', `${id}`)
                  .replace(':campaignId', `${collaboration.campaign.id}`)}
                className='text-sm font-medium text-gray-600 hover:text-gray-700'
              >
                {collaboration.campaign?.title}
              </Link>
            </div>
          </li>
          <li key={breadcrumb}>
            <div className='flex items-center'>
              <Icon
                type={IconType.chevronRight}
                className='h-5 w-5 flex-shrink-0 text-gray-400'
                aria-hidden='true'
              />
              <div className='text-sm font-semibold text-gray-700 hover:text-gray-700'>
                {breadcrumb}
              </div>
            </div>
          </li>
        </ol>
      </nav>

      <div className='grid grid-cols-1 lg:flex lg:flex-row lg:justify-between mt-5'>
        <div className='col-span-1'>
          <div className='flex flex-row justify-between'>
            <h1 className='font-bold text-3xl font-outfit'>
              {collaboration.creator?.firstName}
            </h1>
            <div className='flex flex-row items-center pl-2 pt-[2px]'>
              <CollaborationStatusComponent status={collaboration.status} />
            </div>
          </div>
        </div>
        <div className='mt-4 lg:mt-0 flex flex-wrap'>
          {collaboration.isActive && (
            <Button
              title='Message'
              type='button'
              colour={ButtonColor.primary}
              className='flex-1'
              to={
                BrandRoutes.inboxChat
                  .replace(':id', `${id}`)
                  .replace(':uuid', `${id}-${collaboration.creator.id}`) +
                '?collaborationId=' +
                collaboration.id
              }
            />
          )}
          {collaboration.status === CollaborationStatus.creatorApplied && (
            <AcceptApplicantButton collaboration={collaboration} />
          )}
        </div>
      </div>

      {/* Navigation */}
      <PageTabs
        navigation={navigation}
        menuPosition='right'
        className='mt-4 md:mt-0'
      />
    </div>
  )
}

export { Header }
