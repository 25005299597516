import { gql } from '@apollo/client'
import { BrandFragment } from './brands'
import { CollaborationFragment } from './collaborations'
import { CreatorFragment } from './creators'
import { PaginationFragment } from './common'

export const RatingFragment = gql`
  fragment RatingFragment on Rating {
    id
    createdAt
    type
    stars
    review
    contentQualityRating
    professionalismRating
    communicationRating
    creativityRating
    timelinessRating
    adherenceToBriefRating
    creator {
      ...CreatorFragment
    }
    brand {
      ...BrandFragment
    }
  }
  ${CreatorFragment}
  ${BrandFragment}
`

export const GET_RATINGS = gql`
  query GetRatings($options: GetRatingsOptions!) {
    getRatings(options: $options) {
      items {
        ...RatingFragment
        creator {
          ...CreatorFragment
        }
        brand {
          ...BrandFragment
        }
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${RatingFragment}
  ${CreatorFragment}
  ${BrandFragment}
  ${PaginationFragment}
`

export const CREATE_RATING = gql`
  mutation CreateRating($input: CreateRatingInput!) {
    createRating(input: $input) {
      id
      createdAt
      stars
      review
      type
      creator {
        ...CreatorFragment
      }
      brand {
        ...BrandFragment
      }
      collaboration {
        ...CollaborationFragment
      }
    }
  }
  ${CreatorFragment}
  ${BrandFragment}
  ${CollaborationFragment}
`

export const UPDATE_RATING = gql`
  mutation UpdateRating($id: Int!, $input: UpdateRatingInput!) {
    updateRating(id: $id, input: $input) {
      ...RatingFragment
    }
  }
  ${RatingFragment}
`
