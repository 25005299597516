export enum Params {
  deal = 'deal',
  promo = 'promo',
  gclid = 'gclid',
  fbclid = 'fbclid',
  utm_source = 'utm_source',
  utm_medium = 'utm_medium',
  utm_campaign = 'utm_campaign',
  utm_term = 'utm_term',
  utm_content = 'utm_content',
}

export const captureQueryParams = (searchParams) => {
  Object.keys(Params).forEach((param) => {
    const paramValue = searchParams.get(param)
    if (paramValue) {
      localStorage.setItem(Params[param], paramValue)
    }
  })
}
