import { Controller } from 'react-hook-form'
import { Select, TextField } from '../../../../components'
import { Industry } from '../../../../gql/types'
import { capitalize } from '../../../../utils/helpers'

interface Props {
  errors: any
  control: any
}

const SetIndustry = ({ errors, control }: Props) => {
  return (
    <div>
      <div className='mt-10 sm:mt-0'>
        <div className='md:grid md:grid-cols-3 md:gap-6'>
          <div className='md:col-span-1'>
            <div className=''>
              <h3 className='text-lg font-semibold font-sans leading-6 text-gray-900'>
                Industry
              </h3>
              <p className='mt-1 text-sm font-normal leading-5 text-gray-500 font-sans'>
                The industry of the product / brand presented in the video?
              </p>
            </div>
          </div>
          <div className='mt-5 md:col-span-2 md:mt-0'>
            <div className='md:shadow rounded-t-md'>
              <div className='py-5 md:bg-white space-y-6 sm:p-6'>
                <div className='grid gap-6'>
                  <div className='col-span-3 sm:col-span-2'>
                    <Controller
                      name='industry'
                      control={control}
                      rules={{ required: `Please select an industry.` }}
                      render={({ field }) => (
                        <Select
                          // options={Object.keys(Industry)}
                          options={Object.values(Industry).map((industry) =>
                            capitalize(industry),
                          )}
                          field={field}
                          label='Industry'
                          error={errors?.industry?.message}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='hidden sm:block' aria-hidden='true'>
        <div className='py-5'>
          <div className='border-t border-gray-200' />
        </div>
      </div>
    </div>
  )
}

export { SetIndustry }
