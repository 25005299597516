import { TextArea } from '../../../../components'

interface Props {
  errors: any
  register: any
}

const EditRequirements = ({ errors, register }: Props) => {
  return (
    <div>
      <div className='mt-10 sm:mt-0'>
        <div className='md:grid md:grid-cols-3 md:gap-6'>
          <div className='md:col-span-1'>
            <div className=''>
              <h3 className='text-lg font-semibold font-normal font-sans leading-6 text-gray-900'>
                Mentions & Scenes
              </h3>
              <p className='mt-1 text-sm font-normal leading-5 text-gray-500 font-sans'>
                Anything specific creators should mention? Try to think about
                the goal of your marketing campaign.
              </p>
              <p className='mt-1 text-sm font-normal leading-5 text-gray-500 font-sans'>
                If you have any specific scenes in mind for your video, you can
                add them here.
              </p>
              <p className='mt-1 text-sm font-normal leading-5 text-gray-500 font-sans'>
                Anything else the creator should consider?
              </p>
            </div>
          </div>
          <div className='mt-5 md:col-span-2 md:mt-0'>
            <div className='md:shadow rounded-t-md sm:overflow-hidden'>
              <div className='py-5 md:bg-white space-y-6 sm:p-6'>
                <div className='grid gap-6'>
                  <div className='col-span-3 sm:col-span-2'>
                    <TextArea
                      error={errors.requirements?.message}
                      label='Talking points'
                      placeholder='Mentions, scenes or other requirements'
                      {...register('requirements', {
                        maxLength: {
                          value: 10000,
                          message: `Your requirements text is too long`,
                        },
                      })}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='hidden sm:block' aria-hidden='true'>
        <div className='py-5'>
          <div className='border-t border-gray-200' />
        </div>
      </div>
    </div>
  )
}

export { EditRequirements }
