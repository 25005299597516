import { Transition, Dialog } from '@headlessui/react'
import { Fragment } from 'react'
import { Icon, IconType } from '..'
import { Creator } from '../../gql/types'
import { Avatar } from '../../elements'
import { PortfolioGridNew } from '../PortfolioGridNew'
import { Divider } from '../Divider'

interface IProps {
  creator: Creator
  open: boolean
  setOpen: any
}

const PortfolioQuickView = ({ creator, open, setOpen }: IProps) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={setOpen}>
        <div className='fixed inset-0' />

        <div className='fixed inset-0 overflow-hidden'>
          <div className='absolute inset-0 overflow-hidden'>
            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16'>
              <Transition.Child
                as={Fragment}
                enter='transform transition ease-in-out duration-500 sm:duration-700'
                enterFrom='translate-x-full'
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-500 sm:duration-700'
                leaveFrom='translate-x-0'
                leaveTo='translate-x-full'
              >
                <Dialog.Panel className='pointer-events-auto w-screen max-w-2xl'>
                  <div className='flex h-full flex-col overflow-y-scroll bg-white shadow-xl'>
                    {/* Header */}
                    <div className='sm:sticky top-0 z-10 bg-white'>
                      <div className='px-4 pt-6 sm:px-6'>
                        <div className='flex items-start justify-between'>
                          <Avatar
                            type='user'
                            src={creator.avatar?.url}
                            size='md'
                            alt={creator.firstName}
                          />
                          <div className='ml-3 flex h-7 items-center'>
                            <button
                              type='button'
                              className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none'
                              onClick={() => setOpen(false)}
                            >
                              <span className='sr-only'>Close panel</span>
                              <Icon
                                type={IconType.x}
                                className='h-6 w-6'
                                aria-hidden='true'
                              />
                            </button>
                          </div>
                        </div>
                        <h3 className='text-xl font-bold text-gray-900 sm:text-2xl mt-6'>
                          {creator?.firstName}
                        </h3>
                      </div>
                      <Divider className='py-6' />
                    </div>
                    {/* Main */}
                    <div className='pb-1 sm:pb-3 px-2 sm:px-4 space-y-2 mt-5 overflow-auto'>
                      <PortfolioGridNew creator={creator} useWindow={false} />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export { PortfolioQuickView }
