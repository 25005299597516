import { FunctionComponent, useState } from 'react'
import { replaceURLs } from '../../../utils/helpers'
import { Avatar } from '../../../elements'
import { MessageCheckedStatus } from '../../../gql/types'
import { Badge, BadgeStyle, BadgeType, Icon, IconType, InfoModal } from '../..'
import classNames from 'classnames'

interface IDefaultProps {}

interface IProps extends Partial<IDefaultProps> {
  author: {
    name: string
    avatar: any
  }
  datetime: string
  message: string
  checkedStatus: MessageCheckedStatus
  userIsAuthor: boolean
  adminMessage?: string
  isEditRequest: boolean
}

const ChatMessage: FunctionComponent<IProps> = ({
  author,
  datetime,
  message,
  checkedStatus,
  userIsAuthor,
  adminMessage,
  isEditRequest,
}) => {
  const [isPendingApprovalInfoOpen, setIsPendingApprovalInfoOpen] =
    useState(false)
  const [isWarningInfoOpen, setIsWarningInfoOpen] = useState(false)
  const [isInfoMessageOpen, setIsInfoMessageOpen] = useState(false)

  if (!userIsAuthor && checkedStatus !== MessageCheckedStatus.ok) {
    return null
  }

  return (
    <>
      {isPendingApprovalInfoOpen && (
        <InfoModal
          title='Pending approval'
          children={
            <div>
              <p className='text-sm text-gray-500 mt-2 text-left'>
                To keep the integrity of our platform and a fair exchange
                between brands and creators we moderate all messages on the
                platform.
              </p>
              <ul className='list-disc text-sm text-gray-500 space-y-2 mt-2 ml-6 text-left'>
                <li>
                  The Clip team verifies all messages; off-platform contact and
                  additional payment requests are banned to maintain secure,
                  traceable transactions.
                </li>
                <li>
                  Any attempts to share contact details or settle payments
                  outside the platform will lead to a warning, and repeat
                  offenders may be blocked.
                </li>
                <li>
                  Brands are entitled to request one round of revisions to
                  ensure satisfaction while maintaining a realistic scope of
                  work for creators.
                </li>
              </ul>
              <p className='text-sm text-gray-500 mt-2 text-left'>
                For issues, disputes, or clarifications, please contact the Clip
                team directly at{' '}
                <a href='mailto:hello@useclip.com'>hello@useclip.com</a>.
              </p>
            </div>
          }
          open={isPendingApprovalInfoOpen}
          setOpen={setIsPendingApprovalInfoOpen}
          icon={
            <Icon type={IconType.info} className='w-6 h-6' aria-hidden='true' />
          }
        />
      )}
      {isWarningInfoOpen && (
        <InfoModal
          title={`We couldn't send your message`}
          children={
            <div>
              <p className='text-sm text-gray-500 mt-2 text-left'>
                Your message is against our policies and it couldn't be
                processed.
              </p>
              <ul className='list-disc text-sm text-gray-500 space-y-2 mt-2 ml-6 text-left'>
                <li>
                  The Clip team verifies all messages; off-platform contact and
                  additional payment requests are banned to maintain secure,
                  traceable transactions.
                </li>
                <li>
                  Any attempts to share contact details or settle payments
                  outside the platform will lead to a warning, and repeat
                  offenders may be blocked.
                </li>
                <li>
                  Brands are entitled to request one round of revisions to
                  ensure satisfaction while maintaining a realistic scope of
                  work for creators.
                </li>
              </ul>
              <p className='text-sm text-gray-500 mt-2 text-left'>
                For clarifications, please contact the Clip team directly at{' '}
                <a href='mailto:hello@useclip.com'>hello@useclip.com</a>.
              </p>
            </div>
          }
          open={isWarningInfoOpen}
          setOpen={setIsWarningInfoOpen}
          icon={
            <Icon
              type={IconType.warning}
              className='text-red-700 w-6 h-6'
              aria-hidden='true'
            />
          }
        />
      )}
      {isInfoMessageOpen && (
        <InfoModal
          title='Info message'
          children={
            <div
              className='mt-4 space-y-6 text-sm text-gray-800 text-left'
              dangerouslySetInnerHTML={{
                __html: replaceURLs(adminMessage).replace(/\n/g, '<div></div>'),
              }}
            />
          }
          open={isInfoMessageOpen}
          setOpen={setIsInfoMessageOpen}
          icon={
            <Icon type={IconType.info} className='w-6 h-6' aria-hidden='true' />
          }
        />
      )}
      <div
        className={classNames(
          'bg-white px-4 py-6 shadow sm:rounded-lg sm:px-6',
          {
            'border border-primary-700': isEditRequest,
          },
        )}
      >
        <div className='sm:flex sm:items-baseline sm:justify-between'>
          <h3 className='text-base w-full font-medium flex flex-row items-center'>
            <div className='h-9 w-9 flex-shrink-0 relative'>
              <Avatar
                className='h-9 w-9'
                size='xs'
                src={author.avatar?.url}
                type='user'
                alt='Logo'
              />
            </div>
            <div className='w-full flex flex-row items-center justify-between'>
              <div className='text-gray-900 pl-3 text-sm'>{author.name}</div>
              <p className='mt-1 whitespace-nowrap text-sm text-gray-600 sm:mt-0 sm:ml-3'>
                <time dateTime={datetime}>{datetime}</time>
              </p>
            </div>
          </h3>
        </div>
        {isEditRequest && (
          <div className='mt-4 text-sm text-primary-700 font-semibold'>
            🧑‍🎨 This is a Clip edit request.
          </div>
        )}
        <div
          className='mt-4 space-y-6 text-sm text-gray-800'
          dangerouslySetInnerHTML={{
            __html: replaceURLs(message).replace(/\n/g, '<div></div>'),
          }}
        />
        {userIsAuthor && checkedStatus === MessageCheckedStatus.pending && (
          <div className='mt-4 flex items-center'>
            <Badge
              type={BadgeType.warning}
              text='Pending approval from the Clip team'
              style={BadgeStyle.fill}
              iconType={IconType.info}
              iconPosition='right'
              onClick={() => setIsPendingApprovalInfoOpen(true)}
              className='cursor-pointer'
            />
          </div>
        )}
        {userIsAuthor && checkedStatus === MessageCheckedStatus.flagged && (
          <div className='mt-4 flex items-center'>
            <Badge
              type={BadgeType.error}
              text={`Warning - your message is against our policies`}
              style={BadgeStyle.fill}
              iconType={IconType.warning}
              iconPosition='right'
              onClick={() => setIsWarningInfoOpen(true)}
              className='cursor-pointer'
            />
          </div>
        )}
        {userIsAuthor && checkedStatus === MessageCheckedStatus.info && (
          <div className='mt-4 flex items-center'>
            <Badge
              type={BadgeType.blue}
              text='Please send a revised message.'
              style={BadgeStyle.fill}
              iconType={IconType.info}
              iconPosition='right'
              onClick={() => setIsInfoMessageOpen(true)}
              className='cursor-pointer'
            />
          </div>
        )}
      </div>
    </>
  )
}

export { ChatMessage }
