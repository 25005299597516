import { Menu, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { Fragment, ReactNode, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Icon, IconType } from '../Icon'

interface IProps {
  title: ReactNode
  menuPosition?: 'left' | 'right' | 'top' | 'top-left'
  options: any
  fullWidth?: boolean
  className?: string
  dropdownClassName?: string
  isArrowInvisible?: boolean
  isSelectedItemTitle?: boolean
}

const Dropdown = ({
  title,
  menuPosition,
  options,
  fullWidth,
  className,
  dropdownClassName,
  isArrowInvisible,
  isSelectedItemTitle,
}: IProps) => {
  // default right
  let menuPositionClassName = dropdownClassName ?? 'right-0'
  const { pathname } = useLocation()
  const item = options.find((item: any) => item.to === pathname)
  const [selectedItem, setSelectedItem] = useState<string | null>(
    typeof title === 'string' && item?.text ? item.text : null,
  )

  if (!dropdownClassName && menuPosition === 'right') {
    menuPositionClassName = 'left-0'
  }

  if (!dropdownClassName && menuPosition === 'top') {
    menuPositionClassName = 'bottom-16'
  }

  if (menuPosition === 'top-left') {
    menuPositionClassName = 'bottom-10 right-0'
  }

  // console.log(
  //   'menuPositionClassName',
  //   title,
  //   menuPositionClassName,
  //   dropdownClassName,
  // )

  if (options?.[0]?.text) {
    options = [
      {
        section: '1',
        items: options,
      },
    ]
  }

  return (
    <Menu
      as='div'
      className={classNames(
        'relative inline-block text-left',
        { 'w-full': fullWidth },
        className,
      )}
    >
      <div>
        <Menu.Button className={classNames({ 'w-full': fullWidth })}>
          {typeof title === 'string' && (
            <div className='inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none'>
              {isSelectedItemTitle ? selectedItem ?? title : title}
              {!isArrowInvisible && (
                <Icon
                  type={IconType.chevronDown}
                  className='-mr-1 ml-2 w-5 h-full'
                  aria-hidden='true'
                />
              )}
            </div>
          )}
          {typeof title !== 'string' && (
            <div className='inline-flex w-full justify-center bg-white'>
              {title}
              {!isArrowInvisible && (
                <Icon
                  type={IconType.chevronDown}
                  // className='-mr-1 ml-2 w-5 h-full'
                  className='-mr-1 w-4 h-full'
                  aria-hidden='true'
                />
              )}
            </div>
          )}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        {/* right-0 / left-0 / -translate-y-full (up) */}
        <Menu.Items
          className={classNames(
            'absolute z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-xl bg-white shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none',
            menuPositionClassName,
          )}
        >
          {options.map((section, index) => {
            if (!section.items || section.items.length === 0) {
              return null
            }

            return (
              <div className='py-1' key={index}>
                {section.items?.map((item, index) => (
                  <Menu.Item key={index}>
                    {({ active }) => (
                      <Link
                        to={item.to}
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'group flex items-center px-4 py-2 text-sm',
                        )}
                        onClick={() => {
                          if (typeof title === 'string' && item?.text) {
                            setSelectedItem(item.text)
                          }
                          item.onClick && item.onClick()
                        }}
                      >
                        {item.icon && (
                          <Icon
                            type={item.icon}
                            className='mr-3 h-4 w-4 text-gray-400 group-hover:text-gray-500'
                            aria-hidden='true'
                          />
                        )}
                        {item.text}
                      </Link>
                    )}
                  </Menu.Item>
                ))}
              </div>
            )
          })}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export { Dropdown }
