import { useEffect } from 'react'
import { Creator } from '../../../gql/types'
import LOGO from '../../../assets/logo/lancape_no_bg.png'
import { AnalyticsEvent, trackEvent } from '../../../utils/analytics'

interface Props {
  creator: Creator
}

const ApplicationRejected = ({ creator }: Props) => {
  useEffect(() => {
    trackEvent(AnalyticsEvent.viewCreatorsOnboardingApplicationRejected)
  }, [])

  return (
    <div className='min-h-screen flex flex-col'>
      <div className='flex-grow'>
        <div className='min-h-full flex flex-col justify-center pt-4 px-4 md:mt-8 lg:px-8'>
          <div className='sm:mx-auto sm:w-full sm:max-w-2xl'>
            <div className='lg:col-span-6 lg:block xl:col-span-2 pb-14'>
              <div className='space-y-6 sm:p-6'>
                <img className='h-12 w-auto' src={LOGO} alt='Workflow' />
                <div className='sm:mx-auto'>
                  <h2 className='mt-0 text-left text-3xl font-extrabold text-gray-900'>
                    We are unable to approve your application at this time
                  </h2>
                  <p className='mt-4 text-left text-sm text-gray-600'>
                    Thank you for applying to join Clip, where we connect
                    talented video creators with brands.
                  </p>
                  <p className='mt-4 text-left text-sm text-gray-600'>
                    After a thorough review of your application, we regret to
                    inform you that we cannot approve your account at this time.
                    The introduction video or other details provided in your
                    submission do not adhere to our guidelines.
                  </p>
                  <p className='mt-4 text-left text-sm text-gray-600'>
                    We understand that this may be disappointing, but we
                    encourage you to reapply with an updated introduction video
                    and/or corrected account details that meet our platform's
                    requirements. Please review our guidelines carefully to
                    ensure your submission is in compliance.
                  </p>
                  <p className='mt-4 text-left text-sm text-gray-600'>
                    If you would like to reapply, kindly email us at{' '}
                    <a
                      href='mailto:hello@useclip.com'
                      className='text-blue-600 hover:text-blue-500'
                    >
                      hello@useclip.com
                    </a>{' '}
                    with your new introduction video that follows our
                    guidelines, and we will be happy to reassess your
                    application.
                  </p>
                  <p className='mt-4 text-left text-sm text-gray-600'>
                    We appreciate your interest in Clip and look forward to the
                    opportunity to review your updated application.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { ApplicationRejected }
