import { useMutation, useQuery } from '@apollo/client'
import axios from 'axios'
import { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Loader, NotificationType, VideoUpload } from '../../components'
import {
  CREATE_INSPIRATION,
  GET_BRAND_BY_ID,
  GET_INSPIRATIONS_BY_BRAND_ID,
  GET_INSPIRATION_UPLOAD_URL,
} from '../../gql'
import {
  AgeGroups,
  Ethnicity,
  Gender,
  GetInspirationsSort,
  Industry,
  InspirationType,
  ModelType,
  Platform,
  ProductType,
  User,
  VideoType,
} from '../../gql/types'
import { useNotification } from '../../utils/hooks'
import { BrandRoutes } from '../BrandRoutes'
import {
  SetDuration,
  SetIndustry,
  SetIsTextOver,
  SetIsVoiceOver,
  SetModelType,
  SetVideoType,
} from './components'
import { SetPermission } from './components/SetPermission'

interface GetInspirationUploadUrl {
  getInspirationUploadUrl: {
    inspirationUploadUrl: string
    fileName: string
  }
}

const extractData = (
  data?: GetInspirationUploadUrl,
): {
  inspirationUploadUrl: string
  fileName: string
} => {
  return data?.getInspirationUploadUrl
}

export type CreateInspirationFormData = {
  type: InspirationType
  externalUrl: string
  brandName: string
  videoType: VideoType
  source: Platform
  productType: ProductType
  model: ModelType
  gender: Gender
  ageGroups: AgeGroups | null
  ethnicity: Ethnicity
  industry: Industry
  duration: number
  isTextOver: string
  isVoiceOver: string
  tags: string
  isAllVisible: boolean
}

interface IProps {
  user: User
}

const NewInspiration = ({ user }: IProps) => {
  const { id } = useParams()
  const { addNotification } = useNotification()
  const inputRef = useRef(null)
  const navigate = useNavigate()

  const [loadingUpload, setLoadingUpload] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [errorFileName, setErrorFileName] = useState<string | null>(null)
  const [mime, setMime] = useState('')
  const [contentDimensions, setContentDimensions] = useState<{
    w: number | null
    h: number | null
  }>({ w: null, h: null })

  const { loading, error, data } = useQuery(GET_BRAND_BY_ID, {
    variables: {
      isAllVisible: true,
      id: Number(id),
    },
  })

  const [getInspirationUploadUrl] = useMutation(GET_INSPIRATION_UPLOAD_URL)
  const [createInspiration] = useMutation(CREATE_INSPIRATION, {
    refetchQueries: [
      {
        query: GET_INSPIRATIONS_BY_BRAND_ID,
        variables: {
          brandId: Number(id),
          options: {
            query: '',
            filters: {},
            sort: GetInspirationsSort.lastUpdated,
            skip: 0,
            limit: 10,
          },
        },
      },
    ],
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      type: null,
      // externalUrl: null,
      // brandName: '',
      videoType: null,
      // source: null,
      // productType: null,
      model: '',
      // gender: null,
      // ageGroups: null,
      // ethnicity: null,
      industry: null,
      duration: 0,
      isTextOver: null,
      isVoiceOver: null,
      // tags: '',
      isAllVisible: true,
    },
  })

  const handleFileInput = (
    file: File,
    size?: {
      w: number | null
      h: number | null
    },
  ) => {
    if (!file) {
      setSelectedFile(null)
      setMime('')
      return
    }

    setSelectedFile(file)
    setMime(file.type)
    setErrorFileName(null)
    setContentDimensions(size)
  }

  const onSubmit = async (
    data: CreateInspirationFormData,
    event: React.MouseEvent,
  ) => {
    event.preventDefault()

    if (!selectedFile) {
      setErrorFileName('Please upload a video')
      return
    }

    setErrorFileName(null)
    setLoadingUpload(true)

    try {
      //make sure to call GET_INSPIRATION_UPLOAD_URL every time when uploading a new file
      const dataGetInspirationUploadUrl = await getInspirationUploadUrl({
        variables: { mime },
      })

      const { inspirationUploadUrl, fileName } = extractData(
        dataGetInspirationUploadUrl.data,
      )

      // push inspiration to S3
      await axios.put(inspirationUploadUrl, selectedFile, {
        headers: {
          'Content-Type': mime,
        },
      })

      const input = {
        type: data?.type ? data.type : InspirationType.video,
        fileName: fileName,
        // externalUrl: data.externalUrl,
        // brandName: data.brandName,
        videoType: data.videoType,
        // source: data.source,
        // productType: data.productType,
        model: data.model,
        // gender: genderLocalizedStringInverse(data.gender),
        // ageGroups: data.ageGroups,
        // ethnicity: ethnicityLocalizedStringInverse(data.ethnicity),
        industry: data.industry.toLowerCase(),
        duration: Number(data.duration),
        isTextOver: data.isTextOver === 'TRUE' ? true : false,
        isVoiceOver: data.isVoiceOver === 'TRUE' ? true : false,
        // tags: data.tags.toLowerCase().split(','),
        isAllVisible: data.isAllVisible,
        brandId: brand?.id ?? null,
        width: contentDimensions.w,
        height: contentDimensions.h,
      }

      // save inspiration to data base
      await createInspiration({
        variables: {
          input,
        },
      })

      setSelectedFile(null)
      setLoadingUpload(false)
      reset()

      addNotification(`Inspiration created.`, NotificationType.success)
      navigate(BrandRoutes.inspiration.replace(':id', `${brand.id}`))
    } catch (error) {
      addNotification(
        `Something went wrong. Please try again.`,
        NotificationType.error,
      )
    }
  }

  if (loading) {
    return <Loader />
  }

  if (error) {
    console.log('error GET_BRAND_BY_ID', error)
    return <p>There has been an error. Try refreshing the page</p>
  }

  const brand = data?.getBrandById

  return (
    <>
      <div className='pb-8'>
        <div className='sm:flex-auto py-4 -mx-4 sm:mx-0'>
          <div className='flex flex-col'>
            <h1 className='font-bold text-3xl font-outfit'>
              Add inspiration video
            </h1>
            <p className='mt-1 text-sm text-gray-500'>
              Add your own inspiration video to use as examples and illustrate
              what you want for your campaign.
            </p>
          </div>
        </div>
        <div className='hidden sm:block' aria-hidden='true'>
          <div className='border-t border-gray-200' />
        </div>
        <div className='-mx-8 xs:-mt-8 xs:-mx-8'>
          <div className='flex flex-1 flex-col'>
            <form onSubmit={handleSubmit(onSubmit)} className='space-y-6'>
              <div className='pt-6'>
                <div className='mx-auto max-w-7xl px-4 sm:px-6 md:px-8'>
                  {/* SetInspirationVideo */}
                  <div>
                    <div className='mt-10 sm:mt-0'>
                      <div className='md:grid md:grid-cols-3 md:gap-6'>
                        <div className='md:col-span-1'>
                          <div className=''>
                            <h3 className='text-lg font-semibold font-sans leading-6 text-gray-900'>
                              Video
                            </h3>
                            <p className='mt-1 text-sm font-normal leading-5 text-gray-500 font-sans'>
                              Upload your video.
                            </p>
                          </div>
                        </div>
                        <div className='mt-5 md:col-span-2 md:mt-0'>
                          <div className='md:shadow rounded-t-md sm:overflow-hidden'>
                            <div className='py-5 md:bg-white space-y-6 sm:p-6'>
                              <div className='grid gap-6'>
                                <div className='col-span-3 sm:col-span-2'>
                                  <VideoUpload onChange={handleFileInput} />
                                  {errorFileName && (
                                    <p className='text-sm text-red-500 mt-2'>
                                      Please upload a video.
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='hidden sm:block' aria-hidden='true'>
                      <div className='py-5'>
                        <div className='border-t border-gray-200' />
                      </div>
                    </div>
                  </div>

                  <SetDuration errors={errors} register={register} />
                  <SetVideoType control={control} errors={errors} />
                  <SetModelType control={control} errors={errors} />
                  <SetIndustry control={control} errors={errors} />
                  <SetIsTextOver control={control} errors={errors} />
                  <SetIsVoiceOver control={control} errors={errors} />
                  <SetPermission
                    errors={errors}
                    watch={watch}
                    register={register}
                  />
                </div>
              </div>
              <div className='text-right px-4 sm:px-8'>
                <Button
                  title='Create inspiration video'
                  type='submit'
                  loading={loadingUpload}
                  disabled={loadingUpload}
                  onClick={() => {
                    if (selectedFile === null) {
                      setErrorFileName('Please upload a video')
                    }
                  }}
                />
                {loadingUpload && (
                  <p className='text-sm text-gray-500 mt-4'>
                    Video is uploading. Please allow up to 2 minutes for it to
                    upload.
                  </p>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export { NewInspiration }
