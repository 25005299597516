import { useMutation, useQuery } from '@apollo/client'
import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react'
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import {
  GET_MESSAGES_BY_THREAD,
  GET_MESSAGES_THREADS,
  GET_MESSAGE_THREAD_BY_UUID,
  GetMessageThreadByUuidResponse,
  GetMessagesByThreadResponse,
  MARK_ALL_MESSAGES_AS_READ_BY_CREATOR,
  MARK_THREAD_MESSAGES_AS_READ_BY_CREATOR,
} from '../../gql/messages'
import {
  GetMessagesSort,
  GetMessagesThreadsSort,
  Message,
  MessageType,
  QueryGetMessageThreadByUuidArgs,
  QueryGetMessagesByThreadArgs,
  ThreadStatus,
} from '../../gql/types'
import { Header } from './components/Header'
import classNames from 'classnames'
import { Loader, NotificationType } from '../../components'
import { ChatInput, ChatMembersList, ChatMessage } from '../../components/Chat'
import { useNotification } from '../../utils/hooks'
import moment from 'moment'
import { CreatorRoutes } from '../CreatorRoutes'
import { PricingData } from '../../utils/PricingUtil'

interface IProps {
  pricing: PricingData
}

const Inbox: FunctionComponent<IProps> = ({ pricing }) => {
  const { addNotification } = useNotification()
  const messagesEndRef = useRef(null)

  const { id, uuid } = useParams()

  const [query, setQuery] = useState('')
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const filtersDefault = {
    creatorId: parseInt(id),
    brandId: null,
    status: [ThreadStatus.active],
  }
  const sortDefault = GetMessagesThreadsSort.lastUpdated

  const [filters, setFilters] = useState(filtersDefault)
  const [sort, setSort] = useState<GetMessagesThreadsSort>(
    (searchParams.get('sort') as GetMessagesThreadsSort) || sortDefault,
  )
  const resetFilters = () => {
    setSort(sortDefault)
    setFilters(filtersDefault)
  }

  useEffect(() => {
    searchParams.set('sort', sort)
    setSearchParams(searchParams)
  }, [sort])

  const {
    loading: threadLoading,
    error: threadError,
    data: thread,
    refetch: refetchThread,
  } = useQuery<GetMessageThreadByUuidResponse, QueryGetMessageThreadByUuidArgs>(
    GET_MESSAGE_THREAD_BY_UUID,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        uuid,
      },
      skip: !uuid,
    },
  )

  const activeThread = useMemo(() => {
    if (thread && thread?.getMessageThreadByUuid) {
      return thread.getMessageThreadByUuid
    }

    return null
  }, [thread])

  const [threadsSkip, setThreadsSkip] = useState(0)
  const THREADS_LIMIT = 20

  const {
    loading: threadsLoading,
    error: threadsError,
    data: threads,
    refetch: refetchMessageThreads,
  } = useQuery(GET_MESSAGES_THREADS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      options: {
        query,
        filters: {
          ...filters,
        },
        sort,
        skip: threadsSkip,
        limit: THREADS_LIMIT,
      },
    },
  })

  const fetchMoreThreads = () => {
    if (!threadsLoading) {
      setThreadsSkip(threadsSkip + THREADS_LIMIT)
    }
  }

  useEffect(() => {
    setThreadsSkip(0)
  }, [query, filters, sort])

  const {
    loading: messagesLoading,
    data: messages,
    refetch: refetchMessages,
  } = useQuery<GetMessagesByThreadResponse, QueryGetMessagesByThreadArgs>(
    GET_MESSAGES_BY_THREAD,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        threadId: activeThread?.id,
        options: {
          sort: GetMessagesSort.oldest,
          skip: 0,
          limit: 100,
        },
      },
      skip: !activeThread,
    },
  )

  const [markThreadMessagesAsReadByCreator] = useMutation(
    MARK_THREAD_MESSAGES_AS_READ_BY_CREATOR,
  )

  useEffect(() => {
    if (activeThread?.uuid) {
      markThreadMessagesAsReadByCreator({
        variables: {
          uuid: activeThread?.uuid,
        },
      }).catch((error) => console.log(error))
    }
  }, [activeThread?.uuid])

  const [markAllMessagesAsReadByCreator] = useMutation(
    MARK_ALL_MESSAGES_AS_READ_BY_CREATOR,
  )

  const onMarkAllRead = async () => {
    try {
      await markAllMessagesAsReadByCreator({
        variables: {
          id: parseInt(id),
        },
      })

      addNotification('All messages marked as read', NotificationType.success)
    } catch (error) {
      addNotification(
        `Something went wrong. Please try again.`,
        NotificationType.error,
      )
    }
  }

  const onSearch = (query: string) => {
    setQuery(query)
  }

  if (threadsError) {
    return <p className='p-4'>Something went wrong. Please navigate back.</p>
  }

  if (!id) {
    return <Loader />
  }

  const renderMessage = (message: Message) => {
    let name,
      avatar,
      userIsAuthor,
      checkedStatus = message.checkedStatus,
      text = message.message,
      adminMessage = message.adminMessage
    switch (message.type) {
      case MessageType.brandToCreator:
        name = activeThread?.brand?.name
        avatar = activeThread?.brand?.logo
        userIsAuthor = false
        break
      case MessageType.creatorToBrand:
        name = `${activeThread?.creator?.firstName}`
        avatar = activeThread?.creator?.avatar
        userIsAuthor = true
        break
      default:
        name = 'Clip'
        avatar = `https://d1td5d11gfjk4b.cloudfront.net/logo/logo.png`
    }
    const author = {
      name,
      avatar,
      userIsAuthor,
    }
    return (
      <div key={message.uuid}>
        <ChatMessage
          author={author}
          datetime={
            message.createdAt
              ? moment(message.createdAt).format('ll LT')
              : undefined
          }
          message={text}
          checkedStatus={checkedStatus}
          userIsAuthor={userIsAuthor}
          adminMessage={adminMessage}
          isEditRequest={message.isEditClipRequest}
        />
      </div>
    )
  }

  const renderChat = () => {
    if (!activeThread) {
      return null
    }

    return (
      <div className='flex flex-col w-full justify-between relative'>
        <Header
          className='p-4 bg-gray-50'
          creator={activeThread?.creator}
          brand={activeThread?.brand}
          pricing={pricing}
        />

        {messagesLoading ? (
          <div className='flex-1 flex justify-center items-center'>
            <Loader />
          </div>
        ) : (
          <>
            <div className='flex flex-1 pb-48 lg:pb-64 flex-col-reverse overflow-y-auto no-scrollbar no-scrollbar::-webkit-scrollbar py-4'>
              <div className='px-4 sm:px-6 '>
                <ul className='space-y-4'>
                  {messages?.getMessagesByThread.items.map((message: Message) =>
                    renderMessage(message),
                  )}
                  {messages?.getMessagesByThread.items.length === 0 && (
                    <p className='text-center text-gray-500'>
                      No messages yet. Start the conversation!
                    </p>
                  )}
                </ul>
              </div>

              <div ref={messagesEndRef} />
            </div>
            <ChatInput
              profilePicUrl={activeThread?.brand?.logo?.url}
              creatorId={activeThread?.creator?.id}
              brandId={activeThread?.brand?.id}
              type={MessageType.creatorToBrand}
              threadId={activeThread?.id}
            />
          </>
        )}
      </div>
    )
  }

  return (
    <div className='w-full h-full overflow-hidden'>
      <div className='flex h-full flex-1 justify-center lg:justify-start flex-row'>
        <div
          className={classNames(
            'h-full md:border-r',
            uuid ? 'hidden lg:block' : '',
          )}
        >
          <ChatMembersList
            pagination={threads?.getMessagesThreads?.pagination}
            threads={threads?.getMessagesThreads?.items?.map((thread) => ({
              id: thread.id,
              uuid: thread.uuid,
              from: {
                name: `${thread.brand.name}`,
                avatar: thread.brand.logo,
              },
              lastMessage: thread.lastMessage,
              threadUnreadMessagesCount: thread.creatorUnreadMessagesCount,
              lastMessageDate: thread.updatedAt,
              type: 'brand',
              onClick: () =>
                navigate({
                  pathname: CreatorRoutes.inboxChat
                    .replace(':id', `${thread.creator.id}`)
                    .replace(
                      ':uuid',
                      `${thread.brand.id}-${thread.creator.id}`,
                    ),
                  search: `?${createSearchParams(searchParams)}`,
                }),
            }))}
            activeThreadId={uuid}
            onSearch={onSearch}
            onMarkAllRead={onMarkAllRead}
            fetchMore={fetchMoreThreads}
          />
        </div>

        {activeThread && renderChat()}
        {!activeThread && (
          <div className='hidden md:block flex-col w-full justify-between'>
            {!threadsLoading &&
              threads?.getMessagesThreads?.items?.length > 0 && (
                <div className='flex h-screen'>
                  <div className='m-auto'>
                    <p className='text-center'>
                      Select a brand chat to see messages
                    </p>
                  </div>
                </div>
              )}
          </div>
        )}
      </div>
    </div>
  )
}

export { Inbox }
