import {
  Button,
  ButtonColor,
  Icon,
  IconType,
  TextArea,
} from '../../../../components'
import { Controller, useFieldArray } from 'react-hook-form'
import { VideoPricing } from '../../../../gql/types'
import { mapCurrencyToSign } from '../../../../utils/mappers'
import { useEffect } from 'react'

interface Props {
  errors: any
  register: any
  watch: any
  control: any
  videoPricing: VideoPricing
}

const SetVariations = ({
  errors,
  register,
  watch,
  control,
  videoPricing,
}: Props) => {
  const {
    fields: hooksFields,
    append: hooksAppend,
    remove: hooksRemove,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'hooks', // unique name for your Field Array
  })

  const canAddMoreVariations = hooksFields.length < 10

  return (
    <div>
      <div className='mt-10 sm:mt-0'>
        <div className='md:grid md:grid-cols-3 md:gap-6'>
          <div className='md:col-span-1'>
            <div className=''>
              <h3 className='text-lg font-semibold font-sans leading-6 text-gray-900'>
                Would you like to add extra variations?
              </h3>
              <p className='mt-2 text-sm font-normal leading-5 text-gray-500 font-sans'>
                Variations can be hooks or CTAs - different starting points or
                end messages to catch viewers' interest. Each variation is an
                altered version of the original video, featuring a unique hook
                or call-to-action. The duration of each variation is up to 5
                seconds.
              </p>
              {/* <p className='mt-2 text-sm font-normal leading-5 text-gray-500 font-sans'>
                If you purchase variations for a campaign, each creator will
                produce all the requested variations. Alternatively, after
                receiving a video, you can buy individual variations directly
                from your preferred creators.
              </p> */}
            </div>
          </div>
          <div className='mt-5 md:col-span-2 md:mt-0'>
            <div className='md:shadow rounded-t-md sm:overflow-hidden'>
              <div className='py-5 md:bg-white sm:p-6'>
                <div className='grid gap-6'>
                  <div className='col-span-3 sm:col-span-2'>
                    <ul>
                      {hooksFields.map((item, index) => (
                        <li key={item.id} className='relative'>
                          <Controller
                            name={`hooks.${index}`}
                            control={control}
                            rules={{
                              required: `Please set a variation.`,
                            }}
                            render={({ field }) => (
                              <TextArea
                                error={errors.hooks?.[index]?.message}
                                placeholder={`Example: Start with a different hook "This is the best birthday gift."`}
                                minRows={3}
                                field={field}
                                watch={watch}
                                {...register(`hooks.${index}`, {
                                  minLength: {
                                    value: 10,
                                    message: `Please write a descriptive variation.`,
                                  },
                                  maxLength: {
                                    value: 10000,
                                    message: `Your variation text is too long`,
                                  },
                                })}
                                autoFocus={index === hooksFields.length}
                              />
                            )}
                          />
                          <div
                            onClick={() => hooksRemove(index)}
                            className='absolute right-1 top-1 cursor-pointer'
                          >
                            <Icon
                              type={IconType.x}
                              className='h-5 w-5 text-primary'
                              aria-hidden='true'
                            />
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className={`${watch('hooks')?.length > 0 ? 'mt-6' : ''}`}>
                  {canAddMoreVariations && (
                    <Button
                      title='New variation'
                      type='button'
                      colour={ButtonColor.primary}
                      onClick={() => hooksAppend('')}
                      icon={
                        <Icon
                          type={IconType.plus}
                          className='h-5 w-5 text-white'
                          aria-hidden='true'
                        />
                      }
                      iconPosition='left'
                    />
                  )}
                  <p className='text-sm font-bold leading-5 text-gray-500 mt-4'>
                    + {mapCurrencyToSign(videoPricing.currency)}
                    {videoPricing.hook.priceCents / 100}
                    <sub>/ each variation</sub>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='hidden sm:block' aria-hidden='true'>
        <div className='py-5'>
          <div className='border-t border-gray-200' />
        </div>
      </div>
    </div>
  )
}

export { SetVariations }
