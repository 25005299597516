import { useMutation } from '@apollo/client'
import { useForm } from 'react-hook-form'
import {
  Button,
  ButtonColor,
  ButtonSize,
  Icon,
  IconType,
  TextArea,
  TextField,
} from '../../components'
import { Loader } from '../../components/Loader'
import {
  CREATE_BRAND,
  UPLOAD_BRAND_LOGO_FROM_URL,
  GET_BRAND_LOGO_UPLOAD_URL,
  GET_SELF,
  GET_WEBSITE_META,
  GET_USER_BRANDS,
} from '../../gql'
import { CountryCode, User } from '../../gql/types'
import validator from 'validator'
import { useNavigate } from 'react-router-dom'
import { BrandRoutes } from '../BrandRoutes'
import { cleanUrl } from '../../utils/helpers'
import { useState } from 'react'
import axios from 'axios'
import { LogoUpload } from '../../components/LogoUpload'

export type EditCreateBrandFormData = {
  website: string
  name: string
  countryCode: CountryCode
  description: string
  logoFileName: string
}

interface Props {
  user: User
}

const Create = ({ user }: Props) => {
  const navigate = useNavigate()
  const [selectedFile, setSelectedFile] = useState(null)
  const [imageUrl, setImageUrl] = useState(null)

  const [createBrand, { loading, error }] = useMutation(CREATE_BRAND, {
    refetchQueries: [
      {
        query: GET_SELF,
      },
      {
        query: GET_USER_BRANDS,
        variables: {
          options: {
            filters: {
              userIds: [user.id],
            },
            limit: 20,
          },
        },
      },
    ],
  })

  const [getWebsiteMeta, { loading: getWebsiteMetaLoading }] =
    useMutation(GET_WEBSITE_META)

  const [getBrandLogoUploadUrl, { loading: loadingBrandLogoUploadUrl }] =
    useMutation(GET_BRAND_LOGO_UPLOAD_URL)

  const [uploadBrandLogoFromUrl, { loading: uploadBrandLogoFromUrlLoading }] =
    useMutation(UPLOAD_BRAND_LOGO_FROM_URL)

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    watch,
    clearErrors,
  } = useForm({
    defaultValues: {
      website: '',
      name: '',
      countryCode: user.countryCode,
      description: '',
      logoFileName: null,
    },
  })

  const updateMeta = async () => {
    if (watch('website') === '') {
      setError('website', {
        type: 'custom',
        message: 'Please set a website for your brand.',
      })
      return
    }

    clearErrors('website')

    const { data: dataMeta } = await getWebsiteMeta({
      variables: {
        website: cleanUrl(watch('website')),
      },
    })
    const meta = dataMeta?.getWebsiteMeta

    setValue('website', meta.website)
    setValue('name', meta.title)
    if (watch('name') !== '') {
      clearErrors('name')
    }
    setValue('description', meta.description)
    if (watch('description') !== '') {
      clearErrors('description')
    }

    if (!meta.imageUrl) {
      return
    }

    setImageUrl(meta.imageUrl)

    try {
      const { data } = await uploadBrandLogoFromUrl({
        variables: {
          logoUrl: meta.imageUrl,
        },
      })

      setValue('logoFileName', data.uploadBrandLogoFromUrl.fileName)
      setSelectedFile(null)
      clearErrors('logoFileName')
    } catch (error) {
      console.log('error uploadBrandLogoFromUrl', error.message)
    }
  }

  const onBrandLogoUpload = async (file: File) => {
    if (!file) {
      return
    }
    setSelectedFile(file)
    clearErrors('logoFileName')

    try {
      const { data } = await getBrandLogoUploadUrl({
        variables: {
          mime: file.type,
        },
      })

      await axios.put(data.getBrandLogoUploadUrl.uploadUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
      })

      setValue('logoFileName', data.getBrandLogoUploadUrl.fileName)
    } catch (error) {
      console.log('error onBrandLogoUpload', error.message)
    }
  }

  const onClickCreate = async () => {
    if (watch('logoFileName') === null) {
      setError('logoFileName', {
        type: 'custom',
        message: 'Please set a logo for your brand.',
      })
      return
    }
    clearErrors('logoFileName')
  }

  const onSubmit = async (data: EditCreateBrandFormData) => {
    try {
      const { data: dataNewBrand } = await createBrand({
        variables: {
          userId: user.id,
          input: {
            website: data.website,
            name: data.name,
            countryCode: user.countryCode ?? CountryCode.US,
            description: data.description,
            logoFileName: data.logoFileName,
            isMarketingEmailsSubscribed:
              user?.notifications?.isMarketingEnabled ?? true,
          },
        },
      })

      const brand = dataNewBrand?.createBrand
      navigate(BrandRoutes.campaigns.replace(':id', `${brand.id}`))
    } catch (error) {
      if (error.message) {
        console.log('error createBrand', error.message)
        return
      }
    }
  }

  if (loading) {
    return <Loader />
  }

  if (error) {
    console.log('error onCreateBrand', error)
    return <p>There has been an error. Try refreshing the page</p>
  }

  return (
    <div className='md:-ml-[256px] min-h-screen flex flex-col'>
      <div className='flex-grow'>
        <div className='min-h-full flex flex-col justify-center pt-4 px-4 md:mt-8 lg:px-8'>
          <div className='sm:mx-auto sm:w-full sm:max-w-2xl'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='md:shadow rounded-t-md sm:overflow-hidden'>
                <div className='py-5 md:bg-white space-y-6 sm:p-6'>
                  <div className='mx-auto'>
                    <h2 className='mt-0 md:text-center text-3xl font-extrabold text-gray-900'>
                      Create Brand
                    </h2>
                    <p className='mt-2 md:text-center text-sm text-gray-600'>
                      Add the website, name and description of your brand
                    </p>
                  </div>

                  <div className='grid gap-6'>
                    <div className='col-span-3 sm:col-span-2'>
                      <TextField
                        error={errors.website?.message}
                        label='Website'
                        placeholder='www.example.com'
                        className={'rounded-none'}
                        leftElement={
                          <span className='inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm'>
                            https://
                          </span>
                        }
                        rightElement={
                          <Button
                            title={
                              <Icon
                                type={IconType.check}
                                className='text-white'
                              />
                            }
                            type='button'
                            className='md:text-center text-sm text-gray-600 rounded-none max-w-20'
                            loading={getWebsiteMetaLoading}
                            size={ButtonSize.medium}
                            colour={ButtonColor.primary}
                            onClick={() => updateMeta()}
                          />
                        }
                        {...register('website', {
                          required: `Please set a website for your brand.`,
                          maxLength: {
                            value: 100,
                            message: `Your brand website cannot have more than 100 characters.`,
                          },
                          validate: {
                            value: (website) => {
                              if (validator.isURL(website)) {
                                return true
                              }

                              return 'Invalid website.'
                            },
                          },
                        })}
                      />
                      {!errors.website?.message && (
                        <p className='mt-2 text-sm text-gray-600'>
                          Press the check button to automatically extract
                          essential info about your brand
                        </p>
                      )}
                    </div>
                  </div>

                  <div className='grid gap-6'>
                    <div className='col-span-3 sm:col-span-2'>
                      <LogoUpload
                        title='Logo'
                        logoUpload={
                          selectedFile
                            ? URL.createObjectURL(selectedFile)
                            : imageUrl
                        }
                        onChange={onBrandLogoUpload}
                      />
                      <div
                        className='relative mt-2 text-sm text-red-600'
                        id='errorLogoFileName'
                      >
                        {errors.logoFileName?.message && (
                          <div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
                            <Icon
                              type={IconType.exclamationCircleFill}
                              className='h-5 w-5 text-red-500'
                              aria-hidden='true'
                            />
                          </div>
                        )}
                      </div>
                      {errors.logoFileName?.message && (
                        <p
                          className='mt-2 text-sm text-red-600'
                          id='errorLogoFileName'
                        >
                          Please set a logo.
                        </p>
                      )}
                    </div>
                  </div>

                  <div className='grid gap-6'>
                    <div className='col-span-3 sm:col-span-2'>
                      <TextField
                        error={errors.name?.message}
                        label='Brand Name'
                        placeholder='Example: Gym Shark'
                        {...register('name', {
                          required: `Please set a brand name.`,
                          maxLength: {
                            value: 100,
                            message: `Your brand name cannot have more than 100 characters.`,
                          },
                        })}
                      />
                    </div>
                  </div>

                  <div className='grid gap-6'>
                    <div className='col-span-3 sm:col-span-2'>
                      <TextArea
                        error={errors.description?.message}
                        label='Description'
                        placeholder='Example: Fitness apparel & accessories'
                        {...register('description', {
                          required: `Please set a brand description.`,
                          maxLength: {
                            value: 10000,
                            message: `Description too long.`,
                          },
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='md:shadow rounded-b-md py-3 md:bg-gray-50 text-right sm:px-6'>
                <Button
                  title='Create brand'
                  type='submit'
                  loading={loading || loadingBrandLogoUploadUrl}
                  disabled={loading || loadingBrandLogoUploadUrl}
                  // onClick={() => {
                  //   if (!selectedFile || selectedFile === null) {
                  //     setErrorSelectedFile(true)
                  //   }
                  // }}
                  onClick={onClickCreate}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export { Create }
