import { useApolloClient, useQuery } from '@apollo/client'
import { GET_SELF, GET_USER_BRAND } from '../gql'
import { GET_PRICING } from '../gql/pricing'
import { GetPricingResponse, User } from '../gql/types'
import { PricingData } from '../utils/PricingUtil'

export const AUTH_TOKEN = 'AUTH_TOKEN'

export const useSelf = (): {
  user: User
  loading: boolean
} => {
  const token = localStorage.getItem(AUTH_TOKEN)

  const { data, loading } = useQuery(GET_SELF, {
    skip: !token,
  })

  if (!data) {
    return {
      loading: false,
      user: null,
    }
  }

  return {
    loading,
    user: data.getSelf,
  }
}

export const useLogout = () => {
  const client = useApolloClient()

  const logout = () => {
    // authLogout()
    // @TODO: make API request
    localStorage.removeItem(AUTH_TOKEN)
    client.cache.reset()

    window.location.replace('/')
    window.location.reload()
  }

  return logout
}

export const usePricing = (): {
  loading: boolean
  pricing?: PricingData
} => {
  const token = localStorage.getItem(AUTH_TOKEN)

  const { data, loading } = useQuery<{ getPricing: GetPricingResponse }>(
    GET_PRICING,
    {
      skip: !token,
    },
  )

  return {
    loading,
    pricing: data ? { ...data.getPricing } : undefined,
  }
}

export const useUserBrand = (brandId: number) => {
  const logout = useLogout()
  const { data, loading, error } = useQuery(GET_USER_BRAND, {
    variables: {
      brandId,
    },
    skip: !brandId,
  })

  if (loading) {
    return null
  }

  if (error) {
    console.log('error GET_USER_BRAND', error)
    logout()
    return null
  }

  return data?.getUserBrand
}
