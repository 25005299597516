import { useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  GetProductsResponse,
  GetProductsSort,
  QueryGetProductsByBrandIdArgs,
  User,
} from '../../gql/types'
import { useQuery } from '@apollo/client'
import { ListOptions, Loader } from '../../components'
import { GET_PRODUCTS_BY_BRAND_ID } from '../../gql'
import { Header } from './components/Header'
import { List } from './components/List'
import { Grid } from './components/Grid'

interface IProps {
  user: User
}

const Products = ({ user }: IProps) => {
  const { id } = useParams()
  const limit = 6
  const [skip, setSkip] = useState(0)

  const { loading, error, data, refetch } = useQuery<
    { getProductsByBrandId: GetProductsResponse },
    QueryGetProductsByBrandIdArgs
  >(GET_PRODUCTS_BY_BRAND_ID, {
    variables: {
      brandId: parseInt(id),
      options: {
        query: '',
        filters: {},
        sort: GetProductsSort.newest,
        limit,
        skip,
      },
    },
  })

  const [query, setQuery] = useState('')

  const refetchData = (query) => {
    refetch({
      options: {
        query: query,
        filters: {},
        sort: GetProductsSort.newest,
        limit,
        skip,
      },
    })
  }

  const onSearch = () => {
    refetchData(query)
  }

  if (error) {
    console.log(error)
    return <p>There has been an error. Try refreshing the page</p>
  }

  const setPage = (page: number) => {
    setSkip(page * limit)
  }

  return (
    <div>
      <Header />

      {loading && <Loader />}
      {!loading && data && (
        <div className='pb-4 w-auto h-fit'>
          <div className='-my-2 -mx-4 overflow-x-auto'>
            <div className='inline-block min-w-full py-2 px-1 align-middle'>
              <div className='shadow ring-1 ring-black ring-opacity-5 rounded-t'>
                <ListOptions
                  query={query}
                  setQuery={setQuery}
                  onSearch={onSearch}
                />
                <div className='hidden lg:block'>
                  <List
                    products={data.getProductsByBrandId.items}
                    pagination={data.getProductsByBrandId.pagination}
                    setPage={setPage}
                  />
                </div>
                <div className='block lg:hidden'>
                  <Grid
                    products={data.getProductsByBrandId.items}
                    pagination={data.getProductsByBrandId.pagination}
                    setPage={setPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export { Products }
