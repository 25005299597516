import classnames from 'classnames'
import { Pagination as PaginationComponent } from '../../../../components'
import { Collaboration, Pagination } from '../../../../gql/types'
import { ActiveCampaignCard } from '../ActiveCampaignCard'
import { PricingData } from '../../../../utils/PricingUtil'

interface IProps {
  pricing: PricingData
  collaborations: Collaboration[]
  pagination?: Pagination
  setPage(page: number): void
}

const TableHead = () => {
  return (
    <thead className='bg-white'>
      <tr>
        <th
          scope='col'
          className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-600 sm:pl-6'
        >
          Product
        </th>

        <th
          scope='col'
          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-600'
        >
          Earnings
        </th>

        <th
          scope='col'
          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-600'
        >
          Status
        </th>
        <th
          scope='col'
          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-600'
        ></th>
        <th></th>
        <th></th>
      </tr>
    </thead>
  )
}

export const List = ({
  pricing,
  collaborations,
  pagination,
  setPage,
}: IProps) => {
  return (
    <>
      <div className='flex flex-col'>
        <table className='min-w-full divide-y divide-gray-200'>
          <TableHead />
          <tbody className='divide-y divide-gray-200 bg-white'>
            {pagination.numberOfHits === 0 && (
              <tr>
                <td colSpan={6}>
                  <p className='py-4 pl-4 pr-3 text-sm sm:pl-6 w-full'>
                    No campaigns.
                  </p>
                </td>
              </tr>
            )}

            {collaborations.map((collaboration, index) => (
              <tr
                key={collaboration.id}
                className={classnames(
                  index % 2 === 0 ? 'bg-gray-50' : 'bg-white',
                )}
              >
                <ActiveCampaignCard
                  collaboration={collaboration}
                  pricing={pricing}
                />
              </tr>
            ))}
          </tbody>
        </table>

        {pagination && (
          <PaginationComponent pagination={pagination} setPage={setPage} />
        )}
      </div>
    </>
  )
}
