import { useMutation } from '@apollo/client'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  NotificationType,
  Button,
  ButtonColor,
} from '../../../../../components'
import { Toggle } from '../../../../../components/Toggle'
import { UPDATE_TEAM_MEMBER_ROLES, GET_TEAM_MEMBERS } from '../../../../../gql'
import {
  UserBrand,
  UserRole,
  GetUserBrandsSort,
} from '../../../../../gql/types'
import { ErrorCode } from '../../../../../utils/ErrorCode'
import { SKIP_DEFAULT, LIMIT_DEFAULT } from '../../../../../utils/constants'
import { useNotification } from '../../../../../utils/hooks'
import { findErrorMethodName } from '../../../../../utils/helpers'

interface IProps {
  userBrand: UserBrand
  isOpen: boolean
  setIsOpen: (event: any) => void
}

const UpdateRolesModal = ({ userBrand, isOpen, setIsOpen }: IProps) => {
  const { addNotification } = useNotification()
  const { id: brandId } = useParams()

  const [isOwner, setIsOwner] = useState(
    userBrand.roles.includes(UserRole.owner),
  )
  const [isAdvertiser, setIsAdvertiser] = useState(
    userBrand.roles.includes(UserRole.advertiser),
  )
  const [isDefaultContactUser, setIsDefaultContactUser] = useState(
    userBrand.isDefaultContactUser,
  )

  useEffect(() => {
    resetToggles(userBrand)
  }, [userBrand])

  const resetToggles = (userBrand) => {
    setIsOwner(userBrand.roles.includes(UserRole.owner))
    setIsAdvertiser(userBrand.roles.includes(UserRole.advertiser))
    setIsDefaultContactUser(userBrand.isDefaultContactUser)
  }

  const [updateTeamMemberRoles, { loading: updateTeamMemberRolesLoading }] =
    useMutation(UPDATE_TEAM_MEMBER_ROLES, {
      refetchQueries: [
        {
          query: GET_TEAM_MEMBERS,
          variables: {
            options: {
              query: null,
              filters: {
                brandId: parseInt(brandId),
              },
              sort: GetUserBrandsSort.newest,
              skip: SKIP_DEFAULT,
              limit: LIMIT_DEFAULT,
            },
          },
        },
      ],
    })

  const closeModal = () => {
    setIsOpen(false)
    resetToggles(userBrand)
  }

  const onSubmit = async (e) => {
    e.preventDefault()

    try {
      const roles = []
      if (isOwner) {
        roles.push(UserRole.owner)
      }
      if (isAdvertiser) {
        roles.push(UserRole.advertiser)
      }
      if (roles.length === 0) {
        addNotification(
          `Please select at least one role.`,
          NotificationType.error,
        )
        return
      }

      await updateTeamMemberRoles({
        variables: {
          userId: userBrand.user.id,
          brandId: userBrand.brand.id,
          input: {
            roles: roles,
            isDefaultContactUser: isDefaultContactUser,
          },
        },
      })

      addNotification('User roles have been updated.', NotificationType.success)
      closeModal()
    } catch (error) {
      if (findErrorMethodName(error, ErrorCode.userLastDefaultContactPerson)) {
        setIsDefaultContactUser(true)
        addNotification(
          `Enable at least one user as primary contact.`,
          NotificationType.error,
        )
      } else {
        addNotification(
          `Something went wrong. Please try again.`,
          NotificationType.error,
        )
      }
    }
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                  <Dialog.Title
                    as='h3'
                    className='text-lg font-medium leading-6 text-gray-900'
                  >
                    Update roles for {userBrand.user.firstName}{' '}
                    {userBrand.user.lastName}
                  </Dialog.Title>
                  <p className='mt-2 text-base text-gray-500'>
                    Can select multiple.
                  </p>

                  <form onSubmit={onSubmit}>
                    <div className='mt-4 space-y-10'>
                      <fieldset>
                        <legend className='text-sm font-semibold leading-6 text-gray-900'>
                          Roles
                        </legend>
                        <div className='mt-6 space-y-6'>
                          <div className='relative flex gap-x-3'>
                            <Toggle
                              // labelPosition='right'
                              label='Owner'
                              description='This role allows to manage payments, invite users and update roles of other users.'
                              enabled={isOwner}
                              onChange={() => setIsOwner(!isOwner)}
                            />
                          </div>
                          <div className='relative flex gap-x-3'>
                            <Toggle
                              // labelPosition='right'
                              label='Advertiser'
                              description='This role is associated with managing content and communication within the Clip platform.'
                              enabled={isAdvertiser}
                              onChange={() => setIsAdvertiser(!isAdvertiser)}
                            />
                          </div>

                          <legend className='text-sm font-semibold leading-6 text-gray-900'>
                            Other
                          </legend>
                          <div className='relative flex gap-x-3'>
                            <Toggle
                              // labelPosition='right'
                              label='Primary Contact'
                              description='This is the primary user who will be notified about influencer communications.'
                              enabled={isDefaultContactUser}
                              onChange={() =>
                                setIsDefaultContactUser(!isDefaultContactUser)
                              }
                            />
                          </div>
                        </div>
                      </fieldset>
                    </div>
                    <div className='mt-10 flex items-center justify-between'>
                      <div className='py-2'>
                        <Button
                          type='button'
                          title='Cancel'
                          colour={ButtonColor.white}
                          onClick={closeModal}
                        />
                      </div>

                      <Button
                        title='Update'
                        loading={updateTeamMemberRolesLoading}
                      />
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export { UpdateRolesModal }
