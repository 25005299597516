import { useParams } from 'react-router-dom'
import { Button, ButtonColor, Icon, IconType } from '../../../../components'
import { Collaboration, CollaborationStatus } from '../../../../gql/types'
import { CreatorRoutes } from '../../../CreatorRoutes'

interface IDefaultProps {}

const defaultProps: IDefaultProps = {}

interface IProps extends Partial<IDefaultProps> {
  collaboration: Collaboration
  setIsCreateClipModalOpen: (event: any) => void
  setIsViewClipsModalOpen: (event: any) => void
  setIsWithdrawModalOpen: (event: any) => void
}

const GridButtons = ({
  collaboration,
  setIsCreateClipModalOpen,
  setIsViewClipsModalOpen,
  setIsWithdrawModalOpen,
}: IProps) => {
  const { id } = useParams()

  const RowButton = () => {
    switch (collaboration.status) {
      // case CollaborationStatus.creatorAccepted:
      // case CollaborationStatus.productShipped:
      // case CollaborationStatus.productDelivered:
      //   return (
      //     <Button
      //       title='Message'
      //       type='button'
      //       colour={ButtonColor.white}
      //       to={
      //         CreatorRoutes.inboxChat
      //           .replace(':id', `${id}`)
      //           .replace(':uuid', `${collaboration.campaign.brand.id}-${id}`) +
      //         '?collaborationId=' +
      //         collaboration.id
      //       }
      //       className='w-full py-3'
      //     />
      //   )
      case CollaborationStatus.contentUploaded:
      case CollaborationStatus.contentAccepted:
        return (
          <Button
            // title='View Clips'
            type='button'
            colour={ButtonColor.secondary}
            onClick={() => setIsViewClipsModalOpen(true)}
            className='w-full py-3'
            icon={
              <Icon
                type={IconType.filmThree}
                className='h-4 w-4 text-white'
                aria-hidden='true'
              />
            }
            iconPosition='left'
            tooltip='See videos you have uploaded for this campaign'
          />
        )
      case CollaborationStatus.creatorApplied:
        return undefined
    }
  }

  return (
    <>
      <div>
        <div className='-mt-px flex divide-x divide-gray-200'>
          {collaboration.isActive && (
            <div className='-ml-px flex w-0 flex-1 justify-center'>
              <Button
                // title='Message'
                type='button'
                colour={ButtonColor.white}
                to={
                  CreatorRoutes.inboxChat
                    .replace(':id', `${id}`)
                    .replace(
                      ':uuid',
                      `${collaboration.campaign.brand.id}-${id}`,
                    ) +
                  '?collaborationId=' +
                  collaboration.id
                }
                className='py-3 w-full'
                icon={
                  <Icon
                    type={IconType.inbox}
                    className='h-4 w-4'
                    aria-hidden='true'
                  />
                }
                iconPosition='left'
                tooltip='Message brand about this campaign'
              />
              {RowButton()}
            </div>
          )}

          <div className='-ml-px flex w-0 flex-1 justify-center cursor-pointer'>
            {collaboration.status === CollaborationStatus.creatorApplied && (
              <Button
                title='Withdraw Application'
                type='button'
                colour={ButtonColor.primary}
                onClick={() => setIsWithdrawModalOpen(true)}
                className='w-full'
              />
            )}
            {collaboration.status !== CollaborationStatus.creatorApplied && (
              <Button
                title='Upload'
                type='button'
                colour={ButtonColor.primary}
                onClick={() => setIsCreateClipModalOpen(true)}
                className='w-full'
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

GridButtons.defaultProps = defaultProps

export { GridButtons }
