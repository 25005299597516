import { Dialog, Transition } from '@headlessui/react'
import { Fragment, FunctionComponent, useRef } from 'react'
import { useForm } from 'react-hook-form'
import {
  Button,
  ButtonColor,
  NotificationType,
  TextArea,
  TextField,
} from '../../../../components'
import { Template, TemplateType } from '../../../../gql/types'
import { useNotification } from '../../../../utils/hooks'
import { useUpdateBrandTemplateMutation } from '../../../../hooks'

export type EditTemplateFormData = {
  name: string
  message: string
}

interface IProps {
  template: Template
  isOpen: boolean
  setIsOpen: (event: any) => void
}

const EditTemplateModal: FunctionComponent<IProps> = ({
  template,
  isOpen,
  setIsOpen,
}: IProps) => {
  const cancelButtonRef = useRef(null)
  const { addNotification } = useNotification()

  const { updateTemplate, loading: updateTemplateLoading } =
    useUpdateBrandTemplateMutation()

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: template?.name ?? '',
      message: template?.message ?? '',
    },
  })

  const closeModal = () => {
    setIsOpen(false)
    reset()
  }

  const onSubmit = async (data: EditTemplateFormData) => {
    try {
      await updateTemplate({
        variables: {
          templateId: template.id,
          input: {
            name: data.name,
            message: data.message,
          },
        },
      })

      closeModal()
      addNotification('Template updated', NotificationType.success)
    } catch (error) {
      addNotification(error.message, NotificationType.error)
    }
  }

  return (
    <Transition.Root appear show={isOpen} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-10'
        initialFocus={cancelButtonRef}
        onClose={closeModal}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                <Dialog.Title
                  as='h3'
                  className='flex flex-row text-lg font-medium leading-6 text-gray-900 items-center'
                >
                  New template
                </Dialog.Title>
                <div className='mt-2'>
                  <p className='text-sm text-gray-500'>
                    Create a new template to use when messaging.
                  </p>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <div>
                    <div className='mt-3 sm:mt-5'>
                      <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
                        <div className='sm:col-span-6'>
                          <TextField
                            label='Name'
                            name='name'
                            error={errors.name?.message}
                            {...register('name', {
                              required: `Please set a name.`,
                              maxLength: {
                                value: 100,
                                message: `Please keep your name under 100 characters.`,
                              },
                            })}
                          />
                        </div>
                        <div className='sm:col-span-6'>
                          <TextArea
                            label={`Message`}
                            minRows={8}
                            placeholder={``}
                            name='message'
                            error={errors.message?.message}
                            {...register('message', {
                              required: 'Please set a message.',
                              maxLength: {
                                value: 10000,
                                message: `Please keep your message under 10,000 characters.`,
                              },
                              validate: {
                                noEmoji: (value) => {
                                  const regex = /\p{So}/gu
                                  return (
                                    !regex.test(value) ||
                                    'Emojis are not allowed.'
                                  )
                                },
                              },
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='mt-4 flex justify-between'>
                    <div className='py-2' ref={cancelButtonRef}>
                      <Button
                        type='button'
                        title='Cancel'
                        colour={ButtonColor.white}
                        onClick={closeModal}
                      />
                    </div>

                    <div className='py-2'>
                      <Button
                        title='Save'
                        type='submit'
                        colour={ButtonColor.primary}
                        loading={updateTemplateLoading}
                      />
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export { EditTemplateModal }
