import {
  ApolloClient,
  ApolloProvider,
  from,
  HttpLink,
  InMemoryCache,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { ErrorCode } from './utils/ErrorCode'
import { AUTH_TOKEN } from './hooks'
import env from './config/env'

const errorLink = onError(
  ({ graphQLErrors, networkError, forward, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((gqlError: any) => {
        // Remove session and reload if unauthenticated call
        if (
          gqlError.code === ErrorCode.authorizationError ||
          gqlError.message === 'Forbidden resource'
        ) {
          // localStorage.removeItem(AUTH_SESSION)
          // window.location.reload()
        }
      })
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`)
    }

    forward(operation)
  },
)

const sessionToken = localStorage.getItem(AUTH_TOKEN)
const GQL_ENDPOINT = `${env.API_URL}/graphql`

const link = from([
  errorLink,
  new HttpLink({
    uri: GQL_ENDPOINT,
    headers: {
      'x-api-token': sessionToken ?? '',
      client: 'Xy72JqKBWQOZcab8EfPtSenF7kzrt5Ww',
    },
  }),
])

const mergeObjects = (existing, incoming) => {
  const newItems = [...existing.items]

  for (const item of incoming.items) {
    const index = newItems.findIndex(
      (existing) => existing.__ref === item.__ref,
    )

    if (index >= 0) {
      // need to update
      newItems[index] = item
      continue
    }

    newItems.push(item)
  }

  return {
    items: newItems,
    pagination: incoming.pagination,
  }
}

const mergeLogo = (existing = { name: '', url: '' }, incoming) => {
  if (!incoming) {
    return existing
  }

  return incoming
}

const client = new ApolloClient({
  // uri: 'http://localhost:3005/graphql',
  cache: new InMemoryCache({
    typePolicies: {
      Brand: {
        fields: {
          logo: {
            merge(existing, incoming) {
              return mergeLogo(existing, incoming)
            },
          },
        },
      },
      Query: {
        fields: {
          getMessagesThreads: {
            keyArgs: ['options', ['query', 'filters', 'sort']],
            merge(
              existing = { items: [], pagination: {} },
              incoming,
              { args },
            ) {
              if (!incoming) {
                return existing
              }

              return mergeObjects(existing, incoming)
            },
          },
          getClipsByBrandId: {
            keyArgs: ['brandId', 'options', ['query', 'filters', 'sort']],
            merge(
              existing = { items: [], pagination: {} },
              incoming,
              { args },
            ) {
              if (!incoming) {
                return existing
              }

              return mergeObjects(existing, incoming)
            },
          },
          getClipsByCampaignId: {
            keyArgs: ['campaignId', 'options', ['query', 'filters', 'sort']],
            merge(
              existing = { items: [], pagination: {} },
              incoming,
              { args },
            ) {
              if (!incoming) {
                return existing
              }

              return mergeObjects(existing, incoming)
            },
          },
          getClipsByCreatorId: {
            keyArgs: ['creatorId', 'options', ['query', 'filters', 'sort']],
            merge(
              existing = { items: [], pagination: {} },
              incoming,
              { args },
            ) {
              if (!incoming) {
                return existing
              }

              return mergeObjects(existing, incoming)
            },
          },
          getClipsByBrandAndCreator: {
            keyArgs: [
              'brandId',
              'creatorId',
              'options',
              ['query', 'filters', 'sort'],
            ],
            merge(
              existing = { items: [], pagination: {} },
              incoming,
              { args },
            ) {
              if (!incoming) {
                return existing
              }

              return mergeObjects(existing, incoming)
            },
          },
          getInspirationsByBrandId: {
            keyArgs: ['brandId', 'options', ['query', 'filters', 'sort']],
            merge(
              existing = { items: [], pagination: {} },
              incoming,
              { args },
            ) {
              if (!incoming) {
                return existing
              }

              return mergeObjects(existing, incoming)
            },
          },
          getInspirations: {
            keyArgs: ['options', ['query', 'filters', 'sort']],
            merge(
              existing = { items: [], pagination: {} },
              incoming,
              { args },
            ) {
              if (!incoming) {
                return existing
              }

              return mergeObjects(existing, incoming)
            },
          },
          getInspirationsByCampaignId: {
            keyArgs: ['campaignId', 'options', ['query', 'filters', 'sort']],
            merge(
              existing = { items: [], pagination: {} },
              incoming,
              { args },
            ) {
              if (!incoming) {
                return existing
              }

              return mergeObjects(existing, incoming)
            },
          },
          getPortfolioByCreator: {
            keyArgs: ['creatorId', 'options', ['query', 'filters', 'sort']],
            merge(
              existing = { items: [], pagination: {} },
              incoming,
              { args },
            ) {
              if (!incoming) {
                return existing
              }

              return mergeObjects(existing, incoming)
            },
          },
          getCollaborationsByCampaignId: {
            keyArgs: ['campaignId', 'options', ['filters', 'sort']],
            merge(
              existing = { items: [], pagination: {} },
              incoming,
              { args },
            ) {
              if (!incoming) {
                return existing
              }

              return mergeObjects(existing, incoming)
            },
          },
        },
      },
    },
  }),
  connectToDevTools: true,
  link,
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
