import { Controller } from 'react-hook-form'
import { RadioGroup } from '../../../../components'
import { videoDurationOptions } from '../../../../utils/mappers'
import { PricingData } from '../../../../utils/PricingUtil'
import { CreatorQuality, Currency } from '../../../../gql/types'

interface Props {
  control: any
  errors: any
  currency: Currency
  pricing: PricingData
  quality: CreatorQuality
}

const EditVideoDuration = ({
  control,
  errors,
  currency,
  pricing,
  quality,
}: Props) => {
  return (
    <div>
      <div className='mt-10 sm:mt-0'>
        <div className='md:grid md:grid-cols-3 md:gap-6'>
          <div className='md:col-span-1'>
            <div className=''>
              <h3 className='text-lg font-semibold font-sans leading-6 text-gray-900'>
                Video duration
              </h3>
              <p className='mt-1 text-sm font-normal font-sans leading-5 text-gray-500'>
                Optimal video length varies depending on where you share or
                embed it.
              </p>
            </div>
          </div>
          <div className='mt-5 md:col-span-2 md:mt-0'>
            <div className='md:shadow rounded-t-md sm:overflow-hidden'>
              <div className='py-5 md:bg-white space-y-6 sm:p-6'>
                <div className='grid gap-6'>
                  <Controller
                    name='videoDuration'
                    control={control}
                    rules={{
                      required: `Tell us what is your video duration.`,
                    }}
                    render={({ field }) => (
                      <RadioGroup
                        options={videoDurationOptions({
                          currency,
                          pricing,
                          quality,
                        })}
                        field={field}
                        className='grid-cols-1 lg:grid-cols-3'
                        error={errors?.videoDuration?.message}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='hidden sm:block' aria-hidden='true'>
        <div className='py-5'>
          <div className='border-t border-gray-200' />
        </div>
      </div>
    </div>
  )
}

export { EditVideoDuration }
