import React, { useRef, useEffect } from 'react'
import { useVideoPlayer } from './Provider'

interface VideoPlayerProps extends React.VideoHTMLAttributes<HTMLVideoElement> {
  src: string
  poster?: string
}

export const VideoPlayer: React.FC<VideoPlayerProps> = ({
  src,
  poster,
  ...props
}) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const { playingVideo, setPlayingVideo } = useVideoPlayer()

  useEffect(() => {
    if (
      videoRef.current &&
      playingVideo !== videoRef.current &&
      !videoRef.current.paused
    ) {
      videoRef.current.pause()
    }
  }, [playingVideo])

  const handlePlay = () => {
    if (videoRef.current) {
      setPlayingVideo(videoRef.current)
    }
  }

  const videoProps = {
    ref: videoRef,
    onPlay: handlePlay,
    src: src,
    ...props,
  } as any

  if (poster) {
    videoProps.poster = poster
    videoProps.preload = 'none'
  }

  return <video {...videoProps}></video>
}
