import classNames from 'classnames'
import { FunctionComponent } from 'react'

interface IDefaultProps {
  isSelected: boolean
  disabled: boolean
  className?: string
}

interface IProps extends Partial<IDefaultProps> {
  value: string
  title: string | any
  selectTag?(tag): void
}

const defaultProps: IDefaultProps = {
  isSelected: false,
  disabled: false,
  className: null,
}

const Tag: FunctionComponent<IProps> = ({
  value,
  className,
  title,
  isSelected,
  selectTag,
  disabled,
}) => {
  return (
    <div
      className={classNames(
        'bg-gray-100 text-zinc-700 inline-flex items-center rounded-full text-sm py-[4px] px-[12px]',
        {
          'bg-green-50 text-green-700': isSelected,
          // styles['tag'],
          // styles[isSelected ? 'active' : ''],
          // styles[!selectTag ? 'tag-disable-hover' : ''],
        },
        className,
      )}
      key={value}
      onClick={() => selectTag && selectTag(value)}
    >
      {title}
    </div>
  )
}

Tag.defaultProps = defaultProps

export { Tag }
