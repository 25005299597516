import mixpanel from 'mixpanel-browser'
import { Currency, User } from '../gql/types'

const MIXPANEL_KEY = 'fb012a735cb2ac12beb75a5b79d8ef25'

interface CreateTrackPurchaseInput {
  transactionId: string
  currency: Currency
  priceCents: number
}

const trackPurchaseInputDefault = {
  transactionId: null,
  currency: null,
  priceCents: null,
}

export const init = () => {
  mixpanel.init(MIXPANEL_KEY, { debug: false })
}

export const identity = (user: User) => {
  // console.log('Identify', user.id)
  // // @ts-ignore
  // if (window.YourTracker) {
  //   // @ts-ignore
  //   window.YourTracker.identify(user.id, {
  //     email: user.email,
  //     firstName: user.firstName,
  //     lastName: user.lastName,
  //     type: user.type,
  //   })
  // }
}

export const trackEvent = (event: AnalyticsEvent, options: any = {}): void => {
  mixpanel.track(event, options)

  // @ts-ignore
  window.fbq('trackCustom', event)

  // @ts-ignore
  window.gtag('event', event)
}

export enum AnalyticsEvent {
  firstPurchase = 'firstPurchase',

  // App
  viewLogin = 'viewLogin',

  // Brands events
  viewBrandsProfile = 'viewBrandsProfile',
  viewBrandsRegister = 'viewBrandsRegister',
  brandSignedUp = 'brandSignedUp',
  startTrial = 'startTrial',
  completeRegistration = 'completeRegistration',
  clickBookOnboardingCall = 'clickBookOnboardingCall',
  clickBookADemoCall = 'clickBookADemoCall',
  brandClickGetInTouch = 'brandClickGetInTouch',
  brandClickBookAnOnboardingCall = 'brandClickBookAnOnboardingCall',
  brandDownloadClip = 'brandDownloadClip',
  viewBrandsTemplates = 'viewBrandsTemplates',

  // Brands onboarding
  viewBrandsOnboardingSetWebsite = 'viewBrandsOnboardingSetWebsite',
  viewBrandsOnboardingSetDetails = 'viewBrandsOnboardingSetDetails',
  viewBrandsOnboardingSetCampaignDetails = 'viewBrandsOnboardingSetCampaignDetails',
  viewBrandsOnboardingSetProductDetails = 'viewBrandsOnboardingSetProductDetails',
  viewBrandsOnboardingSetVideoType = 'viewBrandsOnboardingSetVideoType',
  viewBrandsOnboardingSetModelType = 'viewBrandsOnboardingSetModelType',
  viewBrandsOnboardingSetVoiceType = 'viewBrandsOnboardingSetVoiceType',
  viewBrandsOnboardingSetPlatform = 'viewBrandsOnboardingSetPlatform',
  viewBrandsOnboardingSetVideoDuration = 'viewBrandsOnboardingSetVideoDuration',
  viewBrandsOnboardingSetRequirements = 'viewBrandsOnboardingSetRequirements',
  viewBrandsOnboardingSetAddons = 'viewBrandsOnboardingSetAddons',
  viewBrandsOnboardingSetHooks = 'viewBrandsOnboardingSetHooks',
  viewBrandsOnboardingSetCreatorDetails = 'viewBrandsOnboardingSetCreatorDetails',
  viewBrandsOnboardingSetOrderSummary = 'viewBrandsOnboardingSetOrderSummary',
  viewBrandsAllReviews = 'viewBrandsAllReviews',

  // Brands collaborations
  viewBrandsCollaborationProfile = 'viewBrandsCollaborationProfile',
  viewBrandsCollaborationReviews = 'viewBrandsCollaborationReviews',
  brandLeftCreatorReview = 'brandLeftCreatorReview',
  brandUpdatedRating = 'brandUpdatedRating',

  // checkout
  initiateCheckout = 'initiateCheckout',
  purchase = 'purchase',

  // Creators events
  viewCreatorsRegister = 'viewCreatorsRegister',
  creatorSignedUp = 'creatorSignedUp',
  creatorAppliedToClip = 'creatorAppliedToClip',
  creatorAppliedToCampaign = 'creatorAppliedToCampaign',
  viewCreatorsOpportunities = 'viewCreatorsOpportunities',
  viewCreatorsInvitations = 'viewCreatorsInvitations',
  creatorWithdrawFromCollaboration = 'creatorWithdrawFromCollaboration',
  creatorAcceptedCampaignInvitation = 'creatorAcceptedCampaignInvitation',

  // Creators onboarding
  viewCreatorsOnboardingSetProfile = 'viewCreatorsOnboardingSetProfile',
  viewCreatorsOnboardingSetAbout = 'viewCreatorsOnboardingSetAbout',
  viewCreatorsOnboardingSetInterests = 'viewCreatorsOnboardingSetInterests',
  viewCreatorsOnboardingSetIntroVideo = 'viewCreatorsOnboardingSetIntroVideo',
  viewCreatorsOnboardingSetShippingAddress = 'viewCreatorsOnboardingSetShippingAddress',
  viewCreatorsOnboardingSetPaypal = 'viewCreatorsOnboardingSetPaypal',
  viewCreatorsOnboardingSetAgreeTerms = 'viewCreatorsOnboardingSetAgreeTerms',
  viewCreatorsOnboardingSetPortfolio = 'viewCreatorsOnboardingSetPortfolio',
  viewCreatorsOnboardingApplicationCompleted = 'viewCreatorsOnboardingApplicationCompleted',
  viewCreatorsOnboardingApplicationRejected = 'viewCreatorsOnboardingApplicationRejected',

  // Creators collaborations
  viewCreatorsCollaborationView = 'viewCreatorsCollaborationView',
  viewCreatorsCollaborationReviews = 'viewCreatorsCollaborationReviews',
  creatorLeftBrandReview = 'creatorLeftBrandReview',

  // Account Settings
  viewCreatorsProfile = 'viewCreatorsProfile',
  viewCreatorsPersonalInformation = 'viewCreatorsPersonalInformation',
  viewCreatorsEditShippingAddress = 'viewCreatorsEditShippingAddress',
  viewCreatorsEditPassword = 'viewCreatorsEditPassword',
  viewCreatorsEditPaused = 'viewCreatorsEditPaused',

  // Team and Invitations
  teamMemberSignedUp = 'teamMemberSignedUp',
}
/**
 * Tracking complete registration for brands - completed onboarding
 */

export const trackCompleteRegistration = () => {
  // @ts-ignore
  window.fbq('track', 'CompleteRegistration')
  mixpanel.track(AnalyticsEvent.brandSignedUp)

  // @ts-ignore
  window.gtag('event', AnalyticsEvent.brandSignedUp)

  // @ts-ignore
  window.gtag('event', 'complete_registration')
}

/**
 * this is tracking completing the onboarding and getting to checkout page
 */
export const trackAddToCart = () => {
  // @ts-ignore - Facebook
  window.fbq('track', 'AddToCart')

  // @ts-ignore - Google
  window.gtag('event', 'add_to_cart')

  // Mixpanel
  mixpanel.track(AnalyticsEvent.brandSignedUp)
}

export const trackInitiateCheckout = (
  input: CreateTrackPurchaseInput = trackPurchaseInputDefault,
) => {
  // @ts-ignore - Facebook
  window.fbq('track', 'InitiateCheckout')

  // @ts-ignore - Google
  window.gtag('event', 'begin_checkout')

  // Mixpanel
  mixpanel.track(AnalyticsEvent.initiateCheckout)
}

export const trackPurchase = (
  input: CreateTrackPurchaseInput = trackPurchaseInputDefault,
) => {
  // @ts-ignore Facebook
  window.fbq('track', 'Purchase', {
    eventID: `Purchase-${input.transactionId}`,
    value: input.priceCents / 100,
    currency: input.currency.toLocaleUpperCase(),
  })

  // Mixpanel
  mixpanel.track(AnalyticsEvent.purchase)

  // @ts-ignore
  window.gtag('event', 'purchase', {
    transaction_id: input.transactionId, // A unique transaction ID for this purchase.
    affiliation: 'Clip App', // The store or affiliation from which this transaction occurred (if applicable).
    value: input.priceCents / 100, // The total value of the transaction.
    currency: input.currency.toLocaleUpperCase(), // The currency of the transaction.
    tax: 0.0, // The total tax associated with the transaction.
    shipping: 0.0, // The shipping cost associated with the transaction.
  })
}

export const trackFirstPurchase = (
  input: CreateTrackPurchaseInput = trackPurchaseInputDefault,
) => {
  // @ts-ignore Facebook
  window.fbq('track', AnalyticsEvent.firstPurchase, {
    eventID: `firstPurchase-${input.transactionId}`,
    value: input.priceCents / 100,
    currency: input.currency.toLocaleUpperCase(),
  })

  // Mixpanel
  mixpanel.track(AnalyticsEvent.firstPurchase)

  // @ts-ignore
  window.gtag('event', AnalyticsEvent.firstPurchase)
}
