import React, { useRef } from 'react'
import { useNotification } from '../../utils/hooks'
import { NotificationType } from '../Notification'

interface IProps {
  onChange: (
    file: File,
    size?: {
      w: number | null
      h: number | null
    },
  ) => void
  id?: string
  name?: string
  className?: string
}

export const VideoFileInput: React.FC<IProps> = ({
  id,
  name,
  className,
  onChange,
}) => {
  const { addNotification } = useNotification()
  const fileInputRef = useRef<HTMLInputElement>(null)

  const isVideoSupported = (fileType: string): boolean => {
    const video = document.createElement('video')
    return !!video.canPlayType(fileType)
  }

  // Function to check if the file type is a valid video extension
  const isValidVideoExtension = (filename: string): boolean => {
    const validVideoExtensions = ['.mp4', '.mov', '.mpeg', '.mpg', '.m4v']

    // Extract the file extension
    const fileExtension = filename
      .slice(filename.lastIndexOf('.'))
      .toLowerCase()

    // Check if the file extension exists in the list of valid video extensions
    return validVideoExtensions.includes(fileExtension)
  }

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0]

    if (!file) return

    // If a valid video extension, no need to check contents of the file.
    if (isValidVideoExtension(file.name)) {
      // Create a URL for the file
      const videoUrl = URL.createObjectURL(file)

      // Create a temporary video element
      const video = document.createElement('video')
      video.src = videoUrl

      video.onloadedmetadata = () => {
        onChange(file, {
          w: video.videoWidth,
          h: video.videoHeight,
        })
      }

      return
    }

    if (!isVideoSupported(file.type)) {
      console.error('File format is not supported in this browser.')
      addNotification(
        'Selected file format is not supported in this browser',
        NotificationType.error,
      )

      return
    }

    const reader = new FileReader()

    // Create a URL for the file
    const videoUrl = URL.createObjectURL(file)

    // Create a temporary video element
    const video = document.createElement('video')
    video.src = videoUrl

    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (e.target && e.target.result) {
        const video = document.createElement('video')

        video.onloadedmetadata = () => {
          onChange(file, {
            w: video.videoWidth,
            h: video.videoHeight,
          })
        }

        video.onerror = (e) => {
          console.error(e)
          console.error('File is not a valid video')
          addNotification(
            'Selected file is not a valid video',
            NotificationType.error,
          )
        }

        video.src = e.target.result as string
      }
    }

    reader.readAsDataURL(file)
  }

  return (
    <input
      id={id}
      name={name}
      type='file'
      accept='video/*'
      ref={fileInputRef}
      className={className}
      onChange={onFileChange}
    />
  )
}
