import { gql } from '@apollo/client'
import { CreatorFragment } from './creators'
import { GetUploadUrlResponse } from './types'

export const UserFragment = gql`
  fragment UserFragment on User {
    id
    type
    firstName
    lastName
    email
    bio
    phoneNumber

    avatar {
      name
      url
    }
  }
`

export const SessionFragment = gql`
  fragment SessionFragment on Session {
    id
    token
    expiresAt
    active
  }
`

export const UserBrandFragment = gql`
  fragment UserBrandFragment on UserBrand {
    id
    uuid
    isActive
    roles
    createdAt
    isDefaultContactUser
  }
`

export const NotificationsFragment = gql`
  fragment NotificationsFragment on UserNotifications {
    id
    isMarketingEnabled
    isReviewsEnabled
    isApplicantsReminderEnabled
    isDailyDigestEnabled
  }
`

export const GET_SELF = gql`
  query GetSelf {
    getSelf {
      ...UserFragment

      creator {
        ...CreatorFragment
      }
      notifications {
        ...NotificationsFragment
      }
    }
  }
  ${UserFragment}
  ${CreatorFragment}
  ${NotificationsFragment}
`

export const CREATE_USER = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        ...UserFragment

        creator {
          ...CreatorFragment
        }
      }
      session {
        ...SessionFragment
      }
    }
  }
  ${UserFragment}
  ${SessionFragment}
  ${CreatorFragment}
`

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      user {
        ...UserFragment

        creator {
          ...CreatorFragment
        }
      }

      session {
        ...SessionFragment
      }
    }
  }
  ${UserFragment}
  ${CreatorFragment}
  ${SessionFragment}
`

export const UPDATE_USER = gql`
  mutation UpdateUser($id: Int!, $input: UpdateUserInput!) {
    updateUser(id: $id, input: $input) {
      ...UserFragment
    }
  }
  ${UserFragment}
`

export const GET_USER_AVATAR_UPLOAD_URL = gql`
  mutation GetUserAvatarUploadUrl($userId: Int!, $mime: String!) {
    getUserAvatarUploadUrl(userId: $userId, mime: $mime) {
      uploadUrl
      fileName
    }
  }
`

export const UPDATE_USER_PASSWORD = gql`
  mutation UpdateUserPassword($oldPassword: String!, $newPassword: String!) {
    updateUserPassword(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`

export const VERIFY_USER_EMAIL = gql`
  mutation VerifyUserEmail($token: String!) {
    verifyUserEmail(token: $token)
  }
`

export const RESET_PASSWORD = gql`
  mutation ResetPassword($email: String!) {
    resetPassword(email: $email)
  }
`

export const UPDATE_PASSWORD_WITH_TOKEN = gql`
  mutation UpdatePasswordWithToken($password: String!, $token: String!) {
    updatePasswordWithToken(password: $password, token: $token)
  }
`

export const GET_USER_BRAND = gql`
  query GetUserBrand($brandId: Int!) {
    getUserBrand(brandId: $brandId) {
      ...UserBrandFragment
    }
  }
  ${UserBrandFragment}
`

export const UPDATE_USER_NOTIFICATIONS = gql`
  mutation UpdateUserNotifications(
    $userId: Int!
    $input: UpdateUserNotificationsInput!
  ) {
    updateUserNotifications(userId: $userId, input: $input) {
      ...NotificationsFragment
    }
  }
  ${NotificationsFragment}
`

export interface GetUserAvatarUploadUrlResponse {
  getUserAvatarUploadUrl: GetUploadUrlResponse
}
