import { useMutation } from '@apollo/client'
import { FunctionComponent, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { UPDATE_USER_PASSWORD } from '../../../../gql'
import { useNotification } from '../../../../utils/hooks'
import { Button, NotificationType, TextField } from '../../../../components'
import { ErrorCode } from '../../../../utils/ErrorCode'
import { AnalyticsEvent, trackEvent } from '../../../../utils/analytics'

interface IProps {}

export type EditPasswordFormData = {
  oldPassword: string
  newPasswordFirstTry: string
  newPasswordSecondTry: string
}

const EditPassword: FunctionComponent<IProps> = () => {
  const { addNotification } = useNotification()

  const [updateUserPassword, { loading }] = useMutation(UPDATE_USER_PASSWORD)

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
  } = useForm<EditPasswordFormData>({
    defaultValues: {
      oldPassword: '',
      newPasswordFirstTry: '',
      newPasswordSecondTry: '',
    },
  })

  useEffect(() => {
    trackEvent(AnalyticsEvent.viewCreatorsEditPassword)
  }, [])

  const onSubmit = async (data: EditPasswordFormData) => {
    try {
      await updateUserPassword({
        variables: {
          oldPassword: data.oldPassword,
          newPassword: data.newPasswordFirstTry,
        },
      })

      addNotification('Password updated successfully', NotificationType.success)
    } catch (error) {
      console.log('error EditPassword', error.message)
      if (error.message === ErrorCode.invalidPasswordForUpdate) {
        setError('oldPassword', {
          type: 'custom',
          message: `Old password is wrong.`,
        })
        return
      }
    }
  }

  const OldPassword = () => {
    return (
      <TextField
        type='password'
        label='Old password'
        placeholder='******'
        error={errors.oldPassword?.message}
        {...register('oldPassword', {
          required: `Please set your old password to make sure it's you.`,
          minLength: {
            value: 6,
            message: `Your old password should have at least 6 characters.`,
          },
          maxLength: {
            value: 100,
            message: `Your old password should have max 100 characters.`,
          },
        })}
      />
    )
  }

  const NewPassword = () => {
    return (
      <TextField
        type='password'
        label='New password'
        placeholder='******'
        error={errors.newPasswordFirstTry?.message}
        {...register('newPasswordFirstTry', {
          required: `Don't forget to set a new password.`,
          minLength: {
            value: 6,
            message: `Please set a password with at least 6 characters.`,
          },
          maxLength: {
            value: 100,
            message: `Please set a password with max 100 characters.`,
          },
          validate: {
            value: (newPasswordFirstTry) => {
              if (newPasswordFirstTry !== watch('oldPassword')) {
                return true
              }

              return 'The new password matches the old password.'
            },
          },
        })}
      />
    )
  }

  const NewPasswordConfirmation = () => {
    return (
      <TextField
        type='password'
        label='Repeat new password'
        placeholder='******'
        error={errors.newPasswordSecondTry?.message}
        {...register('newPasswordSecondTry', {
          required: `Don't forget to repeat the new password to make sure it's correct.`,
          minLength: {
            value: 6,
            message: `Please set a password with at least 6 characters.`,
          },
          maxLength: {
            value: 100,
            message: `Please set a password with max 100 characters.`,
          },
          validate: {
            value: (newPasswordSecondTry) => {
              if (newPasswordSecondTry === watch('newPasswordFirstTry')) {
                return true
              }

              return `Your new password doesn't match.`
            },
          },
        })}
      />
    )
  }

  return (
    <div className='grid grid-cols-1 gap-x-8 gap-y-8 pt-10 lg:grid-cols-3'>
      <div className='px-0'>
        <h2 className='text-lg leading-6 font-medium text-gray-900'>
          Update password
        </h2>
        <p className='mt-1 text-sm text-gray-500'>
          Make sure you choose a secure password. Please have minimum 6
          characters.
        </p>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className='bg-white shadow ring-1 ring-gray-900/5 sm:rounded-xl lg:col-span-2'
      >
        <div className='px-4 py-6 sm:p-8'>
          <div className='grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6'>
            <div className='sm:col-span-full'>{OldPassword()}</div>
            <div className='sm:col-span-3'>{NewPassword()}</div>
            <div className='sm:col-span-3'>{NewPasswordConfirmation()}</div>
          </div>
        </div>
        <div className='flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8'>
          <Button
            title='Update'
            type='submit'
            loading={loading}
            disabled={loading}
          />
        </div>
      </form>
    </div>
  )
}

export { EditPassword }
