import { Dialog, Transition } from '@headlessui/react'
import { loadStripe } from '@stripe/stripe-js'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import {
  Badge,
  BadgeSize,
  BadgeType,
  Button,
  ButtonColor,
  Icon,
  IconType,
  NotificationType,
  TextArea,
  TooltipInfo,
} from '../../../components'
import env from '../../../config/env'
import { Collaboration, CreatorQuality, CountryCode } from '../../../gql/types'
import { useCreateHooksCheckout } from '../../../hooks/payments'
import {
  formatPriceInCents,
  formattedHooksPrice,
} from '../../../utils/PricingUtil'
import { useNotification } from '../../../utils/hooks'
import { usePricing } from '../../../hooks'
import { UrlBuilder } from '../../../utils/UrlBuilder'
import classNames from 'classnames'

const stripePromise = loadStripe(env.STRIPE_KEY)

type EditCampaignFormData = {
  quality: CreatorQuality
  hooks: string[]
}

interface IProps {
  collaboration: Collaboration
  open?: boolean
  setOpen?: (event: any) => void
  description: string
  title: string
}

export const OrderHooks: React.FC<IProps> = ({
  collaboration,
  open,
  setOpen,
  title,
  description,
}) => {
  const campaign = collaboration.campaign
  const { brand } = campaign
  const { addNotification } = useNotification()
  const { pricing } = usePricing()
  const [creditPayment, setCreditPayment] = useState(false)
  const [initiateCheckoutLoading, setInitiateCheckoutLoading] = useState(false)
  const { createHooksCheckout, loading } = useCreateHooksCheckout()
  const cancelButtonRef = useRef(null)

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
  } = useForm<EditCampaignFormData>({
    defaultValues: {
      quality: campaign.quality ?? CreatorQuality.regular,
      hooks: [],
    },
  })

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: 'hooks' as never, // unique name for your Field Array
    },
  )

  useEffect(() => {
    append('')
  }, [collaboration.id])

  const initiateCheckout = async () => {
    setInitiateCheckoutLoading(true)

    try {
      const { data } = await createHooksCheckout({
        variables: {
          brandId: brand.id,
          input: {
            hooks: watch('hooks'),
            collaborationId: collaboration.id,
            successRedirectUrlPath: UrlBuilder.buildCampaignCollaboratorsUrl(
              brand.id,
              collaboration.campaign.id,
            ),
            cancelRedirectUrlPath: UrlBuilder.buildCampaignCollaboratorsUrl(
              brand.id,
              collaboration.campaign.id,
            ),
          },
        },
      })

      // if no stripe session it means the purchase was made with credits
      if (!data?.createHooksCheckout?.stripeSessionId) {
        addNotification(
          `Your variation(s) order has been confirmed.`,
          NotificationType.success,
        )
      }

      // if stripe session id present - redirect to stripe
      if (data?.createHooksCheckout?.stripeSessionId) {
        // if stripe session id present - redirect to stripe
        const stripe = await stripePromise

        // When the customer clicks on the button, redirect them to Checkout.
        const result = await stripe.redirectToCheckout({
          sessionId: data?.createHooksCheckout?.stripeSessionId,
        })

        if (result.error) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
          addNotification(
            `Something went wrong. Please try again or contact support at hello@useclip.com.`,
            NotificationType.error,
          )
        }
      }

      setOpen(false)
      reset()
    } catch (error) {
      addNotification(
        // `Something went wrong. Please try again or contact support at hello@useclip.com.`,
        error.message,
        NotificationType.error,
      )
    }

    setInitiateCheckoutLoading(false)
  }

  const renderHooksPrice = () => {
    if (!watch('hooks') || watch('hooks')?.length === 0) {
      return null
    }

    const formattedPrice = formattedHooksPrice({
      hooksCount: watch('hooks')?.length,
      currency: campaign.currency,
      duration: campaign.videoDuration,
      pricing,
    })

    return (
      <div className='border rounded-lg shadow-sm px-3 py-3 flex justify-start items-center'>
        <div className='flex flex-row justify-between w-full text-sm'>
          <div className='font-medium text-gray-500 ml-1'>
            {formattedPrice}
            {brand.countryCode === CountryCode.GB && <span>{` `}+ VAT</span>}
          </div>
          <div>
            <Badge
              type={BadgeType.indigo}
              iconPosition='left'
              text={
                <TooltipInfo
                  valueKey='hooks-badge'
                  content={`${watch('hooks')?.length} variations`}
                  tooltipText='Each variation is an altered version of the original video, featuring a unique hook or call-to-action. The duration of each variation is up to 5 seconds.'
                />
              }
              size={BadgeSize.small}
              className='!mr-0'
            />
          </div>
        </div>
      </div>
    )
  }

  const renderPayWithCreditInput = () => {
    if (campaign.currency !== brand.currency) {
      return null
    }

    if (brand.creditCents <= 0) {
      return null
    }

    const formattedCredit = formatPriceInCents(
      {
        priceInCents: brand.creditCents,
        currency: campaign.currency,
      },
      { minimumFractionDigits: 0 },
    )

    return (
      <div className='relative flex items-start mt-3'>
        <div className='flex h-6 items-center'>
          <input
            id='payWithCredit'
            aria-describedby='payWithCredit-description'
            name='payWithCredit'
            type='checkbox'
            // checked={creditPayment}
            checked={true}
            disabled
            onChange={() => setCreditPayment((prev) => !prev)}
            className={classNames(
              'h-4 w-4 rounded border-gray-300 text-gray-600 disabled:accent-gray-300 focus:ring-gray-500 cursor-pointer',
              creditPayment
                ? 'focus:ring-gray-800 accent-primary-800'
                : 'accent-gray-300',
            )}
          />
        </div>
        <div className='ml-3 text-sm leading-6'>
          <label
            htmlFor='payWithCredit'
            className='font-medium text-gray-900 cursor-pointer'
          >
            Use credits
          </label>
          <label
            htmlFor='payWithCredit'
            id='payWithCredit-description'
            className='block leading-4 text-gray-500 cursor-pointer'
          >
            You have {formattedCredit} remaining credits. Credits will
            automatically be applied.
          </label>
        </div>
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit(initiateCheckout)}>
      <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
        <div className='sm:flex sm:items-start '>
          <div className='mt-3 sm:mt-0 sm:ml-0 sm:text-left'>
            <h3 className='text-lg leading-6 font-medium text-gray-900'>
              Buy more variations
            </h3>
            <div className='my-2'>
              <p className='text-sm text-gray-500'>
                Each variation is an altered version of the original video,
                featuring a unique hook or call-to-action. The duration of each
                variation is up to 5 seconds
              </p>
            </div>
            <div className='grid gap-6 h-[450px] sm:h-[240px] overflow-y-auto p-2 -mx-2'>
              <div className='col-span-3 sm:col-span-2'>
                <ul>
                  {fields.map((field, index) => (
                    <li key={field.id} className='relative'>
                      <Controller
                        name={`hooks.${index}`}
                        control={control}
                        rules={{
                          required: `Please set a variation.`,
                        }}
                        render={({ field }) => (
                          <TextArea
                            error={errors.hooks?.[index]?.message}
                            placeholder='Add hook or other variation'
                            minRows={3}
                            field={field}
                            {...register(`hooks.${index}`, {
                              minLength: {
                                value: 10,
                                message: `Please write a descriptive variation.`,
                              },
                              maxLength: {
                                value: 10000,
                                message: `Your variation text is too long`,
                              },
                            })}
                            autoFocus={index === fields.length}
                          />
                        )}
                      />
                      <div
                        onClick={() => remove(index)}
                        className='absolute right-1 top-1 cursor-pointer'
                      >
                        <Icon
                          type={IconType.x}
                          className='h-5 w-5 text-primary'
                          aria-hidden='true'
                        />
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className='pt-3 flex flex-row justify-between'>
              <Button
                title='New variation'
                type='button'
                colour={ButtonColor.indigo}
                onClick={() => append('')}
                icon={
                  <Icon
                    type={IconType.plus}
                    className='h-5 w-5 text-white'
                    aria-hidden='true'
                  />
                }
                iconPosition='left'
                className='w-full sm:w-auto mr-2'
              />
              <div className='w-full items-center'>{renderHooksPrice()}</div>
            </div>
            {renderPayWithCreditInput()}
          </div>
        </div>
      </div>
      <div className='bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse'>
        <Button
          colour={ButtonColor.primary}
          title='Confirm your order'
          type='submit'
          loading={initiateCheckoutLoading}
          className='w-full sm:w-auto'
        />
        <button
          type='button'
          className='mt-3 mr-3 w-full inline-flex justify-center items-center rounded-md border border-gray-300 shadow-sm px-4 py-1 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
          onClick={() => setOpen(false)}
          ref={cancelButtonRef}
        >
          Cancel
        </button>
      </div>
    </form>
  )
}
