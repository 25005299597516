import React, { useRef } from 'react'
import { useNotification } from '../../utils/hooks'
import { NotificationType } from '../Notification'

interface IProps {
  onChange: (file: File) => void
  id?: string
  name?: string
  className?: string
}

export const ImageFileInput: React.FC<IProps> = ({
  id,
  name,
  className,
  onChange,
}) => {
  const { addNotification } = useNotification()

  const fileInputRef = useRef<HTMLInputElement>(null)

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0]

    if (!file) return

    if (file.type.includes('gif')) {
      addNotification(`Gif format is not allowed.`, NotificationType.error)
      return
    }

    const reader = new FileReader()

    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (e.target && e.target.result) {
        const img = new Image()

        img.onload = () => {
          onChange(file)
        }

        img.onerror = () => {
          console.error('File is not a valid image')
          addNotification(
            'Selected file is not a valid image',
            NotificationType.error,
          )
        }

        img.src = e.target.result as string
      }
    }

    reader.readAsDataURL(file)
  }

  return (
    <input
      id={id}
      name={name}
      type='file'
      // accept='image/*'
      accept='image/png, image/jpeg, image/avif'
      // accept='image/png, image/jpeg, image/avif, image/bmp, image/vndmicrosofticon, image/svg+xml, image/tiff, image/webp'
      ref={fileInputRef}
      className={className}
      onChange={onFileChange}
    />
  )
}
