import { useMutation } from '@apollo/client'
import { ConfirmationModal, NotificationType } from '../../../../components'
import { DELETE_COLLABORATION_BY_CREATOR } from '../../../../gql'
import { Collaboration, Creator } from '../../../../gql/types'
import { AnalyticsEvent, trackEvent } from '../../../../utils/analytics'
import { useNotification } from '../../../../utils/hooks'

interface IProps {
  creator: Creator
  collaboration: Collaboration
  isOpen: boolean
  setIsOpen: (event: any) => void
}

const WithdrawFromCollaborationModal = ({
  creator,
  collaboration,
  isOpen,
  setIsOpen,
}: IProps) => {
  const { addNotification } = useNotification()
  const [deleteCollaborationByCreator, { loading }] = useMutation(
    DELETE_COLLABORATION_BY_CREATOR,
    {
      update(cache) {
        const normalizedId = cache.identify(collaboration)
        cache.evict({ id: normalizedId })
        cache.gc()
      },
    },
  )

  const closeModal = () => {
    setIsOpen(false)
  }

  const onDeleteCollaboration = async () => {
    try {
      await deleteCollaborationByCreator({
        variables: {
          creatorId: creator.id,
          collaborationId: collaboration.id,
        },
      })

      trackEvent(AnalyticsEvent.creatorWithdrawFromCollaboration, {
        creatorId: creator.id,
        collaborationId: collaboration.id,
      })

      closeModal()
      addNotification(`Application withdrawn.`, NotificationType.success)
    } catch (error) {
      addNotification(error.message, NotificationType.error)
    }
  }

  return (
    <ConfirmationModal
      title='Confirm'
      description={`Are you certain you wish to withdraw your application to this campaign?`}
      open={isOpen}
      setOpen={setIsOpen}
      okButtonTitle='Withdraw Application'
      onAction={() => onDeleteCollaboration()}
    />
  )
}

export { WithdrawFromCollaborationModal }
