import { ApolloCache, useMutation } from '@apollo/client'
import {
  CREATE_ADDONS_CHECKOUT,
  CREATE_CAMPAIGN_CHECKOUT,
  CREATE_HOOKS_CHECKOUT,
  GET_CAMPAIGNS_BY_BRAND_ID,
  MutationCreateAddonsCheckoutResponse,
  MutationCreateCampaignCheckoutResponse,
  MutationCreateHooksCheckoutResponse,
} from '../gql'
import {
  GetCampaignsSort,
  MutationCreateAddonsCheckoutArgs,
  MutationCreateCampaignCheckoutArgs,
  MutationCreateHooksCheckoutArgs,
} from '../gql/types'
import { useParams } from 'react-router-dom'

export const useCreateCampaignCheckout = () => {
  const { id: brandId } = useParams()

  const [createCampaignCheckout, { loading }] = useMutation<
    MutationCreateCampaignCheckoutResponse,
    MutationCreateCampaignCheckoutArgs
  >(CREATE_CAMPAIGN_CHECKOUT, {
    update: (cache: ApolloCache<any>, { data: { createCampaignCheckout } }) => {
      // console.log('campaign checkout created', createCampaignCheckout)
    },
    refetchQueries: [
      {
        query: GET_CAMPAIGNS_BY_BRAND_ID,
        variables: {
          brandId: parseInt(brandId),
          options: {
            query: '',
            filters: {},
            sort: GetCampaignsSort.lastUpdated,
            skip: 0,
            limit: 20,
          },
        },
      },
    ],
  })

  return { createCampaignCheckout, loading }
}

export const useCreateHooksCheckout = () => {
  const [createHooksCheckout, { loading }] = useMutation<
    MutationCreateHooksCheckoutResponse,
    MutationCreateHooksCheckoutArgs
  >(CREATE_HOOKS_CHECKOUT, {
    update: (cache: ApolloCache<any>, { data: { createHooksCheckout } }) => {
      // TO DO - not working update cache ??
      // console.log('hooks checkout created', createHooksCheckout)
    },
  })

  return { createHooksCheckout, loading }
}

export const useCreateAddonsCheckout = () => {
  const [createAddonsCheckout, { loading }] = useMutation<
    MutationCreateAddonsCheckoutResponse,
    MutationCreateAddonsCheckoutArgs
  >(CREATE_ADDONS_CHECKOUT, {
    update: (cache: ApolloCache<any>, { data: { createAddonsCheckout } }) => {
      // TO DO - not working update cache ??
      // console.log('addons checkout created', createAddonsCheckout)
    },
  })

  return { createAddonsCheckout, loading }
}
