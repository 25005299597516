import React from 'react'
import { User } from '../../../gql/types'
import { Header } from '../components/Header'
import { Products } from '../../Products'

interface IProps {
  user: User
}

export const BrandProducts = ({ user }: IProps) => {
  return (
    <div>
      <Header />

      <Products user={user} />
    </div>
  )
}
