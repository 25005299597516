import { Button, ButtonColor } from '../../../../../components'
import { Collaboration } from '../../../../../gql/types'

interface IProps {
  collaboration: Collaboration
  setSelectedCollaboration(collaboration: Collaboration): void
  setIsCreatorSliderOpen(isSliderOpen: boolean): void
  className?: string
}

interface IDefaultProps {}

const defaultProps: IDefaultProps = {}

interface IProps extends Partial<IDefaultProps> {}

const GridButtons = ({
  collaboration,
  setSelectedCollaboration,
  setIsCreatorSliderOpen,
  className,
}: IProps) => {
  return (
    <>
      <div>
        <div className='-mt-px flex divide-x divide-gray-200'>
          {/* <div className='-ml-px flex w-0 flex-1 justify-center'></div> */}
          <div className='-ml-px flex w-0 flex-1 justify-center'>
            <Button
              title='Quick view'
              type='button'
              colour={ButtonColor.primary}
              onClick={() => {
                setSelectedCollaboration(collaboration)
                setIsCreatorSliderOpen(true)
              }}
              className='w-full group flex items-center px-4 py-4 text-sm cursor-pointer text-gray-800'
            />
          </div>
        </div>
      </div>
    </>
  )
}

GridButtons.defaultProps = defaultProps

export { GridButtons }
