export enum BrandRoutes {
  default = '/',

  prefix = '/b/',
  onboarding = '/b/onboarding',
  pending = '/b/pending',
  create = '/b/brands/create',
  brand = '/b/:id/edit',

  /** Campaign Settings */
  campaigns = '/b/:id/campaigns',
  campaignView = '/b/:id/campaigns/:campaignId/view',
  campaignApplicants = '/b/:id/campaigns/:campaignId/applicants',
  campaignCollaborators = '/b/:id/campaigns/:campaignId/collaborators',
  campaignClips = '/b/:id/campaigns/:campaignId/clips',
  newCampaign = '/b/:id/campaigns/new',
  campaignEdit = '/b/:id/campaigns/:campaignId/edit',

  /** Collaborations */
  clips = '/b/:id/clips',
  collaborationViewProfile = '/b/:id/collaborations/:uuid/profile',
  collaborationViewReviews = '/b/:id/collaborations/:uuid/reviews',
  collaborationViewClips = '/b/:id/collaborations/:uuid/clips',

  /** Inspirations */
  inspiration = '/b/:id/inspiration',
  newInspiration = '/b/:id/inspiration/new',

  /** Delivery */
  delivery = '/b/:id/delivery/:searchStatus',

  /** Products */
  products = '/b/:id/products',
  newProduct = '/b/:id/products/new',
  productView = '/b/:id/products/:productId/view',
  productEdit = '/b/:id/products/:productId/edit',

  /** Billing */
  billing = '/b/:id/billing',

  /** Credit Packs */
  creditPacks = '/b/:id/credit-packs',

  /** Team */
  team = '/b/:id/team',
  invitations = '/b/:id/invitations',

  /** Account Settings */
  accountProfile = '/b/:id/account/profile/edit',
  accountPassword = '/b/:id/account/profile/password',
  accountReviews = '/b/:id/account/profile/reviews',

  /** Payments */
  confirmPayment = `/b/payments/success`,
  confirmCreditPackPayment = `/b/payments/credit-pack/success`,
  confirmVariationsPayment = `/b/payments/variations/success`,
  confirmAddonsPayment = `/b/payments/addons/success`,

  /** Inbox */
  inbox = '/b/:id/inbox/',
  inboxChat = '/b/:id/inbox/:uuid',

  /** Templates */
  templates = '/b/:id/templates',
}
