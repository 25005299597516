import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { PageTabs } from '../../../../components'
import { BrandRoutes } from '../../../BrandRoutes'

const Header = () => {
  const { id } = useParams()

  const navigation = useMemo(
    () => [
      {
        text: 'Due to ship',
        count: null,
        to: BrandRoutes.delivery
          .replace(':id', `${id}`)
          .replace(':searchStatus', `due`),
      },
      {
        text: 'Shipped',
        count: null,
        to: BrandRoutes.delivery
          .replace(':id', `${id}`)
          .replace(':searchStatus', `shipped`),
      },
      // {
      //   text: 'Pending content approval',
      //   count: null,
      //   to: BrandRoutes.delivery
      //     .replace(':id', `${id}`)
      //     .replace(':searchStatus', `contentUploaded`),
      // },
      {
        text: 'Finished',
        count: null,
        to: BrandRoutes.delivery
          .replace(':id', `${id}`)
          .replace(':searchStatus', `completed`),
      },
    ],
    [id],
  )

  return (
    <div className='flex flex-col -mx-4 sm:-mx-6 md:mx-0 py-4'>
      <div className='grid grid-cols-1 lg:flex lg:flex-row lg:justify-between mt-5'>
        <div className='col-span-1'>
          <div className='flex flex-row justify-between'>
            <h1 className='font-bold text-3xl font-outfit'>Delivery</h1>
          </div>
        </div>
      </div>

      {/* Navigation */}
      <PageTabs
        navigation={navigation}
        menuPosition='right'
        className='mt-4 md:mt-0'
      />
    </div>
  )
}

export { Header }
