import { useEffect } from 'react'
import validator from 'validator'
import {
  Button,
  ButtonColor,
  Icon,
  IconType,
  TextField,
} from '../../../../components'
import { Controller, useFieldArray } from 'react-hook-form'

interface Props {
  errors: any
  register: any
  watch: any
  control: any
}

const EditInspirationVideosLinks = ({
  errors,
  register,
  watch,
  control,
}: Props) => {
  const {
    fields: inspirationVideosLinksFields,
    append: inspirationVideosLinksAppend,
    remove: inspirationVideosLinksRemove,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'inspirationVideosLinks', // unique name for your Field Array
  })

  return (
    <div>
      <div className='mt-10 sm:mt-0'>
        <div className='md:grid md:grid-cols-3 md:gap-6'>
          <div className='md:col-span-1'>
            <div className=''>
              <h3 className='text-lg font-semibold font-sans leading-6 text-gray-900'>
                Inspiration
              </h3>
              <p className='mt-2 text-sm font-normal leading-5 text-gray-500 font-sans'>
                Share links to UGC examples that you would like creators to use
                as inspiration for videos (public links to Instagram, TikTok,
                Facebook, Google Drive or others).
              </p>
            </div>
          </div>
          <div className='mt-5 md:col-span-2 md:mt-0'>
            <div className='md:shadow rounded-t-md sm:overflow-hidden'>
              <div className='py-5 md:bg-white sm:p-6'>
                <div className='grid gap-6 max-h-[400px] overflow-y-auto'>
                  <div className='col-span-3 sm:col-span-2'>
                    <ul>
                      {inspirationVideosLinksFields.map((item, index) => (
                        <li key={item.id} className='relative mb-4'>
                          <Controller
                            name={`inspirationVideosLinks.${index}`}
                            control={control}
                            rules={{
                              maxLength: {
                                value: 1000,
                                message: `The link cannot have more than 1000 characters.`,
                              },
                              validate: {
                                value: (url) => {
                                  if (validator.isURL(url)) {
                                    return true
                                  }

                                  return 'Please enter a valid URL'
                                },
                              },
                            }}
                            render={({ field }) => (
                              <TextField
                                error={
                                  errors.inspirationVideosLinks?.[index]
                                    ?.message
                                }
                                placeholder='www.example.com'
                                // className={'rounded-none rounded-r-md'}
                                field={field}
                                watch={watch}
                                // leftElement={
                                //   <span className='inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm'>
                                //     https://
                                //   </span>
                                // }
                                {...register('inspirationVideosLinks')}
                                autoFocus={
                                  index === inspirationVideosLinksFields.length
                                }
                              />
                            )}
                          />
                          <div
                            onClick={() => inspirationVideosLinksRemove(index)}
                            className='absolute right-1 top-1 cursor-pointer'
                          >
                            <Icon
                              type={IconType.x}
                              className='h-5 w-5 text-primary'
                              aria-hidden='true'
                            />
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div>
                  <Button
                    title='Add link'
                    type='button'
                    colour={ButtonColor.primary}
                    onClick={() => inspirationVideosLinksAppend('')}
                    icon={
                      <Icon
                        type={IconType.plus}
                        className='h-5 w-5 text-white'
                        aria-hidden='true'
                      />
                    }
                    iconPosition='left'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='hidden sm:block' aria-hidden='true'>
        <div className='py-5'>
          <div className='border-t border-gray-200' />
        </div>
      </div>
    </div>
  )
}

export { EditInspirationVideosLinks }
