// Avatar.tsx
import classNames from 'classnames'
import React, { useState } from 'react'
import { Icon, IconType } from '../../components'

type AvatarProps = {
  src?: string
  alt: string
  type: 'user' | 'brand' | 'product'
  size: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
  onlineStatus?: 'online' | 'offline'
  brandSrc?: string
  isVerified?: boolean
  isSelected?: boolean
  className?: string
}

const sizeClasses = {
  xxs: 'h-8 w-8',
  xs: 'h-10 w-10',
  sm: 'h-12 w-12',
  md: 'h-16 w-16',
  lg: 'h-24 w-24',
  xl: 'h-32 w-16',
  xxl: 'h-24 w-24',
}

const iconClasses = {
  xxs: 'h-4 w-4',
  xs: 'h-5 w-5',
  sm: 'h-6 w-6',
  md: 'h-8 w-8',
  lg: 'h-12 w-12',
  xl: 'h-16 w-32',
  xxl: 'h-48 w-48',
}

const Avatar: React.FC<AvatarProps> = ({
  src,
  alt,
  type,
  size,
  onlineStatus,
  brandSrc,
  isVerified,
  isSelected,
  className,
}) => {
  const [imageError, setImageError] = useState(false)

  const borderRadius = type === 'user' ? 'rounded-full' : 'rounded-lg'
  const borderColor = isSelected ? 'border-gray-600' : 'border-transparent'
  const sizeClass = sizeClasses[size]
  const iconClass = iconClasses[size]

  const defaultIcon =
    type === 'user'
      ? IconType.user
      : type === 'brand'
        ? IconType.store
        : IconType.product

  const avatarClasses = classNames(
    'object-cover',
    borderRadius,
    'border-2',
    borderColor,
    sizeClass,
    className,
  )

  const handleError = () => {
    setImageError(true)
  }

  return (
    <div className={`relative ${sizeClass}`}>
      {src && !imageError ? (
        <img
          className={avatarClasses}
          src={src}
          alt={alt}
          onError={handleError}
        />
      ) : (
        <div
          className={`flex items-center justify-center text-white bg-gray-100 ${sizeClass} ${avatarClasses}`}
        >
          <Icon type={defaultIcon} className={`text-gray-600 ${iconClass}`} />
        </div>
      )}
      {onlineStatus && (
        <span
          className={`absolute bottom-[0px] right-[0px] w-4 h-4 ${
            onlineStatus === 'online' ? 'bg-green-500' : 'bg-red-500'
          } rounded-full border-white border-2`}
        ></span>
      )}
      {type === 'user' && brandSrc && (
        <img
          className={`absolute bottom-[-4px] right-[-4px] w-6 h-6 rounded-lg border-white border-2`}
          src={brandSrc}
          alt='Brand'
        />
      )}
      {isVerified && (
        <span className='absolute bottom-[1px] right-[1px] text-white'>
          {/* You can use a verified icon here */}
          <Icon type={IconType.verified} className='text-blue-500 w-4 h-4' />
        </span>
      )}
    </div>
  )
}

export { Avatar }
