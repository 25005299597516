import { useLazyQuery, useMutation } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { NotificationType } from '../../../../components'
import Multiselect from '../../../../elements/Multiselect'
import {
  ADD_CAMPAIGN,
  GET_CAMPAIGNS_BY_BRAND_AND_INSPIRATION,
  REMOVE_CAMPAIGN,
} from '../../../../gql'
import {
  Campaign,
  GetCampaignsResponse,
  Inspiration,
  QueryGetCampaignsByBrandAndInspirationArgs,
} from '../../../../gql/types'
import { compareArrays } from '../../../../utils/helpers'
import { useNotification } from '../../../../utils/hooks'

interface IProps {
  inspiration: Inspiration
  campaigns: Campaign[]
}

const AddCampaignsToInspiration = ({ inspiration, campaigns }: IProps) => {
  const { id } = useParams()
  const { addNotification } = useNotification()
  const [selectedOptions, setSelectedOptions] = useState([])

  const [getCampaignsByBrandAndInspiration, { loading, error, data }] =
    useLazyQuery<
      { getCampaignsByBrandAndInspiration: GetCampaignsResponse },
      QueryGetCampaignsByBrandAndInspirationArgs
    >(GET_CAMPAIGNS_BY_BRAND_AND_INSPIRATION)

  const [addCampaign, { loading: addCampaignLoading }] =
    useMutation(ADD_CAMPAIGN)
  const [removeCampaign, { loading: removeCampaignLoading }] =
    useMutation(REMOVE_CAMPAIGN)

  useEffect(() => {
    if (data) {
      setSelectedOptions(
        data.getCampaignsByBrandAndInspiration.items.map((campaign) => ({
          value: `${campaign.id}`,
          label: campaign.title,
        })),
      )
    }
  }, [data])

  // passing an updated array of selected
  const onChange = async (newSelectedOptions) => {
    const data = compareArrays(selectedOptions, newSelectedOptions)

    try {
      if (data.type === 'addition') {
        await addCampaign({
          variables: {
            inspirationId: inspiration.id,
            campaignId: parseInt(data.item.value),
          },
        })
      }

      if (data.type === 'deletion') {
        await removeCampaign({
          variables: {
            inspirationId: inspiration.id,
            campaignId: parseInt(data.item.value),
          },
        })
      }

      setSelectedOptions(newSelectedOptions)
    } catch (error) {
      addNotification(`Couldn't update your campaigns`, NotificationType.error)
    }
  }

  const onMenuOpen = async () => {
    await getCampaignsByBrandAndInspiration({
      variables: {
        brandId: parseInt(id),
        inspirationId: inspiration.id,
      },
    })
  }

  const renderCustomText = (count: number) => {
    // return `Select campaigns...`

    if (count === 0) {
      return `No campaigns`
    }

    if (count === 1) {
      return `1 campaign`
    }

    return `${count} campaigns selected`
  }

  return (
    <div>
      <Multiselect
        selected={selectedOptions}
        options={campaigns?.map((campaign) => ({
          value: `${campaign.id}`,
          label: campaign.title,
        }))}
        onChange={onChange}
        onMenuOpen={onMenuOpen}
        maxMenuHeight={250}
        placeholder='Select campaigns...'
        displayTags={false}
        customText={renderCustomText(selectedOptions.length)}
        className='mt-2'
      />
    </div>
  )
}

export { AddCampaignsToInspiration }
