import { useEffect } from 'react'
import classnames from 'classnames'

const RibbonWidget = ({ className }) => {
  const id = `reviewsio-ribbon-widget-component-${Math.floor(
    Math.random() * 10000000 + 1,
  )}`

  useEffect(() => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.id = id
    script.innerHTML = `
      if(window.reviewsBadgeRibbon) {
        reviewsBadgeRibbon("badge-ribbon", {
          store: 'www.useclip.com',
          size: "small",
          mono: true
        })
      }`

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <>
      <div className={classnames('mx-auto w-[300px]', className)}>
        <div id='badge-ribbon'></div>
      </div>
    </>
  )
}

export { RibbonWidget }
