import { useMutation } from '@apollo/client'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { useForm } from 'react-hook-form'
import {
  Button,
  ButtonColor,
  NotificationType,
  TextArea,
} from '../../../../components'
import { APPLY_TO_CAMPAIGN } from '../../../../gql'
import { Campaign, Creator } from '../../../../gql/types'
import { AnalyticsEvent, trackEvent } from '../../../../utils/analytics'
import { useNotification } from '../../../../utils/hooks'

interface IProps {
  creator: Creator
  campaign: Campaign
  invitationId?: number | null
  isOpen: boolean
  setIsOpen: (event: any) => void
  onAppliedToCampaign: () => void
}

const ApplyToCampaignModal = ({
  creator,
  campaign,
  invitationId,
  isOpen,
  setIsOpen,
  onAppliedToCampaign,
}: IProps) => {
  const { addNotification } = useNotification()
  const [applyToCampaign, { loading }] = useMutation(APPLY_TO_CAMPAIGN)

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      message: '',
    },
  })

  const closeModal = () => {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  const onSubmit = async (data) => {
    try {
      await applyToCampaign({
        variables: {
          creatorId: creator.id,
          campaignId: campaign.id,
          input: {
            message: data.message,
            invitationId: invitationId,
          },
        },
      })

      onAppliedToCampaign()

      trackEvent(AnalyticsEvent.creatorAppliedToCampaign, {
        creatorId: creator.id,
        campaignId: campaign.id,
      })

      closeModal()
      addNotification(`Application sent`, NotificationType.success)
    } catch (error) {
      addNotification(error.message, NotificationType.error)
    }
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                  <Dialog.Title
                    as='h3'
                    className='text-lg font-medium leading-6 text-gray-900'
                  >
                    Apply to campaign
                  </Dialog.Title>
                  <div className='mt-2'>
                    {/* <p className='text-sm text-gray-500'>
                      Adding a personalised message is optional but it can help
                      increase your changes of a successful collaboration.
                    </p> */}
                    <p className='text-sm text-gray-500'>
                      The Clip team verifies all messages.
                    </p>
                    <p className='text-sm text-gray-500 mt-2'>
                      Sharing contact details or payment requests / negotiations
                      is banned. Accounts may be blocked.
                    </p>
                  </div>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='mt-4'>
                      <TextArea
                        // rows={5}
                        name='email'
                        label={`Write a private message for ${campaign.brand.name}`}
                        placeholder={`A personal message can highlight why you are an ideal candidate for the campaign.`}
                        error={errors.message?.message}
                        {...register('message', {
                          maxLength: {
                            value: 1000,
                            message: `Your message is too long.`,
                          },
                        })}
                      />
                    </div>

                    <div className='mt-4 flex justify-between'>
                      <div className='py-2'>
                        <Button
                          type='button'
                          title='Cancel'
                          colour={ButtonColor.white}
                          onClick={closeModal}
                        />
                      </div>

                      <div className='py-2'>
                        <Button
                          type='submit'
                          title='Apply to campaign'
                          loading={loading}
                        />
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export { ApplyToCampaignModal }
