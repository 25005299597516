import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { CampaignDetailsBrief, Loader, OrderClipModal } from '../../components'
import { GET_CAMPAIGN_BY_ID, GetCampaignByIdResponse } from '../../gql'
import {
  GetCreatorsSort,
  QueryGetCampaignByIdArgs,
  User,
} from '../../gql/types'
import { Header } from './components/Header'
import { useState } from 'react'

interface IProps {
  user: User
}

const sortOptions = [
  { name: 'Newest', value: GetCreatorsSort.newest },
  { name: 'Oldest', value: GetCreatorsSort.oldest },
  { name: 'Last Updated', value: GetCreatorsSort.lastUpdated },
]

const filterOptions = [
  {
    id: 'status',
    name: 'Status',
    options: [
      { value: 'All', label: 'All' },
      { value: 'Approved', label: 'Approved' },
      { value: 'Archived', label: 'Archived' },
    ],
  },
]

const CampaignView = ({ user }: IProps) => {
  const { id, campaignId } = useParams()

  const [isOrderClipOpen, setIsOrderClipOpen] = useState(false)

  const { loading, error, data, refetch } = useQuery<
    GetCampaignByIdResponse,
    QueryGetCampaignByIdArgs
  >(GET_CAMPAIGN_BY_ID, {
    variables: {
      id: Number(campaignId),
    },
  })

  if (loading || !data?.getCampaignById) {
    return <Loader />
  }

  if (!loading && !data?.getCampaignById) {
    return <p>Campaign does not exist.</p>
  }

  return (
    <div>
      <Header />

      {loading && <Loader />}

      {isOrderClipOpen && (
        <OrderClipModal
          campaign={data?.getCampaignById}
          title={`Buy more Clips`}
          description={`Select the number of additional videos you would like and hit 'Confirm your order' below.`}
          open={isOrderClipOpen}
          setOpen={setIsOrderClipOpen}
        />
      )}

      {/* If already accepted all creators that you bought videos for */}
      {data?.getCampaignById &&
        data?.getCampaignById?.videoQuantity ===
          data?.getCampaignById?.collaboratorsCount && (
          <div className='text-white text-sm font-medium bg-primary-700 rounded-lg p-4 mb-4'>
            <p>
              You're out of clips, creators can't apply to your campaign. If
              you'd like more applicants,{' '}
              <span
                className='text-blue-400 hover:text-blue-300 font-semibold cursor-pointer'
                onClick={() => setIsOrderClipOpen(true)}
              >
                purchase additional clips
              </span>
              .
            </p>
          </div>
        )}

      <div className='flex flex-col space-y-4 md:flex-row md:justify-between md:w-full md:space-x-4 md:space-y-0 mb-14'>
        {/* <div className='flex md:flex-row md:justify-between md:w-full md:space-x-4'> */}
        <CampaignDetailsBrief
          campaign={data?.getCampaignById}
          hooks={data?.getCampaignById?.hooks?.map((hook) => hook.description)}
        />
      </div>
    </div>
  )
}

export { CampaignView }
