import React, { FunctionComponent } from 'react'
import classnames from 'classnames'
import { Icon, IconType } from '../Icon'
import { Tooltip as ReactTooltip } from 'react-tooltip'

interface IDefaultProps {
  className: string
  enforceMaxWidth?: boolean
  iconColor: string
}

interface IProps extends Partial<IDefaultProps> {
  valueKey: string
  content?: any
  tooltipText: string
}

const defaultProps: IDefaultProps = {
  className: null,
  enforceMaxWidth: true,
  iconColor: 'text-primary',
}

const TooltipInfo: FunctionComponent<IProps> = ({
  valueKey,
  content,
  tooltipText,
  className,
  enforceMaxWidth,
  iconColor,
}) => {
  return (
    <span className={classnames('flex items-center normal-case', className)}>
      <span>{content}</span>
      <span
        data-tooltip-id={`tooltip-${valueKey}`}
        data-tooltip-content={tooltipText}
      >
        <Icon type={IconType.info} className={iconColor + ' ml-1'} />
      </span>
      <ReactTooltip
        id={`tooltip-${valueKey}`}
        className={classnames(
          '!bg-primary !text-xs whitespace-normal',
          enforceMaxWidth && '!w-64 ',
        )}
      />
    </span>
  )
}

TooltipInfo.defaultProps = defaultProps

export { TooltipInfo }
