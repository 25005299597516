import { useMutation } from '@apollo/client'
import { FunctionComponent } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import validator from 'validator'
import { Button, NotificationType, TextField } from '../../components'
import { RESET_PASSWORD } from '../../gql'
import { useNotification } from '../../utils/hooks'
import { PublicRoutes } from '../../utils/PublicRoutes'
import LOGO from '../../assets/logo/lancape_no_bg.png'

export type ResetPasswordFormData = {
  email: string
}

interface IDefaultProps {}

interface IProps extends Partial<IDefaultProps> {}

const defaultProps: IDefaultProps = {}

const ForgotPassword: FunctionComponent<IProps> = () => {
  const { addNotification } = useNotification()
  const [resetPassword, { loading: resetPasswordLoading }] =
    useMutation(RESET_PASSWORD)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
    },
  })

  const onSubmit = async (data: ResetPasswordFormData) => {
    try {
      await resetPassword({
        variables: {
          email: data.email,
        },
      })

      addNotification(
        'We have emailed you a link to reset your password',
        NotificationType.success,
      )
    } catch (error) {
      addNotification(error.message, NotificationType.error)
    }
  }

  return (
    <>
      <div>
        <div className='min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
          <div className='sm:mx-auto sm:w-full sm:max-w-lg'>
            <img className='mx-auto h-12 w-auto' src={LOGO} alt='Workflow' />
            <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>
              Reset your password
            </h2>
            <p className='mt-2 px-6 text-center text-sm text-gray-600'>
              You'll receive a unique link valid for one hour that will let you
              change your password
            </p>
          </div>

          <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-lg'>
            <div className='py-8 px-4 sm:px-10'>
              <form className='space-y-4' onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <TextField
                    placeholder={`Example: hello@useclip.com`}
                    label='Email'
                    name='email'
                    error={errors.email?.message}
                    {...register('email', {
                      required: `Please set an email.`,
                      maxLength: {
                        value: 100,
                        message: `Your email cannot have more than 100 letters.`,
                      },
                      validate: {
                        value: (email) => {
                          if (validator.isEmail(email)) {
                            return true
                          }

                          return 'Invalid email.'
                        },
                      },
                    })}
                  />
                  <p className='mt-2 text-left text-sm text-gray-600'>
                    Double check your email is correct
                  </p>
                </div>

                <div>
                  <Button
                    title='Reset password'
                    type='submit'
                    loading={resetPasswordLoading}
                    disabled={resetPasswordLoading}
                    width='w-full'
                  />
                </div>
              </form>

              <div className='mt-4'>
                <div className='relative'>
                  <div className='relative flex justify-center text-sm'>
                    <p className='px-2 text-center text-sm text-gray-600'>
                      Go back to{' '}
                      <Link
                        to={PublicRoutes.login}
                        className='font-medium text-indigo-500 hover:text-indigo-600'
                      >
                        Login
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

ForgotPassword.defaultProps = defaultProps

export { ForgotPassword }
