import classNames from 'classnames'
import { FunctionComponent } from 'react'
import { CreatorInterests } from '../../../components/CreatorInterests'
import { VideoPlayer } from '../../../components/VideoPlayer'
import { Avatar } from '../../../elements'
import { Collaboration } from '../../../gql/types'
import { formatLongMessage } from '../../../utils/helpers'
import { mapCountryCodeToFlagAndText } from '../../../utils/mappers'

interface IDefaultProps {
  className?: string
}

interface IProps extends Partial<IDefaultProps> {
  collaboration: Collaboration
}

const defaultProps: IDefaultProps = {
  className: null,
}

const ProfileCard: FunctionComponent<IProps> = ({
  collaboration,
  className,
}) => {
  const creator = collaboration.creator

  return (
    <div
      className={classNames(
        'bg-white shadow sm:overflow-hidden rounded-lg',
        className,
      )}
    >
      <div className='px-4 py-5 sm:px-6'>
        <div className='relative flex items-center space-x-3'>
          <div className='flex-shrink-0'>
            <Avatar
              type='user'
              src={creator.avatar?.url}
              size='md'
              alt={creator.firstName}
            />
          </div>
          <div className='min-w-0 flex-1'>
            <span className='absolute inset-0' aria-hidden='true' />
            <h3 className='text-lg leading-6 font-medium text-gray-900'>
              {/* Creator */}
              {collaboration.creator.firstName}
            </h3>
            <p className='mt-1 text-sm text-gray-500'>
              More details and information about the creator.
            </p>
          </div>
        </div>
      </div>
      <div className='border-t border-gray-200 px-4 py-5 sm:p-0'>
        <dl className='sm:divide-y sm:divide-gray-200'>
          {creator.occupation && (
            <div className='py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>Occupation</dt>
              <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                {creator.occupation}
              </dd>
            </div>
          )}
          {collaboration && collaboration.creatorApplicationMessage && (
            <div className='py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>Application</dt>
              <dd
                className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'
                dangerouslySetInnerHTML={{
                  __html: formatLongMessage(
                    collaboration.creatorApplicationMessage,
                  ),
                }}
              ></dd>
            </div>
          )}
          {creator.bio && (
            <div className='py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>About</dt>
              <dd
                className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'
                dangerouslySetInnerHTML={{
                  __html: formatLongMessage(creator.bio),
                }}
              ></dd>
            </div>
          )}
          {creator.interests && creator.interests.length > 0 && (
            <div className='py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>Interests</dt>
              <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 space-x-1'>
                <div className='mt-1.5 sm:items-end flex-wrap'>
                  {creator.interests?.map((interest, index) => (
                    <CreatorInterests key={index} interest={interest} />
                  ))}
                </div>
              </dd>
            </div>
          )}
          {creator.addressCity && (
            <div className='py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>City</dt>
              <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                {creator.addressCity}
              </dd>
            </div>
          )}
          {creator.addressCounty && (
            <div className='py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>County</dt>
              <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                {creator.addressCounty}
              </dd>
            </div>
          )}
          {creator.addressState && (
            <div className='py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>State</dt>
              <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                {creator.addressState}
              </dd>
            </div>
          )}
          {creator.countryCode && (
            <div className='py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>Country</dt>
              <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                {mapCountryCodeToFlagAndText(creator.countryCode)}
              </dd>
            </div>
          )}
          <div className='py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
            <dt className='text-sm font-medium text-gray-500'>
              Introduction Video
            </dt>
            <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
              <div className='grid grid-cols-2 gap-x-6 gap-y-6 @md:grid-cols-3 @lg:grid-cols-4'>
                <div className='relative isolate flex flex-col justify-end shadow-sm shadow-black/20 dark:shadow-white/20 overflow-hidden rounded-2xl bg-gray-900 aspect-[0.7]'>
                  <VideoPlayer
                    src={creator.introVideo?.url}
                    poster={creator.thumbnail?.url}
                    className='absolute inset-0 -z-10 h-full w-full'
                    controls
                  />
                </div>
              </div>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  )
}

ProfileCard.defaultProps = defaultProps

export { ProfileCard }
