import { useParams } from 'react-router-dom'
import { Pagination as PaginationComponent } from '../../../../components'
import { Campaign, Pagination } from '../../../../gql/types'
import { ListItem } from './ListItem'

const TableHead = () => {
  return (
    <thead className='bg-white'>
      <tr>
        <th
          scope='col'
          className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-600 sm:pl-6'
        >
          Name
        </th>

        <th
          scope='col'
          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-600'
        >
          Status
        </th>

        <th
          scope='col'
          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-600'
        >
          Applicants
        </th>

        <th
          scope='col'
          className='px-4 py-3.5 text-left text-sm font-semibold text-gray-600'
        >
          Approved / Paid
        </th>

        <th
          scope='col'
          className='whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-600'
        >
          Date created
        </th>
        <th scope='col' className='relative px-3 py-3.5'>
          <span className='sr-only'>Edit</span>
        </th>
      </tr>
    </thead>
  )
}

interface IProps {
  campaigns: Campaign[]
  pagination: Pagination
  setPage(page: number): void
}

const List = ({ campaigns, pagination, setPage }: IProps) => {
  const { id } = useParams()

  return (
    <>
      <div className='flex flex-col bg-white'>
        <table className='min-w-full divide-y divide-gray-200'>
          <TableHead />
          <tbody className='divide-y divide-gray-200 bg-white'>
            {pagination.numberOfHits === 0 && (
              <tr>
                <td colSpan={6}>
                  <p className='py-4 pl-4 pr-3 text-sm sm:pl-6 w-full'>
                    No campaigns.
                  </p>
                </td>
              </tr>
            )}

            {campaigns.map((campaign, index) => (
              <ListItem key={index} campaign={campaign} index={index} />
            ))}
          </tbody>
        </table>
        {pagination && (
          <PaginationComponent pagination={pagination} setPage={setPage} />
        )}
      </div>
    </>
  )
}

export { List }
