import { User, Brand } from '../../../gql/types'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_PRODUCT_BY_ID } from '../../../gql'
import { Loader } from '../../../components'
import { Edit } from './components/Edit'
import { useBrand } from '../../../hooks'

interface IProps {
  user: User
  brand: Brand
}

const EditProduct = ({ user, brand }: IProps) => {
  const { productId } = useParams()

  const {
    loading: loadingGetProductById,
    error: errorGetProductById,
    data: dataGetProductById,
  } = useQuery(GET_PRODUCT_BY_ID, {
    variables: {
      id: Number(productId),
    },
  })

  if (!brand || loadingGetProductById) {
    return <Loader />
  }

  if (errorGetProductById) {
    console.log('error GetProductById', errorGetProductById)
    return <p>There has been an error. Try refreshing the page</p>
  }

  const product = dataGetProductById?.getProductById

  return (
    <div className='py-4'>
      <Edit product={product} brand={brand} />
    </div>
  )
}

export { EditProduct }
