import { useMutation } from '@apollo/client'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  Button,
  ButtonColor,
  NotificationType,
  RadioGroup,
} from '../../../components'
import { UPDATE_BRAND, UPDATE_CAMPAIGN } from '../../../gql'
import {
  Brand,
  BrandOnboardingStep,
  Campaign,
  VideoDuration,
} from '../../../gql/types'
import { AnalyticsEvent, trackEvent } from '../../../utils/analytics'
import { videoDurationOptions } from '../../../utils/mappers'
import { Bullets } from '../components/Bullets'
import { Steps } from '../components/Steps'
import { isCampaignPremium, useNotification } from '../../../utils/hooks'
import { PricingData } from '../../../utils/PricingUtil'

type EditVideoDurationFormData = {
  videoDuration: VideoDuration
}

interface Props {
  brand: Brand
  campaign: Campaign
  pricing: PricingData
}

const SetVideoDuration = ({ brand, campaign, pricing }: Props) => {
  const { addNotification } = useNotification()

  const [updateBrand, { loading: loadingUpdateBrand }] =
    useMutation(UPDATE_BRAND)
  const [updateCampaign, { loading: loadingUpdateCampaign }] =
    useMutation(UPDATE_CAMPAIGN)

  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors },
    setValue,
    watch,
  } = useForm<EditVideoDurationFormData>({
    defaultValues: {
      videoDuration: campaign?.videoDuration ?? VideoDuration.half,
    },
  })

  useEffect(() => {
    trackEvent(AnalyticsEvent.viewBrandsOnboardingSetVideoDuration)
  }, [])

  const goBack = async () => {
    try {
      await updateBrand({
        variables: {
          id: brand.id,
          input: {
            onboarding: BrandOnboardingStep.setVoiceType,
          },
        },
      })
    } catch (error) {
      addNotification(error.message, NotificationType.error)
    }
  }

  const onSubmit = async ({ videoDuration }: EditVideoDurationFormData) => {
    try {
      await Promise.all([
        updateCampaign({
          variables: {
            id: campaign.id,
            input: {
              videoDuration,
            },
          },
        }),
        updateBrand({
          variables: {
            id: brand.id,
            input: {
              onboarding: BrandOnboardingStep.setRequirements,
            },
          },
        }),
      ])
    } catch (error) {
      console.log('@error SetVideoDuration', error.message)
      addNotification(error.message, NotificationType.error)
    }
  }

  return (
    <div className='min-h-screen flex flex-col'>
      <div className='flex-grow'>
        <Steps
          brand={brand}
          stepCurrent={BrandOnboardingStep.setVideoDuration}
        />

        <div className='min-h-full flex flex-col justify-center pt-4 px-4 md:mt-8 lg:px-8'>
          <div className='sm:mx-auto sm:w-full sm:max-w-2xl'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='md:shadow rounded-t-md sm:overflow-hidden'>
                <div className='py-5 md:bg-white space-y-6 sm:p-6'>
                  <div className='sm:mx-auto'>
                    <h2 className='mt-0 md:text-center text-3xl font-extrabold text-gray-900'>
                      Video duration
                    </h2>
                    <p className='mt-2 md:text-center text-sm text-gray-600'>
                      The ideal duration for a video may differ depending on
                      where you'll use it.
                    </p>
                  </div>

                  <div className='grid gap-6'>
                    <div className='col-span-3 sm:col-span-2'>
                      <Controller
                        name='videoDuration'
                        control={control}
                        rules={{
                          required: `Tell us what is your video duration.`,
                        }}
                        render={({ field }) => (
                          <RadioGroup
                            options={videoDurationOptions({
                              currency: campaign.currency,
                              pricing,
                              quality: campaign.quality,
                            })}
                            field={field}
                            className='sm:grid-cols-3'
                            error={errors?.videoDuration?.message}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='flex justify-between md:shadow rounded-b-md py-3 md:bg-gray-50 text-right sm:px-6'>
                <Button
                  title='Go back'
                  type='button'
                  colour={ButtonColor.white}
                  loading={loadingUpdateBrand}
                  disabled={loadingUpdateBrand}
                  onClick={goBack}
                />
                <Button
                  title='Continue'
                  type='submit'
                  loading={loadingUpdateBrand || loadingUpdateCampaign}
                  disabled={loadingUpdateBrand || loadingUpdateCampaign}
                />
              </div>
            </form>
          </div>
        </div>
      </div>

      <Bullets
        brand={brand}
        stepCurrent={BrandOnboardingStep.setVideoDuration}
      />
    </div>
  )
}

export { SetVideoDuration }
