import { useState } from 'react'
import { Pagination as PaginationComponent } from '../../../components'
import { Collaboration, Pagination } from '../../../gql/types'
import { ViewCreatorSlider } from '../../shared/ViewCreatorSlider'
import { ApplicantRow } from './ApplicantRow'

interface IProps {
  collaborations: Collaboration[]
  pagination?: Pagination
  setPage(page: number): void
  onRefetch: () => void
}

const TableHead = () => {
  return (
    <thead className='bg-white'>
      <tr>
        <th
          scope='col'
          className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-600 sm:pl-6'
        >
          Creator
        </th>

        <th
          scope='col'
          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-600'
        >
          Status
        </th>

        <th
          scope='col'
          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-600'
        >
          Reviews
        </th>

        <th
          scope='col'
          className='py-3.5 pl-3 pr-4 text-left text-sm font-semibold text-gray-600 sm:pr-6'
        />
      </tr>
    </thead>
  )
}

const ApplicantsList = ({
  collaborations,
  pagination,
  setPage,
  onRefetch,
}: IProps) => {
  const [isCreatorSliderOpen, setIsCreatorSliderOpen] = useState(false)
  const [selectedCollaboration, setSelectedCollaboration] = useState<
    Collaboration | undefined
  >(undefined)

  const onCollaborationAccepted = () => {
    setIsCreatorSliderOpen(false)
    setSelectedCollaboration(undefined)
    onRefetch()
  }

  return (
    <>
      <div className='flex flex-col'>
        <table className='min-w-full divide-y divide-gray-200'>
          <TableHead />
          <tbody className='divide-y divide-gray-200 bg-white'>
            {pagination.numberOfHits === 0 && (
              <tr>
                <td colSpan={6}>
                  <p className='py-4 pl-4 pr-3 text-sm sm:pl-6 w-full'>
                    No applicants yet. Check back later.
                  </p>
                </td>
              </tr>
            )}

            {collaborations.map((collaboration, index) => (
              <ApplicantRow
                collaboration={collaboration}
                setSelectedCollaboration={setSelectedCollaboration}
                setIsCreatorSliderOpen={setIsCreatorSliderOpen}
                className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}
                key={collaboration.id}
              />
            ))}
          </tbody>
        </table>

        {pagination && (
          <PaginationComponent
            pagination={pagination}
            setPage={setPage}
            className='bg-white'
          />
        )}
      </div>

      {selectedCollaboration && (
        <ViewCreatorSlider
          open={isCreatorSliderOpen}
          setOpen={setIsCreatorSliderOpen}
          creator={selectedCollaboration.creator}
          collaboration={selectedCollaboration}
          onCollaborationAccepted={() => onCollaborationAccepted()}
        />
      )}
    </>
  )
}

export { ApplicantsList }
