import classnames from 'classnames'
import { mapRoleToText } from '../InviteMemberModal'
import { useMutation } from '@apollo/client'
import moment from 'moment'
import {
  RESEND_TEAM_MEMBER_INVITATION,
  GET_TEAM_INVITATIONS,
  CANCEL_TEAM_MEMBER_INVITATION,
} from '../../../../../gql'
import {
  Brand,
  GetTeamInvitationsSort,
  Pagination as IPagination,
  TeamInvitation,
  TeamInvitationStatus,
} from '../../../../../gql/types'
import { SKIP_DEFAULT, LIMIT_DEFAULT } from '../../../../../utils/constants'
import { useNotification } from '../../../../../utils/hooks'
import { InvitationStatus } from '../InvitationStatus'
import {
  Button,
  ButtonColor,
  Loader,
  NotificationType,
  Pagination,
} from '../../../../../components'

const TableHead = () => {
  return (
    <thead className='bg-gray-50'>
      <tr>
        <th
          scope='col'
          className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-600 sm:pl-6'
        >
          Name
        </th>

        <th
          scope='col'
          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-600'
        >
          Email
        </th>

        <th
          scope='col'
          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-600'
        >
          Roles
        </th>

        <th
          scope='col'
          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-600'
        >
          Expiration
        </th>

        <th
          scope='col'
          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-600'
        >
          Created
        </th>

        <th
          scope='col'
          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-600'
        >
          Status
        </th>
      </tr>
    </thead>
  )
}

interface IProps {
  brand: Brand
  teamMembersInvitations: TeamInvitation[]
  pagination: IPagination
  setPage(page: number): void
  loading: boolean
}

const List = ({
  brand,
  teamMembersInvitations,
  pagination,
  setPage,
  loading,
}: IProps) => {
  const { addNotification } = useNotification()

  const [resendTeamMemberInvitation] = useMutation(
    RESEND_TEAM_MEMBER_INVITATION,
    {
      refetchQueries: [
        {
          query: GET_TEAM_INVITATIONS,
          variables: {
            options: {
              query: null,
              filters: {
                brandId: brand?.id,
              },
              sort: GetTeamInvitationsSort.newest,
              skip: SKIP_DEFAULT,
              limit: LIMIT_DEFAULT,
            },
          },
        },
      ],
    },
  )

  const [cancelTeamMemberInvitation] = useMutation(
    CANCEL_TEAM_MEMBER_INVITATION,
    {
      refetchQueries: [
        {
          query: GET_TEAM_INVITATIONS,
          variables: {
            options: {
              query: null,
              filters: {
                brandId: brand?.id,
              },
              sort: GetTeamInvitationsSort.newest,
              skip: SKIP_DEFAULT,
              limit: LIMIT_DEFAULT,
            },
          },
        },
      ],
    },
  )

  if (loading) {
    return <Loader />
  }

  const resendInvitation = async (email: string) => {
    try {
      await resendTeamMemberInvitation({
        variables: {
          email,
          brandId: brand.id,
        },
      })

      addNotification(`Invite resent.`, NotificationType.success)
    } catch (error) {
      addNotification(
        error.message
          ? error.message
          : `Something went wrong. Please try again.`,
        NotificationType.error,
      )
    }
  }

  const cancelInvitation = async (email: string) => {
    try {
      await cancelTeamMemberInvitation({
        variables: {
          email,
          brandId: brand.id,
        },
      })

      addNotification(`Invite deleted.`, NotificationType.success)
    } catch (error) {
      addNotification(
        error.message
          ? error.message
          : `Something went wrong. Please try again.`,
        NotificationType.error,
      )
    }
  }

  return (
    <div className='flex flex-col'>
      <table className='min-w-full divide-y divide-gray-200'>
        <TableHead />
        <tbody className='divide-y divide-gray-200 bg-white'>
          {teamMembersInvitations?.length === 0 && (
            <tr>
              <td colSpan={7}>
                <p className='py-4 pl-4 pr-3 text-sm sm:pl-6 w-full text-center'>
                  No invitations sent.
                </p>
              </td>
            </tr>
          )}

          {teamMembersInvitations.map((teamInvitation, index) => (
            <tr
              key={teamInvitation.id}
              className={classnames(
                index % 2 === 0 ? 'bg-gray-50' : 'bg-white',
              )}
            >
              <td className='whitespace-nowrap py-4 pl-4 text-sm text-gray-500 sm:pl-6'>
                {teamInvitation.firstName} {teamInvitation.lastName}
              </td>
              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                {teamInvitation.email}
              </td>
              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                {teamInvitation.roles
                  .map((role) => mapRoleToText(role))
                  .join(', ')}
              </td>
              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                {moment.unix(teamInvitation.expirationDate).fromNow()}
              </td>
              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                {moment(teamInvitation.createdAt).fromNow()}
              </td>
              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                <InvitationStatus
                  type={teamInvitation.status}
                  expirationDate={teamInvitation.expirationDate}
                />
              </td>
              <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 float-right space-x-2'>
                {moment().isAfter(moment.unix(teamInvitation.expirationDate)) &&
                  teamInvitation.status === TeamInvitationStatus.invited && (
                    <Button
                      colour={ButtonColor.secondary}
                      title='Resend'
                      onClick={() => resendInvitation(teamInvitation.email)}
                    />
                  )}
                {moment().isBefore(
                  moment.unix(teamInvitation.expirationDate),
                ) &&
                  teamInvitation.status === TeamInvitationStatus.invited && (
                    <Button
                      colour={ButtonColor.error}
                      title='Cancel'
                      onClick={() => cancelInvitation(teamInvitation.email)}
                    />
                  )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {pagination && <Pagination pagination={pagination} setPage={setPage} />}
    </div>
  )
}

export { List }
