import { FunctionComponent } from 'react'
import { TeamInvitation, User } from '../../../../gql/types'
import { Button } from '../../../../components'
import { Avatar } from '../../../../elements'
import { useLogout } from '../../../../hooks'
import LOGO from '../../../../assets/logo/lancape_no_bg.png'

interface IDefaultProps {}

interface IProps extends Partial<IDefaultProps> {
  user: User
  invitation: TeamInvitation
}

const defaultProps: IDefaultProps = {}

const AcceptInvitationCreator: FunctionComponent<IProps> = ({
  user,
  invitation,
}) => {
  const logout = useLogout()

  return (
    <div className='flex flex-col max-w-lg m-auto p-4 mt-20'>
      <header className='text-center mx-auto pb-4'>
        <img className='h-12 w-auto mx-auto mb-2' src={LOGO} alt='Logo' />

        <small>
          Signed in as {user?.firstName} {user?.lastName}
        </small>

        <div className='flex py-4 items-center justify-center'>
          <Avatar
            src={invitation?.brand?.logo?.url}
            alt={invitation?.brand?.name}
            size='xs'
            type='brand'
          />
          <h2 className='text-gray-900 ml-2'>
            Team invitation for brand {invitation?.brand?.name}
          </h2>
        </div>

        <p className='text-sm py-4'>
          You have to logout and create a new user in order to accept this
          invitation.
        </p>

        <Button title='Logout and Sign Up' onClick={logout} />
      </header>
    </div>
  )
}

AcceptInvitationCreator.defaultProps = defaultProps

export { AcceptInvitationCreator }
