import { FunctionComponent, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { Icon, IconType } from '../../Icon'
import { Button, ButtonColor, ButtonStyle } from '../../Button'
import { ChatMember } from '../ChatMember'
import { useParams } from 'react-router-dom'
import { Pagination } from '../../../gql/types'

interface IProps {
  threads: {
    uuid: string
    id: number
    from: {
      name: string
      avatar: string
    }
    threadUnreadMessagesCount: number
    lastMessage: string
    lastMessageDate: string
    type?: 'brand' | 'user'
    onClick: () => void
  }[]

  activeThreadId?: string
  onSearch: (query: string) => void
  onMarkAllRead?: () => void

  fetchMore?: () => void
  pagination: Pagination
}

const ChatMembersList: FunctionComponent<IProps> = ({
  threads,
  activeThreadId,

  onSearch,
  onMarkAllRead,

  fetchMore,
  pagination,
}) => {
  const [query, setQuery] = useState('')
  const { uuid: urlUuid } = useParams()

  useEffect(() => {
    const handleEnterKeydown = async (e: KeyboardEvent): Promise<void> => {
      if (e.key === 'Enter') {
        onSearch(query)
      }
    }

    window.addEventListener('keydown', handleEnterKeydown)
    return () => window.removeEventListener('keydown', handleEnterKeydown)
  }, [onSearch, query])

  const listInnerRef = useRef()

  const onScroll = () => {
    if (
      listInnerRef &&
      listInnerRef.current &&
      pagination &&
      pagination?.pageNumber < pagination?.numberOfPages
    ) {
      const { scrollTop, scrollHeight, clientHeight } =
        listInnerRef.current as any
      if (scrollTop + clientHeight === scrollHeight) {
        if (fetchMore) fetchMore()
      }
    }
  }

  return (
    <div className='w-[360px] flex flex-col h-screen'>
      <div className='px-4 pt-4 pb-2'>
        <div className='flex justify-between items-center'>
          <h1 className='font-bold text-3xl font-outfit'>Inbox</h1>
          {onMarkAllRead && (
            <div className='group'>
              <Button
                title='Mark all as read'
                icon={
                  <Icon
                    type={IconType.doneAll}
                    className='w-4 h-4 text-blue-800'
                    aria-hidden='true'
                  />
                }
                iconPosition='left'
                colour={ButtonColor.blue}
                onClick={onMarkAllRead}
                styleType={ButtonStyle.ghost}
                className='!px-0'
              />
            </div>
          )}
        </div>
      </div>

      <div className='px-4 pb-4'>
        <div className='flex items-center w-full'>
          <label className='sr-only text-gray-500'>Search</label>

          <div className='relative w-full'>
            <div className='flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none'>
              <Icon
                type={IconType.search}
                className='mr-3 h-5 w-5 text-gray-500'
                aria-hidden='true'
              />
            </div>

            <input
              type='text'
              className='bg-white border border-gray-200 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-gray-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:placeholder-gray-500 dark:text-white dark:focus:ring-gray-500 '
              placeholder='Search by name or handle'
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              required
            />
          </div>
        </div>
      </div>

      <div
        className={classNames(
          'lg:flex flex-col max-w-sm flex overflow-y-scroll',
          {
            hidden: activeThreadId,
          },
        )}
        onScroll={() => onScroll()}
        ref={listInnerRef}
      >
        {threads?.length === 0 && (
          <div className='flex h-screen'>
            <div className='m-auto'>
              <p className='text-center'>You inbox is empty</p>
            </div>
          </div>
        )}

        {threads?.map(
          (
            {
              uuid,
              from,
              lastMessage,
              lastMessageDate,
              onClick,
              threadUnreadMessagesCount,
              type,
            },
            index,
          ) => (
            <div
              className='flex flex-col border-b border-gray-200'
              key={index}
              onClick={onClick}
            >
              <ChatMember
                name={from.name}
                avatar={from.avatar}
                unreadMessages={threadUnreadMessagesCount}
                isActive={uuid === urlUuid}
                lastMessageText={lastMessage}
                lastMessageSentAt={lastMessageDate}
                type={type}
              />
            </div>
          ),
        )}

        {threads?.length > 0 &&
          threads?.length === pagination?.numberOfHits && (
            <p className='flex p-2 justify-center text-sm text-gray-500'>
              No more chats
            </p>
          )}
      </div>
    </div>
  )
}

export { ChatMembersList }
