import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { PublicRoutes } from '../../../../utils/PublicRoutes'

interface IDefaultProps {}

interface IProps extends Partial<IDefaultProps> {}

const defaultProps: IDefaultProps = {}

const InvitationAccepted: FunctionComponent<IProps> = () => {
  return (
    <div className='min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8'>
      <div className='mx-auto max-w-max'>
        <main className='sm:flex'>
          <div className='sm:ml-6'>
            <div className='sm:pl-6'>
              <h3 className='font-bold tracking-tight text-gray-900 sm:text-2xl'>
                Invitation accepted
              </h3>
              <p>
                You can now login using the button below and start using Clip
              </p>
            </div>
            <div className='mt-8 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6'>
              <Link
                to={PublicRoutes.login}
                className='cursor-pointer inline-flex items-center rounded-md border border-transparent bg-slate-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2'
              >
                Login
              </Link>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

InvitationAccepted.defaultProps = defaultProps

export { InvitationAccepted }
