import { useEffect, useRef, useState } from 'react'
import Select, {
  components,
  ControlProps,
  createFilter,
  MultiValueProps,
  OptionProps,
} from 'react-select'

type Option = {
  value: string
  label: string
  avatar?: React.ReactNode
}

type MultiselectProps = {
  options: Option[]
  defaultSelected?: Option[]
  selected?: Option[]
  onChange: (selectedOptions: Option[] | null) => void
  onMenuOpen?: () => void
  loading?: boolean
  maxMenuHeight?: number
  placeholder?: string
  displayTags?: boolean
  customText?: string
  className?: string
  isSearchable?: boolean
}

const Control = (props: ControlProps<Option, true>) => {
  return (
    <components.Control
      {...props}
      className={`shadow appearance-none border rounded w-full text-gray-700 leading-tight focus:outline-none focus:ring-green-500 ${props.className}`}
    />
  )
}

const Checkbox = (props: any) => {
  return (
    <div className='flex items-center cursor-pointer'>
      <input
        type='checkbox'
        className='h-4 w-4 rounded border-gray-300 text-gray-600 disabled:accent-gray-300 cursor-pointer focus:ring-gray-800 accent-primary-800'
        checked={props.isSelected}
        onChange={() => null}
      />
      <div className='ml-2'>{props.children}</div>
    </div>
  )
}

const Option = (props: OptionProps<Option, true>) => {
  return (
    <div>
      <components.Option {...props} className=''>
        <Checkbox {...props}>
          <div className='flex items-center gap-x-2'>
            {props.data.avatar && <div>{props.data.avatar}</div>}
            <div className='flex-auto truncate'>{props.label}</div>
          </div>
        </Checkbox>
      </components.Option>
    </div>
  )
}

const MultiValueLabel = (props: MultiValueProps<Option>) => {
  const { children, data } = props
  return (
    <div>
      <components.MultiValueLabel {...props}>
        <div className='flex items-center gap-x-2'>
          {data.avatar && <div>{data.avatar}</div>}
          <div className='flex-auto truncate'>{data.label}</div>
        </div>
      </components.MultiValueLabel>
    </div>
  )
}

const ValueContainer = ({
  children,
  displayTags,
  customText,
  ...props
}: any) => {
  const hasValue = props.hasValue

  if (!displayTags && hasValue) {
    return (
      <components.ValueContainer {...props}>
        {customText}
      </components.ValueContainer>
    )
  }

  return (
    <components.ValueContainer {...props}>{children}</components.ValueContainer>
  )
}

const withCustomText =
  (
    WrappedComponent: React.ComponentType<any>,
    customText: string,
    displayTags: boolean,
  ) =>
  (props: any) => {
    return (
      <WrappedComponent
        {...props}
        customText={customText}
        displayTags={displayTags}
      />
    )
  }

const ClearIndicator = () => {
  return null
}

const Multiselect: React.FC<MultiselectProps> = ({
  options,
  defaultSelected = [],
  selected = [],
  onChange,
  onMenuOpen,
  loading = false,
  maxMenuHeight = 200,
  placeholder = 'Select...',
  displayTags = true,
  customText = '',
  className = null,
  isSearchable = true,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const selectRef = useRef<React.ElementRef<typeof Select>>(null)
  const [menuCloseTimeout, setMenuCloseTimeout] =
    useState<NodeJS.Timeout | null>(null)

  // Add state for menuIsOpen
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  // Function to handle menu closing
  const handleMenuClose = () => {
    setMenuIsOpen(false)
  }

  const customStyles = {
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
    input: (provided: any) => ({
      ...provided,
      '& input[type="checkbox"]': {
        accentColor: 'black',
        // borderColor: '#000', // change the border color
        // color: '#000', // change the checkbox color
      },
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? 'transparent'
        : provided.backgroundColor,
      color: 'text-gray-900',
      ':hover': {
        backgroundColor: '#EAECF0', // change the hover border color
      },
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      // gray-300
      boxShadow: state.isFocused ? '0 0 0 1px #EAECF0' : 'none',
      borderColor: '#EAECF0',
      ':hover': {
        borderColor: '#667085', // change the hover border color
        boxShadow: '0 0 0 1px #667085', // change the hover boxShadow color
      },
    }),
  }

  const components = {
    ClearIndicator,
    Option,
    MultiValueLabel: displayTags ? MultiValueLabel : null,
    ValueContainer: withCustomText(ValueContainer, customText, displayTags),
    Control,
  }

  // Setup click event listener for closing the dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuIsOpen &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        if (menuCloseTimeout) {
          clearTimeout(menuCloseTimeout)
        }

        setMenuIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [menuIsOpen])

  return (
    <div className={className} ref={wrapperRef}>
      <Select
        ref={selectRef}
        menuPortalTarget={document.body}
        isLoading={loading}
        isMulti={true}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={components}
        defaultValue={defaultSelected}
        value={selected}
        options={options}
        onChange={onChange}
        maxMenuHeight={maxMenuHeight}
        placeholder={placeholder}
        styles={customStyles}
        classNamePrefix='multiselect cursor-pointer text-gray-900 sm:text-sm sm:leading-6'
        filterOption={createFilter({ ignoreAccents: false })}
        isSearchable={isSearchable}
        menuIsOpen={menuIsOpen}
        onMenuOpen={() => {
          setTimeout(() => setMenuIsOpen(true), 200)

          if (onMenuOpen) {
            onMenuOpen()
          }
        }}
        onMenuClose={() => {
          if (menuCloseTimeout) {
            clearTimeout(menuCloseTimeout)
          }

          setMenuIsOpen(false)
        }}
      />
    </div>
  )
}

export default Multiselect
