import classNames from 'classnames'
import { forwardRef, ReactNode } from 'react'
import { ChangeHandler, RefCallBack } from 'react-hook-form'
import { Icon, IconType } from '../Icon'

interface IDefaultProps {
  leftElement?: ReactNode
  rightElement?: ReactNode
  error?: string
  className?: string
  autoFocus?: any
}

interface IProps extends Partial<IDefaultProps> {
  type?: string
  label?: string
  placeholder?: string
  min?: string | number
  max?: string | number
  name: string
  onChange?: ChangeHandler
  onBlur?: ChangeHandler
  ref: RefCallBack
  field?: any
}

const defaultProps: IDefaultProps = {
  error: null,
  className: null,
}

const TextField = forwardRef<HTMLDivElement, IProps>((props: IProps, ref) => {
  const {
    type,
    label,
    placeholder,
    min,
    max,
    onChange,
    onBlur,
    name,
    leftElement,
    rightElement,
    error,
    className,
    field,
    autoFocus,
    ...rest
  } = props

  // const { id, onChange, onBlur, value, name } = field

  return (
    <>
      {label && (
        <label className='block text-sm font-medium text-gray-700'>
          {label}
        </label>
      )}
      <div className='mt-1 relative flex rounded-md shadow-sm'>
        {leftElement}
        <input
          type={type ? type : `text`}
          min={type === 'date' ? min : null}
          max={type === 'date' ? max : null}
          className={classNames(
            // `${leftElement ? `rounded-l-none` : null}`,
            // `${rightElement ? `rounded-r-none` : null}`,
            'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-500 focus:outline-none focus:ring-gray-800 focus:border-gray-800 text-sm',
            className,
          )}
          placeholder={placeholder}
          name={name}
          {...(onChange && { onChange: onChange })}
          {...(onBlur && { onBlur: onBlur })}
          ref={ref}
          {...(field && { ...field })}
          {...rest}
          autoFocus={autoFocus}
        />
        {rightElement}
        {error && (
          <div
            className={classNames(
              'absolute inset-y-0 right-0 flex items-center pointer-events-none',
              type === 'date' ? 'pr-8' : 'pr-3',
            )}
          >
            <Icon
              type={IconType.exclamationCircleFill}
              className='h-5 w-5 text-red-500'
              aria-hidden='true'
            />
          </div>
        )}
      </div>
      {error && <p className='mt-2 text-sm text-red-600'>{error}</p>}
    </>
  )
})

TextField.displayName = 'TextField'
TextField.defaultProps = defaultProps

export { TextField }
