import React, { FunctionComponent } from 'react'
import { User, Brand as BrandModel } from '../../gql/types'
import { BrandEdit } from './BrandEdit'
import { Header } from './components/Header'

interface IProps {
  user: User
  brand: BrandModel
}

export const Brand: FunctionComponent<IProps> = ({ user, brand }) => {
  return (
    <div>
      <Header />
      <BrandEdit user={user} brand={brand} />
    </div>
  )
}
