import { gql } from '@apollo/client'

export const PriceItemFragment = gql`
  fragment PriceItemFragment on PriceItem {
    quality
    priceCents
    creatorEarningsCents
    extraPriceCents
    extraCreatorEarningsCents
  }
`

export const HookPriceItemFragment = gql`
  fragment HookPriceItemFragment on HookPriceItem {
    priceCents
    creatorEarningsCents
  }
`

export const AddonPriceItemFragment = gql`
  fragment AddonPriceItemFragment on AddonPriceItem {
    addon
    priceCents
    creatorEarningsCents
  }
`

export const VideoPricingFragment = gql`
  fragment VideoPricingFragment on VideoPricing {
    videoDuration
    currency
    price {
      ...PriceItemFragment
    }
    creatorQuickDeliveryCents
    hook {
      ...HookPriceItemFragment
    }
    addons {
      ...AddonPriceItemFragment
    }
  }
  ${PriceItemFragment}
  ${HookPriceItemFragment}
  ${AddonPriceItemFragment}
`

export const GET_PRICING = gql`
  query GetPricing {
    getPricing {
      videoPricing {
        ...VideoPricingFragment
      }
    }
  }
  ${VideoPricingFragment}
`
