import { gql } from '@apollo/client'
import {
  ConfirmPaymentResponse,
  CreateAddonsCheckoutResponse,
  CreateCampaignCheckoutResponse,
  CreateHooksCheckoutResponse,
} from './types'
import { BrandFragment } from './brands'
import { CampaignFragment } from './campaigns'
import { CollaborationFragment } from './collaborations'

export const CREATE_CAMPAIGN_CHECKOUT = gql`
  mutation CreateCampaignCheckout(
    $brandId: Int!
    $input: CreateCampaignCheckoutInput!
  ) {
    createCampaignCheckout(brandId: $brandId, input: $input) {
      stripeSessionId
      brand {
        ...BrandFragment
      }
      campaign {
        ...CampaignFragment
      }
      creditsUsedCents
    }
  }
  ${BrandFragment}
  ${CampaignFragment}
`

export const CREATE_HOOKS_CHECKOUT = gql`
  mutation CreateHooksCheckout(
    $brandId: Int!
    $input: CreateHooksCheckoutInput!
  ) {
    createHooksCheckout(brandId: $brandId, input: $input) {
      stripeSessionId
      brand {
        ...BrandFragment
      }
      collaboration {
        ...CollaborationFragment
      }
      creditsUsedCents
    }
  }
  ${BrandFragment}
  ${CollaborationFragment}
`

export const CREATE_ADDONS_CHECKOUT = gql`
  mutation CreateAddonsCheckout(
    $brandId: Int!
    $input: CreateAddonsCheckoutInput!
  ) {
    createAddonsCheckout(brandId: $brandId, input: $input) {
      stripeSessionId
      brand {
        ...BrandFragment
      }
      collaboration {
        ...CollaborationFragment
      }
      creditsUsedCents
    }
  }
  ${BrandFragment}
  ${CollaborationFragment}
`

export const CREATE_CREDIT_PACK_CHECKOUT = gql`
  mutation CreateCreditPackCheckout(
    $brandId: Int!
    $input: CreateCreditPackCheckoutInput!
  ) {
    createCreditPackCheckout(brandId: $brandId, input: $input)
  }
`

export const GET_CUSTOMER_PORTAL_SESSION = gql`
  mutation GetCustomerPortalSession(
    $brandId: Int!
    $input: CreateCustomerPortalSessionInput!
  ) {
    getCustomerPortalSession(brandId: $brandId, input: $input)
  }
`

export const CONFIRM_PAYMENT = gql`
  mutation ConfirmPayment($stripeSessionId: String!) {
    confirmPayment(stripeSessionId: $stripeSessionId) {
      brand {
        ...BrandFragment
      }
      transactionId
      currency
      priceCents
    }
  }
  ${BrandFragment}
`

export const CONFIRM_CREDIT_PACK_PAYMENT = gql`
  mutation ConfirmCreditPackPayment($stripeSessionId: String!) {
    confirmCreditPackPayment(stripeSessionId: $stripeSessionId) {
      brand {
        ...BrandFragment
      }
      transactionId
      currency
      priceCents
    }
  }
  ${BrandFragment}
`

export const CONFIRM_HOOKS_PAYMENT = gql`
  mutation ConfirmHooksPayment($stripeSessionId: String!) {
    confirmHooksPayment(stripeSessionId: $stripeSessionId) {
      brand {
        ...BrandFragment
      }
      transactionId
      currency
      priceCents
    }
  }
  ${BrandFragment}
`

export const CONFIRM_ADDONS_PAYMENT = gql`
  mutation ConfirmAddonsPayment($stripeSessionId: String!) {
    confirmAddonsPayment(stripeSessionId: $stripeSessionId) {
      brand {
        ...BrandFragment
      }
      transactionId
      currency
      priceCents
    }
  }
  ${BrandFragment}
`

export const GET_BRAND_INVOICES = gql`
  query GetInvoicesByBrandId($brandId: Int!) {
    getInvoicesByBrandId(brandId: $brandId) {
      invoices {
        id
        created
        paidAt
        pdf
        url
        status
        paidTotal
        currency
      }
    }
  }
`

export interface MutationConfirmPaymentResponse {
  confirmPayment: ConfirmPaymentResponse
}

export interface MutationConfirmHooksPaymentResponse {
  confirmHooksPayment: ConfirmPaymentResponse
}

export interface MutationConfirmAddonsPaymentResponse {
  confirmAddonsPayment: ConfirmPaymentResponse
}

export interface MutationConfirmCreditPackPaymentResponse {
  confirmCreditPackPayment: ConfirmPaymentResponse
}

export interface MutationCreateCampaignCheckoutResponse {
  createCampaignCheckout: CreateCampaignCheckoutResponse
}

export interface MutationCreateAddonsCheckoutResponse {
  createAddonsCheckout: CreateAddonsCheckoutResponse
}

export interface MutationCreateHooksCheckoutResponse {
  createHooksCheckout: CreateHooksCheckoutResponse
}

export interface MutationCreateCreditPackCheckoutResponse {
  createCreditPackCheckout: string
}
