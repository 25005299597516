import { useMutation } from '@apollo/client'
import { FunctionComponent, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  Button,
  NotificationType,
  Select,
  TextField,
} from '../../../../components'
import { UpdateCreatorResponse, UPDATE_CREATOR } from '../../../../gql'
import {
  CountryCode,
  Creator,
  MutationUpdateCreatorArgs,
} from '../../../../gql/types'
import {
  countryOptions,
  mapCountryCodeToFlagAndText,
  mapFlagAndTextToCountryCode,
} from '../../../../utils/mappers'
import { AnalyticsEvent, trackEvent } from '../../../../utils/analytics'
import { useNotification } from '../../../../utils/hooks'

export type EditCreatorLocationData = {
  addressLine1: string
  addressCity: string
  addressCounty: string
  addressPostcode: string
  countryCode: string
}

interface IProps {
  creator: Creator
}

const EditShippingAddress: FunctionComponent<IProps> = ({ creator }) => {
  const { addNotification } = useNotification()

  const [updateCreator, { loading }] = useMutation<
    UpdateCreatorResponse,
    MutationUpdateCreatorArgs
  >(UPDATE_CREATOR)

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<EditCreatorLocationData>({
    defaultValues: {
      addressLine1: creator.addressLine1,
      addressCity: creator.addressCity,
      addressCounty: creator.addressCounty,
      addressPostcode: creator.addressPostcode,
      countryCode: mapCountryCodeToFlagAndText(creator.countryCode),
    },
  })

  useEffect(() => {
    trackEvent(AnalyticsEvent.viewCreatorsEditShippingAddress)
  }, [])

  const onSubmit = async (data: EditCreatorLocationData) => {
    try {
      await updateCreator({
        variables: {
          id: creator.id,
          input: {
            addressLine1: data.addressLine1,
            addressCity: data.addressCity,
            addressCounty: data.addressCounty,
            addressPostcode: data.addressPostcode,
            countryCode: mapFlagAndTextToCountryCode(data.countryCode),
          },
        },
      })

      addNotification(
        'Shipping address updated successfully',
        NotificationType.success,
      )
    } catch (error) {
      console.log('@error SetShippingAddress', error.message)
      addNotification(error.message, NotificationType.error)
    }
  }

  const CountryField = () => {
    return (
      <Controller
        name='countryCode'
        control={control}
        rules={{ required: `Please set a country.` }}
        render={({ field }) => (
          <Select
            options={countryOptions}
            field={field}
            label='Country/Region'
            error={errors?.countryCode?.message}
          />
        )}
      />
    )
  }

  const AddressField = () => {
    return (
      <TextField
        error={errors.addressLine1?.message}
        label='Address'
        placeholder='Street, apartment, suite, etc.'
        {...register('addressLine1', {
          required: `Please set the address.`,
          maxLength: {
            value: 100,
            message: `Your address cannot have more than 100 characters.`,
          },
        })}
      />
    )
  }

  const CityField = () => {
    return (
      <TextField
        error={errors.addressCity?.message}
        label='City'
        placeholder='London'
        {...register('addressCity', {
          required: `Please set the city.`,
          maxLength: {
            value: 100,
            message: `Your city name cannot have more than 100 characters.`,
          },
        })}
      />
    )
  }

  const CountyField = () => {
    return (
      <TextField
        error={errors.addressCounty?.message}
        label='County'
        placeholder='Greater London'
        {...register('addressCounty', {
          required: `Please set the county.`,
          maxLength: {
            value: 100,
            message: `Your county name cannot have more than 100 characters.`,
          },
        })}
      />
    )
  }

  const PostcodeField = () => {
    return (
      <TextField
        error={errors.addressPostcode?.message}
        label='Postcode'
        placeholder='NW3 5SJ'
        {...register('addressPostcode', {
          required: `Please set the postcode.`,
          maxLength: {
            value: 100,
            message: `Your postcode cannot have more than 100 characters.`,
          },
        })}
      />
    )
  }

  return (
    <div className='grid grid-cols-1 gap-x-8 gap-y-8 pt-10 lg:grid-cols-3'>
      <div className='px-0'>
        <div className='flex'>
          <h2 className='text-lg leading-6 font-medium text-gray-900'>
            Shipping Address
          </h2>
        </div>
        <p className='mt-1 text-sm text-gray-500'>
          Will be shared with brands when they need to ship the product.
        </p>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className='bg-white shadow ring-1 ring-gray-900/5 sm:rounded-xl lg:col-span-2'
      >
        <div className='px-4 py-6 sm:p-8'>
          <div className='grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6'>
            <div className='sm:col-span-6'>{AddressField()}</div>

            <div className='sm:col-span-3 sm:col-start-1'>{CountryField()}</div>

            <div className='sm:col-span-3'>
              <CityField />
            </div>

            <div className='sm:col-span-3 sm:col-start-1'>
              <CountyField />
            </div>

            <div className='sm:col-span-3'>
              <PostcodeField />
            </div>
          </div>
        </div>
        <div className='flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8'>
          <Button
            title='Update'
            type='submit'
            loading={loading}
            disabled={loading}
          />
        </div>
      </form>
    </div>
  )
}

export { EditShippingAddress }
